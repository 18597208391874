import { CollapseTemplate } from '../../components/collapse/c-collapse.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { BoxTemplate } from '../../components/box/c-box.template'
import { ChoiceListTemplate, defaultChoiceListData } from '../../components/choice-list/c-choice-list.template'
import { DropdownTemplate } from '../../components/dropdown/c-dropdown.template'
import { DateSelectorTemplate } from '../../components/date-selector/c-date-selector.template'
import { CarRentalCheckoutAdviceTemplate } from './w-booking-car-rental-checkout-advice.template'
import { RequiredCreditCardTemplate } from './w-booking-car-rental-credit-card.template'

/**
 * The BookingCarRentalData contains all data needed to hydrate a BookingCarRental view
 *
 * @typedef {Object}                      BookingCarRentalData
 * @property {String}                     collapseId                                              - the identifier for the collapsible
 * @property {Object}                     DriverDropdownData                                      - The data for the dropdown selector
 * @property {String}                     DriverDropdownData.label                                - The label for the driver dropdown
 * @property {String}                     DriverDropdownData.placeholder                          - The placeholder for the driver dropdown
 * @property {String}                     DriverDropdownData.messageRequired                      - The required message for the driver dropdown
 * @property {Object}                     DriverDropdownBirthdateData                             - The data for the birthdate selector
 * @property {String}                     DriverDropdownBirthdateData.label                       - The label for the birthday selector
 * @property {Bool}                       DriverDropdownBirthdateData.disabled                    - The disabled state for the birthday selector
 * @property {String}                     DriverDropdownBirthdateData.departureDate               - The departure date for the birthday selector
 * @property {String}                     DriverDropdownBirthdateData.minDateVisible              - The min date visible for the birthday selector
 * @property {String/String/String}       DriverDropdownBirthdateData.dateSelectorPlaceholders    - The placeholders for the birthday selector
 * @property {String}                     DriverDropdownBirthdateData.messageRequired             - The required message for the birthday selector
 * @property {String}                     DriverDropdownBirthdateData.messageInvalid              - The invalid message for the birthday selector
 * @property {String}                     DriverDropdownBirthdateData.messageOutOfRange           - The out of range message for the birthday selector
 * @property {Object}                     ageWarning                                              - The age warning when date is out of range
 * @property {Bool}                       ageWarning.enabled                                      - The age warning boolean when date is out of range
 * @property {Object}                     conditions                                              - The conditions to be shown
 * @property {String}                     cancelButton                                            - The cancel button text
 * @property {String}                     saveButton                                              - The save button text
 * @property {String}                     addButton                                               - The add button text
 * @property {Bool}                       optionChecked                                           - Boolean to know if the option is checked
 * @property {String}                     equipmentsLabel                                         - The label text for the equipments field
 * @property {String}                     equipmentsIntroText                                     - The label text for the equipments intro text
 * @property {String}                     checkoutAdvice                                          - The warning text to be shown in the selector box when selecting a car rental
 *
 */

export const BookingCarRentalEditDetailsTemplate = (d) => {
  return `${CollapseTemplate({
              id: d.collapseId,
              extraClasses: 'w-booking-car-rental__edit-details',
              attributes: { 'data-w-booking-car-rental-edit-details__collapse': '' },
              html: `${BookingCarRentalEditTemplate(d)}`
            })}`
}

const BookingCarRentalEditTemplate = (d) => {
  return `
    <div class="c-box--arrow w-booking-car-rental__edit-details__box" data-w-booking-car-rental__arrow-edit-details__box></div>
    ${BoxTemplate({
      variant: 'default',
      extraClasses: 'w-booking-car-rental__edit-details__box',
      html: `${CarRentalCheckoutTemplate(d)}`
    })}
  `
}

const CarRentalCheckoutTemplate = (d) => {
  return `
        <div class="w-booking-car-rental__checkout-content" data-w-booking-car-rental__checkout-form>
          <div class="w-booking-car-rental__checkout-form sp-stack-children--medium">
            <div class="w-booking-car-rental__checkout-field o-layout">
              ${DropdownTemplate({
                id: 'w-booking-car-rental__rent-driver',
                label: d.driver.label,
                placeholder: d.driver.placeholder,
                hasClearButton: false,
                required: true,
                messageRequired: d.driver.messageRequired ? d.driver.messageRequired : '',
                extraClasses: 'o-layout__item u-1/2@sm',
                attributes: { 'data-w-booking-car-rental__item-field-driver': '' }
              })}
              ${DateSelectorTemplate({
                required: true,
                disabled: d.birthdate.disabled,
                mainLabel: d.birthdate.label,
                id: 'w-booking-car-rental__rent-birthdate',
                extraClasses: 'w-booking-car-rental__checkout-field--date-selector o-layout__item u-1/2@sm',
                attributes: { 'data-w-booking-car-rental__item-field-birthdate': '', 'data-departure-date': d.birthdate.departureDate },
                maxDate: d.birthdate.minDateVisible,
                blockModifier: true,
                dayPlaceholder: d.birthdate.dateSelectorPlaceholders.day,
                monthPlaceholder: d.birthdate.dateSelectorPlaceholders.month,
                yearPlaceholder: d.birthdate.dateSelectorPlaceholders.year,
                messageRequired: d.birthdate.messageRequired ? d.birthdate.messageRequired : '',
                messageInvalid: d.birthdate.messageInvalid ? d.birthdate.messageInvalid : '',
                messageOutOfBounds: d.birthdate.messageOutOfRange ? d.birthdate.messageOutOfRange : ''
              })}
            </div>
            ${d.creditCard.checkboxEnabled ? RequiredCreditCardTemplate(d) : ''}
            ${d.ageWarning && d.ageWarning.enabled
              ? BoxTemplate({
                  variant: 'flat',
                  html: '',
                  extraClasses: 'w-booking-car-rental__item-ageWarning cs-state-error cs-state-error--light',
                  attributes: { 'data-w-booking-car-rental__item-ageWarning': '', hidden: '' }
                })
              : ''}
            ${CarRentalCheckoutAdviceTemplate(d.checkoutAdvice)}
            ${EquipmentsTemplate(d)}
          </div>

          <div class="w-booking-car-rental__checkout-form-footer">
            ${d.conditions
              ? BtnTemplate({
                  variant: 'flat-neutral',
                  extraClasses: 'm-link w-booking-car-rental-edit__conditions-btn',
                  track: `${d.carRentalTrack}.Conditions`,
                  attributes: {
                    'data-w-booking-car-rental__car-code': d.carSelectedCode
                  },
                  ...d.conditions
                })
              : ''
            }
            <div class="w-booking-car-rental__checkout-buttons">
              ${BtnTemplate({
                text: d.cancelButton,
                variant: 'flat-neutral',
                jsApi: true,
                track: `${d.carRentalTrack}.Cancel`,
                extraClasses: 'w-booking-car-rental-edit__cancel-btn',
                attributes: {
                  'data-w-booking-car-rental-edit-details__cancel-button': '',
                  'data-w-booking-car-rental__car-code': d.carSelectedCode
                }
              })}
              ${BtnTemplate({
                text: d.optionChecked ? d.saveButton : d.addButton,
                variant: 'trust',
                jsApi: true,
                disabled: true,
                extraClasses: 'w-booking-car-rental__save-btn',
                attributes: {
                  'data-w-booking-car-rental-edit-details__confirm-button': '',
                  'data-w-booking-car-rental__car-code': d.carSelectedCode
}
              })}
            </div>
          </div>
          
      </div>
    `
}

const EquipmentsTemplate = (d) => {
  return `
    <div class="w-booking-car-rental__container-equipments sp-stack-children--small" data-w-booking-car-rental__container-equipments>
      ${d.equipmentsLabel
        ? `<div class="sp-stack-children--tiny">
            <div><span class="m-heading m-heading--small">${d.equipmentsLabel}</span></div>
            ${d.equipmentsIntroText ? `<div><span class="m-body m-body--medium">${d.equipmentsIntroText}</span></div>` : ''}
          </div>`
      : ''}
      ${ChoiceListTemplate({
        ...defaultChoiceListData,
        ...{
          id: 'w-booking-car-rental__equipments',
          label: '',
          variant: 'boxed',
          method: 'multiple',
          extraClasses: 'w-booking-car-rental__checkout-field w-booking-car-rental__equipments',
          attributes: { 'data-w-booking-car-rental__item-field-equipments': '' }
        }
      })}
    </div>
  `
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookedServiceBasket from '../../../../shared/modules/widgets/booked-service-basket/main'

const data = require('../../../../shared/modules/widgets/booked-service-basket/data/w-booked-service-basket__js-template.json')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = async () => {
  const bookedServiceBasketItem = document.querySelectorAll('[data-js-api--w-booking-item="w-booked-service-basket"]')

  bookedServiceBasketItem.forEach(element => {
    const bookedServiceBasket = new BookedServiceBasket(element)
    console.log(bookedServiceBasket)
  })

  const bookedServiceBasketJsTemplate = document.querySelector('[data-booked-service-basket-demo]')
  if (bookedServiceBasketJsTemplate) {
    const widget = new BookedServiceBasket(bookedServiceBasketJsTemplate.firstElementChild)
    await widget.handleFetched({ response: data })
    await widget.enableUI()
  }
}

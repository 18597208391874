import { transferTicketType } from './main'

/**
* Returns the mapped data for the transfer ticket
* @param {Object} apiData
* @param {Object} settingsData
* @param {Bool}   isEmpty
* @param {String} type
* @return {Object | null}
*/
export const transferTicketMapper = (apiData, settingsData, isEmpty, type) => {
  let transferTicketData = null
  if (!settingsData) return transferTicketData
  if (isEmpty) {
    let emptyLabel
    if (type === transferTicketType.OUTBOUND) {
      emptyLabel = settingsData.departureEmptyLabel ? settingsData.departureEmptyLabel : null
    } else if (type === transferTicketType.INBOUND) {
      emptyLabel = settingsData.returnEmptyLabel ? settingsData.returnEmptyLabel : null
    }
    if (emptyLabel) {
      transferTicketData = {
        isEmpty: true,
        emptyText: emptyLabel
      }
    }
    return transferTicketData
  }
  if (!apiData) {
    let infoText
    if (type === transferTicketType.OUTBOUND) {
      infoText = settingsData.transferDepartureInfoText ? settingsData.transferDepartureInfoText : null
    } else if (type === transferTicketType.INBOUND) {
      infoText = settingsData.transferReturnInfoText ? settingsData.transferReturnInfoText : null
    }
    if (infoText) {
      transferTicketData = {
        infoMessage: infoText
      }
    }
    return transferTicketData
  }

  if (!apiData.pickupLocation || !apiData.pickupTime) return transferTicketData

  transferTicketData = {
    locationLabel: settingsData.transferPickUpLocationLabel,
    locationText: apiData.pickupLocation,
    timeLabel: settingsData.transferPickUpTimeLabel,
    timeText: apiData.pickupTime,
    timeDetails: settingsData.transferLocalTimeLabel,
    isCancelled: apiData.status.toLowerCase() === 'cancelled',
    cancelledText: settingsData.transferCancelledLabel
  }
  return transferTicketData
}

import { ChoiceListTemplate } from '../../components/choice-list/c-choice-list.template'
import { BookingFilterableServicesFilterTemplateRenderer } from './w-booking-filterable-services.template'

export const BookingFilterableServicesChoiceListFilterTemplate = (filter, filters, service, configurations) => {
  return `
  <div class="w-booking-filterable-services__filter w-booking-filterable-services__filter-${filter.columnName}">
  ${ChoiceListTemplate({
    method: 'single',
    variant: 'boxed',
    highlighted: false,
    name: `${filter.uid}`,
    id: `${filter.uid}`,
    label: filter.filterLabel,
    extraClasses: '',
    disabled: false,
    unresolved: false,
    items: filter.values.map(value => { return mapFilterItem(value, filter.selectedValue, filter.texts) }),
    attributes: {
      'data-w-booking-filterable-services__filters-choice-list': '',
      'data-w-booking-filterable-services__service-id': service.uid,
      'data-w-booking-filterable-services__filter-type': filter.columnName
    }
  })}
  </div>
  ${BookingFilterableServicesFilterTemplateRenderer(filters, service, configurations)}`
}

const mapFilterItem = (filterValue, selectedValue, texts = null) => {
  return {
    text: MapFilterItemText(filterValue, texts),
    id: filterValue,
    checked: selectedValue === filterValue,
    value: filterValue
  }
}

const MapFilterItemText = (filterValue, texts = null) => {
  if (!texts) { return filterValue }
  const textToBeReplaced = texts.find(text => text.key === filterValue)
  return (textToBeReplaced && textToBeReplaced.value) || filterValue
}

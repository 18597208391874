import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingFilterableServices from '../../../../shared/modules/widgets/booking-filterable-services/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const skiAncillariesData = require('../../../../shared/modules/widgets/booking-filterable-services/data/w-booking-filterable-services__js-template-ski-ancillaries.json')
const skiAncillariesDisabledData = require('../../../../shared/modules/widgets/booking-filterable-services/data/w-booking-filterable-services__js-template-ski-ancillaries-disabled.json')
const skiAncillariesMaterialIncludedData = require('../../../../shared/modules/widgets/booking-filterable-services/data/w-booking-filterable-services__js-template-ski-ancillaries-material-included.json')
const dinerVoucherData = require('../../../../shared/modules/widgets/booking-filterable-services/data/w-booking-filterable-services__js-template-diner-voucher.json')
const bridgeData = require('../../../../shared/modules/widgets/booking-filterable-services/data/w-booking-filterable-services__js-template-bridge.json')
const excursionsData = require('../../../../shared/modules/widgets/booking-filterable-services/data/w-booking-filterable-services__js-template-excursions.json')
const golfData = require('../../../../shared/modules/widgets/booking-filterable-services/data/w-booking-filterable-services__js-template-golf.json')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = async () => {
  const bookingFilterableServiceItems = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-filterable-services"]')

  bookingFilterableServiceItems.forEach(element => {
    const bookingFilterableServiceItem = new BookingFilterableServices(element)
    return bookingFilterableServiceItem
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingSkiAncillariesJsTemplate = document.querySelectorAll('[data-booking-ski-ancillaries-demo]')
  handleFetched(bookingSkiAncillariesJsTemplate, skiAncillariesData)

  const bookingSkiAncillariesMaterialIncludedJsTemplate = document.querySelectorAll('[data-booking-ski-ancillaries-material-included-demo]')
  handleFetched(bookingSkiAncillariesMaterialIncludedJsTemplate, skiAncillariesMaterialIncludedData)

  const bookingSkiAncillariesDisabledJsTemplate = document.querySelectorAll('[data-booking-ski-ancillaries-disabled-demo]')
  handleFetched(bookingSkiAncillariesDisabledJsTemplate, skiAncillariesDisabledData)

  const bookingBridgeJsTemplate = document.querySelectorAll('[data-booking-bridge-demo]')
  handleFetched(bookingBridgeJsTemplate, bridgeData)

  const bookingDinnerVoucherJsTemplate = document.querySelectorAll('[data-booking-dinner-voucher-demo]')
  handleFetched(bookingDinnerVoucherJsTemplate, dinerVoucherData)

  const bookingExcursionsJsTemplate = document.querySelectorAll('[data-booking-excursions-demo]')
  handleFetched(bookingExcursionsJsTemplate, excursionsData)

  const bookingGolfJsTemplate = document.querySelectorAll('[data-booking-golf-demo]')
  handleFetched(bookingGolfJsTemplate, golfData)
}

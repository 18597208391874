
export const definition = {
  name: 'w-booking-item'
}

export const classNames = {
  loading: 'is-loading',
  unresolved: 'is-unresolved',
  error: 'has-error',
  hidden: 'is-hidden'
}

export const attr = {
  url: 'url',
  method: 'method',
  componentId: 'componentId'
}

export const widgetQueries = {
  body: `[data-${definition.name}__body]`
}

export const configOptions = {
  priceConfiguration: 'priceConfiguration'
}

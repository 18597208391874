/**
 * Enumeration for the position of the currency symbol.
 */
const CurrencySymbolPosition = {
  /**
     * Currency not shown.
     */
  NotShown: 'NotShown',

  /**
     * The currency must be shown before the amount.
     */
  BeforeAmount: 'BeforeAmount',
  /**
     * The currency must be shown after the amount.
     */
  AfterAmount: 'AfterAmount',

  /**
     * Checks if a value is a member of the enumeration.
     */
  isValid: (enumValue) => {
    return Object.values(CurrencySymbolPosition).includes(enumValue)
  }
}

export default CurrencySymbolPosition

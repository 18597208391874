import { AccordionTemplate, defaultAccordionData } from '../../components/accordion/c-accordion.template'
import { ChoiceListTemplate, defaultChoiceListData } from '../../components/choice-list/c-choice-list.template'

/**
 * The BookingPreferencesSelectorData contains all data needed to hydrate a BookingPreferencesSelector view
 *
 * @typedef {Object}                  BookingPreferencesSelectorData
 *
 * @property {CategoryData[]}         categories                          - The list of categories to be shown
 * @property {String}                 CategoryData.title                  - The text of the category
 * @property {String}                 CategoryData.icon                   - The icon of the category
 * @property {Number}                 CategoryData.id                     - The id of the category
 * @property {PreferencesData[]}      CategoryData.preferences            - Contains the list of participants in the room
 * @property {ChoiceListData}         PreferencesData.choiceListInfo      - Contains all the options to be shown within the choice list component
 *
 */

export const defaultBookingPreferencesSelectorData = {
  accordionSize: 'small',
  track: null
}

export const BookingPreferencesSelectorTemplate = (d) => {
  d = { ...defaultBookingPreferencesSelectorData, ...d }

  return `
    ${(d.categories && d.categories.length > 0)
      ? AccordionTemplate(Object.assign({}, defaultAccordionData, {
        id: 'w-booking-preferences-selector__accordion',
        size: d.accordionSize,
        extraClasses: 'w-booking-preferences-selector__accordion',
        items: d.categories.map((category, index) => {
          return {
            title: category.title,
            icon: category.icon,
            id: `booking-preferences-selector__category-${category.id}-${index}`,
            innerHTML: PreferencesListTemplate(category.preferences, d.track)
          }
        })
      }))
      : PreferencesListTemplate(d.preferences, d.track)
    }`
}

const PreferencesListTemplate = (preferences, track) => {
  return `${ChoiceListTemplate(
              Object.assign({},
              defaultChoiceListData,
              preferences.choiceListInfo,
              { track },
              { items: preferences.choiceListInfo.items }
              )
          )}`
}

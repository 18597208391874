// @ts-check
/**
  * Returns the mapped data for the download button
  * @param {Object} itemApiData
  * @return {Object | null}
  */
export const mapperExcursionCard = (itemApiData) => {
  if (!itemApiData || !itemApiData.title || !itemApiData.url) return null
  const excursionCardData = {
    variant: 'compact',
    image: itemApiData.imageUrl
      ? {
          sync: false,
          resolve: 'intersect',
          resolvedSrc: itemApiData.imageUrl,
          title: itemApiData.title,
          ratio: '1:1'
        }
      : null,
    title: itemApiData.title,
    description: itemApiData.subtitle ? itemApiData.subtitle : null,
    button: {
      link: {
        href: itemApiData.url,
        target: '_blank',
        title: itemApiData.title
      }
    },
    price: null
  }
  return excursionCardData
}

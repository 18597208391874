import { encodeAttributes } from '../../../js/helpers/string'

export const defaultStepTemplate = {
  textMobile: '',
  text: '',
  active: false,
  disabled: false,
  link: {
    href: '',
    target: '',
    title: ''
  },
  extraClasses: ''
}

export const StepTemplate = (d, variant) => {
  d = { ...defaultStepTemplate, ...d }
  return `<div class="c-steps__item
            ${d.extraClasses ? ` ${d.extraClasses}` : ''}
            ${d.active ? ' is-active' : ''}
            ${d.disabled ? ' is-disabled' : ''}"
            ${d.attributes ? encodeAttributes(d.attributes) : ''}
            >
            <a class="c-steps__link"
                ${!d.active && !d.disabled && d.link
                    ? `${d.link.href ? ` href="${d.link.href}"` : ''}
                    ${d.link.target ? ` target="${d.link.target}"` : ''}
                    ${d.link.title ? ` title="${d.link.title}"` : ''}`
                    : ''}
            >
                ${variant === 'progress' ? '<span class="c-steps--progress__bullet"><i class="c-steps--progress__bullet-icon m-icon m-icon--checkmark"></i></span>' : ''}
                ${variant === 'vertical' ? '<span class="c-steps--vertical__bullet"><i class="c-steps--vertical__bullet-icon"></i></span>' : ''}
                <span class="c-steps__text--mobile">${d.textMobile}</span>
                <span class="c-steps__text">${d.text}</span>
            </a>
      </div>`
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingGenericSummary from '../../../../shared/modules/widgets/booking-generic-summary/main'

const bookingGenericSummaryData = require('../../../../shared/modules/widgets/booking-generic-summary/data/w-booking-generic-summary__js-template')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = () => {
  const bookingGenericSummaryItems = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-generic-summary"]')
  handleFetched(bookingGenericSummaryItems)

  document.addEventListener('razor-load-complete', function () {
    const bookingGenericSummaryItems = document.querySelectorAll('[data-razor-url] [data-js-api--w-booking-item="w-booking-generic-summary"]')
    handleFetched(bookingGenericSummaryItems)
  })
}

const handleFetched = (items) => {
  items.forEach(item => {
    const bookingGenericSummary = new BookingGenericSummary(item)
    console.log(bookingGenericSummary)
  })

  setTimeout(() => {
    const handleFetchedPromises = Array.from(items).map(element => {
      return element['w-booking-item'].handleFetched({ response: bookingGenericSummaryData })
    })
    handleFetchedPromises.forEach(promise => promise.then(resolvedItem => { resolvedItem.enableUI() }))
  }, 1500)
}

import PriceFormatter from '../../../js/helpers/price-formatter'
import { renderTypeEnum } from './enums'

/**
 * This data mapper helper convert a GetBookingDraft response into an
 * object that can be read by widget w-booked-service-basket
 */

export default class BookedServiceBasketDataMapper {
  mapServiceBasketInfo (response, configurations) {
    const priceConfig = configurations && configurations.priceConfiguration ? configurations.priceConfiguration : null
    const widgetResponse = {
      totalBookingAmount: response.bookingInfo
        ? PriceFormatter.toFormattedText(response.bookingInfo.totalPriceValue, priceConfig)
        : '',
      paidBookingAmount: response.bookingInfo
        ? PriceFormatter.toFormattedText(response.bookingInfo.originalBookingPaidAmountValue, priceConfig)
        : '',
      dueAmountValue:
        response.bookingInfo && response.bookingInfo.pendingAmountValue && response.bookingInfo.pendingAmountValue >= 0
          ? PriceFormatter.toFormattedText(response.bookingInfo.pendingAmountValue, priceConfig)
          : '',
      returnAmountValue:
        response.bookingInfo && response.bookingInfo.pendingAmountValue && response.bookingInfo.pendingAmountValue < 0
          ? `- ${PriceFormatter.toFormattedText(Math.abs(response.bookingInfo.pendingAmountValue), priceConfig)}`
          : '',
      downAmountValue:
        response.bookingInfo && response.bookingInfo.downPaymentAmount && response.bookingInfo.downPaymentAmount >= 0
          ? PriceFormatter.toFormattedText(response.bookingInfo.downPaymentAmount, priceConfig)
          : '',
      depositDueDateValue:
        response.bookingInfo && response.bookingInfo.depositDueDateValue
          ? response.bookingInfo.depositDueDateValue
          : '',
      overviewServices: this._mapOverviewServices(response.services, priceConfig, response.rooms)
    }
    return widgetResponse
  }

  // Temporary commented to go Live with insurances and pending to find a better solution
  _mapOverviewServices (services, priceConfig, rooms = null) {
    const overviewServices = []

    if (services) {
      services.forEach(service => {
        const selectedOptions = service.options.filter(option => option.isSelected)
        selectedOptions.forEach(option => {
          overviewServices.push({
            concept: this._serviceTextFormatted(service.renderType, option, rooms, option.displayText),
            value: option.extraPriceInfo && option.extraPriceInfo.totalPrice
              ? this._priceFormatted(option.extraPriceInfo.totalPrice, priceConfig)
              : '',
            subitems: option.extraPriceInfo
              ? this._addExtraPrices(
                option.extraPriceInfo.details,
                priceConfig,
                option,
                service.renderType,
                rooms,
                true
              )
              : []
          })
        })
      })
      return overviewServices
    }
  }

  _addExtraPrices (priceDetails, priceConfig, option, renderType, rooms, isFirstLevel = false) {
    if (priceDetails && Array.isArray(priceDetails)) {
      return priceDetails.map(extraPriceDetail => {
        const subitems = extraPriceDetail.details
          ? this._addExtraPrices(extraPriceDetail.details, priceConfig, option, renderType, rooms)
          : []

        const itemTranslated = {
          concept: this._serviceTextFormatted(
            renderType,
            option,
            rooms,
            extraPriceDetail.text,
            priceConfig,
            extraPriceDetail.pricePerParticipant,
            extraPriceDetail.numberOfParticipants
          ),
          value: this._priceFormatted(extraPriceDetail.totalPrice, priceConfig),
          subitems,
          extraClass: !isFirstLevel ? 'w-booked-service-basket__subitem--child' : ''
        }
        return itemTranslated
      })
    }
  }

  _priceFormatted (price, priceConfiguration) {
    return PriceFormatter.toFormattedText(price, priceConfiguration)
  }

  _serviceTextFormatted (renderType, option, rooms, text, priceConfiguration, pricePerParticipant = null, numberOfParticipants = null) {
    let textFormatted

    if (renderType) {
      switch (renderType) {
        case renderTypeEnum.PerParticipant:
          textFormatted =
          numberOfParticipants && pricePerParticipant
            ? `${text} (${numberOfParticipants} x ${PriceFormatter.toFormattedText(
                  pricePerParticipant,
                  priceConfiguration
                )})`
            : numberOfParticipants
              ? `${numberOfParticipants}x ${text}`
              : text
          break
        case renderTypeEnum.PerRoom:
          textFormatted = rooms
            ? `${
                rooms.filter(room =>
                  room.participantIds.some(participantId => option.assignedParticipants.includes(participantId))
                ).length
              }x ${text}`
            : text
          break
        case renderTypeEnum.PerBooking:
          textFormatted = `1x ${text}`
          break
        default:
          textFormatted = text
          break
      }
    }

    return textFormatted
  }
}

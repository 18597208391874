'use strict';
(function () {
  let devMode = window.sessionStorage.getItem('devMode')
  const devSwitcher = document.getElementsByClassName('js-dev-mode__switcher')[0]
  const devButton = document.getElementsByClassName('js-dev-mode__button')[0]
  const devClass = 's-development'
  const bodyElement = document.getElementsByTagName('BODY')[0]

  if (devMode && devMode === 'true') {
    bodyElement.classList.add(devClass)
    if (devSwitcher) {
      devSwitcher.checked = true
    }
  }

  if (devSwitcher) {
    devSwitcher.addEventListener('change',
      function () {
        devMode = devSwitcher.checked
        setDevMode(devMode, bodyElement, devClass)
      })
  }

  if (devButton) {
    devButton.addEventListener('click',
      function () {
        devMode = !devSwitcher.checked
        devSwitcher.checked = devMode
        setDevMode(devMode, bodyElement, devClass)
      })
  }
})()

function setDevMode (devMode, bodyElement, devClass) {
  window.sessionStorage.setItem('devMode', devMode ? 'true' : 'false')
  bodyElement.classList[devMode ? 'add' : 'remove'](devClass)
}

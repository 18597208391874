export const widgetApi = 'w-booking-box'

export const classList = {
  boxSelected: `${widgetApi}--selected`,
  noOptionSelected: `${widgetApi}--no-option-selected`
}

export const widgetQueries = {
  collapse: `[data-${widgetApi}__collapse]`,
  collapseTrigger: `[data-${widgetApi}__collapse-trigger]`,
  headingTitle: `[data-${widgetApi}__heading-title]`,
  boxCollapse: `[data-${widgetApi}__box-collapse]`,
  identifierSearchName: 'sid'
}

export const apis = {
  collapseApi: 'c-collapse'
}

export const attr = {
  titleSelected: 'wBookingBox__titleSelected',
  noOptionTitleSelected: 'wBookingBox__noOptionTitleSelected',
  boxState: 'wBookingBox__boxState',
  defaultTitle: 'wBookingBox__defaultTitle'
}

export const boxStates = {
  default: 'default',
  selected: 'selected'
}

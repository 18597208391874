import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'

const widgetApi = 'w-booking-remarks'
const antiForgeryTokenName = '__RequestVerificationToken'

const widgetQueries = {
  form: `[data-${widgetApi}__form]`,
  textArea: `[data-${widgetApi}__text-area]`,
  formApi: 'c-form',
  textAreaApi: 'c-textarea',
  antiForgeryToken: `[name="${antiForgeryTokenName}"]`
}

export default class BookingRemarks extends BookingBox {
  /**
   * Creates a new Booking Remarks widget.
   *
   * @constructor
   *
   * @param {HTMLElement} element   - The HTML content.
   * @param {Object} [options={}]   - Options.
   */
  constructor (element, options = {}) {
    super(element)
    this.element = element
    this.form = this.element.querySelector(widgetQueries.form)
    this.textArea = this.element.querySelector(widgetQueries.textArea)
    this.antiForgeryToken = this.element.querySelector(widgetQueries.antiForgeryToken)
    this.contextItemId = this.element.dataset.componentId
    this.formApi = this.form[widgetQueries.formApi]
    if (this.formApi) {
      this.method = this.formApi.method
      this.action = this.formApi.action
    }
    this.textAreaApi = this.textArea[widgetQueries.textAreaApi]
  }

  async getValidationData () {
    const eventArgs = {
      method: this.method,
      url: this.action,
      isForm: true
    }
    if (this._method !== 'GET') {
      const formData = this._getRemarksData()
      formData.append('contextitemid', this.contextItemId)
      if (this.antiForgeryToken) {
        formData.append(antiForgeryTokenName, this.antiForgeryToken.value)
      }
      eventArgs.body = formData
    }
    return eventArgs
  }

  async handleValidationClient () {
    let validationResult = false
    if (this.formApi) {
      validationResult = this.formApi.validate().every(v => v.isValid)
    }
    return validationResult
  }

  _getRemarksData () {
    const formData = new window.FormData()
    const remarksFieldValue = this.textAreaApi.getProp('value')

    formData.append('remarks', remarksFieldValue)

    return formData
  }

  _getPropValue (componentApi, propName) {
    return componentApi.getProp(propName)
  }

  /**
   * This is the command that the mediator will send to all the registered items once a
   * validation at server side has to be performed. The call is done asynchronously
   */
  async handleValidationServer (response) {
    return response.success && super.handleValidationServer(response)
  }
}

registerWidget(BookingRemarks, widgetApi)

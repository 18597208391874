import { encodeAttributes } from '../../../js/helpers/string'
import { PriceTableTemplateDiscountBullet } from './c-price-table.discount.bullet.template'
import { CollapseTemplate, defaultCollapseData } from '../../components/collapse/c-collapse.template'
import { PhotoGalleryTemplate } from '../../components/photo-gallery/c-photo-gallery.template'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/c-price-table__tokens.json')['c-price-table']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/price-table/data/c-price-table__tokens.json`)['c-price-table'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

/**
 * The PriceTableTemplateData contains all data organized to be easily displayed in a table
 *
 * @typedef {Object}     PriceTableTemplateData
 *
 * @property {String}    id                                                 - Price table element's id
 * @property {String}    extraClasses                                       - Element extra CSS classes
 * @property {Object}    attributes                                         - Element attributes (i.e. data-attributes)
 * @property {String}    currency                                           - Price Currency
 * @property {String}    periodName                                         - Period for the package in days
 * @property {Object}    ageProfileDictionary                               - Age profiles info
 * @property {String}    ageProfileDictionary.icon                          - Icon for specific age profile
 * @property {String}    ageProfileDictionary.i18nString                    - Humanized string for specific age profile
 * @property {Object}    departureDictionary                                - Available departure dates info
 * @property {String[]}  departureDictionary.descriptionLines               - Humanized date info
 * @property {Object}    roomData                                           - Info about room type and its available occupancy
 * @property {String}    roomData.title                                     - Room type title
 * @property {String}    roomData.subtitle                                  - Room type subtitle
 * @property {String[]}  roomData.characteristics                           - Room type characteristics
 * @property {Object[]}  roomData.images                                    - Array of objects representing the images of the room
 * @property {Object}    roomData.occupancyData                             - Info about age profile distribution
 * @property {Object[]}  roomData.occupancyData.ageProfileGroups            - Exact quantity of every age profile
 * @property {Object[]}  roomData.occupancyData.ageProfileThresholds        - Exact quantity of every age profile
 * @property {Object}    roomData.occupancyData.packageData                 - Info about the package for a specific roomType and occupancy
 * @property {Object}    roomData.occupancyData.packageData.id              - {roomTypeId}_{occupancyId}_{departureDate}
 * @property {Object}    roomData.occupancyData.packageData.stock           - Available packages of this type
 * @property {Object}    roomData.occupancyData.packageData.formattedPrice  - Formatted price without any currency text
 * @property {Object}    roomData.occupancyData.packageData.transportType   - Type of transport (Flight, bus...)
 * @property {Boolean}   [hasUiTest]                                        - Indicate if the component has a UI applied to it or not
 */

export const defaultPriceTableData = {
  id: '',
  extraClasses: '',
  attributtes: {},
  isCompactMode: false,
  hasUiTest: true
}

/**
 * Returns an html price table template/string
 *
 * @param {PriceTableTemplateData}  d
 * @param {Object}                  options
 *
 * @returns {String} Price Table HTML string
 */

export const PriceTableTemplate = (d, options = {}) => {
  d = { ...defaultPriceTableData, ...d, ...options }

  return `
<div id="${d.id}" class="c-price-table"
  data-compact-mode='${d.isCompactMode.toString()}' ${d.attributes ? encodeAttributes(d.attributes) : ''}
  ${d.bestValues ? d.bestValues.map(el => `data-threshold-${el.name}=${el.threshold}`).join(' ') : ''}>
  <div class="c-price-table__wrapper">
  ${d.legendHighlightPrices ? _getLegend(d.legendHighlightPrices) : ''}
    <div class="c-price-table__navigation-wrapper">
      <button type="button" class="c-btn c-btn--icon-only c-price-table__navigation c-price-table__navigation--right">
        <i class="c-btn__icon c-btn__icon--right m-icon--chevron-right"></i>
        <span class="c-btn__text"></span>
      </button>
      <div class="c-price-table__row c-price-table__row--heading o-pack o-pack--middle">
        <div class="c-price-table__cell c-price-table__cell--title o-pack__item">
          <i class="c-price-table__title-icon m-icon m-icon--calendar"></i>
          <div class="c-price-table__title-text">
            <span class="c-price-table__title-period">${d.periodName} </span>
            ${d.periodNameExtraInfo ? `<span class="c-price-table__title-period-extra-info">${d.periodNameExtraInfo}</span>` : ''}
          </div>
          <button type="button" class="c-btn c-btn--icon-only c-price-table__navigation c-price-table__navigation--left">
            <i class="c-btn__icon c-btn__icon--left m-icon--chevron-left"></i>
            <span class="c-btn__text"></span>
          </button>
        </div>
        ${Object.keys(d.departureDictionary).map((departureDate, departureDateIndex) => `
        <div class="c-price-table__cell c-price-table__cell--date o-pack__item ${d.hasUiTest ? 'ui-test-pricetable-column' : ''}" data-date="${departureDate}" data-position="${departureDateIndex}">
          <div class="c-price-table__day-name m-body m-body--tiny">${d.departureDictionary[departureDate].descriptionLines[0]}</div>
          <div class="c-price-table__day-month ${getTokenClass('room-name', 'default', tokens)}">${d.departureDictionary[departureDate].descriptionLines[1]}</div>
        </div>
        `).join('')}
      </div>
    </div>
    ${Object.entries(d.roomData).map(([roomId, roomItem], roomTypeIndex) => {
      const isRoomCharacteristicList = roomItem.characteristics && roomItem.characteristics.length > 1
      const roomContentColumnsToken = (isRoomCharacteristicList) ? 'default' : 'singleColumn'
      return `
        <div class="c-price-table__room">
          <div class="c-price-table__room-info">
            <div class="c-price-table__room-title">
              <i class="c-price-table__room-icon m-icon m-icon--bed"></i>
              <div class="c-price-table__room-name ${getTokenClass('room-name', 'default', tokens)}">${roomItem.title}</div>
              <div class="c-price-table__room-description m-body m-body--small">${roomItem.subtitle}</div>
              ${roomItem.characteristics
                ? `<button class="m-button--clean c-collapse__trigger c-price-table__room-trigger"
                      data-c-collapse__id="${d.id}-room-collapse-${roomTypeIndex}"
                      data-c-collapse__action="toggle" data-c-collapse__opened="false">
                    <span class="c-collapse__trigger-text">${d.locales.moreInfo ? d.locales.moreInfo : ''}</span>
                    <span class="m-icon m-icon--size-large m-icon--chevron-down c-collapse__trigger-icon"></span>
                  </button>`
                : ''}
            </div>
            ${roomItem.characteristics
              ? CollapseTemplate({
                ...defaultCollapseData,
                ...{
                  id: `${d.id}-room-collapse-${roomTypeIndex}`,
                  html: GetRoomCharacteristicsHtml(roomItem, isRoomCharacteristicList, roomContentColumnsToken)
                }
              })
            : ''}
          </div>
          ${Object.keys(roomItem.occupancyData)
            .sort(function (firstRoom, secondRoom) {
              return (roomItem.occupancyData[firstRoom].ageProfileGroups[0].quantity || 0) - (roomItem.occupancyData[secondRoom].ageProfileGroups[0].quantity || 0)
            })
            .map(occupancyId => {
              const occupancyItem = roomItem.occupancyData[occupancyId]
              return `
              <div class="c-price-table__row o-pack o-pack--middle">
                <div class="c-price-table__cell c-price-table__cell--participants o-pack__item">
                  <span class="c-price-table__cell--participants-fine-detail">
                  ${occupancyItem.ageProfileGroups.map(({ ageProfileId, quantity }) => {
                    const icon = d.ageProfileDictionary[ageProfileId].icon
                    const iconIsArray = Array.isArray(icon)
                    return `
                      <span class="c-price-table__participant" data-participants="${quantity}">
                        ${!iconIsArray || (quantity >= 3)
                    ? `<i class="m-icon m-icon--${iconIsArray ? icon[0] : icon}"></i> x${quantity}`
                    : Array(quantity).fill(1).map(() => `<i class="m-icon m-icon--${icon[0]}"></i>`).join('')}
                      </span>
                    `
                  }).join('')}
                  </span>
                  <span class="c-price-table__cell--participants-coarse-detail">
                    ${_getOccupancyByAgeProfile(d.ageProfileDictionary, occupancyItem)}
                  </span>
                </div>
                ${Object.keys(d.departureDictionary).map((departureDate, departureDateIndex) => {
                  const packageData = occupancyItem.packageData[departureDate]
                  const bestValueScore = (packageData && packageData.bestValueScore) ? packageData.bestValueScore : undefined
                  return `
                <div class="c-price-table__cell c-price-table__cell--price o-pack__item ${_getCellThresholdExtraClasses(d.bestValues, bestValueScore)}" data-position="${departureDateIndex}"

                  ${packageData ? `data-id="${packageData.id}"` : ''}
                  ${bestValueScore ? `data-best-value-score="${bestValueScore}"` : ''}>
                  ${packageData
                  ? `<div class="c-price c-price--small">
                    <div class="c-price__number">
                    <span class="c-price__value">${packageData.formattedPrice}</span>
                    </div>
                    ${_getPriceHighlight(packageData)}
                  </div>`
                  : '‒'}
                </div>
                `
                }).join('')}
              </div>`
            }).join('')}
        </div>
    `
    }).join('')}
  </div>
  ${d.mandatoryExtraCostsText || d.staticText || (d.bestValues && d.bestValuesCaption)
    ? `<div class="c-price-table__footer-wrapper">
        ${d.mandatoryExtraCostsText || d.staticText ? _showMandatoryExtraInfo([d.mandatoryExtraCostsText, d.staticText]) : ''}
        ${d.bestValues && d.bestValuesCaption ? _showTitleWithDescription(d.bestValuesCaption.title, d.bestValuesCaption.description) : ''}
      </div>`
    : ''}
  </div>`
}

function _getOccupancyByAgeProfile (ageProfileDictionary, occupancyItem) {
  const result = occupancyItem.ageProfileGroups
    .filter(x => x.quantity > 0)
    .map(x => {
      const text = ageProfileDictionary[x.ageProfileId].i18nString.split('||||')[x.quantity > 1 ? 1 : 0]
      return `${x.quantity} ${text}`
    })
    .join(' + ')

  return result
}

function _getPriceHighlight (packagePrice) {
  return `
    ${packagePrice.highlightPriceOption
    ? _isOnRequest(packagePrice.highlightPriceOption)
      ? `<div class="c-price-table__onrequest">${packagePrice.highlightPriceOption.description}</div>`
      : PriceTableTemplateDiscountBullet({ ...packagePrice.highlightPriceOption, extraClasses: 'c-price-table__discount_bullet_placement' })
    : ' '}`
}

function _getLegend (legendHighlightPrices) {
  return `<div class="c-price-table__legend_wrapper"><div class="c-price-table__legend">${Object.entries(legendHighlightPrices).map(([legendItemId, legendItem]) =>
    `${_isOnRequest(legendItem)
      ? ''
      : legendItem.hexColor
       ? `${PriceTableTemplateDiscountBullet({ ...legendItem, extraClasses: 'c-price-table__legend_item_bullet' })}<div class="c-price-table__legend_item_description">${legendItem.description}</div>`
       : ''
      }`).join('')}</div></div>`
}

function _isOnRequest (item) {
  return item
    ? item.code
      ? item.code.toUpperCase() === 'ONREQUEST'
      : false
    : false
}

function _getCellThresholdExtraClasses (thresholds, bestValueScore) {
  if (thresholds && bestValueScore !== undefined) {
    return thresholds.map(
      el => `${(bestValueScore >= el.threshold) ? ('c-price-table__cell-threshold-' + el.name) : ''}`
    ).join(' ')
  } else {
    return ''
  }
}

const GetRoomCharacteristicsHtml = (roomItem, isRoomCharacteristicList, roomContentColumnsToken) => {
  let html = ''
  if (roomItem.images) {
    html = PhotoGalleryTemplate({ popupTitle: roomItem.title, elements: roomItem.images })
  }
  return `${html}
  <div class="c-price-table__room-content sp-stack-children--medium o-columns ${getTokenClass('room-content-columns', roomContentColumnsToken, tokens)}">
  ${(isRoomCharacteristicList)
    ? `<ul>
        ${roomItem.characteristics.map((item) => `<li class="c-price-table__room-characteristic">${item}</li>`).join('')}
      </ul>`
    : `<div class="c-price-table__room-characteristic-single">${roomItem.characteristics[0]}</div>`}
</div>
`
}

function _showMandatoryExtraInfo (extraInfo) {
  return `<div class="c-price-table__footer-extra-info m-body--tiny">${extraInfo.filter(text => !!text).join(' ')}</div>`
}

function _showTitleWithDescription (title, description) {
  return `
    <div class="c-price-table__footer-caption m-body--tiny">
      <div class="c-price-table__footer-caption-title">${title || ''}</div>
      <div class="c-price-table__footer-caption-description">${description || ''}</div>
    </div>`
}


export const renderTypeEnum = {
  PerParticipant: 'PerParticipant',
  PerRoom: 'PerRoom',
  PerBooking: 'PerBooking'
}

export const operationTypeEnum = {
  addOperation: 'Add',
  removeOperation: 'Remove',
  swapOperation: 'Swap',
  patchOperation: 'Patch'
}

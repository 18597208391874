import { TitleTemplate } from '../../components/title/c-title.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/w-booking-car-rental__tokens.json')['w-booking-car-rental']

const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/widgets/booking-car-rental/data/w-booking-car-rental__tokens.json`)['w-booking-car-rental'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const BookingCarRentalAddExtraCarsTemplate = (d) => {
  return `${d
            ? `<div class="w-booking-car-rental__add-extra-cars sp-stack-children--medium">
                <div data-w-booking-car-rental__extra-car-list></div>
                <div class="w-booking-car-rental__separator sp-stack-children--medium">
                  ${TitleTemplate({
                    tag: 'h4',
                    text: d.addExtraCarTitle,
                    size: getTokenClass('add-extra-car-title-size', 'default', tokens) || 'small',
                    extraClasses: 'w-booking-car-rental__add-extra-cars-title'
                  })}  
                  ${d.addExtraCarDescription
                    ? `<div class="w-booking-car-rental__add-extra-cars-content ${getTokenClass('add-extra-car-description', 'default', tokens)}">
                        ${d.addExtraCarDescription}</div>`
                    : ''}
                  ${d.showAddExtraCarsBlock && d.addExtraCarEnabled && d.addExtraCarButtonText
                    ? `<div class="w-booking-car-rental__add-extra-cars-button">
                        ${BtnTemplate({
                          text: d.addExtraCarButtonText,
                          variant: 'secondary',
                          icon: d.addExtraCarButtonIcon,
                          jsApi: true,
                          attributes: { 'data-w-booking-car-rental__add-extra-car-button': '' }
                        })}
                      </div>`
                    : ''}
                </div>  
              </div>`
            : ''}`
}

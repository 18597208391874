import { attr as formAttr } from '../../components/form/main'

const requestManualEditionMode = 'requestManualEditionMode'
const phonesIdPrefix = 'phones-'
const addressIdPrefix = 'address-'
const requestManualEditPrefix = 'rme-'

/**
 * Form Data Mapper
 *  Transforms API and settings data into field components.
 *  Transforms the plain form data into the model to send to API
 */
export default class FormDataMapper {
  /**
   * Merges the data of a participant to edit with the form configuration, which contains distribution and texts.
   * Returns a model ready to be used to hydrate a @see PersonalDetailsEdit view
   *
   * @param {string}                 id                                     - The id of the parent widget
   *
   * @param {Object}                 apiData                                - Data specific of current participant to edit
   * @param {Object[]}               apiData.rules                          - The rules for edition
   * @param {string}                 apiData.rules[].fieldName              - The field name this rule is for
   * @param {string}                 apiData.rules[].currentValue           - The current value for the field
   * @param {string}                 apiData.rules[].editionType            - The type of edition allowed for the field
   * @param {Object}                 apiData.rules[].range                  - If applicable for the type of field, the allowed range
   * @param {string}                 apiData.rules[].range.min              - The minimum value allowed
   * @param {string}                 apiData.rules[].range.max              - The maximum value allowed
   *
   * @param {Object[]}               sectionsConfigList                                 - The list of sections for the form and its fields. Contains configuration of distribution and texts for fields.
   * @param {string}                 sectionsConfigList[].title                         - The title of the section
   * @param {Object[]}               sectionsConfigList[].fields                        - The fields of the section
   * @param {string}                 sectionsConfigList[].fields.type                   - The field name this rule is for. MUST match with a rule.
   * @param {string}                 sectionsConfigList[].fields.label                  - The label of the field
   * @param {string}                 sectionsConfigList[].fields.placeholder            - The placeholder of the field
   * @param {Object}                 sectionsConfigList[].fields.placeholders           - If applicable for the type of field, placeholders for child inputs
   * @param {string}                 sectionsConfigList[].fields.isMandatory            - Whether it is mandatory or not
   * @param {string}                 sectionsConfigList[].fields.pattern                - The pattern for the value
   * @param {string}                 sectionsConfigList[].fields.emptyErrorMessage      - Error message for when value is empty
   * @param {string}                 sectionsConfigList[].fields.invalidErrorMessage    - Error message for when value is invalid
   * @param {string}                 sectionsConfigList[].fields.additionalSettings     - Custom settings specific for this type.
   *
   * @param {Object}                settings                                                                      - Contains all settings to render the personal details form.
   * @param {Object}                settings.requestManualEditionSettings                                         - Contains settings that refer to manual edition request.
   * @param {Object}                settings.notEditableTooltipTexts                                              - Contains settings with texts for tooltip texts when field is not editable.
   *
   * @returns {Object}               - The data model ready to be used to hydrate a @see PersonalDetailsEdit view
   */
  mergeRulesAndSettings (id, apiData, sectionsConfigList, settings) {
    const tooltips = settings ? settings.notEditableTooltipTexts : null
    const formSections = this._mapFormSections(apiData, sectionsConfigList, null, tooltips)
    const requestManualEditionFormSections = this._mapFormSections(apiData, sectionsConfigList, requestManualEditionMode, tooltips)
    const allFieldSettings = sectionsConfigList.map(sectionSettings => sectionSettings.fields).flat()
    const changePatternSettings = this._getChangePatternSettings(allFieldSettings)

    const showTopWarning = settings ? this._showTopWarning(settings.requestManualEditionSettings, apiData) : null

    const templateData = {
      id,
      formSections,
      changePatternSettings,
      showTopWarning,
      requestManualEditionData: this._resolveRequestManualEditionData(settings),
      requestManualEditionFormSections
    }

    return templateData
  }

  /**
 * Maps the form data, a simple json with key name - string value,
 * into the body data to send to the API, which can be more complex with lists or objects.
 * Keys should be the same ids of the fields returned in @see mergeRulesAndSettings .
 *
 * Returns a model ready to be send to the API to save the data.
 *
 * @param {Object}                 formData                    - simple json with key name - string value.
 *
 * @returns {Object}               - The data model ready to be send to the API to save the data.
 */
  mapFormData (formData, formMode) {
    const result = {}

    const phones = []
    let address = null
    for (const property in formData) {
      let newProperty = property
      if (formMode === requestManualEditionMode) {
        newProperty = property.replace(requestManualEditPrefix, '')
      }

      if (newProperty.startsWith(phonesIdPrefix)) {
        const type = newProperty.split('-')[1]
        const value = formData[property]
        phones.push({ type, number: value })
      } else if (newProperty.startsWith(addressIdPrefix)) {
        if (address === null) {
          address = {}
        }
        const subProperty = newProperty.split('-')[1]
        address[subProperty] = formData[property]
      } else {
        result[newProperty] = formData[property]
      }
    }

    if (phones.length > 0) {
      result.phones = phones
    }
    if (address !== null) {
      result.address = address
    }
    return result
  }

  _mapFormSections (apiData, sectionsConfigList, formMode, tooltips) {
    const formSections = sectionsConfigList.map(sectionSettings => {
      const fields = sectionSettings.fields
        .map(fieldSettings => {
          const rule = apiData.rules.filter(r => r.fieldName === fieldSettings.type).shift()
          return rule ? this._getComponents(rule, fieldSettings, formMode, tooltips) : null
        })
        .filter(components => components != null)
        .flat()

      const formSection = {
        title: sectionSettings.title,
        components: fields
      }

      return formSection
    })
    return formSections.filter(section => section.components.length > 0)
  }

  _getComponents (rule, fieldSettings, formMode, tooltips) {
    const components = []

    if (this._showField(formMode, rule.editionType)) {
      let id = `${formMode === requestManualEditionMode ? requestManualEditPrefix : ''}${rule.fieldName}`
      const disabled = this._isFieldDisabled(formMode, rule.editionType)
      let value = rule.currentValue ? rule.currentValue : ''
      const tooltipText = this._getTooltipText(rule, tooltips)
      let component = null

      switch (rule.fieldName) {
        case 'firstName':
        case 'lastName':
        case 'email':
          component = this._buildTextField(id, value, disabled, fieldSettings, null, tooltipText)
          components.push(component)
          break
        case 'dateOfBirth':
          component = {
            // fieldset field settings
            type: 'c-date-selector',
            size: 'medium',
            data: {
              // component settings
              id,
              date: value,
              mainLabel: fieldSettings.label,
              dayPlaceholder: fieldSettings.placeholders ? fieldSettings.placeholders.day : '',
              monthPlaceholder: fieldSettings.placeholders ? fieldSettings.placeholders.month : '',
              yearPlaceholder: fieldSettings.placeholders ? fieldSettings.placeholders.year : '',
              required: fieldSettings.isMandatory,
              disabled,
              minDate: rule.range ? rule.range.min : null,
              maxDate: rule.range ? rule.range.max : null,
              messageRequired: fieldSettings.emptyErrorMessage,
              messageInvalid: fieldSettings.invalidErrorMessage,
              messageOutOfBounds: fieldSettings.invalidErrorMessage,
              blockModifier: true,
              tooltip: this._buildTooltip(disabled, tooltipText)
            }
          }
          components.push(component)
          break
        case 'phones': {
          const allPhones = rule.currentValue || []
          const phonesSubList = this._getPhonesOfType(allPhones, fieldSettings.subType)
          const idPrefix = phonesIdPrefix + fieldSettings.subType.toLowerCase() + '-'

          if (phonesSubList.length > 0) {
            for (let i = 0; i < phonesSubList.length; i++) {
              const currentPhone = phonesSubList[i]
              const currentValue = currentPhone.number ? currentPhone.number : ''
              const isMandatory = fieldSettings.isMandatory && i === 0
              const phoneInput = this._buildPhoneField(idPrefix + i, currentValue, disabled, fieldSettings, isMandatory)
              components.push(phoneInput)
            }
          } else {
            const phoneInput = this._buildPhoneField(idPrefix + '0', '', disabled, fieldSettings, fieldSettings.isMandatory)
            components.push(phoneInput)
          }
          break
        }
        case 'address': {
          const subType = fieldSettings.subType
          id = addressIdPrefix + subType
          if (rule.currentValue) {
            value = rule.currentValue[subType] ? rule.currentValue[subType] : ''
          } else {
            value = ''
          }

          const attributes = {}
          if (subType === 'countryCode') {
            attributes[formAttr.changePatternSettingsId] = 'country'
            component = this._buildDropdownField(id, value, disabled, fieldSettings, attributes)
          } else {
            if (subType === 'postalCode') {
              attributes[formAttr.changePatternFieldSelector] = 'postal-code'
            }
            component = this._buildTextField(id, value, disabled, fieldSettings, attributes, tooltipText)
          }
          components.push(component)

          break
        }
        case 'title':
        case 'gender':
          component = this._buildRadioButtons(id, value, disabled, fieldSettings, tooltipText)
          components.push(component)

          break
        default:
          // Nothing to do here
          break
      }
    }

    return components
  }

  _getChangePatternSettings (allFieldsSettings) {
    let settings = {}

    const postalCodeSettings = allFieldsSettings.find(x => x.type === 'address' && x.subType === 'postalCode')
    if (postalCodeSettings && postalCodeSettings.additionalSettings && postalCodeSettings.additionalSettings.allCountries) {
      settings = {
        country: [
          {
            fieldSelector: 'postal-code',
            patterns: postalCodeSettings.additionalSettings.allCountries.map(function (x) {
              return { key: x.iso2, pattern: x.pattern }
            })
          }
        ]
      }
    }

    return settings
  }

  _getPhonesOfType (allPhones, type) {
    if (type.toLowerCase() === 'mobile') {
      return allPhones.filter(phone => this._isPhoneOfType(phone, type) || this._isPhoneOfUnsupportedType(phone))
    } else {
      return allPhones.filter(phone => this._isPhoneOfType(phone, type))
    }
  }

  _isPhoneOfUnsupportedType (phone) {
    const phoneType = phone.type.toLowerCase()
    return phoneType !== 'emergency' && phoneType !== 'mobile'
  }

  _isPhoneOfType (phone, type) {
    return phone.type.toLowerCase() === type.toLowerCase()
  }

  _buildPhoneField (id, value, disabled, fieldSettings, isMandatory) {
    const phoneInput = {
      // fieldset field settings
      type: 'c-phone-input',
      size: 'tiny',
      data: {
        // component settings
        id,
        value,
        label: fieldSettings.label,
        placeholder: fieldSettings.placeholder,
        required: isMandatory,
        disabled,
        messageRequired: fieldSettings.emptyErrorMessage,
        messageInvalid: fieldSettings.invalidErrorMessage
      }
    }

    if (fieldSettings.additionalSettings) {
      phoneInput.data.dialSymbol = fieldSettings.additionalSettings.dialSymbol
      phoneInput.data.countries = fieldSettings.additionalSettings.countries
    }
    return phoneInput
  }

  _buildTextField (id, value, disabled, fieldSettings, attributes, tooltipText) {
    const textField = {
      // fieldset field settings
      type: 'c-textbox',
      size: 'tiny',
      data: {
        // component settings
        id,
        value,
        label: fieldSettings.label,
        placeholder: fieldSettings.placeholder,
        required: fieldSettings.isMandatory,
        patternRule: fieldSettings.pattern,
        disabled,
        messageRequired: fieldSettings.emptyErrorMessage,
        messageInvalid: fieldSettings.invalidErrorMessage,
        attributes: attributes || {},
        tooltip: this._buildTooltip(disabled, tooltipText)
      }
    }

    return textField
  }

  _buildDropdownField (id, value, disabled, fieldSettings, attributes) {
    const dropdownData = {
      id,
      label: fieldSettings.label,
      placeholder: fieldSettings.placeholder,
      required: fieldSettings.isMandatory,
      disabled,
      messageRequired: fieldSettings.emptyErrorMessage,
      options: [],
      attributes: attributes || {}
    }

    if (fieldSettings.values) {
      dropdownData.options = fieldSettings.values.map(function (x) {
        const xValue = x.value ? x.value : ''
        const item = {
          value: x.value,
          text: x.text,
          selected: xValue.toLowerCase() === value.toLowerCase()
        }
        return item
      })
    }

    const dropdownField = {
      type: 'c-dropdown',
      size: 'tiny',
      data: dropdownData
    }

    return dropdownField
  }

  _buildRadioButtons (id, value, disabled, fieldSettings, tooltipText) {
    const choiceListData = {
      method: 'single',
      variant: 'inline',
      name: id,
      id,
      label: fieldSettings.label,
      jsApi: true,
      disabled,
      required: fieldSettings.isMandatory,
      requiredMessage: fieldSettings.emptyErrorMessage,
      items: [],
      tooltip: this._buildTooltip(disabled, tooltipText)
    }

    if (fieldSettings.values) {
      choiceListData.items = fieldSettings.values.map(function (x, index) {
        const xValue = x.value ? x.value : ''
        const item = {
          id: '-item-00' + index,
          value: x.value,
          text: x.text,
          checked: xValue.toLowerCase() === value.toLowerCase()
        }
        return item
      })
    }

    const choiceListField = {
      type: 'c-choice-list',
      size: 'large',
      data: choiceListData
    }

    return choiceListField
  }

  _showTopWarning (requestManualEditionSettings, apiData) {
    let showTopWarning = false

    if (requestManualEditionSettings && requestManualEditionSettings.externalEditableFieldsWarning &&
      apiData && apiData.rules) {
      showTopWarning = apiData.rules.some(f => f.editionType === 'ExternalEditable')
    }

    return showTopWarning
  }

  _resolveRequestManualEditionData (generalSettings) {
    const manualEditionData = {}

    if (generalSettings && generalSettings.requestManualEditionSettings) {
      manualEditionData.settings = generalSettings.requestManualEditionSettings
    }

    return manualEditionData
  }

  _showField (formMode, editionType) {
    return formMode && formMode === requestManualEditionMode
      ? editionType === 'ExternalEditable'
      : true
  }

  _isFieldDisabled (formMode, editionType) {
    return formMode && formMode === requestManualEditionMode
      ? (editionType !== 'Editable' && editionType !== 'ExternalEditable')
      : editionType !== 'Editable'
  }

  _getTooltipText (rule, tooltips) {
    let tooltipText = null
    if (rule.editionType !== 'Editable' && tooltips) {
      tooltipText = tooltips.toolTipNotEditableDefaultMessage

      if (rule.editionTypeReasons && rule.editionTypeReasons.length > 0) {
        const firstReason = rule.editionTypeReasons[0] != null ? rule.editionTypeReasons[0].reason : null
        let propertyName = null
        switch (firstReason) {
          case 'HasExternalFlights':
            propertyName = 'toolTipNotEditableAirline'
            break
          case 'IsAfterLimitTimeBeforeDeparture':
            propertyName = 'toolTipNotEditableTimeBeforeDeparture'
            break
          case 'EditionNoAllowedForAgeRange':
            propertyName = 'toolTipNotEditableAgeRange'
            break
        }
        if (propertyName !== null && tooltips[propertyName]) {
          tooltipText = tooltips[propertyName]
        }
      }
    }
    return tooltipText
  }

  _buildTooltip (disabled, tooltipText) {
    return disabled && tooltipText
      ? {
          text: tooltipText,
          html: '<div class="m-icon m-icon--information-circle c-tooltip__icon"></div>'
        }
      : null
  }
}

import { bool, objectOf, oneOfType, shape, string } from 'prop-types'

export const propTypes = {
  uid: string.isRequired,

  date: shape({
    label: string,
    icon: string,
    placeholder: string
  }).isRequired,

  acceptButtonText: string.isRequired,

  cancelButtonText: string.isRequired,

  /** Extra class names to be appended on root element */
  extraClasses: string,

  /** Extra attributes to be appended on root element */
  attributes: objectOf(
    oneOfType([
      bool,
      string
    ])
  )
}

export const defaultProps = {
  uid: '',
  date: {},
  acceptButtonText: '',
  cancelButtonText: ''
}

import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import { BookingFilterableServicesTemplate, ServicePriceTemplate } from './w-booking-filterable-services.template'
import { FilterableServicesWarningMessage } from './w-booking-filterable-services__warning-message.template'
import { CalculateServicePrice } from './service-helper'
import PriceFormatter from '../../../js/helpers/price-formatter'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import BookingFilterableServicesDataMapper from './data-mapper'
import { bookingItemEvents, bookingFilterableServicesEvents } from '../../../js/document/event-types'
import Swipe from '../../objects/swipe/main'
import { componentTypeEnum } from './enums'
import { getStyle, cssTimeToMs } from '../../../js/document/css'
import CalendarField from '../../components/calendar-field/main'
import { ReturnMissingDates } from './calendar-helper'
import OperationTypeMapper from './operationType-mapper'
import ParticipantIdsMapper from './participantIds-mapper'
import webStorage from '../../../js/document/web-storage'
import registeredEvents from '../../../js/helpers/registered-events'
import { cleanUpSpecialCharacters, toCamelCase } from '../../../js/helpers/string'
import { texts, attr, widgetApi, widgetQueries, classNames } from './constants'

/**
 * BookingFilterableServices widget
 */
export default class BookingFilterableServices extends BookingBox {
  constructor (element) {
    super(element)
    this.canSentEvents = false
    this.firstLoad = true
    this.element = element
    this.dataMapper = new BookingFilterableServicesDataMapper()
    this.serviceName = null

    const filterConfigurationElement = this.element.querySelector(widgetQueries.filterConfigurationElement)
    if (filterConfigurationElement) {
      const innerHtml = filterConfigurationElement.innerHTML
      const configuration = JSON.parse(innerHtml)
      this.filters = configuration.services
      this.texts = []
      this.texts[texts.saveButton] = configuration[texts.saveButton]
      this.texts[texts.cancelButton] = configuration[texts.cancelButton]
      this.texts[texts.emptyOptions] = configuration[texts.emptyOptions]
      this.texts[texts.serviceSelectedInfoText] = configuration[texts.serviceSelectedInfoText]
      this.texts[texts.noServiceSelected] = configuration[texts.noServiceSelected]
      this.texts[texts.selectServiceForFree] = configuration[texts.selectServiceForFree]
      this.texts[texts.occupancyValidationMessage] = configuration[texts.occupancyValidationMessage]
      this.texts[texts.timeChangedMessage] = configuration[texts.timeChangedMessage]
      this.configurations = {}
      this.configurations.price = configuration.priceConfiguration ? configuration.priceConfiguration : {}
      this.configurations.componentType = configuration.componentType ? configuration.componentType : componentTypeEnum.PerParticipant
      this.configurations.timeConfiguration = configuration.timeConfiguration ? configuration.timeConfiguration : {}
      // move this to service level!
      this.configurations.hideOptions = configuration.hideOptions ? configuration.hideOptions : false
    }

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.trackAttr) && { track: this.element.attributes[attr.trackAttr].value }
    })
  }

  /**
   *
   * @param {Object}    data      - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.canSentEvents = false
    this.data = data.response
    this._updateWidgetData()
    this.firstLoad = false
    return super.handleFetched(data.response)
  }

  getServiceName () {
    return this.serviceName || toCamelCase(widgetApi) || ''
  }

  _updateWidgetData () {
    let apiData
    let widgetData
    apiData = this.data.services && this.data.services.find(service => service.componentId === this.componentId)
    if (apiData) {
      this.serviceName = cleanUpSpecialCharacters(apiData.aName) || ''
      apiData = { ...apiData, ...{ participants: this.data.participants, rooms: this.data.rooms } }
      widgetData = this.dataMapper.mapWidgetData(apiData, this.filters, this.texts, this.configurations)
      this.rows = widgetData.rows
      this.filterDefaultValuesPerParticipant = widgetData.filterDefaultValuesPerParticipant
      const selectedOptions = apiData.options.filter(option => option.isSelected)
      if (selectedOptions.length > 0) {
        super.setSuccessStateAndUpdateTitle()
      }
    }

    if (widgetData) {
      this._showComponent()
      const renderedHtml = BookingFilterableServicesTemplate(widgetData)
      const newContent = elementFromString(renderedHtml)
      this._render(newContent)
      // IMPORTANT: Put always the 'initDocumentComponentsFromAPI' after the '_render', otherwise the events are not binded correctly.
      Component.initDocumentComponentsFromAPI(newContent)
      this._cleanEmptyRows(widgetData.emptyRows)
      this._applyCustomSafeMarginToFirstCollapseElement()
      this._initCalendarFields()
      this._attachEvents()
      this._mapApisToRows()
    } else {
      super.hideComponent()
    }
    setTimeout(() => {
      this.canSentEvents = true
    }, 500)
  }

  _applyCustomSafeMarginToFirstCollapseElement () {
    const scrollable = this.element.querySelector(widgetQueries.scrollable)
    if (scrollable) {
      const scrollableElementClientRect = scrollable.getBoundingClientRect()
      const elementClientRect = this.element.getBoundingClientRect()
      const safeMargin = scrollableElementClientRect.top - elementClientRect.top
      const scrollableApi = scrollable && scrollable[widgetQueries.collapseApi]
      scrollableApi && scrollableApi.setProp('scrollableSafeMargin', safeMargin)
    }
  }

  _initCalendarFields () {
    const dateElements = [...this.element.querySelectorAll(widgetQueries.dateFilters)]
    dateElements.forEach(element => {
      const calendarDataModelElement = element.querySelector(widgetQueries.dateCalendarDataModel)
      const innerHtml = calendarDataModelElement.innerHTML
      const calendarObject = JSON.parse(innerHtml)
      const widgetElement = element.querySelector(widgetQueries.calendarField)

      return new CalendarField(widgetElement, { calendarDataModelObject: { attributes: calendarObject } })
    })
  }

  /**
   * Attach events:
   *  - to each filter, so when a filter changed a function is called
   *  - to Accept button, when clicked an event is raised so mediator can execute a call to the backend
   *  - to Cancel button, when clicked collapse is closed
   */
  _attachEvents () {
    Swipe.CreateInstancesOnDocument()
    this._attachEvent(widgetQueries.numberStepperFilters, widgetQueries.numberStepperApi, 'propChanged')
    this._attachEvent(widgetQueries.buttonGroupFilters, widgetQueries.buttonGroupApi, 'changeActiveElement')
    this._attachEvent(widgetQueries.choiceListFilters, widgetQueries.choiceListApi, 'changeOptions')
    this._attachEvent(widgetQueries.tabNavFilters, widgetQueries.tabNavApi, 'propChanged')
    this._attachEvent(widgetQueries.dropdownFilters, widgetQueries.dropdownApi, 'changedOptions')
    this._attachEvent(widgetQueries.calendarField, widgetQueries.calendarFieldApi, 'selectedDatesChanged')

    // Quantity component
    const quantityElements = [...this.element.querySelectorAll(widgetQueries.quantityComponent)]
    this.quantityElementApis = quantityElements.map(quantityElement => quantityElement[widgetQueries.numberStepperApi])
    this.quantityElementApis.forEach(quantityElementApi => {
      quantityElementApi.events.on('propChanged', (eventArgs) => { this._quantityChanged(eventArgs, quantityElementApi) })
    })

    // ParticipantsAsOptions
    const participantsAsOptionschoiceListElements = [...this.element.querySelectorAll(widgetQueries.choiceListParticipantsAsOptions)]
    this.participantAsOptionElementApis = participantsAsOptionschoiceListElements.map(participantAsOptionElement => participantAsOptionElement[widgetQueries.choiceListApi])
    this.participantAsOptionElementApis.forEach(participantAsOptionElementApi => {
      participantAsOptionElementApi.events.on('changeOptions', (eventArgs) => { this._participantAsOptionChanged(eventArgs, participantAsOptionElementApi) })
    })

    const acceptButtons = [...this.element.querySelectorAll(widgetQueries.acceptButtons)]
    this.acceptButtonApis = acceptButtons.map(acceptButton => acceptButton[widgetQueries.buttonApi])
    this.acceptButtonApis.forEach(acceptButton => {
      acceptButton.events.on('clickButton', (eventArgs) => {
        const row = this._getCurrentRowByElement(acceptButton.element)
        this._eventCustomerInteractionEmitter(bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_SAVE_BUTTON, this._extractServiceType(row), 'button', 'save')
        this._optionChanged(eventArgs, acceptButton.element)
      })
    })

    const cancelButtons = [...this.element.querySelectorAll(widgetQueries.cancelButtons)]
    this.cancelButtonApis = cancelButtons.map(cancelButton => cancelButton[widgetQueries.buttonApi])
    this.cancelButtonApis.forEach(cancelButton => {
      cancelButton.events.on('clickButton', (eventArgs) => { this._optionCancelled(eventArgs, cancelButton.element) })
    })

    // Switchs
    const switches = [...this.element.querySelectorAll(widgetQueries.switch)]
    switches.forEach(currentSwitch => {
      currentSwitch.addEventListener('click', (eventArgs) => { this._switchClicked(eventArgs, eventArgs.target) })
    })

    const containers = [...this.element.querySelectorAll(widgetQueries.container)]
    containers.forEach(container => {
      container.addEventListener('click', (eventArgs) => { this._containerClicked(eventArgs, eventArgs.target) })
    })
  }

  _containerClicked (eventArgs, element) {
    if (!this._elementIsSwitchRelated(element)) {
      const row = this._getCurrentRowByElement(element)
      this._openContainers(row)
    }
  }

  _elementIsSwitchRelated (element) {
    return element.classList.contains(classNames.switchCheckbox) || element.classList.contains(classNames.switchCircle)
  }

  _closeContainers (row) {
    this._emitEventOnceToggled(row)
    if (row.collapseApi) {
      row.collapseApi.close()
    }
    if (row.sideDrawerApi) {
      row.sideDrawerApi.close()
    }
  }

  _openContainers (row) {
    if (row.collapseApi) {
      row.collapseApi.toggle()
    }
    if (row.sideDrawerApi) {
      row.sideDrawerApi.toggle()
    }

    this._emitEventOnceToggled(row)
  }

  _emitEventOnceToggled (row) {
    const isOpen = row.collapseApi.isOpened() || row.sideDrawerApi.getProp('open')
    if (row.collapseApi || row.sideDrawerApi) {
      this._eventCustomerInteractionEmitter(bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_CONTAINER_TOGGLE, this._extractServiceType(row), 'accordion', isOpen)
    }
  }

  _extractServiceType (row) {
    return row.options[0].serviceType
  }

  _attachEvent (querySelector, apiSelector, eventName) {
    const filterElements = [...this.element.querySelectorAll(querySelector)]
    const filterApis = filterElements.map(element => element[apiSelector])
    filterApis.forEach(filterApi => {
      filterApi && filterApi.events.on(eventName, () => {
        this._raiseCustomerEvent(filterApi)
        this._filterChanged(filterApi.element)
      })
    })
  }

  _updateButtonGroupFilter (row, filter, lastSelectedValue) {
    filter.values.forEach(filterValue => {
      const filterValueElement = filter.api.element.querySelector(`[data-w-booking-filterable-services__button-group-value="${filterValue}"]`)
      filterValueElement.disabled = !filter.availableValues.includes(filterValue)
    })

    let indexOfValueToShow = 0
    if (lastSelectedValue) {
      indexOfValueToShow = filter.availableValues.indexOf(lastSelectedValue.toString())
    }
    const selectedOption = filter.availableValues[indexOfValueToShow > 0 ? indexOfValueToShow : 0]
    const disabledMissingOptions = filter.values.filter(x => !filter.availableValues.includes(x))
    const buttonsInGroup = [...filter.api.element.querySelectorAll('[data-w-booking-filterable-services__button-group-value]')]
    const selectedButtonIndex = buttonsInGroup.findIndex(button => button.dataset.wBookingFilterableServices__buttonGroupValue === selectedOption)
    const activeElement = buttonsInGroup.find(button => button.dataset.wBookingFilterableServices__buttonGroupValue === selectedOption)

    buttonsInGroup.forEach(button => {
      button.disabled = !!((disabledMissingOptions.length > 0 && disabledMissingOptions.includes(button.dataset.wBookingFilterableServices__buttonGroupValue)))
    })

    if (selectedButtonIndex > -1) {
      filter.api.setProp('activeIndex', selectedButtonIndex)
      filter.api.setProp('activeElement', activeElement, { forceUpdate: true })
    }
    return selectedOption
  }

  _updateTabsFilterValues (row, filter, lastSelectedValue) {
    filter.values.forEach(filterValue => {
      const filterValueElement = this.element.querySelector(`[${attr.filterId}="${filter.filterId}"`).querySelector(`[${attr.tabsId}="${filterValue}"]`).parentElement
      filterValueElement.style.display = filter.availableValues.includes(filterValue) ? '' : 'none'
    })

    // Update filter with first available value. To be improved with last selected value if available
    let indexOfValueToShow = 0
    if (lastSelectedValue) {
      indexOfValueToShow = filter.availableValues.indexOf(lastSelectedValue)
    }
    const selectedOption = filter.availableValues[indexOfValueToShow > 0 ? indexOfValueToShow : 0]
    filter.api.setProp('currentTab', selectedOption || lastSelectedValue)
    return selectedOption
  }

  _updateNumberStepperFilterValues (row, filter, lastSelectedValue) {
    if (filter.availableValues && filter.availableValues.length > 0) {
      const minValue = Math.min(...filter.availableValues)
      const maxValue = Math.max(...filter.availableValues)
      if (lastSelectedValue > maxValue) {
        filter.api.setProp('value', maxValue, { silent: this.firstLoad })
      } else {
        filter.api.setProp('value', parseInt(lastSelectedValue), { silent: this.firstLoad })
      }
      filter.api.setProp('minValue', minValue)
      filter.api.setProp('maxValue', maxValue)
    }

    return filter.api.getProp('value')
  }

  _updateChoiceListFilterValues (row, filter, lastSelectedValue) {
    const availableOptions = filter.values.map(value => {
      return {
        text: this._mapFilterTextItem(value, filter.texts),
        checked: false,
        value,
        disabled: (filter.availableValues.findIndex(availableValue => availableValue === value) < 0)
      }
    })

    if (lastSelectedValue && filter.availableValues.findIndex(value => value === lastSelectedValue) >= 0) {
      availableOptions.find(availableOption => availableOption.value === lastSelectedValue).checked = true
    } else {
      const firstAvailableOptionNotDisabled = availableOptions.find(availableOption => filter.availableValues.findIndex(availableValue => availableValue === availableOption.value) >= 0)
      if (firstAvailableOptionNotDisabled) {
        firstAvailableOptionNotDisabled.checked = true
      }
    }
    const optionsToBeUpdated = availableOptions.map(availableOption => {
      const option = filter.api.getProp('options').find(option => option.value === availableOption.value)
      if (option) {
        return { ...option, ...availableOption }
      }
      return availableOption
    })

    filter.api.setProp('options', optionsToBeUpdated)
    const selectedOption = filter.api.getProp('options').find(option => option.checked)

    return selectedOption && selectedOption.value
  }

  _mapFilterTextItem = (filterValue, texts = null) => {
    if (!texts) { return filterValue }
    const textToBeReplaced = texts.find(text => text.key === filterValue)
    return (textToBeReplaced && textToBeReplaced.value) || filterValue
  }

  _updateDropdownFilterValues (row, filter, lastSelectedValue) {
    const availableOptions = filter.values.map(value => { return { text: value, id: value, value, disabled: !filter.availableValues.includes(value) } })

    filter.api.setProp('options', availableOptions)

    let indexOfValueToShow = 0
    if (lastSelectedValue) {
      filter.api.setProp('value', lastSelectedValue)
      indexOfValueToShow = filter.availableValues.indexOf(lastSelectedValue)
    }
    const selectedOption = filter.availableValues[indexOfValueToShow > 0 ? indexOfValueToShow : 0]
    return selectedOption
  }

  _updateDateFilterValues (row, filter, lastSelectedValue) {
    const { cid, ...newAttributes } = filter.api.selection.attributes
    const missingDatesFromOption = ReturnMissingDates(filter.values)
    const notAvailableDatesForCurrentSelection = filter.values.filter(x => !filter.availableValues.includes(x))
    let attributes
    let selectedDate
    if (lastSelectedValue && filter.availableValues.includes(lastSelectedValue)) {
      selectedDate = lastSelectedValue
      attributes = { ...newAttributes, selectedDate: lastSelectedValue, selectedDates: [lastSelectedValue], disabledDates: [...missingDatesFromOption, ...notAvailableDatesForCurrentSelection] }
    } else {
      selectedDate = filter.values[0]
      attributes = { ...newAttributes, selectedDate, selectedDates: [selectedDate], disabledDates: [...newAttributes.disabledDates, lastSelectedValue] }
    }

    filter.api.selection.setAttributes(attributes)
    return selectedDate
  }

  _setButtonGroupValue (filter, value) {
    const buttonsInGroup = [...filter.api.element.querySelectorAll('[data-w-booking-filterable-services__button-group-value]')]
    const selectedButtonIndex = buttonsInGroup.findIndex(button => button.dataset.wBookingFilterableServices__buttonGroupValue === value)
    if (selectedButtonIndex > -1) {
      filter.api.setProp('activeIndex', selectedButtonIndex)
      filter.api.setProp('activeElement', buttonsInGroup.find(button => button.dataset.wBookingFilterableServices__buttonGroupValue === value), { forceUpdate: true })
    }
  }

  _setTabsFilterValues (filter, value) {
    if (filter.values && filter.values.length > 0 && filter.values.includes(value)) {
      filter.api.setProp('currentTab', value)
    }
  }

  _setNumberStepperValue (filter, value) {
    if (filter.values && filter.values.length > 0 && filter.values.includes(value)) {
      filter.api.setProp('value', parseInt(value))
    }
  }

  /**
   * Collects the APIs of the components and maps them into its specific row
   */
  _mapApisToRows () {
    // Filters
    this._mapFilterApi(widgetQueries.numberStepperFilters, widgetQueries.numberStepperApi, this._getNumberStepperValue, this._setNumberStepperValue.bind(this), this._updateNumberStepperFilterValues.bind(this))
    this._mapFilterApi(widgetQueries.buttonGroupFilters, widgetQueries.buttonGroupApi, this._getButtonGroupValue, this._setButtonGroupValue.bind(this), this._updateButtonGroupFilter.bind(this))
    this._mapFilterApi(widgetQueries.choiceListFilters, widgetQueries.choiceListApi, this._getChoiceListValue, null, this._updateChoiceListFilterValues.bind(this))
    this._mapFilterApi(widgetQueries.tabNavFilters, widgetQueries.tabNavApi, this._getTabsValue, this._setTabsFilterValues.bind(this), this._updateTabsFilterValues.bind(this))
    this._mapFilterApi(widgetQueries.dropdownFilters, widgetQueries.dropdownApi, this._getDropdownValue, null, this._updateDropdownFilterValues.bind(this))
    this._mapFilterApi(widgetQueries.calendarField, widgetQueries.calendarFieldApi, this._getDateValue, null, this._updateDateFilterValues.bind(this))

    // Quantity component
    const quantityElements = [...this.element.querySelectorAll(widgetQueries.quantityComponent)]
    if (quantityElements) {
      quantityElements.forEach(element => {
        const serviceId = element.attributes[attr.serviceId].value
        this.rows[serviceId].quantityApi = element[widgetQueries.numberStepperApi]
      }, this)
    }

    // Time Selector
    const timeSelectorElements = [...this.element.querySelectorAll(widgetQueries.timeSelector)]
    if (timeSelectorElements) {
      timeSelectorElements.forEach(element => {
        const serviceId = element.attributes[attr.serviceId].value
        this.rows[serviceId].timeSelectorApi = element[widgetQueries.dropdownApi]
        const timeOptions = this.rows[serviceId].timeSelectorApi.getProp('options').map((option, idx) => ({ ...option, ...{ selected: this.rows[serviceId].selectedOption ? option.value === this.rows[serviceId].selectedOption.time : idx === 0 } }))
        this.rows[serviceId].timeSelectorApi.setProp('options', timeOptions)
        this.rows[serviceId].timeSelectorApi.setProp('value', timeOptions.find(timeOption => timeOption.selected).value)
      }, this)
    }

    // ParticipantsAsOptions
    const participantsAsOptionschoiceListElements = [...this.element.querySelectorAll(widgetQueries.choiceListParticipantsAsOptions)]
    if (participantsAsOptionschoiceListElements) {
      participantsAsOptionschoiceListElements.forEach(element => {
        const serviceId = element.attributes[attr.serviceId].value
        this.rows[serviceId].participantsAsOptionsApi = element[widgetQueries.choiceListApi]
        this.rows[serviceId].additionalTexts = element.querySelectorAll(widgetQueries.participantAsOptionAdditionalText)
      }, this)
    }

    // collapse
    const collapseElements = [...this.element.querySelectorAll(widgetQueries.collapse)]
    collapseElements.forEach(element => {
      const serviceId = element.attributes[attr.serviceId].value
      this.rows[serviceId].collapseApi = element[widgetQueries.collapseApi]
    }, this)

    const sideDrawerElements = [...this.element.querySelectorAll(widgetQueries.sideDrawer)]
    sideDrawerElements.forEach(element => {
      const serviceId = element.closest(`[${attr.serviceId}]`).dataset[attr.datasetServiceId]
      this.rows[serviceId].sideDrawerApi = element[widgetQueries.sideDrawerApi]
    }, this)

    // Information labels
    const informationElements = [...this.element.querySelectorAll(widgetQueries.filterInfo)]
    informationElements.forEach(element => {
      const serviceId = element.closest(`[${attr.serviceId}]`).dataset[attr.datasetServiceId]
      this.rows[serviceId].filterInfoSection = element
      this.rows[serviceId].filterInfoPrice = element.querySelector(widgetQueries.filterInfoPrice)
      this.rows[serviceId].filterInfoDescription = element.querySelector(widgetQueries.filterInfoDescription)
    })

    const acceptButtons = [...this.element.querySelectorAll(widgetQueries.acceptButtons)]
    acceptButtons.forEach(element => {
      const serviceId = element.closest(`[${attr.serviceId}]`).dataset[attr.datasetServiceId]
      this.rows[serviceId].buttonsSection = element
      this._enableAcceptButton(this.rows[serviceId])
    })

    // Empty options message
    const emptyMessageElements = [...this.element.querySelectorAll(widgetQueries.emptyMessage)]
    emptyMessageElements.forEach(element => {
      const serviceId = element.closest(`[${attr.serviceId}]`).dataset[attr.datasetServiceId]
      this.rows[serviceId].emptyMessage = element
      element.classList.add(classNames.hidden)
    })

    // Validation message
    const validationMessageElements = [...this.element.querySelectorAll(widgetQueries.validationMessages)]
    validationMessageElements.forEach(element => {
      const serviceId = element.closest(`[${attr.serviceId}]`).dataset[attr.datasetServiceId]
      this.rows[serviceId].validationMessages = element
    })

    // Time message
    const timeMessageElements = [...this.element.querySelectorAll(widgetQueries.timeMessages)]
    timeMessageElements.forEach(element => {
      const serviceId = element.closest(`[${attr.serviceId}]`).dataset[attr.datasetServiceId]
      this.rows[serviceId].timeMessages = element
    })

    // Time Selector
    this.timeSelectorElementApis = timeSelectorElements.map(timeSelectorElement => timeSelectorElement[widgetQueries.dropdownApi])
    this.timeSelectorElementApis.forEach(timeSelector => {
      timeSelector.events.on('changedOptions', (eventArgs) => { this._toggleTimeChangedMessage(this._getCurrentRowByElement(timeSelector.element), true) })
    })

    // results
    const choiceListOptionsElements = [...this.element.querySelectorAll(widgetQueries.choiceListServiceOptions)]
    choiceListOptionsElements.forEach(element => {
      const serviceId = element.attributes[attr.serviceId].value
      const resultsApi = element[widgetQueries.choiceListApi]
      this.rows[serviceId].resultsApi = resultsApi
      resultsApi.events.on('changeOptions', () => {
        this._selectedOptionChanged(this.rows[serviceId])
        this._selectedOptionEvent(this.rows[serviceId])
      })

      this._applyFiltersAndSelectedOption(element, this.rows[serviceId])
    }, this)
  }

  _selectedOptionEvent (row) {
    if (this.canSentEvents && !this.firstLoad) {
      const selectedOption = row.resultsApi.getProp('options').find(option => option.checked)
      this._eventCustomerInteractionEmitter(bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_CHOICE_LIST, this._extractServiceType(row), 'radio button', selectedOption.text)
    }
  }

  _applyFiltersAndSelectedOption (element, row) {
    const filterKeys = Object.keys(row.filters)
    let selectedOption = row.resultsApi.getProp('options').find(option => option.checked)

    // If not selectedOption Check if exists in SessionStorage
    if (!selectedOption) {
      const savedData = this._retrieveDataFromStorage()
      if (savedData) {
        const currentRowSelectedOptionCode = savedData[row.uid]
        selectedOption = row.options.find(option => option.code === currentRowSelectedOptionCode)
      }
    }

    if (selectedOption) {
      const optionFilterValues = this._getOptionSelectedValues(selectedOption, row)
      this._updateFilterShownValues(row, optionFilterValues)
    }
    if (filterKeys.length > 0) {
      const firstFilterKey = filterKeys[0]
      const firstFilterElement = row.filters[firstFilterKey].api.element
      this._filterChanged(firstFilterElement)
    } else {
      this._applyFilters(element, this._getCurrentRowByElement(element))
    }
  }

  _updateFilterShownValues (row, filtersValues) {
    const filtersArray = this._getRowFiltersAsArray(row)
    filtersArray.forEach(currentFilter => {
      const currentValue = filtersValues[currentFilter.columnName]
      if (currentValue && currentFilter.setValue && currentFilter.setValue !== null) {
        currentFilter.setValue(currentFilter, currentValue)
      }
    })
  }

  _selectedOptionChanged (row) {
    const selectedOption = row.resultsApi.getProp('options').find(option => option.checked)
    let quantity
    if (row.quantityApi && row.quantityApi.getProp('value')) {
      quantity = row.quantityApi.getProp('value')
    } else if (row.participantsAsOptionsApi && this._checkIfCurrentSelectedOptionServiceTypeIsPerParticipant(selectedOption)) {
      quantity = row.participantsAsOptionsApi.getProp('options').filter(option => option.checked).length
    } else {
      quantity = 1
    }
    const price = CalculateServicePrice(quantity, selectedOption.price)
    const renderedHtml = ServicePriceTemplate(price, this.configurations.price)

    if (row.additionalTexts && row.additionalTexts.length > 0) {
      row.additionalTexts.forEach(additionalText => {
        if (row.participantsAsOptionsApi && this._checkIfCurrentSelectedOptionServiceTypeIsPerUnit(selectedOption)) {
          additionalText.innerHTML = '-'
          additionalText.classList.add(classNames.hidden)
        } else {
          additionalText.innerHTML = `+ ${PriceFormatter.toFormattedText(selectedOption.price, this.configurations.price)}`
          additionalText.classList.remove(classNames.hidden)
        }
      })
    }

    let hideMessage = false
    if (this._checkIfCurrentSelectedOptionServiceTypeIsPerUnit(selectedOption)) {
      const participantsChecked = row.participantsAsOptionsApi ? row.participantsAsOptionsApi.getProp('options').filter(option => option.checked).length : 0
      hideMessage = participantsChecked >= selectedOption.occupancy.min && participantsChecked <= selectedOption.occupancy.max
    }
    this._toggleOccupancyValidationMessage(row, selectedOption, hideMessage)

    this._enableAcceptButton(row)

    row.filterInfoPrice.innerHTML = renderedHtml
    row.filterInfoDescription.innerHTML = selectedOption.text
  }

  _mapFilterApi (querySelector, apiSelector, getValueFunction, setValueFunction, updateFilterValuesFunction) {
    const filterElements = [...this.element.querySelectorAll(querySelector)]

    filterElements.forEach(element => {
      const rowId = element.closest(`[${attr.serviceId}]`).dataset[attr.datasetServiceId]
      const elementId = this._findElementId(element)
      this.rows[rowId].filters[elementId].api = element[apiSelector]
      this.rows[rowId].filters[elementId].getValue = getValueFunction
      this.rows[rowId].filters[elementId].setValue = setValueFunction
      this.rows[rowId].filters[elementId].updateFilter = updateFilterValuesFunction
    }, this)
  }

  _findElementId (element) {
    return element.id || element.querySelector('input, select').id
  }

  _getNumberStepperValue (filter) {
    return filter && filter.api && filter.api.props.value ? parseInt(filter.api.getProp('value')) : null
  }

  _getButtonGroupValue (filter) {
    let filterValue
    if (filter && filter.api && filter.api.props && filter.api.getProp('activeElement')) {
      const activeElement = filter.api.getProp('activeElement')
      filterValue = activeElement.dataset[attr.buttonGroupValue]
    }
    return filterValue
  }

  _getChoiceListValue (filter) {
    let filterValue
    if (filter && filter.api && filter.api.getProp('options')) {
      const selectedOption = filter.api.getProp('options').find(option => option.checked)
      if (selectedOption) filterValue = selectedOption.value
    }
    return filterValue
  }

  _getTabsValue (filter) {
    return filter && filter.api ? filter.api.getProp('currentTab') : null
  }

  _getDropdownValue (filter) {
    return filter && filter.api ? filter.api.getProp('value') : null
  }

  _getDateValue (filter) {
    return filter && filter.api && filter.api.selection.attributes ? filter.api.selection.attributes.selectedDate : null
  }

  _raiseCustomerEvent (filterApi) {
    const row = this._getCurrentRowByElement(filterApi.element)
    const elementId = this._findElementId(filterApi.element)

    this._eventFilterEmitter(filterApi, 'sportType', bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_FILTER_SPORT, 'button group', row, elementId)
    this._eventFilterEmitter(filterApi, 'duration', bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_FILTER_DURATION, 'button group', row, elementId)
    this._eventFilterEmitter(filterApi, 'materialCategory', bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_FILTER_MATERIAL_CATEGORY, 'tab', row, elementId)
    this._eventFilterEmitter(filterApi, 'sportLevel', bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_FILTER_SPORT_LEVEL, 'radio button', row, elementId)
  }

  _eventFilterEmitter (filterApi, filterTypeExpected, eventName, element, row, elementId) {
    const filterType = filterApi.element.attributes[attr.filterType].value
    const filter = row.filters[elementId]
    const result = filter.getValue(filter)
    if (result && filterType === filterTypeExpected) {
      this._eventCustomerInteractionEmitter(eventName, this._extractServiceType(row), element, result)
    }
  }

  _eventCustomerInteractionEmitter (eventName, serviceType, element, result) {
    if (this.canSentEvents && !this.firstLoad) {
      this.canSentEvents = false
      setTimeout(() => {
        this.events.emit(eventName, {
          serviceType,
          element,
          result
        })
        this.canSentEvents = true
      }, 500)
    }
  }

  _filterChanged (element) {
    if (element) {
      const row = this._getCurrentRowByElement(element)
      const elementId = this._findElementId(element)
      // refresh available filters
      this._refreshFilters(row, elementId)
      const startDate = this._getDefaultStartDate(row, this.filterDefaultValuesPerParticipant) || null
      this._applyFilters(element, row, startDate)
      this._refreshTimeOptions(row)
      this._enableAcceptButton(row)
    }
  }

  _refreshTimeOptions (row, participantIds = []) {
    this._toggleTimeChangedMessage(row, true)
    if (!row.selectedOption && row.timeSelectorApi) {
      // 1- Read the selected filters in the current row
      const currentRowSelectedValues = this._getFilterSelectedValues(row)
      // 2- Read the values of the selected options from all rows
      const selectedOptions = Object.values(this.rows).map(row => row.selectedOption).filter(option => option !== null)
      // 3- If the filters in the current row match all the values in the previous rows take the time.
      const filteredSelectedOptions = this._checkIfAnySelectedOptionExists(selectedOptions, { ...currentRowSelectedValues })
      const selectedTimes = filteredSelectedOptions.filter(option => option.assignedParticipants.some(assigned => participantIds.includes(assigned))).map(option => option.time)

      // 4- Disable the specific time.
      const updatedTimeOptions = row.timeSelectorApi.getProp('options').map(option => ({ ...option, ...{ disabled: selectedTimes.includes(option.value) } }))
      row.timeSelectorApi.setProp('options', updatedTimeOptions)

      // 5- If time disabled time is selected, select the first one not disabled
      if (selectedTimes.includes(row.timeSelectorApi.getProp('value'))) {
        row.timeSelectorApi.setProp('value', updatedTimeOptions.find(timeOption => !timeOption.disabled).value)
        this._toggleTimeChangedMessage(row, selectedTimes.includes(row.timeSelectorApi.getProp('value')))
      }
    }
  }

  _toggleTimeChangedMessage (row, hideMessage = false) {
    if (!hideMessage) {
      const text = this.texts[texts.timeChangedMessage] ? this.texts[texts.timeChangedMessage] : ''
      row.timeMessages.innerHTML = FilterableServicesWarningMessage(text)
    } else {
      row.timeMessages.innerHTML = ''
    }
  }

  _checkIfAnySelectedOptionExists (selectedOptions, filtersObject) {
    const matchedFilteredOptions = selectedOptions.filter(item => {
      for (const key in filtersObject) {
        if (item[key] === undefined || item[key] !== filtersObject[key]) { return false }
      }
      return true
    })
    return matchedFilteredOptions
  }

  // if there is min/max occupancy for the service, means that the service is configured perUnit in Davinci
  // if there isn't min/max occupancy configured, the service is configured perParticipant
  _checkIfCurrentSelectedOptionServiceTypeIsPerParticipant (selectedOption) {
    return selectedOption && !selectedOption.occupancy
  }

  // if there is min/max occupancy for the service, means that the service is configured perUnit in Davinci
  // if there isn't min/max occupancy configured, the service is configured perParticipant
  _checkIfCurrentSelectedOptionServiceTypeIsPerUnit (selectedOption) {
    return selectedOption && selectedOption.occupancy
  }

  _participantAsOptionChanged (eventArgs, api) {
    const element = api.element
    if (element) {
      const row = this._getCurrentRowByElement(element)
      if (row) {
        this._enableAcceptButton(row)
        const selectedOption = row.resultsApi.getProp('options').find(option => option.checked)
        const participantIds = row.participantsAsOptionsApi && row.participantsAsOptionsApi.getProp('options') ? row.participantsAsOptionsApi.getProp('options').filter(option => option.checked) : []
        this._refreshTimeOptions(row, participantIds.map(participant => parseInt(participant.value)))
        const quantity = this._checkIfCurrentSelectedOptionServiceTypeIsPerParticipant(selectedOption) ? participantIds.length : 1
        const price = CalculateServicePrice(quantity, selectedOption.price)
        const renderedHtml = ServicePriceTemplate(price, this.configurations.price)
        row.filterInfoPrice.innerHTML = renderedHtml
      }
    }
  }

  _enableAcceptButton (row) {
    const optionSelected = row.resultsApi ? row.resultsApi.getProp('options').find(option => option.checked) : null
    let enableAcceptButton = true
    if (row.participantsAsOptionsApi) {
      const participantOptions = row.participantsAsOptionsApi.getProp('options')
      const participantsChecked = participantOptions ? participantOptions.filter(option => option.checked).length : 0

      enableAcceptButton = participantsChecked > 0
      if (this._checkIfCurrentSelectedOptionServiceTypeIsPerUnit(optionSelected)) {
        enableAcceptButton = participantsChecked >= optionSelected.occupancy.min && participantsChecked <= optionSelected.occupancy.max
        this._toggleOccupancyValidationMessage(row, optionSelected, enableAcceptButton)
      }
    } else if (row.quantityApi) {
      if (row.selectedOption) {
        enableAcceptButton = (row.quantityApi.getProp('value')) !== (optionSelected && optionSelected.quantity ? optionSelected.quantity : null)
      }
    } else {
      enableAcceptButton = (row.selectedOption && row.selectedOption.value ? row.selectedOption.value : null) !== (optionSelected && optionSelected.value ? optionSelected.value : null)
    }
    row.buttonsSection.disabled = !enableAcceptButton
  }

  _toggleOccupancyValidationMessage (row, optionSelected, hideMessage = false) {
    if (this._checkIfCurrentSelectedOptionServiceTypeIsPerUnit(optionSelected) && !hideMessage) {
      const text = this.texts[texts.occupancyValidationMessage]
        ? this.texts[texts.occupancyValidationMessage]
          .replace(/{MIN}/ig, optionSelected.occupancy.min)
          .replace(/{MAX}/ig, optionSelected.occupancy.max)
        : ''

      row.validationMessages.innerHTML = FilterableServicesWarningMessage(text)
    } else {
      row.validationMessages.innerHTML = ''
    }
  }

  _quantityChanged (eventArgs, api) {
    const element = api.element
    if (element) {
      const row = this._getCurrentRowByElement(element)
      if (row) {
        const selectedOption = row.resultsApi.getProp('options').find(option => option.checked)
        const quantity = row.quantityApi && row.quantityApi.getProp('value') ? row.quantityApi.getProp('value') : 0
        const price = CalculateServicePrice(quantity, selectedOption.price)
        const renderedHtml = ServicePriceTemplate(price, this.configurations.price)
        row.filterInfoPrice.innerHTML = renderedHtml
        this._enableAcceptButton(row)
      }
    }
  }

  _applyFilters (element, row, startDate) {
    if (element && row) {
      // 1 for each filter in the ROW, get filter value and filter column name
      const selectedValues = this._getFilterSelectedValues(row)

      // 2 filter options with all filter values
      const sourceOptions = this._getFilteredOptions(row.options, selectedValues)
      const options = this.dataMapper.mapOptions(sourceOptions, row.selectedOption, row.noneOption, row.participantId, this.configurations, { startDate })
      // 3 select an option (ensure at least 1 option is selected)
      let selectedValue = options.find(option => option.checked && (!option.assignedParticipants || option.assignedParticipants.includes(row.participantId)))
      if (!selectedValue && options.length > 0) {
        // if not option is selected, check if its saved in sessionStorage
        let optionIndex = 0
        const savedData = this._retrieveDataFromStorage()
        const currentRowSelectedOptionCode = savedData ? savedData[row.uid] : null
        if (savedData && currentRowSelectedOptionCode) {
          const previousSelectedOptionIndex = options.findIndex(option => option.code === currentRowSelectedOptionCode)
          optionIndex = previousSelectedOptionIndex >= 0 ? previousSelectedOptionIndex : optionIndex
        }
        options[optionIndex].checked = true
        selectedValue = options[optionIndex]
      }

      if (options.length > 0) {
        this._handleEmptySelection(row, false)
        // 4 Update choicelist with filtered options and information labels
        row.resultsApi.setProp('options', options)
        this._selectedOptionChanged(row)
      } else {
        this._handleEmptySelection(row, true)
      }
    }
  }

  _getDefaultStartDate (row, filterDefaultValuesPerParticipant) {
    const defaultDurationValuesParticipant = filterDefaultValuesPerParticipant.find(defaultValue => (defaultValue.columnName === 'duration' && defaultValue.participantId === row.participantId))
    const filteredOptions = row.options && row.options.filter(option => option.duration === (defaultDurationValuesParticipant && defaultDurationValuesParticipant.defaultValue))
    const startDate = (!row.selectedOption && filteredOptions.length > 0) ? (defaultDurationValuesParticipant && defaultDurationValuesParticipant.startDateDefaultValue) : this._getPreviousStartDate(row)
    return startDate
  }

  _getPreviousStartDate (row) {
    const previousOptions = (row && row.resultsApi) ? row.resultsApi.getProp('options') : null
    const previousSelectedOption = previousOptions ? previousOptions.filter(option => option.checked === true)[0] : null
    return previousSelectedOption ? previousSelectedOption.startDate : null
  }

  _handleEmptySelection (row, isEmpty) {
    if (isEmpty) {
      if (row.emptyMessage) row.emptyMessage.classList.remove(classNames.hidden)

      row.resultsApi.element.classList.add(classNames.hidden)
      row.filterInfoSection.classList.add(classNames.hidden)
      row.buttonsSection.classList.add(classNames.isEmpty)
    } else {
      if (row.emptyMessage) row.emptyMessage.classList.add(classNames.hidden)

      row.resultsApi.element.classList.remove(classNames.hidden)
      row.filterInfoSection.classList.remove(classNames.hidden)
      row.buttonsSection.classList.remove(classNames.isEmpty)
    }
  }

  _getFilterSelectedValues (row) {
    const values = []

    Object.keys(row.filters).forEach(key => {
      const filter = row.filters[key]
      if (filter.getValue !== undefined) {
        const filterValue = filter.getValue(filter)
        if (filterValue) {
          values[filter.columnName] = filterValue.toString()
        }
      }
    }, this)

    return values
  }

  _getOptionSelectedValues (option, row) {
    const values = []
    Object.keys(row.filters).forEach(key => {
      const filter = row.filters[key]
      const columnName = filter.columnName
      const currentOption = row.options.find(currentOption => (option.value === currentOption.value))
      values[columnName] = currentOption[columnName].toString()
    })

    return values
  }

  _getFilteredOptions (options, selectedValues) {
    Object.keys(selectedValues).forEach(columnName => {
      const selectedValue = selectedValues[columnName]
      options = this._filterOptions(options, columnName, selectedValue)
    }, this)

    return options
  }

  _filterOptions (options, columnName, columnValue) {
    const filteredOptions = options.filter(function (option) {
      if (columnName && columnValue) {
        return option[columnName].toString() === columnValue.toString()
      } else {
        return option[columnName] === columnValue
      }
    })
    return filteredOptions
  }

  _refreshFilters (row, filterId) {
    const selectedValues = this._getFilterSelectedValues(row)
    const filterArray = this._getRowFiltersAsArray(row)
    let afterChangedFilter = false
    const currentFilterSelection = {}

    filterArray.forEach(filter => {
      // Update the selected values so far in the filters hierarchy
      currentFilterSelection[filter.columnName] = selectedValues[filter.columnName]
      // By default, the available values are all the values of the filter
      filter.availableValues = [...filter.values]

      if (afterChangedFilter) {
        const filterSelectedValues = { ...currentFilterSelection }
        let useSavedFilterValue = false
        // If we are in a lower level than the changed filter, check if all the values of the next filters are available
        filter.values.forEach(filterValue => {
          const tempFilterSelectedValues = { ...filterSelectedValues, [filter.columnName]: filterValue }
          const filteredOptions = this._getFilteredOptions(row.options, tempFilterSelectedValues)

          if (filteredOptions.length === 0) {
            const valuePosition = filter.availableValues.indexOf(filterValue)
            if (valuePosition > -1) {
              filter.availableValues.splice(valuePosition, 1)
              filterSelectedValues[filter.columnName] = useSavedFilterValue ? filterSelectedValues[filter.columnName] : (filter.availableValues[valuePosition > 0 ? valuePosition - 1 : 0])
              useSavedFilterValue = true
            }
          } else if (currentFilterSelection[filter.columnName] === filterValue) {
            filterSelectedValues[filter.columnName] = filterValue // Here we should consider checking all the available options for all the previous filters?
          }
        })
        // Update filter available options to be shown
        if (filter.updateFilter) {
          let selectedValue = useSavedFilterValue ? filterSelectedValues[filter.columnName] : selectedValues[filter.columnName]
          if (!row.selectedOption || row.selectedOption.isNoneOption) {
            const currentDefaultFilterValuePerParticipant = this.filterDefaultValuesPerParticipant.find(defaultFilter => defaultFilter.participantId === row.participantId)
            if (currentDefaultFilterValuePerParticipant && filter.columnName === currentDefaultFilterValuePerParticipant.columnName && filter.values.indexOf((currentDefaultFilterValuePerParticipant.defaultValue && currentDefaultFilterValuePerParticipant.defaultValue.toString()) || selectedValue) >= 0) {
              selectedValue = currentDefaultFilterValuePerParticipant.defaultValue || selectedValue
            }
          }
          row.filters[filter.filterId].lastSelectedOption = filter.updateFilter(row, filter, selectedValue)
          currentFilterSelection[filter.columnName] = selectedValue && selectedValue.toString()
        }
      } else {
        afterChangedFilter = filter.filterId === filterId
      }
    })
  }

  _optionChanged (eventArgs, element, isUnselecting) {
    if (element) {
      const row = this._getCurrentRowByElement(element)
      this._closeContainers(row)

      const patchBody = this._mapSelectedOptionForPatch(row, isUnselecting)
      const patchEventArgs = {
        method: this._method,
        url: this._url,
        body: patchBody,
        componentId: this.componentId
      }

      // Save current selected option
      const previousSelectedOptionCode = patchBody && patchBody.oldService ? patchBody.oldService.code : ''
      this._setSessionStorage(row.uid, previousSelectedOptionCode, isUnselecting)
      // tracking should be placed here. Do we needed it???
      this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, patchEventArgs)
    }
  }

  _mapSelectedOptionForPatch (row, isUnselecting) {
    const options = row.resultsApi.getProp('options')
    let selectedOption = (options) ? options.filter(option => option.checked)[0] : null
    const oldOption = row.selectedOption
    const quantity = row.quantityApi ? row.quantityApi.getProp('value') : 1
    const time = row.timeSelectorApi ? row.timeSelectorApi.getProp('value') : null

    // Map operation
    const operationType = OperationTypeMapper.getOperationType(row, selectedOption, isUnselecting)

    // Map participants
    const participantIds = ParticipantIdsMapper.getParticipantIds(row, this.rows, selectedOption, operationType)

    if (isUnselecting) {
      selectedOption = row.noneOption ? row.noneOption : oldOption
    }

    // Map services
    const newService = {
      code: (selectedOption) ? selectedOption.code : '',
      participants: participantIds,
      quantity,
      time,
      startDate: (selectedOption) ? selectedOption.startDate : null,
      endDate: (selectedOption) ? selectedOption.endDate : null
    }

    const oldService = (oldOption)
      ? {
          code: oldOption.code,
          participants: row.participantsAsOptionsApi ? oldOption.assignedParticipants : participantIds,
          startDate: oldOption.startDate,
          endDate: oldOption.endDate
        }
      : null

    // Build payload
    const body = {
      operationType,
      newService,
      oldService
    }

    return body
  }

  _getCurrentRowByElement (element) {
    return element ? this.rows[element.closest(`[${attr.serviceId}]`).dataset[attr.datasetServiceId]] : null
  }

  _optionCancelled (eventargs, element) {
    const row = this._getCurrentRowByElement(element)
    if (row.collapseApi) {
      row.collapseApi.close()
    }
    if (row.sideDrawerApi) {
      row.sideDrawerApi.close()
    }

    // We do a timeOut to prevent see that options are reset before closing the sidedrawer and collapse components
    const transitionTime = cssTimeToMs(getStyle(element, 'transition-duration'))
    setTimeout(() => {
      this._resetQuantityNumberStepper(row)
      this._resetSelectedParticipantsAsOptions(row)
      this._eventCustomerInteractionEmitter(bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_CANCEL_BUTTON, this._extractServiceType(row), 'button', 'cancel')
    }, transitionTime)
  }

  _resetQuantityNumberStepper (row) {
    if (row.quantityApi && row.quantityApi.getProp('value')) {
      const value = row.selectedOption ? row.selectedOption.quantity : row.quantityApi.getProp('value')
      row.quantityApi.setProp('value', value)
    }
  }

  _resetSelectedParticipantsAsOptions (row) {
    if (row.participantsAsOptionsApi && row.participantsAsOptionsApi.getProp('options')) {
      const participantOptions = row.participantsAsOptionsApi.getProp('options')
      if (row.selectedOption && row.selectedOption.assignedParticipants) {
        row.participantsAsOptionsApi.setProp('options',
          participantOptions.map((option) => ({ ...option, checked: row.selectedOption.assignedParticipants.includes(parseInt(option.value)) })),
          { silent: true }
        )
      } else {
        row.participantsAsOptionsApi.setProp('options', participantOptions.map((option) => ({ ...option, checked: false })), { silent: true })
      }
      this._enableAcceptButton(row)
    }
  }

  _switchClicked (eventArgs, element) {
    if (element.classList.contains(classNames.switchCheckbox)) {
      const row = this._getCurrentRowByElement(element)
      const transitionTime = cssTimeToMs(getStyle(element, 'transition-duration'))
      this._eventCustomerInteractionEmitter(bookingFilterableServicesEvents.BOOKING_FILTERABLE_SERVICES_SWITCH_TOGGLE, this._extractServiceType(row), 'toggle', false)
      setTimeout(() => {
        this._resetSelectedParticipantsAsOptions(row)
        this._optionChanged(eventArgs, element, true)
      }, transitionTime)
    }
  }

  _setSessionStorage (rowUid, selectedOptionCode, isUnselecting) {
    if (isUnselecting) {
      const identifier = this._getComponentId()
      if (identifier) {
        const data = this._retrieveDataFromStorage() || {}
        data[rowUid] = selectedOptionCode
        webStorage.session.set(identifier, data)
      }
    }
  }

  _getComponentId () {
    const identifier = this.componentId
    return identifier !== null ? identifier : undefined
  }

  _retrieveDataFromStorage () {
    let data
    const identifier = this._getComponentId()
    if (identifier) {
      data = webStorage.session.get(identifier)
    }
    return data
  }

  _showComponent () {
    this.element.classList.remove(classNames.hidden)
  }

  _hideComponent () {
    this.element.classList.add(classNames.hidden)
  }

  _render (el) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(el)
  }

  _cleanEmptyRows (emptyRows) {
    emptyRows.forEach(emptyRow => {
      const emptyRowElement = this.element.querySelector(`[${attr.serviceId}="${emptyRow}"]`)
      if (emptyRowElement) {
        emptyRowElement.remove()
      }
    })
  }

  /**
   * Returns the row filters as an array, adding as an extra field the filterId property, that identifies uniquely the filter in the widget
   *
   * @param {row} row The row object containing all the information needed for the current service
   */
  _getRowFiltersAsArray (row) {
    const filterArray = Object.keys(row.filters).map(filterKey => {
      const newFilterObject = { filterId: filterKey }
      return { ...newFilterObject, ...row.filters[filterKey] }
    })
    return filterArray
  }
}

registerWidget(BookingFilterableServices, widgetApi)

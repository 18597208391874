import { TitleTemplate } from '../../../components/title/c-title.template'
import { BtnTemplate } from '../../../components/btn/c-btn.template'

export const defaultBookingCancellationFlowGenericConfirmationData = {
  title: '',
  confirm: '',
  decline: '',
  back: '',
  html: ''
}

export const BookingCancellationFlowGenericConfirmationTemplate = (d) => {
  d = { ...defaultBookingCancellationFlowGenericConfirmationData, ...d }
  return `
    <div>
      <div class="w-booking-cancellation-flow__body sp-stack-children--medium">
        ${d.back
          ? BtnTemplate({
            variant: 'flat-neutral',
            text: d.back,
            icon: 'chevron-left',
            extraClasses: 'w-booking-cancellation-flow__back-button',
            attributes: { 'data-w-booking-cancellation-flow__options-back': '' }
          })
          : ''}
        ${d.title
          ? TitleTemplate({
            text: d.title,
            size: 'default',
            extraClasses: 'u-hidden',
            attributes: { 'data-w-booking-cancellation-flow__transform-title': '' }
          })
          : ''}
        <div class="m-body">
          ${d.html}
        </div>
      </div>
      <div class="w-booking-cancellation-flow__buttons w-booking-cancellation-flow__buttons--with-bg">
        ${d.decline
          ? BtnTemplate({
            text: d.decline,
            jsApi: true,
            variant: 'flat-neutral',
            attributes: { 'data-w-booking-cancellation-flow__options-decline': '' }
          })
          : ''}
        ${d.confirm
          ? BtnTemplate({
            text: d.confirm,
            jsApi: true,
            variant: 'flow',
            attributes: { 'data-w-booking-cancellation-flow__options-confirm': '' }
          })
          : ''}
      </div>
    </div>
    `
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookedServicesList from '../../../../shared/modules/widgets/booked-services-list/main'

const data = require('../../../../shared/modules/widgets/booked-services-list/data/w-booked-services-list__js-template.json')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = async () => {
  const bookedServicesListItem = document.querySelectorAll('[data-js-api--w-booking-item="w-booked-services-list"]')

  bookedServicesListItem.forEach(element => {
    const bookedServicesList = new BookedServicesList(element)
    console.log(bookedServicesList)
  })

  const bookedServicesListJsTemplate = document.querySelector('[data-booked-services-list-demo]')
  if (bookedServicesListJsTemplate) {
    const widget = new BookedServicesList(bookedServicesListJsTemplate.firstElementChild)
    await widget.handleFetched({ response: data })
    await widget.enableUI()
    await widget.handleAllRendered()
  }
}

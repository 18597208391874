import { BulletListTemplate } from '../../components/bullet-list/c-bullet-list.template'
import { BookingMessageTemplate } from '../booking-message/w-booking-message.template'
import { BookingCarRentalAddExtraCarsTemplate } from './w-booking-car-rental-add-extra-cars.template'
import { BookingCarRentalCarsListTemplate } from './w-booking-car-rental-cars-list.template'
import { BookingCarRentalConditionsTemplate } from './w-booking-car-rental-conditions.template'

/**
 * The BookingCarRentalData contains all data needed to hydrate a BookingCarRental view
 *
 * @typedef {Object}                          BookingCarRentalData
 *
 * @property {String}                         collapseId                          - the identifier for the collapsible
 * @property {PriceData}                      price                               - The price data to configure the general style of the prices (see c-price component for more info)
 * @property {String}                         onRequestLabel                      - The label for the available on request text
 * @property {String}                         viewMoreButton                      - The text for the "see more" button
 * @property {String}                         viewLessButton                      - The text for the "see less" button
 * @property {UspData[]}                      usps                                - The usps to be shown in the header of the component
 * @property {String}                         CarData.code                        - Code of the car
 * @property {String}                         CarData.name                        - Name of the car
 * @property {String}                         CarData.type                        - Type of the car, typically the class name (examples: small, economy, compact, suv, ...)
 * @property {String}                         CarData.image                       - The image url of the car rental
 * @property {String}                         CarData.participants                - The participants of the car
 * @property {String}                         CarData.onRequestLabel              - The text to show when the car is "on request"
 * @property {PriceData}                      CarData.price                       - The price of the car
 * @property {CharacteristicData[]}           CarData.characteristics             - The list of characteristics of the car rental (like number of doors, number of suitcases, air conditioned, gear transmission, ... )
 * @property {String}                         CharacteristicData.icon             - The icon of the characteristic
 * @property {String}                         CharacteristicData.text             - The text to show for that characteristic
 * @property {CarAvailableData[]}             items                               - The items of the available cars (CarAvailableData inherits/contains CarData)
 * @property {isCarEditable}                  boolean                             - Indicates if the car that we have is the unique car and also selected
 * @property {AddExtraCarsInformationData[]}  CarData.addExtraCarsInformation     - The list of characteristics of the car rental (like number of doors, number of suitcases, air conditioned, gear transmission, ... )
 */

export const BookingCarRentalTemplate = (d) => {
  return ` <div class="w-booking-car-rental sp-stack-children--medium">
            ${d.usps
              ? `<div class="w-booking-car-rental__usps o-columns o-column--1/3@sml">
                  ${BulletListTemplate(d.usps)}
              </div>`
            : ''}
            ${MessagesTemplate(d)}
            ${d.carsLists && d.carsLists.length > 0
              ? `${
                  d.carsLists.map((list, index) => {
                    const data = {
                      ...list,
                      collapseButtonTexts: d.collapseButtonTexts,
                      driverLabel: d.driverLabel,
                      optionalEquipmentText: d.optionalEquipmentText,
                      selectedEquipmentText: d.selectedEquipmentText,
                      checkoutAdvice: d.checkoutAdvice,
                      carRentalTrack: d.carRentalTrack
                    }
                    return BookingCarRentalCarsListTemplate(data, index + 1)
                  }).join('')
                }
                ${
                  d.conditions
                  ? `<div data-w-booking-car-rental__conditions-container >
                      ${d.conditions ? BookingCarRentalConditionsTemplate(d) : ''}
                    </div>`
                  : ''
                }`
              : ''
            }
            
            ${d.addExtraCarsInformation && d.addExtraCarsInformation.showAddExtraCarsBlock && d.addExtraCarsInformation.addExtraCarEnabled
              ? BookingCarRentalAddExtraCarsTemplate(d.addExtraCarsInformation)
              : ''} 
          </div>
    `
}

const MessagesTemplate = (d) => {
  return d.messages
    ? `<div class="w-booking-car-rental__messages" data-w-booking-car-rental__message>
        ${BookingMessageTemplate(d.messages)}
        </div>`
    : ''
}

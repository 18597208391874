/**
 *  The BookingGenericSummaryTemplate contains all the data needed to hydrate a BookingGenericSummary component.
 *
 *  @typedef {Object}     BookingGenericSummaryTemplate
 *
 *  @property {String}    variant           - The variant that will be used.
 *  @property {String}    extraClasses      - Extra classes if needed.
 *  @property {String}    innerHTML         - The content that should be shown inside the table.
 */

export const defaultBookingGenericSummaryData = {
  variant: '',
  extraClasses: '',
  innerHtml: ''
}

export const BookingGenericSummaryTemplate = (d) => `
  ${d.items
    ? `
      <ul class="w-booking-generic-summary__list o-list-bare">
        ${d.items.map(item => `
          <li class="w-booking-generic-summary__list-item">
            ${item}
          </li>
        `).join('')}
      </ul>
      `
    : ''}
`

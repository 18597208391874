import { TextboxTemplate } from '../../../components/textbox/c-textbox.template'
import { BoxTemplate } from '../../../components/box/c-box.template'
import PriceFormatter from '../../../../js/helpers/price-formatter'

/**
 * The defaultPaymentPartialData contains all data to render partial
 */

export const defaultPaymentPartialData = {
  partialAmountPlaceholder: '',
  partialTotalAmountText: '',
  partialDownPaymentAmountText: '',
  minAmount: 0,
  maxAmount: 0,
  priceConfiguration: {
    showCurrencyAtStart: true
  },
  partialAdditionalAmountInputPlaceholder: '',
  partialToPayAmountText: '',
  partialRemainingAmountText: '',
  partialAdditionalAmountInputText: '',
  partialMinAmountText: ''
}

export const PaymentPartialTemplate = (d) => {
  d = { ...defaultPaymentPartialData, ...d }
  const priceFormatterConfiguration = {
    ...d.priceConfiguration,
    isCurrencyAtStart: d.priceConfiguration.showCurrencyAtStart
  }
  return `

        <div class="w-payment__partial">
        ${BoxTemplate({
          variant: 'flat',
          extraClasses: `w-payment__partial-wrapper ${d.extraClasses ? d.extraClasses : ''}`,
          html: `
            <div>
              <div class="w-payment__partial-line"">
                <div class="w-payment__partial-line-title">${d.partialMinAmountText}</div>
                <div class="w-payment__partial-line-price">${PriceFormatter.toFormattedText(d.minAmount, priceFormatterConfiguration)}</div>
              </div>
              <hr>
              <div class="w-payment__partial-line">
               <h4 class="w-payment__partial-line-title">${d.partialAdditionalAmountInputText.replace('{MAX_AMOUNT}', PriceFormatter.toFormattedText(d.maxAmount - d.minAmount, priceFormatterConfiguration))}</h4>
                ${TextboxTemplate({
                  id: 'w-payment__partial-amount-input',
                  ...d.partialAdditionalAmountInputPlaceholder && { placeholder: d.partialAdditionalAmountInputPlaceholder },
                  elementAttributes: {
                    'data-w-payment__partial-amount-input-element': ''
                  },
                  required: true,
                  extraClasses: 'w-payment__partial-input',
                  attributes: {
                    'data-w-payment__partial-amount-input': ''
                  },
                  text: d.priceConfiguration.currencySymbol,
                  textPosition: d.priceConfiguration.showCurrencyAtStart ? 'left' : 'right'
                })}
              </div>
              <hr>
              <div class="w-payment__partial-total">
                <div class="w-payment__partial-total-title">${d.partialToPayAmountText}</div>
                <div class="w-payment__partial-total-price" data-w-payment__partial-total-to-pay>${PriceFormatter.toFormattedText(d.minAmount, priceFormatterConfiguration)}</div>
              </div>
              <div class="w-payment__partial-subtotal">
                <div class="w-payment__partial-subtotal-title">${d.partialRemainingAmountText}</div>
                <div class="w-payment__partial-subtotal-price" data-w-payment__partial-remaining-balance>${PriceFormatter.toFormattedText(d.maxAmount - d.minAmount, priceFormatterConfiguration)}</div>
              </div>
            </div>
              `
        })}`
}

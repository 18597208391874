import { defaultRadioButtonData, RadioButtonTemplate } from '../../components/radio-button/c-radio-button.template'
import { defaultPriceData, PriceTemplate } from '../../components/price/c-price.template'
import { getTokenClass } from '../../../js/helpers/tokens'
import { TooltipTemplate, defaultTooltipData } from '../../components/tooltip/c-tooltip.template'

// Import tokens
const tokensShared = require('./data/c-transport-ticket__tokens.json')['c-transport-ticket']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/transport-ticket/data/c-transport-ticket__tokens.json`)['c-transport-ticket'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

/**
 * The transportTicketTemplate contains all data needed to hydrate a ticker of transport
 *
 * @typedef {Object}          transportTicketTemplate
 *
 * @property {String}           id                            - The item's ID.
 * @property {String}           name                          - The item's name.
 * @property {String}           type                          - Whether it is a flight or a bus selector.
 * @property {String}           [companyId]                   - Airline or company ID.
 * @property {String}           companyName                   - Airline or company name.
 * @property {String}           flightNumber                  - Flight number, if any
 * @property {String}           departureName                 - Departure's location name.
 * @property {String}           oldDepartureName              - Departure's location name before the change (old airport).
 * @property {String}           [departureTime]               - Departure humanized time.
 * @property {String}           [oldDepartureTime]            - Departure humanized time before change (old time).
 * @property {String}           [arrivalName]                 - Arrival's location name.
 * @property {String}           [oldArrivalName]              - Arrival's location name before the change (old airport).
 * @property {String}           [arrivalTime]                 - Arrival humanized time.
 * @property {String}           [oldArrivalTime]              - Arrival humanized time before change (old time).
 * @property {String}           selectorIsHidden              - It is hiding the radio button.
 * @property {Boolean}          [isInbound]                   - If true, flight/bus is inbound (arrival), else it's departure.
 * @property {Boolean}          [checked]                     - If true, flight/bus item is selected.
 * @property {Boolean}          [disabled]                    - Mark as disabled the transport.
 * @property {PriceData}        price                         - Shows the price of the transport.
 * @property {Boolean}          maxHandLuggageWeight          - Hand luggage weight.
 * @property {String}           maxCheckedLuggageWeight       - Checked luggage weight.
 * @property {String}           handLuggageDescription        - Hand luggage description.
 * @property {String}           handLuggageNotIncludedText    - Text to show when hand luggage is not included (so 0 kg)
 * @property {String}           checkedLuggageDescription     - Checked luggage description.
 * @property {Boolean}          isFlexibleTicket              - Whether is flexible flight or not.
 * @property {String}           flexibleTicketText            - Text to indicate is flexible ticket.
 * @property {String}           regularTicketText             - Text to indicate is regular ticket.
 * @property {String}           weightLuggageSymbol           - Unit of the luggage weight.
 */

export const defaultTransportTicketData = {
  id: '',
  name: '',
  type: 'flight',
  companyId: '',
  companyName: '',
  flightNumber: '',
  departureName: '',
  oldDepartureName: '',
  departureTime: '',
  oldDepartureTime: '',
  arrivalName: '',
  oldArrivalName: '',
  arrivalTime: '',
  oldArrivalTime: '',
  isInbound: false,
  checked: false,
  disabled: false,
  price: {},
  selectorIsHidden: false,
  isDepartureTimeSpecified: true,
  isArrivalTimeSpecified: true,
  remark: '',
  unknownTimeText: 'unknown time',
  enRouteTransportIcon: 'airplane-right',
  maxHandLuggageWeight: 0,
  maxCheckedLuggageWeight: 0,
  handLuggageDescription: '',
  handLuggageNotIncludedText: '',
  checkedLuggageDescription: '',
  isFlexibleTicket: false,
  flexibleTicketText: '',
  regularTicketText: '',
  weightLuggageSymbol: '',
  supplierReservationNumber: '',
  supplierReservationNumberInfo: ''
}

export const TransportTicketTemplate = (d) => {
  d = { ...defaultTransportTicketData, ...d }
  d.name += `_${d.isInbound ? 'inbound' : 'outbound'}`
  const hasOldDepartureTime = !!d.oldDepartureTime && (d.oldDepartureTime !== d.departureTime)
  const departureTimeAdditionalClass = hasOldDepartureTime ? 'c-transport-ticket__data--new' : ''
  const hasOldArrivalTime = !!d.oldArrivalTime && (d.oldArrivalTime !== d.arrivalTime)
  const arrivalTimeAdditionalClass = hasOldArrivalTime ? 'c-transport-ticket__data--new' : ''
  const hasOldDepartureName = !!d.oldDepartureName && (d.oldDepartureName !== d.departureName)
  const departureNameAdditionalClass = hasOldDepartureName ? 'c-transport-ticket__data--new' : ''
  const hasOldArrivalName = !!d.oldArrivalName && (d.oldArrivalName !== d.arrivalName)
  const arrivalNameAdditionalClass = hasOldArrivalName ? 'c-transport-ticket__data--new' : ''

  return `
<div class="c-transport-ticket
  ${d.checked ? 'is-checked' : ''}
  ${d.disabled ? 'is-disabled' : ''}
  ${d.selectorIsHidden ? 'c-transport-ticket__no-radio' : ''}
  ${d.type === 'bus' ? 'c-transport-ticket--bus' : ''}"
  ${d.id ? `id="${d.id}"` : ''}>
  ${d.selectorIsHidden === false
    ? `<div class="c-transport-ticket__radio">
      ${RadioButtonTemplate({ ...defaultRadioButtonData, id: `${d.name}_${d.id}`, name: d.name, value: d.id, checked: d.checked })}
    </div>`
    : ''}

  <div class="c-transport-ticket__contents">
    <div class="c-transport-ticket__detailed-info-top">
      <span class="c-transport-ticket__detailed-info-departure">
        ${hasOldDepartureName ? `<span class="c-transport-ticket__data--old">${d.oldDepartureName}</span>` : ''}
        <span class="${departureNameAdditionalClass}">${d.departureName}</span>
      </span>
      ${d.type === 'flight'
        ? `<span class="c-transport-ticket__detailed-info-arrival">
            ${hasOldArrivalName ? `<span class="c-transport-ticket__data--old">${d.oldArrivalName}</span>` : ''}
            <span class="${arrivalNameAdditionalClass}">${d.arrivalName}</span>
          </span>`
        : ''}
    </div>

    ${(d.type === 'flight' || !d.isInbound)
      ? `<div class="c-transport-ticket__detailed-info-bottom">
          <div class="c-transport-ticket__detailed-info-departure">
            ${(d.isDepartureTimeSpecified)
              ? ` <span class="${getTokenClass('dates__material', 'default', tokens)}">
                ${hasOldDepartureTime ? `<span class="c-transport-ticket__data--old">${d.oldDepartureTime}</span>` : ''}
                <span class="${departureTimeAdditionalClass}">${d.departureTime}</span>
              </span >`
              : `<span class="${getTokenClass('dates__material', 'default', tokens)}">
                  --:--
                </span>
                <span class="c-transport-ticket__detailed-info-unknownTime"> ${d.unknownTimeText} </span >`}
                </div>

          ${d.type === 'flight'
          ? `<div class="c-transport-ticket__detailed-info-icon">
              <span class="m-icon m-icon--${d.enRouteTransportIcon}"></span>
            </div>
            <div class="c-transport-ticket__detailed-info-arrival">
            ${(d.isArrivalTimeSpecified)
            ? `
            <span class="${getTokenClass('dates__material', 'default', tokens)}">
              ${hasOldArrivalTime ? `<span class="c-transport-ticket__data--old">${d.oldArrivalTime}</span>` : ''}
              <span class="${arrivalTimeAdditionalClass}">${d.arrivalTime}</span>
            </span >`
            : `
            <span class="${getTokenClass('dates__material', 'default', tokens)}">
              --:--
            </span >
            <span class="c-transport-ticket__detailed-info-unknownTime"> ${d.unknownTimeText} </span >`}
            </div>`
        : ''}
      </div>`
    : ''}

    <div class="${getTokenClass('airline-name__material', 'default', tokens)} c-transport-ticket__airline-info">
      ${d.companyId ? `<span class="c-transport-ticket__airline-logo m-airline-logo" data-airline-id="${d.companyId}"></span>` : ''}
      <span class="c-transport-ticket__airline-name">${d.companyName}</span>
      ${d.flightNumber
        ? d.companyName
          ? `<span>&nbsp;-&nbsp;</span><span class="c-transport-ticket__flight-number">${d.flightNumber}</span>`
          : `<span class="c-transport-ticket__flight-number">${d.flightNumber}</span>`
        : ''}
    </div>

    ${d.type === 'flight'
      ? `<div class="c-transport-ticket__detailed-info-additional">
        ${(d.maxHandLuggageWeight || d.maxCheckedLuggageWeight)
          ? `<div class="c-transport-ticket__luggage-info-container">
            ${d.maxCheckedLuggageWeight
              ? `<div class="c-transport-ticket__luggage-info" title="${d.checkedLuggageDescription}">
                <span class="m-icon m-icon--size-large m-icon--luggage-check c-transport-ticket__luggage-info-icon"></span>
                <div class="c-transport-ticket__luggage-info-text">${d.maxCheckedLuggageWeight} ${d.weightLuggageSymbol}</div>
              </div>`
            : ''}
            ${d.maxHandLuggageWeight && d.maxHandLuggageWeight !== '0'
              ? `<div class="c-transport-ticket__luggage-info" title="${d.handLuggageDescription}">
                  <span class="m-icon m-icon--size-large m-icon--backpack-checkmark c-transport-ticket__luggage-info-icon"></span>
                  <div class="c-transport-ticket__luggage-info-text">${d.maxHandLuggageWeight} ${d.weightLuggageSymbol}</div>
                </div>`
              : ''}
            ${d.maxHandLuggageWeight && d.maxHandLuggageWeight === '0' && d.handLuggageNotIncludedText
              ? `<div class="c-transport-ticket__luggage-info">
                  <span class="m-icon m-icon--size-large m-icon--backpack-checkmark c-transport-ticket__luggage-info-icon"></span>
                  <div class="c-transport-ticket__luggage-info-text">${d.handLuggageNotIncludedText}</div>
                </div>`
              : ''}
  
          </div>`
        : ''}
        <div class="c-transport-ticket__type-ticket">
          <span class="m-icon m-icon--size-large m-icon--${d.isFlexibleTicket ? 'airplane-flexible' : 'airplane'} c-transport-ticket__type-ticket-icon"></span>
          <div class="c-transport-ticket__type-ticket-text-container">
            <div class="c-transport-ticket__type-ticket-text">
              ${d.isFlexibleTicket ? d.flexibleTicketText : d.regularTicketText}
            </div>
            ${d.supplierReservationNumber
              ? `<div class="c-transport-ticket__pnr-number-container">
                  ${d.supplierReservationNumberInfo
                    ? `${TooltipTemplate({
 ...defaultTooltipData,
                          html: `<div class="c-transport-ticket__pnr-number"><b>PNR: </b>${d.supplierReservationNumber}</div>`,
                          displayOn: 'left',
                          text: d.supplierReservationNumberInfo,
                          displayOnBottomFrom: 'sml'
})}`
                          : `<div class="c-transport-ticket__pnr-number"><b>PNR: </b>${d.supplierReservationNumber}</div>`}
                </div>`
: ''}
          </div>
        </div>
      </div>
      ${d.remark
        ? `<p class="c-transport-ticket__detailed-info-remark">${d.remark}</p>`
        : ''}`
      : ''}


  </div>

  ${(d.price && d.price.value !== undefined)
    ? `<div class="c-transport-ticket__price">
      ${PriceTemplate({ ...defaultPriceData, ...d.price })}
    </div>`
    : ' '}
</div>`
}

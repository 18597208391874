import { BoxTemplate } from '../../components/box/c-box.template'
import { FormTemplate } from '../../components/form/c-form.template'
import { FormSectionTemplate } from '../../components/form/c-form__section.template'
import { TextboxTemplate } from '../../components/textbox/c-textbox.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'

export const defaultBookingErrorData = {
  errorMessage: '',
  errorMessageMandatoryPayment: '',
  internalMessages: {
    visible: false
  },
  form: {
    visible: false
  }
}

const defaultInternalMessagesData = {
  internalMessages: {
    visible: false
  }
}

export const defaultErrorMessageData = {
  errorMessage: ''
}

export const defaultErrorMessageMandatoryPaymentData = {
  errorMessageMandatoryPayment: ''
}
export const BookingErrorTemplate = (d) => {
  d = { ...defaultBookingErrorData, ...d }
  return `
    ${ErrorMessageSectionTemplate(d)}
    ${ErrorMessageSectionMandatoryPaymentTemplate(d)}
    ${InternalMessagesSectionTemplate(d)}
    ${ContactFormSectionTemplate(d)}
    `
}

export const InternalMessagesTemplate = (d) => {
  d = { ...defaultInternalMessagesData, ...d }
  return InternalMessagesContentSectionTemplate(d)
}

export const ErrorMessageTemplate = (d) => {
  d = { ...defaultErrorMessageData, ...d }
  return ErrorMessageSectionTemplate(d)
}

export const ErrorMessageMandatoryPaymentTemplate = (d) => {
  d = { ...defaultErrorMessageMandatoryPaymentData, ...d }
  return ErrorMessageSectionMandatoryPaymentTemplate(d)
}

const ErrorMessageSectionTemplate = (d) => {
  return `
        <div data-error-message-section='' class="booking-error--error-message">
            ${BoxTemplate({
              variant: 'flat',
              extraClasses: 'cs-state-error cs-state-error--light booking-error--main-error',
              html: d.errorMessage
            })}
            ${d.errorMessageDescription}
        </div>
    `
}

const ErrorMessageSectionMandatoryPaymentTemplate = (d) => {
  return `
       <div data-error-message-section-mandatory-payment='' class="booking-error--error-message booking-error--error-message-has-mandatory-payment-case">
        ${BoxTemplate({
              variant: 'flat',
              extraClasses: 'cs-state-error cs-state-error--light booking-error--main-error',
              html: d.errorMessageMandatoryPayment
            })}
            ${d.errorMessageDescriptionMandatoryPayment}
        </div>
    `
}
const InternalMessagesSectionTemplate = (d) => {
  return `
    <div data-internal-messages-container data-internal-messages-title="${d.internalMessages.title}>
      ${InternalMessagesContentSectionTemplate(d)}
    </div>
    `
}

const InternalMessagesContentSectionTemplate = (d) => {
  return `
      ${d.internalMessages.visible
        ? BoxTemplate({
          variant: 'flat',
          extraClasses: `cs-state-warning cs-state-warning--light booking-error--internal-messages ${d.extraClasses ? d.extraClasses : ''}`,
          html: `
                <div class="booking-error--messages">
                    ${d.internalMessages.title
                        ? `<h4 class="booking-error--messages-title">${d.internalMessages.title}</h4><hr>`
                        : ''}
                    ${d.internalMessages.messages.map(message => `<div class="booking-error--message-item">${message}</div>`).join('')}
                </div>
            `
        })
      : ''}
    `
}

const ContactFormSectionTemplate = (d) => {
  if (!d.form.visible) {
    return ''
  }

  return `
    <div data-form class="booking-error--form">
        ${FormTemplate({
          hasSubmitButton: false,
          validate: true,
          html: FormSectionTemplate({
            html: `
                ${d.form.title
                ? `<div class="w-booking-error__form-title">${d.form.title}</div>`
                : ''}
                
                <div class="w-booking-error__form-fields-section">
                    ${TextboxTemplate({
                      ...d.form.fieldName,
                      ...{
                        extraClasses: 'c-form__item c-form__item--large w-booking-error__field-name-input',
                        attributes: {
                          'data-w-booking-error--field-name-input': ''
                        }
                      }
                    }
                    )}
                    ${TextboxTemplate({
                      ...d.form.fieldEmail,
                      ...{
                        extraClasses: 'c-form__item c-form__item--large w-booking-error__field-email-input',
                        attributes: {
                          'data-w-booking-error--field-email-input': ''
                        }
                      }
                    }
                    )}
                    ${TextboxTemplate({
                      ...d.form.fieldPhone,
                      ...{
                        extraClasses: 'c-form__item c-form__item--large w-booking-error__field-phone-input',
                        attributes: {
                          'data-w-booking-error--field-phone-input': ''
                        }
                      }
                    }
                    )}                                        
                </div>
                <div class="w-booking-error__form-button-section">
                    ${BtnTemplate({
                      type: 'button',
                      text: d.form.buttonText,
                      variant: 'trust',
                      extraClasses: 'w-booking-error__form-submit',
                      attributes: {
                        'data-w-booking-error--form-submit-button': ''
                      }
                    })}
                </div>
                <div class="w-booking-error__form-messages-section">
                    ${BoxTemplate({
                      variant: 'flat',
                      extraClasses: 'cs-state-success cs-state-success--light hidden',
                      html: d.form.successMessage,
                      attributes: {
                        'data-w-booking-error--form-success-message': ''
                      }
                    })}
                    ${BoxTemplate({
                      variant: 'flat',
                      extraClasses: 'cs-state-error cs-state-error--light hidden',
                      html: d.form.errorMessage,
                      attributes: {
                        'data-w-booking-error--form-error-message': ''
                      }
                    })}
                </div>
                `
          })
        })}
    </div>
    `
}

/**
 * The ActionableListData contains all data needed to hydrate the view
 *
 * @typedef {Object}          ActionableListData
 *
 * @property {Object}         config               - Configuration of the actionable-list
 * @property {Object[]}       items                - Items list to be rendered
 * @property {String}         [extraClasses]
 * @property {Object}         [attributes]
 *
 */
import { ActionableListRoomTemplate } from './item-types/c-actionable-list__item--room.template'
import { ActionableListItemExtendedTemplate } from './item-types/c-actionable-list__item--extended.template'
import { encodeAttributes } from '../../../js/helpers/string'

export const defaultActionableListData = {
  config: {
    itemType: ''
  },
  items: [],
  extraClasses: '',
  attributes: '',
  jsApi: false
}

export const ActionableListTemplate = (d) => {
  d = { ...defaultActionableListData, ...d }
  return `<ul class="c-actionable-list
    c-actionable-list--${d.config.itemType}
    o-list-bare
    ${d.extraClasses ? d.extraClasses : ''}"
    ${d.jsApi ? 'data-js-component="c-actionable-list"' : ''}
    ${d.attributes ? encodeAttributes(d.attributes) : ''}>
    ${d.items.map(item => `
      <li class="c-actionable-list__item" data-c-actionable-list__item ${item.id ? `data-c-actionable-list__item_id="${item.id}"` : ''} ${item.attributes ? encodeAttributes(item.attributes) : ''} ${item.isDisabled ? 'disabled' : ''}  >
        ${ActionableListTypeTemplate(item, d.config)}
      </li>`
    ).join('')}
</ul>`
}

const ActionableListTypeTemplate = (item, config) => {
  if (config.itemType === 'room') {
    return ActionableListRoomTemplate({ item, config })
  } else if (config.itemType === 'extended') {
    return ActionableListItemExtendedTemplate({ item, config })
  } else {
    return ''
  }
}

import { TitleTemplate, defaultTitleData } from '../../components/title/c-title.template'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'
import { ImgTemplate, defaultImgData } from '../../components/img/c-img.template'
import { BulletListTemplate } from '../../components/bullet-list/c-bullet-list.template'
import { PriceTemplate, defaultPriceData } from '../../components/price/c-price.template'
/**
 * The BookingBasketData contains all data needed to hydrate a BookingBasket view
 *
 * @typedef {Object}                  BookingBundleData
 *
 */

export const defaultBookingBundleData = {

}

export const BookingBundleTemplate = (d) => {
  d = { ...defaultBookingBundleData, ...d }
  return `<div class="w-booking-bundle ${d.selected ? 'is-selected' : ''}" data-w-booking-bundle>
    ${d.image
      ? ImgTemplate({ ...defaultImgData, ...d.image, extraClasses: 'w-booking-bundle__img' })
      : ''
    }
    <div class="w-booking-bundle__content">
      ${d.bundle.selected && d.titleSelected
        ? `<div class="w-booking-bundle__title-selected">
          <i class="m-icon m-icon--size-large m-icon--checkmark-circle"></i>
          ${TitleTemplate({
            ...defaultTitleData,
            ...{
              text: d.titleSelected,
              tag: 'h3',
              size: 'medium',
              extraClasses: 'w-booking-bundle__title'
            }
            })
          }

        </div>`

       : ''}

      ${!d.bundle.selected && d.title
        ? TitleTemplate({
          ...defaultTitleData,
          ...{
            text: d.title,
            tag: 'h3',
            size: 'medium',
            extraClasses: 'w-booking-bundle__title'
          }
        })
      : ''}
      <div class="w-booking-bundle__content-information">
          ${d.bundle && d.bundle.usps
            ? `<div class="w-booking-bundle__usps">
                ${BulletListTemplate(d.bundle.usps)}
            </div>`
        : ''}
        <div class="w-booking-bundle__content-action sp-stack-children--medium">
          ${PriceTemplate(
            {
              ...defaultPriceData,
              ...d.bundle.price.priceConfig,
              value: d.bundle.price.value
            })
          }
          
          ${
            d.bundle.selected
              ? BtnTemplate({
                ...defaultButtonData,
                text: d.removeButtonText,
                variant: 'flat-neutral',
                icon: 'bin',
                extraClasses: 'w-booking-bundle__btn w-booking-bundle__remove-btn',
                attributes: { 'data-w-booking-bundle__btn': 'remove', 'data-w-booking-bundle__code': d.bundle.code }
              })
            : BtnTemplate({
              ...defaultButtonData,
              text: d.selectButtonText,
              variant: 'flow',
              extraClasses: 'w-booking-bundle__btn',
              attributes: { 'data-w-booking-bundle__btn': 'select', 'data-w-booking-bundle__code': d.bundle.code }
            })
          }
        </div>
      </div>
    </div>
  </div>
  `
}

import PriceFormatter from '../../../js/helpers/price-formatter'
import { bookingLuggageDirections } from './config'
import { mapSameOptionForInboundDirection } from './helpers'
/**
 * Maps APi response to widegt model
 * - If Api response doesn't contain rooms, reponse is NULL
 * - If Api response.luggageDefinitionInformation is Null, response.luggageInfoMessage is NULL
 * - If APi server data (texts) doesn't contain proper data for special luggage then reponse.specialLuggage is NULL
 */
export default class BookingLuggageDataMapper {
  mapService (data) {
    const { apiService, texts, showSpecialLuggageCheckbox, configurations } = data
    const roomsData = this._getAvailableRooms(apiService)
    const rooms = roomsData && roomsData.length > 0 ? roomsData.map(room => this._mapRoom({ apiRoom: room, apiService, texts, configurations })) : null
    const widgetService = rooms && apiService.participants && apiService.participants.length > 0
      ? {
          luggageInfoMessage: apiService.luggageDefinitionInformation ? apiService.luggageDefinitionInformation : null,
          rooms,
          specialLuggage: this._mapSpecialLuggage(texts, apiService, showSpecialLuggageCheckbox, configurations?.showSpecialLuggageSwitch),
          configurations: this._mapConfigurations(apiService, configurations),
          oneClickLuggage: {
            isOneClickLuggageReady: (configurations?.showOneClickLuggage && apiService?.isOneClickLuggageReady) || false,
            sameOptionSelectedForAllParticipants: this._checkIfOneServiceOptionAppliesToAllParticipantsOrNone(apiService),
            options: this._mapOneClickLuggageOptions(apiService, configurations),
            noOption: this._mapOneClickLuggageNoOptions(apiService),
            description: apiService?.luggageDefinitionInformation?.oneClickDescription || null,
            flightsInformationText: this._mapOneClickLuggageFlightsInformation(apiService.luggageDefinitionInformation),
            switchViewsInfo: this._mapSwitchViewsInformation(configurations, apiService)
          }
        }
      : null

    return widgetService
  }

  _mapSpecialLuggage (texts, apiService, showSpecialLuggageCheckbox, showSpecialLuggageSwitch) {
    let specialLuggage = null
    const hasSpecialLuggage = texts && texts.specialLuggageCheckboxId && texts.specialLuggageCheckboxValue && texts.specialLuggageText
    if (hasSpecialLuggage) {
      specialLuggage = {
        title: texts.specialLuggageTitle,
        showSpecialLuggageCheckbox,
        showSpecialLuggageSwitch,
        specialLuggageTextCheckboxIsHidden: texts.specialLuggageText,
        choiceListInfo: {
          id: apiService.componentId,
          name: apiService.componentId,
          items: [{
            id: texts.specialLuggageCheckboxId,
            name: texts.specialLuggageCheckboxValue,
            value: texts.specialLuggageCheckboxValue,
            text: texts.specialLuggageText,
            checked: apiService.hasSpecialLuggage
          }]
        }
      }
      if (showSpecialLuggageSwitch) {
        specialLuggage.switchInfo = {
          id: apiService.componentId + '_special-luggage-switch',
          text: texts.specialLuggageText || '',
          checked: apiService.hasSpecialLuggage,
          disabled: false,
          extraClasses: 'w-booking-luggage__special-luggage--switch',
          attributes: {
            'data-w-booking-luggage__special-luggage--switch': ''
          }
        }
        specialLuggage.extraTextSelected = texts.specialLuggageExtraTextSelected || ''
      }
    }
    return specialLuggage
  }

  _mapConfigurations (apiService, config) {
    const configurations = {}
    configurations.price = (config && config.priceConfiguration) || apiService.priceConfiguration || {}
    configurations.showOneClickLuggage = (config && config.showOneClickLuggage) || false
    configurations.maxOneClickOptionsToBeShown = (config && config.maxOneClickOptionsToBeShown) || null
    configurations.oneClickLuggageTexts = (config && config.oneClickLuggageTexts) || null
    return configurations
  }

  _getAvailableRooms (apiService) {
    let rooms = [{ id: null, name: '', participantIds: [] }]
    if (!apiService.rooms || apiService.rooms.length === 0) { return rooms }

    if (apiService.rooms.length > 0) {
      rooms = apiService.rooms
    }

    return rooms
  }

  _mapRoom (roomData) {
    const { apiRoom, apiService, texts, configurations } = roomData
    const filteredParticipants = apiRoom.participantIds && apiRoom.participantIds.length > 0
      ? apiService.participants.filter(
        participant => apiRoom.participantIds.includes(participant.id) &&
      apiService.options.some(option => option.appliesToParticipants.includes(participant.id))
      )
      : (apiService.participants && apiService.participants.filter(participant => apiService.options.some(option => option.appliesToParticipants.includes(participant.id))))

    const widgetRoom = {
      roomName: apiRoom?.displayText,
      participants: filteredParticipants && filteredParticipants.map(participant => this._mapParticipant({ apiParticipant: participant, apiService, texts, configurations })),
      warning: this._mapWarningForRoom(apiService, apiRoom)
    }
    return widgetRoom
  }

  _mapWarningForRoom (apiService, apiRoom) {
    return apiService.warnings && apiService.warnings.length > 0
      ? apiService.warnings.filter(warning => apiRoom.participantIds.includes(warning.participantId))[0]
      : null
  }

  _mapParticipant (participantData) {
    const { apiParticipant, apiService, texts, configurations } = participantData
    const uniqueId = apiService.componentId + '_' + apiParticipant.id

    const inboundData = this._mapDropdownData({ uniqueId, apiParticipant, apiService, directionGroup: bookingLuggageDirections.inboundGroup, labelDropdown: texts.inboundDropdown, configurations })
    const titleForOutboundOptions = inboundData ? texts.outboundDropdown : texts.bothDropdown
    const outboundData = this._mapDropdownData({ uniqueId, apiParticipant, apiService, directionGroup: bookingLuggageDirections.outboundGroup, labelDropdown: titleForOutboundOptions, configurations })

    const inboundSelectedOption = inboundData ? inboundData.options.find(option => option.selected) : null
    const outboundSelectedOption = outboundData ? outboundData.options.find(option => option.selected) : null

    const widgetParticipant = {
      luggageInfo: {
        id: uniqueId,
        name: uniqueId,
        participantName: apiParticipant.firstName || apiParticipant.alternativeName,
        extraClasses: '',
        disabled: false,
        unresolved: false,
        totalPrice: this._calculateTotalPrice(outboundSelectedOption, inboundSelectedOption, apiService, configurations),
        outbound: outboundData,
        inbound: inboundData
      }
    }
    return widgetParticipant
  }

  _calculateTotalPrice (outboundSelectedOpt, inboundSelectedOpt, apiService, configurations) {
    const price = (outboundSelectedOpt ? outboundSelectedOpt.price : 0) + (inboundSelectedOpt ? inboundSelectedOpt.price : 0)
    const priceConfig = (configurations && configurations.priceConfiguration) || apiService.priceConfiguration
    return PriceFormatter.toFormattedText(price, priceConfig)
  }

  _mapDropdownData (dropdownData) {
    const { uniqueId, apiParticipant, apiService, directionGroup, labelDropdown, configurations } = dropdownData
    const directionOptions = apiService.options.filter(option => option.direction === directionGroup)

    const optionsThatApplies = directionOptions.filter(option => option.appliesToParticipants.includes(apiParticipant.id))
    const allOptionsAreNoneOption = optionsThatApplies.every(option => option.isNoneOption)
    const isDisabled = this._checkIfAllServiceOptionsAreDisabledForTheParticipant(optionsThatApplies, apiParticipant.id)

    const dropdownOption = directionOptions && directionOptions.length > 0 && !allOptionsAreNoneOption
      ? {
          id: `${uniqueId}_${directionGroup}`,
          label: !apiService.isOneWayFlight ? labelDropdown : '',
          disabled: isDisabled,
          options: optionsThatApplies.map(option => this._mapOption({ apiOption: option, apiParticipant, apiService, configurations }))
        }
      : null

    return dropdownOption
  }

  _mapOption (optionData) {
    const { apiOption, apiParticipant, apiService, configurations } = optionData
    const participantIds = apiParticipant.participantIds || [apiParticipant.id] || []
    let optionText = apiOption.displayText
    const priceConfig = (configurations && configurations.priceConfiguration) || apiService.priceConfiguration
    const priceFormatted = PriceFormatter.toFormattedText(apiOption.price, priceConfig)
    if (priceFormatted) {
      optionText = optionText + ' - ' + priceFormatted
    }
    const isDisabled = (apiOption.disabledParticipants ? apiOption.disabledParticipants.includes(apiParticipant.id) : false)

    const widgetItem = {
      ...this._mapOptionWidgetItem(apiOption),
      selected: apiOption.isSelected && apiOption.assignedParticipants ? apiOption.assignedParticipants.includes(apiParticipant.id) : false,
      disabled: isDisabled,
      text: optionText,
      participantIds
    }
    return widgetItem
  }

  _mapOptionWidgetItem (option) {
    return {
      id: option.code,
      value: option.code,
      text: option.displayText,
      selected: option.isSelected || false,
      participantIds: (option.assignedParticipants && option.assignedParticipants.length > 0) ? option.assignedParticipants : option.appliesToParticipants,
      price: option.price,
      appliesToParticipants: option.appliesToParticipants ? option.appliesToParticipants : null,
      assignedParticipants: option.assignedParticipants ? option.assignedParticipants : null,
      startDate: option.startDate || null,
      endDate: option.endDate || null
    }
  }

  _checkIfAllServiceOptionsAreDisabledForTheParticipant (options, apiParticipantId) {
    let disabled = false
    disabled = options.every((option) => {
      return option.disabledParticipants ? option.disabledParticipants.includes(apiParticipantId) : false
    })
    return disabled
  }

  _mapOneClickLuggageOptions (apiService, configurations) {
    if (!apiService.oneClickLuggageOptions && !apiService.options) { return null }

    let mapOptions = []
    if (apiService.oneClickLuggageOptions && apiService.oneClickLuggageOptions.length > 0) {
      mapOptions = apiService.oneClickLuggageOptions.filter(option => !option.isNoneOption).map(option => this._mapOneClickOptionItem(option, configurations, apiService))
    } else {
      const filteredOptions = apiService.options.filter(option => !option.isNoneOption && (option.direction === bookingLuggageDirections.outboundGroup))
      mapOptions = filteredOptions.map(option => this._mapOptionForOneClickOptionItem(option, configurations, apiService))
    }

    return mapOptions
  }

  _getHandLuggageAmountsIncluded (luggageDefinitionInformation = {}) {
    let handLuggageAmountIncluded = 0
    const handLuggageOutboundAmountIncluded = luggageDefinitionInformation?.outboundFlightDefinition?.handLuggageAmount || 0
    const handLuggageInboundAmountIncluded = luggageDefinitionInformation?.inboundFlightDefinition?.handLuggageAmount || 0

    if (handLuggageOutboundAmountIncluded === handLuggageInboundAmountIncluded) {
      handLuggageAmountIncluded = luggageDefinitionInformation?.outboundFlightDefinition?.handLuggageAmount || 0
    }

    return {
      outbound: handLuggageOutboundAmountIncluded,
      inbound: handLuggageInboundAmountIncluded,
      both: handLuggageAmountIncluded
    }
  }

  _mapOneClickOptionItem (option, configurations, apiService) {
    const priceConfig = (configurations && configurations.priceConfiguration) || apiService.priceConfiguration
    const handLuggageAmountsIncluded = this._getHandLuggageAmountsIncluded(apiService?.luggageDefinitionInformation)

    const widgetItem = {
      ...this._mapOptionWidgetItem(option),
      highlightText: option?.bestValueText || null,
      priceDescription: configurations?.oneClickLuggageTexts?.priceDescription || '',
      priceFormatted: PriceFormatter.toFormattedText(option.price, priceConfig),
      usps: this._mapOneClickLuggageUspsOption(configurations?.oneClickLuggageTexts?.luggageUsps, handLuggageAmountsIncluded),
      extraContent: this._mapOneClickLuggageExtraContent(option, configurations?.oneClickLuggageTexts?.outboundAndInboundDifferentText)
    }
    return widgetItem
  }

  _mapOptionForOneClickOptionItem (outboundOption, configurations, apiService) {
    const priceConfig = (configurations && configurations.priceConfiguration) || apiService.priceConfiguration
    const inboundOption = mapSameOptionForInboundDirection(outboundOption, apiService)
    const handLuggageAmountsIncluded = this._getHandLuggageAmountsIncluded(apiService?.luggageDefinitionInformation)

    const widgetItem = {
      ...this._mapOptionWidgetItem(outboundOption),
      text: outboundOption.oneClickLuggageDisplayText || '',
      selected: this._mapOneClickLuggageIfOptionIsSelected(outboundOption) && this._mapOneClickLuggageIfOptionIsSelected(inboundOption),
      highlightText: outboundOption?.bestValueText || null,
      priceDescription: configurations?.oneClickLuggageTexts?.priceDescription || '',
      priceFormatted: PriceFormatter.toFormattedText(outboundOption.price, priceConfig),
      usps: this._mapOneClickLuggageUspsOption(configurations?.oneClickLuggageTexts?.luggageUsps, handLuggageAmountsIncluded)
    }
    return widgetItem
  }

  _mapOneClickLuggageUspsOption (usps, handLuggageAmountsIncluded) {
    let filteredUsps = usps ? usps.filter(usp => usp.text !== '') : []
    if (!filteredUsps || filteredUsps.length === 0) { return null }
    const { outbound, inbound } = handLuggageAmountsIncluded

    if (outbound === inbound) {
      filteredUsps = filteredUsps.filter(usp => {
        if (usp.text.includes('{hand_luggage_outbound_amount}')) { return false }
        if (usp.text.includes('{hand_luggage_inbound_amount}')) { return false }
        return usp
      })
    } else {
      filteredUsps = filteredUsps.filter(usp => {
        return (!usp.text.includes('{hand_luggage_amount}'))
      })
    }

    const widgetUspInfo = {
      variant: '',
      extraClasses: 'm-body m-body--medium',
      items: filteredUsps && filteredUsps.map(usp => this._mapUsp(usp, handLuggageAmountsIncluded))
    }
    return widgetUspInfo
  }

  _mapUsp (apiUsp, handLuggageAmountsIncluded) {
    const { outbound, inbound, both } = handLuggageAmountsIncluded || { outbound: 0, inbound: 0, both: 0 }
    const text = apiUsp.text.replace('{hand_luggage_amount}', both).replace('{hand_luggage_outbound_amount}', outbound).replace('{hand_luggage_inbound_amount}', inbound)

    const widgetUsp = {
      text,
      icon: apiUsp.icon || 'checkmark'
    }

    return widgetUsp
  }

  _mapOneClickLuggageExtraContent (option, text = '') {
    const outboundAmount = option?.unitWeightOutbound || 0
    const inboundAmount = option?.unitWeightInbound || 0
    if (!text || (outboundAmount === 0 && inboundAmount === 0) || (outboundAmount === inboundAmount)) { return null }
    return text.replace('{outbound_luggage_amount}', outboundAmount).replace('{inbound_luggage_amount}', inboundAmount)
  }

  _mapOneClickLuggageNoOptions (apiService) {
    if (!apiService.oneClickLuggageOptions && !apiService.options) { return null }
    let noOptions = []
    if (apiService.oneClickLuggageOptions) {
      noOptions = apiService.oneClickLuggageOptions.filter(option => option.isNoneOption)
    } else {
      noOptions = apiService.options.filter(option => option.isNoneOption && (option.direction === bookingLuggageDirections.outboundGroup))
    }

    const optionsMapped = noOptions.map(option => this._mapOptionWidgetItem(option))
    return optionsMapped
  }

  _mapOneClickLuggageFlightsInformation (flightsInformation) {
    let flightsInformationText = ''
    const outboundInformation = flightsInformation && flightsInformation.outboundFlightDefinition
    const inboundInformation = flightsInformation && flightsInformation.inboundFlightDefinition
    if (outboundInformation) {
      flightsInformationText = `${outboundInformation.airportNameDeparture} - ${outboundInformation.airportNameArrival}, ${outboundInformation.airlineName}`
    }
    if (inboundInformation) {
      flightsInformationText += ` | ${inboundInformation.airportNameDeparture} - ${inboundInformation.airportNameArrival}, ${inboundInformation.airlineName}`
    }
    return flightsInformationText
  }

  _mapSwitchViewsInformation (configurations, apiService) {
    return {
      id: apiService.componentId + '_switch-views',
      text: configurations?.oneClickLuggageTexts?.textSwitchViews || '',
      checked: configurations?.choosePerPersonIsChecked || !this._checkIfOneServiceOptionAppliesToAllParticipantsOrNone(apiService),
      disabled: false,
      extraClasses: 'w-booking-luggage__one-click--switch-views',
      attributes: {
        'data-w-booking-luggage__one-click--switch-views': ''
      }
    }
  }

  _mapOneClickLuggageIfOptionIsSelected (option) {
    let isOptionSelectedForAllParticipants = false
    if (!option) { return isOptionSelectedForAllParticipants }
    if (option.isSelected && option.assignedParticipants && option.assignedParticipants.length > 0) {
      isOptionSelectedForAllParticipants = this._checkIfParticipantsMatch(option.assignedParticipants, option.appliesToParticipants)
    }
    return isOptionSelectedForAllParticipants
  }

  _checkIfOneServiceOptionAppliesToAllParticipantsOrNone (apiService) {
    let checked = false
    checked = apiService.options && apiService.options.every((option) => {
      return option.assignedParticipants && option.assignedParticipants.length > 0 ? this._checkIfParticipantsMatch(option.assignedParticipants, option.appliesToParticipants) : true
    })
    return checked
  }

  _checkIfParticipantsMatch (participantsFromOption, participantIds) {
    return Array.isArray(participantsFromOption) &&
      Array.isArray(participantIds) &&
      participantsFromOption.length === participantIds.length &&
      participantsFromOption.every((val, index) => val === participantIds[index])
  }
}

import { defaultTableData, TableTemplate } from '../table/c-table.template'
import { defaultTableItemData, TableItemTemplate } from '../table/c-table__item.template'

/**
 * The BreakdownTableTemplate contains all the data needed to hydrate a BreakDownTable component
 *
 * @typedef {Object}                    BreakdownTableItemData
 *
 * @property {String}                   concept       - The display text
 * @property {String}                   value         - The value to display
 * @property {BreakdownTableItemData[]} [subitems]    - The list of subitems
 * @property {Boolean}                  [isTotal]     - True if is total false otherwise
 * @property {Boolean}                  [isDiscount]  - True if is discount false otherwise
 */

/**
 * The BreakdownTableTemplate contains all the data needed to hydrate a BreakDownTable component
 *
 * @typedef {Object}                    BreakdownTableData
 *
 * @property {BreakdownTableItemData[]} items                  - Items the table
 * @property {String}                   extraContent           - The variant that will be used
 * @property {String}                   [topItemsExtraClasses]   - Extra classes of the top items
 * @property {String}                   [preItemsExtraClasses]   - Extra classes of the pre items
 * @property {String}                   [extraClasses]         - Extra classes if needed
 * @property {String}                   variant                - Variant if needed
 * @property {String}                   tableItemExtraClasses  - Extra classes for table if needed
 */

export const defaultBreakdownTableData = {
  items: [],
  topItemsExtraClasses: '',
  preItemsExtraClasses: '',
  extraContent: 'default',
  extraClasses: '',
  variant: '',
  tableItemExtraClasses: '',
  childrenExtraClasses: ''
}

/**
 * The BreakdownTableTemplate contains all the data needed to hydrate a BreakDownTable component
 *
 * @param {BreakdownTableData}      d - price breakdown data
 * @returns {String}                  - html
 */
export const BreakdownTableTemplate = (d) => {
  d = { ...defaultBreakdownTableData, ...d }
  const innerHTML = `
      ${d.items.map(item => {
        let extraClass = ' c-breakdown-table__item'
        extraClass = `${item.total
          ? ' c-breakdown-table__item c-breakdown-table__item--total'
          : item.isDiscount
            ? 'c-breakdown-table__item-content--discount'
            : ''
          } 
          ${d.tableItemExtraClasses}`
        return `
          ${TableItemTemplate(Object.assign({}, defaultTableItemData, {
            extraClasses: extraClass,
            innerHTML: ` ${item.preitem
              ? `<div class="c-breakdown-table__item-content c-breakdown-table__item-content--preitem ${item.preitem.preItemsExtraClasses} ">
                  <div class="c-breakdown-table__item-left">
                    ${item.preitem.concept ? item.preitem.concept : ''}
                  </div>
                  <div class="c-breakdown-table__item-right">
                    ${item.preitem.value ? item.preitem.value : ''}
                  </div>
                </div>`
              : ''
            }

            <div class="c-breakdown-table__item-content ${item.topItemsExtraClasses}" >
              <div class="c-breakdown-table__item-left">
                ${item.concept ? item.concept : ''}
              </div>
              <div class="c-breakdown-table__item-right">
                ${item.value ? item.value : ''}
              </div>
            </div>

            ${item.subitems
              ? item.subitems.map(subitem => {
                return `
                  ${SubItemTemplate(subitem)}
                  ${subitem.subitems && subitem.subitems.length > 0
                    ? `<div class="c-breakdown-table__item-content--children ${d.childrenExtraClasses || ''}">
                      ${subitem.subitems.map(child => SubItemTemplate(child)).join('')}  
                    </div>`
                    : ''
                  }`
              }).join('')
              : ''
            }`
          }))}
       `
      }).join('')}
  `
  return TableTemplate({
    ...defaultTableData,
    extraClasses: `c-breakdown-table ${d.extraClasses}`,
    variant: d.variant,
    innerHTML
  })
}

const SubItemTemplate = (subitem) => {
  return `
  <div class="c-breakdown-table__item-content c-breakdown-table__item-content--subitem ${subitem.extraClass || ''}">
    <div class="c-breakdown-table__item-left">
    ${subitem.isInfo
      ? `<span class="m-icon--${subitem.icon} c-breakdown-table__item-content--subitem--info">${subitem.concept}</span>`
      : `${subitem.concept
        ? subitem.concept
        : ''} `
    }
    </div>
    <div class="c-breakdown-table__item-right">
      ${subitem.value ? subitem.value : ''}
    </div>
  </div>`
}

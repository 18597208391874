import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import { bookingStepsEvents } from '../../../../shared/js/document/event-types'
import registeredEvents from '../../../../shared/js/helpers/registered-events'

const EventEmitter = require('eventemitter3')

const bookingDraft = require('../../../assets/api/booking-draft_step-4.json')
const bookingDraftMandatoryPayment = require('../../../assets/api/booking-draft_step-4__mandatory-payment.json')
const bookingDraftMandatoryPaymentRebooking = require('../../../assets/api/booking-draft_step-4__mandatory-payment-rebooking.json')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const handleFetched = (items) => {
  setTimeout(() => {
    const handleFetchedPromises = Array.from(items)
      .filter(element => !element.dataset.bookingNumber)
      .map(element => {
        const response = returnDataDependingOnComponentId(element.dataset.componentId)
        return element['w-booking-item'] && element['w-booking-item'].handleFetched({ response })
      })
    handleFetchedPromises.forEach(promise => promise.then(resolvedItem => { resolvedItem.enableUI() }))
  }, 1500)
}

const returnDataDependingOnComponentId = (componentId) => {
  switch (componentId) {
    case '3035454522222':
      return bookingDraftMandatoryPaymentRebooking
    case '222222221':
      return bookingDraftMandatoryPayment
    default:
      return bookingDraft
  }
}
const initBookingService = async () => {
  // Emulate event
  const items = document.querySelectorAll('[data-js-api--w-booking-item="w-payment"]')
  if (items.length > 0) {
    setTimeout(() => {
      const events = new EventEmitter()
      registeredEvents.registerWidgetEvents('w-booking-steps', events, { track: 'track' })
      events.emit(bookingStepsEvents.BOOKING_ITEM_BOOKINGDRAFT_FETCHED, bookingDraft)
    }, 1400)

    handleFetched(items)
  }

  document.addEventListener('razor-load-complete', function () {
    const items = document.querySelectorAll('[data-razor-url] [data-js-api--w-booking-item="w-payment"]')
    handleFetched(items)
  })
}

import { encodeAttributes } from '../../../js/helpers/string'

/**
 * The CalendarData contains all data needed to hydrate a calendar view
 *
 * @typedef {Object}          CalendarData
 *
 * @property {String}         id                  - The element id
 * @property {String}         extraClasses
 * @property {Object}         [attributes]        - Extra attributes
 */

export const defaultCalendarData = {
  extraClasses: '',
  attributes: {}
}

export const CalendarTemplate = (d) => {
  d = { ...defaultCalendarData, ...d }
  return `
    <div id="${d.id}" class="c-calendar ${d.extraClasses}" data-js-component="c-calendar" ${d.attributes ? encodeAttributes(d.attributes) : ''} >
        <input type="text" data-js-api="c-calendar__input" data-c-calendar__id="${d.id}" class="c-calendar__input">
        <div data-js-api="c-calendar__container" data-c-calendar__id="${d.id}" class="c-calendar__container"></div>  
    </div>`
}

import { registerWidget } from '../../../js/core/widget/widget-directory'
import { promotedPriceEvents, reviewsListEvents } from '../../../js/document/event-types'
import { getUrlFromString } from '../../../js/document/url'
import { fetchJsonData } from '../../../js/helpers/json-fetch'

// Ensure other child component APIs are loaded on time
require('../promoted-price/main')
require('../reviews-list/main')

const widgetApi = 'w-acco-structured-data'
const promotedPriceWidgetApi = 'w-promoted-price'
const reviewsListWidgetApi = 'w-reviews-list'
const defaultMsToWait = 4000

const widgetQueries = {
  urlAttr: `data-${widgetApi}__url`,
  MapUrlAttr: `data-${widgetApi}__map-url`,
  descriptionAttr: `data-${widgetApi}__description`,
  imageUrlAttr: `data-${widgetApi}__image-url`,
  nameAttr: `data-${widgetApi}__name`,
  reviewCountAttr: `data-${widgetApi}__review-count`,
  ratingValueAttr: `data-${widgetApi}__rating-value`,
  maxRatingValueAttr: `data-${widgetApi}__max-rating-value`,
  priceTextAttr: `data-${widgetApi}__price-text`,
  showReviewsAttr: `data-${widgetApi}__show-reviews`,
  anonymousAuthorTextAttr: `data-${widgetApi}__anonymous-author-text`,
  msToWaitAttr: `data-${widgetApi}__ms-to-wait`,
  reviewsUrlAttr: `data-${widgetApi}__reviews-url`,
  promotedPriceWidgetsSelector: `[data-js-widget="${promotedPriceWidgetApi}"]`,
  reviewsListWidgetsSelector: `[data-js-api="${reviewsListWidgetApi}"]`,
  extraParams: `[data-${widgetApi}__extra-params]`
}

export default class AccoStructuredData {
  /**
   * Creates a new AccoStructuredData
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element.
   * @param {options} [options={}] - Options object
   *
   */
  constructor (element, options = {}) {
    this.element = element

    this.options = {
      ...options,
      ...{
        url: this.decodeHtml(this.element.getAttribute(widgetQueries.urlAttr)),
        mapUrl: this.decodeHtml(this.element.getAttribute(widgetQueries.MapUrlAttr)),
        description: this.decodeHtml(this.element.getAttribute(widgetQueries.descriptionAttr)),
        imageUrl: this.decodeHtml(this.element.getAttribute(widgetQueries.imageUrlAttr)),
        name: this.decodeHtml(this.element.getAttribute(widgetQueries.nameAttr)),
        reviewCount: this.decodeHtml(this.element.getAttribute(widgetQueries.reviewCountAttr)),
        ratingValue: this.decodeHtml(this.element.getAttribute(widgetQueries.ratingValueAttr)),
        maxRatingValue: this.decodeHtml(this.element.getAttribute(widgetQueries.maxRatingValueAttr)),
        priceText: this.decodeHtml(this.element.getAttribute(widgetQueries.priceTextAttr)),
        showReviews: this.decodeHtml(this.element.getAttribute(widgetQueries.showReviewsAttr)),
        anonymousAuthorText: this.decodeHtml(this.element.getAttribute(widgetQueries.anonymousAuthorTextAttr)),
        msToWait: this.decodeHtml(this.element.getAttribute(widgetQueries.msToWaitAttr)),
        reviewsUrl: this.decodeHtml(this.element.getAttribute(widgetQueries.reviewsUrlAttr)),
        extraParams: this.getExtraParameters()
      }
    }

    this.scriptPrinted = false
    this.waitingPriceData = true
    this.waitingReviewsData = false
    this.mSecondsToWaitApiCalls = Number(this.options.msToWait) || defaultMsToWait

    // create the script element
    this.initScriptProp()

    // set up event listeners
    const promotedPriceElement = document.querySelector(widgetQueries.promotedPriceWidgetsSelector)
    if (promotedPriceElement) {
      const promotedPriceObject = promotedPriceElement[promotedPriceWidgetApi]
      if (promotedPriceObject && promotedPriceObject.events) {
        promotedPriceObject.events.once(promotedPriceEvents.PROMOTED_PRICE_DATA_LOADED, (response) => { this.addPriceDataInScript(response) })
      }
    }

    if (this.options.showReviews && this.options.showReviews !== 'null') {
      this.waitingReviewsData = true
      if (this.options.reviewsUrl) {
        this.addReviewsDataInScript()
      } else {
        const reviewsListWidgetsElement = document.querySelector(widgetQueries.reviewsListWidgetsSelector)

        const reviewsListObject = reviewsListWidgetsElement ? reviewsListWidgetsElement[reviewsListWidgetApi] : null

        if (reviewsListObject && reviewsListObject.events) {
          reviewsListObject.events.once(reviewsListEvents.REVIEWS_LIST_DATA_LOADED, (response) => { this.addReviewsDataInScript(response) })
        }
      }
    }

    setTimeout(function () { this.printScriptInThePageHead() }.bind(this), this.mSecondsToWaitApiCalls)

    // Expose component public API
    this.element[widgetApi] = {
      element: this.element,
      options: this.options
    }
  }

  /**
   * Returns the extra parameters that are exposed in the DOM
   *
   * @returns {string} - Returns the api url
   */
  getExtraParameters () {
    const extraParamsElements = this.element.querySelectorAll(widgetQueries.extraParams)
    const extraParams = extraParamsElements
      ? [...extraParamsElements].reduce((obj, el) => {
          obj[el.name] = el.value
          return obj
        }, {})
      : undefined

    return extraParams
  }

  initScriptProp () {
    let aggregateRating = ''

    if (this.options.maxRatingValue && this.options.reviewCount && this.options.ratingValue) {
      aggregateRating = '"aggregateRating" : {\n' +
        '"bestRating" : ' + this.options.maxRatingValue + ',\n' +
        '"worstRating" : 0,\n' +
        '"reviewCount" : ' + this.options.reviewCount + ',\n' +
        '"@type" : "AggregateRating",\n' +
        '"ratingValue" : "' + this.options.ratingValue + '"\n' +
        '},\n'
    }
    const description = this.options.description.replace(/"/g, "'").replace(/\\/g, '\\\\')

    this.script = document.createElement('script')
    this.script.type = 'application/ld+json'
    this.script.text = '{\n' +
      '"@type" : "Hotel",\n' +
      '"@context" : "http://schema.org",\n' +
      '"url" : "' + this.options.url + '",\n' +
      '"hasMap" : "' + this.options.mapUrl + '",\n' +
      '"description" : "' + description + '",\n' +
      '"image" : "' + this.options.imageUrl + '",\n' +
      (this.options.reviewCount > 0 ? aggregateRating : '') +
      '"name":"' + this.options.name + '"}'
  }

  addPriceDataInScript (data) {
    try {
      if (this.script.text.length && data) {
        const newMarkup = ',\n"priceRange" : "' + this.getPriceMarkupFromResponse(data) + '"\n'
        if (!this.scriptPrinted) {
          this.addContentToScript(newMarkup)
          this.waitingPriceData = false

          if (!this.waitingReviewsData) {
            this.printScriptInThePageHead()
          }
        }
      }
    } catch (ex) {
      // avoid error propagation
      console.log(ex)
    }
  }

  async addReviewsDataInScript (dataReviewsList) {
    try {
      let data = null
      if (dataReviewsList) {
        data = dataReviewsList
      } else {
        if (this.options.reviewsUrl) {
          const fetchUrl = getUrlFromString(this.options.reviewsUrl, this.options.extraParams)
          data = await fetchJsonData(fetchUrl).then((result) => {
            return result.data[0]
          })
        }
      }

      if (data) {
        if (this.script.text.length && data && data.reviews && this.options.reviewCount > 0) {
          let newMarkup = ',\n"review" : ['
          for (let i = 0; i < data.reviews.length; i++) {
            newMarkup += this.getReviewMarkupFromResponse(data.reviews[i])
            if (i < data.reviews.length - 1) {
              newMarkup += ',\n'
            }
          }
          newMarkup += ']'

          if (!this.scriptPrinted) {
            this.addContentToScript(newMarkup)
            this.waitingReviewsData = false

            if (!this.waitingPriceData) {
              this.printScriptInThePageHead()
            }
          }
        }
      }
    } catch (e) {
    }
  }

  getPriceMarkupFromResponse (response) {
    let price, currency, details

    // TODO: remove this once the w-promoted-price triggers the new response
    try {
      price = response.price.value.toString()
      currency = response.price.currency
      details = response.packageBlock.filters.join(', ')
    } catch (ex) {
      if (typeof price === 'undefined' || typeof currency === 'undefined' || typeof details === 'undefined') {
        price = response.price.value.toFixed(response.price.currencySettings.numberOfDecimals)
        currency = response.price.currencySettings.symbol
        details = response.featuredFilters.join(', ')
      }
    }

    let text = this.options.priceText

    if (!text) {
      text = '{price} {currency} ({details})'
    }

    text = text.replace('{price}', price)
    text = text.replace('{currency}', currency)
    text = text.replace('{details}', details)

    return text
  }

  getReviewMarkupFromResponse (response) {
    let text = '{' +
      '"@type": "Review",'

    let author = response.userFullName || this.options.anonymousAuthorText
    author = author.replace(/"/g, "'").replace(/\\/g, '\\\\')
    text += '"author": {\n' +
      '"@type": "Person",\n' +
      '"name": "' + author + '" \n}'

    if (response.comment) {
      const comment = response.comment.replace(/"/g, "'").replace(/\\/g, '\\\\')

      text += ',"description": "' + comment + '"'
    }
    if (response.rating && response.rating > 1) {
      text += ',"reviewRating": {\n' +
        '"@type": "Rating",\n' +
        '"bestRating": "10",\n' +
        '"ratingValue": "' +
        response.rating.toFixed(1).replace('.', ',') +
        '",\n' +
        '"worstRating": "0"}'
    }
    text += '}'

    return text
  }

  addContentToScript (newContent) {
    let scriptContent = this.script.text
    scriptContent = scriptContent.substring(0, scriptContent.lastIndexOf('}'))
    scriptContent += newContent + '}'
    this.script.text = scriptContent
  }

  printScriptInThePageHead () {
    if (!this.scriptPrinted) {
      this.scriptPrinted = true
      const head = document.getElementsByTagName('head')[0]
      head.appendChild(this.script)
    }
  }

  decodeHtml (html) {
    const txt = document.createElement('textarea')
    txt.innerHTML = html
    return txt.value
  }
}

registerWidget(AccoStructuredData, widgetApi)

/**
 * This data mapper helper convert a GetBookingDraft response into an
 * object that can be read by widget w-booking-basket
 */
import PriceFormatter from '../../../js/helpers/price-formatter'

export default class BookingBasketDataMapper {
  mapBasketInfo (response, configurations) {
    const widgetResponse = {
      totalPrice: response.bookingInfo ? response.bookingInfo.totalPrice : '',
      averagePrice: response.bookingInfo ? response.bookingInfo.avgPrice : '',
      paidAmount: response.bookingInfo ? response.bookingInfo.originalBookingPaidAmount : '',
      totalPackagePrice: response.bookingInfo ? response.bookingInfo.totalPackagePrice : '',
      avgtotalPackagePrice: response.bookingInfo ? response.bookingInfo.avgTotalPackagePrice : '',
      rebookingWarning: this._mapWarningMessage(response.bookingInfo),
      accommodationInfo: this._mapAccoInfo(response.accommodationInfo),
      packageBlockInfo: this._mapPackageBlockInfo(response.bookingInfo),
      overviewServices: this._mapOverviewServices(response, configurations)
    }
    return widgetResponse
  }

  _mapAccoInfo (accoInfo) {
    let acco = {}
    if (accoInfo) {
      acco = {
        name: accoInfo.name || '',
        starsNumber: accoInfo.numberOfStars || '',
        image: {
          sync: false,
          resolve: 'load',
          placeholderSrc: `${accoInfo.mainImage.original}?width=32&height=32&scale=both&mode=crop`,
          resolvedSrc: `${accoInfo.mainImage.original}?width={W}&height={H}&scale=both&mode=crop`,
          ratio: '4:3',
          title: accoInfo.name || '',
          dynamicRatios: [
            {
              ratio: '5:2',
              bp: 'sm'
            }
          ],
          extraClasses: 'w-booking-basket__image'
        },
        location: accoInfo.location ? accoInfo.location.map(item => ({ text: item })) : null
      }
    }
    return acco
  }

  _mapPackageBlockInfo (bookingInfo) {
    let packageInfo = {}
    if (bookingInfo) {
      packageInfo = {
        includedServices: bookingInfo.includedServices || null,
        departureDate: bookingInfo.departureDate,
        filters: bookingInfo.filters || null,
        extraClasses: 'w-booking-basket__package-block'
      }
    }
    return packageInfo
  }

  _mapOverviewServices (response, configurations) {
    const overviewServices = []
    if (response.overviewServices) {
      response.overviewServices.map(service => {
        const buttonAttributes = { ...(service.ariaLabel ? { 'aria-label': service.ariaLabel } : {}) }
        return overviewServices.push(
          {
            id: service.id,
            header: service.header,
            icon: service.icon,
            collapsed: service.isCollapsedByDefault,
            buttonAttributes,
            lines: service.lines.map(line => this._mapServiceLine(line, configurations))
          }
        )
      })
    }

    return overviewServices
  }

  _mapServiceLine (line, configurations) {
    let lineResponse = {
      text: line.text,
      isTitle: line.isTitle
    }
    lineResponse = { ...lineResponse, ...this._mapServiceLinePrice(line, configurations?.priceConfiguration), ...this._mapTotalServiceLinePrice(line, configurations) }
    return lineResponse
  }

  _mapServiceLinePrice (line, priceConfig) {
    if ((!line.price && line.price !== 0) || !priceConfig) { return }

    priceConfig = { ...priceConfig, suffix: line.isPricePerParticipant ? priceConfig.suffix : null }

    return {
      price: PriceFormatter.toFormattedText(line.price, priceConfig)
    }
  }

  _mapTotalServiceLinePrice (line, configurations) {
    let priceConfig = configurations?.priceConfiguration

    if ((!line.price && line.price !== 0) || !priceConfig) { return }
    const calculatedPriceLine = line.quantity ? line.price * line.quantity : line.price
    // overwrite priceConfig with null because we don't want to show the suffix in the total line price
    priceConfig = { ...priceConfig, suffix: null }

    const priceFormatted = PriceFormatter.toFormattedText(calculatedPriceLine, priceConfig)
    const priceToBeShown = (line.price && line.price > 0)
      ? priceFormatted
      : !configurations.priceFreeText ? priceFormatted : configurations.priceFreeText

    return {
      totalPrice: priceToBeShown
    }
  }

  _mapWarningMessage (bookingInfo) {
    let response
    if (bookingInfo && bookingInfo.rebookingWarning) {
      response = {
        messages: [{
          texts: [bookingInfo.rebookingWarning],
          type: 'success'
        }],
        extraClasses: 'w-booking-message__text_rebooking'
      }
    }
    return response
  }
}

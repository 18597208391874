export const widgetApi = 'w-booking-filterable-services'

export const widgetQueries = {
  filterConfigurationElement: `[data-${widgetApi}__filters-configuration]`,
  // apis
  numberStepperApi: 'c-number-stepper',
  buttonGroupApi: 'c-button-group',
  choiceListApi: 'c-choice-list',
  tabNavApi: 'c-tabs',
  buttonApi: 'c-btn',
  collapseApi: 'c-collapse',
  sideDrawerApi: 'c-side-drawer',
  dropdownApi: 'c-dropdown',
  calendarFieldApi: 'c-calendar-field',
  // filters:
  numberStepperFilters: `[data-${widgetApi}__filters-number-stepper]`,
  buttonGroupFilters: `[data-${widgetApi}__filters-button-group]`,
  choiceListFilters: `[data-${widgetApi}__filters-choice-list]`,
  tabNavFilters: '[data-js-component="c-tabs"]',
  dropdownFilters: `[data-${widgetApi}__filters-dropdown]`,
  dateFilters: `[data-${widgetApi}__filters-calendar-field]`,
  calendarField: '[data-c-calendar-field__filters-calendar]',
  dateCalendarDataModel: '[data-c-calendar-field__calendar-data-model]',
  timeSelector: `[data-${widgetApi}__time-dropdown]`,
  // Quantity component:
  quantityComponent: `[data-${widgetApi}__quantity-number-stepper]`,
  // Participants as Options:
  choiceListParticipantsAsOptions: `[data-${widgetApi}__service-participants-choice-list]`,
  participantAsOptionAdditionalText: `[data-${widgetApi}__service-participants-choice-list] .c-checkbox__additional-text`,
  // Options:
  choiceListServiceOptions: `[data-${widgetApi}__service-options-choice-list]`,
  // Buttons
  acceptButtons: `[data-${widgetApi}__service-accept-button]`,
  cancelButtons: `[data-${widgetApi}__service-cancel-button]`,
  // containers
  collapse: `[data-${widgetApi}__service-collapsible]`,
  sideDrawer: `[data-${widgetApi}__service-side-drawer]`,
  scrollable: '[data-c-collapse__scrollable]',
  // switch
  switch: `[data-${widgetApi}__service-switch]`,
  // container
  container: `[data-${widgetApi}__container]`,
  // Information items
  filterInfo: `[data-${widgetApi}__filter-info]`,
  filterInfoPrice: `[data-${widgetApi}__filter-info__price]`,
  filterInfoDescription: `[data-${widgetApi}__filter-info__price-description]`,
  emptyMessage: `[data-${widgetApi}__empty-message]`,
  validationMessages: `[data-${widgetApi}__validation-messages]`,
  timeMessages: `[data-${widgetApi}__time-messages]`
}

export const attr = {
  serviceId: `data-${widgetApi}__service-id`,
  filterId: `data-${widgetApi}__filter-id`,
  tabsId: 'data-c-tabs__tab-id',
  datasetServiceId: 'wBookingFilterableServices__serviceId',
  buttonGroupValue: 'wBookingFilterableServices__buttonGroupValue',
  trackAttr: 'data-track',
  filterType: `data-${widgetApi}__filter-type`
}

export const classNames = {
  hidden: 'is-hidden',
  isEmpty: 'is-empty',
  switchCheckbox: 'c-switch__checkbox',
  switchCircle: 'c-switch__circle'
}

export const texts = {
  saveButton: 'saveButton',
  cancelButton: 'cancelButton',
  emptyOptions: 'emptyOptions',
  noServiceSelected: 'noServiceSelected',
  selectServiceForFree: 'selectServiceForFree',
  serviceSelectedInfoText: 'serviceSelectedInfoText',
  occupancyValidationMessage: 'occupancyValidationMessage',
  timeChangedMessage: 'timeChangedMessage'
}

export const ageCategories = {
  adult: 'Adult',
  child: 'Child',
  baby: 'Baby'
}

export const CarRentalCheckoutAdviceTemplate = (d) => {
  return `${d && d !== ''
            ? `<div class="w-booking-car-rental__checkout-advice m-body m-body--tiny o-bullet o-bullet--block">
                <div class="o-bullet__icon">
                    <span class="m-icon m-icon--information-circle"></span>
                </div>
                <div class="o-bullet__text m-body m-body--tiny">
                    ${d}
                </div>
              </div>`
            : ''}`
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import PriceTable from '../../../../shared/modules/components/price-table/main'
import JSONFetcher from '../../../../shared/js/helpers/json-fetcher'

loadQueueHelper.add(function () {
  initPriceTable()
}, 2)

const initPriceTable = () => {
  const priceTableElement = document.getElementById('docs-price-table')
  if (priceTableElement) {
    const quickSearchRequest = new JSONFetcher({ src: '../assets/api/price-table-info.json' })
    quickSearchRequest.fetch().then(data => {
      // eslint-disable-next-line no-unused-vars
      const priceTable = new PriceTable(priceTableElement, { data, prevMonth: false })
      priceTable.events.on('prevEdge', () => new PriceTable(priceTableElement, { data, prevMonth: false, origin: 'end' }))
      priceTable.events.on('nextEdge', () => new PriceTable(priceTableElement, { data, nextMonth: false }))
    })
  }
}

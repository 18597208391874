import { FormTemplate } from '../../components/form/c-form.template'
import { FormSectionTemplate } from '../../components/form/c-form__section.template'
import { FormFieldsetTemplate } from '../../components/form/c-form__fieldset.template'
import { FormChangePatternConfigurationTemplate } from '../../components/form/c-form__change-pattern-configuration.template'
import { BoxTemplate } from '../../components/box/c-box.template'
import { ModalTemplate } from '../../components/modal/c-modal.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'

/**
 * The templateData contains all data needed to hydrate a PersonalDetailsEdit view
 *
 * @typedef  {Object}      templateData                                     - Contains the form fields, already with the current values and rules for the participant to edit.
 *
 * @property {Object[]}   templateData.formSections                        - The list of sections for the form and its fields
 * @property {string}     templateData.formSections[].title                - The title of the section
 * @property {Object[]}   templateData.formSections[].components           - The field components to render in this section. See data model for @see FormFieldsetTemplate
 * @property {string}     templateData.formSections[].components.type      - The name of the component to render. For example, 'c-textbox', 'c-date-selector'. Must be supported by @see FormFieldsetTemplate
 * @property {string}     templateData.formSections[].components.size      - The field size. For example, 'tiny'
 * @property {Object}     templateData.formSections[].components.data      - The actual settings for the component to render. See the data model for each component.
 * @property {Object}     templateData.changePatternSettings               - The settings to change pattern validations
 */

export const defaultPersonalDetailsEditFormData = {
  id: 'personal-details-manual-edit-42',
  formSections: [],
  changePatternSettings: {},
  showTopWarning: false,
  requestManualEditionData: {},
  requestManualEditionFormSections: []
}

export const PersonalDetailsEditFormTemplate = (d) => {
  d = { ...defaultPersonalDetailsEditFormData, ...d }
  const modalId = d.id + '-modal'
  return `<div>
      ${d.showTopWarning
        ? `${BoxTemplate({
          variant: 'flat',
          extraClasses: 'cs-state-warning cs-state-warning--light w-personal-details-edit__warning-message',
          html: `
            <span class="w-personal-details-edit__warning-message__icon m-icon m-icon--information-circle"></span>
            <span class="w-personal-details-edit__warning-message__text">${formatTopWarning(modalId, d.requestManualEditionData)}</span>`,
          attributes: {
            'data-w-personal-details-edit__warning-message': ''
          }
        })}`
        : ''}
        ${d.showTopWarning
          ? `${BoxTemplate({
            variant: 'flat',
            extraClasses: 'cs-state-info cs-state-info--light w-personal-details-edit__warning-message is-hidden',
            attributes: {
              'data-w-personal-details-edit__warning-request-sent-message': ''
            },
            html: `
              <span class="w-personal-details-edit__warning-message__icon w-personal-details-edit__warning-message__icon--info m-icon m-icon--information-circle"></span>
              <span class="w-personal-details-edit__warning-message__text">${d.requestManualEditionData.settings.externalEditableFieldsRequestSentWarning ? d.requestManualEditionData.settings.externalEditableFieldsRequestSentWarning : ''}</span>`
          })}`
          : ''}

      ${BoxTemplate({
        variant: 'flat',
        extraClasses: 'cs-default cs-default--light w-personal-details-edit__form',
        html: getForm(d.formSections, d.changePatternSettings)
      })}

      ${d.requestManualEditionData && d.requestManualEditionData.settings && d.showTopWarning
        ? `${ModalTemplate({
          size: 'large',
          id: modalId,
          async: false,
          extraClasses: 'w-personal-details-edit__request-manual-edition__modal',
          closable: true,
          openOnLoad: false,
          hiddenBackdrop: false,
          attributes: { 'data-w-personal-details-edit__request-manual-edition__modal': '' },
          style: 'neutral',
          title: d.requestManualEditionData.settings.title ? d.requestManualEditionData.settings.title : '',
          bodyContent: getRequestManualEditionModalBody(d.requestManualEditionData, d.requestManualEditionFormSections, d.changePatternSettings),
          footerContent: getRequestManualEditionModalFooter(modalId, d.requestManualEditionData)
        })}`
        : ''}
    </div>
`
}

function getForm (formSections, changePatternSettings) {
  return `${FormTemplate({
    hasSubmitButton: false,
    validate: true,
    attributes: {
      'data-w-personal-details-edit__form': ''
    },
    html: FormSectionTemplate({
      html: formSections.map(fieldset => FormFieldsetTemplate({
        title: fieldset.title,
        components: fieldset.components
      })).join('')
    }) + FormChangePatternConfigurationTemplate(changePatternSettings)
  })}`
}

function getRequestManualEditionModalBody (requestManualEditionData, formSections, changePatternSettings) {
  return `
    ${requestManualEditionData.settings.introText ? `<div>${requestManualEditionData.settings.introText}</div>` : ''}
    ${getForm(formSections, changePatternSettings)}
  `
}

function getRequestManualEditionModalFooter (modalId, requestManualEditionData) {
  return `
    ${requestManualEditionData.settings.cancelButtonText || requestManualEditionData.settings.saveButtonText
      ? `<div class="w-personal-details-edit__buttons">
        ${requestManualEditionData.settings.cancelButtonText
          ? BtnTemplate({
            variant: 'flat-neutral',
            attributes: {
              'data-c-modal__id': modalId,
              'data-c-modal__action': 'close'
            },
            text: requestManualEditionData.settings.cancelButtonText
          })
          : ''}
          ${requestManualEditionData.settings.saveButtonText
            ? BtnTemplate({
              variant: 'flow',
              attributes: { 'data-w-personal-details-edit__request-manual-edition__save-button': '' },
              text: requestManualEditionData.settings.saveButtonText
            })
            : ''}
        </div>`
      : ''}
  `
}

function formatTopWarning (modalId, requestManualEditionData) {
  let text = requestManualEditionData.settings.externalEditableFieldsWarning
    ? requestManualEditionData.settings.externalEditableFieldsWarning
    : ''
  const link = requestManualEditionData.settings.externalEditableFieldsWarningLinkText
    ? `<a data-c-modal__id="${modalId}" data-c-modal__action="open">${requestManualEditionData.settings.externalEditableFieldsWarningLinkText}</a>`
    : ''
  text = text.replace('{OPEN_MODAL_LINK}', link)

  return text
}

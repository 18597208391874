import Voucher from '../../voucher/main'
import { widgetQueries } from '../payment-constants'
import { paymentEvents } from '../../../../js/document/event-types'
import { elementFromString, flush } from '../../../../js/document/html-helper'
import Component from '../../../../js/core/component/component'
import { PaymentVoucherSelectorTemplate } from './w-payment-voucher-selector.template'

const DEFAULT_OPTIONS = {
  locales: {},
  vouchers: []
}

export default class VoucherSelector {
  constructor (element, options = {}) {
    this.element = element
    this.options = {
      ...DEFAULT_OPTIONS,
      ...options
    }
    this.locales = options.locales
    this.events = options.events
    this.vouchers = options.vouchers.filter(voucher => voucher.canBeUsed && voucher.isActive)

    this._render()
  }

  _render () {
    if (this.element) {
      const html = PaymentVoucherSelectorTemplate({
        ...this.locales,
        vouchers: this.vouchers || [],
        showBonusTexts: this.vouchers && this.vouchers.length > 0 && this.vouchers.some(v => v.bonus)
      })
      const newContent = elementFromString(html)
      Component.initDocumentComponentsFromAPI(newContent)
      flush(this.element)
      this.element.appendChild(newContent)

      const voucherElements = this.element.querySelectorAll(widgetQueries.voucher)
      this.voucherApis = []
      voucherElements.forEach((e, index) => {
        const voucher = this.vouchers[index]

        const voucherApi = new Voucher(e, {
          data: {
            variant: 'compact',
            price: {
              ...(voucher.amount && voucher.amount.value) && { value: voucher.amount.value },
              currencyPosition: voucher.amount.currencySettings.symbolPosition === 'BeforeAmount' ? 'before' : 'after',
              ...(voucher.amount && voucher.amount.currencySettings && voucher.amount.currencySettings.symbol) && { currency: voucher.amount.currencySettings.symbol }
            },
            bonus: {
              ...(voucher.bonus && voucher.bonus.amountLabel) && { amountLabel: voucher.bonus.amountLabel },
              ...(voucher.bonus && voucher.bonus.bonusLabel) && { bonusLabel: voucher.bonus.bonusLabel }
            },
            expirationText: this.locales.expirationText ? this.locales.expirationText.replace('{EXPIRATION_DATE}', voucher.expirationDate) : '',
            link: {
              href: '#'
            }
          }
        })
        this.voucherApis.push(voucherApi)
        voucherApi && voucherApi.events.on('voucherSelected', this._showVoucherSelectionOptions, this)
      })

      this._attachEvents()
    }
  }

  _attachEvents () {
    const addLink = this.element.querySelector(widgetQueries.voucherAddLink)
    addLink.addEventListener('click', this._clickedAddVoucher.bind(this), false)
  }

  _showVoucherSelectionOptions (voucherCode, ev) {
    ev && ev.preventDefault()
    const voucher = this.vouchers.find(v => v.voucherCode === voucherCode)
    voucher.isOwned = true
    voucher && this.events.emit(paymentEvents.VOUCHER_SELECTED, voucher)
  }

  _clickedAddVoucher (ev) {
    ev.preventDefault()
    this.events.emit(paymentEvents.ADD_VOUCHER)
  }
}

import { registerWidget } from '../../../js/core/widget/widget-directory'
import { apiCaller } from '../../../js/helpers/api-caller'

const classNames = {
  widgetApi: 'w-welcome-user',
  isUnresolved: 'is-unresolved'
}

const widgetQueries = {
  welcomeUserRequestUrl: `data-${classNames.widgetApi}__request-url`,
  welcomeUserText: `[data-${classNames.widgetApi}__text]`,
  welcomeUserAvatar: `[data-${classNames.widgetApi}__avatar]`,
  id: `data-${classNames.widgetApi}__id`
}

export default class WelcomeUser {
  /**
  * Creates a new welcome user widget.
  * @constructor
  * @param {HTMLElement} element The HTML element.
  */
  constructor (element) {
    this.element = element
    this._getHtmlElements()
    if (this.requestUrl) this._renderUserData(this.requestUrl)
  }

  /**
  * Get the necessary elements from the html
  */
  _getHtmlElements () {
    this.requestUrl = this.element.getAttribute(widgetQueries.welcomeUserRequestUrl)
    this.text = this.element.querySelector(widgetQueries.welcomeUserText)
    this.avatar = this.element.querySelector(widgetQueries.welcomeUserAvatar)
    this.id = this.element.getAttribute(widgetQueries.id)
  }

  /**
  * Renders the user data
  * @param {String} requestUrl
  */
  _renderUserData (requestUrl) {
    this._requestUserData(requestUrl).then(response => {
      let welcomeMessage = ''
      let name = ''
      if (response && response.data) {
        if (response.data.welcomeMessage && response.data.welcomeMessage.trim().length > 0) {
          welcomeMessage = response.data.welcomeMessage.trim()
        }
        if (response.data.name && response.data.name.trim().length > 0) {
          name = response.data.name.trim()
        }
      }
      this._addAvatar(name)
      if (!welcomeMessage) this.text.style.display = 'none'
      this.text.innerHTML = welcomeMessage
    }).finally(() => {
      this.text.classList.remove(classNames.isUnresolved)
    })
  }

  /**
  * Returns the requested user data
  * @param {String} requestUrl
  * @return {Promise}
  */
  async _requestUserData (requestUrl) {
    const result = await apiCaller(`${requestUrl}?contextItemId=${this.id}`)
    if (!result.success || !result.response) return null
    return result.response
  }

  /**
   * Adds an avatar with the first letter of the name, or hides the avatar if the name is not available
   * @param {String} name - The name to be used for the avatar
   */
  _addAvatar (name) {
    if (!name) {
      this.avatar.style.display = 'none'
    } else {
      this.avatar.textContent = name[0].toUpperCase()
      this.avatar.classList.remove(classNames.isUnresolved)
    }
  }
}

registerWidget(WelcomeUser, classNames.widgetApi)

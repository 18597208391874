// Helpers to be used inside DataMapper and main
import { bookingLuggageDirections } from './config'

export const mapSameOptionForInboundDirection = (outboundOption, apiService) => {
  const filteredInboundOptions = apiService.options.filter(option => !option.isNoneOption && (option.direction === bookingLuggageDirections.inboundGroup))
  const arrayMatchInboundCode = outboundOption.code.split('_')
  const outboundCodeMatching = arrayMatchInboundCode && arrayMatchInboundCode.length > 0 && arrayMatchInboundCode.pop()
  const inboundOption = filteredInboundOptions.find(inboundOption => inboundOption.code.includes(outboundCodeMatching))
  return inboundOption
}

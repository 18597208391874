import { propTypes as CardPricePropTypes, defaultProps as defaultCardPriceProps } from './c-card-price.props'
import { checkPropTypes } from 'prop-types'
import { encodeAttributes } from '../../../js/helpers/string'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'
import { PriceTemplate, defaultPriceData } from '../../components/price/c-price.template'
import { TitleTemplate, defaultTitleData } from '../../components/title/c-title.template'
import { ImgTemplate, defaultImgData } from '../../components/img/c-img.template'
import { getTokenClass } from '../../../js/helpers/tokens'

/**
 * The CardPriceData contains all data needed to hydrate a CardPrice view
 *
 * @typedef {Object}                CardPriceData
 *
 * @property {String}               [title]                             - Title in the card
 * @property {Object}               [image]                             - The image url to be shown in the card
 * @property {Object}               [price]                             - The price to be shown in the card
 * @property {String}               [description]                       - The description to be shown in the car
 * @property {Object}               [button]                            - The button info to be shown in the car
 * @property {String}               [extraClasses]                      - Extra classes to be appended on root element
 * @property {Object}               [attributes]                        - Extra attributes to be shown
 * @property {String}               [variant]                           - Car price variant
 */

// Import tokens
const tokensShared = require('./data/c-card-price__tokens.json')['c-card-price']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/c-card-price/data/c-card-price__tokens.json`)['c-card-price'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

/**
 * @param {CardPriceData} cardPriceData
 *
 * @returns {String}
 */
export const CardPriceTemplate = (cardPriceData) => {
  const d = { ...defaultCardPriceProps, ...cardPriceData }
  checkPropTypes(CardPricePropTypes, d, 'prop', 'CardPriceTemplate')

  return `
    <div class="c-card-price
      ${d.extraClasses ? d.extraClasses : ''}
      ${getTokenClass('variant', d.variant, tokens)}
      ${!d.price && d.variant === 'compact' ? 'c-card-price--without-price' : ''}
      ${d.button && d.variant === 'compact' ? 'c-card-price--hover-effect' : ''}"
      ${d.attributes ? encodeAttributes(d.attributes) : ''}>
      ${d.button && d.variant === 'compact'
      ? `<a class="c-card-price__link" href="${d.button.link.href}" title="${d.button.link.title}" target="${d.button.link.target}">`
      : ''}
      <div class="c-card__header o-block__header">
      ${d.button && d.variant !== 'compact'
        ? `<a href="${d.button.link.href}" title="${d.button.link.title}" target="${d.button.link.target}">
            ${CardImage(d.image)}
          </a>`
        : ` ${CardImage(d.image)} `
      }
      </div>

      <div class="c-card__body o-block__body
        ${getTokenClass('space-stack-children', d.variant, tokens)} m-body
        ${getTokenClass('body__size', d.variant, tokens)}">
        <div class="c-card-price__info">
          ${d.variant !== 'compact' ? '<div class="c-card-price__headings">' : ''}
            ${d.title
              ? TitleTemplate({
                ...defaultTitleData,
                ...{
                  text: d.title,
                  tag: d.titleTag,
                  size: '',
                  extraClasses: `c-card-price__title ${getTokenClass('header__size', d.variant, tokens)}`
                }
              })
            : ''}
            ${d.subtitle && d.variant !== 'compact'
              ? `
              <${d.subtitleTag ? d.subtitleTag : 'h4'}
                class="c-card-price__subtitle m-body ${getTokenClass('body__size', d.variant, tokens)}">
                ${d.subtitle}
              </${d.subtitleTag ? d.subtitleTag : 'h4'}>`
            : ''}
          </div>
          ${d.price
            ? `${PriceTemplate({
              ...defaultPriceData,
              ...d.price
            })}
          `
          : ''}
        ${d.variant !== 'compact' ? '</div>' : ''}
        <div class="c-card-price__description">${d.description}</div>
      </div>

      ${d.button && d.variant !== 'compact'
        ? `<div class="c-card__footer o-block__footer">
          ${BtnTemplate({ ...defaultButtonData, ...d.button })}
        </div>
      `
      : ''}
      ${d.button && d.variant === 'compact' ? '</a>' : ''}
    </div>`
}

const CardImage = (image) => {
  return `
    ${image
      ? `${ImgTemplate({ ...defaultImgData, ...image, extraClasses: 'c-card-price__img' })}`
      : `<div class="c-card-price__icon-wrapper o-ratio o-ratio--16:9">
        <span class="c-card-price__icon m-icon m-icon--image"></span>
      </div>`
    }`
}

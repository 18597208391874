import { bookedServiceEditEvents, bookedServicesListEvents, bookingItemEvents } from '../../../js/document/event-types'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { apiCaller } from '../../../js/helpers/api-caller'
import registeredEvents from '../../../js/helpers/registered-events'
import breakpoints from '../../../js/document/breakpoints'
import { getParamsFromURLForBookingSteps, buildUrlWithParams } from '../../../js/document/url'
import { removeUndefinedKeys } from '../../../js/helpers/object'
import { arrayifyObject } from '../../../js/helpers/arrayify-object'
import Component from '../../../js/core/component/component'
import * as utilsHelper from '../../../js/utils'
import domEventsHelper from '../../../js/document/dom-events-helper'
import { elementFromString } from '../../../js/document/html-helper'
import { fromCamelCase } from '../../../js/helpers/string'
import webStorage from '../../../js/document/web-storage'

const EventEmitter = require('eventemitter3')
const widgetApi = 'w-booked-service-edit'
const childWidgetApi = 'w-booking-item'

const classNames = {
  serviceSelected: 'service-is-selected',
  hidden: 'is-hidden'
}

const widgetQueries = {
  urlAttr: `data-${widgetApi}__url`,
  timeoutAttr: `data-${widgetApi}__timeout`,
  contextItemIdAttr: `data-${widgetApi}__contextitemid`,
  childWidgetsSelector: `[data-js-api--${childWidgetApi}]`,
  loaderElement: `[data-${widgetApi}__loader]`,
  trackAttr: 'data-track',
  bookingServicesItemsContainer: `.${widgetApi}__main-content`,
  actionButtonsElement: `[data-${widgetApi}__action-buttons]`,
  addToCartButtonElement: `[data-${widgetApi}__add-to-cart]`,
  hideIfHasErrorElement: `[data-${widgetApi}__hide-if-has-error]`,
  addToCartSuccessMessage: `[data-${widgetApi}__success-message]`,
  goToPaymentElementBlock: `[data-${widgetApi}__go-to-payment-element]`,
  flowButton: `[data-${widgetApi}__flow-button]`,
  breakpointUntil: `data-${widgetApi}__breakpoint-until`,
  elementWithErrorSelector: '.has-error',
  modalError: `[data-${widgetApi}__modal-error]`,
  modalConditions: `[data-${widgetApi}__modal-conditions]`,
  modalServiceNotAvailable: `[data-${widgetApi}__service-not-available-modal]`,
  modalApi: 'c-modal-v2',
  availableServicesContainer: `[data-${widgetApi}__services-container]`,
  identifierSearchName: 'sid',
  debugParam: 'debug'
}

const attr = {
  componentId: 'data-component-id',
  readMoreButtonText: 'data-w-booked-service-edit__more-information-button',
  deleteRelationUrl: `data-${widgetApi}__delete-relation-url`
}

const defaults = {
  breakpointUntil: 'md',
  resizeDelay: 200,
  timeout: 30000
}

export default class BookedServiceEdit {
  /**
  * Creates a new BookedServiceEdit
  *
  * @constructor
  *
  * @param {HTMLElement} element - The element where to attach BookedServiceEdit
  * @param {Object} options
  */
  constructor (element, options = {}) {
    this.element = element
    this.options = { ...defaults, ...options }
    this.debug = this._isDebugMode()

    this.events = new EventEmitter()
    this.registeredItems = []
    this.saveButtonClicked = false
    this.loaderElement = document.querySelector(widgetQueries.loaderElement)
    this.bookingServicesItemsContainer = this.element.querySelector(widgetQueries.bookingServicesItemsContainer)
    this.bookingServicesItems = this.bookingServicesItemsContainer.querySelectorAll(widgetQueries.childWidgetsSelector)

    this.actionButtonsElement = document.querySelector(widgetQueries.actionButtonsElement)
    this.hideIfHasErrorElements = document.querySelectorAll(widgetQueries.hideIfHasErrorElement)
    this.addToCartSuccessMessage = document.querySelector(widgetQueries.addToCartSuccessMessage)
    this.goToPaymentElementBlock = document.querySelector(widgetQueries.goToPaymentElementBlock)
    this.flowButtons = this.goToPaymentElementBlock ? this.goToPaymentElementBlock.querySelectorAll(widgetQueries.flowButton) : null
    this.addToCartButton = this.actionButtonsElement ? this.actionButtonsElement.querySelector(widgetQueries.addToCartButtonElement) : null
    this.addToCartButtonApi = this.addToCartButton ? this.addToCartButton['c-btn'] : null

    this.modalConditionsElement = document.querySelector(widgetQueries.modalConditions)
    this.modalServiceNotAvailable = document.querySelector(widgetQueries.modalServiceNotAvailable)

    this.cancelButtonChanges = this.element.querySelector(`[${attr.deleteRelationUrl}]`)
    this.availableServicesContainer = this.element.querySelector(widgetQueries.availableServicesContainer)

    this.timeout = this.element.getAttribute(widgetQueries.timeoutAttr) || this.options.timeout

    this.addToCartButtonClicked = false

    this.currentParams = { ...getParamsFromURLForBookingSteps() }

    this._buildEvents()
    this._toggleButtonsVisibility()
    this._attachEvents()

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.trackAttr) && { track: this.element.attributes[widgetQueries.trackAttr].value }
    })

    const bookingItems = this.element.querySelectorAll(widgetQueries.childWidgetsSelector)
    bookingItems.forEach(item => {
      this._createLinkOpenModalMoreInfo(item)
      item[childWidgetApi].events.on(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, this._onComponentDataChanged.bind(this))
      item[childWidgetApi].events.on(bookedServicesListEvents.BOOKED_SERVICES_LIST_SERVICE_SELECTED, this._showSelectedService.bind(this))
      item[childWidgetApi].events.on(bookedServicesListEvents.BOOKED_SERVICES_LIST_SERVICE_NOT_SELECTED, this._showNotServiceSelected.bind(this))
      item[childWidgetApi].events.on(bookedServicesListEvents.BOOKED_SERVICES_LIST_SERVICE_NOT_AVAILABLE, this._showServiceNotAvailable.bind(this))
      item[childWidgetApi].events.on(bookedServiceEditEvents.SAVE_CHANGES_PROCESS_STARTED, this._onSaveChangesProcessStarted.bind(this))
      this.registeredItems.push(item[childWidgetApi])
    })

    const url = this.element.getAttribute(widgetQueries.urlAttr)
    if (url) {
      const getUrl = this._enrichUrlWithDefaultQueryString(url)
      try {
        this.callApi('GET', getUrl, undefined, true)
      } catch (exception) {
        this._hideLoader()
      }
    }

    this.element[widgetApi] = {
      events: this.events
    }
  }

  _attachEvents () {
    if (this.addToCartButtonApi) {
      this.addToCartButtonApi.events.on('clickButton', () => {
        this.addToCartButtonClicked = true
        this._showGoToPaymentButtons()
      })
    }

    if (this.cancelButtonChanges) {
      const url = this.cancelButtonChanges.getAttribute(attr.deleteRelationUrl)
      const cancelButton = this.cancelButtonChanges.querySelector('a')
      const buttonApi = cancelButton ? cancelButton['c-btn'] : null
      cancelButton.setAttribute('onclick', 'return false;')
      if (buttonApi) {
        buttonApi.events.on('clickButton', () => {
          buttonApi.setLoading(true)
          buttonApi.setProp('disabled', true)
          this.deleteBookedServiceChanges(cancelButton, url)
        })
      }
    }

    if (this.availableServicesContainer) {
      const cancelSideDrawerButton = this.availableServicesContainer.querySelector('.w-booked-services-list__side-drawer__close-button')
      const cancelSideDrawerButtonApi = cancelSideDrawerButton ? cancelSideDrawerButton['c-btn'] : null
      if (cancelSideDrawerButtonApi) {
        cancelSideDrawerButtonApi.events.on('clickButton', () => {
          if (this.currentParams.serviceType === undefined) {
            const info = this._getPreviousPageInfo()
            if (info && info.url) { this._gotoUrl(info.url) }
          }
        })
      }
    }
  }

  async deleteBookedServiceChanges (cancelButton, url) {
    const paramsToBeSend = { bookingNumber: this.currentParams.bookingNumber }
    const newUrl = this._enrichUrlWithDefaultQueryString(url, paramsToBeSend)
    await apiCaller(newUrl, { method: 'DELETE' })
    this._gotoUrl(cancelButton.getAttribute('href'))
  }

  _createLinkOpenModalMoreInfo (bookingItem) {
    const currentBookingItemButton = bookingItem.querySelector('.w-booking-box__heading-info')
    if (currentBookingItemButton) {
      const bookingDescription = bookingItem.querySelector('.w-booking-box__description')
      const currentBookingItemButtonAttributes = currentBookingItemButton.dataset
      if (this.element.getAttribute(attr.readMoreButtonText)) {
        const html = `<a class="w-booked-service-edit__booking-item-modal-button"
                    ${currentBookingItemButtonAttributes
                      ? Object.keys(currentBookingItemButtonAttributes).map(k => `data-${fromCamelCase(k)}="${currentBookingItemButtonAttributes[k]}"`).join(' ')
                      : ''}>
                      ${this.element.getAttribute(attr.readMoreButtonText)}
                    </a>`
        const newContent = elementFromString(html)
        if (bookingDescription) {
          bookingDescription.appendChild(newContent)
        }

        Component.initDocumentComponentsFromAPI(bookingItem)
        Component.initComponentActionElements(bookingItem)
      }
    }
  }

  /**
  * Callback to be executed when the event 'booking-item-changed' is triggered
  * @param {object} data
  */
  async _onComponentDataChanged (data) {
    this.events.emit(bookedServiceEditEvents.ANCILLARY_DATA_CHANGED, data)
    this.registeredItems.forEach(item => { item.handleFetching() })
    const url = this._enrichUrlWithDefaultQueryString(data.url, data.params)
    this.callApi(data.method, url, data.body)
  }

  /**
   * Update Browser's url adding checkoutId params
   * - Should happen after first call
   * - The main purpose is using checkoutId to work with this
   *
   * @returns {BookedServicesList} self instance
   */
  _updateBrowserUrl (checkoutId) {
    this.currentParams = { ...this.currentParams, ...{ sid: checkoutId } }
    window.history.replaceState(
      '',
      '',
      buildUrlWithParams(
        document.URL.split('?')[0],
        arrayifyObject({
          ...removeUndefinedKeys(this.currentParams)
        })
      ))
    return this
  }

  _getIdentifierFromUrl () {
    let identifier
    const urlParams = new window.URLSearchParams(window.location.search)
    if (urlParams) {
      identifier = urlParams.get(widgetQueries.identifierSearchName)
    }
    return identifier !== null ? identifier : undefined
  }

  _getPreviousPageInfo () {
    let info
    const identifier = this._getIdentifierFromUrl()
    if (identifier) {
      info = webStorage.session.get(identifier)
    }
    return info
  }

  /**
   * Calls backend to retrieve data, and pings all registred items
   *
   * @param {string} method - GET|POST|PATCH
   * @param {string} uri - Full url to execute the action
   * @param {object} body - (Optional) The payload for the POST or PATCH operations
   */
  async callApi (method, url, body, isFirstCall) {
    const result = await apiCaller(url, { method, body })
    const isSuccess = this._getIsSuccess(result)
    this._hideLoader(isFirstCall)

    if (isSuccess && result.response.isEditable) {
      if (isFirstCall) {
        // Update Url with SID ...
        this._enrichUrlWithDefaultQueryString(url)
        this._updateBrowserUrl(result.response.draftId)
        this.events.emit(bookedServiceEditEvents.BOOKED_SERVICE_EDIT_FETCHED, result.response)
      } else {
        this.events.emit(bookedServiceEditEvents.BOOKED_SERVICE_EDIT_FETCHED_ITEM_CHANGED, result.response)
      }

      const simultaneouslyHandleFetchedPromises = this.registeredItems
        .filter(item => !item.isDeferredAndResolvedByItself())
        .map(item => item.handleFetched(result))

      const deferredHandleFetchedPromises = this.registeredItems
        .filter(item => item.isDeferredAndResolvedByItself())
        .map(item => item.handleFetched(result))

      Promise.all(simultaneouslyHandleFetchedPromises).then(resolvedItems => {
        resolvedItems.forEach(resolvedItem => {
          resolvedItem.enableUI()
          resolvedItem.emitAvailableServiceEvent(isFirstCall)
        })
        deferredHandleFetchedPromises.forEach(promise => promise.then(resolvedItem => {
          resolvedItem.enableUI()
          resolvedItem.emitAvailableServiceEvent(isFirstCall)
        }))
      }).then(() => {
        this.registeredItems.forEach(item => item.handleAllRendered())
        if (!isFirstCall) {
          this._toggleDisabledButtonState()
        }
      })

      if (this.saveButtonClicked) {
        await this._validateClient().isValid
      }
    } else {
      this.events.emit(bookedServiceEditEvents.API_CALL_FAILED, result)
      this.hideIfHasErrorElements.forEach(element => element.classList.add(classNames.hidden))
      const handleErrorPromises = this.registeredItems.map(item => item.handleError(result))
      Promise.all(handleErrorPromises).then(() => {
        this.registeredItems.forEach(item => item.setHasError())
      })
    }
  }

  /**
  * Checks if api call succeed and if it was, the checks that the response from the api doesn't contains errors
  * @param {object} apiResult
  */
  _getIsSuccess (apiResult) {
    let isSuccess = apiResult.success
    if (isSuccess) {
      if (!!apiResult.response && !!apiResult.response.error) {
        isSuccess = !apiResult.response.error.hasError
      }
    }
    return isSuccess
  }

  /**
   * It adds the default params to the url, if the given url already contains params,
   * default params are appended at the end
   *
   * @returns {string} url
   */
  _enrichUrlWithDefaultQueryString (url, params) {
    url = url || ''
    const urlData = {
      ...params,
      ...this.currentParams,
      ...{
        timeout: this.timeout,
        contextItemId: this.element.getAttribute(widgetQueries.contextItemIdAttr)
      }
    }

    const queryString = Object.keys(urlData).map(key => key + '=' + urlData[key]).join('&')
    const andOrQuestionMark = url.includes('?') ? '&' : '?'
    return `${url}${andOrQuestionMark}${queryString}`
  }

  _hideLoader (isFirstCall = true) {
    if (isFirstCall && this.loaderElement) {
      this.loaderElement.classList.add(classNames.hidden)
    }
  }

  _showLoader () {
    if (this.loaderElement) {
      this.loaderElement.classList.remove(classNames.hidden)
    }
  }

  _showSelectedService (eventArgs) {
    this.bookingServicesItems.forEach(item => {
      if (eventArgs === item.getAttribute(attr.componentId)) {
        this._toggleActionButtons()
        item.classList.add(classNames.serviceSelected)
        item[childWidgetApi].showComponent()
      } else {
        if (!this._checkIfModalConditionsIsOpen()) {
          item[childWidgetApi].hideComponent()
          item.classList.remove(classNames.serviceSelected)
        }
      }
    })
    this._toggleDisabledButtonState(true)
    this._toggleButtonsVisibility()
    this._toggleContinueAddingElements(true)
    if (!this.addToCartButtonClicked) {
      this._toggleGoToPaymentElementBlock(true)
    }
    if (this.addToCartButtonClicked) {
      this._toggleActionButtons()
    }
  }

  _checkIfModalConditionsIsOpen () {
    let modalIsOpen = false
    modalIsOpen = this.modalConditionsElement && this.modalConditionsElement[widgetQueries.modalApi].getProp('open')
    return modalIsOpen
  }

  _showServiceNotAvailable (eventArgs) {
    this._showNotServiceSelected()
    this.modalServiceNotAvailable && this.modalServiceNotAvailable[widgetQueries.modalApi].open()
    this.events.emit(bookedServiceEditEvents.BOOKED_SERVICE_EDIT_SERVICE_NOT_AVAILABLE, eventArgs)
  }

  _showNotServiceSelected () {
    this.bookingServicesItems.forEach(item => {
      item[childWidgetApi].hideComponent()
    })
  }

  _showGoToPaymentButtons () {
    if (window.innerWidth < breakpoints[this.options.breakpointUntil]) {
      if (this.addToCartSuccessMessage) {
        this.addToCartSuccessMessage[childWidgetApi].showComponent()
      }
      this._toggleContinueAddingElements()
      this._toggleGoToPaymentElementBlock()
      this._toggleActionButtons(true)
      this._toggleDisabledButtonState(true)
    }
  }

  _toggleActionButtons (isHidden = false) {
    this.actionButtonsElement.classList.toggle(classNames.hidden, isHidden)
  }

  _toggleDisabledButtonState (isDisabled = false) {
    if (this.addToCartButtonApi) {
      this.addToCartButtonApi.setProp('disabled', isDisabled)
    }
  }

  _toggleGoToPaymentElementBlock (isHidden = false) {
    this.goToPaymentElementBlock.classList.toggle(classNames.hidden, isHidden)
  }

  _toggleContinueAddingElements (isHidden = false) {
    this.flowButtons.forEach(button =>
      button.classList.toggle(classNames.hidden, isHidden)
    )
  }

  _toggleButtonsVisibility (isHidden = false) {
    this.currentParams = { ...getParamsFromURLForBookingSteps() }
    isHidden = (window.innerWidth < breakpoints[this.options.breakpointUntil] && !(this.currentParams.serviceType === undefined))
    this._toggleActionButtons(!isHidden)
  }

  _buildEvents () {
    this._events = [[window, {
      resize: utilsHelper.debounce(() => {
        this._toggleButtonsVisibility()
      }, this.options.resizeDelay)
    }]]
    domEventsHelper.attachEvents(this._events, widgetApi)
    return this
  }

  /**
   * Callback to be executed when the event 'next-step-process-started' is triggered
   */
  async _onSaveChangesProcessStarted (ev) {
    try {
      // This will be needed to disable all components till all validations finish
      this.registeredItems.forEach(item => { item.disableUI() })
      this.saveButtonClicked = true
      // 1 Validate clientSide components
      this.debug && console.log('Validating at client side.....')
      const validateComponents = await this._validateClient()
      const resultValidation = validateComponents.isValid

      this.debug && console.log(`... client side done. With result ${resultValidation}`)
      if (!resultValidation) {
        this._emitConditionsValidationFailedEvent(validateComponents.conditionsValidationFailed)
        this._focusFirstElementWithError()
        this.registeredItems.forEach(item => item.enableUI())
        return
      }

      // 2- Validate Server Side
      this.debug && console.log('Validating at server side.....')
      const resultServerValidationSuccess = await this._validateServer()
      this.debug && console.log(`... server side done. With result ${resultServerValidationSuccess}`)
      if (!resultServerValidationSuccess) {
        this._showModalError()
        this.registeredItems.forEach(item => item.enableUI())
        return
      }

      // 3- Save Changes
      if (ev.saveUrl && ev.method) {
        const resultSaveSuccess = await this._saveChanges(ev.saveUrl, ev.method, this.timeout)
        if (!resultSaveSuccess.success) {
          this._showModalError()
          const eventArgs = { ...resultSaveSuccess, fieldType: ev.elementType }
          this.events.emit(bookedServiceEditEvents.SAVE_CHANGES_TO_BOOKING_FAILED, eventArgs)
          this.registeredItems.forEach(item => item.enableUI())

          if (window.newrelic) {
            window.newrelic.noticeError('[SelfServiceEditFetchedError] Error with:' + JSON.stringify(eventArgs))
          }
          return
        } else {
          const eventArgs = { ...resultSaveSuccess }
          this.events.emit(bookedServiceEditEvents.SAVE_CHANGES_TO_BOOKING_SUCCESS, eventArgs)
        }
      }

      // 4 Perform any action needed before moving to next step (like pop up)
      this.modalConditionsElement && this.modalConditionsElement[widgetQueries.modalApi].close()
      const resultBeforeNextStep = await this._beforeNextStep()
      if (!resultBeforeNextStep) {
        this.registeredItems.forEach(item => item.enableUI())
        return
      }

      // 5 - Redirect if success
      if (ev.url) {
        this._gotoUrl(ev.url)
      }
    } catch (error) {
      // Todo: stop saving
    }
  }

  async _validateClient () {
    const validationPromises = []
    const conditionsValidationPromises = []
    this.registeredItems.forEach(item => {
      validationPromises.push(item.handleValidationClient())
      conditionsValidationPromises.push(item.handleConditionsValidation())
    })

    const results = await Promise.all(validationPromises)
    const conditionsValidationResults = await Promise.all(conditionsValidationPromises)
    const allComponentsAreValid = results.every(result => result) && conditionsValidationResults.every(result => result.isValid)
    const conditionsValidationFailed = conditionsValidationResults.filter(result => !result.isValid).map(item => ({ serviceName: item.serviceName, fieldType: item.fieldType })) || []

    return { isValid: allComponentsAreValid, conditionsValidationFailed }
  }

  async _validateServer () {
    let index = 0
    let noErrors = true
    while (index < this.registeredItems.length && noErrors) {
      const data = await this.registeredItems[index].getValidationData()
      if (data.url) {
        const url = this._enrichUrlWithDefaultQueryString(data.url, data.params)
        const result = await apiCaller(url, { method: data.method, body: data.body, isForm: data.isForm ? data.isForm : false })
        if (result.success) {
          noErrors = await this.registeredItems[index].handleValidationServer(result.response)
        } else {
          noErrors = false
        }
      }
      index++
    }
    return noErrors
  }

  async _saveChanges (saveUrl, method, timeout) {
    this._showLoader()
    saveUrl = this._enrichUrlWithDefaultQueryString(saveUrl)
    const resultSave = await apiCaller(saveUrl, { method, timeout })
    this._hideLoader()
    return { ...resultSave }
  }

  _focusFirstElementWithError () {
    const firstElementWithError = document.querySelector(widgetQueries.elementWithErrorSelector)
    if (firstElementWithError != null) {
      const firstParentElementWithError = firstElementWithError.closest(widgetQueries.childWidgetsSelector)
      this.events.emit(bookedServiceEditEvents.SAVE_CHANGES_PROCESS_FAILED, firstParentElementWithError.getAttribute(attr.componentId))
      firstElementWithError.scrollIntoView({ behavior: 'smooth' })
    }
  }

  _emitConditionsValidationFailedEvent (conditionsValidationFailed = []) {
    if (conditionsValidationFailed.length > 0) {
      conditionsValidationFailed.forEach(condition => {
        this.events.emit(bookedServiceEditEvents.CONDITIONS_VALIDATION_FAILED, { ...condition })
      })
    }
  }

  _showModalError () {
    const modalError = this.element.querySelector(widgetQueries.modalError)
    if (modalError) {
      this.modalConditionsElement && this.modalConditionsElement[widgetQueries.modalApi].close()
      modalError[widgetQueries.modalApi].open()
    }
  }

  async _beforeNextStep () {
    const beforeNextStepPromises = []
    this.registeredItems.forEach(item => { beforeNextStepPromises.push(item.beforeNextStep()) })
    const results = await Promise.all(beforeNextStepPromises)
    return results.every(result => result)
  }

  _gotoUrl (url) {
    window.location.href = url
  }

  _isDebugMode () {
    const urlParams = new URLSearchParams(window.location.search)
    const debugParam = urlParams.get(widgetQueries.debugParam)
    return !!debugParam
  }
}

registerWidget(BookedServiceEdit, widgetApi)

/**
 *
 * @global
 * @typedef {Object}       RoomData
 *
 * @property {String}      id                                 - Room Id, like: "2PKA"
 * @property {String}      name                               - Room name
 * @property {String}      subtitle                           - Room description
 * @property {Number}      stock                              - Room stock
 * @property {String[]}    characteristics                    - Room characteristics
 *
 */

/**
 * A Room
 */

export default class Rooms {
  /**
   * Creates a new model
   *
   * @constructor
   * @param {RoomData[]} roomsData
   */
  constructor (roomsData) {
    this.data = roomsData
  }

  /**
   * Get Room by Id
   *
   * @param {String}                        id                - Room id
   *
   * @returns {RoomData|undefined}
   */
  getRoomById (roomId) {
    return this.data.find(room => room.id === roomId)
  }
}

/**
 * The defaultBookingVoucherListData contains all data to render voucher information
 */

export const defaultBookingVouchersListData = {
  expirationText: '',
  vouchersEmptyText: ''
}

export const BookingVouchersList = (d) => {
  d = { ...defaultBookingVouchersListData, ...d }
  return `${d.vouchers.length > 0
          ? `<div class="w-booking-vouchers__container">
            ${d.vouchers.map(voucher => '<div class="w-booking-vouchers__voucher-widget" data-w-booking-vouchers__voucher></div>').join('')}
          </div>`
          : `<p>${d.vouchersEmptyText}</p>`
        }`
}

import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import { BookingLuggageTemplate } from './w-booking-luggage.template'
import BookingLuggageDataMapper from './data-mapper'
import { BookingLuggageMessageTemplate } from './w-booking-luggage__info-message.template'
import { BookingLuggagePriceTemplate } from './w-booking-luggage__price.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import { bookingItemEvents, bookingLuggageEvents } from '../../../js/document/event-types'
import Component from '../../../js/core/component/component'
import { cleanUpSpecialCharacters, toCamelCase } from '../../../js/helpers/string'
import registeredEvents from '../../../js/helpers/registered-events'
import { widgetApi, widgetQueries, attr, configOptions, apis, bookingLuggageConsts, classNames, bookingLuggageDirections, settings, bookingLuggageTypes } from './config'
import { mapSameOptionForInboundDirection } from './helpers'
import webStorage from '../../../js/document/web-storage'
import * as utilsHelper from '../../../js/utils'
import domEventsHelper from '../../../js/document/dom-events-helper'

/**
 * BookingLuggage widget
 */
export default class BookingLuggage extends BookingBox {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   * @param {Object} [options={}]   - Options
   */
  constructor (element, options = {}) {
    super(element)
    this.element = element
    this.dropdownApis = null
    this.choiceListHasSpecialLuggageApis = null
    this.prices = null
    const filterConfigurationElement = this.element.querySelector(widgetQueries.configurationElement)
    this.configurations = this._readOptionsFromConfigurations(filterConfigurationElement) || {}
    this.texts = this._readTextsFromAttributes()
    this.dataMapper = new BookingLuggageDataMapper()
    this.specialLuggageUrl = this.element.dataset[attr.specialLuggageUrl]
    this.showSpecialLuggageCheckbox = this.element.dataset[attr.showSpecialLuggageCheckbox] === 'true'
    this.serviceName = null
    this.choosePerPersonIsChecked = false
    this.draftId = null
    this.selectOptionElements = []

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.trackAttr) && { track: this.element.attributes[attr.trackAttr].value }
    })

    this._buildResizeEvents()
    // Attach events to the luggage elements in the widget
    this._attachEvents()

    this.element[widgetApi] = {
      showOneClickLuggage: (this.setShowOneClickLuggage).bind(this),
      showSpecialLuggageSwitch: (this.setShowSpecialLuggageSwitch).bind(this)
    }
  }

  /**
   *  Will be executed once the mediator has fetched a response from the backend
   *
   * @param {Object}    data      - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.data = data.response
    this.draftId = this.data.draftId
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  enableUI () {
    super.enableUI()
    this._setOneClickLuggageSliderIndex()
  }

  getServiceName () {
    return this.serviceName || toCamelCase(widgetApi) || ''
  }

  /**
   *  When a change is made in the dropdown component and a 'changedOptions' event is emitted,
   *  this is the method that will be executed to collect all the data needed to do a backend
   *  call and set/unset the option
   *
   * @param {DropdownComponent} dropdown The modified dropdown object
   * @param {*} options All the current options of the component
   * @param {*} oldOptions All the old options that were set in the component before being modified
   */
  _clickedOptionWithOptions (dropdown, options, oldOptions) {
    const selectedOption = options.find(o => o.selected)
    const selectedOldOption = oldOptions.find(o => o.selected)
    const operationType = this._mapOperationType(selectedOption, selectedOldOption)
    const eventArgs = this._mapArgsToBeSend(operationType, selectedOption, selectedOldOption)
    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  _mapArgsToBeSend (operationType, selectedOption, selectedOldOption, luggageServiceType = bookingLuggageTypes.luggage) {
    // Map services
    const newService = selectedOption
      ? {
          code: selectedOption.value,
          participants: selectedOption.participantIds,
          startDate: selectedOption.startDate,
          endDate: selectedOption.endDate
        }
      : null

    const oldService = selectedOldOption
      ? {
          code: selectedOldOption.value,
          participants: selectedOldOption.participantIds,
          startDate: selectedOldOption.startDate,
          endDate: selectedOldOption.endDate
        }
      : null

    const eventArgs = {
      method: this._method,
      url: this._url,
      componentId: this.componentId,
      type: 'luggage'
    }

    if (this._method !== 'GET') {
      eventArgs.body = {
        operationType,
        newService,
        oldService,
        datatype: luggageServiceType
      }
    }
    return eventArgs
  }

  _clickedSelectOption (optionElement) {
    const optionCode = optionElement.getAttribute(attr.optionCode)
    let filteredOptions = []
    if (this.apiData.oneClickLuggageOptions && this.apiData.oneClickLuggageOptions.length > 0) {
      filteredOptions = this.apiData.oneClickLuggageOptions
    } else {
      filteredOptions = this.apiData.options.filter(option => option.direction === bookingLuggageDirections.outboundGroup)
    }

    const filteredMappedOptions = filteredOptions && filteredOptions.map(option => this._mapOptionToBeSend(option))
    let selectedOptionMapped = filteredMappedOptions && filteredMappedOptions.find(option => option.value === optionCode)

    const selectedOldOptions = filteredOptions && filteredOptions.filter(option => option.isSelected)

    const selectedOldOptionsMapped = selectedOldOptions && selectedOldOptions.map(option => this._mapOptionToBeSend(option, true))
    const selectedOldOptionMapped = selectedOldOptionsMapped && selectedOldOptionsMapped.length > 0 && selectedOldOptionsMapped[0]

    // when clicking same option already selected, the selectedOptionMapped should be the noOption
    if (selectedOptionMapped?.value === selectedOldOptionMapped?.value) {
      const areParticipantsMatching = this._checkIfAssignedParticipantsMatchParticipantsToApply(selectedOldOptionMapped.appliesToParticipants, selectedOldOptionMapped.assignedParticipants) || false
      let oldInboundOption = null
      if (this.apiData.oneClickLuggageOptions && this.apiData.oneClickLuggageOptions.length > 0) {
        oldInboundOption = selectedOldOptions && selectedOldOptions.length > 0 ? selectedOldOptions[0] : null
      } else {
        oldInboundOption = mapSameOptionForInboundDirection(selectedOldOptionMapped, this.apiData)
      }
      const areParticipantsMatchingForInbound = this._checkIfAssignedParticipantsMatchParticipantsToApply(oldInboundOption.appliesToParticipants, oldInboundOption.assignedParticipants) || false

      if (areParticipantsMatching && (oldInboundOption?.isSelected && areParticipantsMatchingForInbound)) {
        selectedOptionMapped = this.widgetData?.oneClickLuggage?.noOption[0] || null
      }
    }

    const operationType = this._mapOperationType(selectedOptionMapped, selectedOldOptionMapped)
    const eventArgs = this._mapArgsToBeSend(operationType, selectedOptionMapped, selectedOldOptionMapped, bookingLuggageTypes.oneClickLuggage)
    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  _checkIfAssignedParticipantsMatchParticipantsToApply (participantsFromOption, participantsFromOldOption) {
    return Array.isArray(participantsFromOption) &&
      Array.isArray(participantsFromOldOption) &&
      participantsFromOption.length === participantsFromOldOption.length &&
      participantsFromOption.every((val, index) => val === participantsFromOldOption[index])
  }

  _mapOptionToBeSend (option, isOldOption = false) {
    return {
      ...option,
      value: option.code,
      participantIds: isOldOption ? (option.assignedParticipants || option.appliesToParticipants) : option.appliesToParticipants
    }
  }

  _clickedSwitchSpecialLuggage (event) {
    const specialLuggageSwitchInput = event.currentTarget.querySelector('input')
    const specialLuggageChecked = !specialLuggageSwitchInput.checked
    this._updateSwitchVisualization(event.currentTarget, specialLuggageChecked)
    specialLuggageSwitchInput.checked = specialLuggageChecked

    const eventArgs = {
      method: this._method,
      url: this.specialLuggageUrl
    }

    if (this._method !== 'GET') {
      eventArgs.body = {
        hasSpecialLuggage: specialLuggageChecked
      }
    }

    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  _updateSwitchVisualization (currentElement, specialLuggageChecked) {
    const extraTextSwitchElement = currentElement.parentElement.querySelector(widgetQueries.specialLuggageExtraTextSwitch)
    if (extraTextSwitchElement) {
      extraTextSwitchElement.classList.toggle(classNames.hidden, !specialLuggageChecked)
    }
  }

  _clickedRemoveOption () {
    const selectedOldOption = this.widgetData?.oneClickLuggage?.options.find(option => option.selected)
    const noOption = this.widgetData?.oneClickLuggage?.noOption[0] || null

    const operationType = this._mapOperationType(noOption, selectedOldOption)
    const eventArgs = this._mapArgsToBeSend(operationType, noOption, selectedOldOption, bookingLuggageTypes.oneClickLuggage)
    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  _mapOperationType (selectedOption, selectedOldOption) {
    let operationType = bookingLuggageConsts.addOperation

    if (selectedOption && selectedOldOption) {
      operationType = bookingLuggageConsts.swapOperation
    } else if (selectedOldOption) {
      operationType = bookingLuggageConsts.removeOperation
    }

    return operationType
  }

  _attachEvents () {
    this.prices = [...this.element.querySelectorAll(widgetQueries.prices)]
    this.labels = [...this.element.querySelectorAll(widgetQueries.labels)]

    const bookingLuggageElements = [...this.element.querySelectorAll(widgetQueries.luggages)]
    this.dropdownApis = bookingLuggageElements.map(element => element[apis.dropdownApi])
    this.dropdownApis.forEach(dropdown => {
      dropdown.events.on('changedOptions', (...args) => this._clickedOptionWithOptions(dropdown, ...args))
    })

    const bookingSpecialLuggageElements = [...this.element.querySelectorAll(widgetQueries.specialLuggage)]
    this.choiceListHasSpecialLuggageApis = bookingSpecialLuggageElements.map(element => element[apis.choiceListApi])
    this.choiceListHasSpecialLuggageApis.forEach(choiceListApi => {
      choiceListApi.events.on('changeOptions', (...args) => this._clickedOptionSpecialLuggage(...args))
    })

    const bookingSpecialLuggageSwitchElements = [...this.element.querySelectorAll(widgetQueries.specialLuggageSwitch)]
    bookingSpecialLuggageSwitchElements.forEach(switchElement => {
      switchElement.addEventListener('click', (event) => {
        event.preventDefault()
        this._clickedSwitchSpecialLuggage(event)
      })
    })

    this._attachOneClickEvents()
  }

  _attachOneClickEvents () {
    this.selectOptionElements = [...this.element.querySelectorAll(widgetQueries.oneClickOption)]
    this.selectOptionElements.forEach(optionElement => {
      optionElement.addEventListener('click', (e) => {
        this._clickedSelectOption(optionElement)
      })
    })

    const removeOneClickLuggageButtons = [...this.element.querySelectorAll(widgetQueries.removeOptionButtonElement)]
    this.removeOneClickLuggageButtonApis = removeOneClickLuggageButtons.map(removeLuggageButton => removeLuggageButton[apis.buttonApi])

    this.removeOneClickLuggageButtonApis.forEach(buttonApi => {
      buttonApi.events.on('clickButton', () => { this._clickedRemoveOption() })
    })

    this.selectPerParticipantsSwitch = this.element.querySelector(widgetQueries.selectPerParticipantElementSwitch)
    if (this.selectPerParticipantsSwitch) {
      this.selectPerParticipantsSwitch.addEventListener('click', (event) => {
        event.preventDefault()
        this._selectPerParticipantsClicked(event)
      })
    }
  }

  _selectPerParticipantsClicked (event) {
    const inputPerParticipants = event.currentTarget.querySelector('input')
    this.choosePerPersonIsChecked = !inputPerParticipants.checked
    inputPerParticipants.checked = this.choosePerPersonIsChecked
    this._saveChoosePerPersonToSessionStorage(this.choosePerPersonIsChecked)
    this._emitChoosePerPersonEvent(this.choosePerPersonIsChecked)
    this._toggleVisibilityPerParticipantView(this.choosePerPersonIsChecked)
  }

  _clickedOptionSpecialLuggage (options) {
    const selectedOption = options.find(o => o.checked)

    const eventArgs = {
      method: this._method,
      url: this.specialLuggageUrl
    }

    if (this._method !== 'GET') {
      eventArgs.body = {
        hasSpecialLuggage: !!selectedOption
      }
    }

    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  _init () {
    const renderedHtml = BookingLuggageTemplate(this.widgetData)
    const newContent = elementFromString(renderedHtml)
    this._render(newContent)
    // IMPORTANT: Put always the 'initDocumentComponentsFromAPI' after the '_render', otherwise the events are not binded correctly.
    Component.initDocumentComponentsFromAPI(newContent)
    this._attachEvents()
  }

  _updateWidgetData () {
    const buildDropdown = (dropdown, options) => {
      dropdown.setProp('options', options, { silent: true })
      if (dropdown.getSelectNode().selectedIndex < 0) {
        dropdown.getSelectNode().selectedIndex = 0
      }
    }

    this.apiData = null
    this.widgetData = null
    this.apiData = this.data.services && this.data.services.find(service => service.componentId === this.componentId)

    let configurationsSavedSessionStorage = null
    if (this.configurations && this.configurations.showOneClickLuggage) {
      configurationsSavedSessionStorage = this._retrieveChoosePerPersonDataFromStorage()

      if (configurationsSavedSessionStorage) {
        this.configurations.choosePerPersonIsChecked = configurationsSavedSessionStorage.choosePerPersonSelected || false
      }
    }

    if (this.apiData) {
      this.serviceName = cleanUpSpecialCharacters(this.apiData.aName) || ''
      const isOneWayFlight = Object.prototype.hasOwnProperty.call(this.data, 'isOneWayFlight') ? this.data.isOneWayFlight : false
      this.apiData = { ...this.apiData, ...{ participants: this.data.participants, rooms: this.data.rooms, isOneWayFlight } }
      this.widgetData = this.dataMapper.mapService({ apiService: this.apiData, texts: this.texts, showSpecialLuggageCheckbox: this.showSpecialLuggageCheckbox, configurations: this.configurations })
    }

    if (this.widgetData) {
      this._updateBookingBoxCollapse(this.apiData)
      super.showComponent()
      if ((this.apiData.isOneClickLuggageReady && this.configurations.showOneClickLuggage) || (this.dropdownApis == null || this.dropdownApis.length === 0)) {
        this._init()
      }

      if (this.apiData.isOneClickLuggageReady && this.configurations.showOneClickLuggage && this.widgetData?.oneClickLuggage?.sameOptionSelectedForAllParticipants) {
        this._toggleVisibilityPerParticipantView(this.configurations.choosePerPersonIsChecked)
      }

      const participantsOptions = []
      this.widgetData.rooms.forEach(
        room => room.participants.forEach(participant => participantsOptions.push(participant.luggageInfo)))
      const warningElements = this.element.querySelectorAll('[' + widgetQueries.warningsection + ']')
      if (warningElements && warningElements.length > 0) {
        this.widgetData.rooms.forEach((room, index) => {
          if (room.warning && room.warning.message) {
            const warningMessage = BookingLuggageMessageTemplate({
              ...room.warning,
              ...{
                description: room.warning.message,
                extraClasses: 'cs-state-warning cs-state-warning--light'
              }
            })
            warningElements[index].innerHTML = warningMessage
          } else {
            warningElements[index].innerHTML = ''
          }
        })
      }

      let index = 0
      let rowIndex = 0
      const dropdownApisArray = [...this.dropdownApis]
      participantsOptions.forEach(participant => {
        const shouldPrintParticipant = participant.inbound || participant.outbound
        if (shouldPrintParticipant) {
          this.prices[rowIndex].innerHTML = this.widgetData.configurations && this.widgetData.configurations.price ? BookingLuggagePriceTemplate(participant.totalPrice, this.widgetData.configurations.price) : participant.totalPrice
          this.labels[rowIndex].innerText = participant.participantName
          if (participant.outbound && participant.outbound.options) {
            buildDropdown(dropdownApisArray[index++], participant.outbound.options)
          }
          if (participant.inbound && participant.inbound.options) {
            buildDropdown(dropdownApisArray[index++], participant.inbound.options)
          }
          rowIndex++
        }
      })
    } else {
      super.hideComponent()
    }
  }

  _updateBookingBoxCollapse (apiData) {
    const selectedOptions = apiData.options.filter(option => option.isSelected)
    if (selectedOptions.length > 0) {
      const noneOptions = selectedOptions.filter(option => option.isNoneOption)
      if ((noneOptions.length > 0) && (selectedOptions.length === noneOptions.length)) {
        super.noOptionSelectedAndUpdateTitle()
      } else {
        super.setSuccessStateAndUpdateTitle()
      }
    }
  }

  _readOptionsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)
      const priceConfigurations = super.readPriceConfigFromConfigurations(configuration)

      return {
        ...priceConfigurations,
        choosePerPersonIsChecked: false,
        oneClickLuggageTexts: this._readOneClickLuggageTextsFromConfigurations(configuration),
        showOneClickLuggage: configuration[configOptions.showOneClickLuggage],
        maxOneClickOptionsToBeShown: configOptions.maxOneClickOptionsToBeShown,
        showSpecialLuggageSwitch: configuration[configOptions.showSpecialLuggageSwitch] || false
      }
    }
  }

  _readOneClickLuggageTextsFromConfigurations (configuration) {
    const oneClickLuggageTexts = {
      priceDescription: configuration[configOptions.oneClickLuggagePriceDescription],
      selectTextButton: configuration[configOptions.oneClickLuggageSelectTextButton],
      unselectTextButton: configuration[configOptions.oneClickLuggageUnselectTextButton],
      removeTextButton: configuration[configOptions.oneClickLuggageRemoveTextButton],
      textSwitchViews: configuration[configOptions.oneClickLuggageTextSwitchViews],
      outboundAndInboundDifferentText: configuration[configOptions.oneClickLuggageOutboundAndInboundDifferentText],
      luggageUsps: configuration[configOptions.bookingLuggageUsps]
    }
    return oneClickLuggageTexts
  }

  _readTextsFromAttributes () {
    const texts = {
      inboundDropdown: this.element.dataset[attr.texts.inboundDropdown],
      outboundDropdown: this.element.dataset[attr.texts.outboundDropdown],
      bothDropdown: this.element.dataset[attr.texts.bothDropdown],
      specialLuggageTitle: this.element.dataset[attr.texts.specialLuggageTitle],
      specialLuggageText: this.element.dataset[attr.texts.specialLuggageText],
      specialLuggageExtraTextSelected: this.element.dataset[attr.texts.specialLuggageExtraTextSelected],
      specialLuggageCheckboxId: this.element.dataset[attr.texts.specialLuggageCheckboxId],
      specialLuggageCheckboxValue: this.element.dataset[attr.texts.specialLuggageCheckboxValue]
    }
    return texts
  }

  _render (el) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(el)
  }

  setShowOneClickLuggage (showOneClickLuggage = false) {
    if (this.configurations) {
      this.configurations = { ...this.configurations, showOneClickLuggage }
    }
  }

  setShowSpecialLuggageSwitch (showSpecialLuggageSwitch = false) {
    if (this.configurations) {
      this.configurations = { ...this.configurations, showSpecialLuggageSwitch }
    }
  }

  _saveChoosePerPersonToSessionStorage (isChecked = false) {
    const identifier = this._getComponentIdentifier()
    if (identifier) {
      const setChoosePerPersonSelectedData = { choosePerPersonSelected: isChecked }
      webStorage.session.set(identifier, setChoosePerPersonSelectedData)
    }
  }

  _getComponentIdentifier () {
    const identifier = `${this.draftId}-${this.componentId}`
    return identifier !== null ? identifier : undefined
  }

  _retrieveChoosePerPersonDataFromStorage () {
    let choosePerPersonSelectedData
    const identifier = this._getComponentIdentifier()
    if (identifier) {
      choosePerPersonSelectedData = webStorage.session.get(identifier)
    }
    return choosePerPersonSelectedData
  }

  _emitChoosePerPersonEvent (choosePerPersonIsChecked) {
    const switchEventArgs = {
      choosePerPersonIsChecked,
      serviceName: 'Luggage',
      description: 'Change luggage visualization'
    }

    this.events.emit(bookingLuggageEvents.CHOOSE_PER_PERSON_SWITCH, switchEventArgs)
  }

  _toggleVisibilityPerParticipantView (choosePerPersonIsChecked) {
    const perParticipantViews = this.element.querySelector(widgetQueries.selectPerParticipantView)
    if (choosePerPersonIsChecked) {
      perParticipantViews.classList.remove(classNames.hidden)
    } else {
      perParticipantViews.classList.add(classNames.hidden)
    }
  }

  _setOneClickLuggageSliderIndex () {
    const slider = this._getOneClickLuggageSliderElement()
    const currentSelectedOptionIndex = this._getOneClickLuggageSelectedOptionIndex(this.widgetData)
    if (slider) {
      this.oneClickOptionsSliderApi = slider[apis.sliderApi]
      const indexToBeSet = currentSelectedOptionIndex || this.oneClickOptionsSliderApi.getProp('index') || 0
      this.oneClickOptionsSliderApi && this.oneClickOptionsSliderApi.setProp('index', indexToBeSet, { forceUpdate: true })
    }
  }

  _getOneClickLuggageSliderElement () {
    this.oneClickOptionsSliderElement = this.oneClickOptionsSliderElement || this.element.querySelector(widgetQueries.oneClickOptionsSlider)
    return this.oneClickOptionsSliderElement
  }

  _getOneClickLuggageSelectedOptionIndex (widgetData) {
    if (!widgetData && !widgetData?.oneClickLuggage) { return }
    return (widgetData.oneClickLuggage.options && widgetData.oneClickLuggage.options.findIndex(option => option.selected)) || 0
  }

  _buildResizeEvents () {
    this._events = [[window, { resize: utilsHelper.debounce(() => this._setOneClickLuggageSliderIndex(), settings.resizeDelay) }]]
    domEventsHelper.attachEvents(this._events, widgetApi)
    return this
  }
}

registerWidget(BookingLuggage, widgetApi)

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'

loadQueueHelper.add(function () {
  // dummy javascript to show correct icon per brand, this will only work on Design system
  const brands = [
    { brand: 'sunweb', regex: /sunweb/ },
    { brand: 'eliza', regex: /eliza/ },
    { brand: 'gogo', regex: /gogo/ },
    { brand: 'primavera', regex: /primavera/ },
    { brand: 'totally', regex: /totally/ },
    { brand: 'bizztravel', regex: /bizztravel/ }
  ]
  const modalLogos = document.querySelectorAll('.c-modal-v2__header-logo-img')
  const pageUrl = window.location.href
  let currentBrand

  brands.forEach(brand => {
    if (pageUrl.search(brand.regex) !== -1) {
      currentBrand = brand.brand
    }
  })

  modalLogos.forEach(modalLogo => {
    if (modalLogo) {
      modalLogo.setAttribute('alt', currentBrand)
      modalLogo.setAttribute('src', modalLogo.getAttribute('src').replace('sunweb', currentBrand))
    }
  })
})

import {
  BreakdownTableTemplate,
  defaultBreakdownTableData
} from '../../components/breakdown-table/c-breakdown-table.template'
import { BoxTemplate } from '../../components/box/c-box.template'
import { TitleTemplate, defaultTitleData } from '../../components/title/c-title.template'

/**
 * The BookingPriceOverviewTemplate contains all the data needed to hydrate a BookingPriceOverview component
 *
 * @typedef {Object}      BookingPriceOverviewTemplate
 *
 * @property {String}     variant            - The variant that will be used
 * @property {String}     extraClasses      - Extra classes if needed
 * @property {String}     innerHTML         - The content that should be shown inside the table
 */

export const defaultBookingPriceOverviewData = {
  variant: 'default',
  extraClasses: ''
}

export const BookingPriceOverviewTemplate = (d) => `
  <div>
    ${d.tableContent.warningMessage
      ? `${BoxTemplate({
        variant: 'flat',
        extraClasses: 'cs-state-info cs-state-info--light w-booking-price-overview--warning-box',
        html: `<p class='w-booking-price-overview--warning-text'>${d.tableContent.warningMessage}</p>`
      })}`
      : ''}

  ${BreakdownTableTemplate(Object.assign({}, defaultBreakdownTableData, { items: d.tableContent.items, extraClasses: 'w-booking-price-overview', childrenExtraClasses: 'w-booking-price-overview__subitem--indented' }))}
  ${d.tableContent.mandatoryCosts.length > 0
? `
      ${TitleTemplate({
        ...defaultTitleData,
        ...{
          text: d?.tableContent?.mandatoryCostHeader?.text,
          extraClasses: 'w-booking-price-overview__heading-extra-content-title',
          size: d?.tableContent?.mandatoryCostHeader?.size
          }
        }
      )}
      <div class="w-booking-price-overview__heading-extra-content-text">
        <p class="m-body m-body--small sp-stack-children--medium">
            ${d.tableContent.mandatoryCosts}
        </p>
      </div>
  `
: ''}
  </div>
`

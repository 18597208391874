/**
 * The NavLinkData contains all data needed to hydrate a NavLinkForm view
 *
 * @typedef {Object}          NavLinkData
 *
 * @property {Object}         link
 * @property {String}         [link.href]         - Link's url
 * @property {String}         [link.target]       - Open in same page, new page...
 * @property {String}         [link.icon]
 * @property {String}         [link.text]
 * @property {Boolean}        isCollapsible
 * @property {String}         collapsibleId
 * @property {Object}         attributes
 * @property {String}         extraClasses
 */

import { encodeAttributes } from '../../../js/helpers/string'

export const defaultNavLinkData = {
  link: {},
  isCollapsible: false,
  collapsibleId: '',
  attributes: {},
  extraClasses: ''
}

export const NavLinkTemplate = (d) => {
  d = { ...defaultNavLinkData, ...d }
  return `
    <a class="c-nav__link ${d.extraClasses}"
      ${d.link.href ? `href="${d.link.href}"` : ''}
      ${d.link.target ? `target="${d.link.target}"` : ''}
      ${d.isCollapsible ? `data-c-collapse__id="${d.collapsibleId}" data-c-collapse__action="toggle"` : ''}
      ${d.attributes ? encodeAttributes(d.attributes) : ''}>
        ${d.link.icon ? `<i class="c-nav__link-icon m-icon m-icon--${d.link.icon}"></i>` : ''}
        ${d.link.text ? `<span class="c-nav__link-text">${d.link.text}</span>` : ''}
    </a>
  `
}

import { widgetQueries } from '../main'
import { elementFromString, flush } from '../../../../js/document/html-helper'
import Component from '../../../../js/core/component/component'

export default class BaseCancellation {
  constructor (element) {
    this.element = element
  }

  _attachEvents () {
    const confirm = this.element.querySelector(widgetQueries.optionsConfirm)
    const decline = this.element.querySelector(widgetQueries.optionsDecline)
    const back = this.element.querySelector(widgetQueries.optionsBack)
    const done = this.element.querySelector(widgetQueries.optionsDone)

    this.apis = {
      ...confirm && { confirmButton: confirm[widgetQueries.buttonApi] },
      ...decline && { declineButton: decline[widgetQueries.buttonApi] },
      ...back && { backButton: back[widgetQueries.buttonApi] },
      ...done && { doneButton: done[widgetQueries.buttonApi] }
    }

    confirm && confirm.addEventListener('click', this._clickedConfirm.bind(this))
    decline && decline.addEventListener('click', this._clickedDecline.bind(this))
    back && back.addEventListener('click', this._clickedBack.bind(this))
    done && done.addEventListener('click', this._clickedDone.bind(this))

    const closeModal = this.element.querySelector(widgetQueries.closeModal)
    closeModal && closeModal.addEventListener('click', this._clickedClose.bind(this))
  }

  _clickedConfirm (ev) {
    ev.preventDefault()
  }

  _clickedDecline (ev) {
    ev.preventDefault()
    flush(this.element)
    this._setButtonsState(false)
    this._setParentButtons(false)
  }

  _clickedBack (ev) {
    ev.preventDefault()
  }

  _clickedDone (ev) {
    ev.preventDefault()
    window.location.reload()
  }

  _clickedClose (ev) {
    ev.preventDefault()
    this._closeModal()
  }

  _renderHTML (html) {
    const newContent = elementFromString(html)
    Component.initDocumentComponentsFromAPI(newContent)
    flush(this.element)
    this.element.appendChild(newContent)
    this._setTitleModal(newContent)
  }

  _setTitleModal (content) {
    const title = content.querySelector(widgetQueries.transformTitle)
    const titleText = title ? title.textContent.trim() : ''
    this.options.modalApi.setProp('title', titleText)
  }

  _closeModal () {
    if (this.options.modalApi) {
      this.options.modalApi.close()
    }
  }

  _setButtonsState (isLoading) {
    this.apis.confirmButton && this.apis.confirmButton.setProps({ disabled: isLoading, loading: isLoading })
    this.apis.declineButton && this.apis.declineButton.setProp('disabled', isLoading)
    this.apis.backButton && this.apis.backButton.setProp('disabled', isLoading)
  }

  _setParentButtons (isLoading) {
    this.options.buttonApi.setProps({ disabled: isLoading, loading: isLoading })
    if (!isLoading) {
      this.options.parent.apis.cancelChoiceList.enableComponent()
    } else {
      this.options.parent.apis.cancelChoiceList.disableComponent()
    }
  }
}

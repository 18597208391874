import * as loadQueueHelper from '../../../../shared/js/document/load-queue'

loadQueueHelper.add(function () {
  initBookingVouchers()
}, 2)

const initBookingVouchers = () => {
  const items = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-vouchers"]')
  handleFetched(items)

  document.addEventListener('razor-load-complete', function () {
    const items = document.querySelectorAll('[data-razor-url] [data-js-api--w-booking-item="w-booking-vouchers"]')
    handleFetched(items)
  })
}

const handleFetched = (items) => {
  setTimeout(() => {
    const handleFetchedPromises = Array.from(items).map(element => {
      return element['w-booking-item'].handleFetched()
    })
    handleFetchedPromises.forEach(promise => promise.then(resolvedItem => { resolvedItem.enableUI() }))
  }, 1500)
}

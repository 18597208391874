import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import RoomDropdown from '../../../../shared/modules/widgets/room-dropdown/main'

const data = require('../../../../shared/modules/widgets/room-dropdown/data/w-room-dropdown__data-options')

loadQueueHelper.add(function () {
  initRoomDropdown()
}, 2)

const initRoomDropdown = async () => {
  const roomDropdownElement = document.getElementById('my-room-dropdown')
  if (roomDropdownElement) {
    const roomDropdown = new RoomDropdown(roomDropdownElement, { data })
    data.rooms.shift()
    roomDropdown.updateRooms(data.rooms)
    roomDropdown.events.on('selected', handleSelectedStateSubmit, this)
  }
}

function handleSelectedStateSubmit (data) {
  console.log(`You selected ${data}`)
}

export const DDGridTemplateDataFromPrices = (prices) => {
  const currentYear = parseInt(prices[0].departureDate.split('-')[0])
  const { durations, departures } = prices.reduce(
    ({ departures, durations }, { departureDate, duration, ...others }) => {
      if (!durations.includes(duration)) durations.push(duration)
      if (!departures[departureDate]) departures[departureDate] = {}
      departures[departureDate][duration] = others
      return { departures, durations }
    }, { departures: {}, durations: [] }
  )

  durations.sort((a, b) => a - b)
  return {
    currentYear,
    departures,
    durations
  }
}

/**
 * The ActionableListItemExtendedData contains all data needed to hydrate the view
 *
 * @typedef {Object}          ActionableListExtendedData
 *
 *
 */

export const defaultActionableListItemExtendedData = {}

export const ActionableListItemExtendedTemplate = (d) => {
  d = { ...defaultActionableListItemExtendedData, ...d }
  return `<button class="m-button--clean c-actionable-list__item-extended" ${d.item.isDisabled ? 'disabled' : ''}>
    ${d.item.icon ? `<span class="m-icon m-icon--${d.item.icon}"></span>` : ''}
    <span class="c-actionable-list__item-extended__details">
      ${d.item.title ? `<span data-field="title">${d.item.title}</span>` : ''}
      ${d.item.description ? `<span data-field="description">${d.item.description}</span>` : ''}
     </span>
</button>`
}

import { BookingCancellationFlowGenericConfirmationTemplate } from './w-booking-cancellation-flow-generic-confirmation.template'
import { BookingCancellationFlowGenericResponseTemplate } from './w-booking-cancellation-flow-generic-response.template'
import { apiCaller } from '../../../../js/helpers/api-caller'
import { BookingCancellationFlowLoaderTemplate } from './w-booking-cancellation-flow-loader.template'
import { language } from '../../../../js/user/locale-settings'
import { register } from '../../../../js/document/namespace'
import BaseCancellation from './base-cancellation'
import { BookingCancellationFlowPricesTemplate } from './w-booking-cancellation-flow-prices.template'
import { bookingCancellationFlowEvents } from '../../../../js/document/event-types'

const dateLocales = register(`window.sundio.i18n.${language}.dates`)
const DEFAULT_OPTIONS = {
  locales: {},
  vouchers: [],
  cancellationConditionsUrl: '',
  deleteUrl: '',
  bookingNumber: 0,
  modalApi: '',
  selfserviceLink: '',
  parent: null,
  events: null
}

export default class RegularCancellation extends BaseCancellation {
  constructor (element, options = {}) {
    super(element)
    this.options = {
      ...DEFAULT_OPTIONS,
      ...options
    }
    this.apis = {}
    this.locales = options.locales
    this.getCancellationsFlag = false
    this.cancelBookingFlag = false
    this.isWithCost = false
    this.cancelled = false
  }

  async getCancellationConditions () {
    this._setParentButtons(true)
    this.getCancellationsFlag = true
    if (this.options.isIsolated) {
      this._renderLoader()
    }
    const result = await apiCaller(`${this.options.cancellationConditionsUrl}?bookingNumber=${this.options.bookingNumber}&contextItemId=${this.options.parent.componentId}`)
    if (this.getCancellationsFlag) {
      this.getCancellationsFlag = false
      const isSuccess = result.success && result.response.isValid
      this.options.events.emit(bookingCancellationFlowEvents.CHECK_REGULAR_CANCELLATION_CONDITIONS, {
        bookingNumber: this.options.bookingNumber,
        isSuccess,
        isWithCost: isSuccess && result.response.isCancellationWithCosts
      })
      if (isSuccess) {
        if (result.response.isFreeCancellation) {
          this.isWithCost = false
          this.options.parent._showStep(null)
          this._renderConfirmationWithoutCost(result.response.freeCancellationLimitDate || '')
        } else if (result.response.isCancellationWithCosts) {
          this.isWithCost = true
          this.options.parent._showStep(null)
          this._renderConfirmationWithCost(result.response)
        } else if (this.options.selfserviceLink) {
          window.location.href = this.options.selfserviceLink
        } else {
          this.options.parent._showStep(null)
          this._renderResponseWithoutCost(false)
        }
      } else {
        this.options.parent._showStep(null)
        this._renderResponseWithoutCost(false)
      }
    }
  }

  stopAsyncCalls () {
    this.getCancellationsFlag = false
    this.cancelBookingFlag = false
    this._setParentButtons(false)
  }

  async _cancelBooking () {
    this.cancelBookingFlag = true
    this._setButtonsState(true)
    const result = await apiCaller(`${this.options.deleteUrl}?bookingNumber=${this.options.bookingNumber}&isWithCost=${this.isWithCost}`, { method: 'DELETE', timeout: 60000 })
    if (this.cancelBookingFlag) {
      this.cancelBookingFlag = false
      this.cancelled = result.success && result.response.isValid
      const iwWithCost = (this.cancelled && result.response.isCancelledWithCost) || (!this.cancelled && this.isWithCost)
      this.options.events.emit(bookingCancellationFlowEvents.CONFIRM_REGULAR_CANCELLATION, {
        bookingNumber: this.options.bookingNumber,
        isSuccess: this.cancelled,
        isWithCost: iwWithCost,
        amount: this.amountToPay
      })
      if (iwWithCost) {
        this._renderResponseWithCost(this.cancelled)
      } else {
        this._renderResponseWithoutCost(this.cancelled)
      }
    }
  }

  _renderLoader () {
    if (this.element) {
      const html = BookingCancellationFlowLoaderTemplate({
        ...(this.locales.regularConfirmationLoadingText) && { text: this.locales.regularConfirmationLoadingText }
      })
      this._renderHTML(html)
    }
  }

  _renderConfirmationWithoutCost (freeCancellationLimitDate) {
    if (this.element) {
      const html = BookingCancellationFlowGenericConfirmationTemplate({
        title: this.locales.regularConfirmationWithoutCostTitle || '',
        confirm: this.locales.regularConfirmationConfirmButton || '',
        decline: this.locales.regularConfirmationDeclineButton || '',
        html: this.locales.regularConfirmationWithoutCostText && (this.locales.regularConfirmationWithoutCostText.replace('{LIMIT_DATE}', freeCancellationLimitDate) || '')
      })
      this._renderHTML(html)
      this._attachEvents()
    }
  }

  _renderConfirmationWithCost (params) {
    if (this.element) {
      this.amountToPay = params.amountToPayRaw
      const partialHtml = BookingCancellationFlowPricesTemplate({
        text: this.locales.regularConfirmationWithCostText || '',
        paymentDueDate: this._getDate(),
        overviewTitle: this.locales.regularConfirmationWithCostOverviewTitle || '',
        overviewCancellationCostText: this.locales.regularConfirmationWithCostOverviewCancellationCostText || '',
        overviewAmountPaidText: this.locales.regularConfirmationWithCostOverviewAmountPaidText || '',
        overviewPercentageAmountText: this.locales.regularConfirmationWithCostOverviewPercentageAmountText || '',
        overviewAmountToPayText: this.amountToPay >= 0 ? this.locales.regularConfirmationWithCostOverviewAmountToPayText || '' : this.locales.regularConfirmationWithCostOverviewAmountToReceiveText || '',
        overviewDueDateText: this.locales.regularConfirmationWithCostOverviewDueDateText || '',
        overviewFooter: this.locales.regularConfirmationWithCostOverviewFooter || '',
        cost: params.cost,
        amountAlreadyPaid: params.amountAlreadyPaid,
        amountToPay: params.amountToPay
      })
      const html = BookingCancellationFlowGenericConfirmationTemplate({
        title: this.locales.regularConfirmationWithCostTitle || '',
        confirm: this.locales.regularConfirmationConfirmButton || '',
        decline: this.locales.regularConfirmationDeclineButton || '',
        html: partialHtml
      })
      this._renderHTML(html)
      this._attachEvents()
    }
  }

  _renderResponseWithoutCost (isSuccess) {
    if (this.element) {
      const html = BookingCancellationFlowGenericResponseTemplate({
        modalId: this.options.modalId,
        isSuccess,
        title: isSuccess ? (this.locales.regularResponseWithoutCostSuccessfulTitle || '') : (this.locales.regularResponseWithoutCostFailureTitle || ''),
        text: isSuccess ? (this.locales.regularResponseWithoutCostSuccessfulText || '') : (this.locales.regularResponseWithoutCostFailureText || ''),
        confirm: this.locales.regularResponseConfirmButton || ''
      })

      this._renderHTML(html)
      this._attachEvents()
    }
  }

  _renderResponseWithCost (isSuccess) {
    if (this.element) {
      const html = BookingCancellationFlowGenericResponseTemplate({
        modalId: this.options.modalId,
        isSuccess,
        title: isSuccess ? (this.locales.regularResponseWithCostSuccessfulTitle || '') : (this.locales.regularResponseWithCostFailureTitle || ''),
        text: isSuccess ? (this.locales.regularResponseWithCostSuccessfulText && (this.locales.regularResponseWithCostSuccessfulText.replace('{_date_}', this._getDate()) || '')) : (this.locales.regularResponseWithCostFailureText || ''),
        confirm: this.locales.regularResponseConfirmButton || ''
      })

      this._renderHTML(html)
      this._attachEvents()
    }
  }

  _clickedConfirm (ev) {
    ev.preventDefault()
    this._cancelBooking()
  }

  _clickedDecline (ev) {
    super._clickedDecline(ev)
    this.getCancellationsFlag = false
    this.cancelBookingFlag = false
    this.options.isIsolated ? this._closeModal() : this.options.parent._showStep('1')
  }

  _clickedClose (ev) {
    super._clickedClose(ev)
    this.getCancellationsFlag = false
    this.cancelBookingFlag = false
  }

  _getDate () {
    const tomorrowDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
    return `${tomorrowDate.getDate()} ${dateLocales.months_short[tomorrowDate.getMonth()]} ${tomorrowDate.getFullYear()}`
  }
}

/**
 * The RoomSelectorTemplateData contains all data needed to hydrate a RoomSelector view
 *
 * @typedef {Object}                  RoomSelectorTemplateData
 *
 * @property {String}                 [headerText]
 */

export const defaultRoomSelectorData = {
  headerText: '[Select your rooms]'
}

export const RoomSelectorTemplate = (d) => {
  d = { ...defaultRoomSelectorData, ...d }

  return `
<h2 class="c-title m-heading m-heading--small w-booking-gate__item-title">${d.headerText}</h2>
<div data-w-room-selector--main-container>
</div>
`
}

/**
 *
 * @global
 * @typedef {Object}       PackageData
 *
 * @property {String}      id                                 - Package id, like: "2PKA_3:3-0:99-0:99-0:99_2019-12-11"
 * @property {String}      roomId                             - Room Id, like: "2PKA"
 * @property {String}      roomOccupancyId                    - Room Occupancy Id, like: "2PKA03"
 * @property {String}      occupancyId                        - Occupancy Id, like: "occupancy_0"
 * @property {Number}      price                              - raw price as number
 * @property {String}      priceFormatted                     - formattedPrice including separators but no currencySymbol
 * @property {Boolean}     [onRequest]                        - onRequest package if true
 *
 */

export default class Packages {
  /**
   * @constructor
   * @param {PackageData[]} packagesData
   */
  constructor (packagesData) {
    this.data = packagesData
  }

  /**
   * Get packages matching given room ID
   *
   * @param {String}      roomId         - The roomId to match with (aka roomGroup prop)
   *
   * @returns {PackageData[]}
   */
  getPackagesByRoomId (roomId) {
    return Packages.getPackagesByRoomId(this.data, roomId)
  }

  /**
   * Get packages matching given room ID and occupancyId
   *
   * @param {String}      roomId              - The roomId to match with (aka roomGroup prop)
   * @param {String}      roomOccupancyId     - The roomOccupancyId to match with
   *
   * @returns {PackageData[]}
   */
  getPackagesByRoomIdAndRoomOccupancyId (roomId, roomOccupancyId) {
    return Packages.getPackagesByRoomIdAndRoomOccupancyId(this.data, roomId, roomOccupancyId)
  }

  /**
   * Get the cheapest package
   * @returns {PackageData|undefined}
   */
  getCheapestPackage () {
    return Packages.getCheapestPackage(this.data)
  }

  /**
   * Get package by Id
   *
   * @param {String}                        id                - Package id
   *
   * @returns {PackageData|undefined}
   */
  getPackageById (id) {
    return this.data.find(pkg => pkg.id === id)
  }

  /**
   * Get packages matching given room ID
   *
   * @param {PackageData[]}               packagesData        - The packages to evaluate
   * @param {String}                      roomId              - The roomId to match with (aka roomGroup prop)
   *
   * @returns {PackageData[]}
   */
  static getPackagesByRoomId (packagesData, roomId) {
    return packagesData.filter(pkg => pkg.roomId === roomId)
  }

  /**
   * Get packages matching given room ID and room occupancy id
   *
   * @param {PackageData[]}               packagesData        - The packages to evaluate
   * @param {String}                      roomId              - The roomId to match with (aka roomGroup prop)
   * @param {String}                      roomOccupancyId     - The roomOccupancyId to match with
   *
   * @returns {PackageData[]}
   */
  static getPackagesByRoomIdAndRoomOccupancyId (packagesData, roomId, roomOccupancyId) {
    return Packages.getPackagesByRoomId(packagesData, roomId).filter(pkg => pkg.roomOccupancyId === roomOccupancyId)
  }

  /**
   * Get the cheapest package from a given list of them
   *
   * @param {PackageData[]}   packagesData       - The packages to evaluate
   *
   * @returns {PackageData|undefined}
   */
  static getCheapestPackage (packagesData) {
    if (!packagesData.length) return undefined
    return packagesData.reduce(
      (cheapestPkg, pkg) =>
        pkg.price < cheapestPkg.price
          ? pkg
          : cheapestPkg
    )
  }
}

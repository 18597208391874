import { AbsoluteDialogTemplate } from '../../components/absolute-dialog/c-absolute-dialog.template'

/**
 * The CustomerDiscountsContentData contains all data needed to hydrate inner content for CustomerDiscounts widget
 *
 * @typedef {Object}                      CustomerDiscountsContentData
 *
 */

export const defaultCustomerDiscountsContentData = {
  text: '',
  link: null
}

export const CustomerDiscountsContentTemplate = (d) => {
  d = { ...defaultCustomerDiscountsContentData, ...d }

  return `${AbsoluteDialogTemplate({
    body: `<div class="w-customer-discounts__message">
        <div class="w-customer-discounts__message__text">${d.text}</div>
        <div>${buildLink(d.link)}</div>
      </div>`,
    closable: false,
    alertType: 'info'
  })}`
}

function buildLink (link) {
  if (!link || !link.href || !link.text) { return '' }
  return `<a href="${link.href}">${link.text}</a>`
}

import { PersonalDetailsEditFormTemplate } from './w-personal-details-edit__form.template'
import Component from '../../../js/core/component/component'
import { elementFromString, flush } from '../../../js/document/html-helper'
import FormDataMapper from './form-data-mapper'

const widgetApi = 'w-personal-details-edit'
const widgetQueries = {
  form: `[data-${widgetApi}__form]`,
  externalEditModal: `[data-${widgetApi}__request-manual-edition__modal]`
}
const requestManualEditionMode = 'requestManualEditionMode'
/**
 * Form Manager
 *  Manages render the form with the data from API and settings of widgets,
 *  also getting the current values of form.
 */
export default class FormManager {
  // Element is the form container.
  constructor (element) {
    this.element = element
    this.elements = {}
    this.apis = {}
  }

  render (id, apiData, formSettings, generalSettings) {
    const mapper = new FormDataMapper()
    const widgetData = mapper.mergeRulesAndSettings(id, apiData, formSettings.sections, generalSettings)
    const renderedHtml = PersonalDetailsEditFormTemplate(widgetData)
    this._renderHTML(renderedHtml, this.element)
    this._initForm()
  }

  validate () {
    return this.apis.form && !this.apis.form.validate().every(v => v.isValid)
  }

  validateExternalForm () {
    return this.apis.externalEditForm && !this.apis.externalEditForm.validate().every(v => v.isValid)
  }

  getFormData () {
    const mapper = new FormDataMapper()
    const plainFormData = this.apis.form.getFormData({ format: 'json' })
    return mapper.mapFormData(plainFormData)
  }

  /**
   *  Returns a string of all labels whose value has changed
   *
   * @param {Boolean} isExternal - Its external form
   * @returns {String}
   **/
  getLabelsOfFormDataChanged (isExternal) {
    const currentFormData = isExternal ? this.getExternalEditableFormData() : this.getFormData()
    const fetchedFormData = isExternal ? this.fetchedExternalFormData : this.fetchedFormData
    const LabelsOfFormDataChanged = Object.keys(fetchedFormData).reduce((keyArray, key) => {
      if (JSON.stringify(currentFormData[key]) === JSON.stringify(fetchedFormData[key])) return keyArray
      keyArray.push(key)
      return keyArray
    }, [])
    return LabelsOfFormDataChanged.toString()
  }

  getExternalEditableFormData () {
    const mapper = new FormDataMapper()
    const plainFormData = this.apis.externalEditForm.getFormData({ format: 'json' })
    return mapper.mapFormData(plainFormData, requestManualEditionMode)
  }

  _initForm () {
    this.elements.form = this.element.querySelector(widgetQueries.form)
    if (this.elements.form) {
      this.apis.form = this.elements.form['c-form']
      this.fetchedFormData = this.getFormData()
    }

    this.elements.externalEditForm = this.element.querySelector(widgetQueries.externalEditModal + ' ' + widgetQueries.form)
    if (this.elements.externalEditForm) {
      this.apis.externalEditForm = this.elements.externalEditForm['c-form']
      this.fetchedExternalFormData = this.getExternalEditableFormData()
    }
  }

  _renderHTML (html, targetElement) {
    const newContent = elementFromString(html)
    flush(targetElement)
    targetElement.appendChild(newContent)
    Component.initDocumentComponentsFromAPI(newContent)
  }
}

import { LoaderTemplate } from '../../../components/loader/c-loader.template'

export const defaultBookingCancellationFlowLoaderTemplateTemplate = {
  text: ''
}

export const BookingCancellationFlowLoaderTemplate = (d) => {
  d = { ...defaultBookingCancellationFlowLoaderTemplateTemplate, ...d }
  return `
    <div class="w-booking-cancellation-flow__body sp-stack-children--medium">
      <div class="m-body w-booking-cancellation-flow__loader-container">
        <div class="w-booking-cancellation-flow__loader">${LoaderTemplate({ size: 'tiny' })}</div>
        <div>${d.text}</div>
      </div>
    </div>
    `
}

import { renderTypeEnum } from './enums'
import PriceFormatter from '../../../js/helpers/price-formatter'
export default class BookingChoiceListDataMapper {
  mapService (apiService, configurations) {
    let rooms = [{ participantIds: [], name: '', id: null }]
    if (apiService.rooms && apiService.rooms.length) {
      rooms = this._checkRenderTypePerBooking(apiService.renderType) ? apiService.rooms.slice(0, 1) : apiService.rooms
    }
    const isAppliesToAllParticipantsVisible = this._checkIfAppliesToAllParticipantsMustBeVisible(configurations, apiService)
    const widgetService = {
      rooms: rooms ? rooms.map(room => this._mapRoom(room, apiService, configurations)) : [],
      messages: apiService.warnings && apiService.warnings.length ? this._mapMessages(apiService) : null,
      showMessagesOnTop: configurations && configurations.showMessagesOnTop ? configurations.showMessagesOnTop : false,
      conditions: configurations && configurations.conditionGroup ? this._mapConditions(configurations.conditionGroup, apiService) : null,
      choosePerPersonSelected: (isAppliesToAllParticipantsVisible && configurations.choosePerPersonSelected) || (isAppliesToAllParticipantsVisible && this._checkIfAppliesToAllParticipantsMustBeDisabled(apiService)) || false,
      choosePerPerson: isAppliesToAllParticipantsVisible ? this._mapAppliesToAllParticipants(configurations, apiService) : null
    }
    return widgetService
  }

  _mapRoom (apiRoom, apiService, configurations) {
    let participants = []

    if (this._checkRenderTypePerBooking(apiService.renderType)) {
      participants = apiService.participants.slice(0, 1)
    } else if (this._checkRenderTypePerRoom(apiService.renderType)) {
      participants = this._filterParticipants(apiService, apiRoom).slice(0, 1)
    } else {
      participants = this._filterParticipants(apiService, apiRoom)
    }

    const widgetRoom = {
      roomName: this._checkRenderTypePerBooking(apiService.renderType) ? '' : apiRoom.name,
      participants: participants.map(apiParticipant => this._mapParticipant(apiParticipant, apiService, apiRoom, configurations))
    }

    return widgetRoom
  }

  _filterParticipants (apiService, apiRoom) {
    if (!apiRoom.participantIds || apiRoom.participantIds.length === 0) return apiService.participants
    return apiService.participants.filter(
      participant => apiRoom.participantIds.includes(participant.id) &&
      apiService.options.some(option => option.appliesToParticipants.includes(participant.id))
    )
  }

  _mapParticipant (apiParticipant, apiService, apiRoom, configurations) {
    const participantIds = apiParticipant.id
    const isRequired = (!apiService.ignoreValidation && apiService.requiredMessage && apiService.requiredMessage !== '')
    const widgetParticipant = {
      choiceListInfo: {
        method: 'single',
        highlighted: false,
        id: apiService.componentId + '_' + participantIds,
        name: apiService.componentId + '_' + participantIds,
        label: this._checkRenderTypePerParticipant(apiService.renderType) ? (apiParticipant.firstName || apiParticipant.alternativeName) : '',
        extraClasses: '',
        disabled: false,
        unresolved: false,
        items: apiService.options.filter(option => option.appliesToParticipants.includes(apiParticipant.id)).map(option => this._mapOption(option, apiParticipant, apiService, apiRoom, configurations)),
        required: isRequired,
        requiredMessage: apiService.requiredMessage
      }
    }
    return widgetParticipant
  }

  _mapOption (apiOption, apiParticipant, apiService, apiRoom, configurations) {
    const isRequired = (!apiService.ignoreValidation && apiService.requiredMessage && apiService.requiredMessage !== '')
    let participantIds = []
    if (apiOption.participantIds) {
      participantIds = [...apiOption.participantIds]
    } else if (this._checkRenderTypePerBooking(apiService.renderType)) {
      participantIds = [...apiService.participants.map(participant => participant.id)]
    } else if (this._checkRenderTypePerRoom(apiService.renderType)) {
      participantIds = [...apiRoom.participantIds]
    } else {
      participantIds = [apiParticipant.id]
    }

    const priceConfig = configurations && configurations.priceConfiguration ? configurations.priceConfiguration : apiService.priceConfiguration
    let optionPriceFormatted
    // apiService.isPricePerPerson means that the apiOption.price is for each participant,
    // else the option price is for whole service no matter the number of participants
    if (apiService.isPricePerPerson && apiOption.prices) {
      let calculatedOptionPrice = 0
      apiOption.prices.forEach(optionPrice => {
        const includedIds = participantIds.filter(id => optionPrice.participantIds.includes(id))
        if (includedIds.length > 0) {
          calculatedOptionPrice += includedIds.length * optionPrice.price
        }
      })
      const filterEmptyParticipantIdsFromPrices = apiOption.prices.filter(price => price.participantIds.length)

      if (!configurations.choosePerPersonSelected && this._checkIfAppliesToAllParticipantsMustBeVisible(configurations, apiService) && filterEmptyParticipantIdsFromPrices.length > 1) {
        optionPriceFormatted = this._priceSplittedPerAgeCategory(filterEmptyParticipantIdsFromPrices, apiService.participants, configurations, priceConfig, apiOption, apiService)
      } else {
        optionPriceFormatted = PriceFormatter.toFormattedText(calculatedOptionPrice, priceConfig)
      }
    } else {
      optionPriceFormatted = this._priceFormatted(apiOption.price, participantIds, priceConfig, apiService.isPricePerPerson)
    }

    const isDisabled = (apiOption.disabledParticipants ? apiOption.disabledParticipants.includes(apiParticipant.id) : false)

    const uniqueId = apiOption.code + '-' + participantIds.join('-')
    const widgetItem = {
      id: uniqueId,
      name: uniqueId,
      value: apiOption.code,
      text: apiOption.displayText,
      additionalText: apiOption.percentage || optionPriceFormatted,
      disabled: isDisabled,
      checked: apiOption.isSelected && apiOption.assignedParticipants ? apiOption.assignedParticipants.includes(apiParticipant.id) : false,
      participantIds,
      appliesToParticipants: apiOption.appliesToParticipants ? apiOption.appliesToParticipants : null,
      assignedParticipants: apiOption.assignedParticipants ? apiOption.assignedParticipants : null,
      price: apiOption.price || null,
      prices: apiOption.prices || null,
      required: isRequired,
      requiredMessage: apiService.requiredMessage,
      highlightText: apiOption.bestValueText,
      startDate: apiOption.startDate,
      endDate: apiOption.endDate
    }
    return widgetItem
  }

  _priceSplittedPerAgeCategory (filterEmptyParticipantIdsFromPrices, participants, configurations, priceConfig, apiOption, apiService) {
    const participantsGroupedByAgeCategory = this._getParticipantsGroupedByKey(participants, 'ageCategory')

    return participantsGroupedByAgeCategory
      ? this._showAgeCategoryWithPrice(participantsGroupedByAgeCategory, filterEmptyParticipantIdsFromPrices, configurations, priceConfig, apiOption, apiService)
      : this._showRangePrice(filterEmptyParticipantIdsFromPrices, priceConfig)
  }

  _showAgeCategoryWithPrice (participantsGroupedByAgeCategory, filterEmptyParticipantIdsFromPrices, configurations, priceConfig, apiOption, apiService) {
    if (!participantsGroupedByAgeCategory) { return }
    const ageCategoriesTexts = configurations && configurations.ageCategoriesTexts ? configurations.ageCategoriesTexts : null
    const pricePerAgeCategory = Object.keys(participantsGroupedByAgeCategory).map(ageCategory => {
      const priceItem = filterEmptyParticipantIdsFromPrices.find(item => item.participantIds.includes(participantsGroupedByAgeCategory[ageCategory][0].id))
      const optionPrice = (priceItem !== undefined) ? priceItem.price : null
      const ageCategoryTranslated = `<span class="w-booking-choice-list__additional-text-legend">${ageCategoriesTexts ? ageCategoriesTexts[ageCategory.toLowerCase()] : ageCategory}</span>`
      priceConfig = { ...priceConfig, suffix: '' }
      return `<span class="w-booking-choice-list__additional-text--age-category">${configurations && configurations.isLabelPricePerAgeCategoryAtStart ? ageCategoryTranslated : ''} <span class="w-booking-choice-list__additional-text-price">${PriceFormatter.toFormattedText(optionPrice, priceConfig)}</span> ${configurations && !configurations.isLabelPricePerAgeCategoryAtStart ? ageCategoryTranslated : ''}</span>`
    }).join('')

    const calculatedOptionPrice = this._calculateTotalPricePerOption(apiOption, apiService)
    return `${PriceFormatter.toFormattedText(calculatedOptionPrice, priceConfig)} 
            <br> ${pricePerAgeCategory}`
  }

  _calculateTotalPricePerOption (apiOption, apiService) {
    let calculatedOptionPrice = 0

    if (apiService.isPricePerPerson && apiOption.prices) {
      apiOption.prices.forEach(optionPrice => {
        const includedIds = apiOption.appliesToParticipants.filter(id => optionPrice.participantIds.includes(id))
        if (includedIds.length > 0) {
          calculatedOptionPrice += includedIds.length * optionPrice.price
        }
      })
    } else {
      calculatedOptionPrice = apiOption.price
    }

    return calculatedOptionPrice
  }

  _showRangePrice (filterEmptyParticipantIdsFromPrices, priceConfig) {
    const pricesOrdered = filterEmptyParticipantIdsFromPrices.sort((firstItem, secondItem) => firstItem.price - secondItem.price)
    const firstItem = pricesOrdered.shift()
    const lastItem = pricesOrdered.pop()
    return `${PriceFormatter.toFormattedText(firstItem.price, priceConfig)} - ${PriceFormatter.toFormattedText(lastItem.price, priceConfig)}`
  }

  _getParticipantsGroupedByKey (participants, key) {
    if (!participants.filter(participant => Object.prototype.hasOwnProperty.call(participant, key)).length > 0) { return }
    return participants.reduce((result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item
      ]
    }), {})
  }

  _mapMessages (apiService) {
    let messages = []
    if (apiService.warnings) {
      messages = apiService.warnings.map(warning => this._mapMessage(warning))
    }
    return messages
  }

  _priceFormatted (price, participantIds, priceConfiguration, isPricePerPerson) {
    const totalPrice = isPricePerPerson && participantIds.length >= 1
      ? (participantIds.length * price)
      : price

    return PriceFormatter.toFormattedText(totalPrice, priceConfiguration)
  }

  _mapMessage (warning) {
    const type = warning.type ? warning.type.toLowerCase() : 'info'
    const message = {
      title: warning.title,
      type,
      texts: [warning.message]
    }
    return message
  }

  _mapConditions (conditionGroup, apiService) {
    const isRequired = conditionGroup.isRequired && apiService.options.some(option => option.isSelected)
    const isDisabled = this._checkIfAllServiceOptionsAreDisabledForAllParticipants(apiService)
    const conditions = {
      choiceListInfo: {
        method: conditionGroup.method.toLowerCase(),
        id: apiService.componentId + '_conditions',
        name: apiService.componentId + '_conditions',
        items: conditionGroup.options.map(option => ({ ...option, disabled: isDisabled, required: (isRequired && option.isRequired), checked: apiService.areConditionsAccepted })),
        required: isRequired,
        disabled: isDisabled,
        attributes: {
          'data-message-required': conditionGroup.messages.map(message => message).join(','),
          'data-w-booking-choice-list__choice-list--conditions': ''
        }
      }
    }
    return conditions
  }

  _mapAppliesToAllParticipants (configurations, apiService) {
    const appliesToAllParticipants = {
      switchInfo: {
        id: apiService.componentId + '_applies-to-all-participants',
        text: configurations.appliesToAllParticipantsLabel,
        checked: configurations.choosePerPersonSelected || this._checkIfAppliesToAllParticipantsMustBeDisabled(apiService),
        disabled: this._checkIfAppliesToAllParticipantsMustBeDisabled(apiService),
        extraClasses: 'w-booking-choice-list__switch--applies-to-all-participants',
        attributes: {
          'data-w-booking-choice-list__switch--applies-to-all-participants': ''
        }
      }
    }
    return appliesToAllParticipants
  }

  _checkIfAppliesToAllParticipantsMustBeDisabled (apiService) {
    const participantIds = apiService.participants.map(participant => participant.id)
    let isDisabled = false

    const anyOptionHasDisabledParticipants = apiService.options.some((option) => {
      return option.disabledParticipants && option.disabledParticipants.length > 0
    })

    const anyOptionHasAllParticipantsAssigned = apiService.options.some((option) => {
      return option.assignedParticipants && option.assignedParticipants.length > 0 ? this.checkIfParticipantsMatch(option.assignedParticipants, participantIds) : false
    })

    if (anyOptionHasDisabledParticipants && anyOptionHasAllParticipantsAssigned) {
      isDisabled = false
    } else if (anyOptionHasDisabledParticipants && !anyOptionHasAllParticipantsAssigned) {
      isDisabled = true
    } else {
      isDisabled = false
    }

    return isDisabled
  }

  _checkIfOneServiceOptionAppliesToAllParticipantsOrNone (apiService) {
    let checked = false
    checked = apiService.options.every((option) => {
      return option.assignedParticipants && option.assignedParticipants.length > 0 ? this.checkIfParticipantsMatch(option.assignedParticipants, option.appliesToParticipants) : true
    })
    return checked
  }

  _checkIfAllServiceOptionsAreDisabledForAllParticipants (apiService) {
    const participantIds = apiService.participants.map(participant => participant.id)
    let disabled = false
    disabled = apiService.options.every((option) => {
      return option.disabledParticipants ? this.checkIfParticipantsMatch(option.disabledParticipants, participantIds) : false
    })
    return disabled
  }

  checkIfParticipantsMatch (participantsFromOption, participantIds) {
    return Array.isArray(participantsFromOption) &&
      Array.isArray(participantIds) &&
      participantsFromOption.length === participantIds.length &&
      participantsFromOption.every((val, index) => val === participantIds[index])
  }

  _checkIfAppliesToAllParticipantsMustBeVisible (configurations, apiService) {
    return configurations && configurations.showAppliesToAllParticipants && this._checkRenderTypePerParticipant(apiService.renderType) && apiService.participants.length > 1
  }

  _checkRenderTypePerParticipant (renderType) {
    if (renderType !== renderTypeEnum.PerParticipant) return false
    return true
  }

  _checkRenderTypePerRoom (renderType) {
    if (renderType !== renderTypeEnum.PerRoom) return false
    return true
  }

  _checkRenderTypePerBooking (renderType) {
    if (renderType !== renderTypeEnum.PerBooking) return false
    return true
  }
}

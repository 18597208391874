import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingParticipantSummary from '../../../../shared/modules/widgets/booking-participant-summary/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const bookingParticipantSummaryData = require('../../../../shared/modules/widgets/booking-participant-summary/data/w-booking-participant-summary__js-template')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = async () => {
  const bookingParticipantSummaryItems = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-participant-summary"]')

  bookingParticipantSummaryItems.forEach(element => {
    const bookingParticipantSummary = new BookingParticipantSummary(element)
    return bookingParticipantSummary
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingParticipantSummaryJsTemplate = document.querySelectorAll('[data-booking-participant-summary-demo]')
  handleFetched(bookingParticipantSummaryJsTemplate, bookingParticipantSummaryData)
}

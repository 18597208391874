const defaultDateSelectorSettings = {
  label: 'Date of birth (on date of departure)',
  dayPlaceholder: 'Day',
  monthPlaceholder: 'Month',
  yearPlaceholder: 'Year',
  messageInvalid: 'Error Invalid date',
  messageOutOfBounds: 'Out of range',
  emptyErrorMessage: 'Empty birthdate',
  isMandatory: true,
  startDate: null,
  endDate: null
}

export default class DateSelectorConfig {
  constructor (element) {
    this.element = element

    this.dateSelectorsConfig = { ...defaultDateSelectorSettings }
    this.setDateSelectorFieldsSettings = this._setDateSelectorFieldsSettings.bind(this)
  }

  _setDateSelectorFieldsSettings (birthdateSettings = {}) {
    this.dateSelectorsConfig = { ...this.dateSelectorsConfig, ...birthdateSettings }

    return {
      label: this.dateSelectorsConfig.label,
      placeholders: {
        day: this.dateSelectorsConfig.dayPlaceholder,
        month: this.dateSelectorsConfig.monthPlaceholder,
        year: this.dateSelectorsConfig.yearPlaceholder
      },
      messageInvalid: this.dateSelectorsConfig.messageInvalid,
      messageOutOfBounds: this.dateSelectorsConfig.messageOutOfBounds,
      emptyErrorMessage: this.dateSelectorsConfig.emptyErrorMessage,
      isMandatory: this.dateSelectorsConfig.isMandatory,
      dateRange: {
        startDate: this.dateSelectorsConfig.startDate,
        endDate: this.dateSelectorsConfig.endDate
      }
    }
  }
}

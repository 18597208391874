/**
 * The FlightId identifies a single flight
 *
 * @typedef {String}          FlightId
 */

/**
 * The FlightData contains all data for a flight, outbound or inbound
 *
 * @typedef {Object}          FlightData
 *
 * @property {String}         airlineId
 * @property {FlightId}       flightId
 * @property {String}         arrivalAirportId
 * @property {String}         departureAirportId
 * @property {Object}         departureDate
 * @property {String}         departureDate.formattedDate                   - Departure's humanized date
 * @property {String}         departureDate.formattedTime                   - Departure's humanized time
 * @property {Object}         arrivalDate
 * @property {String}         arrivalDate.formattedDate                     - Arrival's humanized date
 * @property {String}         arrivalDate.formattedTime                     - Arrival's humanized time
 * @property {String}         checkedInLuggageDescription                   - Checked-in luggage info
 * @property {String}         handLuggageDescription                        - Hand luggage info
 * @property {Object[]}       inboundRelatedFlights
 * @property {String}         inboundRelatedFlights.inboundFlightId         - Related inbound flight id
 * @property {Object}         inboundRelatedFlights.priceBreakdown          - Price breakdown (rooms, services...)
 * @property {Object}         inboundRelatedFlights.routePricePerPerson
 * @property {Object}         inboundRelatedFlights.routeTotalPrice
 * @property {Object[]}       inboundRelatedFlights.tripSummary             - (Dates, participants, flight info...)
 * @property {Object}         priceDiff                                     - Price diff from the default selected flight
 * @property {String}         priceDiff.formatted                           - Price diff humanized text
 * @property {Boolean}        isFlexible                                    - True if the flight is internal
 * @property {Boolean}        isSelected
 * @property {Boolean}        isDepartureTimeSpecified                      - True if the flight has departure time
 * @property {Boolean}        isArrivalTimeSpecified                        - True if the flight has arrival time
 * @property {String}         remark                                        - Remark about the flight
 */

/**
 * The FlightSelectorApiData contains all data as received from API
 *
 * @typedef {Object}          FlightSelectorApiData
 *
 * @property {Object[]}       airlines                              - Airlines' info
 * @property {Object[]}       airports                              - Airports' info
 * @property {Object}         configurations                        - Extra configuration like price-diff label
 * @property {Object}         configurations.labels
 * @property {String}         configurations.labels.priceDiffText   - (price per person, p.p...)
 * @property {FlightData[]}   outboundFlights                       - Departure flights
 * @property {FlightData[]}   inboundFlights                        - Return flights
 * @property {Object}         priceMismatchMessage                  - Price mismatch message when price difference
 * @property {String}         extraInfo                             - Extra info shown at bottom of flight selector
 * @property {Object[]}       detailedTripSummary                   - Detail info related with the selected trip
 * @property {String[]}       contentUsps                           - Content USP's
*/

/**
 * The FlightSelectorData contains all data organized to be easily displayed in a table
 *
 * @typedef {FlightSelectorApiData}          FlightSelectorData
 *
 * @property {String}         id
 */

const MessageAlertDictionary = {
  info: 'info',
  alert: 'warning'
}

const defaults = {
  airlines: [],
  airports: [],
  outboundFlights: [],
  inboundFlights: [],
  detailedTripSummary: [],
  contentUsps: []
}

/**
 * A FlightSelectorData model
 */
export default class FlightSelectorDataModel {
  /**
   * Creates a new FlightSelectorDataModel
   *
   * @constructor
   * @param {FlightSelectorData|{}}           [attributes={}]   - The model attributes
   * @param {FlightBusSelectorTemplateData}   [defaultData]     - Extra data (default texts and others)
   */
  constructor (attributes = {}, defaultData = {}) {
    this.id = attributes.id
    this._setSelectorData({
      ...defaults,
      ...attributes
    })
    this.defaultData = defaultData
  }

  /**
   * Sets a price table model
   * @param {FlightSelectorData} selectorApiData -
   *
   * @returns {FlightSelectorDataModel} Self instance.
   */
  _setSelectorData (selectorApiData) {
    this.airlineDictionary = selectorApiData.airlines.reduce((obj, { id, name }) => ({ ...obj, [id]: name }), {})
    this.airportDictionary = selectorApiData.airports.reduce((obj, { id, name }) => ({ ...obj, [id]: name }), {})

    const inboundPriceDiffs = {}
    this.selectorData = {
      outboundData: selectorApiData.outboundFlights.reduce((obj, outboundFlight) => ({
        ...obj,
        [outboundFlight.flightId]: {
          ...outboundFlight,
          inboundRelatedItems: outboundFlight.inboundRelatedFlights.reduce((obj, inboundFlight) => {
            // We gather round information about price-diff for every item
            if (!inboundPriceDiffs[inboundFlight.inboundFlightId]) {
              inboundPriceDiffs[inboundFlight.inboundFlightId] = inboundFlight.priceDiff
            }
            return {
              ...obj,
              [inboundFlight.inboundFlightId]: {
                ...inboundFlight,
                isOpenJaw: outboundFlight.departureAirportId.toUpperCase() !== inboundFlight.arrivalAirportCode.toUpperCase()
              }
            }
          },
          {})
        }
      }),
      {}),
      inboundData: selectorApiData.inboundFlights.reduce((obj, inboundFlight) => ({
        ...obj,
        [inboundFlight.flightId]: {
          ...inboundFlight,
          priceDiff: inboundPriceDiffs[inboundFlight.flightId]
        }
      }),
      {}),
      priceMismatchMessage: (selectorApiData.priceMismatchMessage)
        ? {
            body: selectorApiData.priceMismatchMessage.text,
            alertType: MessageAlertDictionary[selectorApiData.priceMismatchMessage.level],
            closable: false
          }
        : null,
      detailedTripSummary: selectorApiData.tripSummary,
      contentUsps: selectorApiData.contentUsps
    }
    return this
  }

  /**
   * Gets the flight selector data
   *
   * @returns {FlightBusSelectorData} Self instance.
   */
  getSelectorData () {
    return this.selectorData
  }

  /**
   * Returns true if there is flexible flight
   *
   * @returns {Boolean}
   */
  _isFlexibleTabActive (items) {
    let result = false
    Object.keys(items).forEach(key => {
      if (items[key].isFlexibleTicket) {
        result = true
      }
    })
    return result
  }

  /**
   * Returns false if there is regular flight
   *
   * @returns {Boolean}
   */
  _isRegularTabActive (items) {
    let result = false
    Object.keys(items).forEach(key => {
      if (!items[key].isFlexibleTicket) {
        result = true
      }
    })
    return result
  }

  /**
   * Returns Object with all the selector items
   *
   * @returns {Object}
   */
  _getSelectorItems () {
    return { ...this.selectorData.outboundData, ...this.selectorData.inboundData }
  }

  typeOfFlights (outboundData) {
    const result = {
      isOpenJaw: false,
      isCloseJaw: false
    }

    Object.keys(outboundData).forEach((outboundKey) => {
      outboundData[outboundKey].inboundRelatedFlights.forEach(item => {
        if (outboundData[outboundKey].departureAirportId.toUpperCase() === item.arrivalAirportCode.toUpperCase()) {
          result.isCloseJaw = true
        } else {
          result.isOpenJaw = true
        }
      })
    })
    return result
  }

  /**
   * Gets the flight selector object the template will use
   *
   * @returns {FlightBusSelectorTemplateData} - The Flight Bus Selector Template Data
   */
  getSelectorTemplateData (options = {}) {
    const locales = options.locales

    const templateData = {
      ...this.defaultData,
      id: this.id,
      outboundDepartureDate: Object.values(this.selectorData.outboundData)[0].departureDate.formattedDate,
      outboundArrivalDate: Object.values(this.selectorData.outboundData)[0].arrivalDate?.formattedDate,
      inboundDepartureDate: Object.values(this.selectorData.inboundData)[0].departureDate.formattedDate,
      inboundArrivalDate: Object.values(this.selectorData.inboundData)[0].arrivalDate?.formattedDate,
      priceMismatchMessage: this.selectorData.priceMismatchMessage,
      extraInfo: locales.flightSelector.extraInfo,
      headerText: locales.flightSelector.headerText,
      outboundHeading: locales.flightSelector.departureText,
      inboundHeading: locales.flightSelector.returnText,
      buttonMoreText: locales.flightSelector.showMoreText,
      buttonLessText: locales.flightSelector.showLessText,
      buttonCancelText: locales.flightSelector.closeText,
      buttonSubmitText: locales.flightSelector.confirmPreselectionText,
      changeTransportButtonText: locales.flightSelector.changeFlightsButtonText,
      unrelatedHeading: locales.flightSelector.unrelatedInboundsText,
      typeOfFlights: this.typeOfFlights(this.selectorData.outboundData),
      openJawSwitchTitle: locales.flightSelector.openJawSwitchTitle,
      openJawSwitchDescription: locales.flightSelector.openJawSwitchDescription,
      openJawSwitchStatus: locales.flightSelector.openJawSwitchStatus,
      isOpenJawFilteringEnabled: !!locales.flightSelector.isOpenJawFilteringEnabled,
      sideDrawer: {
        title: locales.flightSelector.headerText,
        icon: locales.flightSelector.sideDrawerHeader ? locales.flightSelector.sideDrawerHeader || '' : '' // not used in the side drawer at the moment.
      },
      buttons: [
        {
          text: locales.flightSelector.departureText,
          icon: locales.flightSelector.outboundTransport ? locales.flightSelector.outboundTransport || '' : ''
        },
        {
          text: locales.flightSelector.returnText,
          icon: locales.flightSelector.inboundTransport ? locales.flightSelector.inboundTransport || '' : ''
        }
      ]
    }

    if (locales.flightSelector.flightTicketInfo && locales.flightSelector.flightTicketInfo.regularTicket) {
      templateData.regularTicket = {
        active: this._isRegularTabActive(this._getSelectorItems()),
        title: locales.flightSelector.flightTicketInfo.regularTicket.title,
        characteristics: locales.flightSelector.flightTicketInfo.regularTicket.characteristics
      }
    }

    if (locales.flightSelector.flightTicketInfo && locales.flightSelector.flightTicketInfo.flexibleTicket) {
      templateData.flexibleTicket = {
        active: this._isFlexibleTabActive(this._getSelectorItems()),
        title: locales.flightSelector.flightTicketInfo.flexibleTicket.title,
        characteristics: locales.flightSelector.flightTicketInfo.flexibleTicket.characteristics
      }
    }

    return templateData
  }

  /**
   * Gets the flight selector's item object the template will use
   *
   * @param {FlightData}  flightInfo            - The flight info
   * @param {Object}      options               - The options
   * @param {Boolean}     options.isInbound     - If true, flight type is inbound. It's Outbound by default)
   *
   * @returns {flightBusSelectorItemTemplateData} - The flight bus selector item template data
   */
  getSelectorItemTemplateData (flightInfo, options = {}) {
    const locales = options.locales

    let luggageHandWeight
    let luggageHandDescription
    let luggageHandNotIncludedText
    let luggageHoldWeight
    let luggageHoldDescription

    const handLuggage = flightInfo.luggage && flightInfo.luggage.find(item => item.type === 'hand')
    const holdLuggage = flightInfo.luggage && flightInfo.luggage.find(item => item.type === 'hold')

    const luggageUnitText = locales.flightSelector.luggageUnitText
    if (handLuggage) {
      luggageHandWeight = handLuggage.weight + ''
      luggageHandDescription = locales.flightSelector.luggageHandLabelText + ': ' + handLuggage.weight + locales.flightSelector.luggageUnitText
      luggageHandNotIncludedText = handLuggage.weight === 0 ? locales.flightSelector.luggageHandNotIncludedText : ''
    }
    if (holdLuggage) {
      luggageHoldWeight = holdLuggage.weight + ''
      luggageHoldDescription = locales.flightSelector.luggageHoldLabelText + ': ' + holdLuggage.weight + locales.flightSelector.luggageUnitText
    }

    const templateData = {
      ...options,
      id: flightInfo.flightId,
      name: this.id,
      companyId: flightInfo.airlineId.toUpperCase(),
      companyName: this.airlineDictionary[flightInfo.airlineId],
      departureName: this.airportDictionary[flightInfo.departureAirportId],
      departureTime: flightInfo.departureDate.formattedTime,
      arrivalName: this.airportDictionary[flightInfo.arrivalAirportId],
      arrivalTime: flightInfo.arrivalDate.formattedTime,
      weightLuggageSymbol: luggageUnitText,
      maxHandLuggageWeight: luggageHandWeight,
      handLuggageDescription: luggageHandDescription,
      handLuggageNotIncludedText: luggageHandNotIncludedText,
      maxCheckedLuggageWeight: luggageHoldWeight,
      checkedLuggageDescription: luggageHoldDescription,
      isFlexibleTicket: flightInfo.isFlexibleTicket,
      regularTicketText: '',
      flexibleTicketText: '',
      checked: flightInfo.isSelected,
      isDepartureTimeSpecified: flightInfo.isDepartureTimeSpecified,
      isArrivalTimeSpecified: flightInfo.isArrivalTimeSpecified,
      remark: flightInfo.remark,
      disabled: false,
      price: {
        value: flightInfo.priceDiff.sign === '+' ? `${flightInfo.priceDiff.sign}${flightInfo.priceDiff.formattedOnlyNumber}` : flightInfo.priceDiff.formattedOnlyNumber,
        currency: flightInfo.priceDiff.currency,
        currencyPosition: flightInfo.priceDiff.currencyAtStart ? 'before' : 'after',
        ...(locales.flightSelector.priceDiffLabel ? { label: locales.flightSelector.priceDiffLabel } : {})
      },
      unknownTimeText: locales.flightSelector.unknownTimeText,
      enRouteTransportIcon: locales.flightSelector.enRouteTransport ? locales.flightSelector.enRouteTransport || '' : ''
    }

    if (locales.flightSelector.flightTicketInfo && locales.flightSelector.flightTicketInfo.regularTicket) {
      templateData.regularTicketText = locales.flightSelector.flightTicketInfo.regularTicket.title
    }

    if (locales.flightSelector.flightTicketInfo && locales.flightSelector.flightTicketInfo.flexibleTicket) {
      templateData.flexibleTicketText = locales.flightSelector.flightTicketInfo.flexibleTicket.title
    }

    return templateData
  }
}

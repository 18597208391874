import { SliderTemplate } from '../../components/slider/c-slider.template'

export const ProductCardSliderTemplate = (d) => {
  const sliderAttributes = {
    'data-loop': false,
    'data-responsive': 'xs:1',
    'data-gutter': '32',
    'data-edge-padding': '16'
  }

  return SliderTemplate({
    id: 'product-map__product-card-slider',
    items: d.sliderItems,
    attributes: sliderAttributes,
    barNavigation: false,
    outerNavigation: false,
    withGradient: false,
    extraClasses: 'w-product-map__product-card-slider'
  })
}

/**
 * The BusId identifies a single bus
 *
 * @typedef {String}          BusId
 */

/**
 * The BusData contains all data for a bus, outbound or inbound
 *
 * @typedef {Object}          BusData
 *
 * @property {BusId}          busId
 * @property {String}         departureCode                         - Departure id
 * @property {String}         departureCityName
 * @property {String}         departureStationName
 * @property {Object}         departureDate
 * @property {String}         departureDate.formattedDate           - Departure's humanized date
 * @property {String}         departureDate.formattedTime           - Departure's humanized time
 * @property {String}         arrivalCode                           - Arrival id
 * @property {String}         arrivalCityName
 * @property {String}         arrivalStationName
 * @property {Object}         priceDiff                             - Price diff from the default selected bus
 * @property {String}         priceDiff.formatted                   - Price diff humanized text
 * @property {String}         routeTotalPriceFormatted              - Total Price humanized text
 * @property {String}         routePricePerPersonFormatted          - Price per person humanized text
 * @property {Boolean}        isSelected
 */

/**
 * The BusSelectorApiData contains all data as received from API
 *
 * @typedef {Object}          BusSelectorApiData
 *
 * @property {Object[]}       routes                                - All combination of routes and its info
 * @property {Object[]}       routes.outboundBusId                  -
 * @property {Object[]}       routes.inboundBusId                   -
 * @property {Object}         configurations                        - Extra configuration like price-diff label
 * @property {Object}         configurations.labels
 * @property {String}         configurations.labels.priceDiffText   - (price per person, p.p...)
 * @property {BusData[]}      outboundBuses                         - Departure buses
 * @property {BusData[]}      inboundBuses                          - Return buses
 * @property {Object[]}       detailedTripSummary                   - Detail info related with the selected trip
 */

/**
 * The BusSelectorData contains all data organized to be easily displayed in a table
 *
 * @typedef {BusSelectorApiData}          BusSelectorData
 *
 * @property {String}         id
 */

import { parseFormattedPrice } from '../../components/price/c-price.template'

const defaults = {
  routes: [],
  outboundBuses: [],
  inboundBuses: [],
  detailedTripSummary: []
}

/**
 * A BusSelectorData model
 */
export default class BusSelectorDataModel {
  /**
   * Creates a new BusSelectorDataModel
   *
   * @constructor
   * @param {BusSelectorData|{}}              [attributes={}]   - The model attributes
   * @param {FlightBusSelectorTemplateData}   [defaultData]     - Extra data (default texts and others)
   */
  constructor (attributes = {}, defaultData = {}) {
    this.id = attributes.id
    this._setSelectorData({
      ...defaults,
      ...attributes
    })
    this.defaultData = defaultData
  }

  /**
   * Sets a price table model
   * @param {BusSelectorApiData} selectorApiData
   *
   * @returns {BusSelectorDataModel} Self instance.
   */
  _setSelectorData (selectorApiData) {
    const inboundPriceDiffs = {}
    this.selectorData = {
      outboundData: selectorApiData.outboundBuses.reduce((obj, outboundBus) => ({
        ...obj,
        [outboundBus.busId]: {
          ...outboundBus,
          inboundRelatedItems: selectorApiData.routes.filter(({ outboundBusId }) => outboundBusId === outboundBus.busId).reduce((obj, route) => {
            // We gather round information about price-diff for every item
            if (!inboundPriceDiffs[route.inboundBusId]) {
              inboundPriceDiffs[route.inboundBusId] = route.priceDiff
            }
            route.routeTotalPrice = {
              raw: parseFormattedPrice(route.routeTotalPriceFormatted).value,
              formatted: route.routeTotalPriceFormatted
            }
            route.routePricePerPerson = {
              raw: parseFormattedPrice(route.routePricePerPersonFormatted).value,
              formatted: route.routePricePerPersonFormatted
            }
            return {
              ...obj,
              [route.inboundBusId]: route
            }
          }, {})
        }
      }), {}),
      inboundData: selectorApiData.inboundBuses.reduce((obj, inboundBus) => ({
        ...obj,
        [inboundBus.busId]: {
          ...inboundBus
        }
      }), {}),
      detailedTripSummary: selectorApiData.tripSummary
    }

    return this
  }

  /**
   * Gets the flight selector data
   *
   * @returns {FlightBusSelectorData} Self instance.
   */
  getSelectorData () {
    return this.selectorData
  }

  /**
   * Gets the bus selector object the template will use
   *
   * @returns {FlightBusSelectorTemplateData}
   */
  getSelectorTemplateData (options = {}) {
    return {
      ...this.defaultData,
      id: this.id,
      outboundDepartureDate: Object.values(this.selectorData.outboundData)[0].departureDate.formattedDate,
      outboundArrivalDate: Object.values(this.selectorData.outboundData)[0].arrivalDate?.formattedDate,
      inboundDepartureDate: Object.values(this.selectorData.inboundData)[0].departureDate.formattedDate,
      inboundArrivalDate: Object.values(this.selectorData.inboundData)[0].arrivalDate?.formattedDate,
      extraInfo: options.locales.busSelector.extraInfo,
      headerText: options.locales.busSelector.headerText,
      outboundHeading: options.locales.busSelector.departureHeaderText,
      inboundHeading: options.locales.busSelector.returnHeaderText,
      buttonMoreText: options.locales.busSelector.showMoreText,
      buttonLessText: options.locales.busSelector.showLessText,
      buttonCancelText: options.locales.busSelector.closeText,
      buttonSubmitText: options.locales.busSelector.confirmPreselectionText,
      changeTransportButtonText: options.locales.busSelector.changeBusesButtonText,
      sideDrawer: {
        title: options.locales.busSelector.headerText,
        icon: options.locales.busSelector.sideDrawerHeader ? options.locales.busSelector.sideDrawerHeader || '' : '' // not used in the side drawer at the moment.
      },
      buttons: [
        {
          text: options.locales.busSelector.departureHeaderText,
          icon: options.locales.busSelector.outboundTransport ? options.locales.busSelector.outboundTransport || '' : ''
        },
        {
          text: options.locales.busSelector.returnHeaderText,
          icon: options.locales.busSelector.inboundTransport ? options.locales.busSelector.inboundTransport || '' : ''
        }
      ]
    }
  }

  /**
   * Gets the bus selector's item object the template will use
   *
   * @param {BusData}     busInfo
   * @param {Object}      options
   * @param {Boolean}     options.isInbound     - If true, bus type is inbound. It's Outbound by default)
   *
   * @returns {flightBusSelectorItemTemplateData}
   */
  getSelectorItemTemplateData (busInfo, options = {}) {
    return {
      ...options.locales,
      id: busInfo.busId,
      name: this.id,
      type: 'bus',
      companyName: busInfo[`${options.isInbound ? 'arrival' : 'departure'}StationName`],
      departureName: busInfo[`${options.isInbound ? 'arrival' : 'departure'}CityName`],
      ...(!options.isInbound ? { departureTime: busInfo.departureDate.formattedTime } : {}),
      isInbound: options.isInbound,
      checked: busInfo.isSelected,
      internal: false,
      disabled: false,
      price: {
        ...parseFormattedPrice(busInfo.priceDiff.formatted, '+'),
        ...(options.locales.busSelector.priceDiffLabel ? { label: options.locales.busSelector.priceDiffLabel } : {})
      }
    }
  }
}

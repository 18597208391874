import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import JSONFetcher from '../../../../shared/js/helpers/json-fetcher'
import FilterModel from '../../../../shared/js/data/filters/filter-model'
import AirportFilter from '../../../../shared/modules/widgets/airport-filter/main'

loadQueueHelper.add(function () {
  initAirportFilters()
}, 2)

const initAirportFilters = () => {
  const airportFilterElement = document.getElementById('my-airport-filter-se')
  const airportFilterElementDe = document.getElementById('my-airport-filter-de')
  if (!airportFilterElement || !airportFilterElementDe) return
  console.log('Airport Filter Elements found', airportFilterElement, airportFilterElementDe)

  const quickSearchRequest = new JSONFetcher({ src: '../assets/api/airports.json' })
  quickSearchRequest
    .fetch()
    .then(data => {
      const simpleAirportsModel = new FilterModel(data.se)
      const nestedAirportsModel = new FilterModel(data.de)

      console.log('Airport Data Models initialized', simpleAirportsModel, nestedAirportsModel)

      const airportFilter = new AirportFilter(airportFilterElement, simpleAirportsModel)
      console.log('Airport initialized', airportFilter)
      const airportFilterDe = new AirportFilter(airportFilterElementDe, nestedAirportsModel)
      console.log('Airport initialized', airportFilterDe)
    })
}

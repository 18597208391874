import { BookingBox } from '../booking-box/main'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import { BookingComparisonTableTemplate } from '../booking-comparison-table/w-booking-comparison-table.template'
import BookingComparisonTableDataMapper from '../booking-comparison-table/data-mapper'
import { bookingItemEvents } from '../../../js/document/event-types'
import registeredEvents from '../../../js/helpers/registered-events'

const widgetApi = 'w-booking-comparison-table'
const widgetQueries = {
  configurationElement: `[data-${widgetApi}__configuration]`,
  selectButton: `[data-${widgetApi}__select-button]`,
  buttonApi: 'c-btn',
  trackAttr: `data-${widgetApi}__track`
}

const configOptions = {
  showMessagesOnTop: 'showMessagesOnTop',
  selectTextButton: 'selectTextButton',
  unselectTextButton: 'unselectTextButton',
  moreInfoTextButton: 'moreInfoTextButton',
  topInformationText: 'topInformationText'
}

const attr = {
  optionCode: `data-${widgetApi}__option-code`
}

const operationTypeEnum = {
  addOperation: 'Add',
  removeOperation: 'Remove',
  swapOperation: 'Swap',
  patchOperation: 'Patch'
}

export default class BookingComparisonTable extends BookingBox {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   */
  constructor (element) {
    super(element)
    this.element = element
    this.dataMapper = new BookingComparisonTableDataMapper()
    const filterConfigurationElement = this.element.querySelector(widgetQueries.configurationElement)
    this.configurations = this._readOptionsFromConfigurations(filterConfigurationElement)
    this.widgetData = null
    this.track = this.element.getAttribute(widgetQueries.trackAttr)

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.trackAttr) && { track: this.track }
    })
  }

  _attachEvents () {
    const selectButtons = [...this.element.querySelectorAll(widgetQueries.selectButton)]
    this.selectButtonApis = selectButtons.map(selectButton => selectButton[widgetQueries.buttonApi])
    this.selectButtonApis.forEach(selectButton => {
      selectButton.events.on('clickButton', (element) => {
        const optionCode = element.getAttribute(attr.optionCode)
        this._optionSelected(optionCode, this.widgetData.options)
      })
    })
  }

  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  _updateWidgetData () {
    const apiData = this.data.services && this.data.services.find(service => service.componentId === this.componentId)
    if (apiData) {
      this.widgetData = this.dataMapper.map(apiData, this.configurations)
    }

    if (this.widgetData) {
      super.showComponent()
      this._init(this.widgetData)
    } else {
      super.hideComponent()
    }
  }

  _init (data) {
    const renderedHtml = BookingComparisonTableTemplate(data)
    const newContent = elementFromString(renderedHtml)
    this._render(newContent)
    Component.initDocumentComponentsFromAPI(newContent)
    this._attachEvents()
  }

  _render (el) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(el)
  }

  _optionSelected (optionCode, options) {
    const selectedOption = options.find(option => option.value === optionCode)

    const eventArgs = {
      method: this._method,
      url: this._url,
      body: this._mapSelectedOptionForPatch(selectedOption, options),
      componentId: this.componentId
    }
    eventArgs.rawData = {
      text: selectedOption.text,
      price: selectedOption.price
    }
    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  _mapSelectedOptionForPatch (selectedOption, oldOptions) {
    const newServiceValue = selectedOption ? selectedOption.value : ''
    const oldServiceValue = this._getOldServiceValue(oldOptions)
    const oldOption = oldOptions.find(opt => opt.checked)
    const operationType = this._mapOperationType(newServiceValue, oldServiceValue)

    // Map services
    const newService = {
      code: (selectedOption) ? selectedOption.value : '',
      participants: (selectedOption) ? selectedOption.appliesToParticipants : null,
      startDate: (selectedOption) ? selectedOption.startDate : null,
      endDate: (selectedOption) ? selectedOption.endDate : null
    }

    const oldService = oldOption
      ? {
          code: oldOption.value,
          participants: oldOption.appliesToParticipants,
          startDate: oldOption.startDate,
          endDate: oldOption.endDate
        }
      : null

    const body = {
      operationType,
      newService,
      oldService
    }
    return body
  }

  _getOldServiceValue (oldOptions) {
    let oldServiceValue = ''
    if (oldOptions) {
      const oldOption = oldOptions.find(opt => opt.checked)
      if (oldOption) {
        oldServiceValue = oldOption.value
      }
    }
    return oldServiceValue
  }

  _mapOperationType (serviceValue, oldServiceValue) {
    let operationType = operationTypeEnum.addOperation

    if (serviceValue && oldServiceValue) {
      operationType = operationTypeEnum.swapOperation
    } else if (oldServiceValue) {
      operationType = operationTypeEnum.removeOperation
    }

    return operationType
  }

  _readOptionsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)

      return {
        showMessagesOnTop: configuration[configOptions.showMessagesOnTop],
        selectTextButton: configuration[configOptions.selectTextButton],
        unselectTextButton: configuration[configOptions.unselectTextButton],
        moreInfoTextButton: configuration[configOptions.moreInfoTextButton],
        topInformationText: configuration[configOptions.topInformationText]
      }
    }
  }
}

registerWidget(BookingComparisonTable, widgetApi)

import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import { bookingItemEvents, bookingStepsEvents } from '../../../js/document/event-types'
import { InternalMessagesTemplate, ErrorMessageTemplate, ErrorMessageMandatoryPaymentTemplate } from './w-booking-error.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import BookingErrorDataMapper from './data-mapper'
import registeredEvents from '../../../js/helpers/registered-events'
import { BookingErrorModalTemplate } from './w-booking-error__modal.template'
import Component from '../../../js/core/component/component'

const attr = {
  internalErrorsTitle: 'data-internal-messages-title'
}

const classNames = {
  hidden: 'hidden',
  hasMandatoryPayment: 'booking-error--has-mandatory-payment'
}

const widgetApi = 'w-booking-error'
const widgetQueries = {
  internalMessagesContainer: '[data-internal-messages-container]',
  form: '[data-js-component="c-form"]',
  nameInput: `[data-${widgetApi}--field-name-input]`,
  emailInput: `[data-${widgetApi}--field-email-input]`,
  phoneInput: `[data-${widgetApi}--field-phone-input]`,
  formSubmitButton: `[data-${widgetApi}--form-submit-button]`,
  formSuccessMessage: `[data-${widgetApi}--form-success-message]`,
  formErrorMessage: `[data-${widgetApi}--form-error-message]`,
  errorMessageSection: '[data-error-message-section]',
  errorMessageSectionMandatoryPayment: '[data-error-message-section-mandatory-payment]',
  errorMessageBody: '.booking-error--main-error .c-box__content',
  trackAttr: `data-${widgetApi}__track`,
  modalErrorElementSection: `[data-${widgetApi}__modal-section]`,
  modalErrorElement: `[data-${widgetApi}__modal]`,
  configurationElement: `[data-${widgetApi}__configuration]`,
  modalErrorButton: `[data-${widgetApi}__modal-btn]`
}

const configOptions = {
  modalSettings: 'modalSettings'
}
export default class BookingError extends BookingBox {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   * @param {Object} [options={}]   - Options
   */
  constructor (element, options = {}) {
    super(element)
    this.element = element
    this.dataMapper = new BookingErrorDataMapper()
    this.locales = this.getLocales()
    this.bookingId = this.element.getAttribute('data-bookingdraft-id')
    this.redirectUrl = null
    const filterConfigurationElement = this.element.querySelector(widgetQueries.configurationElement)
    this.configurations = this._readOptionsFromConfigurations(filterConfigurationElement) || {}
    this._initHtmlElements()
    this._initHtmlElementsApi()
    // Attach events to the booking error elements in the widget
    this._attachEvents()
    this.track = this.element.hasAttribute(widgetQueries.trackAttr) && this.element.attributes[widgetQueries.trackAttr].value

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.trackAttr) && { track: this.track }
    })
  }

  enableUI () {
    super.enableUI()
    this.hideComponent()
  }

  handleError (result) {
    super.handleError(result)

    let eventArgs = {}
    if (result.response && result.response.error) {
      eventArgs = {
        hasError: result.response.error.hasError,
        description: result.response.error.description
      }
    }

    if (this._hasMandatoryPayment(result)) {
      this._setMandatoryPayment()
    }

    if (this._isInfoRequestSuccessResponse(result)) {
      this._showFormMessageSuccess()
    } else if (this._isInfoRequestFailResponse(result)) {
      this._showFormMessageError()
      this._replaceExternalMessage({ errorMessage: '', errorCode: '' })
    } else if (this._isBookingResponse(result) && this._isRedirectResponse(result)) {
      this._renderModalError(result)
      this._setRedirectUrl(result)
      this._attachModalButtonEvents()
      eventArgs = {
        ...eventArgs,
        element: 'popup visible',
        interaction: false
      }
    } else if (this._isBookingResponse(result)) {
      const widgetData = this._mapWidgetData(result)
      this._renderInternalMessages(widgetData)
      this._replaceExternalMessage(widgetData.externalMessage)
      if (widgetData.emailInfo) {
        this.emailInputApi.setProp('value', widgetData.emailInfo)
      }
      if (widgetData.phoneInfo) {
        this.phoneInputApi.setProp('value', widgetData.phoneInfo)
      }
    }

    if (eventArgs) {
      this.events.emit(bookingStepsEvents.ERROR_MESSAGE, eventArgs)
    }
  }

  _isRedirectResponse (result) {
    let isRedirectResponse = false
    if (result.response && result.response.error) {
      isRedirectResponse = Object.prototype.hasOwnProperty.call(result.response.error, 'redirectUrlToAccoPage')
        ? Object.prototype.hasOwnProperty.call(result.response.error, 'redirectUrlToAccoPage')
        : Object.prototype.hasOwnProperty.call(result.response.error, 'redirectUrlToSearchPage')
    }
    return isRedirectResponse
  }

  _isBookingResponse (result) {
    return !!result.response
  }

  _isInfoRequestSuccessResponse (result) {
    const cause = result && result.response && result.response.error && result.response.error.cause
    return cause === 'SendInfoToContactCenter'
  }

  _isInfoRequestFailResponse (result) {
    return !result.success && !!result.error
  }

  _hasMandatoryPayment (result) {
    return result.response !== null &&
      result.response !== undefined &&
      result.response.hasMandatoryPayment
  }

  _setMandatoryPayment () {
    this.element.classList.add(classNames.hasMandatoryPayment)
  }

  _setRedirectUrl (result) {
    if (!result.response && !result.response.error) { return }

    this.redirectUrl = result.response.error.redirectUrlToAccoPage || result.response.error.redirectUrlToSearchPage
  }

  _mapWidgetData (result) {
    if (!result) {
      return undefined
    }
    const internalErrorsTitle = this.internalMessagesContainer ? this.internalMessagesContainer.getAttribute(attr.internalErrorsTitle) || '' : ''
    const widgetData = this.dataMapper.mapApiErrorToWidget(result.response, internalErrorsTitle)
    return widgetData
  }

  _renderModalError (result) {
    if (this.configurations && this.configurations.modalSettings) {
      const modalHtml = BookingErrorModalTemplate({
        id: this.configurations.modalSettings.id,
        size: this.configurations.modalSettings.modalSize,
        title: this.configurations.modalSettings.title,
        bodyContent: this.configurations.modalSettings.body,
        redirectAccoButtonText: this.configurations.modalSettings.redirectAccoButtonText,
        redirectSearchButtonText: this.configurations.modalSettings.redirectSearchButtonText,
        isAccoRedirect: result.response.error.redirectUrlToAccoPage !== undefined,
        track: 'bookingErrorModal'
      })
      const newContent = elementFromString(modalHtml)
      flush(this.modalErrorElementSection)

      if (newContent) {
        this.modalErrorElementSection.appendChild(newContent)
        Component.initDocumentComponentsFromAPI(newContent)
      }

      if (this.modalErrorElementSection) {
        this.modalErrorElement = this.modalErrorElementSection.querySelector(widgetQueries.modalErrorElement)
        this.modalErrorElementApi = this.modalErrorElement && this.modalErrorElement['c-modal-v2']
      }
    }
  }

  _attachModalButtonEvents () {
    if (!this.modalErrorElement) { return }
    const redirectButton = this.modalErrorElement.querySelector(widgetQueries.modalErrorButton)
    if (redirectButton && this.redirectUrl) {
      const redirectButtonApi = redirectButton['c-btn']
      redirectButtonApi.events.on('clickButton', () => {
        window.location.href = this.redirectUrl
      })
    }
  }

  _renderInternalMessages (widgetData) {
    if (this.internalMessagesContainer) {
      const renderedHtml = InternalMessagesTemplate(widgetData)
      const newContent = elementFromString(renderedHtml)
      flush(this.internalMessagesContainer)
      if (newContent) {
        this.internalMessagesContainer.appendChild(newContent)
      }
    }
  }

  _buildData (error, message, description) {
    const textReplaced = message && message.replace('{ERROR_MESSAGE}', (error.errorMessage || ''))
      .replace('{ERROR_CODE}', (error.errorCode || ''))
    const data = {
      message: textReplaced,
      description: description || ''
    }

    return data
  }

  _replaceExternalMessage (error) {
    if (this.errorMessageSection && this.locales) {
      const templateData = this._buildData(error, this.locales.errorMessage, this.locales.errorMessageDescription)
      const renderedHtml = ErrorMessageTemplate({ errorMessage: templateData.message, errorMessageDescription: templateData.description })
      const newContent = elementFromString(renderedHtml)
      flush(this.errorMessageSection)

      if (newContent) {
        this.errorMessageSection.appendChild(newContent)
      }
    }

    if (this.errorMessageSectionMandatoryPayment && this.locales) {
      const templateData = this._buildData(error, this.locales.errorMessageWhenMandatoryPayment, this.locales.errorMessageDescriptionWhenMandatoryPayment)
      const renderedHtml = ErrorMessageMandatoryPaymentTemplate({ errorMessageMandatoryPayment: templateData.message, errorMessageDescriptionMandatoryPayment: templateData.description })
      const newContent = elementFromString(renderedHtml)
      flush(this.errorMessageSectionMandatoryPayment)
      if (newContent) {
        this.errorMessageSectionMandatoryPayment.appendChild(newContent)
      }
    }
  }

  setHasError () {
    // intentionally not calling base method super.setHasError()
    super.enableUI()
    if (this.modalErrorElementApi && this.redirectUrl) {
      this.modalErrorElementApi.open()
      super.hideComponent()
    } else {
      this.showComponent()
    }
  }

  _initHtmlElements () {
    this.internalMessagesContainer = this.element.querySelector(widgetQueries.internalMessagesContainer)
    this.errorMessageSection = this.element.querySelector(widgetQueries.errorMessageSection)
    this.errorMessageSectionMandatoryPayment = this.element.querySelector(widgetQueries.errorMessageSectionMandatoryPayment)

    this.form = this.element.querySelector(widgetQueries.form)
    if (this.form) {
      this.nameInput = this.form.querySelector(widgetQueries.nameInput)
      this.emailInput = this.form.querySelector(widgetQueries.emailInput)
      this.phoneInput = this.form.querySelector(widgetQueries.phoneInput)
      this.formSubmitButton = this.form.querySelector(widgetQueries.formSubmitButton)
      this.formSuccessMessage = this.form.querySelector(widgetQueries.formSuccessMessage)
      this.formErrorMessage = this.form.querySelector(widgetQueries.formErrorMessage)
    }

    this.modalErrorElementSection = this.element.parentElement.querySelector(widgetQueries.modalErrorElementSection)
  }

  _initHtmlElementsApi () {
    if (this.form) { this.formApi = this.form['c-form'] }
    if (this.nameInput) { this.nameInputApi = this.nameInput['c-textbox'] }
    if (this.emailInput) { this.emailInputApi = this.emailInput['c-textbox'] }
    if (this.phoneInput) { this.phoneInputApi = this.phoneInput['c-phone-input'] }
  }

  _attachEvents () {
    if (this.formSubmitButton) {
      this.formSubmitButton.addEventListener('click', (ev) => this._clickSubmitButton(ev))
    }
  }

  _hasFormActive () {
    return !!this.form
  }

  async _clickSubmitButton (ev) {
    ev.preventDefault()

    if (this.formApi.validate().some(v => !v.isValid)) {
      return
    }

    const eventArgs = await this._getContactInfoData()
    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  async _getContactInfoData () {
    if (!this._hasFormActive()) {
      return undefined
    }

    const eventArgs = {
      method: this._method,
      url: this._url
    }
    if (this._method !== 'GET') {
      eventArgs.body = {
        customerName: this.nameInputApi.getProp('value'),
        customerEmail: this.emailInputApi.getProp('value'),
        customerPhone: this.phoneInputApi.getProp('value')
      }
    }
    return eventArgs
  }

  _showFormMessageSuccess () {
    this.formSuccessMessage && this.formSuccessMessage.classList.remove(classNames.hidden)
    this.formErrorMessage && this.formErrorMessage.classList.add(classNames.hidden)
    this.nameInput && this.nameInput.classList.add(classNames.hidden)
    this.phoneInput && this.phoneInput.classList.add(classNames.hidden)
    this.emailInput && this.emailInput.classList.add(classNames.hidden)
    this.formSubmitButton && this.formSubmitButton.classList.add(classNames.hidden)
  }

  _showFormMessageError () {
    this.formSuccessMessage && this.formSuccessMessage.classList.add(classNames.hidden)
    this.formErrorMessage && this.formErrorMessage.classList.remove(classNames.hidden)
  }

  getLocales () {
    const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.componentId}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) {}

    return customLocaleData || {}
  }

  _readOptionsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)

      return {
        modalSettings: configuration[configOptions.modalSettings]
      }
    }
  }
}

registerWidget(BookingError, widgetApi)

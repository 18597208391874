/**
 * ---------------
 *     README
 * ---------------
 * ⚠ About this ⚠
 *
 * The reason to have this file here, is because the differences on data models in between
 * Sitecore and Design System components. The responsibility of this file is, given an API
 * model, obtain a ready to render model.
 *
 */

const DEFAULT_PRICE_DATA_MODEL = {
  extraClasses: 'w-promoted-price__price',
  size: 'large'
}

const DEFAULT_PACKAGE_BLOCK_DATA_MODEL = {
  extraClasses: 'w-promoted-price__package-block'
}

/**
   * Builds a PriceData model from raw API data
   *
   * @param {Object} data - The orginal data retrieved from API
   *
   * @returns {PriceData}
   */
export function getPriceData (data, staticTexts, acmModalId, contextItemId) {
  let publicPriceWithCurrencySymbol

  if (data.price.publicValue !== undefined) {
    const symbol = data.price.currencySettings.symbol
    publicPriceWithCurrencySymbol = data.price.currencySettings.symbolPosition === 'BeforeAmount' ? `${symbol} ${data.price.publicValue}` : `${data.price.publicValue} ${symbol}`
  }

  let acmUrl

  if (data.acmInformation && data.acmInformation.acmUrl) {
    acmUrl = data.acmInformation.acmUrl.replace(/contextItemId=[\w-]*&/ig, '')
    acmUrl = acmUrl + '&contextItemId=' + contextItemId
  }

  return {
    ...DEFAULT_PRICE_DATA_MODEL,
    value: data.price.value,
    currency: data.price.currencySettings.symbol,
    currencyPosition: data.price.currencySettings.symbolPosition === 'BeforeAmount' ? 'before' : 'after',
    saleText: data.price.discountPercentage
      ? `-${data.price.discountPercentage}%`
      : data.price.publicValue && data.price.discountPercentagePublicPrice ? `-${data.price.discountPercentagePublicPrice}%` : undefined,
    oldPrice: data.price.originalValue ? data.price.originalValue : undefined,
    acmUrl,
    acmModalId: data.acmInformation && data.acmInformation.acmUrl ? acmModalId : undefined,
    legend: staticTexts.priceLegend,
    publicPrice: publicPriceWithCurrencySymbol ? staticTexts.publicPrice + ' ' + publicPriceWithCurrencySymbol : undefined
  }
}

/**
   * Builds a PackageBlockData model from raw API data
   *
   * @param {Object} data - The orginal data retrieved from API
   *
   * @returns {PackageBlockData}
   */
export function getPackageBlockData (data) {
  return {
    ...DEFAULT_PACKAGE_BLOCK_DATA_MODEL,
    includedServices: data.includedServices,
    filters: data.featuredFilters,
    departureDate: data.departureDate.humanized
  }
}

/**
 * Returns the ski pass public price text
 *
 * @param {Object}   data - The orginal data retrieved from API
 */
export function getSkiPassPublicPriceText (data) {
  return data.skiPassPublicPriceText
}

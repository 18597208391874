/**
 * The CarRentalItemTemplate contains all data needed to hydrate a CarRental Item
 *
 * @typedef {Object}            CarRentalItemTemplate
 *
 * @property {String}           carClass                                    - CarRentalItem type of car
 * @property {String}           carName                                     - CarRentalItem The name of the car
 * @property {String}           hasAirConditioningText                      - CarRentalItem if has Air conditioning
 * @property {String}           imagePath                                   - CarRentalItem image path
 * @property {Object[]}         prices                                      - CarRentalItem prices
 * @property {String}           reccommendedBaggageText                     - CarRentalItem recommended baggage capacity
 * @property {String}           reccommendedPassengersText                  - CarRentalItem recommended passengers capacity
 * @property {String}           transmission                                - CarRentalItem type of transmission
 * @property {String}           wheelDriveText                              - CarRentalItem wheel drive type
 *
 */

import {
  TableTemplate
} from '../../components/table/c-table.template'
import {
  TableItemTemplate
} from '../../components/table/c-table__item.template'
import {
  CollapseTemplate
} from '../../components/collapse/c-collapse.template'
import {
  BoxTemplate
} from '../../components/box/c-box.template'

const carCharacteristics = [
  {
    dataKey: 'reccommendedPassengersText',
    icon: 'adult'
  },
  {
    dataKey: 'reccommendedBaggageText',
    icon: 'suitcase'
  },
  {
    dataKey: 'wheelDriveText',
    icon: 'transmission-manual'
  },
  {
    dataKey: 'hasAirConditioningText',
    icon: 'snowflake'
  },
  {
    dataKey: 'transmission',
    icon: 'transmission-manual'
  }
]

export const defaultCarRentalItemData = {
  carClass: '',
  carName: '',
  hasAirConditioningText: '',
  imagePath: '',
  prices: [],
  reccommendedBaggageText: '',
  reccommendedPassengersText: '',
  transmission: '',
  wheelDriveText: ''
}

export const CarRentalItemTemplate = (data, carRentalItemId, locales) => {
  data = {
    ...defaultCarRentalItemData,
    ...data
  }

  return `
  <div class="w-car-rental__item">
    <div class="w-car-rental__item-content">

      <div class="w-car-rental__item-header">
        <img class="w-car-rental__item-image" src="${data.imagePath}" alt="${data.imagePath}">
        <div class="w-car-rental__item-title">
          <h3 class="w-car-rental__item-name">${data.carName}</h3>
          <div class="w-car-rental__item-category m-body m-body--tiny">${data.carClass}</div>
        </div>
      </div>

      <div class="w-car-rental__item-characteristics m-body m-body--small">
        ${carCharacteristics.map(characteristic => data[characteristic.dataKey]
          ? `<div class="w-car-rental__item-characteristic">
            <span class="w-car-rental__item-icon m-icon m-icon--${characteristic.icon}"></span>
            <span class="w-car-rental__item-characteristic-text">${data[characteristic.dataKey]}</span>
          </div>`
          : '').join('')}
      </div>

      <div class="w-car-rental__item-overlay is-hidden" data-c-collapse__id="w-car-rental__item-collapse--${carRentalItemId}" data-c-collapse__action="toggle"></div>

      <div class="w-car-rental__item-trigger m-body m-body--small">
        <span class="w-car-rental__item-trigger-text--more">${locales.showMore}</span> 
        <span class="w-car-rental__item-trigger-text--less">${locales.showLess}</span>
      </div>

    </div>

    ${CollapseTemplate({
      id: `w-car-rental__item-collapse--${carRentalItemId}`,
      extraClasses: 'w-car-rental__item-collapse',
      html: `
      ${TableTemplate({
        variant: 'stripped',
        extraClasses: 'w-car-rental__item-table m-body m-body--small',
        innerHTML: `
        ${TableItemTemplate({
          extraClasses: 'w-car-rental__item-row',
          innerHTML: `
            <div class="w-car-rental__item-cell">${locales.period}</div>
            <div class="w-car-rental__item-cell">${locales.oneWeek}</div>
            <div class="w-car-rental__item-cell">${locales.twoWeeks}</div>
          `

        })}
        
        ${data.prices.map((price) => {
          return `
          ${TableItemTemplate({
            extraClasses: 'w-car-rental__item-row',
            innerHTML: `
              <div class="w-car-rental__item-cell w-car-rental__item-cell--body">${price.period}</div>
              <div class="w-car-rental__item-cell w-car-rental__item-cell--body">+ ${price.oneWeekPrice}</div>
              <div class="w-car-rental__item-cell w-car-rental__item-cell--body">+ ${price.twoWeeksPrice}</div>
            `
          })}
          `
        }).join('')}
        `
      })}
      `
    })}
  </div>
  `
}

export const CarRentalNotificationTemplate = locales => {
  return `
  ${BoxTemplate({
    extraClasses: 'cs-state-info cs-state-info--light',
    html: `
      <div class="w-car-rental__notification-box">
        <span class="m-icon m-icon--information-circle m-icon--size-medium w-car-rental__notification-icon"></span>
        <p class="m-body m-body--medium">${locales.noCarNotification}</p>
      </div>
    `
  })}
  `
}

import { LabelTemplate } from '../../components/label/c-label.template'
import { ButtonGroupTemplate } from '../../components/button-group/c-button-group.template'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'
import { BookingFilterableServicesFilterTemplateRenderer } from './w-booking-filterable-services.template'

export const BookingFilterableServicesButtonGroupFilterTemplate = (filter, filters, service, configurations) => {
  return `
  <div class="w-booking-filterable-services__filter w-booking-filterable-services__filter-${filter.columnName}">
  ${LabelTemplate({
    text: filter.filterLabel,
    size: 'micro',
    extraClasses: 'w-booking-filterable-services-label e-form-label'
  })}
  ${ButtonGroupTemplate({
    id: `${filter.uid}`,
    extraClasses: '',
    variant: CheckButtonGroupChipsType(filter.filterType) ? 'chips' : '',
    html: `${filter.values.map(value => `
    ${BtnTemplate({
      ...defaultButtonData,
      ...{
        text: CheckButtonGroupChipsType(filter.filterType) ? `${value} ${service.durationFilterSuffixText}` : MapFilterItemText(value, filter.texts),
        type: 'submit',
        variant: '',
        block: false,
        extraClasses: `w-booking-filterable-services-btn${CheckButtonGroupChipsType(filter.filterType) ? '-chip' : ''}`,
        attributes: {
          'data-w-booking-filterable-services__button-group-value': value
        }
      }
    })}
      `).join('')
    }`,
    attributes: {
      'data-w-booking-filterable-services__filters-button-group': '',
      'data-w-booking-filterable-services__service-id': service.uid,
      'data-w-booking-filterable-services__filter-type': filter.columnName
    }
  })}
  </div>
  ${BookingFilterableServicesFilterTemplateRenderer(filters, service, configurations)}`
}

const CheckButtonGroupChipsType = (filterType) => {
  return filterType === 'buttonGroupChips'
}

const MapFilterItemText = (filterValue, texts = null) => {
  if (!texts) { return filterValue }
  const textToBeReplaced = texts.find(text => text.key === filterValue)
  return (textToBeReplaced && textToBeReplaced.value) || filterValue
}

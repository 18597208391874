import { widgetQueries } from '../payment-constants'
import { PaymentPartialTemplate } from './w-payment-partial.template'
import { elementFromString, flush } from '../../../../js/document/html-helper'
import Component from '../../../../js/core/component/component'
import PriceFormatter from '../../../../js/helpers/price-formatter'
import { payment } from '../event-types'

const DEFAULT_OPTIONS = {
  locales: {}
}

export default class Partial {
  constructor (element, options = {}) {
    this.element = element
    this.options = {
      ...DEFAULT_OPTIONS,
      ...options
    }
    this.locales = this.options.locales
    this.events = options.events
    this.timer = 0

    this._init()
    this._render()
  }

  _init () {
    const minAmountInfo = this.options.priceInfo.amounts.find(a => a.type.toLowerCase() === 'custompartial')
    this.minAmount = minAmountInfo.price
    this.maxAmount = this.options.maxAmount
  }

  _render () {
    const selectedOptionId = this.options.selectedOption.id
    const renderPartialSectionHtml = PaymentPartialTemplate(
      {
        ...this.locales,
        maxAmount: this.maxAmount,
        minAmount: this.minAmount,
        priceConfiguration: this.options.priceConfiguration
      })
    const newContent = elementFromString(renderPartialSectionHtml)
    Component.initDocumentComponentsFromAPI(newContent)

    const choiceListItemSelected = this.element.querySelector(`[id*='${selectedOptionId}']`).parentElement
    const radioButtonExtraContentHtml = choiceListItemSelected.querySelector(widgetQueries.radioButtonExtraContent)
    flush(radioButtonExtraContentHtml)
    radioButtonExtraContentHtml.appendChild(newContent)

    this._attachEvents()
  }

  _attachEvents () {
    this.amountInputElement = this.element.querySelector(widgetQueries.partialAmountInputElement)
    const amount = this.element.querySelector(widgetQueries.partialAmountInput)

    this.apis = {
      ...amount && { amount: amount['c-textbox'] }
    }

    this.apis.amount && this.apis.amount.events.on('input', this._validateTypedAmount, this)

    this.remainingBalance = this.element.querySelector(widgetQueries.partialRemainingBalanceText)
    this.totalToPay = this.element.querySelector(widgetQueries.partialTotalToPayText)
  }

  _validateTypedAmount (ev) {
    const element = this.amountInputElement || ev.target
    let prev = element.getAttribute('data-prev')
    prev = (prev !== '') ? prev : ''

    const regex = this._getRegex()
    const isValid = (regex).test(element.value)
    if (!isValid && element.value !== '') {
      element.value = prev
    } else {
      this.events.emit(payment.PARTIAL_REMOVE)
      if (element.value !== '') {
        this._change(element)
      }
    }
    element.setAttribute('data-prev', element.value)
  }

  _getRegex () {
    if (this.options.priceConfiguration.decimalSeparator === '.') {
      return /^([0-9]{1,10})((\.)[0-9]{0,2})?$/
    }
    const separator = this.options.priceConfiguration.decimalSeparator || ','
    return new RegExp('^([0-9]{1,10})((' + separator + ')[0-9]{0,2})?$')
  }

  _change (element) {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      if (element.value === '') {
        this.totalToPay.innerHTML = PriceFormatter.toFormattedText(this.minAmount, this.options.priceConfiguration)
        this.remainingBalance.innerHTML = PriceFormatter.toFormattedText(this.maxAmount - this.minAmount, this.options.priceConfiguration)
      } else {
        let toPay = this.minAmount + parseFloat(element.value.replace(this.options.priceConfiguration.decimalSeparator, '.'))
        if (toPay > this.maxAmount) {
          toPay = this.maxAmount
          this.amountInputElement.value = parseFloat(toPay - this.minAmount).toFixed(2).replace('.', this.options.priceConfiguration.decimalSeparator)
        }
        const remaining = this.maxAmount - toPay
        this.events.emit(payment.PARTIAL_PAY, toPay)
        this.totalToPay.innerHTML = PriceFormatter.toFormattedText(toPay, this.options.priceConfiguration)
        this.remainingBalance.innerHTML = PriceFormatter.toFormattedText(remaining, this.options.priceConfiguration)
      }
    }, 1000)
  }
}

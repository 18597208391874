import { BookingFilterableServicesNumberStepperFilterTemplate } from './w-booking-filterable-services__number-stepper-filter.template'
import { BookingFilterableServicesButtonGroupFilterTemplate } from './w-booking-filterable-services__button-group-filter.template'
import { BookingFilterableServicesChoiceListFilterTemplate } from './w-booking-filterable-services__choice-list-filter.template'
import { BookingFilterableServicesTabFilterTemplate } from './w-booking-filterable-services__tab-filter.template'
import { BookingFilterableServicesDropdownFilterTemplate } from './w-booking-filterable-services__dropdown-filter.template'
import { BookingFilterableServicesDateTimeFilterTemplate } from './w-booking-filterable-services__date-time-filter.template'
import { BookingFilterableServicesOptionsTemplate } from './w-booking-filterable-services__options.template'
import { BookingFilterableServicesTimeDropdownTemplate } from './w-booking-filterable-services__time-dropdown.template'
import { BookingFilterableServicesQuantityNumberStepperTemplate } from './w-booking-filterable-services__quantity-number-stepper.template'
import { BookingFilterableServicesParticipantsChoiceListTemplate } from './w-booking-filterable-services__participants-choice-list.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { SwitchTemplate } from '../../components/switch/c-switch.template'
import { CollapseTemplate, defaultCollapseData } from '../../components/collapse/c-collapse.template'
import { CollapseTriggerTemplate, defaultCollapseTriggerData } from '../../components/collapse/c-collapse__trigger.template'
import { TitleTemplate } from '../../components/title/c-title.template'
import { SideDrawerTemplate, defaultSideDrawerData } from '../../components/side-drawer/c-side-drawer.template'
import { SideDrawerHeaderTemplate } from '../../components/side-drawer/c-side-drawer__header.template'
import { SideDrawerBodyTemplate } from '../../components/side-drawer/c-side-drawer__body.template'
import { BookingMessageTemplate } from '../booking-message/w-booking-message.template'
import { defaultPriceData, PriceTemplate } from '../../components/price/c-price.template'
import PriceFormatter from '../../../js/helpers/price-formatter'
import { ageCategories } from './constants'

const suffixIdentifiers = {
  collapse: '__collapse',
  sideDrawer: '__side-drawer'
}

export const BookingFilterableServicesTemplate = (d) => {
  return `<div class="w-booking-filterable-services">
  ${d.rooms.map(room => `
  <div class="w-booking-filterable-services__room" id="room-${room.id}">
    ${room.roomName
      ? `<div class="w-booking-filterable-services__room-name">
            ${TitleTemplate({ text: room.roomName })}
        </div>`
      : ''}
    ${room.participants.map(participant => `
      <div id="participant-${participant.id}" class="w-booking-filterable-services__participant">
      ${participant.label
        ? `<div class="w-booking-filterable-services__participant-name">
            ${TitleTemplate({ text: participant.label })}
          </div>`
        : ''
      }
      ${participant.services.map(service => `
        <div class="w-booking-filterable-services__service" data-w-booking-filterable-services__service-collapsible data-w-booking-filterable-services__service-id=${service.uid} >
          ${service.canBeUnselected
            ? `${ServiceTemplate({ service, texts: { ...d.texts, ...{ participantName: participant.label } }, configurations: d.configurations, participant })}`
            : `
              ${ServiceHeaderTemplate({ service, extraClasses: 'w-booking-filterable-services--collapse__header u-hidden--until@md', containerAttributes: null, configurations: d.configurations, texts: d.texts, participant })}
              ${ServiceHeaderTemplate({ service, extraClasses: 'w-booking-filterable-services--side__drawer__header u-hidden--from@md', containerAttributes: null, configurations: d.configurations, texts: d.texts, participant })}
            `
          }
        </div>`).join('')}
      </div>`).join('')}
    </div>`).join('')}
  </div>`
}

const ServiceTemplate = (d) => {
  const { service, texts, configurations, participant } = d
  // Collapse
  const dataAttributesCollapse = `data-c-collapse__id="${service.uid}${suffixIdentifiers.collapse}" data-w-booking-filterable-services__container`
  // SideDrawer
  const dataAttributesSideDrawer = `data-c-side-drawer__id="${service.uid}${suffixIdentifiers.sideDrawer}" data-w-booking-filterable-services__container`
  const bodyTemplate = `<div class="w-booking-filterable-services__participant__service-title--side-drawer">${texts.participantName ? TitleTemplate({ text: texts.participantName }) : ''}</div>
                        ${ServiceBodyTemplate(service, texts, configurations)}
                        ${ServiceBodyButtons(service, texts)}`
  const data = {
    ...defaultSideDrawerData,
    ...{
      id: service.uid + suffixIdentifiers.sideDrawer,
      extraClasses: 'w-booking-filterable-services-side-drawer__service__content',
      until: 'md',
      attributes: {
        'data-w-booking-filterable-services__service-side-drawer': ''
      },
      html: `${SideDrawerHeaderTemplate({ title: service.title, id: service.uid + suffixIdentifiers.sideDrawer, closable: true, extraClasses: 'w-booking-filterable-services__service-title--side-drawer' })}
      ${SideDrawerBodyTemplate({ html: bodyTemplate })}`
    }
  }

  return `
    ${ServiceHeaderTemplate({ service, extraClasses: 'w-booking-filterable-services--collapse__header u-hidden--until@md', containerAttributes: dataAttributesCollapse, configurations, texts, participant })}
    ${ServiceHeaderTemplate({ service, extraClasses: 'w-booking-filterable-services--side__drawer__header u-hidden--from@md', containerAttributes: dataAttributesSideDrawer, configurations, texts, participant })}
    ${CollapseTemplate({
          ...defaultCollapseData,
          ...{
            id: service.uid + suffixIdentifiers.collapse,
            extraClasses: 'w-booking-filterable-services-collapse__service__content',
            collapseFrom: 'md',
            html: `<div id="${service.uid}__body" class="w-booking-filterable-services__body">
                    ${SideDrawerTemplate(data)}
                  </div>`,
            attributes:
            {
              'data-c-collapse__scrollable': '',
              'data-c-collapse__scrollable--safe-margin': '60',
              'data-w-booking-filterable-services__service-collapsible': '',
              'data-w-booking-filterable-services__service-id': service.uid
            }
          }
        })
    }
  `
}

const GetTextForServiceHeader = (service, texts, participant) => {
  let text = ''
  if (service.selectedOption) {
    if (service.useQuantity && service.quantity > 0 && texts.serviceSelectedInfoText) {
      text = `(${service.quantity} ${texts.serviceSelectedInfoText})`
    } else if (service.showParticipantsAsOptions && texts.serviceSelectedInfoText) {
      const optionsSelected = service.participants.filter(option => option.checked).length
      if (optionsSelected > 0) {
        text = `(${optionsSelected} ${texts.serviceSelectedInfoText})`
      }
    } else {
      text = service.selectedOption.displayText
    }
  } else if (service.noneOption) {
    text = service.noneOption.displayText || texts.noServiceSelected || ''
  } else if (participant.ageCategory && participant.ageCategory === ageCategories.child && areSomeServiceOptionsFreeForParticipant(service, participant.id)) {
    text = texts.selectServiceForFree || ''
  } else {
    text = texts.noServiceSelected || ''
  }
  return text
}

const areSomeServiceOptionsFreeForParticipant = (service, participantId) => {
  return service.options.filter(option => option.appliesToParticipants.includes(participantId)).some(option => option.price === 0)
}

const ServiceHeaderTemplate = (d) => {
  const { service, extraClasses, containerAttributes, configurations, texts, participant } = d
  const text = GetTextForServiceHeader(service, texts, participant)
  const price = (service.selectedOption)
    ? service.totalPrice
    : service.noneOption ? (service.noneOption.price !== 0 ? service.noneOption.price : '') : ''

  const isSwitchChecked = service.selectedOption && !service.selectedOption.isNoneOption

  const htmlHeader = `
    <div class="w-booking-filterable-services__header ${extraClasses}
    ${isSwitchChecked ? 'active' : ''}"
    ${containerAttributes}>
    ${SwitchTemplate({
      id: `${service.uid}-switch`,
      text: SwitchText(service.title, text),
      checked: isSwitchChecked,
      disabled: !service.canBeUnselected,
      dataset: {
        'w-booking-filterable-services__service-switch': '',
        'w-booking-filterable-services__service-id': service.uid
      }
    })}
    <div class="w-booking-filterable-services__header-right">
      <span class="w-booking-filterable-services__header-info">${ServicePriceTemplate(price, configurations.price)}</span>
      ${service.canBeUnselected
        ? `${CollapseTriggerTemplate({ ...defaultCollapseTriggerData, ...{ id: service.uid + suffixIdentifiers.collapse, smooth: true, arrow: true, extraClasses: 'w-booking-filterable-services__header-trigger' } })}`
        : ''
      }
    </div>
  </div>`

  return htmlHeader
}

const SwitchText = (serviceTitle, serviceText) => {
  return `<span class="w-booking-filterable-services__service-title">
            ${serviceTitle}
          </span>
          <span class="w-booking-filterable-services__service-selected">
            ${serviceText}
          </span>`
}

const ServiceBodyTemplate = (service, texts, configurations) => {
  return `
    <div class="w-booking-filterable-services__body__filter">
      ${BookingFilterableServicesFilterTemplateRenderer([...service.filters], service, configurations)}
      <div class="w-booking-filterable-services__filter w-booking-filterable-services__filter-info" data-w-booking-filterable-services__filter-info>
      <div class="w-booking-filterable-services__filter-info__price" data-w-booking-filterable-services__filter-info__price>
        ${service.totalPrice
          ? ServicePriceTemplate(service.totalPrice, configurations.price)
          : service.selectedOption && service.selectedOption.price ? ServicePriceTemplate(service.selectedOption.price, configurations.price) : ''}
      </div>
        <div class="w-booking-filterable-services__filter-info__price-description" data-w-booking-filterable-services__filter-info__price-description></div>
      </div>
    </div>
      ${FilterableServicesOptionsEmpty(service, texts)}
    `
}

export const ServicePriceTemplate = (value, priceConfig) => {
  return typeof (value) === 'number'
    ? PriceTemplate({
      ...defaultPriceData,
      size: 'small',
      value: PriceFormatter.toFormattedText(value, {
        numberOfDecimals: priceConfig.numberOfDecimals,
        decimalSeparator: priceConfig.decimalSeparator
      }),
      currency: priceConfig.currencySymbol,
      currencyPosition: priceConfig.isCurrencyAtStart ? 'before' : 'after'
    })
    : ''
}

const FilterableServicesOptionsEmpty = (service, texts) => {
  let html = ''
  if (texts.emptyOptions) {
    const messageData = {
      extraClasses: '',
      messages: [
        {
          type: 'error',
          texts: [texts.emptyOptions]
        }
      ]
    }
    html = `<div class="w-booking-filterable-services__empty-message" data-w-booking-filterable-services__empty-message>${BookingMessageTemplate(messageData)}</div>`
  }
  return html
}

const ServiceBodyButtons = (service, texts) => {
  return `
    <div class="w-booking-filterable-services__body__buttons" data-w-booking-filterable-services__body-buttons>
      ${BtnTemplate({
        jsApi: true,
        text: texts.cancelButton,
        variant: 'flat-neutral',
        extraClasses: 'w-booking-filterable-services__cancel-btn',
        attributes:
        {
          'data-w-booking-filterable-services__service-cancel-button': '',
          'data-w-booking-filterable-services__service-id': service.uid
        }
      })}
      ${BtnTemplate({
        jsApi: true,
        text: texts.saveButton,
        variant: 'trust',
        extraClasses: 'w-booking-filterable-services__accept-btn',
        attributes:
        {
          'data-w-booking-filterable-services__service-accept-button': '',
          'data-w-booking-filterable-services__service-id': service.uid
        }
      })}
    </div>
  `
}

/**
 * This is the recurrency logic, where everything happens ;)
 *  1 - Gets a list of filters (and options)
 *  2.1 - If list if empty renders the options and stops processing
 *  2.2 - If list contains filters
 *      - Get the first filter of the list and remove it from the list
 *      - Passes the new list and the filter to the specific template renderer
 *        that will call BookingFilterableServicesFilterTemplateRenderer (back to step 1)
 */
export const BookingFilterableServicesFilterTemplateRenderer = (filters, service, configurations) => {
  const filter = filters ? filters.shift() : null
  return !filter
    ? GetBottomSectionHtml(service, configurations)
    : filter.filterType === 'numberStepper'
      ? BookingFilterableServicesNumberStepperFilterTemplate(filter, filters, service, configurations)
      : filter.filterType === 'dropdown'
        ? BookingFilterableServicesDropdownFilterTemplate(filter, filters, service, configurations)
        : filter.filterType === 'dateTime'
          ? BookingFilterableServicesDateTimeFilterTemplate(filter, filters, service, configurations)
          : filter.filterType === 'buttonGroup' || filter.filterType === 'buttonGroupChips'
            ? BookingFilterableServicesButtonGroupFilterTemplate(filter, filters, service, configurations)
            : filter.filterType === 'choiceList'
              ? BookingFilterableServicesChoiceListFilterTemplate(filter, filters, service, configurations)
              : filter.filterType === 'tabSelector'
                ? BookingFilterableServicesTabFilterTemplate(filter, filters, service, configurations)
                : ''
}

const GetBottomSectionHtml = (service, configurations) => {
  const quantityHtml = service.useQuantity ? BookingFilterableServicesQuantityNumberStepperTemplate(service, configurations) : ''
  const participantsHtml = service.showParticipantsAsOptions ? BookingFilterableServicesParticipantsChoiceListTemplate(service) : ''
  const timeHtml = service.addTime ? BookingFilterableServicesTimeDropdownTemplate(service, configurations) : ''
  const optionsHtml = BookingFilterableServicesOptionsTemplate(service, configurations)
  const validationMessagesHTML = '<div class="w-booking-filterable-services__validation-messages" data-w-booking-filterable-services__validation-messages></div>'
  const timeMessagesHTML = '<div class="w-booking-filterable-services__time-messages" data-w-booking-filterable-services__time-messages></div>'
  return timeHtml + quantityHtml + participantsHtml + optionsHtml + validationMessagesHTML + timeMessagesHTML
}

import { defaultTitleData, TitleTemplate } from '../../components/title/c-title.template'
import { BreadcrumbsTemplate, defaultBreadcrumbsData } from '../../components/breadcrumbs/c-breadcrumbs.template'
import { RatingTemplate } from '../../components/rating/c-rating.template'
import { ImgTemplate, defaultImgData } from '../../components/img/c-img.template'
// image
// rating
// breadcrumbs
/**
 * The BookingAccommodationSummaryTemplate contains all the data needed to hydrate a BookingAccommodationSummary component
 *
 * @typedef {Object}      BookingAccommodationSummaryTemplate
 *
 * @property {String}     variant            - The variant that will be used
 */

export const defaultBookingAccommodationSummaryData = {
  id: ''
}

export const BookingAccommodationSummaryTemplate = (d) => {
  d = { ...defaultBookingAccommodationSummaryData, ...d }
  return `
  <div class="w-booking-accommodation-summary">
  <div class="w-booking-accommodation-summary__header">
    ${ImgTemplate({
      ...defaultImgData,
      ...{
        sync: true,
        resolve: 'intersect',
        placeholderSrc: d.mainImage.medium,
        resolvedSrc: d.mainImage.medium,
        ratio: '16:9',
        extraClasses: 'w-booking-accommodation-summary__image'
      }
    })}
    <div class="w-booking-accommodation-summary__booking-info">
      <div class="w-booking-accommodation-summary__acco-info">
        ${RatingTemplate({ ...d.ratingData, ...{ value: d.numberOfStars, extraClasses: 'w-booking-accommodation-summary__rating', size: 's' } })}
        ${TitleTemplate({ ...defaultTitleData, ...{ text: d.accommodationName, size: 'small', extraClasses: 'w-booking-accommodation-summary__acco-name' } })}
        ${BreadcrumbsTemplate({ ...defaultBreadcrumbsData, ...{ items: [{ href: '#', text: d.country }, { href: '#', text: d.region }, { href: '#', text: d.city }], extraClasses: 'w-booking-accommodation-summary__breadcrumbs' } })}
      </div>
      <div class="w-booking-accommodation-summary__booking-filters">
        <div class="w-booking-accommodation-summary__booking-filters-group">
          <span class="m-icon m-icon--size-medium m-icon--family w-booking-accommodation-summary__booking-filters-item"></span>
          <div class="w-booking-accommodation-summary-text">${d.participantsText}</div>
        </div>
        <div class="w-booking-accommodation-summary__booking-filters-group">
          <span class="m-icon m-icon--size-medium m-icon--calendar w-booking-accommodation-summary__booking-filters-item"></span>
          <div class="w-booking-accommodation-summary-text">${d.departureArrivalText}</div>
        </div>
        <div class="w-booking-accommodation-summary__booking-filters-group">
          <span class="m-icon m-icon--size-medium m-icon--suitcase w-booking-accommodation-summary__booking-filters-item"></span>
          <div class="w-booking-accommodation-summary-text">${d.durationText}</div>
        </div>
        <div class="w-booking-accommodation-summary__booking-filters-group">
          <span class="m-icon m-icon--size-medium m-icon--cutlery w-booking-accommodation-summary__booking-filters-item"></span>
          <div class="w-booking-accommodation-summary-text">${d.mealplan}</div>
        </div>
      </div>
    </div>

  </div>
  <div class="w-booking-accommodation-summary__rooms">
  ${d.roomsTitle ? TitleTemplate({ ...defaultTitleData, ...{ text: d.roomsTitle, size: 'tiny', extraClasses: 'w-booking-accommodation-summary__rooms-title' } }) : ''}
    <div class="w-booking-accommodation-summary__rooms-list">
      ${d.rooms.map(room => `
      <div class="w-booking-accommodation-summary__room-info">
        ${TitleTemplate({ ...defaultTitleData, ...{ text: room.name, size: 'tiny', extraClasses: 'w-booking-accommodation-summary__room-name' } })}
        ${room.lines.map(line => `<div class="w-booking-accommodation-summary-text">${line}</div>`).join('')}
      </div>
      `).join('')}
    </div>
  </div>
</div>
  `
}

import { FormTemplate } from '../../components/form/c-form.template'
import { FormSectionTemplate } from '../../components/form/c-form__section.template'
import { FormFieldsetTemplate } from '../../components/form/c-form__fieldset.template'
import { FormChangePatternConfigurationTemplate } from '../../components/form/c-form__change-pattern-configuration.template'

export const defaultBookingParticipantsFormData = {
  formSections: []
}

export const BookingParticipantsFormTemplate = (d, formAttributes = {}) => {
  d = { ...defaultBookingParticipantsFormData, ...d }
  return `${FormTemplate({
    formAction: formAttributes.formAction || null,
    formMethod: formAttributes.formMethod || null,
    track: formAttributes.track || null,
    validate: true,
    attributes: {
      'data-w-booking-participants-form__form': ''
    },
    html: d.formSections.map(section => FormSectionTemplate({
      canBeCollapsed: section.canBeCollapsed,
      collapseId: section.collapseId,
      icon: section.icon,
      title: section.title,
      html: section.fieldsets && section.fieldsets.map(fieldset => FormFieldsetTemplate({
        title: fieldset.title,
        description: fieldset.description,
        components: fieldset.components,
        extraClasses: fieldset.extraClasses
      })).join('')
    })).join('') + FormChangePatternConfigurationTemplate(d.changePatternSettings)
  })
  }`
}

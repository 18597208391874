import { encodeAttributes } from '../../../js/helpers/string'

/**
 * The PriceTableTemplateData contains all data organized to be easily displayed in a table
 *
 * @typedef {Object}          PriceTableTemplateDiscountBulletData
 * @property {String}         extraClasses                                  - Extra classes to add to the element
 * @property {Object}         attributes                                    - Element attributes (i.e. data-attributes)
 * @property {String}         hexColor                                      - The color of the bullet
 * @property {String}         description                                   - The description of the discount
 */

export const defaultPriceTableTemplateDiscountBulletData = {
  extraClasses: '',
  attributtes: {}
}

/**
 * Returns an html price table template/string
 *
 * @param {PriceTableTemplateDiscountBulletData} d
 *
 * @returns {String} Price Table HTML string
 */

export const PriceTableTemplateDiscountBullet = (d) => {
  d = { ...defaultPriceTableTemplateDiscountBulletData, ...d }

  return `<div ${d.attributes ? encodeAttributes(d.attributes) : ''}
               title="${d.description}"
               class="c-price-table__discount_bullet ${d.extraClasses}"
               style="background-color:${d.hexColor};">
        </div>`
}

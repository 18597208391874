/**
 * The TripSummaryData identifies the trip summary info
 *
 * @typedef {Object}              TripSummaryData
 *
 * @property {String}             icon                      - Trip summary item's icon
 * @property {Object[]}           lines                     - Trip summary item's lines
 * @property {String}             lines.text
 * @property {Boolean}            lines.isBold              - If true, text line is bold
 */

/**
 * The SelectedStateApiDataModel contains all data as received from API
 *
 * @typedef {Object}              SelectedStateApiDataModel
 *
 * @property {Object}             routePricePerPerson                   - Price per person
 * @property {String}             routePricePerPerson.formatted         - Price per person humanized text
 * @property {Object}             routeTotalPrice                       - Overall price
 * @property {String}             routeTotalPrice.formatted             - Overall price humanized text
 * @property {Object}             priceBreakdown
 * @property {Object[]}           priceBreakdown.rooms                  - Room breakdown info
 * @property {String}             priceBreakdown.rooms.text             - Room item's text
 * @property {String}             priceBreakdown.rooms.value            - Room item's value
 * @property {Object[]}           priceBreakdown.services               - Services breakdown info
 * @property {String}             priceBreakdown.services.text          - Services item's text
 * @property {String}             priceBreakdown.services.value         - Services item's value
 * @property {Object}             priceBreakdown.bookMessage            - Conditional/alert message
 * @property {String}             priceBreakdown.bookMessage.level      - Type of alert
 * @property {String}             priceBreakdown.bookMessage.text       - Conditional message's text
 * @property {TripSummaryData[]}  tripSummary                           - Global trip summary
 * @property {TripSummaryData[]}  selectorTripSummary                   - Transport specific trip summary
 * @property {String}             campaignCode                          - Campaign code
 * @property {String[]}           contentUsps                           - Content USP's
*/

const LOCAL_COSTS_CURRENCY_TAG = '{Currency}'

const selectedStateDataDefaults = {
  routePricePerPerson: {},
  routeTotalPrice: {},
  priceBreakdown: {
    rooms: [],
    services: [],
    bookMessage: {}
  },
  tripSummary: [],
  contentUsps: []
}

/**
 * A SelectedStateData model
 */
export default class SelectedStateDataModel {
  /**
   * Creates a new SelectedStateDataModel
   *
   * @constructor
   * @param {SelectedStateApiDataModel} data     - The model data
   * @param {Object}                    options  - Locales data
   * @param {String}                    [options.id]  - Element id
   * @param {Object}                    [options.locales]  - Locales data
   */
  constructor (data = {}, options = {}) {
    this.data = {
      ...selectedStateDataDefaults,
      ...data
    }
    this.locales = options.locales || {}
    this.elementId = options.id || ''
  }

  /**
   * Transfrom tripSummary data from API to template's desired format
   *
   * @param {TripSummaryData[]}  tripSummaryData an array of TripSummaryData
   *
   * @returns {BulletListItemData[]} an array of BulletListItemData
   */
  static transformTripSummary (tripSummaryData = []) {
    return tripSummaryData.map(({ icon, lines }) => [
      { icon, ...lines[0] },
      ...lines.slice(1).filter(({ text }) => !!text).map((line) => ({ isIconHidden: true, ...line }))
    ]).reduce((obj, items) => ([...obj, ...items]), [])
  }

  /**
   * Gets the selected state object the template will use
   *
   * @returns {SelectedStateTemplateData} the SelectedStateTemplateData
   */
  _getSelectedStateTemplateData () {
    // Map optionalServices to BulletListItemData scheme
    const optionalServices = this.data.optionalServices && this.data.optionalServices.optionalServices
      ? this.data.optionalServices.optionalServices.map(service => ({
        icon: service.icon,
        text: service.name
      }))
      : []

    return {
      id: this.elementId,
      mainTitleText: this.locales.tripSummary && this.locales.tripSummary.headerText,
      headerMessageText: this.data.priceBreakdown && this.data.priceBreakdown.bookMessage && this.data.priceBreakdown.bookMessage.text,
      headerMessageLevel: this.data.priceBreakdown && this.data.priceBreakdown.bookMessage && this.data.priceBreakdown.bookMessage.level
        ? this.data.priceBreakdown.bookMessage.level
        : 'info',
      accommodation: this.locales.accommodation,
      tripDetails: [
        ...SelectedStateDataModel.transformTripSummary(this.data.detailedTripSummary),
        ...SelectedStateDataModel.transformTripSummary(this.data.tripSummary)
      ],
      optionalServices: {
        label: this.locales.optionalServices.label,
        tagLine: this.locales.optionalServices.tagLine,
        items: optionalServices
      },
      priceBreakdown: {
        items: this._getBreakdownTableTemplateData(),
        itemsLocalCosts: this._getBreakdownTableTemplateDataLocalCosts(),
        itemsLocalCostsWithouPrices: this.data.priceBreakdown?.localCostsWithoutPrice,
        itemsTotal: this._getBreakdownTableTemplateDataTotal(),
        extraInfo: this.locales.priceBreakdown.extraInfo,
        extraInfoIcon: this.locales.priceBreakdown.extraInfoIcon,
        openJawMessage: this.data.isOpenJaw ? this.locales.priceBreakdown.openJawMessage : '',
        headerText: this.locales.priceBreakdown.headerText,
        headerTextLocalCosts: this.locales.priceBreakdown.headerTextLocalCosts,
        headerDescriptionLocalCosts: this._getLocalCostsCurrencyDescription()
      },
      mandatoryCosts: {
        title: this.locales.priceBreakdown.mandatoryCostsTitle,
        content: this.locales.priceBreakdown.mandatoryCostsContent
      },
      submitButtonText: this._getSubmitButtonText(),
      packageUsps: this.data.priceBreakdown.usps,
      contentUsps: this.data.contentUsps
    }
  }

  _getSubmitButtonText () {
    const genericText = this.locales.priceBreakdown.bookButton
    const tranportType = (this.data.transportType || '').toLowerCase()

    let buttonTextPerTransport
    if (tranportType === 'flight') {
      buttonTextPerTransport = this.locales.priceBreakdown.bookButtonFlightSelector
    } else if (tranportType === 'bus') {
      buttonTextPerTransport = this.locales.priceBreakdown.bookButtonBusSelector
    } else {
      buttonTextPerTransport = this.locales.priceBreakdown.bookButtonSelfDriveSelector
    }
    return buttonTextPerTransport || genericText || ''
  }

  /**
   * Builds BreakdownTableItemData from current data
   *
   * @returns {BreakdownTableItemData[]}
   */
  _getBreakdownTableTemplateData () {
    const rooms = this.data.priceBreakdown.rooms.map((room, index) => ({ title: index + 1, items: room }))
    const services = { items: this.data.priceBreakdown.services }

    return [
      ...rooms.map((room) => ({
        concept: this.locales.priceBreakdown.roomText + ' ' + room.title,
        subitems: room.items.map((item) => ({ concept: item.text, value: item.value })),
        total: room.total ? room.total : false,
        value: room.value,
        topItemsExtraClasses: 'w-selected-state__price-overview__row-title',
        preItemsExtraClasses: 'w-selected-state__price-overview__row-title'
      })),
      ...services.items.map((service) => ({
        concept: service.text,
        value: service.value,
        isDiscount: service.isDiscount ? service.isDiscount : false,
        topItemsExtraClasses: `${service.rawAmount === 0 ? 'w-selected-state__price-overview__row-services--inclusive' : ''} w-selected-state__price-overview__row-services`
      })),
      ...this._getSubTotalRowTemplateData(this.data?.priceBreakdown?.subTotalPayAtReservation?.formatted)
    ]
  }

  _getSubTotalRowTemplateData (value) {
    return value
      ? [{
          concept: this.locales.priceBreakdown.subTotalText,
          value,
          topItemsExtraClasses: 'w-selected-state__price-subtotal'
        }]
      : []
  }

  _getBreakdownTableTemplateDataLocalCosts () {
    const localCosts = this.data.priceBreakdown?.localCostsWithPrice || []
    return [
      ...localCosts.map((localCost) => ({
        concept: localCost.name,
        subitems: [{ concept: localCost.description }],
        value: localCost.price.formatted
      })),
      ...this._getSubTotalRowTemplateData(this.data?.priceBreakdown?.subTotalPayAtDestination?.formatted)
    ]
  }

  _getBreakdownTableTemplateDataTotal () {
    const showGrossPrice = this.data.priceBreakdown.totalPrice &&
                            this.data.priceBreakdown.totalGrossPrice &&
                            this.data.priceBreakdown.totalPrice.raw !== this.data.priceBreakdown.totalGrossPrice.raw
    const totalPriceSubItems = [{
      concept: this.locales.priceBreakdown.pricePerPersonText,
      value: this.data.routePricePerPerson.formatted
    }]
    if (this.data?.priceBreakdown?.subTotalPayAtReservation) {
      totalPriceSubItems.push({
        concept: this.locales.priceBreakdown.headerText,
        value: this.data.priceBreakdown.subTotalPayAtReservation.formatted,
        extraClass: 'w-selected-state__price-overview__total-subitem w-selected-state__price-overview__total-subitem-first'
      })
    }
    if (this.data?.priceBreakdown?.subTotalPayAtDestination) {
      totalPriceSubItems.push({
        concept: this.locales.priceBreakdown.headerTextLocalCosts,
        value: this.data.priceBreakdown.subTotalPayAtDestination.formatted,
        extraClass: 'w-selected-state__price-overview__total-subitem'
      })
    }

    return [
      {
        preitem: (showGrossPrice)
          ? {
              value: this.data.priceBreakdown.totalGrossPrice.formatted,
              preItemsExtraClasses: 'w-selected-state__price-overview__grossprice'
            }
          : '',
        concept: this.locales.priceBreakdown.totalText,
        value: this.data.routeTotalPrice.formatted,
        total: true,
        topItemsExtraClasses: 'w-selected-state__price-overview__item--highlighted',
        subitems: totalPriceSubItems
      }
    ]
  }

  _getLocalCostsCurrencyDescription () {
    return this.data.priceBreakdown.localCostsCurrencyCode
      ? this.locales.priceBreakdown.headerDescriptionLocalCosts.replace(LOCAL_COSTS_CURRENCY_TAG, `<strong>${this.data.priceBreakdown.localCostsCurrencyCode}</strong>`)
      : ''
  }
}

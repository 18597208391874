import { registerWidget } from '../../../js/core/widget/widget-directory'
import { apiCaller } from '../../../js/helpers/api-caller'
import { DownloadButtonTemplate } from '../../components/download-button/c-download-button.template'
import { mapperDownloadButton, orderDocuments } from './helpers'
import { downloadDocumentsEvents } from '../../../js/document/event-types'
import registeredEvents from '../../../js/helpers/registered-events'
const EventEmitter = require('eventemitter3')

const classNames = {
  widgetApi: 'w-download-documents'
}

const attr = {
  track: 'data-track'
}

const widgetQueries = {
  downloadDocumentsRequestUrl: `data-${classNames.widgetApi}__request-url`,
  documentsContainer: `[data-${classNames.widgetApi}__container]`,
  errorMessage: `[data-${classNames.widgetApi}__error-message]`,
  emptyMessage: `[data-${classNames.widgetApi}__empty-message]`,
  loader: `.${classNames.widgetApi}__loader`,
  customerSessionStatus: `data-${classNames.widgetApi}__request-session-url`,
  redirectSubPath: `data-${classNames.widgetApi}__redirect-sub-path`,
  downloadDocumentsBookingNumber: `data-${classNames.widgetApi}__booking-number`,
  downloadButtonSubtitle: '.c-download-button__subtitle'
}

export default class DownloadDocuments {
  /**
  * Creates a new download documents widget.
  * @constructor
  * @param {HTMLElement} element The HTML element.
  */
  constructor (element) {
    this.element = element
    this._getHtmlElements()
    if (this.requestUrl) this._renderDocuments(this.requestUrl)

    // Setup to track events for data layers
    this.events = new EventEmitter()
    registeredEvents.registerWidgetEvents(classNames.widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
  }

  /**
  * Get the necessary elements from the html
  */
  _getHtmlElements () {
    this.requestUrl = this.element.getAttribute(widgetQueries.downloadDocumentsRequestUrl)
    this.container = this.element.querySelector(widgetQueries.documentsContainer)
    /** @type {HTMLElement} */
    this.errorMessage = this.element.querySelector(widgetQueries.errorMessage)
    /** @type {HTMLElement} */
    this.emptyMessage = this.element.querySelector(widgetQueries.emptyMessage)
    /** @type {HTMLElement} */
    this.loader = this.element.querySelector(widgetQueries.loader)
    this.customerSessionStatus = this.element.getAttribute(widgetQueries.customerSessionStatus)
    this.redirectSubPath = this.element.getAttribute(widgetQueries.redirectSubPath)
    this.bookingNumber = this.element.getAttribute(widgetQueries.downloadDocumentsBookingNumber)
  }

  /**
  * Renders the documents
  * @param {String} requestUrl
  */
  _renderDocuments (requestUrl) {
    const request = this._requestDocuments(requestUrl)
    request.then(response => {
      this.loader.style.display = 'none'
      if (!response || !response.isValid) {
        this.errorMessage.style.display = 'block'
        return
      }
      if (response.documents && !response.documents.length) {
        this.emptyMessage.style.display = 'block'
        return
      }

      const orderedDocuments = orderDocuments(response.documents)

      orderedDocuments.forEach(item => {
        const mappedData = mapperDownloadButton(item)
        if (!mappedData) return
        this.container.innerHTML += DownloadButtonTemplate(mappedData)
        this._attachDownloadDocumentsEvents()
      })
      if (!this.container.childNodes.length) this.errorMessage.style.display = 'block'
    })
  }

  /**
  * Returns the requested documents
  * @param {String} requestUrl
  * @return {Promise}
  */
  async _requestDocuments (requestUrl) {
    const result = await apiCaller(requestUrl)
    if (!result.success || !result.response) return null

    return result.response
  }

  _attachDownloadDocumentsEvents () {
    Array.from(this.container.getElementsByClassName('c-download-button')).forEach(document => {
      document.addEventListener('click', async (event) => {
        event.preventDefault()
        event.stopPropagation()
        await this._validateCustomerSession(event.currentTarget)
      })
    })
  }

  async _validateCustomerSession (currentTarget) {
    if (!this.customerSessionStatus) return

    const { response, success } = await apiCaller(this.customerSessionStatus)
    if (!success || response.error) return
    if (!response.active) this._redirect()
    this._downloadDocument(currentTarget)
  }

  _downloadDocument (currentTarget) {
    const downloadNode = document.createElement('a')
    downloadNode.href = `${currentTarget.getAttribute('href')}`
    downloadNode.download = 'download'
    downloadNode.click()
    this.events.emit(downloadDocumentsEvents.DOWNLOADED_DOCUMENT, { documentName: this._getSubtitleDownloadButton(currentTarget), bookingNumber: this.bookingNumber })
  }

  _getSubtitleDownloadButton (downloadButton) {
    let subtitleText
    const subtitleNode = downloadButton.querySelector(widgetQueries.downloadButtonSubtitle)
    if (subtitleNode) subtitleText = subtitleNode.innerHTML
    return subtitleText
  }

  _redirect () {
    const url = new URL(window.location.href).origin
    window.location.href = `${url}${this.redirectSubPath}`
  }
}

registerWidget(DownloadDocuments, classNames.widgetApi)

import Component from '../../../js/core/component/component'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { getData } from '../../../js/document/html-helper'
import { ProductCardTemplate } from './product-card.template'
import { ProductCardSliderTemplate } from './product-card-slider.template'
import { promotedPriceEvents, productMapEvents } from '../../../js/document/event-types'
import registeredEvents from '../../../js/helpers/registered-events'
import { getUrlFromString } from '../../../js/document/url'
import { fetchJsonData } from '../../../js/helpers/json-fetch'
const EventEmitter = require('eventemitter3')

require('../../components/map-interactive/main')

const widgetApi = 'w-product-map'
const promotedPriceWidgetApi = 'w-promoted-price'
const mapApi = 'c-map-interactive'

const widgetQueries = {
  promotedPriceWidgetsSelector: `[data-js-widget="${promotedPriceWidgetApi}"]`,
  trackAttr: 'data-track'
}

export default class ProductMap {
  /**
   * Creates a new Map
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML component element.
   * @param {AutocompleteOptions} [options={}] - Options object
   *
   */
  constructor (element, options = {}) {
    this.element = element
    this.events = new EventEmitter()

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.trackAttr) && { track: this.element.attributes[widgetQueries.trackAttr].value }
    })

    this.modal = element.querySelector('.c-modal')
    this.map = element.querySelector('.c-map-interactive')

    this.settings = { ...getData(this.element), ...options }
    this.settings.productBreadcrumbs = this._getProductBreadcrumbs() || []
    this.modal['c-modal'].events.on('opened', this._showMap, this)
    this.modal['c-modal'].events.on('open', this._prepareMap, this)
    this.modal['c-modal'].events.on('close', this._handleMapClosed, this)
    this.nearbyApiUrl = this.settings.nearbyApiUrl
    this.accoDetailsApiUrl = this.settings.accoDetailsApiUrl
    this.initialized = false
    this.centerMarkerInfoWindowData = []

    if (!this.settings.infoHidePrice) {
      const promotedPriceWidget = document.querySelector(widgetQueries.promotedPriceWidgetsSelector)
      if (promotedPriceWidget) {
        const promotedPriceApi = promotedPriceWidget[promotedPriceWidgetApi]
        if (promotedPriceApi && promotedPriceApi.events) {
          promotedPriceApi.events.once(promotedPriceEvents.PROMOTED_PRICE_DATA_LOADED, (response) => {
            this._addPriceData(response)
          })
        }
      }
    }
  }

  /**
   * Fetches the nearby products
   *
   * @returns {Object|undefined}
   */
  async _fetchProducts () {
    const url = getUrlFromString(this.nearbyApiUrl)
    const result = await fetchJsonData(url)

    return result
  }

  async _fetchAccommodationData (accommodationIds) {
    const params = accommodationIds.reduce((result, id, index) => {
      const idObj = {}
      idObj['accommodationId[' + index + ']'] = id
      return { ...result, ...idObj }
    }, {})

    const url = getUrlFromString(this.accoDetailsApiUrl, params)
    const result = await fetchJsonData(url)

    return result
  }

  _addNearbyMarkers (data) {
    data.accommodations.forEach((accommodation, index) => {
      const latitude = parseFloat(accommodation.latitude)
      const longitude = parseFloat(accommodation.longitude)
      const markerId = index + 1
      const icons = {
        default: data.marker,
        active: (data.markerActive) ? data.markerActive : false
      }

      const accommodationIds = [accommodation.accommodationId]
      accommodation.sameLocationAccommodations.forEach((item) => { accommodationIds.push(item.accommodationId) })

      this.map[mapApi].addMarker(
        markerId,
        latitude,
        longitude,
        icons,
        'nearby',
        true,
        [{ type: 'click', callBack: this._loadInfoWindow.bind(this, 'nearby', accommodationIds) }]
      )
    })

    this.map[mapApi].createClusterMarkers()

    if (this.settings.zoomToMarkers) {
      this.map[mapApi].zoomToMarkers()
    }
  }

  _getCenterMarkerInitialData () {
    return {
      image: {
        sync: this.settings.infoImageSync,
        placeholderSrc: this.settings.infoImagePlaceholdersrc,
        resolvedSrc: this.settings.infoImageResolvedsrc,
        description: this.settings.infoImageDescription,
        ratio: this.settings.infoImageRatio,
        dynamicRatios: this._getImageDynamicRatios() || {},
        resolve: this.settings.infoImageResolve
      },
      titleUrl: this.settings.infoTitleUrl,
      titleText: this.settings.infoTitleText,
      grade: this.settings.infoGrade,
      rating: this.settings.infoRating,
      infoCtaText: this.settings.infoCtaText,
      priceLegend: this.settings.infoPriceLegend,
      breadcrumbs: this.settings.productBreadcrumbs || this._getProductBreadcrumbs() || [],
      hidePrice: this.settings.infoHidePrice,
      price: this.price
    }
  }

  _groupMarkersWithSameLocation (markerData) {
    const groupedAccommodations = []
    const centerMarkerData = []

    markerData.accommodations.forEach((accommodation) => {
      if (parseFloat(this.settings.latitude) === accommodation.latitude && parseFloat(this.settings.longitude) === accommodation.longitude) {
        centerMarkerData.push({ ...accommodation })
      } else {
        const markerDataItem = groupedAccommodations.find(({ latitude, longitude, accommodationId }) => (
          latitude === accommodation.latitude &&
          longitude === accommodation.longitude &&
          accommodationId !== accommodation.accommodationId
        ))
        if (!markerDataItem) {
          groupedAccommodations.push({ ...accommodation, sameLocationAccommodations: [] })
        } else {
          markerDataItem.sameLocationAccommodations.push({ ...accommodation })
        }
      }
    })

    const nearbyMarkersData = { ...markerData, accommodations: [...groupedAccommodations] }

    return { nearbyMarkersData, centerMarkerData }
  }

  _loadInfoWindow (markerType, accommodationIds) {
    this._fetchAccommodationData(accommodationIds).then(response => {
      const { infoCtaText, priceLegend, hidePrice } = this.settings
      const responseArray = (Array.isArray(response)) ? response : [response]
      const infoWindowData = responseArray.map((data) => ({ ...data, infoCtaText, priceLegend, hidePrice }))
      this._populateAndShowInfoWindow(markerType, infoWindowData)
    }).catch(error => {
      console.error(error)
    })
  }

  _populateAndShowInfoWindow (markerType, infoWindowData) {
    const infoWindowSingleData = (Array.isArray(infoWindowData)) ? infoWindowData[0] : infoWindowData
    this.events.emit(productMapEvents.ACCOMMODATION_MARKER_CLICKED, { ...infoWindowSingleData, markerType })

    if (infoWindowData.length > 1) {
      const sliderItems = infoWindowData.map(item => (
        ProductCardTemplate({ ...item, extraClasses: 'slider-item' }))
      )
      const infoWindowContent = ProductCardSliderTemplate({ sliderItems })
      this.map[mapApi].showInfoWindow(infoWindowContent)
      Component.initDocumentComponentsFromAPI(this.modal)
    } else {
      const infoWindowContent = ProductCardTemplate({ ...infoWindowSingleData })
      this.map[mapApi].showInfoWindow(infoWindowContent)
    }
  }

  _prepareMap () {
    if (this.initialized) {
      this.map[mapApi].clearMarkers()
    }
  }

  _showMap () {
    this.settings.mapOpenDate = new Date()
    this.events.emit(productMapEvents.PRODUCT_MAP_OPENED, { ...this.settings })
    this.map[mapApi].showMap()

    this._fetchProducts().then(async (response) => {
      const { nearbyMarkersData, centerMarkerData } = this._groupMarkersWithSameLocation(response)
      this._addNearbyMarkers(nearbyMarkersData)

      if (this.settings.showCenterMarker) {
        let centerMarkerSameLocationsData = []
        const centerMarkerInitialData = this._getCenterMarkerInitialData()

        if (centerMarkerData.length > 0) {
          const accommodationIds = centerMarkerData.map(marker => marker.accommodationId)
          const response = await this._fetchAccommodationData(accommodationIds)
          const { infoCtaText, priceLegend, hidePrice } = this.settings
          const responseArray = (Array.isArray(response)) ? response : [response]
          centerMarkerSameLocationsData = responseArray.map((data) => ({ ...data, infoCtaText, priceLegend, hidePrice }))
        }

        this._addCenterMarker([centerMarkerInitialData, ...centerMarkerSameLocationsData])
      }
    }).catch(error => {
      console.error(error)
    })

    this.initialized = true
  }

  _handleMapClosed () {
    this.settings.mapClosedDate = new Date()
    this.events.emit(productMapEvents.PRODUCT_MAP_CLOSED, { ...this.settings })
    this._clearMap()
  }

  _clearMap () {
    this.map[mapApi].clearInfoWindow()
  }

  _addCenterMarker (centerMarkerInfoWindowData) {
    const latitude = parseFloat(this.settings.latitude)
    const longitude = parseFloat(this.settings.longitude)
    const markerId = 0
    const icons = {
      default: this.settings.markerIcon,
      active: (this.settings.markerIconActive) ? this.settings.markerIconActive : false
    }

    this.map[mapApi].addMarker(
      markerId,
      latitude,
      longitude,
      icons,
      'center',
      false,
      [{ type: 'click', callBack: this._populateAndShowInfoWindow.bind(this, 'center', centerMarkerInfoWindowData) }]
    )
    this._populateAndShowInfoWindow('center', centerMarkerInfoWindowData)
  }

  _addPriceData (price) {
    this.price = price
    if (this.initialized) {
      if (this.settings.showCenterMarker) {
        this.map[mapApi].clearMarkers()
        this._addCenterMarker()
      }
    }
  }

  _getProductBreadcrumbs () {
    const breadcrumbsElement = document.querySelector(`[data-type="i18n"][data-uid="${this.element.id}__breadcrumbs"]`)
    let breadcrumbsData = null
    try {
      breadcrumbsData = JSON.parse(breadcrumbsElement.textContent)
    } catch (err) { }

    return breadcrumbsData
  }

  _getImageDynamicRatios () {
    const dynamicRatiosElement = document.querySelector(`[data-type="i18n"][data-uid="${this.element.id}__dynamicRatios"]`)
    let dynamicRatiosData = null
    try {
      dynamicRatiosData = JSON.parse(dynamicRatiosElement.textContent)
    } catch (err) { }

    return dynamicRatiosData
  }
}

registerWidget(ProductMap, widgetApi)

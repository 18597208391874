/**
 * The ProgressBarData contains all the data needed to hydrate a Number score component
 *
 * @typedef {Object}      ProgressBarData
 *
 * @property {String}     variant           - The variant ot the progress bar
 * @property {String}     title             - The progress bar title
 * @property {String}     value             - The progress bar filled value
 * @property {String}     max               - The progress bar max value
 * @property {String}     hideValue         - Defines if the progress bar filled value number is hidden
 * @property {Boolean}    valueSuffix       - Defines the value suffix
 * @property {String}     extraClasses      - The extra classes for the component
 * @property {Object}     [attributes]      - Attributes for the progress bar
 */

import { getTokenClass } from '../../../js/helpers/tokens'
import { encodeAttributes } from '../../../js/helpers/string'

// Import tokens
const tokensShared = require('./data/c-progress-bar__tokens.json')['c-progress-bar']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/progress-bar/data/c-progress-bar__tokens.json`)['c-progress-bar'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultProgressBarData = {
  variant: '',
  title: '',
  value: '25',
  max: '100',
  hideValue: false,
  valueSuffix: '%',
  extraClasses: '',
  attributes: null
}

export function ProgressBarTemplate (data) {
  data = { ...defaultProgressBarData, ...data }

  return `
  <div class="c-progress-bar ${data.extraClasses}" ${data.attributes ? encodeAttributes(data.attributes) : ''}>
    ${data.title
    ? `<span class="c-progress-bar__title m-body ${getTokenClass('header__size', data.variant ? data.variant : 'default', tokens)}">
        ${data.title}
      </span>`
    : ''
}

    <div class="c-progress-bar__body o-pack">
      <div class="c-progress-bar__graphic o-pack__item">
        <progress value="${data.value}" max="${data.max}"></progress>
      </div>
      ${data.hiddenValue
    ? ''
    : `
          <div class="c-progress-bar__value o-pack__item ${getTokenClass('value__size', data.variant ? data.variant : 'default', tokens)}">
            ${data.value}${data.valueSuffix ? data.valueSuffix : ''}
          </div>
        `.trim()
}
    </div>
  </div>
  `
}

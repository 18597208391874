import { BoxTemplate } from '../../components/box/c-box.template'
import { TitleTemplate } from '../../components/title/c-title.template'

/**
 * The BookingMessagesData contains all data needed to hydrate a BookingMessages view
 *
 * @typedef {Object}                  BookingMessagesData
 *
 * @property {MessageData[]}          messages                         - The list of messages to be shown
 * @property {String}                 MessageData.type                 - The type of the message (allows info, warning, error)
 * @property {String[]}               MessageData.texts                - Contains the list of texts to be shown in that box
  *
 */

export const BookingMessageTemplate = (d) => {
  return `<div class="sp-stack-children--medium">
    ${d.messages.map(message => `
      ${BoxTemplate({
        variant: 'flat',
        extraClasses: 'cs-state-' + message.type +
      ' cs-state-' + message.type + '--light' +
      ' w-booking-message__box ' + d.extraClasses,
        html: `<div class="w-booking-message__text">                
                ${message.title
                  ? `${TitleTemplate({
                    text: message.title,
                    size: 'tiny',
                    variant: '',
                    extraClasses: 'w-booking-message__title'
                  })}`
                  : ''}
                ${message.texts.map(text => `
                      <div class="w-booking-message__text">${text}</div>
                  `).join('')}
              </div>`
      })}
   `).join('')}
    </div>
  `
}

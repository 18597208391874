import { apiCaller } from '../../../js/helpers/api-caller'
import { registerWidget } from '../../../js/core/widget/widget-directory'

const WIDGET_API = 'w-subscribe-form'

const WIDGET_QUERIES = {
  form: '[data-js-component="c-form"]',
  saveButtons: `[data-${WIDGET_API}--save-button]`,
  emailInput: `[data-${WIDGET_API}--email-input]`,
  successToggle: `[data-${WIDGET_API}--success-toggle]`
}

const ATTRIBUTES = {
  componentId: 'wSubscribeFormComponentId',
  datasourceId: 'wSubscribeFormDatasourceId',
  classes: {
    isSuccess: 'w-subscribe-form__is-success',
    isError: 'w-subscribe-form__is-error'
  }
}

require('../../components/form/main')
require('../../components/textbox/main')

export default class SubscribeForm {
  /**
   * Creates a new Subscribe form widget
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML element
   */
  constructor (element) {
    if (!element) { return }
    this.element = element
    this.componentId = this.element.dataset[ATTRIBUTES.componentId]
    this.datasourceId = this.element.dataset[ATTRIBUTES.datasourceId]

    // Expose component public API
    this.element[WIDGET_API] = {
      element: this.element
    }

    this._attachEvents()
  }

  _attachEvents () {
    this.form = this.element.querySelector(WIDGET_QUERIES.form)

    if (this.form) {
      this.formApi = this.form['c-form']
    }

    this.emailInput = this.element.querySelector(WIDGET_QUERIES.emailInput)

    if (this.emailInput) {
      this.emailInputApi = this.emailInput['c-textbox']
    }

    this.successToggleElement = this.element.querySelector(WIDGET_QUERIES.successToggle)
    this.saveButtons = [...this.element.querySelectorAll(WIDGET_QUERIES.saveButtons)]

    if (this.saveButtons) {
      this.saveButtons.forEach(button => {
        button.addEventListener('click', (ev) => this._clickSaveButton(ev))
      })
    }
  }

  async _clickSaveButton (ev) {
    ev.preventDefault()

    try {
      this._enableForm(false)
      this.successToggleElement.classList.remove(ATTRIBUTES.classes.isError)

      if (this._getEmailToSubscribe() && this.formApi.validate()) {
        await this._saveEmailAndGetDataChangeData()
      }
    } catch (ex) {
      console.error(ex)
      this._enableForm(true)
    }
  }

  async _saveEmailAndGetDataChangeData () {
    const eventArgs = {
      method: this.formApi.method,
      url: this.formApi.action
    }

    if (this.formApi.method !== 'GET') {
      eventArgs.body = {
        email: this._getEmailToSubscribe(),
        itemId: this.componentId,
        datasourceId: this.datasourceId
      }
    }

    if (eventArgs.url) {
      this.callApi(eventArgs.method, eventArgs.url, eventArgs.body)
    }
  }

  /**
   * Calls backend to retrieve data, and pings all registred items
   *
   * @param {string} method - GET|POST|PATCH
   * @param {string} uri - Full url to execute the action
   * @param {object} body - (Optional) The payload for the POST or PATCH operations
   */
  async callApi (method, url, body) {
    try {
      const result = await apiCaller(url, {
        method,
        body
      })

      if (result.success) {
        this.apiData = result.response

        if (this.apiData) {
          this._showSubscribeFormSuccess()
        }
      } else {
        this._showSubscribeFormError()
      }
    } finally {
      this._enableForm(true)
    }
  }

  _enableForm (enableStatus) {
    Array.from(this.form).forEach(elem => { elem.disabled = !enableStatus })

    this.saveButtons.forEach(btn => btn['c-btn'].setLoading(!enableStatus))
  }

  _getEmailToSubscribe () {
    return this.emailInputApi.getProp('value').trim()
  }

  _showSubscribeFormSuccess () {
    this.successToggleElement.classList.remove(ATTRIBUTES.classes.isError)
    this.successToggleElement.classList.add(ATTRIBUTES.classes.isSuccess)
  }

  _showSubscribeFormError () {
    this.successToggleElement.classList.remove(ATTRIBUTES.classes.isSuccess)
    this.successToggleElement.classList.add(ATTRIBUTES.classes.isError)
  }
}

registerWidget(SubscribeForm, WIDGET_API)

import BookingItem from '../booking-item/main'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import webStorage from '../../../js/document/web-storage'

const widgetApi = 'w-booking-back-button'

const widgetQueries = {
  buttonElement: '.w-booking-back-button__btn',
  btnApi: 'c-btn',
  identifierSearchName: 'sid'
}

export default class BookingBackButton extends BookingItem {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   */
  constructor (element) {
    super(element)
    this.element = element
    this.button = this.element.querySelector(widgetQueries.buttonElement)
    this.buttonApi = this.button ? this.button[widgetQueries.btnApi] : undefined
    this.previousPageInfo = this._getPreviousPageInfo()
    if (this.previousPageInfo) {
      this._feedBackButton(this.previousPageInfo)
    }
  }

  async handleFetched (data) {
    const widgetData = data.response.previousPage
    if (widgetData && !this.previousPageInfo) {
      const info = {
        url: widgetData.url,
        text: widgetData.text
      }

      this._feedBackButton(info)
      this._setSessionStorage(info)
    }

    return super.handleFetched(data.response)
  }

  _feedBackButton (info) {
    if (this.buttonApi) {
      this.buttonApi.setProp('text', info.text)
    }
    if (this.button) {
      this.button.href = info.url
    }
  }

  _getIdentifierFromUrl () {
    let identifier
    const urlParams = new window.URLSearchParams(window.location.search)
    if (urlParams) {
      identifier = urlParams.get(widgetQueries.identifierSearchName)
    }
    return identifier !== null ? identifier : undefined
  }

  _setSessionStorage (info) {
    const identifier = this._getIdentifierFromUrl()
    if (identifier) {
      webStorage.session.set(identifier, info)
    }
  }

  _getPreviousPageInfo () {
    let info
    const identifier = this._getIdentifierFromUrl()
    if (identifier) {
      info = webStorage.session.get(identifier)
    }
    return info
  }
}

registerWidget(BookingBackButton, widgetApi)

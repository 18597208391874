import { registerWidget } from '../../../js/core/widget/widget-directory'
import { elementFromString, flush } from '../../../js/document/html-helper'
import { fetchJsonData } from '../../../js/helpers/json-fetch'
import { getUrlFromString } from '../../../js/document/url'
import Component from '../../../js/core/component/component'
import { CustomerDiscountsContentTemplate } from './w-customer-discounts__content.template'

const widgetApi = 'w-customer-discounts'

const defaults = {
}

const widgetQueries = {
  mainContainer: `[data-${widgetApi}__content]`,
  configuration: `[data-${widgetApi}__configuration]`
}

const attr = {
  url: `data-${widgetApi}__url`
}

export default class CustomerDiscounts {
  /**
   * Creates a new CustomerDiscounts
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML component element.
   * @param {Object} [options]    - Options object
   *
   */
  constructor (element, options = {}) {
    this.element = element
    this.id = this.element.id
    this.options = {
      ...defaults,
      url: this.element.getAttribute(attr.url),
      ...options
    }

    this.settings = this._getSettingsDataFromDom() || {}

    // Get elements
    this.elements = {}
    this.elements.mainContainer = this.element.querySelector(widgetQueries.mainContainer)

    // Fetch and update html
    this.fetchAndUpdate()
      .catch(e => { })
  }

  async fetchAndUpdate () {
    const getUrl = getUrlFromString(this.options.url, this.options.urlParams)
    const results = await fetchJsonData(getUrl)

    if (results && this._hasActiveDiscounts(results)) {
      const templateData = {
        text: this._buildText(results),
        link: this.settings.moreLink
      }
      const renderedHtml = CustomerDiscountsContentTemplate(templateData)
      this._renderHTML(renderedHtml, this.elements.mainContainer)
      this.element.classList.remove('is-hidden')
    }
  }

  // assume results is not null
  _buildText (results) {
    let text = ''

    if (this.settings.notificationMessageText) {
      const discountPercentage = results.data.discounts[0].discountPercentage
      text = this.settings.notificationMessageText
        .replace(/{discountPercentage}/g, discountPercentage)
    }

    return text
  }

  _hasActiveDiscounts (results) {
    return results.data && results.data.discounts && results.data.discounts.some(d => d.canBeUsed === true)
  }

  _renderHTML (html, targetElement) {
    const newContent = elementFromString(html)
    flush(targetElement)
    targetElement.appendChild(newContent)
    Component.initDocumentComponentsFromAPI(newContent)
  }

  _getSettingsDataFromDom () {
    const element = this.element.querySelector(widgetQueries.configuration)
    if (!element) {
      return {}
    }
    return JSON.parse(element.innerText)
  }
}

registerWidget(CustomerDiscounts, widgetApi)

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'

loadQueueHelper.add(function () {
  setTimeout(function () {
    initPromotedPrice('my-promoted-price-razor')
    initPromotedPrice('my-promoted-price-is-floating-razor')
    initPromotedPrice('my-promoted-price-is-floating-from-razor')
  }, 2000) // Set timeout because razor views are not always visible after document load event.
}, 2)

const initPromotedPrice = (promotedPriceId) => {
  const params = {}
  params['Participants[0][0]'] = '1989-03-20'
  params['Participants[0][1]'] = '1989-03-20'
  params.Duration = '7-12'
  params.Mealplan = 'AI'
  params.TransportType = 'Flight'

  const promotedPriceElement = document.getElementById(promotedPriceId)
  if (promotedPriceElement) {
    // Init promoted price razor views to be visible.
    promotedPriceElement['w-promoted-price'].refresh(params)
  }
}

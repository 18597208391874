import { operationTypeEnum } from './enums'
export default class ParticipantIdsMapper {
  /** Retrives the proper participantIds
   *  If row has participantsAsOptionsApi:
   *    participantIds are the values checked
   *  If row has quantityApi && selectedOption
   *    participants are selectedOption.applies to participants
   *  If row has participantId:
   *    Check if other rows has the same selectedOption and return the partcipats
   *    Else  push to partcipantIds the current participantID
   * @param {object}        row                  -- The current row
   * @param {object}        allRows              -- The total component rows
   * @param {object}        selectedOption       -- The new options selected
   *
   */
  static getParticipantIds (row, allRows, selectedOption, operationType) {
    let participantIds = []

    if (row.participantsAsOptionsApi && row.participantsAsOptionsApi.getProp('options')) {
      // Excursions, Bridge, Golf
      const participantOptions = row.participantsAsOptionsApi.getProp('options').filter(option => option.checked)
      participantIds = [...participantOptions.map(option => parseInt(option.value))]
    } else if (row.quantityApi && selectedOption) {
      // DinnerVoucher
      participantIds = [...selectedOption.appliesToParticipants]
    } else if (row.participantId) {
      // Ski
      const participantsFromOtherRowsWithSameSelectedOption = Object.values(allRows).filter(currentRow => currentRow.selectedOption && currentRow.selectedOption.code === selectedOption.code).map(row => row.participantId)
      if (participantsFromOtherRowsWithSameSelectedOption.length > 0 && operationType === operationTypeEnum.Patch) {
        participantIds = [...participantsFromOtherRowsWithSameSelectedOption]
      } else {
        participantIds.push(row.participantId)
      }
    }

    return participantIds
  }
}

import { FormTemplate } from '../../../components/form/c-form.template'
import { FormSectionTemplate } from '../../../components/form/c-form__section.template'
import { FormFieldsetTemplate } from '../../../components/form/c-form__fieldset.template'
import { ChoiceListTemplate } from '../../../components/choice-list/c-choice-list.template'
import { TextareaTemplate } from '../../../components/textarea/c-textarea.template'
import { TitleTemplate } from '../../../components/title/c-title.template'
import { BulletListTemplate } from '../../../components/bullet-list/c-bullet-list.template'

export const BookingCancellationFlowFormParticipantsTemplate = (d) => {
  return `
    <div>
      <div>${d.text.replace('{{MAINBOOKER}}', `${d.mainBooker.firstName} ${d.mainBooker.lastName}`)}</div>
      ${FormTemplate({
        hasSubmitButton: false,
        validate: true,
        attributes: {
          'data-w-booking-cancellation-flow__form': ''
        },
        extraClasses: 'w-booking-cancellation-flow__form',
        html: `
          <div class="w-booking-cancellation-flow__form-body-section">
            ${FormSectionTemplate({
              html: FormFieldsetTemplate({
                html: `${ChoiceListTemplate({
                  id: 'participants',
                  variant: 'boxed',
                  method: 'multiple',
                  label: d.participantsLabel,
                  items: d.participants.map(p => { return { text: `${p.firstName} ${p.lastName}`, id: p.id, value: p.id, checked: d.selectedParticipantsIds.includes(parseInt(p.id)) } }),
                  attributes: {
                    'data-w-booking-cancellation-flow__participants-choice-list': '',
                    'data-message-required': d.participantsRequiredMessage
                  },
                  required: true,
                  minlength: '1',
                  extraClasses: 'c-form__item c-form__item--large u-mb--none'
                })}`
              })
            })}
          </div>`
      })}
    </div>
    `
}

export const BookingCancellationFlowFormRemarksAndConditionsTemplate = (d) => {
  const selectedParticipants = d.selectedParticipants.map(p => ({ text: `${p.firstName} ${p.lastName}` }))
  return `
    <div>
      ${TitleTemplate({
        text: d.selectedParticipantsLabel,
        size: 'small'
      })}
      ${BulletListTemplate({
        items: selectedParticipants,
        extraClasses: 'u-mv--medium'
      })}
      ${FormTemplate({
        hasSubmitButton: false,
        validate: true,
        attributes: {
          'data-w-booking-cancellation-flow__form': ''
        },
        extraClasses: 'w-booking-cancellation-flow__form',
        html: `
          <div class="w-booking-cancellation-flow__form-body-section">

          ${FormSectionTemplate({
            html: FormFieldsetTemplate({
              html: `
              ${TextareaTemplate({
                id: 'remark',
                attributes: {
                  'data-w-booking-cancellation-flow__remarks': ''
                },
                label: d.remarksLabel,
                placeholder: d.remarksPlaceholder,
                extraClasses: 'c-form__item c-form__item--large'
              })}
              <div class="w-booking-cancellation-flow__terms-conditions">${d.termsAndConditionsText}</div>
              ${ChoiceListTemplate({
                id: 'conditions',
                method: 'multiple',
                required: true,
                items: [{ text: d.conditionsLabel, value: 'accept', required: true }],
                attributes: {
                  'data-w-booking-cancellation-flow__conditions': '',
                  'data-message-required': d.conditionsRequiredMessage
                },
                extraClasses: 'c-form__item c-form__item--large w-booking-cancellation-flow__conditions'
              })}
              `
            })
          })}
        </div>`
      })}
    </div>
    `
}

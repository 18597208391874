import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingBasket from '../../../../shared/modules/widgets/booking-basket/main'

const data = require('../../../../shared/modules/widgets/booking-basket/data/w-booking-basket__js-template.json')
const dataFullyPaid = require('../../../../shared/modules/widgets/booking-basket/data/w-booking-basket__js-template-fully-paid.json')
const dataPartiallyPaid = require('../../../../shared/modules/widgets/booking-basket/data/w-booking-basket__js-template-partially-paid.json')
const dataCouponCodeData = require('../../../../shared/modules/widgets/booking-basket/data/w-booking-basket__js-template-coupon-code-selected.json')
const dataWithPrices = require('../../../../shared/modules/widgets/booking-basket/data/w-booking-basket__js-template-with-prices.json')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = async () => {
  const bookingBasketItem = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-basket"]')

  bookingBasketItem.forEach(element => {
    const bookingBasket = new BookingBasket(element)
    console.log(bookingBasket)
  })

  const bookingBasketJsTemplate = document.querySelector('[data-booking-basket-demo]')
  if (bookingBasketJsTemplate) {
    const widget = new BookingBasket(bookingBasketJsTemplate.firstElementChild)
    await widget.handleFetched({ response: data })
    await widget.enableUI()
  }

  const bookingBasketJsCouponCodeTemplate = document.querySelector('[data-booking-basket-coupon-code-demo]')
  if (bookingBasketJsCouponCodeTemplate) {
    const widgetCouponCode = new BookingBasket(bookingBasketJsCouponCodeTemplate.firstElementChild)
    await widgetCouponCode.handleFetched({ response: dataCouponCodeData })
    await widgetCouponCode.enableUI()
  }

  const bookingBasketJsPartiallyPaid = document.querySelector('[data-booking-basket-rebooking-partially-paid-demo]')
  if (bookingBasketJsPartiallyPaid) {
    const widgetPartiallyPaid = new BookingBasket(bookingBasketJsPartiallyPaid.firstElementChild)
    await widgetPartiallyPaid.handleFetched({ response: dataPartiallyPaid })
    await widgetPartiallyPaid.enableUI()
  }

  const bookingBasketJsFullyPaid = document.querySelector('[data-booking-basket-rebooking-fully-paid-demo]')
  if (bookingBasketJsFullyPaid) {
    const widgetFullyPaid = new BookingBasket(bookingBasketJsFullyPaid.firstElementChild)
    await widgetFullyPaid.handleFetched({ response: dataFullyPaid })
    await widgetFullyPaid.enableUI()
  }

  const bookingBasketJsTemplateWithPrices = document.querySelector('[data-booking-basket-with-prices]')
  if (bookingBasketJsTemplateWithPrices) {
    const widget = new BookingBasket(bookingBasketJsTemplateWithPrices.firstElementChild)
    await widget.handleFetched({ response: dataWithPrices })
    await widget.enableUI()
  }
}

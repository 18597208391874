import { BtnTemplate } from '../../components/btn/c-btn.template'

/**
 * Returns an html that will wrap Price Table inner elements (room selector, flight bus selector, overview)
 *
 *
 * @returns {String} Booking Gate Item HTML string
 */

export const BookingGateItemTemplate = (id) => `
  <div id="${id}__wrapper" class="w-booking-gate__item-wrapper">
    ${BtnTemplate({
      extraClasses: 'w-booking-gate__item-close',
      type: 'button',
      icon: 'cross',
      variant: 'icon-only'
    })}
    <div class="w-booking-gate__item-wrapper-main">
      <div id="${id}__room-selector" class="w-room-selector w-booking-gate__room-selector"></div>
      <div id="${id}__bus-selector" class="w-booking-gate__flight-bus-selector"></div>
      <div id="${id}__selected-state" class="w-booking-gate__selected-state"></div>
    </div>
  </div>
`

/**
 * The NavData contains all data needed to hydrate a Nav view
 *
 * @typedef {Object}          NavData
 *
 * @property {String}         tag
 * @property {String}         extraClasses
 * @property {String}         id
 * @property {String}         backText
 * @property {String}         more
 * @property {String}         moreText
 * @property {Boolean}        multilevel
 * @property {Number}         currentLevel
 * @property {Array}          items
 * @property {String}         [variant]           - Nav's variant
 * @property {String}         [track]             - Nav's track
 * @property {Object}         [attributes]        - Attributes for the nav
 * @property {Object}         [navData]           - navData for the nav
 */

import { SwipeTemplate } from '../../objects/swipe/o-swipe.template'
import { NavListTemplate } from './c-nav__list.template'
import { encodeAttributes } from '../../../js/helpers/string'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/c-nav__tokens.json')['c-nav']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/nav/data/c-nav__tokens.json`)['c-nav'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultNavData = {
  tag: '',
  variant: 'default',
  extraClasses: '',
  backText: 'Back',
  more: false,
  moreText: 'More',
  multilevel: false,
  id: '',
  level: 1,
  currentLevel: 1,
  track: '',
  items: [],
  attributes: {}
}

export const NavTemplate = (d) => {
  d = { ...defaultNavData, ...d }

  return `
  <${d.tag ? d.tag : 'nav'}
  class="c-nav ${d.variant ? getTokenClass('variant', d.variant, tokens) : ''} 
  ${d.variant !== 'list' && d.multilevel ? 'c-nav--multilevel' : ''} 
  ${d.extraClasses}"
  ${d.id ? `id="${d.id}"` : ''}
  ${d.variant === 'block' || d.variant === 'dropdown' ? `data-c-nav__shown-level="${d.currentLevel}" data-c-nav__variant="${d.variant}"` : ''}
  ${d.variant === 'inline' ? 'data-js-swipe data-list-class="c-nav__list" data-list-item-class="c-nav__item" data-left-arrow-class="o-swipe-btn--left" data-right-arrow-class="o-swipe-btn--right" data-active-class="is-active"' : ''}
  ${d.track ? `data-track=${d.track}` : ''}
  ${d.attributes ? encodeAttributes(d.attributes) : ''}
  data-js-component='c-nav'>
    ${NavListTemplate(Object.assign({}, {
      variant: d.variant,
      multilevel: d.multilevel,
      items: d.items,
      level: d.level,
      currentLevel: d.currentLevel,
      id: d.id,
      backText: d.backText,
      more: d.more,
      moreText: d.moreText
    }))}
    ${d.variant === 'inline' ? SwipeTemplate() : ''}
  </${d.tag ? d.tag : 'nav'}>
`
}

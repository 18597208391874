import BookingItem from '../booking-item/main'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookedServiceBasketLinesTemplate } from './booked-service-basket__overview-lines.template'
import BookedServiceBasketDataMapper from './data-mapper'

const widgetApi = 'w-booked-service-basket'

const classNames = {
  opened: 'in',
  hidden: 'is-hidden'
}

const widgetQueries = {
  configurationElement: `[data-${widgetApi}__configuration]`,
  sideDrawer: `[data-${widgetApi}__c-side-drawer]`,
  sideDrawerApi: 'c-side-drawer',
  sideDrawerTrigger: `[data-${widgetApi}__side-drawer-trigger]`,
  totalBookingAmount: `[data-${widgetApi}__total-booking-amount]`,
  paidBookingAmount: `[data-${widgetApi}__paid-booking-amount]`,
  dueAmountValue: `[data-${widgetApi}__due-amount-value]`,
  returnAmountValue: `[data-${widgetApi}__return-amount-value]`,
  depositDueDate: `[data-${widgetApi}__due-date]`,
  downAmountValue: `[data-${widgetApi}__down-amount-value]`,
  servicesDetailElement: `[data-${widgetApi}__services]`,
  dueAmountElement: `[data-${widgetApi}__due-amount]`,
  dueDateElement: `[data-${widgetApi}__due-date-element]`,
  downAmountElement: `[data-${widgetApi}__down-amount]`,
  returnAmountElement: `[data-${widgetApi}__return-amount]`
}

/**
 * BookedServiceBasket widget
 */
export default class BookedServiceBasket extends BookingItem {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   */
  constructor (element) {
    super(element)
    this.element = element
    this.dataMapper = new BookedServiceBasketDataMapper()

    this.totalBookingAmountElements = this.element.querySelectorAll(widgetQueries.totalBookingAmount)
    this.paidBookingAmountElements = this.element.querySelectorAll(widgetQueries.paidBookingAmount)
    this.dueAmountValueElements = this.element.querySelectorAll(widgetQueries.dueAmountValue)
    this.downAmountValueElements = this.element.querySelectorAll(widgetQueries.downAmountValue)
    this.returnAmountValueElements = this.element.querySelectorAll(widgetQueries.returnAmountValue)
    this.depositDueDateElements = this.element.querySelectorAll(widgetQueries.depositDueDate)
    this.servicesDetailElements = this.element.querySelectorAll(widgetQueries.servicesDetailElement)
    const filterConfigurationElement = this.element.querySelector(widgetQueries.configurationElement)
    this.configurations = this._readOptionsFromConfigurations(filterConfigurationElement)

    this._attachEvents()
  }

  /**
   *
   * @param {Object} data - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    const basketData = this.dataMapper.mapServiceBasketInfo(data.response, this.configurations)
    this._updateWidget(basketData)
    return super.handleFetched(data.response)
  }

  _attachEvents () {
    this._attachSideDrawer()
  }

  _attachSideDrawer () {
    const sideDrawerElement = this.element.querySelector(widgetQueries.sideDrawer)
    if (sideDrawerElement != null) {
      const sideDrawerApi = sideDrawerElement[widgetQueries.sideDrawerApi]
      const sideDrawerTriggerElement = this.element.querySelector(widgetQueries.sideDrawerTrigger)
      if (sideDrawerApi) {
        sideDrawerApi.events.on('open', () => {
          sideDrawerTriggerElement.classList.add(classNames.opened)
        })

        sideDrawerApi.events.on('close', () => {
          sideDrawerTriggerElement.classList.remove(classNames.opened)
        })
      }
    }
  }

  _updateWidget (basketData) {
    this.totalBookingAmountElements.forEach(element => {
      element.innerHTML = basketData.totalBookingAmount
    })

    this.paidBookingAmountElements.forEach(element => {
      element.innerHTML = basketData.paidBookingAmount
    })

    this._setAmountElement(this.dueAmountValueElements, basketData.dueAmountValue, widgetQueries.dueAmountElement)
    this._setAmountElement(this.depositDueDateElements, basketData.depositDueDateValue, widgetQueries.dueDateElement)
    this._setAmountElement(this.returnAmountValueElements, basketData.returnAmountValue, widgetQueries.returnAmountElement)
    this._setAmountElement(this.downAmountValueElements, basketData.downAmountValue, widgetQueries.downAmountElement)

    const overviewServices = basketData.overviewServices
    let newInner = ''
    if (overviewServices) {
      overviewServices.forEach(extra => {
        newInner += BookedServiceBasketLinesTemplate(extra)
      })
    }
    if (this.servicesDetailElements) {
      this.servicesDetailElements.forEach(element => {
        element.innerHTML = newInner
      })
    }
  }

  _setAmountElement (amountElements, amount, amountElementQuery) {
    amountElements.forEach(element => {
      element.innerHTML = amount
      if (amount !== '') {
        element.closest(amountElementQuery).classList.remove(classNames.hidden)
      } else {
        element.closest(amountElementQuery).classList.add(classNames.hidden)
      }
    })
  }

  _readOptionsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)
      const priceConfigurations = super.readPriceConfigFromConfigurations(configuration)

      return {
        ...priceConfigurations
      }
    }
  }
}

registerWidget(BookedServiceBasket, widgetApi)

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import JSONFetcher from '../../../../shared/js/helpers/json-fetcher'
import FilterModel from '../../../../shared/js/data/filters/filter-model'
import { FILTER_TYPES } from '../../../../shared/js/data/filters/config'
import DatesFilter from '../../../../shared/modules/widgets/dates-filter/main'
import { addDays, dateToString, getConsecutivePeriod } from '../../../../shared/js/helpers/dates'

loadQueueHelper.add(function () {
  initDatesFilter('my-dates-filter')
  initFutureDatesFilter('my-future-dates-filter')
}, 2)

document.addEventListener('razor-load-complete', function () {
  initDatesFilter('my-razor-dates-filter')
  initFutureDatesFilter('my-razor-future-dates-filter')
})

const initDatesFilter = (datesFilterId) => {
  const datesFilterElement = document.getElementById(datesFilterId)
  if (!datesFilterElement) return
  console.log('Dates Filter Element found', datesFilterElement)

  const quickSearchRequest = new JSONFetcher({ src: '../assets/api/quick-search.json' })
  quickSearchRequest
    .fetch()
    .then(data => {
      const datesFilterData = data.filters.find(filter => filter.type === FILTER_TYPES.DEPARTURE_DATE)
      const datesFilterModel = new FilterModel(datesFilterData)

      console.log('Dates Filter Data Model initialized', datesFilterModel)

      const datesFilter = new DatesFilter(datesFilterElement, datesFilterModel)
      console.log('Dates Filter initialized', datesFilter)
    })
}

const initFutureDatesFilter = (datesFilterId) => {
  const datesFilterElement = document.getElementById(datesFilterId)
  if (!datesFilterElement) return
  console.log('Dates Filter Element found', datesFilterElement)

  const today = dateToString(new Date())
  const firstAvailableDate = dateToString(addDays(today, 60))
  const dates = getConsecutivePeriod(today, addDays(today, 365))
    .map(d => dateToString(d))
  const datesFilterData = {
    type: FILTER_TYPES.DEPARTURE_DATE,
    isMultiselectable: true,
    values: dates.map(d => ({
      caption: d,
      value: d,
      isSelected: false,
      isAvailable: (d >= firstAvailableDate)
    }))
  }

  const datesFilterModel = new FilterModel(datesFilterData)
  console.log('Dates Filter Data Model initialized', datesFilterModel)
  const datesFilter = new DatesFilter(datesFilterElement, datesFilterModel)
  console.log('Dates Filter initialized', datesFilter)
}

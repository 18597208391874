import { propTypes, defaultProps } from './w-room-list-item.props'
import { checkPropTypes } from 'prop-types'
import { PriceTemplate } from '../../components/price/c-price.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { LabelTemplate } from '../../components/label/c-label.template'
import { CollapseTemplate, defaultCollapseData } from '../../components/collapse/c-collapse.template'
import { PhotoGalleryTemplate } from '../../components/photo-gallery/c-photo-gallery.template'
import { getTokenClass } from '../../../js/helpers/tokens'
import { accommodationRoomtypesType as events } from '../data-layer/types'

// Import room occupancy tokens
const tokensShared = require('./data/w-room-list__tokens.json')['w-room-list']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/widgets/room-list/data/w-room-list__tokens.json`)['w-room-list'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const dataRoomtypeCollapseListener = events.ROOMTYPES_SELECTOR_TOGGLED

export const RoomListItemTemplate = (props) => {
  const d = { ...defaultProps, ...props }
  checkPropTypes(propTypes, d, 'prop', 'RoomListItemTemplate')

  const classNameLastRoom = d.stockWarningMessageIsLastRoom ? 'w-room-list-item__availability-lastroom' : ''

  return `
<div class="w-room-list-item ${d.extraClasses ? d.extraClasses : ''}"
  ${d.id ? `data-room-type-id="${d.id}"` : ''}
>
    <div class="w-room-list-item__section-header">
        <div class="w-room-list-item__labels-and-room-name">
            ${d.onRequest && d.locales.onRequestLabel && d.isSelectable
                ? LabelTemplate({
                  text: d.locales.onRequestLabel,
                  variant: 'sale',
                  extraClasses: 'w-room-list-item__onrequest'
                })
                : ''}
            ${d.stockWarningMessage && d.isSelectable
                ? LabelTemplate({
                  text: d.stockWarningMessage,
                  variant: 'sale',
                  extraClasses: `w-room-list-item__availability ${classNameLastRoom}`
                })
                : ''}
            <div class="w-room-list-item__room-name">
                <span class="w-room-list-item__name-text m-body m-body--large">${d.title}</span>
                ${d.description
                  ? `<span class="w-room-list-item__description">${d.description}</span>`
                  : ''}
            </div>
        </div>
        <div class="w-room-list-item__price">
            ${PriceTemplate({
              ...d.priceConfig,
              value: d.price
            })}
        </div>
    </div>
    ${d.isSelectable
      ? `<div class="w-room-list-item__section-footer">
          <div class="w-room-list-item__section-upper-footer">
            ${d.characteristics && d.locales.roomTypeDescriptionTitle
              ? `<div class="w-room-list-item__characteristics-trigger">
                  <button type="button" class="m-button--clean c-collapse__trigger w-room-list-item__characteristics-trigger-btn" data-c-collapse__id="${d.id}-${d.roomNumber}"  data-c-collapse__action="toggle">
                    <span class="c-collapse__trigger-text m-body--small">${d.locales.roomTypeDescriptionTitle}</span>
                    <span class="m-icon m-icon--chevron-down c-collapse__trigger-icon"></span>
                  </button>
                </div>`
              : ''
            }
            <div class="w-room-list-item__room-selection">
              ${d.locales && d.locales.selectRoomButtonText
                ? BtnTemplate({
                  extraClasses: 'w-room-list-item__room-selection--button',
                  variant: 'trust',
                  jsApi: true,
                  text: d.locales.selectRoomButtonText,
                  attributes: {
                    'data-room-contract-id': d.contractId,
                    'data-room-id': d.id,
                    'data-occupancy-id': d.occupancyId,
                    'data-package-id': d.packageId
                  }
                })
              : ''}
            </div>
          </div>
          ${d.characteristics
            ? `${CollapseTemplate({
              ...defaultCollapseData,
              ...{
                id: `${d.id}-${d.roomNumber}`,
                track: dataRoomtypeCollapseListener,
                attributes: {
                  'data-roomtype-title': d.title
                },
                html: GetRoomCharacteristicsHtml(d)
              }
            })}`
            : ''
          }
        </div>`
    : ''}
</div>
    `
}

const GetRoomCharacteristicsHtml = (d) => {
  let html = ''
  if (d.images) {
    html = PhotoGalleryTemplate({ popupTitle: d.title, elements: d.images })
  }
  return `${html}
  <div class="w-room-list-item__characteristics-content sp-stack-children--medium ${getTokenClass('characteristicsContentLayout', d.characteristicsContentLayout, tokens)}">
    <ul class="sp-stack-children--tiny">
      ${d.characteristics.map((item) => `<li class="m-body--small">${item}</li>`).join('')}
    </ul>
  </div>
`
}

import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import { BookingParticipantSummaryTemplate } from './w-booking-participant-summary.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'

const widgetApi = 'w-booking-participant-summary'

/**
 * BookingParticipantSummary widget
 */
export default class BookingParticipantSummary extends BookingBox {
  /**
   * Creates a new Booking Participant Summary widget.
   *
   * @constructor
   *
   * @param {HTMLElement} element   - The HTML content.
   * @param {Object} [options={}]   - Options.
   */
  constructor (element, options = {}) {
    super(element)
  }

  /**
   * This is the command executed when the mediator if going to do a call to the backend.
   *
   * @param {Object} data           - Contains the data necessary to render the body of the widget.
   */
  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  /**
   * Hydrates the Booking Participant Summary template with the data, and renders it.
   *
   * @private
   */
  _updateWidgetData () {
    const widgetData = this.data.summaryComponents && this.data.summaryComponents.find(i => i.componentId === this.componentId)
    if (widgetData) {
      const widgetDataTranslated = this._processData(widgetData)
      const renderedHtml = BookingParticipantSummaryTemplate(widgetDataTranslated)
      const content = elementFromString(renderedHtml)
      this._render(content)
      Component.initDocumentComponentsFromAPI(content)
    } else {
      super.hideComponent()
    }
  }

  /**
   * Renders the content into the widget body.
   *
   * @param content                 - The content to be rendered into the widget body.
   * @private
   */
  _render (content) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(content)
  }

  _processData (data) {
    const modelTranslated = {
      id: data.componentId,
      seeMoreText: data.seeMoreText,
      seeLessText: data.seeLessText,
      participants: []
    }
    data.participants.forEach(p => {
      const newParticipant = {
        title: p.title,
        items: []
      }
      Object.entries(p).forEach(([key, value]) => {
        const concept = data[`${key}Text`]
        if (concept !== undefined) {
          newParticipant.items.push({
            concept,
            value
          })
        }
      })
      modelTranslated.participants.push(newParticipant)
    })
    return modelTranslated
  }
}

registerWidget(BookingParticipantSummary, widgetApi)

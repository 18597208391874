import { BtnTemplate } from '../../components/btn/c-btn.template'

/**
 * The PromotedPriceData contains all data needed to hydrate a PromotedPrice view
 *
 * @typedef  {Object}           PromotedPriceFallbackData
 *
 * @property {String}           title                       - Promoted price title
 * @property {String}           description                 - Promoted price description
 * @property {String}           buttonText                  - Promoted price text button
 * @property {Boolean}          [hasUiTest]                 - Flag to indicate if this component is subjected to a UI test.
 */
export const defaultPromotedPriceFallbackData = {
  title: '',
  description: '',
  buttonText: '',
  hasUiTest: false
}

export const PromotedPriceFallbackTemplate = (d) => {
  return promotedPriceFallbackTemplate({ ...d, isSticky: false })
}
export const PromotedPriceStickyFallbackTemplate = (d) => {
  return promotedPriceFallbackTemplate({ ...d, isSticky: true })
}

const promotedPriceFallbackTemplate = (d) => {
  d = { ...defaultPromotedPriceFallbackData, ...d }

  const className = d.isSticky ? 'w-promoted-price__sticky' : 'w-promoted-price__main'

  return `
    <div class="${className} w-promoted-price__fallback">
        ${d.title ? `<div class="w-promoted-price__fallback-title">${d.title}</div>` : ''}
        ${d.description ? `<div class="w-promoted-price__fallback-description">${d.description}</div>` : ''}
        ${BtnTemplate({
            variant: 'flow',
            block: true,
            text: d.buttonText,
            extraClasses: `w-promoted-price__btn ${d.hasUiTest ? 'qa-promoted-price-click-trigger' : ''}`
        })}
    </div>
  `
}

import { registerWidget } from '../../../js/core/widget/widget-directory'
import { getDataPrefixed } from '../../../js/document/html-helper'
import { apiCaller } from '../../../js/helpers/api-caller'
import { getSearchParamsFromUrl } from '../../../js/document/url'
import storage from '../../../js/document/web-storage'
import FormManager from './form-manager'
import { personalDetailsEditEvents } from '../../../js/document/event-types'
import registeredEvents from '../../../js/helpers/registered-events'
const EventEmitter = require('eventemitter3')

const widgetApi = 'w-personal-details-edit'
const widgetDataPrefix = 'wPersonalDetailsEdit__'

const widgetQueries = {
  configuration: `[data-${widgetApi}__configuration]`,
  formContainer: `[data-${widgetApi}__form-container]`,
  loaderFormContainer: `.${widgetApi}__loader-form-container`,
  saveButton: `[data-${widgetApi}__save-button]`,
  cancelButton: `[data-${widgetApi}__cancel-button]`,
  backButton: '[data-w-booking-back-button__button]',
  cancelModal: `[data-${widgetApi}__cancel-modal]`,
  externalEditWarning: `[data-${widgetApi}__warning-message]`,
  externalEditRequestSuccessWarning: `[data-${widgetApi}__warning-request-sent-message]`,
  externalEditModal: `[data-${widgetApi}__request-manual-edition__modal]`,
  externalSaveButton: `[data-${widgetApi}__request-manual-edition__save-button]`,
  externalEditSuccessModal: `[data-${widgetApi}-external__modal-success]`,
  loader: `[data-${widgetApi}__loader]`,
  saveUnexpectedErrorModal: `[data-${widgetApi}__save-unexpected-error-modal]`,
  saveCannotUpdateErrorModal: `[data-${widgetApi}__save-cannot-update-error-modal]`,
  mainBody: `[data-${widgetApi}__main-body]`,
  loadErrorMessage: `[data-${widgetApi}__load-error-message]`,
  trackAttr: 'data-track'
}

const queryParams = {
  bookingNumber: 'bookingNumber',
  participantId: 'participantId',
  participantType: 'type'
}

const bookingStateNotUpdatableCode = '503'

const options = {

}

/**
 * Personal Details Edit
 *
 */
export default class PersonalDetailsEdit {
  /**
   * Creates a new personal details edit.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    if (!element) { return }
    this.element = element
    this.options = {
      ...options,
      ...getDataPrefixed(this.element, widgetDataPrefix)
    }
    this.id = this.element.id

    try {
      this.elements = this._runElementQueries()
      this.apis = {}
      this.settings = this.getSettingsDataFromDom()
      this.context = this.getContextFromUrlOrOptions()
      if (!this.context.bookingNumber || !this.context.participantId || !this.context.participantType) {
        throw new Error('Not all context parameters defined (bookingNumber, participantId or participantType)')
      }
      this.cookie = storage.cookie
      this._attachEvents()
      this.formManager = new FormManager(this.elements.formContainer, {})
      this.fetchAndUpdate().then(() => {
        this.elements.loaderFormContainer.classList.add('is-hidden')
        Object.assign(this.elements, this._runModalQueries())
        this._attachModalEvents()
      }).catch(e => {
        this._logError(e)
        this._showLoadError()
      })
    } catch (err) {
      this._logError(err)
      this._showLoadError()
    }
    this.events = new EventEmitter()
    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.trackAttr) && { track: this.element.attributes[widgetQueries.trackAttr].value }
    })
  }

  _runElementQueries () {
    return {
      configuration: this.element.querySelector(widgetQueries.configuration),
      formContainer: this.element.querySelector(widgetQueries.formContainer),
      loaderFormContainer: this.element.querySelector(widgetQueries.loaderFormContainer),
      saveButton: this.element.querySelector(widgetQueries.saveButton),
      cancelButton: this.element.querySelector(widgetQueries.cancelButton),
      backButton: document.querySelector(widgetQueries.backButton),
      cancelModal: this.element.querySelector(widgetQueries.cancelModal),
      loader: this.element.querySelector(widgetQueries.loader),
      saveUnexpectedErrorModal: this.element.querySelector(widgetQueries.saveUnexpectedErrorModal),
      saveCannotUpdateErrorModal: this.element.querySelector(widgetQueries.saveCannotUpdateErrorModal),
      mainBody: this.element.querySelector(widgetQueries.mainBody),
      loadErrorMessage: this.element.querySelector(widgetQueries.loadErrorMessage)
    }
  }

  _runModalQueries () {
    return {
      externalSaveButton: this.element.querySelector(widgetQueries.externalSaveButton),
      externalEditModal: this.element.querySelector(widgetQueries.externalEditModal),
      externalEditSuccessModal: this.element.querySelector(widgetQueries.externalEditSuccessModal),
      externalEditWarning: this.element.querySelector(widgetQueries.externalEditWarning),
      externalEditRequestSuccessWarning: this.element.querySelector(widgetQueries.externalEditRequestSuccessWarning)
    }
  }

  _attachEvents () {
    this.apis = {}
    if (this.elements.saveButton) {
      this.elements.saveButton.addEventListener('click', this._clickConfirmButton.bind(this))
      this.apis.saveButton = this.elements.saveButton['c-btn']
    }

    if (this.elements.cancelButton) {
      this.elements.cancelButton.addEventListener('click', this._goBackWithWarning.bind(this))
    }

    if (this.elements.backButton) {
      this.elements.backButton.addEventListener('click', this._goBackWithWarning.bind(this))
    }

    if (this.elements.cancelModal) {
      this.apis.cancelModal = this.elements.cancelModal['c-modal']
    }

    if (this.elements.saveUnexpectedErrorModal) {
      this.apis.saveUnexpectedErrorModal = this.elements.saveUnexpectedErrorModal['c-modal']
    }

    if (this.elements.saveCannotUpdateErrorModal) {
      this.apis.saveCannotUpdateErrorModal = this.elements.saveCannotUpdateErrorModal['c-modal']
    }
  }

  _attachModalEvents () {
    if (this.elements.externalSaveButton) {
      this.elements.externalSaveButton.addEventListener('click', this._clickExternalConfirmButton.bind(this))
      this.apis.externalSaveButton = this.elements.externalSaveButton['c-btn']
    }
    if (this.elements.externalEditModal) {
      this.apis.externalEditModal = this.elements.externalEditModal['c-modal']
      this.apis.externalEditModal.events.on('opened', this._emitTrackingEvent.bind(
        this,
        personalDetailsEditEvents.PERSONAL_DETAILS_EDIT_EXTERNAL_FLIGHTS_FORM_OPEN,
        null,
        this.context.bookingNumber
      ))
    }

    if (this.elements.externalEditSuccessModal) {
      this.apis.externalEditSuccessModal = this.elements.externalEditSuccessModal['c-modal']
    }

    if (this.elements.externalEditWarning && this.elements.externalEditRequestSuccessWarning) {
      this.apis.externalEditWarning = this.elements.externalEditWarning
      this.apis.externalEditRequestSuccessWarning = this.elements.externalEditRequestSuccessWarning
    }
  }

  _clickConfirmButton (ev) {
    ev.preventDefault()
    this._sendForm()
  }

  _goBackWithWarning (ev) {
    ev.preventDefault()
    if (this.formManager.getLabelsOfFormDataChanged() && this.apis.cancelModal) {
      this.apis.cancelModal.open()
    } else {
      window.location.href = ev.currentTarget.href
    }
  }

  _clickExternalConfirmButton (ev) {
    ev.preventDefault()
    this._sendExternalForm()
  }

  async _sendForm () {
    if (!this.fetched) {
      return
    }
    if (this.formManager.validate()) {
      return
    }
    this._setLoadingState(true)

    const data = this.formManager.getFormData()

    const postUrl = this._prepareUrlWithContext(this.options.saveUrl)
    const result = await apiCaller(postUrl, { method: 'POST', body: data, timeout: 60000 })
    this._emitUpdatedFormTrackingEvent()
    this._setLoadingState(false)
    this._renderResponse(result)
  }

  async _sendExternalForm () {
    if (!this.fetched) {
      return
    }
    if (this.formManager.validateExternalForm()) {
      return
    }
    this.apis.externalEditModal.close()
    this._setLoadingState(true)

    const data = this.formManager.getExternalEditableFormData()

    const postUrl = this._prepareUrlWithContext(this.settings.requestManualEditionSettings.saveUrl)
    const result = await apiCaller(postUrl, { method: 'POST', body: data, timeout: 60000 })
    this._emitUpdatedFormTrackingEvent(true)
    this._setLoadingState(false)
    this._renderExternalResponse(result)
  }

  _setLoadingState (isLoading) {
    this.apis.saveButton && this.apis.saveButton.setProps({ disabled: isLoading, loading: isLoading })
    if (this.elements.loader) {
      this.elements.loader.classList[!isLoading ? 'add' : 'remove']('is-hidden')
    }
  }

  _renderResponse (result) {
    if (result.success) {
      if (this.options.backUrl) {
        // set flag to show popup
        this.cookie.set('participant-edit-saved', '1', {
          path: '/',
          expirationDays: parseInt(1)
        })
        // redirect
        window.location.href = this._prepareUrlWithContext(this.options.backUrl)
      }
    } else {
      this._handleError(result)
    }
  }

  _renderExternalResponse (result) {
    if (result.success) {
      this.apis.externalEditSuccessModal.open()
      this._showRequestSentWarning()
    } else {
      this._handleError(result)
    }
  }

  _showRequestSentWarning () {
    if (this.apis.externalEditRequestSuccessWarning && this.apis.externalEditWarning) {
      this.apis.externalEditRequestSuccessWarning.classList.remove('is-hidden')
      this.apis.externalEditWarning.classList.add('is-hidden')
    }
  }

  _handleError (result) {
    if (result.error === 'timeout') {
      const err = new Error('POST request to save data timeout')
      this._logError(err)
    }
    const specificErrors = this._getApiSpecificErrors(result)
    const cannotUpdateBooking = specificErrors.some(el => el.code === bookingStateNotUpdatableCode)
    if (cannotUpdateBooking) {
      if (this.apis.saveCannotUpdateErrorModal) {
        this.apis.saveCannotUpdateErrorModal.open()
      }
    } else if (this.apis.saveUnexpectedErrorModal) {
      this.apis.saveUnexpectedErrorModal.open()
    }
  }

  _getApiSpecificErrors (result) {
    if (result.response) {
      if (result.response.errors) {
        return result.response.errors
      } else if (result.response.apiResponse && result.response.apiResponse.errors) {
        return result.response.apiResponse.errors
      }
    }
    return []
  }

  async fetchAndUpdate () {
    if (this.options.url) {
      const getUrl = this._prepareUrlWithContext(this.options.url)
      const result = await apiCaller(getUrl, { method: 'GET' })
      if (result.success) {
        const data = result.response.apiResponse
        const formSettings = this.settings.forms
          .filter(f => f.type === this.context.participantType)
          .shift()
        this.formManager.render(this.id, data, formSettings, this.settings)
        this.fetched = true
        this._setLoadingState(false)
      } else {
        if (result.error === 'timeout') {
          const err = new Error('GET request to get data to build form timeout')
          this._logError(err)
        }
        this._showLoadError()
      }
    }
  }

  _prepareUrlWithContext (url) {
    return url
      .replace(/{bookingnumber}/ig, this.context.bookingNumber)
      .replace(/{participantid}/ig, this.context.participantId)
  }

  getSettingsDataFromDom () {
    const element = this.elements.configuration
    if (!element) {
      throw new Error('Configuration element should exist for settings in DOM')
    }
    return JSON.parse(element.innerText)
  }

  getContextFromUrlOrOptions () {
    const parameters = getSearchParamsFromUrl(document.location)
    const context = {
      bookingNumber: parameters && parameters[queryParams.bookingNumber] ? parseInt(parameters[queryParams.bookingNumber]) : this.options.contextBookingNumber,
      participantId: parameters && parameters[queryParams.participantId] ? parseInt(parameters[queryParams.participantId]) : this.options.contextParticipantId,
      participantType: parameters && parameters[queryParams.participantType] ? parameters[queryParams.participantType] : this.options.contextType
    }
    return context
  }

  _showLoadError () {
    this._setLoadingState(false)
    if (this.elements.loadErrorMessage) {
      this.elements.loadErrorMessage.classList.remove('is-hidden')
    }
    if (this.elements.mainBody) {
      this.elements.mainBody.classList.add('is-hidden')
    }
  }

  _logError (err) {
    if (window.newrelic) {
      window.newrelic.noticeError(err)
    }
  }

  _emitUpdatedFormTrackingEvent (isExternal) {
    if (isExternal && this.formManager.getLabelsOfFormDataChanged(true)) {
      this._emitTrackingEvent(
        personalDetailsEditEvents.PERSONAL_DETAILS_EDIT_EXTERNAL_FLIGHTS_FORM_UPDATED,
        this.formManager.getLabelsOfFormDataChanged(true),
        this.context.bookingNumber
      )
    }
    if (this.formManager.getLabelsOfFormDataChanged()) {
      this._emitTrackingEvent(
        personalDetailsEditEvents.PERSONAL_DETAILS_EDIT_UPDATED,
        this.formManager.getLabelsOfFormDataChanged(),
        this.context.bookingNumber
      )
    }
  }

  _emitTrackingEvent (event, labels, bookingNumber) {
    if (!bookingNumber || !event) return
    const labelsObj = labels ? { labels } : {}
    this.events.emit(event, {
      ...labelsObj,
      bookingNumber
    })
  }
}

registerWidget(PersonalDetailsEdit, widgetApi)

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingUsps from '../../../../shared/modules/widgets/booking-usps/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const data = require('../../../../shared/modules/widgets/booking-usps/data/w-booking-usps__js-template.json')
const dataAsBlock = require('../../../../shared/modules/widgets/booking-usps/data/w-booking-usps__block-js-template.json')

loadQueueHelper.add(function () {
  initBookingUsps()
}, 2)

const initBookingUsps = async () => {
  const bookingUspsItems = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-usps"]')

  bookingUspsItems.forEach(item => {
    const bookingUspsElement = new BookingUsps(item)
    console.log(bookingUspsElement)
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingUspsJsTemplate = document.querySelectorAll('[data-booking-usps-demo]')
  handleFetched(bookingUspsJsTemplate, data)

  const bookingUspsBlockJsTemplate = document.querySelectorAll('[data-booking-usps-block-demo]')
  handleFetched(bookingUspsBlockJsTemplate, dataAsBlock)
}

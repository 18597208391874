/**
 * Iframe Resize
 */
const resizeIframe = {
  /**
   * @param height - The Height of iframe element
   * @param iframe - The iframe element
   * @param isIframe - Check if parent window is an iframe
   */
  height: 0,
  iframe: null,
  isIframe: window.self !== window.top,

  /**
   * Set the iframe height every 60 ms if its same domain, else will post message to window with iframe height
   */

  setIframeHeight () {
    setInterval(function () {
      this.height = document.body.scrollHeight
      window.top.postMessage(this.height, '*')
    }, 60)
  },

  /**
   * Initialize Iframe Resize and check if iframe exist
   */

  init () {
    if (this.isIframe) {
      this.setIframeHeight()
    }
  }
}
resizeIframe.init()

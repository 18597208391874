import { FILTER_TYPES } from '../../../js/data/filters/config'
import AirportFilter from '../airport-filter/main'
import DropdownFilter from '../dropdown-filter/main'
import DropdownMultipleFilter from '../dropdown-multiple-filter/main'
import ParticipantsFilter from '../participants-filter/main'
import TabsFilter from '../tabs-filter/main'

export const WIDGET_API = 'w-booking-gate'

export const ELEMENT_QUERIES = {
  apiDataList: `[data-${WIDGET_API}__apis]`,
  idsDataList: `[data-${WIDGET_API}__ids]`,
  extraParams: `[data-${WIDGET_API}__extra-params]`,
  filtersElement: `.${WIDGET_API}__filters`,
  filterElements: (id) => `[data-w-filters__id="${id}"]`,
  filtersExceptParticipantsElements: '[data-w-filters__view]:not([data-w-filters__view="participants"])',
  filterViewAttr: 'data-w-filters__view',
  filterNameAttr: 'data-w-filters__name',
  priceTableMessages: `.${WIDGET_API}__price-table-messages`,
  priceTableContainer: `.${WIDGET_API}__price-table-container`,
  noResultsElement: `.${WIDGET_API}__no-results`,
  errorElement: `.${WIDGET_API}__error`,
  chipWrapperElement: `.${WIDGET_API}__alternatives-chip-wrapper`,
  chipElement: '.c-chip',
  roomSelectorElement: `.${WIDGET_API}__room-selector`,
  flightBusSelectorElement: `.${WIDGET_API}__flight-bus-selector`,
  selectedStateElement: `.${WIDGET_API}__selected-state`,
  bookingItemCloseElement: `.${WIDGET_API}__item-close`,
  promotedPriceElement: '.w-promoted-price',
  errorModal: `.${WIDGET_API}__error-modal`,
  errorModalButton: `.${WIDGET_API}__error-modal-button`,
  outOfSeasonModal: `.${WIDGET_API}__out-of-season-modal`,
  outOfSeasonModalButton: `.${WIDGET_API}__out-of-season-modal-button`,
  outOfSeasonModalButtonText: 'data-w-subscribe-form-modal__button-text',
  outOfSeasonModalId: 'data-w-subscribe-form-modal__id',
  startBookingErrorModal: `.${WIDGET_API}__start-booking-error-modal`,
  startBookingErrorModalButton: `.${WIDGET_API}__start-booking-error-modal-button`,
  openJawValidationModal: `.${WIDGET_API}__open-jaw-validation-modal`,
  openJawValidationModalButton: `.${WIDGET_API}__open-jaw-validation-modal__submit-button`,
  openJawValidationModalCancelButton: `.${WIDGET_API}__open-jaw-validation-modal__cancel-button`,
  openJawValidationModalBody: `.${WIDGET_API}__open-jaw-validation-modal .c-modal__body`,
  departureAirportFilter: '[data-w-filters__name="DepartureAirport"]',
  arrivalAirportFilter: '[data-w-filters__name="ArrivalAirport"]',
  roomTypeFilter: '[data-w-filters__name="RoomType"]',
  localesData: (id) => `[data-${WIDGET_API}__data][data-${WIDGET_API}__id="${id}"]`,
  trackAttr: 'data-track',
  modalApi: 'c-modal'
}

/**
 * ⚠ Filter names are different on this API response than other implementations and expected params 👏, fixed here
 *
 * - Keys are the facets received from API
 * - Values are the facets expected to:
 *   - Be sent as params
 *   - Reflected to URL
 *   - Identified on DOM filter elements
 */
export const CORRECT_FILTER_NAMES = {
  participants: FILTER_TYPES.PARTICIPANTS,
  departureAirports: FILTER_TYPES.DEPARTURE_AIRPORT,
  arrivalAirports: FILTER_TYPES.ARRIVAL_AIRPORT,
  durations: FILTER_TYPES.DURATION,
  mealplans: FILTER_TYPES.MEALPLAN,
  months: FILTER_TYPES.MONTH,
  transportTypes: FILTER_TYPES.TRANSPORT_TYPE,
  departureDate: FILTER_TYPES.DEPARTURE_DATE,
  allocation: FILTER_TYPES.ALLOCATION,
  roomTypes: FILTER_TYPES.ROOM_TYPE
}

/**
 * Filter view types are defined in HTML attributes
 * This associates those attributes with ClassNames in order to instantiate it
 */
export const KNOWN_FILTER_VIEWS = {
  airport: AirportFilter,
  dropdown: DropdownFilter,
  'dropdown-multiple': DropdownMultipleFilter,
  participants: ParticipantsFilter,
  tabs: TabsFilter
}

export const HIDDEN_CLASSNAME = 'is-hidden'

export const NEXT_SEASON_ITEM_TEMPLATE = (d) => `
<ul class="c-tabs__nav-list c-tabs__nav-list--featured o-list-bare o-list-inline">
  <li class="c-tabs__nav-item c-tabs__nav-item--featured">
    <button class="c-btn c-btn--flat c-tabs__nav-button w-booking-gate__out-of-season-modal-button" data-c-modal__id="${d.modalId}" data-c-modal__action="open">
      ${d.label}
    </button>
  </li>
</ul>
`

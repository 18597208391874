import { fetchJsonData } from '../../../js/helpers/json-fetch'
import { getUrlFromString } from '../../../js/document/url'
import { CarRentalItemTemplate, CarRentalNotificationTemplate } from './w-car-rental-item.template'
import Component from '../../../js/core/component/component'
import { register } from '../../../js/document/namespace'
import { language } from '../../../js/user/locale-settings'
import { observerAPI, documentObserver } from '../../../js/document/intersector'
import { registerWidget } from '../../../js/core/widget/widget-directory'

require('../../components/collapse/main')

const widgetApi = 'w-car-rental'
const globalLocales = register(`window.sundio.i18n.${language}.global`)
const componentLocales = register(`window.sundio.i18n.${language}.carRental`)

const classes = {
  isLoading: 'is-loading'
}

const widgetQueries = {
  url: `data-${widgetApi}__url`,
  extraParams: 'input[type="hidden"]',
  itemsElement: `.${widgetApi}__items`
}

export default class CarRental {
  /**
   * Creates a new CarRental
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element
   */
  constructor (element) {
    this.element = element
    this.itemsElement = this.element.querySelector(widgetQueries.itemsElement)

    this.url = this.element.getAttribute(widgetQueries.url)
    this.extraParams = this.getExtraParameters()
    this.locales = this.getLocales()

    this.element.classList.add(classes.isLoading)

    const observer = documentObserver()
    observer.observe(this.element)
    this.element[observerAPI].events.on('enter', () => {
      this.getData()
        .then(freshData => this.render(freshData))
        .then(() => this.element.classList.remove(classes.isLoading))
        .catch(e => console.error(e))
      observer.unobserve(this.element)
    })
  }

  /**
   * Fetches data from the API and returns carRentalItems
   *
   * @return {Object[]} carRentalItems
   */
  async getData () {
    const fetchUrl = getUrlFromString(this.url, this.extraParams)
    return fetchJsonData(fetchUrl).then((result) => {
      return result.data[0].cars
    })
  }

  /**
   * Renders a car rental items
   *
   * @param {Object[]} carRentalItems - Total results of the fetch
   */
  async render (carRentalItems) {
    if (carRentalItems.length === 0) {
      this.itemsElement.innerHTML = CarRentalNotificationTemplate(this.locales)
    } else {
      this.itemsElement.innerHTML = carRentalItems.map((item, index) => {
        return CarRentalItemTemplate(item, index, this.locales)
      }).join('')
      Component.initDocumentComponentsFromAPI(this.element)
    }
  }

  /**
   * Return a locale strings object
   *
   * @returns {Object} - returns the locales (static texts)
   *
   */
  getLocales () {
    const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.element.id}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) {}

    return {
      ...globalLocales,
      ...componentLocales,
      ...(customLocaleData || {})
    }
  }

  /**
   * Returns the extra parameters that are exposed in the DOM
   *
   * @returns {object} - Returns the extra parameters object
   */
  getExtraParameters () {
    const extraParamsElements = this.element.querySelectorAll(widgetQueries.extraParams)
    return extraParamsElements
      ? [...extraParamsElements].reduce((obj, el) => {
          obj[el.name] = el.value
          return obj
        }, {})
      : undefined
  }
}

registerWidget(CarRental, widgetApi)

/**
 * The PaymentFormTemplate contains all the data needed to hydrate a PaymentFormTemplate component
 *
 * @typedef {Object}      PaymentFormTemplate
 *
 * @property {List}     dataForOgone            - contains all the data to be able to recover the form used to enter to Ogone host window
 */

export const PaymentFormTemplate = (d) => {
  if (Object.keys(d).length === 0) {
    return ''
  }
  return `<form method="post" action="${d.ogoneurl}" id="paymentParametersForm" data-js-component="w-payment__form-hidden">
            <div id="payment-parameters">
                    <input type="hidden" name="TP" value="${d.tp}">
                    <input type="hidden" name="PSPID" value="${d.pspid}">
                    <input type="hidden" name="PM" value="${d.pm}">
                    <input type="hidden" name="ORDERID" value="${d.orderid}">
                    <input type="hidden" name="LOGO" value="${d.logo}">
                    <input type="hidden" name="LANGUAGE" value="${d.language}">
                    <input type="hidden" name="HOMEURL" value="${d.homeurl}">
                    <input type="hidden" name="EXCEPTIONURL" value="${d.exceptionurl}">
                    <input type="hidden" name="DECLINEURL" value="${d.declineurl}">
                    <input type="hidden" name="CURRENCY" value="${d.currency}">
                    <input type="hidden" name="COMPLUS" value="${d.complus}">
                    <input type="hidden" name="CATALOGURL" value="${d.catalogurl}">
                    <input type="hidden" name="CANCELURL" value="${d.cancelurl}">
                    <input type="hidden" name="BRAND" value="${d.brand}">
                    <input type="hidden" name="AMOUNT" value="${d.amount}">
                    <input type="hidden" name="ALIASUSAGE" value="${d.aliasusage}">
                    <input type="hidden" name="TITLE" value="">
                    <input type="hidden" name="ALIAS" value="${d.alias}">
                    <input type="hidden" name="ACCEPTURL" value="${d.accepturl}">
                    <input type="hidden" name="SHASIGN" value="${d.shasign}">
            </div>
        </form>`
}

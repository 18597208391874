import { fromCamelCase } from '../../../js/helpers/string'

/**
 * The SideDrawerData contains all data needed to hydrate a Side Drawer selector view
 *
 * @typedef {Object}          SideDrawerData
 *
 * @property {String}         id                  - The item's id
 * @property {String}         origin              - Which side the side drawer will appear from
 * @property {String}         until               - Breakpoint where sidedrawer starts to be shown
 * @property {String}         extraClasses
 * @property {String}         html                - HTML snippet
 * @property {String}         closable            - If true, it will be hidden at some breakpoint
 */

export const defaultSideDrawerData = {
  id: '',
  origin: 'right',
  until: '',
  extraClasses: '',
  html: '',
  closable: true
}

export const SideDrawerTemplate = (d) => {
  d = { ...defaultSideDrawerData, ...d }
  return `
<div ${d.id ? `id="${d.id}"` : ''}
  class="c-side-drawer c-side-drawer--origin-${d.origin} sp-stack-children--small ${d.until ? `c-side-drawer--until@${d.until}` : ''} ${d.extraClasses}"
     ` + // FIXME {{get-token-class @root.ds-tokens-components "c-side-drawer.space-stack-children" "default"}}
 `data-js-component="c-side-drawer"
  data-c-side-drawer__origin="${d.origin}"
  ${d.until ? `data-c-side-drawer__until="${d.until}"` : ''}
  ${d.attributes ? Object.keys(d.attributes).map(k => `${fromCamelCase(k)}="${d.attributes[k]}"`).join(' ') : ''}>
  ${d.html}
</div>
`
}

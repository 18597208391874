import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import JSONFetcher from '../../../../shared/js/helpers/json-fetcher'
import FilterModel from '../../../../shared/js/data/filters/filter-model'
import DropdownMultipleFilter from '../../../../shared/modules/widgets/dropdown-multiple-filter/main'

loadQueueHelper.add(function () {
  initDropdownMultipleFilter()
}, 2)

const initDropdownMultipleFilter = () => {
  const filterComponents = document.querySelectorAll('[data-w-filters__view="dropdown-multiple"]')
  if (!filterComponents || filterComponents.length === 0) return

  console.log('Found ' + filterComponents.length + ' multiple dropdowns.', filterComponents)

  const filtersRequest = new JSONFetcher({ src: '../assets/api/price-table-filters.json' })
  filtersRequest
    .fetch()
    .then(data => {
      filterComponents.forEach(filterComponent => {
        const filterName = filterComponent.getAttribute('data-w-filters__name')
        const apiFilterData = data[filterName] || []
        const filterData = {
          type: filterName,
          isMultiselectable: true,
          parentFilterType: 'Unknown',
          values: apiFilterData.map(filter => ({
            isSelected: false,
            isAvailable: true,
            caption: filter.text,
            value: filter.value,
            count: filter.count,
            groupInfo: ''
          }))
        }
        const filterModel = new FilterModel(filterData)

        console.log('DropdownMultiple Data Model initialized', filterModel)

        const dropdownMultipleFilter = new DropdownMultipleFilter(filterComponent, filterModel)
        console.log('DropdownMultiple initialized', dropdownMultipleFilter)
      })
    })
}

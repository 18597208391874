import * as loadQueueHelper from '../../../../shared/js/document/load-queue'

const bookingParticipantsFormData = require('../../../assets/api/booking-participants-form.json')
const bookingParticipantsFormEmptyData = require('../../../assets/api/booking-participants-form-empty.json')

loadQueueHelper.add(function () {
  initBookingParticipantsForm()
}, 2)

const initBookingParticipantsForm = () => {
  const items = document.querySelectorAll('[data-booking-participants-form-demo] [data-js-api--w-booking-item="w-booking-participants-form"]')
  const itemsCollapsed = document.querySelectorAll('[data-booking-participants-form-collapsed-demo] [data-js-api--w-booking-item="w-booking-participants-form"]')
  handleFetched(items, bookingParticipantsFormData)
  handleFetched(itemsCollapsed, bookingParticipantsFormEmptyData)

  document.addEventListener('razor-load-complete', function () {
    const items = document.querySelectorAll('[data-razor-url] [data-booking-participants-form-demo] [data-js-api--w-booking-item="w-booking-participants-form"]')
    const itemsCollapsed = document.querySelectorAll('[data-razor-url] [data-booking-participants-form-collapsed-demo] [data-js-api--w-booking-item="w-booking-participants-form"]')
    handleFetched(items, bookingParticipantsFormData)
    handleFetched(itemsCollapsed, bookingParticipantsFormEmptyData)
  })
}

const handleFetched = (items, data) => {
  setTimeout(() => {
    const handleFetchedPromises = Array.from(items).map(element => {
      return element['w-booking-item'].handleFetched({ response: data })
    })
    handleFetchedPromises.forEach(promise => promise.then(resolvedItem => { resolvedItem.enableUI() }))
  }, 1500)
}

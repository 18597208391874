import BookingItem from '../booking-item/main'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { elementFromString, flush } from '../../../js/document/html-helper'
import { ContentUspsTemplate } from '../content-usps/w-content-usps.template'

const widgetApi = 'w-booking-usps'

const attr = {
  shownAsBlock: `data-${widgetApi}__shown-as-block`
}

const defaultUspsData = {
  showHorizontally: true,
  useIconSecondaryColor: true
}

const classList = {
  shownAsBlock: 'w-booking-usps__shown-as-block'
}

export default class BookingUsps extends BookingItem {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   */
  constructor (element) {
    super(element)
    this.element = element
    this.shownAsBlock = this.element.attributes[attr.shownAsBlock] || false
  }

  /**
   *
   * @param {Object}    data      - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  _updateWidgetData () {
    this.apiData = this.data.bookingUsps && this.data.bookingUsps.find(bookingUsp => bookingUsp.componentId === this.componentId)

    if (this.apiData) {
      super.showComponent()
      this._init(this.apiData)
    } else {
      super.hideComponent()
    }
  }

  _init (data) {
    const extraClassesUsps = this.shownAsBlock ? classList.shownAsBlock : ''
    const renderedHtml = ContentUspsTemplate({ ...defaultUspsData, ...data, useUspBlock: this.shownAsBlock, extraClasses: extraClassesUsps })
    const newContent = elementFromString(renderedHtml)
    this._render(newContent)
  }

  _render (el) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(el)
  }
}

registerWidget(BookingUsps, widgetApi)

/**
 * A mapper to convert from "Sitecore Api response" to "internal widget model".
 * An example of the internal widget model is:
 * "messages": [
 * {
 *     "type": "info",
 * "title" : "Watch out"
 *     "texts": [
 *       "1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
 *       "2. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
 *      ]
 * }]
 */
export default class BookingMessageDataMapper {
  mapComponent (apiComponent) {
    if (apiComponent && apiComponent.messages) {
      const widgetComponent = {
        messages: apiComponent.messages.map(message =>
          this._mapMessage(message, apiComponent)
        )
      }
      return widgetComponent
    }
    return {}
  }

  _mapMessage (apiMessage) {
    const type = apiMessage.type ? apiMessage.type.toLowerCase() : 'info'
    const result = {
      type,
      title: apiMessage.title,
      texts: [apiMessage.text]
    }
    return result
  }
}

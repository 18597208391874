import { BookingBox } from '../booking-box/main'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { bookingItemEvents } from '../../../js/document/event-types'

const attr = {
  buttonText: 'data-w-booking-coupon-code--button-text',
  buttonSendingText: 'data-w-booking-coupon-code--button-sending-text'
}

const widgetApi = 'w-booking-coupon-code'

const classNames = {
  codeAccepted: 'w-booking-coupon-code__state-accepted',
  codeRejected: 'w-booking-coupon-code__state-rejected'
}

const widgetQueries = {
  fieldInput: `[data-${widgetApi}--field-input]`,
  button: `[data-${widgetApi}--button-send]`
}

/**
 * BookingCouponCode widget
 */
export default class BookingCouponCode extends BookingBox {
  /**
     *
     * @param {HTMLElement} element   - The HTML content
     */
  constructor (element) {
    super(element)
    this.element = element

    this._attachEvents()
    this._setStateNormal()
  }

  _attachEvents () {
    this.fieldInput = this.element.querySelector(widgetQueries.fieldInput)
    if (this.fieldInput) {
      this.fieldInputApi = this.fieldInput['c-textbox']
    }

    this.sendButton = this.element.querySelector(widgetQueries.button)
    if (this.sendButton) {
      this.sendButtonApi = this.sendButton['c-btn']
      this.sendButton.addEventListener('click', (ev) => this._clickSendButton(ev))
    }
  }

  async handleFetched (data) {
    this.data = data.response
    this._setStateNormal()
    const couponResponse = this._getCouponCodeResponse(this.data)
    if (couponResponse) {
      if (this._isCouponCodeSuccess(couponResponse)) {
        this._setStateSuccess()
      } else if (this._isCouponCodeError(couponResponse)) {
        this._setStateError()
      }
    }

    return super.handleFetched(data.response)
  }

  _clickSendButton (ev) {
    if (this.fieldInputApi) {
      const code = this.fieldInputApi.getProp('value') ? this.fieldInputApi.getProp('value').trim() : ''
      if (code !== '') {
        this._setStateSending()
        const eventArgs = {
          method: this._method,
          url: this._url,
          params: {
            couponCode: code
          }
        }

        this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
      }
    }
  }

  _setStateNormal () {
    if (this.fieldInputApi) {
      this.fieldInputApi.setProp('state', '')
    }
    if (this.sendButtonApi) {
      this.sendButtonApi.setProp('loading', false)
      this.sendButtonApi.setProp('text', this.sendButton.getAttribute(attr.buttonText))
    }
  }

  _setStateSending () {
    if (this.fieldInputApi) {
      this.fieldInputApi.setProp('state', '')
    }
    if (this.sendButtonApi) {
      this.sendButtonApi.setProp('loading', true)
      this.sendButtonApi.setProp('text', this.sendButton.getAttribute(attr.buttonSendingText))
    }
  }

  _setStateSuccess () {
    if (this.fieldInputApi) {
      this.fieldInputApi.setProp('state', 'success')
    }
    this._setStatus(classNames.codeAccepted)
    if (this.data && this.fieldInputApi) {
      if (this.data.bookingInfo && this.data.bookingInfo.couponInfo) {
        const code = this.data.bookingInfo.couponInfo.couponCode
        this.fieldInputApi.setProp('value', code)
      }
    }
  }

  _setStateError () {
    if (this.fieldInputApi) {
      this.fieldInputApi.setProp('state', 'error')
      this.fieldInputApi.setProp('value', '')
    }
    this._setStatus(classNames.codeRejected)
  }

  _setStatus (newStatus) {
    const allStatuses = [classNames.codeAccepted, classNames.codeRejected]
    allStatuses.forEach(status => {
      if (status !== newStatus) {
        this.element.classList.remove(status)
      }
    })
    this.element.classList.add(newStatus)
  }

  _getCouponCodeResponse (data) {
    let response
    if (data && data.consequences) {
      response = data.consequences.couponcodeSuccess
    }
    return response
  }

  _isCouponCodeSuccess (response) {
    const isSuccess = (response === 'true')
    return isSuccess
  }

  _isCouponCodeError (response) {
    const isError = (response === 'false')
    return isError
  }
}

registerWidget(BookingCouponCode, widgetApi)

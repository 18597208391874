import PriceTableDataModel from './price-table-data-model'
import { EventEmitter } from 'eventemitter3'
import { elementFromString, flush, isTargetBefore, moveChildrenFrom } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import { PriceTableTemplate } from './c-price-table.template'

const componentAPI = 'c-price-table'

const componentQueries = {
  navWrapperElement: `.${componentAPI}__navigation-wrapper`,
  prevElement: `.${componentAPI}__navigation--left`,
  nextElement: `.${componentAPI}__navigation--right`,
  priceElement: `.${componentAPI}__price`,
  headerCell: `.${componentAPI}__cell--date`,
  priceCell: `.${componentAPI}__cell--price`,
  priceRow: `.${componentAPI}__row:not(.c-price-table__row--heading)`,
  isHighlightedClassName: 'is-highlighted',
  isActiveClassName: 'is-active'
}

const defaults = {
  prevMonth: true,
  nextMonth: true,
  origin: 'start',
  data: {}
}

/**
 * PriceTable content
 *
 */
export default class PriceTable {
  /**
   * Creates a new PriceTable behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   * @param {Object} [options={}] - Options object
   * @param {PriceTableApiData} [options.data] - Price table info (departures, packages, occupancies...)
   * @param {Boolean} [options.prevMonth|nextMonth] - Enable or disable arrow when there is no other Price Table to be created
   * @param {String} [options.origin] - Checks if table needs to be created showing the first or the last dates (month-wide navigation)
   */
  constructor (element, options = {}) {
    this.element = element
    this.options = {
      ...defaults,
      ...options
    }
    this.events = (this.element[componentAPI] && this.element[componentAPI].events) || new EventEmitter()

    this._init()

    // Expose component public API
    this.element[componentAPI] = {
      refresh: this.refresh.bind(this),
      updateArrows: this.updateArrows.bind(this),
      toggleNavVisibility: this.toggleNavVisibility.bind(this),
      events: this.events,
      priceTableData: this.priceTableData,
      prev: (this.prev).bind(this),
      next: (this.next).bind(this),
      goTo: (this.goTo).bind(this),
      move: (this.move).bind(this)
    }
  }

  /**
   * Initializes a priceTable
   *
   * @param {PriceTableApiData} [data] - Options object
   */
  _init (data = this.options.data) {
    const hasData = (data && Object.keys(data).length > 0)
    if (this.element[componentAPI] && this.element[componentAPI].priceTableDataModel) {
      delete this.element[componentAPI].priceTableDataModel
    }
    flush(this.element)

    if (!hasData) return

    this._createHtml()
    this._initElements()
    this._addListeners()
    if (this.options.origin === 'end') {
      this.move(1, 'last', { silent: true })
    }
    this.updateArrows()
  }

  /**
   * Shows the previous dates of the month (if if none, fires event 'prevEdge'
   *
   * @param {Object} [options] - Options object
   * @param {Boolean} options.silent - If true, does not fire events
   *
   * @returns {PriceTable} self instance
   */
  prev (options = {}) { return this.move(-1, null, options) }

  /**
   * Shows the next dates of the month (if if none, fires event 'nextEdge'
   *
   * @param {Object} [options] - Options object
   * @param {Boolean} options.silent - If true, does not fire events
   *
   * @returns {PriceTable} self instance
   */
  next (options = {}) { return this.move(1, null, options) }

  /**
   * Place focus on the date specified by DateString in the price table
   *
   * @param {DateString} date - The date to be shown
   * @param {Object} [options] - Options object
   * @param {Boolean} options.silent - If true, does not fire events
   *
   * @returns {PriceTable} self instance
   */
  goTo (date, options = {}) { return this.move(1, date, options) }

  _createHtml () {
    this.element[componentAPI] = this.element[componentAPI] || {}
    this.element[componentAPI].priceTableDataModel = new PriceTableDataModel({ ...this.options.data, id: this.element.id })
    this.priceTableData = this.element[componentAPI].priceTableDataModel.getPriceTableData()
    const priceTableHtml = elementFromString(PriceTableTemplate(this.priceTableData, this.options))
    moveChildrenFrom(priceTableHtml, this.element, { flush: true, attributes: true })
    Component.initDocumentComponentsFromAPI(this.element)
  }

  _initElements () {
    this.navWrapperElement = this.element.querySelector(componentQueries.navWrapperElement)
    this.prevElement = this.element.querySelector(componentQueries.prevElement)
    this.nextElement = this.element.querySelector(componentQueries.nextElement)
    this.dateHeaderElements = Array.from(this.element.querySelectorAll(componentQueries.headerCell))
    this.datePriceElements = Array.from(this.element.querySelectorAll(componentQueries.priceCell))
    this.allElements = [...this.dateHeaderElements, ...this.datePriceElements]
    this.priceRowElements = Array.from(this.element.querySelectorAll(componentQueries.priceRow))
  }

  _addListeners () {
    this.prevElement && this.prevElement.addEventListener('click', () => this.prev())
    this.nextElement && this.nextElement.addEventListener('click', () => this.next())
    this.datePriceElements && this.datePriceElements.forEach((datePriceElement) => {
      datePriceElement.addEventListener('mouseover', (ev) => {
        const target = ev.target && ev.target.closest(componentQueries.priceCell)
        if (target) {
          const targetPosition = target.dataset.position
          const currentRow = target.closest(componentQueries.priceRow)
          this.priceRowElements.forEach((element) => {
            element.classList[(element === currentRow) ? 'add' : 'remove'](componentQueries.isHighlightedClassName)
          })
          this.allElements.forEach((element) => {
            element.classList[((element.dataset.position === targetPosition) && isTargetBefore(target, element)) ? 'add' : 'remove'](componentQueries.isHighlightedClassName)
          })
        }
      })
      datePriceElement.addEventListener('mouseout', (ev) => {
        this.priceRowElements.forEach((element) => { element.classList.remove(componentQueries.isHighlightedClassName) })
        this.allElements.forEach((element) => { element.classList.remove(componentQueries.isHighlightedClassName) })
      })
      datePriceElement.addEventListener('click', (ev) => {
        const target = ev.target && ev.target.closest(componentQueries.priceCell)
        this._unselectPriceCell()
        target.classList.add(componentQueries.isActiveClassName)
        if (target && target.dataset.id) {
          const [room, occupancy, packageDate] = target.dataset.id.split('_')
          const occupancyData = this.priceTableData.roomData[room].occupancyData[occupancy]
          const occupation = occupancyData.ageProfileGroups.reduce((accum, { ageProfileId, quantity }) => {
            accum[ageProfileId] = quantity
            return accum
          },
          {})
          const selectedPackage = {
            ...occupancyData.packageData[packageDate],
            occupation
          }

          this.events.emit('dateSelected', {
            selectedPackage,
            selectedRowElement: target.closest(componentQueries.priceRow),
            priceClickedData: {
              price: selectedPackage.price,
              isPriceHighlighted: selectedPackage.highlightPriceOption !== undefined,
              currencyCode: this.priceTableData.currency,
              duration: selectedPackage.duration,
              departureDate: selectedPackage.departureDate
            },
            instance: this
          })
        }
      })
    })
  }

  _unselectPriceCell () {
    const priceCellElement = this.datePriceElements && this.datePriceElements
      .find(({ classList }) => classList.contains(componentQueries.isActiveClassName))
    priceCellElement && priceCellElement.classList.remove(componentQueries.isActiveClassName)
  }

  updateArrows () {
    const visibleElements = this.dateHeaderElements.filter((element) => element.offsetParent)
    if (this.dateHeaderElements[0] === visibleElements[0]) {
      this.prevElement.disabled = !this.options.prevMonth
    } else {
      this.prevElement.disabled = false
    }

    if (this.dateHeaderElements.slice(-1)[0] === visibleElements.slice(-1)[0]) {
      this.nextElement.disabled = !this.options.nextMonth
    } else {
      this.nextElement.disabled = false
    }

    return this
  }

  toggleNavVisibility (visible = true) {
    this.navWrapperElement.classList[!visible ? 'add' : 'remove']('is-hidden')
  }

  /**
   * Move withing the departure dates
   *
   * @param {Number} direction - The direction to move
   * @param {DateString|String} goTo - Where to move
   * @param {Object} [options] - Options object
   * @param {Boolean} options.silent - If true, does not fire events
   *
   * @returns {PriceTable} self instance
   */
  move (direction = 1, goTo, options = {}) {
    direction = (direction < 0) ? -1 : 1

    // Gets the currentVisibleElements from CSS pseudo-element attribute on root element
    // Because CSS will be only responsible to decide how many elements are shown when
    const visibleElementsAmount = parseInt(
      document.defaultView.getComputedStyle(this.element, ':before')
        .content.replace(/(("|')|\\)/g, '')
    )
    const visibleElements = this.dateHeaderElements.filter((element) => {
      const currentPosition = parseInt(element.dataset.position)
      return currentPosition >= 0 && currentPosition < visibleElementsAmount
    })
    let edgePosition = parseInt(visibleElements.slice(-1)[0].dataset.position) + 1

    if (goTo === 'last') {
      edgePosition = this.dateHeaderElements.length - edgePosition
    } else if (goTo) {
      let elementIndex = this.dateHeaderElements.findIndex((element) => element.dataset.date === goTo)
      elementIndex = (elementIndex < 0) ? 0 : elementIndex
      if ((this.dateHeaderElements.length - elementIndex) < edgePosition) {
        elementIndex = this.dateHeaderElements.length - edgePosition
      }
      edgePosition = parseInt(this.dateHeaderElements[elementIndex || 0].dataset.position)
      direction = 1
    } else {
      if (direction < 0 && (parseInt(this.dateHeaderElements[0].dataset.position) + edgePosition) > 0) {
        edgePosition = Math.abs(parseInt(this.dateHeaderElements[0].dataset.position))
        if (this.options.prevMonth && this.dateHeaderElements[0] === visibleElements[0]) {
          if (!options.silent) {
            this.events.emit('prevEdge', {
              direction: 'prev',
              instance: this
            })
          }
          this._unselectPriceCell()
          return this
        }
      } else if (direction > 0 && (parseInt(this.dateHeaderElements.slice(-1)[0].dataset.position) - edgePosition) < edgePosition) {
        edgePosition = Math.abs(parseInt(this.dateHeaderElements.slice(-1)[0].dataset.position)) - edgePosition + 1
        if (this.options.nextMonth && this.dateHeaderElements.slice(-1)[0] === visibleElements.slice(-1)[0]) {
          if (!options.silent) {
            this.events.emit('nextEdge', {
              direction: 'next',
              instance: this
            })
          }
          this._unselectPriceCell()
          return this
        }
      }
    }

    this.allElements.forEach((element) => {
      element.dataset.position = parseInt(element.dataset.position) + (edgePosition * -direction)
    })
    this._unselectPriceCell()
    if (!options.silent) {
      this.events.emit('move', {
        direction,
        instance: this
      })
    }

    this.updateArrows()

    return this
  }

  /**
   * Recreates Price table content and listeners
   *
   * @param {Object} [options]
   *
   * @returns {PriceTable} self instance
   */
  refresh (options = {}) {
    this.options = {
      ...defaults,
      ...options
    }
    this._init()

    return this
  }
}

import BookingItem from '../booking-item/main'
import { collapseEvents } from '../../components/collapse/event-types'
import { bookingBoxEvents } from '../../../js/document/event-types'
import { BookingBoxHeadingTemplate } from './booking-box__heading.template'
import webStorage from '../../../js/document/web-storage'
import { widgetQueries, attr, classList, apis, boxStates } from './constants'

export class BookingBox extends BookingItem {
  constructor (element) {
    super(element)
    this.element = element
    this.boxState = boxStates[this.element.dataset[attr.boxState]] || boxStates.default
    this._initCollapseElements()
    this._attachCollapseEvents()
  }

  async handleFetched (response) {
    const data = this._getAllPreviousCollapseInfo()
    const currentCollapseState = (data && data[this.componentId]) || null

    if (this.collapseElementApi) {
      currentCollapseState === collapseEvents.OPENED ? this.openCollapseElement() : this.closeCollapseElement()
    }

    return super.handleFetched(response)
  }

  _initCollapseElements () {
    this.collapseElement = this.element.querySelector(widgetQueries.collapse)
    this.collapseElementApi = this.collapseElement && this.collapseElement[apis.collapseApi]
    this.collapseTriggerElement = this.element.querySelector(widgetQueries.collapseTrigger)
  }

  _attachCollapseEvents () {
    if (this.collapseElementApi) {
      this.collapseElementApi.events.on(collapseEvents.OPENED, () => {
        this._saveCollapseStateToSessionStorage(collapseEvents.OPENED)
        this.events.emit(bookingBoxEvents.COLLAPSE_OPEN, { serviceName: this.getServiceName(), boxState: this.boxState })
      })
      this.collapseElementApi.events.on(collapseEvents.CLOSED, () => {
        this._saveCollapseStateToSessionStorage(collapseEvents.CLOSED)
        this.events.emit(bookingBoxEvents.COLLAPSE_CLOSE, { serviceName: this.getServiceName(), boxState: this.boxState })
      })
    }
  }

  openCollapseElement (options = { silent: true }) {
    this.collapseElementApi && this.collapseElementApi.open(options)
    this._saveCollapseStateToSessionStorage(collapseEvents.OPENED)
  }

  closeCollapseElement (options = { silent: true }) {
    this.collapseElementApi && this.collapseElementApi.close(options)
    this._saveCollapseStateToSessionStorage(collapseEvents.CLOSED)
  }

  hideComponent () {
    this._removeSuccessState()
    this._updateCollapseHeadingTemplate()
    return super.hideComponent()
  }

  /**
   * Change to success state and change the title
   */
  setSuccessStateAndUpdateTitle () {
    this._setSuccessState()
    const titleText = (this.element.dataset[attr.titleSelected] || '')
    this._updateCollapseHeadingTemplate(titleText)
  }

  /**
   * Change to success state and change the title
   */
  noOptionSelectedAndUpdateTitle () {
    this._removeSuccessState()
    this._addNoOptionClassSelected()
    const titleText = (this.element.dataset[attr.noOptionTitleSelected] || '')

    this._updateCollapseHeadingTemplate(titleText)
  }

  _updateCollapseHeadingTemplate (titleText = '') {
    if (this.collapseTriggerElement) {
      const headingTitle = this.collapseTriggerElement.querySelector(widgetQueries.headingTitle)

      if (!titleText) {
        titleText = (this.element.dataset[attr.defaultTitle] || '')
      }

      if (headingTitle) {
        headingTitle.innerHTML = `${BookingBoxHeadingTemplate({ text: titleText })}`
      }
    }
  }

  _setSuccessState () {
    this.boxState = boxStates.selected
    const boxCollapse = this.element.querySelector(widgetQueries.boxCollapse)
    boxCollapse && boxCollapse.classList.add(classList.boxSelected)
  }

  _removeSuccessState () {
    this.boxState = boxStates.default
    const boxCollapse = this.element.querySelector(widgetQueries.boxCollapse)
    if (boxCollapse && boxCollapse.classList.contains(classList.boxSelected)) {
      boxCollapse.classList.remove(classList.boxSelected)
      boxCollapse.classList.add(classList.noOptionSelected)
    }
  }

  _addNoOptionClassSelected () {
    const boxCollapse = this.element.querySelector(widgetQueries.boxCollapse)
    boxCollapse && boxCollapse.classList.add(classList.noOptionSelected)
  }

  _saveCollapseStateToSessionStorage (state) {
    const identifier = this._getCollapseInfoIdentifier()
    const data = this._getAllPreviousCollapseInfo() || {}
    data[this.componentId] = state

    if (identifier) {
      webStorage.session.set(identifier, data)
    }
  }

  _getAllPreviousCollapseInfo () {
    let info
    const identifier = this._getCollapseInfoIdentifier()
    if (identifier) {
      info = webStorage.session.get(identifier)
    }
    return info
  }

  _getCollapseInfoIdentifier () {
    let identifier
    const urlParams = new window.URLSearchParams(window.location.search)
    if (urlParams) {
      identifier = urlParams.get(widgetQueries.identifierSearchName)
    }
    return identifier !== null ? `booking-box__collapse-states-${identifier}` : undefined
  }
}

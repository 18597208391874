import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import BookingPreferencesSelectorDataMapper from './data-mapper'
import {
  BookingPreferencesSelectorTemplate
} from './w-booking-preferences-selector.template'
import { TextboxTemplate } from '../../components/textbox/c-textbox.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import { preferenceTypeEnum } from './enums'

import Component from '../../../js/core/component/component'
import registeredEvents from '../../../js/helpers/registered-events'
import { attr } from '../booking-participants-form/main'

const widgetApi = 'w-booking-preferences-selector'
const antiForgeryTokenName = '__RequestVerificationToken'

const widgetQueries = {
  form: `[data-${widgetApi}__form]`,
  choicelist: `[data-${widgetApi}__choicelist]`,
  fieldSet: `[data-${widgetApi}__form__section__fieldset]`,
  textBox: `[data-${widgetApi}__textbox]`,
  formApi: 'c-form',
  choicelistApi: 'c-choice-list',
  textboxApi: 'c-textbox',
  textboxElement: '.c-textbox__element',
  antiForgeryToken: `[name="${antiForgeryTokenName}"]`,
  customerInputTextbox: `[data-${widgetApi}__textbox]`,
  configurationElement: `[data-${widgetApi}__configuration]`
}

const classNames = {
  disabled: 'is-disabled',
  hidden: 'is-hidden',
  textboxBlockElement: `${widgetApi}__option__textbox m-body m-body--medium m-body--no-crop`
}

const RulePattern = {
  maxLength: '[\\w\\W]{1,10}'
}

export default class BookingPreferencesSelector extends BookingBox {
  /**
   * Creates a new Booking Preferences Selector widget.
   *
   * @constructor
   *
   * @param {HTMLElement} element   - The HTML content.
   * @param {Object} [options={}]   - Options.
   */
  constructor (element, options = {}) {
    super(element)
    this.element = element
    this.form = this.element.querySelector(widgetQueries.form)
    this.fieldSet = this.element.querySelector(widgetQueries.fieldSet)

    this.antiForgeryToken = this.element.querySelector(widgetQueries.antiForgeryToken)
    this.contextItemId = this.element.dataset.componentId
    this.formApi = this.form && this.form[widgetQueries.formApi]

    this.dataMapper = new BookingPreferencesSelectorDataMapper()
    const filterConfigurationElement = this.element.querySelector(widgetQueries.configurationElement)
    this.configurations = this._readOptionsFromConfigurations(filterConfigurationElement)

    if (this.formApi) {
      this.method = this.formApi.method
      this.action = this.formApi.action
    }

    this.choiceListApis = null

    this.track = this.element.hasAttribute(attr.track) && this.element.attributes[attr.track].value

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.track }
    })
  }

  /**
   *
   * @param {Object}    data      - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  _updateWidgetData () {
    let widgetData

    let apiData = {}
    if (this.data.preferences) {
      apiData = { ...{ preferences: this.data.preferences, categories: this.data.preferencesCategories } }

      widgetData = this.dataMapper.mapWidgetData(apiData)

      if (this.track) {
        widgetData = { ...widgetData, track: this.track }
      }

      if (widgetData && widgetData.preferences) {
        super.showComponent()
        if (this.choiceListApis == null || this.choiceListApis.length === 0) {
          this._init(widgetData)
        }
      } else {
        super.hideComponent()
      }
    }
  }

  _init (data) {
    const renderedHtml = BookingPreferencesSelectorTemplate(data)
    const newContent = elementFromString(renderedHtml)
    this._render(newContent)
    Component.initDocumentComponentsFromAPI(newContent)

    this.getChoiceListApi()
    this._attachChoiceListEvents()
    this._createTextBoxForEachCustomerInput()
    this._initTextBoxApis()
  }

  _render (el) {
    flush(this.fieldSet)
    this.fieldSet.appendChild(el)
  }

  getChoiceListApi () {
    const choicelistElement = this.element.querySelector(widgetQueries.choicelist)
    if (choicelistElement) {
      this.choicelistApi = choicelistElement[widgetQueries.choicelistApi]
    }
  }

  _attachChoiceListEvents () {
    const choiceListElements = [...this.element.querySelectorAll(widgetQueries.choicelist)]
    this.choiceListApis = choiceListElements.map(choiceListElement => choiceListElement[widgetQueries.choicelistApi])
      .filter(api => api)

    this.choiceListApis.forEach(choiceList => {
      choiceList.events.on('changeOptions', (options) => { this._clickedOption(options, choiceList) })
    })
  }

  _createTextBoxForEachCustomerInput () {
    const preferencesWithCustomerInputElements = this.element.querySelectorAll(`.c-checkbox__input[data-type*="${preferenceTypeEnum.CustomerInput}"]`)
    if (preferencesWithCustomerInputElements) {
      preferencesWithCustomerInputElements.forEach(preferenceWithCustomerInputElement => {
        const textBoxextraClasses = `${classNames.textboxBlockElement} ${!preferenceWithCustomerInputElement.checked ? 'is-hidden' : ''}`
        const data = {
          id: preferenceWithCustomerInputElement.value,
          name: preferenceWithCustomerInputElement.name,
          disabled: !preferenceWithCustomerInputElement.checked,
          value: preferenceWithCustomerInputElement.dataset.textBoxValue,
          placeholder: preferenceWithCustomerInputElement.dataset.textBoxPlaceholder,
          extraClasses: textBoxextraClasses,
          patternRule: RulePattern.maxLength,
          messageInvalid: this.configurations ? this.configurations.customPreferenceMaxLengthMessage : '',
          attributes: { 'data-w-booking-preferences-selector__textbox': '' }
        }
        const textboxHtml = TextboxTemplate(data)
        const newContent = elementFromString(textboxHtml)
        Component.initDocumentComponentsFromAPI(newContent)
        preferenceWithCustomerInputElement.parentElement.appendChild(newContent)
      })
    }
  }

  _clickedOption (options, api) {
    const selectedOptions = options.filter(option => option.checked)
    const customerInputSelectedOptions = selectedOptions.filter(option => option.dataset.type === preferenceTypeEnum.CustomerInput)
    if (customerInputSelectedOptions) {
      const preferencesWithCustomerInputElements = api.element.querySelectorAll(`.c-checkbox__input[data-type*="${preferenceTypeEnum.CustomerInput}"]`)
      preferencesWithCustomerInputElements.forEach(preferenceWithElement => {
        const customerInputSelectedOptionsIds = customerInputSelectedOptions.map(({ id }) => id)
        const textBoxElement = preferenceWithElement.parentElement.querySelector(widgetQueries.customerInputTextbox)
        const textBoxElementApi = textBoxElement ? textBoxElement[widgetQueries.textboxApi] : null
        const enableTextBox = customerInputSelectedOptionsIds.includes(preferenceWithElement.id)
        if (textBoxElementApi) {
          if (!enableTextBox) {
            textBoxElementApi.setProp('value', '')
          } else {
            textBoxElementApi.setProp('value', textBoxElementApi.getProp('value') || preferenceWithElement.dataset.textBoxValue)
          }
        }
        textBoxElement.classList.toggle(classNames.disabled, !enableTextBox)
        textBoxElement.querySelector(widgetQueries.textboxElement).disabled = !enableTextBox
        textBoxElement.classList.toggle(classNames.hidden, !enableTextBox)
      })
    }
  }

  _initTextBoxApis () {
    this.textBoxes = [...this.element.querySelectorAll(widgetQueries.textBox)]
    this.textBoxesApis = this.textBoxes
      ? this.textBoxes.map(textBoxElement => textBoxElement[widgetQueries.textboxApi]).filter(api => api)
      : null

    this.textBoxesApis.forEach(textboxApi => {
      this.formApi.init().fields.push(textboxApi)
      textboxApi.events.on('blur', () => textboxApi.validate(), this)
    })
  }

  async getValidationData () {
    const eventArgs = {
      method: this.method,
      url: this.action,
      isForm: true
    }
    if (this._method !== 'GET') {
      const formData = this._getPreferencesData()
      formData.append('contextitemid', this.contextItemId)
      if (this.antiForgeryToken) {
        formData.append(antiForgeryTokenName, this.antiForgeryToken.value)
      }
      eventArgs.body = formData
    }
    return eventArgs
  }

  async handleValidationClient () {
    let validationResult = false
    if (this.formApi) {
      validationResult = this.formApi.validate().every(v => v.isValid)
    }
    return validationResult
  }

  _getPreferencesData () {
    const formData = new window.FormData()

    let preferencesFieldsValue = []
    if (this.choiceListApis) {
      this.choiceListApis.forEach(api => preferencesFieldsValue.push(api.getSelectedValues()))
      preferencesFieldsValue = preferencesFieldsValue.flat()
    } else if (this.choicelistApi) {
      preferencesFieldsValue = this.choicelistApi.getSelectedValues()
    }

    const cutomerPreferencesData = this._getCustomerInputPreferencesData()

    preferencesFieldsValue.forEach(preferenceValue => {
      formData.append('preferences', preferenceValue)
    })

    cutomerPreferencesData.forEach(preferenceValue => {
      formData.append('customerInputs', JSON.stringify(preferenceValue))
    })

    return formData
  }

  _getCustomerInputPreferencesData () {
    let customerInputPreferencesFieldsValue = []
    if (this.textBoxesApis) {
      this.textBoxesApis.forEach(api => {
        const itemId = api.element.querySelector('.c-textbox__element').getAttribute('id') || null
        const itemData = {
          id: itemId,
          value: api.getProp('value')
        }
        customerInputPreferencesFieldsValue.push(itemData)
      })
      customerInputPreferencesFieldsValue = customerInputPreferencesFieldsValue.flat()
    }
    return customerInputPreferencesFieldsValue
  }

  /**
   * This is the command that the mediator will send to all the registered items once a
   * validation at server side has to be performed. The call is done asynchronously
   */
  async handleValidationServer (response) {
    return response.success && super.handleValidationServer(response)
  }

  _readOptionsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)
      const options = {
        customPreferenceMaxLengthMessage: configuration.customPreferenceMaxLengthMessage
      }
      return options
    }
  }
}
registerWidget(BookingPreferencesSelector, widgetApi)

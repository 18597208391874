import Component from '../../../js/core/component/component'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { apiCaller } from '../../../js/helpers/api-caller'
import { elementFromString, flush } from '../../../js/document/html-helper'
import { BookingChangedNotificationResponseContentTemplate } from './w-booking-changed-notification-response__content.template'

export const widgetApi = 'w-booking-changed-notification'

export const attr = {
  method: `data-${widgetApi}__method`,
  url: `data-${widgetApi}__url`,
  changeType: `data-${widgetApi}__change-type`,
  modalId: `data-${widgetApi}__modal-id`
}

export const widgetQueries = {
  mainContainer: `[data-${widgetApi}__content]`,
  acknowledgeButton: `[data-${widgetApi}__acknowledge-button]`,
  configuration: `[data-${widgetApi}__configuration]`
}

export default class BookingChangedNotification {
  /**
   * Creates a new BookingChangedNotification
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element
   * @param options
   */
  constructor (element, options = {}) {
    if (!element) { return }
    this.element = element
    this.options = {
      method: this.element.getAttribute(attr.method),
      url: this.element.getAttribute(attr.url),
      changeType: this.element.getAttribute(attr.changeType),
      modalId: this.element.getAttribute(attr.modalId),
      ...options
    }
    this.apis = {}
    this.settings = this._getSettingsDataFromDom()
    this._initElements()
    this._attachEvents()
  }

  _getSettingsDataFromDom () {
    const element = this.element.querySelector(widgetQueries.configuration)
    if (!element) {
      return {}
    }
    return JSON.parse(element.innerText)
  }

  _initElements () {
    this.elements = {}
    this.elements.mainContainer = this.element.querySelector(widgetQueries.mainContainer)
    this.elements.acknowledgeButton = this.element.querySelector(widgetQueries.acknowledgeButton)
  }

  _attachEvents () {
    if (this.elements.acknowledgeButton) {
      this.elements.acknowledgeButton.addEventListener('click', this._clickAcknowledgeButton.bind(this))
      this.apis.acknowledgeButton = this.elements.acknowledgeButton['c-btn']
    }
  }

  async _clickAcknowledgeButton (ev) {
    ev.preventDefault()

    this._setButtonsState(true)
    this.fetchAndUpdate()
  }

  _setButtonsState (isLoading) {
    if (this.elements.acknowledgeButton && this.apis.acknowledgeButton) {
      this.apis.acknowledgeButton.setProps({ disabled: isLoading, loading: isLoading })
    }
  }

  async fetchAndUpdate () {
    try {
      const data = {
        type: this.options.changeType
      }
      const result = await apiCaller(this.options.url, { body: data, method: this.options.method })

      if (result.success) {
        this._renderView(true)
      } else {
        this._renderView(false)
      }
    } catch (error) {
      this._renderView(false)
    }
    this._setButtonsState(false)
  }

  _renderView (isSuccess) {
    const templateData = this._buildResponseData(isSuccess)
    const renderedHtml = BookingChangedNotificationResponseContentTemplate(templateData)
    this._renderHTML(renderedHtml, this.elements.mainContainer)
  }

  _buildResponseData (isSuccess) {
    const data = {
      modalId: this.options.modalId,
      isSuccess,
      title: '',
      text: '',
      buttonText: ''
    }
    if (isSuccess && this.settings && this.settings.successResponse) {
      data.title = this.settings.successResponse.title
      data.text = this.settings.successResponse.text
      data.buttonText = this.settings.successResponse.buttonText
    } else if (this.settings && this.settings.failureResponse) {
      data.title = this.settings.failureResponse.title
      data.text = this.settings.failureResponse.text
      data.buttonText = this.settings.failureResponse.buttonText
    }
    return data
  }

  _renderHTML (html, targetElement) {
    const newContent = elementFromString(html)
    flush(targetElement)
    targetElement.appendChild(newContent)
    // Component.initDocumentComponentsFromAPI(newContent)
    Component.initComponentActionElements(newContent)
  }
}

registerWidget(BookingChangedNotification, widgetApi)

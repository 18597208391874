
export default class BookingPreferencesSelectorDataMapper {
  mapWidgetData (apiData) {
    const widgetPreferences = (apiData.preferences && apiData.preferences.length > 0)
      ? {
          categories: this._mapCategories(apiData),
          preferences: !apiData.categories ? this._mapPreferences(apiData) : null
        }
      : null
    return widgetPreferences
  }

  _mapCategories (apiData) {
    const categories = (apiData && apiData.categories) ? apiData.categories.map(category => this._mapCategory(category, apiData)) : null
    return categories
  }

  _mapCategory (apiCategory, apiData) {
    const widgetCategory = {
      title: apiCategory.text || '',
      icon: apiCategory.icon || '',
      id: apiCategory.id,
      preferences: this._mapPreferences(apiData, apiCategory)
    }
    return widgetCategory
  }

  _mapPreferences (apiData, apiCategory = null) {
    const preferences = apiCategory ? apiData.preferences.filter(preference => preference.categoryId === apiCategory.id) : apiData.preferences
    const widgetPreference = preferences && preferences.length > 0
      ? {
          choiceListInfo: {
            method: 'multiple',
            highlighted: false,
            id: apiCategory ? `w-booking-preferences-selector__category-${apiCategory.id}` : 'w-booking-preferences-selector',
            name: apiCategory ? `w-booking-preferences-selector__category-${apiCategory.id}` : 'w-booking-preferences-selector',
            extraClasses: 'o-columns u-columns--2@sm u-columns--3@sml',
            attributes: { 'data-w-booking-preferences-selector__choicelist': '' },
            disabled: false,
            unresolved: false,
            items: preferences ? preferences.map(preference => this._mapPreference(preference)) : null
          }
        }
      : null
    return widgetPreference
  }

  _mapPreference (apiPreference) {
    const widgetItem = {
      id: apiPreference.id,
      name: apiPreference.value,
      value: apiPreference.value,
      text: apiPreference.text,
      checked: apiPreference.isChecked,
      dataset: {
        type: apiPreference.type,
        textBoxValue: apiPreference.textBoxValue || '',
        textBoxPlaceholder: apiPreference.textBoxPlaceholder || ''
      }
    }
    return widgetItem
  }
}

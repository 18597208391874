import { RoomListTemplate, RoomListItemsTemplate, slpittedRoomListItemsTemplate } from './w-room-list.template'
import { elementFromString, moveChildrenFrom } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import EventEmitter from 'eventemitter3'
import { language } from '../../../js/user/locale-settings'
import { register } from '../../../js/document/namespace'
import SortRoomsBy from '../../../js/data/room-selector/sort-rooms-by'

require('../../components/floating-box/main')

const COMPONENT_LOCALES = register(`window.sundio.i18n.${language}.roomList`)

const WIDGET_API = 'w-room-list'
const NUMBER_STEPPER_API = 'c-number-stepper'

const WIDGET_QUERIES = {
  url: `data-${WIDGET_API}__url`,
  roomsListSelectRoomButtons: `.${WIDGET_API}-item__room-selection--button`,
  roomListNumberSteppers: `.${WIDGET_API}__number-stepper`,
  roomsListContainerElement: `[data-${WIDGET_API}__rooms-list]`
}

const DEFAULT_OPTIONS = {
  locales: {},
  sortRoomsBy: SortRoomsBy.PriceAndName,
  data: {
    id: '',
    priceConfig: {},
    rooms: []
  }
}

export default class RoomList {
  /**
   * Creates a new RoomList
   *
   * @constructor
   *
   * @param {HTMLElement}     element                     - The main HTML element
   * @param {Object}          [options={}]                - Options object
   * @param {Object}          [options.locales]           - Localized texts
   * @param {Object}          [options.data]              - Data to be used
   * @param {String}          [options.data.id]           - Element ID to be used
   * @param {PriceData}       [options.data.priceConfig]  - Price config object
   * @param {Object[]}        [options.data.rooms]        - Rooms to be rendered
   */
  constructor (element, options = {}) {
    this.element = element
    this.options = {
      ...DEFAULT_OPTIONS,
      ...options
    }
    this.events = new EventEmitter()

    this.locales = {
      ...COMPONENT_LOCALES,
      ...options.locales
    }

    this._initOccupancyFilter()
    this._createHtml()
    this._getHtmlElements()
    this._bindEvents()
    this._refreshRooms()
  }

  _createHtml (data = this.options.data) {
    const roomListHtml = elementFromString(RoomListTemplate(
      {
        ...data,
        locales: this.locales,
        priceConfig: this.options.data.priceConfig,
        rooms: []
      }
    ))

    Component.initDocumentComponentsFromAPI(roomListHtml)
    moveChildrenFrom(roomListHtml, this.element, { flush: true, attributes: true })
    Component.initComponentActionElements(roomListHtml)
  }

  _getHtmlElements () {
    this.numberSteppers = [...this.element.querySelectorAll(WIDGET_QUERIES.roomListNumberSteppers)]
    this.numberSteppersApi = this.numberSteppers.map(stepper => stepper[NUMBER_STEPPER_API])
    this.roomsContainer = this.element.querySelector(WIDGET_QUERIES.roomsListContainerElement)
    this._getHtmlElementsInItems()
  }

  _getHtmlElementsInItems () {
    this.roomsListSelectRoomButtons = this.element.querySelectorAll(WIDGET_QUERIES.roomsListSelectRoomButtons)
  }

  _bindEvents () {
    this.numberSteppersApi.forEach(numberStepperApi => {
      numberStepperApi.events.on('change', this._numberStepperChanged, this)
    })
    this._bindEventsItems()
  }

  _bindEventsItems () {
    this.roomsListSelectRoomButtons.forEach(selectRoomButton => {
      selectRoomButton['c-btn'].events.on('clickButton', this._selectRoomItem, this)
    })
  }

  _initRoomsContainerEvents () {
    Component.initComponentActionElements(this.roomsContainer)
  }

  _selectRoomItem (button, ev) {
    const isOccupationEmptyOrNotSet = this.options.roomSelectorService.occupancies.isOccupationEmptyOrNotSet(this.occupancyFilter)

    const eventArgs = {
      roomContractId: ev.attr.roomContractId,
      roomId: ev.attr.roomId,
      occupancyId: ev.attr.occupancyId,
      packageId: isOccupationEmptyOrNotSet ? undefined : ev.attr.packageId,
      occupancy: isOccupationEmptyOrNotSet ? undefined : this.occupancyFilter
    }
    this.events.emit('selected', eventArgs)
  }

  _numberStepperChanged () {
    const occupancyFilter = this._getOccupancyFilterFromNumberSteppers()
    this._updateOccupancyFilter(occupancyFilter)
  }

  _updateOccupancyFilter (occupancyFilter, options = { silent: false }) {
    this.occupancyFilter = occupancyFilter
    if (!options.silent) {
      this._refreshRooms()
      this._initRoomsContainerEvents()
    }
  }

  _initOccupancyFilter () {
    if (this.options.data.occupancySelectors) {
      const occupancyFilter = this.options.data.occupancySelectors.reduce((occupancy, occupancySelector) => {
        const current = occupancySelector.current || 0

        occupancy[occupancySelector.ageProfileId] = current
        occupancy.total = (occupancy.total || 0) + current
        return occupancy
      }, {})

      this._updateOccupancyFilter(occupancyFilter, { silent: true })
    }
  }

  _getOccupancyFilterFromNumberSteppers () {
    return this.numberSteppersApi.reduce((occupancy, stepper) => {
      const ageProfile = stepper.element.attributes['data-age-profile'].value
      occupancy[ageProfile] = stepper.props.value
      occupancy.total = (occupancy.total || 0) + stepper.props.value
      return occupancy
    }, {})
  }

  _refreshRooms () {
    const filterResponse = this.options.roomSelectorService
      ? this.options.roomSelectorService.filterRooms(this.options.data.rooms, this.occupancyFilter, this.options.sortRoomsBy)
      : { rooms: this.options.data.rooms, roomsNotMatchingOccupancy: [] }

    const isOccupancyFilterDefined = this.numberSteppersApi && this.numberSteppersApi.length > 0
    const isOccupancyFilterEmpty = isOccupancyFilterDefined && this.options.roomSelectorService.occupancies.isOccupationEmptyOrNotSet(this.occupancyFilter)

    const selectedRoomTypeRooms = filterResponse.roomsMatchingOccupancy.filter(room => room.selected)
    const notSelectedRoomTypeRooms = filterResponse.roomsMatchingOccupancy.filter(room => !room.selected)

    let roomsHtml = null
    if (selectedRoomTypeRooms.length) {
      roomsHtml = elementFromString(slpittedRoomListItemsTemplate({
        d: {
          roomNumber: this.options.data.roomNumber,
          roomsNotMatchingOccupancy: filterResponse.roomsNotMatchingOccupancy,
          showEmtpyFilteredRoomsMessage: filterResponse.roomsMatchingOccupancy.length === 0,
          roomsNotMatchingOccupancyHeader: this._getHeaderTextForRoomsNotMatchingOccupancy(filterResponse.roomsNotMatchingOccupancy),
          showFromTextInRooms: isOccupancyFilterDefined && isOccupancyFilterEmpty,
          locales: this.locales,
          priceConfig: this.options.data.priceConfig
        },
        selectedRoomTypeList: selectedRoomTypeRooms,
        notSelectedRoomTypeList: notSelectedRoomTypeRooms
      }))
    } else {
      roomsHtml = elementFromString(RoomListItemsTemplate({
        roomNumber: this.options.data.roomNumber,
        rooms: filterResponse.roomsMatchingOccupancy,
        roomsNotMatchingOccupancy: filterResponse.roomsNotMatchingOccupancy,
        showEmtpyFilteredRoomsMessage: filterResponse.roomsMatchingOccupancy.length === 0,
        roomsNotMatchingOccupancyHeader: this._getHeaderTextForRoomsNotMatchingOccupancy(filterResponse.roomsNotMatchingOccupancy),
        showFromTextInRooms: isOccupancyFilterDefined && isOccupancyFilterEmpty,
        locales: this.locales,
        priceConfig: this.options.data.priceConfig
      }))
    }

    Component.initDocumentComponentsFromAPI(roomsHtml)
    moveChildrenFrom(roomsHtml, this.roomsContainer, { flush: true, attributes: false })
    this._getHtmlElementsInItems()
    this._bindEventsItems()
  }

  _getHeaderTextForRoomsNotMatchingOccupancy (roomsNotMatchingOccupancy) {
    let message = ''
    if (roomsNotMatchingOccupancy && roomsNotMatchingOccupancy.length > 0) {
      message = (this.locales.roomsNotMatchingOccupancyHeader || '').replace('{n}', roomsNotMatchingOccupancy.length)
    }
    return message
  }
}

/**
 *
 * @typedef {Object}                  PackageUspData
 *
 * @property {String}                 kind
 * @property {String}                 title
 * @property {String}                 subtitle
 */

/**
 * The SelectedStateTemplateData contains all data needed to hydrate a Selected State view
 *
 * @typedef {Object}                  SelectedStateTemplateData
 *
 * @property {String}                 id                          - SelectedState's id
 * @property {String}                 [mainTitleText]
 * @property {String}                 [headerMessageText]
 * @property {String}                 [headerMessageLevel]

 * @property {Object}                 [accommodation]
 * @property {String}                 [accommodation.name]
 * @property {Number}                 [accommodation.category]
 * @property {String}                 [accommodation.image]
 * @property {String}                 [accommodation.imagePlaceholder]
 * @property {String}                 [accommodation.imageUnresolved]
 * @property {String[]}               [accommodation.usps]
 *
 * @property {BulletListItemData[]}   [tripDetails]
 *
 * @property {Object}                 [optionalServices]
 * @property {String}                 [optionalServices.label]
 * @property {String}                 [optionalServices.tagLine]
 * @property {BulletListItemData[]}   [optionalServices.items]
 *
 * @property {Object}                 [priceBreakdown]
 * @property {BreakdownTableItemData[]} [priceBreakdown.items]
 * @property {String}                 [priceBreakdown.extraInfo]
 * @property {String}                 [priceBreakdown.openJawMessage]
 *
 * @property {Object}                 [mandatoryCosts]
 * @property {String}                 [mandatoryCosts.title]
 * @property {String[]}               [mandatoryCosts.content]
 *
 * @property {String}                 [submitButtonText]
 *
 * @property {PackageUspData[]}       [packageUsps]
 * @property {String[]}               [contentUsps]
 *
 */

import { BulletListTemplate } from '../../components/bullet-list/c-bullet-list.template'
import { CollapseTemplate } from '../../components/collapse/c-collapse.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { BreakdownTableTemplate } from '../../components/breakdown-table/c-breakdown-table.template'
import { LabelTemplate } from '../../components/label/c-label.template'
import { getTokenClass } from '../../../js/helpers/tokens'
import { AbsoluteDialogTemplate } from '../../components/absolute-dialog/c-absolute-dialog.template'
const tokens = require('./data/w-selected-state__tokens.json')['w-selected-state']

export const defaultSelectedStateData = {
  id: '',
  mainTitleText: '',
  headerMessageText: '',
  headerMessageLevel: 'info',
  accommodation: {
    name: '[Hotel Name]',
    category: 4,
    image: '',
    imagePlaceholder: '',
    imageUnresolved: '',
    usps: []
  },
  tripDetails: [],
  optionalServices: {
    label: '',
    tagLine: '',
    items: []
  },
  priceBreakdown: {
    items: [],
    extraInfo: '',
    openJawMessage: ''
  },
  mandatoryCosts: {
    title: '',
    content: []
  },
  submitButtonText: '[Book now]',
  packageUsps: [],
  contentUsps: []
}

export const SelectedStateTemplate = (d, options) => {
  d = { ...defaultSelectedStateData, ...d, ...options }

  if (d.isEmpty) return `<div id="${d.id}" class="w-selected-state"></div>`

  // ⚠️ Build BulletListItemData for accommodation USPs
  const accommodationUSPs = d.contentUsps ? d.contentUsps.map(usp => ({ text: usp })) : null

  // ⚠️ Flight Icons are hardcoded from API, fix here till they're not
  const tripDetailsItems = d.tripDetails.map(item => {
    if (item.icon === 'icon-flight-outbound') item.icon = 'airplane-departure'
    if (item.icon === 'icon-flight-inbound') item.icon = 'airplane-return'
    return item
  })

  // ⚠️ Image is fixed to be sync and same size
  d.accommodation.image = d.accommodation.image && d.accommodation.imageUnresolved
    ? d.accommodation.imageUnresolved
      .replace('{W}', '640')
      .replace('{H}', '320')
      .concat('&scale=both&mode=crop')
    : null

  return `
<div id="${d.id}" class="w-selected-state">
  <h2 class="c-title m-heading m-heading--small w-booking-gate__item-title">${d.mainTitleText}</h2>
  ${d.headerMessageText
      ? `<div class="w-selected-state__conditional-message--wrapper ${d.headerMessageLevel
        ? `cs-state-${d.headerMessageLevel} cs-state-${d.headerMessageLevel}--light`
        : ''}">
      <p class="w-selected-state__conditional-message">${d.headerMessageText}</p>
    </div>`
      : ''
    }
  <div class="w-selected-state__body">
    <div class="w-selected-state__booking-overview">
      <div class="w-selected-state__summary">
        ${d.accommodation.image
      ? `<div class="c-img c-img--sync o-ratio o-ratio--2:1 w-selected-state__img"><img src="${d.accommodation.image}" class="c-img__resolved o-ratio__content"></div>`
      : ''
    }
        ${BulletListTemplate({
      items: tripDetailsItems,
      extraClasses: 'w-selected-state__lists'
    })}
      </div>
      ${OptionalServicesItemTemplate(d.optionalServices)}
    </div>
    <div class="w-selected-state__price-overview-container">
      <div class="w-selected-state__price-overview">
        ${TitleSectionTemplate(d.priceBreakdown?.headerText)}
        ${BreakdownTableTemplate({ items: d.priceBreakdown.items })}

        ${d.priceBreakdown.itemsLocalCosts?.length > 0 || d.priceBreakdown.itemsLocalCostsWithouPrices?.length > 0
          ? `${TitleSectionTemplate(d.priceBreakdown?.headerTextLocalCosts, true)}
             ${BreakdownTableTemplate({ items: d.priceBreakdown.itemsLocalCosts, extraClasses: 'w-selected-state__price-overview-localcosts-table' })}
             ${MandatoryCostsWithoutPricesTemplate(d.priceBreakdown.itemsLocalCostsWithouPrices)}
             ${LocalCostsDescriptionTemplate(d.priceBreakdown?.headerDescriptionLocalCosts)}`
          : ''
        }

        ${BreakdownTableTemplate({ items: d.priceBreakdown.itemsTotal })}

        <div class="w-selected-state__price-overview-details">
          ${MandatoryCostsTemplate(d.mandatoryCosts, d.id, d.mandatoryCostsCollapsed)}
          <div class="w-selected-state__footer">
            ${BtnTemplate({
      text: d.submitButtonText,
      variant: 'flow',
      jsApi: true,
      extraClasses: 'w-selected-state__footer-button'
    })}
          </div>
          ${d.priceBreakdown.openJawMessage
            ? `<div class="w-selected-state__price-overview-details--open-jaw-message">
                ${AbsoluteDialogTemplate({
                  body: d.priceBreakdown.openJawMessage,
                  alertType: 'warning',
                  closable: false
                })}
              </div>`
            : ''
          }
          ${d.priceBreakdown.extraInfo
            ? `<div class="w-selected-state__price-overview-details--extra-info-message">
                ${AbsoluteDialogTemplate({
                  body: `${d.priceBreakdown.extraInfoIcon ? `<span class="m-icon m-icon--${d.priceBreakdown.extraInfoIcon}"></span>` : ''} ${d.priceBreakdown.extraInfo}`,
                  alertType: 'info',
                  closable: false
                })}
              </div>`
            : ''
          }
          ${PackageUspsTemplate(d.packageUsps)}
        </div>
      </div>
    </div>
  </div>
${accommodationUSPs
      ? `<div class="w-selected-state__basket-usps-content">
  ${BulletListTemplate({
        items: accommodationUSPs,
        icon: 'checkmark',
        variant: 'inline',
        extraClasses: 'w-bullet-list__usps'
      })}
  </div>`
      : ''}

</div>`
}

const TitleSectionTemplate = (title, useMarginTop = false) => {
  return `
    <div class="w-selected-state__price-overview-header-section ${useMarginTop ? 'w-selected-state__price-overview-header-section-with-margin' : ''}">
      ${title ? `<h3 class="m-heading m-heading--medium w-selected-state__price-overview-header">${title}</h3>` : ''}
    </div>
  `
}

const LocalCostsDescriptionTemplate = (description) => {
  return `${description ? `<div class="w-selected-state__price-overview-localcosts-description">${description}</div>` : ''}`
}

const OptionalServicesItemTemplate = (optionalServices) => {
  // Skip render if there's no items
  if (!optionalServices.items || !optionalServices.items.length) return ''

  return `<div class="w-overview__optional-services">
${optionalServices.label
      ? LabelTemplate({
        text: optionalServices.label.toUpperCase(),
        extraClasses: 'w-overview__optional-service-promotion-label'
      })
      : ''
    }
${optionalServices.tagLine
      ? `<label class="w-overview__optional-service-tag-line ">${optionalServices.tagLine}</label>`
      : ''
    }
<div class="w-selected-state__usps-lists">
  ${BulletListTemplate({ items: optionalServices.items })}
</div >
</div>`
}

const MandatoryCostsTemplate = (mandatoryCosts, id, mandatoryCostsCollapsed) => {
  // Skip render if there's no content
  if (!mandatoryCosts.content || !mandatoryCosts.content.length) return ''

  return `<div class="w-selected-state__price-overview-costs">
<p class="w-selected-state__collapse-trigger-wrapper" data-c-collapse__id="${id}__collapse" data-c-collapse__action="toggle">
  <span class="w-selected-state__price-overview-costs-title">${mandatoryCosts.title}</span>
  <button class="m-button--clean c-collapse__trigger c-collapse__trigger--smooth w-selected-state__collapse-trigger" data-c-collapse__id="${id}__collapse">
    <span class="m-icon m-icon--size-large m-icon--chevron-down c-collapse__trigger-icon"></span>
  </button>
</p>
${CollapseTemplate({
    id: `${id}__collapse`,
    extraClasses: 'w-selected-state__collapse-wrapper',
    html: mandatoryCosts.content.map((contentItem) => `<p>${contentItem}</p>`).join(''),
    opened: !mandatoryCostsCollapsed
  })}
</div>`
}

const MandatoryCostsWithoutPricesTemplate = (localCosts) => {
  localCosts = localCosts || []
  if (localCosts.length === 0) {
    return ''
  }

  // Skip render if there's no content
  return `<div class="w-selected-state__price-overview-costs">
      ${localCosts.map(localCost => localCost.name || localCost.description
        ? `<div class="w-selected-state__price-overview-cost-item">
              ${localCost.name ? `<div class="w-selected-state__price-overview-cost-item-name">${localCost.name}</div>` : ''}
              ${localCost.description ? `<div class="w-selected-state__price-overview-cost-item-description">${localCost.description}</div>` : ''}
            </div>`
        : ''
      ).join('')}
    </div>`
}

const PackageUspsTemplate = (usps) => {
  // Skip render if there's no content
  if (!usps || !usps.length) return ''

  return `<div class="w-selected-state__usps-content">
${usps.slice(0, 3).map((usp) => PackageUspsItemTemplate(usp)).join('')}
</div>`
}

const PackageUspsItemTemplate = (usp) => {
  return `
  <div class="w-selected-state__usp">
    <span class="m-icon m-icon--${getTokenClass('packageUspIcons', usp.kind, tokens)} m-icon--size-large w-selected-state__package-usp-icon"></span>
    <div class="w-selected-state__usp-text-wrapper">
      <div class="w-selected-state__usp-text">
        <span class="w-selected-state__usp-title">
          <strong>${usp.title}</strong>
        </span>
      </div>
      <span>${usp.subtitle}</span>
    </div>
  </div>`
}

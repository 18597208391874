import { TitleTemplate } from '../../../components/title/c-title.template'

/**
 * The defaultPaymentVoucherSelectorData contains all data to render voucher information
 */

export const defaultPaymentVoucherSelectorData = {
  vouchersSelectorTitle: '',
  addVoucherText: '',
  noVouchersMessage: '',
  bonusTitle: '',
  bonusIcon: 'voucher',
  bonusMessage: '',
  showBonusTexts: false
}

export const PaymentVoucherSelectorTemplate = (d) => {
  d = { ...defaultPaymentVoucherSelectorData, ...d }
  return `
    <div class="w-payment__voucher-selection-wrapper">
      ${d.showBonusTexts
        ? `<div class="w-payment__bonus-wrapper">
            <div class="w-payment__bonus-header">
              <div class="w-payment__bonus-icon">
                <span class="m-icon m-icon--${d.bonusIcon}"></span>
              </div>
              <div class="w-payment__bonus-title">
                ${TitleTemplate({ text: d.bonusTitle })}
              </div>
            </div>
  
            <p class="w-payment__bonus-description">
              ${d.bonusMessage}
            </p>
          </div>`
        : ''}
      ${TitleTemplate({ text: d.vouchersSelectorTitle })}
      ${d.vouchers.length < 1 ? `<p class="w-payment__voucher-selection-description">${d.noVouchersMessage}</p>` : ''}
      <div class="w-payment__voucher-selector-vouchers-wrapper">
        ${d.vouchers.map(voucher => `<div class="w-payment__voucher-widget" data-w-payment__voucher data-w-voucher__code="${voucher.voucherCode}"></div>`).join('')}
      </div>
      <a href="#" class="w-payment__voucher-selector-add-link" data-w-payment__voucher-add-link>${d.addVoucherText}</a>
     </div>
    `
}

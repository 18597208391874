import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingStepsNavigation from '../../../../shared/modules/widgets/booking-steps-navigation/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const bookingStepsNavigationData = require('../../../../shared/modules/widgets/booking-steps-navigation/data/w-booking-steps-navigation__js-template')

loadQueueHelper.add(function () {
  initBookingStepsNavigation()
}, 2)

const initBookingStepsNavigation = () => {
  const items = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-steps-navigation"]')
  // data-js-api--w-booking-item="w-booking-steps-navigation"
  items.forEach(element => {
    const bookingStepsNavigation = new BookingStepsNavigation(element)
    console.log(bookingStepsNavigation)
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingStepsNavigationJsTemplate = document.querySelectorAll('[data-booking-steps-navigation-demo]')
  handleFetched(bookingStepsNavigationJsTemplate, bookingStepsNavigationData)
}

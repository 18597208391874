export const widgetApi = 'w-booking-car-rental'

export const widgetQueries = {
  configurationElement: `[data-${widgetApi}__configuration]`,
  choiceLists: `[data-${widgetApi}__choice-list]`,
  collapseElement: `[data-${widgetApi}__collapse]`,
  editCollapseElement: `[data-${widgetApi}-edit-details__collapse]`,
  editCarButton: `[data-${widgetApi}__selected-edit]`,
  removeCarButton: `[data-${widgetApi}__selected-remove]`,
  upgradeCarButton: `[data-${widgetApi}__selected-upgrade]`,
  itemEditConfirmButton: `[data-${widgetApi}-edit-details__confirm-button]`,
  itemEditCancelButton: `[data-${widgetApi}-edit-details__cancel-button]`,
  formItem: `[data-${widgetApi}__checkout-form]`,
  formDriverDropdown: `[data-${widgetApi}__item-field-driver]`,
  formBirthdateDateSelector: `[data-${widgetApi}__item-field-birthdate]`,
  formEquipmentsSelector: `[data-${widgetApi}__item-field-equipments]`,
  formEquipmentsContainer: `[data-${widgetApi}__container-equipments]`,
  ageWarningSelector: `[data-${widgetApi}__item-ageWarning]`,
  selectedInfoSection: `[data-${widgetApi}__selected-info-section]`,
  optionItem: `[data-${widgetApi}__option]`,
  formItemErrorSelector: '.has-error',
  conditionsElement: `[data-${widgetApi}__conditions-container]`,
  conditionsElementChoiceList: `[data-${widgetApi}__choice-list--conditions]`,
  addExtraCarButton: `[data-${widgetApi}__add-extra-car-button]`,
  extraCarListContainer: `[data-${widgetApi}__extra-car-list]`,
  arrowEditFormLeftPosition: `[data-${widgetApi}__arrow-edit-details__box]`,
  sliderButtonsDirection: '[data-js-component="c-slider"] [data-c-slider__buttons--direction]'
}

export const componentsApis = {
  buttonApi: 'c-btn',
  collapseApi: 'c-collapse',
  dropdownApi: 'c-dropdown',
  dateSelectorApi: 'c-date-selector',
  choiceListApi: 'c-choice-list'
}

export const attr = {
  departureDate: 'departureDate',
  location: 'wBookingCarRental__location',
  ageWarning: {
    age: 'ageWarningAge',
    text: 'ageWarningText'
  },
  itemCode: `data-${widgetApi}__item-code`,
  itemParticipants: `data-${widgetApi}__item-participants`,
  conditionsUrl: 'conditionsUrl',
  carCode: `data-${widgetApi}__car-code`,
  carDriver: `data-${widgetApi}__car-driver`,
  containerListId: `data-${widgetApi}__container-list-id`,
  trackAttr: 'data-track',
  sliderButtonDirectionAttr: 'data-c-slider__buttons--direction',
  containerCarListId: ' data-w-booking-car-rental__container-list-id'
}

export const defaults = {
  maxNumberOfCarsToBeShown: 3,
  minimumAge: 18,
  showEditTemplateForIncludedCars: true,
  maxNumberOfEquipmentsToBeSelected: undefined,
  arrowEditFormLeftPosition: 0
}

export const configOptions = {
  buttonTexts: 'buttonTexts',
  carTexts: 'carTexts',
  checkoutTexts: 'checkoutTexts',
  ageWarningConfiguration: 'ageWarningConfiguration',
  conditionGroup: 'conditionGroup',
  editDriverBirthdateEditable: 'editDriverBirthdateEditable',
  addExtraCarsConfiguration: 'addExtraCarsConfiguration',
  creditCardConfiguration: 'creditCardConfiguration',
  upgradeCarFeatureEnabled: 'upgradeCarFeatureEnabled',
  showCarsInOneRow: 'showCarsInOneRow',
  isCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow: 'isCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow'
}

export const carRentalOperations = {
  add: 'Add',
  patch: 'Patch',
  swap: 'Swap',
  remove: 'Remove'
}

export const carRentalMessageTypes = {
  info: 'info',
  warning: 'warning'
}

export const config = {
  scroll: { block: 'start', behavior: 'smooth' }
}

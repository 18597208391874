import { defaultPriceData, PriceTemplate, getACMPriceLabel } from '../../components/price/c-price.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'

/**
 * The PromotedPriceData contains all data needed to hydrate a PromotedPrice view
 *
 * @typedef  {Object}           PromotedPriceStickyResultData
 *
 * @property {PriceData}        price                       - Price data
 * @property {String}           buttonText                  - Promoted price text button
 * @property {String}           [acmUrl]                    - ACM url where to request info to be displayed
 * @property {String}           [acmModalId]                - Modal ID where the ACM info will be shown at
 * @property {Boolean}          [acmHideInfoIcon]           - Hide the ACM info icon (an 'i' in an circle).
 * @property {String}           [mandatoryExtraCostsText]   - The mandatory extra costs text, to be shown with the price.
 * @property {String}           [staticText]                - Some optional static text to be shown along the price.
 */
export const defaultPromotedPriceStickyResultData = {
  price: {},
  buttonText: '',
  acmUrl: '',
  acmModalId: '',
  acmHideInfoIcon: true,
  mandatoryExtraCostsText: '',
  staticText: '',
  fallbackTitle: '',
  fallbackDescription: ''
}

export const PromotedPriceStickyTemplate = (d) => {
  d = { ...defaultPromotedPriceStickyResultData, ...d }

  return `
    <div class="w-promoted-price__sticky">
      ${PriceTemplate({
        ...defaultPriceData,
        ...d.price,
        publicPriceDetail: d.skiPassPublicPriceText,
        hasUiTest: d.hasUiTest,
        acmHideInfoIcon: !!d.mandatoryExtraCostsText,
        label: d.mandatoryExtraCostsText && d.price.acmUrl && d.price.acmModalId
          ? getACMPriceLabel({ ...d, text: d.mandatoryExtraCostsText, staticText: d.staticText, acmUrl: d.price.acmUrl, modalId: d.price.acmModalId })
          : d.price.staticText,
        labelGrayed: true
      })}
      ${BtnTemplate({
        variant: 'flow',
        block: true,
        text: d.buttonText,
        extraClasses: `w-promoted-price__btn ${d.hasUiTest ? 'qa-promoted-price-click-trigger' : ''}`
      })}
    </div>
  `
}

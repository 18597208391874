'use strict';
(function () {
  const switchers = document.getElementsByClassName('js-docs-brand-switcher')

  for (let i = 0; i < switchers.length; i++) {
    listenChange(switchers[i])
  }

  function listenChange (switcher) {
    const currentBrand = switcher.options[switcher.selectedIndex].value
    let newBrand
    switcher.addEventListener('change', function () {
      newBrand = switcher.options[switcher.selectedIndex].value
      window.location.href = window.location.href.replace(currentBrand, newBrand)
    })
  }
})()

/**
 * The BookingGateNoResultsTemplateData contains all data organized to be easily displayed in a table
 *
 * @typedef {Object}          BookingGateNoResultsTemplateData
 *
 * @property {String}         warningMessage            - On top, saying to user there're no prices
 * @property {String}         actionsHeading            - Saying to user some actions could help
 * @property {String}         clearAllTitle             - Title for clear all
 * @property {String}         clearAllAction            - Action for clear all
 * @property {Object}         alternatives              - Possible alternatives, every key is a filter type with alternatives (that will be shown in chips)
 */

import { AbsoluteDialogTemplate } from '../../components/absolute-dialog/c-absolute-dialog.template'
import { ChipTemplate } from '../../components/chip/c-chip.template'

export const defaultBookingGateNoResultsData = {
  warningMessage: '[Sorry, but we could not find any price by your current filters selection]',
  actionsHeading: '[You could try again applying any suggested actions below]',
  clearAllTitle: '[You could reset your current selection]',
  clearAllAction: '[Clear all current filters]',
  alternatives: {}
}

/**
 * Returns an html that will wrap Price Table inner elements (room selector, flight bus selector, overview)
 *
 * @param {BookingGateNoResultsTemplateData} d
 *
 * @returns {String} Booking Gate Item HTML string
 */
export const BookingGateNoResultsTemplate = (d) => {
  d = { ...defaultBookingGateNoResultsData, ...d }
  // Append the clear all alternative
  d.alternatives = {
    ...d.alternatives,
    reset: {
      description: d.clearAllTitle,
      options: [
        {
          caption: d.clearAllAction,
          value: ''
        }
      ]
    }
  }
  return `
<div class="w-booking-gate__no-results">
  ${d.warningMessage
    ? AbsoluteDialogTemplate({
      body: d.warningMessage,
      alertType: 'warning',
      closable: false
    })
    : ''}
  
    ${d.alternatives && Object.entries(d.alternatives).length
      ? `<div class="w-booking-gate__no-results-alternatives">
          <div class="w-booking-gate__no-results-text"><p>${d.actionsHeading}</p></div>
            ${d.alternatives
              ? Object.entries(d.alternatives).map(([filterName, alternative]) => `
                <div class="w-booking-gate__alternatives-item">
                  <h2 class="c-title m-heading m-heading--tiny">${alternative.description}</h2>
    
                  <div class="w-booking-gate__alternatives-chip-wrapper">
                    ${alternative.options.map(option => ChipTemplate({
                      text: option.caption,
                      value: option.value,
                      attributes: { 'data-filter-name': filterName },
                      removable: false
                    })).join('')}
                  </div>
                </div>
              `).join('')
              : ''}
          </div>
        </div>`
    : ''}

`
}

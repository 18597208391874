import { ChoiceListTemplate, defaultChoiceListData } from '../../components/choice-list/c-choice-list.template'

export const RequiredCreditCardTemplate = (d) => {
  return `
    ${ChoiceListTemplate({
      ...defaultChoiceListData,
      ...{
        id: 'w-booking-car-rental__required-credit-card',
        method: 'multiple',
        required: true,
        minlength: '1',
        items: [{ text: d.creditCard.checkboxTexts.checkboxLabel, name: 'creditCard', value: 'creditCard', required: true, size: 'small', checked: d.creditCard.checkboxChecked }],
        extraClasses: 'w-booking-car-rental__checkout-field w-booking-car-rental__required-credit-card is-hidden',
        attributes: { 'data-w-booking-car-rental__item-field-credit-card': '', 'data-message-required': d.creditCard.checkboxTexts.checkboxRequiredMessage }
      }
    })}
  `
}

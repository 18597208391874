import { bool, objectOf, oneOfType, shape, string } from 'prop-types'

export const propTypes = {

  /** Car image */
  image: string.isRequired,

  /** Car type (small, compact, ...) */
  type: string.isRequired,

  /** Car name (Brand Model or similar) */
  name: string.isRequired,

  /** Optional car features */
  features: shape({
    passengers: string,
    luggage: string,
    doors: string,
    airConditioner: string,
    gearShift: string
  }),

  /** Optional label text  */
  labelText: string,

  /** Optional label extra class name  */
  labelExtraClasses: string,

  /** Extra class names to be appended on root element */
  extraClasses: string,

  /** Extra details content is rich content to be rendered inside car details html */
  extraDetailsHtml: string,

  /** Extra attributes to be appended on root element */
  attributes: objectOf(
    oneOfType([
      bool,
      string
    ])
  )
}

export const defaultProps = {
  image: '',
  type: '',
  name: '',
  features: {
    passengers: '',
    luggage: '',
    doors: '',
    airConditioner: '',
    gearShift: ''
  }
}

export default class BookingStepsTabsDataMapper {
  /**
   * Maps API response data to widget data
   *
   * @typedef {Object}         BookingStepsTabsData
   *
   * @property {Object}        [apiResponseData]    - Api response data
   */
  mapWidgetData (apiResponseData, variant) {
    const widgetData = {
      variant: variant.toLowerCase() || null,
      steps: apiResponseData ? apiResponseData.map(step => this._mapStep(step)) : []
    }
    return widgetData
  }

  _mapStep (step) {
    const widgetStep = {
      attributes: { 'data-w-booking-steps-tabs__tab': '' },
      extraClasses: 'w-booking-steps-tabs__item',
      text: step.text,
      textMobile: step.textMobile,
      link: {
        href: step.linkUrl,
        target: '_self',
        title: step.textMobile
      },
      active: step.active,
      disabled: step.disabled
    }
    return widgetStep
  }
}

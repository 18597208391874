/**
 * Car Features Icons
 *
 * The responsibility of knowing what icons are associated with what car features,
 * should not belong to widgets and rely somewhere as top as possible.
 *
 * We considered this component the better place to store them for now.
 *
 */
export const CAR_FEATURES_ICONS = {
  passengers: '1-person',
  luggage: 'suitcase',
  doors: 'car-door',
  airConditioner: 'snowflake',
  gearShift: 'transmission-manual'
}

import { registerWidget } from '../../../js/core/widget/widget-directory'
import Img from '../../components/img/main'
import { apiCaller } from '../../../js/helpers/api-caller'
import { CardPriceTemplate } from '../../components/card-price/c-card-price.template'
import Component from '../../../js/core/component/component'
import { mapperExcursionCard } from './data-mapper'
import { excursionsEvents } from '../../../js/document/event-types'
import registeredEvents from '../../../js/helpers/registered-events'
import { elementFromString } from '../../../js/document/html-helper'
const EventEmitter = require('eventemitter3')

const classNames = {
  widgetApi: 'w-excursions'
}

const attr = {
  track: 'data-track'
}

const widgetQueries = {
  excursionsRequestUrl: `data-${classNames.widgetApi}__request-url`,
  excursionsContainer: `[data-${classNames.widgetApi}__container]`,
  errorMessage: `[data-${classNames.widgetApi}__error-message]`,
  emptyMessage: `[data-${classNames.widgetApi}__empty-message]`,
  loader: `.${classNames.widgetApi}__loader`,
  allExcursionsLink: `[data-${classNames.widgetApi}__all-excursions-link]`
}

export default class Excursions {
  /**
  * Creates a new excursion widget.
  * @constructor
  * @param {HTMLElement} element The HTML element.
  */
  constructor (element) {
    this.element = element
    this._getHtmlElements()
    if (this.requestUrl) this._renderExcursions(this.requestUrl)

    // Setup to track events for data layers
    this.events = new EventEmitter()
    registeredEvents.registerWidgetEvents(classNames.widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
  }

  /**
  * Get the necessary elements from the html
  */
  _getHtmlElements () {
    this.requestUrl = this.element.getAttribute(widgetQueries.excursionsRequestUrl)
    this.container = this.element.querySelector(widgetQueries.excursionsContainer)
    this.errorMessage = this.element.querySelector(widgetQueries.errorMessage)
    this.emptyMessage = this.element.querySelector(widgetQueries.emptyMessage)
    this.loader = this.element.querySelector(widgetQueries.loader)
    this.allExcursionsLink = this.element.querySelector(widgetQueries.allExcursionsLink)
  }

  /**
  * Renders the excursions
  * @param {String} requestUrl
  */
  _renderExcursions (requestUrl) {
    const request = this._requestExcursions(requestUrl)
    request.then(response => {
      this.loader.style.display = 'none'
      if (!response) {
        this.errorMessage.style.display = 'block'
        return
      }
      if (response.allExcursionsLink) {
        this._setupAllExcursionsLink(response.allExcursionsLink)
      }
      if (!response.excursions || (response.excursions && !response.excursions.length)) {
        this.emptyMessage.style.display = 'block'
        return
      }
      response.excursions.forEach(item => {
        const mappedData = mapperExcursionCard(item)
        if (!mappedData) return
        this.container.appendChild(this._setupExcursionCard(mappedData))
      })
      if (!this.container.childNodes.length) {
        this.errorMessage.style.display = 'block'
      } else {
        Img.createInstancesOnDocument(this.container)
        Component.initDocumentComponentsFromAPI(this.container)
      }
    })
  }

  /**
  * Show, add the link and the tracking events (dataLayer) to the all excursion link
  * @param {String} link
  */
  _setupAllExcursionsLink (link) {
    this.allExcursionsLink.href = link
    this.allExcursionsLink.style.display = 'block'
    this.allExcursionsLink.addEventListener('click', () => this.events.emit(excursionsEvents.SEE_MORE_EXCURSIONS_CLICKED))
  }

  /**
  * Hydrate the excursion card with data, add the follow-up event and return it.
  * @param {Object} data
  * @return {HTMLElement} excursionCard
  */
  _setupExcursionCard (data) {
    const excursionCard = elementFromString(CardPriceTemplate(data))
    const excursionTitle = data.title
    excursionCard.addEventListener('click', () => {
      this.events.emit(excursionsEvents.EXCURSION_CLICKED, { excursionTitle })
    })
    return excursionCard
  }

  /**
  * Returns the requested excursions
  * @param {String} requestUrl
  * @return {Promise}
  */
  async _requestExcursions (requestUrl) {
    const result = await apiCaller(requestUrl)
    if (!result.success || !result.response) return null
    return result.response
  }
}

registerWidget(Excursions, classNames.widgetApi)

import { propTypes as CarItemPropTypes, defaultProps as defaultCarItemProps } from './c-car-item.props'
import { checkPropTypes } from 'prop-types'
import { encodeAttributes } from '../../../js/helpers/string'
import { BulletListTemplate } from '../bullet-list/c-bullet-list.template'
import { TitleTemplate, defaultTitleData } from '../title/c-title.template'
import { LabelTemplate, defaultLabelData } from '../label/c-label.template'
import { CAR_FEATURES_ICONS } from './config'
import { getTokenClass } from '../../../js/helpers/tokens'

/**
 * The CarItemData contains all data needed to hydrate a CarItem view
 *
 * @typedef {Object}                CarItemData
 *
 * @property {String}               name                                - Name of the car
 * @property {String}               type                                - Type of the car, typically the class name (examples: small, economy, compact, suv, ...)
 * @property {String}               image                               - The image url of the car rental
 * @property {Object}               [features]                          - The list of characteristics of the car rental (like number of doors, number of suitcases, air conditioned, gear transmission, ... )
 * @property {String}               [labelText]                         - The label for the available text
 * @property {String}               [labelExtraClasses]                 - The label for the available text
 * @property {String}               [extraClasses]                      Extra classes to be appended on root element
 * @property {Object}               [attributes]                        Extra attributes to be shown
 * @property {String}               [extraDetailsContent]               Extra details content is rich content to be rendered inside car details html
 */

// Import tokens
const tokensShared = require('./data/c-car-item__tokens.json')['c-car-item']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/car-item/data/c-car-item__tokens.json`)['c-car-item'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const carItemFeaturesToBulletListData = (features) => ({
  items: Object.entries(features)
    .map(([featureName, text]) => ({
      icon: CAR_FEATURES_ICONS[featureName] || '',
      text
    }))
})

/**
 * @param {CarItemData} carItemData
 *
 * @returns {String}
 */
export const CarItemTemplate = (carItemData) => {
  const d = { ...defaultCarItemProps, ...carItemData }
  checkPropTypes(CarItemPropTypes, d, 'prop', 'CarItemTemplate')

  const carItemFeaturesBulletListData = carItemFeaturesToBulletListData(d.features)

  return `
    <div class="c-car-item ${d.extraClasses ? d.extraClasses : ''}"
      ${d.attributes ? encodeAttributes(d.attributes) : ''}>
        <div class="c-car-item__image">
          <img src="${d.image}" alt="${d.name}">
        </div>
        <div class="c-car-item__details">
          <div class="c-car-item__heading">
            <div class="c-car-item__pre-heading">
              <div class="c-car-item__type ${getTokenClass('type-material', 'default', tokens)}">${d.type}</div>
              ${d.labelText
                ? LabelTemplate({
                  ...defaultLabelData,
                  ...{
                    text: d.labelText,
                    variant: 'sale',
                    extraClasses: 'c-car-item__label ' + d.labelExtraClasses
                  }
                })
                : ''}
            </div>
            ${TitleTemplate({
              ...defaultTitleData,
              ...{
                text: d.name,
                size: getTokenClass('title-size', 'default', tokens) || 'small',
                extraClasses: 'c-car-item__name'
              }
            })}
          </div>
          ${BulletListTemplate({
            ...carItemFeaturesBulletListData,
            variant: 'inline',
            extraClasses: 'c-car-item__features'
          })}
          ${d.extraDetailsContent ? d.extraDetailsContent : ''}
        </div>
    </div>
    `
}

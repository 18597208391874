import * as loadQueueHelper from '../../../../shared/js/document/load-queue'

loadQueueHelper.add(function () {
  const validateInvalid = function (elementId) {
    const dateSelector = window.document.querySelector('#' + elementId)
    if (dateSelector) {
      dateSelector.querySelector('[data-c-date-selector__type="day"]')['c-dropdown'].setProp('value', '30')
      dateSelector.querySelector('[data-c-date-selector__type="month"]')['c-dropdown'].setProp('value', '1')
      dateSelector.querySelector('[data-c-date-selector__type="year"]')['c-dropdown'].setProp('value', '2018')
      setTimeout(() => dateSelector['c-date-selector'].validate(), 500)
    }
  }

  validateInvalid('date-selector-invalid-1')
  validateInvalid('date-selector-invalid-2')
}, 2)

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingTermsConditions from '../../../../shared/modules/widgets/booking-terms-conditions/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const bookingTermsConditionsData = require('../../../../shared/modules/widgets/booking-terms-conditions/data/w-booking-terms-conditions__js-template.json')

loadQueueHelper.add(function () {
  initTermsConditionsSelector()
}, 2)

const initTermsConditionsSelector = async () => {
  const bookingTermsConditionsItems = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-terms-conditions"]')

  bookingTermsConditionsItems.forEach(element => {
    const bookingTermsConditions = new BookingTermsConditions(element)
    console.log(bookingTermsConditions)
  })
  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingTermsConditionsJsTemplate = document.querySelectorAll('[data-booking-terms-conditions-demo]')
  handleFetched(bookingTermsConditionsJsTemplate, bookingTermsConditionsData)
}

export const iconName = {
  CERTIFICATE: 'certificate',
  INVOICE: 'invoice',
  CAR: 'car',
  FILE: 'file'
}

export const documentType = {
  ATOL_CERTIFICATE: 'AtolCertificate',
  INVOICE: 'Invoice',
  TRAVEL_DOCUMENT: 'TravelDocument',
  STATIC_FILE_DOCUMENT: 'StaticFileDocument'
}

export const documentSubtype = {
  SET_OF_DOCUMENTS: 'SetOfDocuments',
  CAR_RENTAL: 'CarRental'
}

/**
  * Returns the mapped data for the download button
  * @param {Object} itemApiData
  * @return {Object | null}
  */
export const mapperDownloadButton = (itemApiData) => {
  if (!itemApiData || !itemApiData.title || !itemApiData.url) return null
  const downloadButtonData = {
    icon: getIcon(itemApiData.type, itemApiData.subtype),
    title: itemApiData.title,
    subtitle: itemApiData.name ? itemApiData.name : '',
    resourceUrl: itemApiData.url
  }
  return downloadButtonData
}

/**
  * Returns the name of the icon depending on the type
  * @param {String} type
  * @param {String} subtype
  * @return {String}
  */
export const getIcon = (type, subtype) => {
  switch (type) {
    case documentType.ATOL_CERTIFICATE:
      return iconName.CERTIFICATE
    case documentType.INVOICE:
      return iconName.INVOICE
    case documentType.TRAVEL_DOCUMENT:
      return subtype === documentSubtype.CAR_RENTAL ? iconName.CAR : iconName.FILE
    default:
      return iconName.FILE
  }
}

export const orderDocuments = (documents) => {
  const typePriority = {
    [documentType.TRAVEL_DOCUMENT]: 1,
    [documentType.INVOICE]: 2
  }

  const subtypePriority = {
    [documentSubtype.SET_OF_DOCUMENTS]: 1,
    [documentSubtype.CAR_RENTAL]: 2
  }

  documents.sort((a, b) => a.name.localeCompare(b.name))

  documents.sort((a, b) => {
    if (a.type !== b.type) {
      return (typePriority[a.type] || Infinity) - (typePriority[b.type] || Infinity)
    }

    if (a.type === documentType.TRAVEL_DOCUMENT && a.subtype !== b.subtype) {
      return (subtypePriority[a.subtype] || Infinity) - (subtypePriority[b.subtype] || Infinity)
    }

    return 0
  })

  return documents
}

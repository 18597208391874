import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import { BookingParticipantsBadgeTemplate } from './w-booking-participants-badge.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import BookingParticipantsBadgeDataMapper from './data-mapper'
import CryptoHelper from './crypto-helper'
import storage from '../../../js/document/web-storage'
import { bookingItemEvents } from '../../../js/document/event-types'
import registeredEvents from '../../../js/helpers/registered-events'
import { toCamelCase } from '../../../js/helpers/string'
import DateSelectorConfig from './date-selector-config'
import { widgetApi, widgetQueries, attr, componentsApi, cookieDefaultSettings, configOptions } from './config'

/**
 * BookingParticipantsBadge widget
 */
export default class BookingParticipantsBadge extends BookingBox {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   * @param {Object} [options={}]   - Options
   */
  constructor (element, options = {}) {
    super(element)
    this.element = element
    const filterConfigurationElement = this.element.querySelector(widgetQueries.configurationElement)
    this.dateSelectorsConfiguration = new DateSelectorConfig(this.element)
    this.dataMapper = new BookingParticipantsBadgeDataMapper()

    this.texts = this._readTextsFromAttributes()
    this.settings = { ...this._readSettingsFromAttributes(), ...this._readOptionsFromConfigurations(filterConfigurationElement) }

    if (CryptoHelper.isSupported()) {
      this.cryptoHelper = new CryptoHelper(this.settings.encryptAlgorithm, this.settings.encryptIv)
    } else {
      this.settings.cookieEnabled = false
      this.settings.encryptEnabled = false
    }

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.trackAttr) && { track: this.element.attributes[attr.trackAttr].value }
    })

    this.element[widgetApi] = {
      ...this.element[widgetApi],
      showDateSelectorForAdults: (this._showDateSelectorForAdults).bind(this)
    }

    // Attach events to the luggage elements in the widget
    this._attachEvents()
  }

  _showDateSelectorForAdults (showDateSelectorFields = false) {
    this.settings.showDateSelectorFieldsForAdults = showDateSelectorFields
  }

  async _init (widgetData) {
    const renderedHtml = BookingParticipantsBadgeTemplate(widgetData)
    const newContent = elementFromString(renderedHtml)
    this._render(newContent)
    // IMPORTANT: Put always the 'initDocumentComponentsFromAPI' after the '_render', otherwise the events are not binded correctly.
    Component.initDocumentComponentsFromAPI(newContent)
    this._attachEvents()
    await this._restoreEmailIfExists()
  }

  getServiceName () {
    return toCamelCase(widgetApi) || ''
  }

  _attachEvents () {
    this.form = this.element.querySelector(widgetQueries.form)
    if (this.form) {
      this.formApi = this.form[componentsApi.formApi]
    }
    this.emailInput = this.element.querySelector(widgetQueries.emailInput)
    if (this.emailInput) {
      this.emailInputApi = this.emailInput[componentsApi.textBoxApi]
      this.emailInputApi.events.on('keyup', (ev) => {
        this._disableSuccessState()
      })
    }
    this.successToggleElement = this.element.querySelector(widgetQueries.successToggle)

    this.saveButtons = [...this.element.querySelectorAll(widgetQueries.saveButtons)]
    if (this.saveButtons) {
      this.saveButtons.forEach(button => {
        button.addEventListener('click', (ev) => this._clickSaveButton(ev))
      })
    }

    this.nameInputs = [...this.element.querySelectorAll(widgetQueries.nameInputs)]
    this.birthdateInputs = [...this.element.querySelectorAll(widgetQueries.birthdateInputs)]
  }

  /**
   *  Will be executed once the mediator has fetched a response from the backend
   *
   * @param {Object}    data      - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.data = data.response
    await this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  async handleValidationClient () {
    const isValid = this.formApi.validate().every(v => v.isValid)
    if (!isValid) { super.openCollapseElement() }
    return isValid && super.handleValidationClient()
  }

  async getValidationData () {
    const eventArgs = await this._saveEmailAndGetDataChangeData()
    return eventArgs
  }

  async _updateWidgetData () {
    this.settings = { ...this.settings, ageCategoryRestrictions: this.data.ageCategoryRestrictions || [] }

    const widgetData = this.data ? this.dataMapper.map(this.data, this.componentId, this.texts, this.settings) : null

    if (widgetData) {
      const participantsNamesConfigured = this.data.participants && this.data.participants.filter(participant => (participant.name && participant.name !== ''))
      if (participantsNamesConfigured.length > 0) {
        super.setSuccessStateAndUpdateTitle()
      }
      super.showComponent()
      await this._init(widgetData)
    } else {
      super.hideComponent()
    }
  }

  _readTextsFromAttributes () {
    const texts = {
      emailLabel: this.element.dataset[attr.texts.emailLabel],
      emailPlaceholder: this.element.dataset[attr.texts.emailPlaceholder],
      emailPattern: this.element.dataset[attr.texts.emailPattern],
      emailWarningRequired: this.element.dataset[attr.texts.emailWarningRequired],
      emailWarningPattern: this.element.dataset[attr.texts.emailWarningPattern],
      emailMessageInfo: this.element.dataset[attr.texts.emailMessageInfo],
      emailMessageSuccess: this.element.dataset[attr.texts.emailMessageSuccess],
      emailSaveButton: this.element.dataset[attr.texts.emailSaveButton],
      participantsLabel: this.element.dataset[attr.texts.participantsLabel],
      participantsNamePlaceholder: this.element.dataset[attr.texts.participantsNamePlaceholder],
      participantsNamePattern: this.element.dataset[attr.texts.participantsNamePattern],
      participantsNamePatternWarning: this.element.dataset[attr.texts.participantsNamePatternWarning],
      participantsSaveButton: this.element.dataset[attr.texts.participantsSaveButton],
      participantsCancelButton: this.element.dataset[attr.texts.participantsCancelButton]
    }
    return texts
  }

  _readSettingsFromAttributes () {
    const settings = {
      ...cookieDefaultSettings,
      ...{
        cookieEnabled: this._parseBool(this.element.dataset[attr.settings.cookieEnabled]),
        encryptEnabled: this._parseBool(this.element.dataset[attr.settings.encryptEnabled]),
        encryptKey: this.element.dataset[attr.settings.encryptKey],
        encryptIv: this.element.dataset[attr.settings.encryptIv],
        isEmailRequired: this._parseBool(this.element.dataset[attr.settings.isEmailRequired])
      }
    }

    return settings
  }

  _readOptionsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)

      const dateSelectorSettings = this.dateSelectorsConfiguration.setDateSelectorFieldsSettings(configuration[configOptions.dateSelectorsFieldSettings])
      return {
        showEmailSection: configuration[configOptions.showEmailSection],
        collapseNamesSection: configuration[configOptions.collapseNamesSection],
        showDateSelectorFields: configuration[configOptions.showDateSelectorFields],
        showDateSelectorFieldsForAdults: configuration[configOptions.showDateSelectorFieldsForAdults],
        dateSelectorsFieldSettings: dateSelectorSettings
      }
    }
  }

  _parseBool (value) {
    let num
    return value != null &&
        (!isNaN(num = +value)
          ? !!num
          : !!String(value).toLowerCase().replace(!!0, ''))
  }

  _disableSuccessState () {
    if (this.successToggleElement) {
      this.successToggleElement.classList.remove(attr.classes.isSuccess)
    }
  }

  _render (el) {
    const bodyElement = this._resolveBodyElement()
    if (bodyElement) {
      flush(bodyElement)
      bodyElement.appendChild(el)
    }
  }

  _resolveBodyElement () {
    if (this.bodyElement) {
      return this.bodyElement
    }

    // TODO Improve the code below as the 'this.bodyElement' is only rendered as part of the Razor rendering
    // when rendering only the javascript part, we don't have a body element (see attribute 'data-w-booking-item__body')
    // and the rendered javascript doesn't knows where to render.
    // This workaround is searching for the container used in documentation page that has class name 'c-docs-component-container'
    // If the container is 'this.element' itself, this.element is used. Otherwise we are searching if any item inside 'this.element'
    // is marked with this class name.
    if (this.element.classList.contains('c-docs-component-container')) {
      return this.element
    }

    const docContainer = this.element.querySelector('.c-docs-component-container')
    if (docContainer) {
      return docContainer
    }

    return null
  }

  async _clickSaveButton (ev) {
    ev.preventDefault()

    if (this._areAllFieldsEmpty()) {
      return
    }

    if (this.formApi.validate().some(v => !v.isValid)) {
      return
    }

    const eventArgs = await this._saveEmailAndGetDataChangeData()
    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  async _saveEmailAndGetDataChangeData () {
    await this._saveEmailIfExists()

    const eventArgs = {
      method: this._method,
      url: this._url
    }
    if (this._method !== 'GET') {
      eventArgs.body = {
        participants: this._getParticipantsData()
      }
    }
    return eventArgs
  }

  _areAllFieldsEmpty () {
    let areAllFieldsEmpty = true

    const emailValue = (this.emailInputApi && this.emailInputApi.getProp('value')) ? this.emailInputApi.getProp('value').trim() : ''
    if (emailValue !== '') {
      areAllFieldsEmpty = false
    }
    this.nameInputs.forEach(nameInput => {
      const nameValue = nameInput[componentsApi.textBoxApi].getProp('value') ? nameInput[componentsApi.textBoxApi].getProp('value').trim() : ''
      if (nameValue !== '') {
        areAllFieldsEmpty = false
      }
    })

    if (this.settings.showDateSelectorFields) {
      this.birthdateInputs && this.birthdateInputs.forEach(birthdateInput => {
        const customerBirthdate = birthdateInput[componentsApi.dateSelectorApi].getProp('date')
        if (customerBirthdate !== '') {
          areAllFieldsEmpty = false
        }
      })
    }
    return areAllFieldsEmpty
  }

  _getParticipantsData () {
    const participantsInfo = []

    const emailValue = (this.emailInputApi && this.emailInputApi.getProp('value'))

    this.nameInputs.forEach(nameInput => {
      const participantId = nameInput.dataset[attr.participantId]

      const participantInfo = {
        id: participantId,
        name: nameInput[componentsApi.textBoxApi].getProp('value')
      }

      if (participantId && participantId === '1') {
        participantInfo.email = emailValue
      }

      participantsInfo.push(participantInfo)
    })

    if (this.settings.showDateSelectorFields) {
      this.birthdateInputs && this.birthdateInputs.forEach(birthdateInput => {
        const participantId = birthdateInput.dataset[attr.participantId]
        const customerBirthdate = birthdateInput[componentsApi.dateSelectorApi].getProp('date')
        if (participantsInfo) {
          const customerInfo = participantsInfo.find(participant => participant.id === participantId)
          if (customerInfo) { customerInfo.birthdate = customerBirthdate }
        }
      })
    }

    return participantsInfo
  }

  async _restoreEmailIfExists () {
    if (this.settings.cookieEnabled && this.emailInputApi) {
      const currentValue = this.emailInputApi.getProp('value')
      if (!currentValue || currentValue === '') {
        const cookieValue = storage.cookie.get(this.settings.cookieName)
        try {
          if (cookieValue && cookieValue.email) {
            const emailDescrypted = await this._decryptCookie(cookieValue.email)
            this.emailInputApi.setProp('value', emailDescrypted)
          }
        } catch (ex) {
          console.log('Could no deserialize email: ' + ex.message)
        }
      }
    }
  }

  async _saveEmailIfExists () {
    if (this.settings.cookieEnabled && this.emailInputApi) {
      const emailValue = this.emailInputApi.getProp('value')
      if (emailValue && emailValue !== '') {
        try {
          const emailValueEncrypted = await this._encryptCookie(emailValue)
          const cookieValue = { email: emailValueEncrypted }
          if (cookieValue) {
            storage.cookie.set(this.settings.cookieName, cookieValue, this.settings.cookieSettings)
          }
        } catch (ex) {
          console.log('Could no serialize email: ' + ex.message)
        }
      }
    }
  }

  async _encryptCookie (value) {
    if (this.settings.encryptEnabled) {
      return this.cryptoHelper.encrypt(value, this.settings.encryptKey)
    } else {
      return value
    }
  }

  async _decryptCookie (encrypted) {
    if (this.settings.encryptEnabled) {
      return this.cryptoHelper.decrypt(encrypted, this.settings.encryptKey)
    } else {
      return encrypted
    }
  }
}

registerWidget(BookingParticipantsBadge, widgetApi)

import { encodeAttributes } from '../../../js/helpers/string'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import button group tokens
const tokensShared = require('./data/c-button-group__tokens.json')['c-button-group']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/box/data/c-button-group__tokens.json`)['c-button-group'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

/**
 * The ButtonGroupData contains all data needed to hydrate a Button Group selector view
 *
 * @typedef {Object}          ButtonGroupData
 *
 * @property {String}         id                  - The element id
 * @property {String}         extraClasses
 * @property {String}         html                - HTML snippet
 * @property {Boolean}        proportional        - If true, buttons have same distance between each other
 * @property {Boolean}        disabled            - If true, buttons are disabled
 * @property {String}         [variant]           - Button Group's variant
 * @property {Object}         [attributes]        - Extra attributes
 */

export const defaultButtonGroupData = {
  extraClasses: '',
  html: '',
  variant: 'default',
  proportional: false,
  disabled: false,
  attributes: {}
}

export const ButtonGroupTemplate = (d) => {
  d = { ...defaultButtonGroupData, ...d }
  return `
<div ${d.id ? `id="${d.id}"` : ''} class="c-button-group ${d.extraClasses} ${d.proportional ? 'c-button-group--proportional' : ''} ${d.variant ? getTokenClass('variant', d.variant, tokens) : ''} ${d.disabled ? 'is-disabled' : ''}"
  data-js-component="c-button-group" ${d.attributes ? encodeAttributes(d.attributes) : ''} >
    ${d.html}
</div>
`
}

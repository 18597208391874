import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import BookingTermsAndConditionsDataMapper from './data-mapper'
import { BookingTermsAndConditionsTemplate } from './w-booking-terms-conditions.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'

const widgetApi = 'w-booking-terms-conditions'

const widgetQueries = {
  choiceList: `[data-${widgetApi}__choice-list]`,
  choiceListApi: 'c-choice-list',
  configurationElement: `[data-${widgetApi}__configuration]`,
  body: `[data-${widgetApi}__body]`
}

/**
 * BookingTermsConditions widget
 */
export default class BookingTermsConditions extends BookingBox {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   * @param {Object} [options={}]   - Options
   */
  constructor (element, options = {}) {
    super(element)
    this.element = element
    this.bodyElement = this.element.querySelector(widgetQueries.body)

    const filterConfigurationElement = this.element.querySelector(widgetQueries.configurationElement)
    this.configurations = this._readOptionsFromConfigurations(filterConfigurationElement)

    this.dataMapper = new BookingTermsAndConditionsDataMapper()

    this.getChoiceListApi()
  }

  async handleFetched (data) {
    this._updateWidgetData(data.response)
    return super.handleFetched(data.response)
  }

  _updateWidgetData (rawData) {
    let widgetData

    let apiData = {}
    if (rawData.serviceGroupsModified) {
      apiData = { ...{ serviceGroupsModified: rawData.serviceGroupsModified } }

      widgetData = this.dataMapper.mapWidgetData(apiData, this.configurations)

      if (widgetData) {
        this.showComponent()
        this._init(widgetData)
      } else {
        this.hideComponent()
      }
    }
  }

  _init (data) {
    const renderedHtml = BookingTermsAndConditionsTemplate(data)
    const newContent = elementFromString(renderedHtml)
    this._render(newContent)
    Component.initDocumentComponentsFromAPI(newContent)

    this.getChoiceListApi()
    this._attachChoiceListEvents()
  }

  _render (el) {
    flush(this.bodyElement)
    if (el) {
      this.bodyElement.appendChild(el)
    }
  }

  getChoiceListApi () {
    this.choiceList = this.element.querySelector(widgetQueries.choiceList) || null
    this.choiceListApi = (this.choiceList && this.choiceList[widgetQueries.choiceListApi]) || null
  }

  _attachChoiceListEvents () {
    if (this.choiceListApi) {
      this.choiceListApi.enableErrors()
    }
  }

  _readOptionsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)
      const options = {
        conditions: configuration.conditions
      }
      return options
    }
  }

  /**
  * Ensures that all check-boxes are checked
  */
  async handleValidationClient () {
    const choiceListApi = this.choiceList[widgetQueries.choiceListApi]
    const isValid = choiceListApi.validate().isValid
    this._setErrorMessageVisibility(!isValid)
    console.log('Validation of BookingTermsConditions. Is valid: ' + isValid)
    return isValid
  }

  _setErrorMessageVisibility (hasError) {
    if (hasError) {
      this.choiceList.classList.add('has-error')
    } else {
      this.choiceList.classList.remove('has-error')
    }
  }
}

registerWidget(BookingTermsConditions, widgetApi)

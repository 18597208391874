import { propTypes, defaultProps } from './w-room-occupancy.props'
import { checkPropTypes } from 'prop-types'
import { NumberStepperTemplate } from '../../components/number-stepper/c-number-stepper.template'
import { PriceTemplate } from '../../components/price/c-price.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { LabelTemplate } from '../../components/label/c-label.template'
import { encodeAttributes } from '../../../js/helpers/string'
import { CollapseTemplate, defaultCollapseData } from '../../components/collapse/c-collapse.template'
import { PhotoGalleryTemplate } from '../../components/photo-gallery/c-photo-gallery.template'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import room occupancy tokens
const tokensShared = require('./data/w-room-occupancy__tokens.json')['w-room-occupancy']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/widgets/room-occupancy/data/w-room-occupancy__tokens.json`)['w-room-occupancy'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

/**
 *  The RoomOccupancyTemplate contains all the data needed to hydrate a RoomOccupancy component.
 *
 *  @typedef  {Object}      RoomOccupancyTemplate
 *
 *  @property {String}      id                                - The identifier of the room
 *  @property {String}      extraClasses                      - The extra styles that will be applied
 *  @property {String}      attributes                        - Attributes to add to the root element of the widget
 *  @property {String}      roomWord                          - The word to say 'room'
 *  @property {String}      roomNumber                        - The room number to numerate the rooms
 *  @property {String}      errorMessage                      - The error message to show when the occupancy of the room is wrong
 *  @property {Boolean}     onRequest                         - A boolean that indicates if the room is on request or not
 *  @property {String}      onRequestLabel                    -  The text to show when a room is "on request"
 *  @property {String}      stockWarningMessage               - The text to show when as a warning on the stock
 *  @property {String}      roomName                          - Name of the room
 *  @property {String}      roomDescription                   - Occupancy description of the room
 *  @property {Decimal}     price                             - The price value
 *  @property {Array}       characteristics                   - Array of texts enumerating the characteristics of the room
 *  @property {Object[]}    images                            - Array of objects representing the images of the room
 *  @property {String}      roomTypeDescriptionTitle          - The text to show as collapse header of the room characteristics
 *  @property {Boolean}     removable                         - Indicates if the room can be removed, so it will show an icon/button to remove the room
 *  @property {Boolean}     isFlexibleAllocation              - Indicates if the allocation can be changed (flexible) or not
 *  @property {String}      characteristicsContentLayout      - Defines the layout of the characteristics content
 *  @property {Array}       occupancySelectors                - An Array of occupancy selectors {OccSelector} with the information for them
 *  @property {String}      OccupancySelector.id              - identifier of the selector (ex: 'adults')
 *  @property {String}      OccupancySelector.current         - value of the occupancy (ex: 2)
 *  @property {String}      OccupancySelector.min             - min value (ex: 1)
 *  @property {String}      OccupancySelector.max             - max value (ex: 4)
 *  @property {String}      OccupancySelector.name            - name of the occupancy (ex: "Volwassen")
 *  @property {String}      OccupancySelector.description     - description of the occupancy (ex: "from 17 years")
 *
 */
export const RoomOccupancyTemplate = (props) => {
  const d = { ...defaultProps, ...props }
  checkPropTypes(propTypes, d, 'prop', 'RoomOccupancyTemplate')

  return `
  <div id="${d.id}" class="w-room-occupancy ${d.extraClasses ? d.extraClasses : ''}"
    data-js-widget="w-room-occupancy"
    ${d.attributes ? encodeAttributes(d.attributes) : ''}>
    <div class='w-room-occupancy__room-title-row'>
      <span class="w-room-occupancy__room-title-icon m-icon m-icon--bed"></span>
      <h3 class='w-room-occupancy__room-title-text'>${d.roomWord} ${d.roomNumber ? d.roomNumber : ''}</h3>
    </div>
    <div class="w-room-occupancy__details-wrapper">
      <div class="w-room-occupancy__details ${d.errorMessage ? 'w-room-occupancy__details--invalid' : ''}">
        <div class="w-room-occupancy__details-header">
          <div class="w-room-occupancy__header">
            ${d.onRequest && d.onRequestLabel
            ? LabelTemplate({
              text: d.onRequestLabel,
              variant: 'sale',
              extraClasses: 'w-room-occupancy__onrequest'
            })
            : ''}
            ${d.stockWarningMessage
              ? LabelTemplate({
                text: d.stockWarningMessage,
                variant: 'sale',
                extraClasses: 'w-room-occupancy__availability'
              })
            : ''}
            <div class="w-room-occupancy__name">
              <span class="w-room-occupancy__name-text m-body m-body--large">${d.roomName}</span>
              ${d.roomDescription
                ? `<span class="w-room-occupancy__description">${d.roomDescription}</span>`
                : ''}
            </div>
          </div>
          <div class="w-room-occupancy__price">
            ${PriceTemplate(d.price)}
          </div>
        </div>
        ${d.errorMessage
          ? `<div class="w-room-occupancy__error-message">
                ${d.errorMessage}
            </div>`
          : ''
        }
        ${d.isFlexibleAllocation && (d.occupancySelectors && d.occupancySelectors.length > 0)
          ? `<div class="w-room-occupancy__occupancy-selector">
                ${d.occupancySelectors.map(occupancySelector => NumberStepperTemplate(getNumberStepperData(d.id, occupancySelector))).join(' ')}
            </div>`
          : ''}

        ${d.characteristics
          ? `<div class="w-room-occupancy__characteristics">
              <div class="w-room-occupancy__characteristics-trigger">
                <button class="m-button--clean c-collapse__trigger w-room-occupancy__characteristics-trigger-btn" data-c-collapse__id="${d.id}-${d.index}"  data-c-collapse__action="toggle">
                  <span class="c-collapse__trigger-text m-body--small">${d.roomTypeDescriptionTitle}</span>
                  <span class="m-icon m-icon--chevron-down c-collapse__trigger-icon"></span>
                </button>
              </div>
              ${CollapseTemplate({
                ...defaultCollapseData,
                ...{
                  id: `${d.id}-${d.index}`,
                  html: GetRoomCharacteristicsHtml(d)
                }
              })}
            </div>`
          : ''
        }
      </div>
      ${d.removable
        ? BtnTemplate({
          variant: 'icon-only',
          icon: 'bin',
          extraClasses: 'w-room-occupancy__remove-button',
          jsApi: true
        })
        : ''}
    </div>
  </div> `
}

function getNumberStepperData (id, d) {
  return {
    id: id + `_${d.id}`,
    value: d.current,
    minValue: d.min,
    maxValue: d.max,
    step: 1,
    label: d.name,
    labelDescription: d.description,
    extraClasses: 'w-room-occupancy__number-stepper',
    attributes: { 'data-age-profile': d.id }
  }
}

const GetRoomCharacteristicsHtml = (d) => {
  let html = ''
  if (d.images) {
    html = PhotoGalleryTemplate({ popupTitle: d.title, elements: d.images })
  }
  return `${html}
  <div class="w-room-list-item__characteristics-content sp-stack-children--medium ${getTokenClass('characteristicsContentLayout', d.characteristicsContentLayout, tokens)}">
    <ul class="sp-stack-children--tiny">
      ${d.characteristics.map((item) => `<li class="m-body--small">${item}</li>`).join('')}
    </ul>
  </div>
`
}

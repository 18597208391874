// Components
// -----------------
import { BoxTemplate, defaultBoxData } from '../../shared/modules/components/box/c-box.template'
import { LabelTemplate, defaultLabelData } from '../../shared/modules/components/label/c-label.template'
import { TextboxTemplate, defaultTextboxData } from '../../shared/modules/components/textbox/c-textbox.template'
import { TooltipTemplate, defaultTooltipData } from '../../shared/modules/components/tooltip/c-tooltip.template'
import { PhoneInputTemplate } from '../../shared/modules/components/phone-input/c-phone-input.template'
import { DropdownTemplate } from '../../shared/modules/components/dropdown/c-dropdown.template'
import Textarea from '../../shared/modules/components/textarea/main'
import { BreadcrumbsTemplate, defaultBreadcrumbsData } from '../../shared/modules/components/breadcrumbs/c-breadcrumbs.template'
import Textbox from '../../shared/modules/components/textbox/main'
import { PriceTemplate, defaultDemoPriceData } from '../../shared/modules/components/price/c-price.template'
import { StepsTemplate } from '../../shared/modules/components/steps/c-steps.template'
import { SwitchTemplate, defaultSwitchData } from '../../shared/modules/components/switch/c-switch.template'
import { TabsNavTemplate, defaultTabsNavData } from '../../shared/modules/components/tabs/c-tabs__nav.template'
import { TransportTicketTemplate } from '../../shared/modules/components/transport-ticket/c-transport-ticket.template'
import { CarItemTemplate } from '../../shared/modules/components/car-item/c-car-item.template'
import { defaultCarItemProps } from '../../shared/modules/components/car-item/c-car-item.props'
import { CalendarFieldTemplate } from '../../shared/modules/components/calendar-field/c-calendar-field.template'
import { defaultCalendarFieldProps } from '../../shared/modules/components/calendar-field/c-calendar-field.props'
import { TextareaTemplate } from '../../shared/modules/components/textarea/c-textarea.template'
import { ModalTemplate } from '../../shared/modules/components/modal/c-modal.template'
import { ModalV2Template } from '../../shared/modules/components/modal-v2/c-modal-v2.template'
import { DateSelectorTemplate, defaultDateSelectorData } from '../../shared/modules/components/date-selector/c-date-selector.template'
import { ChoiceListTemplate } from '../../shared/modules/components/choice-list/c-choice-list.template'
import { BtnTemplate, defaultButtonData } from '../../shared/modules/components/btn/c-btn.template'
import { NavTemplate } from '../../shared/modules/components/nav/c-nav.template'
import { DownloadButtonTemplate } from '../../shared/modules/components/download-button/c-download-button.template'
import { CardPriceTemplate } from '../../shared/modules/components/card-price/c-card-price.template'
import { CheckboxTemplate } from '../../shared/modules/components/checkbox/c-checkbox.template'
import { RadioButtonTemplate } from '../../shared/modules/components/radio-button/c-radio-button.template'
import { ProgressBarTemplate } from '../../shared/modules/components/progress-bar/c-progress-bar.template'
import { PhotoGalleryTemplate } from '../../shared/modules/components/photo-gallery/c-photo-gallery.template'
import { ProductCardTemplate } from '../../shared/modules/components/product-card/c-product-card.template'
import { FormTemplate } from '../../shared/modules/components/form/c-form.template'

// Widgets
// --------------------
import { BookingMessageTemplate } from '../../shared/modules/widgets/booking-message/w-booking-message.template'
import { BookingParticipantsBadgeTemplate } from '../../shared/modules/widgets/booking-participants-badge/w-booking-participants-badge.template'
import BookingParticipantsBadge from '../../shared/modules/widgets/booking-participants-badge/main'
import { InternalMessagesTemplate } from '../../shared/modules/widgets/booking-error/w-booking-error.template'
import BookingError from '../../shared/modules/widgets/booking-error/main'
import { BookingFilterableServicesTemplate } from '../../shared/modules/widgets/booking-filterable-services/w-booking-filterable-services.template'
import BookingFilterableServices from '../../shared/modules/widgets/booking-filterable-services/main'
import { TransportSummaryTemplate } from '../../shared/modules/widgets/transport-summary/w-transport-summary.template'
import BookingTransportSummary from '../../shared/modules/widgets/booking-transport-summary/main'
import { BookingRedeemerListTemplate } from '../../shared/modules/widgets/booking-redeemer/w-booking-redeemer-list.template'
import BookingRedeemer from '../../shared/modules/widgets/booking-redeemer/main'
import { DDGridTemplate } from '../../shared/modules/widgets/dd-grid/w-dd-grid.template'
import { en as DdGridDefaultLocales } from '../../shared/modules/widgets/dd-grid/locales'
import { RoomOccupancyTemplate } from '../../shared/modules/widgets/room-occupancy/w-room-occupancy.template'
import { defaultRoomOccupancyProps } from '../../shared/modules/widgets/room-occupancy/w-room-occupancy.props'
import { VoucherRedeemerTemplate } from '../../shared/modules/widgets/voucher-redeemer/w-voucher-redeemer.template'
import VoucherRedeemer from '../../shared/modules/widgets/voucher-redeemer/main'
import { VoucherTemplate } from '../../shared/modules/widgets/voucher/w-voucher.template'
import Voucher from '../../shared/modules/widgets/voucher/main'
import { RefundTemplate } from '../../shared/modules/widgets/refund/refund.template'
import Refund from '../../shared/modules/widgets/refund/main'
import { TransferTicketTemplate } from '../../shared/modules/widgets/transfer-ticket/w-transfer-ticket.template'
import { SelectedStateTemplate } from '../../shared/modules/widgets/selected-state/w-selected-state.template'
import SelectedStateDataModel from '../../shared/modules/widgets/selected-state/selected-state-data-model'
import { ContentUspsTemplate } from '../../shared/modules/widgets/content-usps/w-content-usps.template'

const priceTableGroupedByDurationData = require('../assets/api/price-table-grouped-by-duration').data
const selectedStateDemoData = require('../assets/api/selected-state')
const selectedStateDemoLocales = require('../../shared/modules/widgets/selected-state/data/w-selected-state__demo-locales')

const selectedStateDataModel = new SelectedStateDataModel(selectedStateDemoData, // this.options.data,
  {
    id: 'w-selected-state-id',
    locales: selectedStateDemoLocales
  })

// README
//  Use Type or Template. One of the two.
//  - Type is used if you either:
//    - Set renderedInConstructor to true
//    - Set a value for renderMethod
//    Usually Type is used only for Widgets.
// ---------------------------------------------
export const TemplatesDictionary = {
  'c-box': {
    template: BoxTemplate,
    data: defaultBoxData
  },
  'c-label': {
    template: LabelTemplate,
    data: defaultLabelData
  },
  'c-breadcrumbs': {
    template: BreadcrumbsTemplate,
    data: defaultBreadcrumbsData
  },
  'c-calendar-field': {
    template: CalendarFieldTemplate,
    data: defaultCalendarFieldProps
  },
  'c-price': {
    template: PriceTemplate,
    data: defaultDemoPriceData
  },
  'c-steps': {
    template: StepsTemplate
  },
  'c-switch--full-width': {
    template: SwitchTemplate,
    data: {
      ...defaultSwitchData,
      variant: 'full-width',
      text: 'switch full width',
      modal: null
    }
  },
  'c-switch--with-modal': {
    template: SwitchTemplate,
    data: {
      ...defaultSwitchData,
      text: 'switch full width with modal',
      modal: {
        id: 'c-switch--with-modal',
        bodyContent: 'Lorem ipsum body',
        title: 'lorem ipsum title'
      }
    }
  },
  'c-tabs': {
    template: TabsNavTemplate,
    data: defaultTabsNavData
  },
  'c-transport-ticket': {
    template: TransportTicketTemplate
  },
  'c-car-item': {
    template: CarItemTemplate,
    data: defaultCarItemProps
  },
  'c-textarea': {
    template: TextareaTemplate,
    Type: Textarea
  },
  'c-textbox': {
    template: TextboxTemplate,
    Type: Textbox
  },
  'c-textbox[type="password"]': {
    template: TextboxTemplate,
    data: {
      ...defaultTextboxData,
      type: 'password',
      placeholder: 'Enter you Password',
      icon: 'lock'
    }
  },
  'c-textbox-with-tooltip': {
    template: TextboxTemplate,
    data: {
      ...defaultTextboxData,
      tooltip: {
        text: 'this is the tooltip text',
        html: '<div class="m-icon m-icon--information-circle c-tooltip__icon"></div>'
      },
      label: 'My textbox',
      icon: 'heart',
      disabled: true
    }
  },
  'c-tooltip': {
    template: TooltipTemplate,
    data: {
      ...defaultTooltipData,
      text: 'this is the tooltip text',
      html: '<div class="m-icon m-icon--size-large m-icon--information-circle c-tooltip__icon"></div>'
    }
  },
  'c-phone-input': {
    template: PhoneInputTemplate
  },
  'c-dropdown': {
    template: DropdownTemplate
  },
  'c-modal': {
    template: ModalTemplate
  },
  'c-modal-v2': {
    template: ModalV2Template
  },
  'c-date-selector-with-tooltip': {
    template: DateSelectorTemplate,
    data: {
      ...defaultDateSelectorData,
      tooltip: {
        text: 'this is the tooltip text',
        html: '<div class="m-icon m-icon--information-circle c-tooltip__icon"></div>'
      },
      mainLabel: 'My date selector'
    }
  },
  'c-choice-list': {
    template: ChoiceListTemplate
  },
  'c-btn': {
    template: BtnTemplate,
    data: {
      ...defaultButtonData,
      text: 'Default button'
    }
  },
  'c-download-button': {
    template: DownloadButtonTemplate
  },
  'c-card-price': {
    template: CardPriceTemplate
  },
  'c-checkbox': {
    template: CheckboxTemplate
  },
  'c-radio-button': {
    template: RadioButtonTemplate
  },
  'c-product-card': {
    template: ProductCardTemplate
  },
  'c-progress-bar': {
    template: ProgressBarTemplate
  },
  'c-photo-gallery': {
    template: PhotoGalleryTemplate
  },
  'c-nav': {
    template: NavTemplate
  },
  'c-form': {
    template: FormTemplate
  },
  'w-booking-message': {
    template: BookingMessageTemplate
  },
  'w-booking-participants-badge': {
    template: BookingParticipantsBadgeTemplate,
    Type: BookingParticipantsBadge,
    renderMethod: 'handleFetched'
  },
  'w-booking-error': {
    template: InternalMessagesTemplate,
    Type: BookingError,
    renderMethod: 'handleFetched'
  },
  'w-booking-filterable-services': {
    template: BookingFilterableServicesTemplate,
    Type: BookingFilterableServices,
    renderMethod: 'handleFetched'
  },
  'w-booking-redeemer': {
    template: BookingRedeemerListTemplate,
    Type: BookingRedeemer,
    renderMethod: 'handleFetched'
  },
  'w-booking-transport-summary': {
    Type: BookingTransportSummary,
    renderMethod: 'handleFetched'
  },
  'w-transport-summary': {
    template: TransportSummaryTemplate
  },
  'w-dd-grid': {
    template: DDGridTemplate,
    data: {
      bestValues: priceTableGroupedByDurationData.bestValues,
      bestValuesCaption: priceTableGroupedByDurationData.bestValuesCaption,
      mandatoryExtraCostsText: priceTableGroupedByDurationData.mandatoryExtraCostsText,
      prices: priceTableGroupedByDurationData.prices,
      highlightInfo: priceTableGroupedByDurationData.highlightInfo,
      texts: DdGridDefaultLocales
    }
  },
  'w-room-occupancy': {
    template: RoomOccupancyTemplate,
    data: defaultRoomOccupancyProps
  },
  'w-voucher-redeemer': {
    template: VoucherRedeemerTemplate,
    Type: VoucherRedeemer,
    renderedInConstructor: true
  },
  'w-voucher': {
    template: VoucherTemplate,
    Type: Voucher,
    renderedInConstructor: true
  },
  'w-refund': {
    template: RefundTemplate,
    Type: Refund,
    renderedInConstructor: true
  },
  'w-transfer-ticket': {
    template: TransferTicketTemplate
  },
  'w-selected-state': {
    template: SelectedStateTemplate,
    data: selectedStateDataModel._getSelectedStateTemplateData() // selectedStateDemoData2
  },
  'w-content-usps': {
    template: ContentUspsTemplate
  }
}

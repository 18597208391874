import { encodeAttributes } from '../../../js/helpers/string'
/**
 * The TabsContentWrapperData contains all data needed to hydrate a Tab Content Wrapper selector view
 *
 * @typedef {Object}          TabsContentWrapperData
 *
 * @property {String}         id                - Tab content's id
 * @property {String}         html              - HTML snippet
 * @property {Boolean}        scrollOnChange    - If true, scroll will to top when changing tab
 * @property {String}         [extraClasses]    - CSS extra classes.
 */

export const defaultTabsContentWrapperData = {
  id: '',
  html: '',
  scrollOnChange: false,
  track: '',
  extraClasses: '',
  anchorable: false
}

export const TabsContentWrapperTemplate = (d) => {
  d = { ...defaultTabsContentWrapperData, ...d }
  return `
<div id="${d.id}" class="c-tabs__content-wrapper ${d.extraClasses}"
    data-js-component="c-tabs"
    ${d.anchorable ? 'data-c-tabs__anchorable' : ''} 
    ${d.track ? `data-track=${d.track}` : ''} 
    ${d.scrollOnChange ? 'data-c-tabs__scroll-on-change' : ''}
    ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  ${d.html}
</div>
`
}

import { registerWidget } from '../../../js/core/widget/widget-directory'
import { apiCaller } from '../../../js/helpers/api-caller'
import { TransferTicketTemplate } from '../transfer-ticket/w-transfer-ticket.template'
import { elementFromString } from '../../../js/document/html-helper'
import { transferTicketMapper } from './data-mapper'

export const transferTicketType = {
  OUTBOUND: 'a',
  INBOUND: 'd'
}

const widgetApi = 'w-transfer-summary'

const widgetQueries = {
  settings: `[data-${widgetApi}__settings]`,
  itemsContainer: `[data-${widgetApi}__items-container]`,
  departureTicketContainer: `[data-${widgetApi}__departure-ticket-container]`,
  returnTicketContainer: `[data-${widgetApi}__return-ticket-container]`,
  departureDateContainer: `[data-${widgetApi}__departure-date-container]`,
  returnDateContainer: `[data-${widgetApi}__return-date-container]`,
  errorMessage: `[data-${widgetApi}__error-message]`,
  loader: `.${widgetApi}__loader`
}

export default class TransferSummary {
  /**
   * Creates a new TransferSummary
   * @constructor
   * @param {HTMLElement} element
   */
  constructor (element) {
    this.element = element
    this._getHtmlElements()
    try {
      this.settingsData = this._getSettingsDataFromDom()
      this.apiData = this._getDataFromApi()
      this.apiData.then((data) => {
        if (!data.transfers) throw this._displayError('No transfer available')
        const departureData = this._getTicketData(transferTicketType.OUTBOUND, data.transfers)
        const returnData = this._getTicketData(transferTicketType.INBOUND, data.transfers)
        this._renderDepartureTransfer(departureData)
        this._renderReturnTransfer(returnData)
        this._displayItemsContainer()
      })
    } catch (e) {}
  }

  /**
  * Setup the necessary elements from the html
  */
  _getHtmlElements () {
    this.settings = this.element.querySelector(widgetQueries.settings)
    this.itemsContainers = this.element.querySelectorAll(widgetQueries.itemsContainer)
    this.departureTicketContainer = this.element.querySelector(widgetQueries.departureTicketContainer)
    this.returnTicketContainer = this.element.querySelector(widgetQueries.returnTicketContainer)
    this.departureDateContainer = this.element.querySelector(widgetQueries.departureDateContainer)
    this.returnDateContainer = this.element.querySelector(widgetQueries.returnDateContainer)
    this.errorMessage = this.element.querySelector(widgetQueries.errorMessage)
    this.loader = this.element.querySelector(widgetQueries.loader)
  }

  /**
  * Get the Settings from the DOM
  * @return {Object}
  */
  _getSettingsDataFromDom () {
    if (!this.settings) {
      throw this._displayError('Settings element should exist for settings in DOM')
    }
    return JSON.parse(this.settings.textContent)
  }

  /**
  * Get the data from the API
  * @return {Promise}
  */
  async _getDataFromApi () {
    if (!this.settingsData || !this.settingsData.requestUrl) {
      throw this._displayError('Request url should exists on setting')
    }
    const result = await apiCaller(this.settingsData.requestUrl)
    if (!result.success || !result.response) {
      throw this._displayError('The transfer API dose not send a response')
    }
    return result.response
  }

  /**
  * Get the ticket data for the specify type
  * @param {String} type departure or return type
  * @param {Object} data
  * @return {Object}
  */
  _getTicketData (type, data) {
    const ticketData = data.find(item => {
      let transferDirectionData = false
      if (item.transferDirection) {
        transferDirectionData = item.transferDirection.toLowerCase() === type
      }
      return transferDirectionData
    })
    return ticketData
  }

  /**
  * Renders the departure transfer section
  * @param {Object} data
  * @return {Void|undefined}
  */
  _renderDepartureTransfer (data) {
    if (!this.departureTicketContainer) throw this._displayError('No departure container available')

    if (!this.settingsData.departureIncluded) {
      const departureEmptyTicketData = transferTicketMapper(null, this.settingsData, true, transferTicketType.OUTBOUND)
      if (departureEmptyTicketData) {
        this._renderTransferTicket(departureEmptyTicketData, this.departureTicketContainer)
        return
      }
      throw this._displayError('No departure settings available')
    }

    if (this.departureDateContainer && data && data.pickupDate) {
      this._renderTransferDate(data.pickupDate, this.departureDateContainer)
    }

    if (this.settingsData.transferDepartureInfoText) {
      const departureTicketData = transferTicketMapper(null, this.settingsData, false, transferTicketType.OUTBOUND)
      if (departureTicketData) {
        this._renderTransferTicket(departureTicketData, this.departureTicketContainer)
        return
      }
    }
    throw this._displayError('No departure settings available')
  }

  /**
  * Renders the return transfer section
  * @param {Object} data
  * @return {Void|undefined}
  */
  _renderReturnTransfer (data) {
    if (!this.returnTicketContainer) throw this._displayError('No return container available')

    if (!this.settingsData.returnIncluded) {
      const returnEmptyTicketData = transferTicketMapper(null, this.settingsData, true, transferTicketType.INBOUND)
      if (returnEmptyTicketData) {
        this._renderTransferTicket(returnEmptyTicketData, this.returnTicketContainer)
        return
      }
      throw this._displayError('No return settings available')
    }

    if (this.returnDateContainer && data && data.pickupDate) {
      this._renderTransferDate(data.pickupDate, this.returnDateContainer)
    }

    if (!data) {
      if (this.settingsData.transferReturnInfoText) {
        const returnTicketData = transferTicketMapper(null, this.settingsData, false, transferTicketType.INBOUND)
        if (returnTicketData) {
          this._renderTransferTicket(returnTicketData, this.returnTicketContainer)
          return
        }
      }
    }
    const departureTicketData = transferTicketMapper(data, this.settingsData, false, transferTicketType.INBOUND)
    if (departureTicketData) {
      this._renderTransferTicket(departureTicketData, this.returnTicketContainer)
      return
    }

    throw this._displayError('No correct return transfer data')
  }

  /**
  * Display the error message and returns an Error
  * @param {Object} error
  * @return {Error}
  */
  _displayError (error) {
    this.errorMessage.style.display = 'block'
    this.loader.style.display = 'none'
    return new Error(error)
  }

  /**
  * Display the item containers
  */
  _displayItemsContainer () {
    this.loader.style.display = 'none'
    this.itemsContainers.forEach(item => {
      item.style.display = 'flex'
    })
  }

  /**
  * Renders the transfer date o the DOM
  * @param {Object} transferDateData
  * @param {HTMLElement} container
  */
  _renderTransferDate (transferDateData, container) {
    const transferDateElement = elementFromString(TransferSummaryHeadingDateTemplate(transferDateData))
    container.appendChild(transferDateElement)
  }

  /**
  * Renders the transfer ticket o the DOM
  * @param {Object} transferTicketData
  * @param {HTMLElement} container
  */
  _renderTransferTicket (transferTicketData, container) {
    const transferTicketElement = TransferTicketTemplate(transferTicketData)
    container.innerHTML = transferTicketElement
  }
}

registerWidget(TransferSummary, widgetApi)

/**
* Returns the heading date template
* @param {Object} date
* @return {String}
*/
export const TransferSummaryHeadingDateTemplate = (date) => {
  return `<span class="w-transfer-summary__heading">${date}</span>`
}

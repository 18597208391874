import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import { SliderTemplate } from '../slider/c-slider.template'
import { ImgTemplate } from '../img/c-img.template'
import { VideoTemplate } from '../video/c-video.template'
import Img from '../img/main'

const selectorNames = {
  galleryItem: '.c-photo-gallery__item',
  gallerySlider: 'c-photo-gallery__slider',
  galleryModalContent: 'c-modal__body',
  galleryModalFooter: 'c-modal__footer',
  modal: '[data-c-photo-gallery__modal]',
  modalApi: 'c-modal',
  videoApi: 'c-video',
  sliderApi: 'c-slider',
  sliderContent: 'c-slider__content'
}

const definition = {

  name: 'c-photo-gallery',
  props: [
    {
      name: 'currentIndex',
      type: 'integer'
    }
  ]
}

/**
 * Photo Gallery content
 *
 */
export default class PhotoGallery extends Component {
  /**
   * Creates a new Photo Gallery behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */

  constructor (element) {
    super(element, definition.name)
    this.modal = this.element.querySelector(selectorNames.modal)
    this.modalContent = this.modal.querySelector(`.${selectorNames.galleryModalContent}`)
    this.modalFooter = this.modal.querySelector(`.${selectorNames.galleryModalFooter}`)
    this._attachEvents()
    this._setGalleryListeners()
    this.modalContentRendered = false
    this.sliderInitialized = false
    this.sliderIndex = 0
    Img.createInstancesOnDocument(this.element)
  }

  _attachEvents () {
    if (this.modal) {
      this.modalApi = this.modal[selectorNames.modalApi]
      this.modalApi.events.on('onScreen', this._isOpened, this)
      this.modalApi.events.on('close', this._isClosed, this)
    }
  }

  _isClosed () {
    this.videos = this.element.querySelectorAll('.' + selectorNames.videoApi)
    for (const i in this.videos) {
      const videoElement = this.videos[i]
      const video = videoElement[selectorNames.videoApi]
      if (video) video.pauseVideo()
    }
  }

  _isOpened () {
    const sliderTransitionElement = this.modalContent.querySelector(`.${selectorNames.sliderContent}`)
    if (sliderTransitionElement) sliderTransitionElement.style.transition = 'none'
    Img.createInstancesOnDocument(this.modalContent)
    if (this.sliderIndex === 0) {
      this._setSliderIndex(1)
    }
    this._setSliderIndex(this.sliderIndex)
    if (sliderTransitionElement) sliderTransitionElement.style.transition = null

    this._renderModalFooter()
  }

  _renderModalContent () {
    this.modalContent.innerHTML = SliderTemplate({
      id: `${this.element.id}__slider`,
      extraClasses: selectorNames.gallerySlider,
      luminosity: 'dark',
      outerButtons: true,
      attributes: { 'data-loop': true, 'data-resolve-on-intersect': false },
      items: this._getImageElements()
    })
  }

  _renderModalFooter () {
    const slider = this._getSliderElement()
    const sliderApi = slider['c-slider']
    const descriptions = this._getDescriptions()
    if (slider) {
      this._updateDescription(descriptions[this.sliderIndex])
      sliderApi.events.on('change', (index) => {
        this._updateDescription(descriptions[index])
      }, this)
    }
  }

  _updateDescription (description) {
    this.modalFooter.innerHTML = description
  }

  _getImageElements () {
    const images = []
    const items = this.element.querySelectorAll(selectorNames.galleryItem)
    items.forEach(item => {
      if (item.getAttribute('data-item-gallery-type') === 'video') {
        const image = VideoTemplate({
          videoId: item.getAttribute('data-video-videoId'),
          id: item.getAttribute('data-video-id'),
          apiKey: item.getAttribute('data-video-apiKey'),
          url: item.getAttribute('data-video-url'),
          thumbnail: item.getAttribute('data-image-placeholderSrc')
        })
        images.push(image)
      } else {
        const image = ImgTemplate({
          placeholderSrc: item.getAttribute('data-image-placeholderSrc'),
          resolvedSrc: item.getAttribute('data-image-resolvedSrc'),
          ratio: item.getAttribute('data-image-ratio'),
          resolve: item.getAttribute('data-image-resolve')
        })
        images.push(image)
      }
    })
    return images
  }

  _getDescriptions () {
    const descriptions = []
    const items = this.element.querySelectorAll(selectorNames.galleryItem)
    items.forEach(item => {
      const description = item.getAttribute('data-image-description')
      descriptions.push(description)
    })
    return descriptions
  }

  _setSliderIndex (index) {
    const slider = this._getSliderElement()
    if (slider) {
      slider['c-slider'].setProp('index', index, { forceUpdate: true })
      this.sliderApi = this.sliderElement[selectorNames.sliderApi]
      this.sliderApi.events.on('change', this._isClosed, this)
    }
  }

  _setGalleryListeners () {
    this.element.addEventListener('click', (ev) => {
      ev.preventDefault()
      if (!this.modalContentRendered) {
        this._renderModalContent()
        Component.initDocumentComponentsFromAPI(this.modalContent)
        Component.initComponentActionElements(this.modalContent)
        this.modalContentRendered = true
      }
      const target = ev.target && ev.target.closest(selectorNames.galleryItem)
      if (target) {
        this.sliderIndex = parseInt(target.dataset.cPhotoGalleryItem)
      }
    })
  }

  _getSliderElement () {
    this.sliderElement = this.sliderElement || this.element.querySelector(`.${selectorNames.gallerySlider}`)
    this.videos = this.element.querySelectorAll('.' + selectorNames.videoApi)
    return this.sliderElement
  }
}

registerComponent(PhotoGallery, definition.name, definition)

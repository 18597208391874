
export const ReturnMissingDates = (dates) => {
  const result = dates.sort(function (a, b) {
    return Date.parse(a) - Date.parse(b)
  }).reduce((function (hash) {
    return function (p, c) {
      const missingDaysNo = (Date.parse(c) - hash.prev) / (1000 * 3600 * 24)
      if (hash.prev && missingDaysNo > 1) {
        let missingDate
        for (let i = 1; i < missingDaysNo; i++) { missingDate = new Date(hash.prev + i * (1000 * 3600 * 24)) }
        p.push(missingDate.toISOString().slice(0, 10))
      }
      hash.prev = Date.parse(c)
      return p
    }
  }(Object.create(null))), [])

  return result
}

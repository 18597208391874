import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import { BookingRedeemerListTemplate } from './w-booking-redeemer-list.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import { bookingItemEvents } from '../../../js/document/event-types'
import BookingRedeemerDataMapper from './data-mapper'
import Img from '../../components/img/main'
import registeredEvents from '../../../js/helpers/registered-events'
import { toCamelCase } from '../../../js/helpers/string'

const widgetApi = 'w-booking-redeemer'

const attr = {
  bookingNumber: `data-${widgetApi}__booking-number`,
  trackAttr: 'data-track'
}

const widgetQueries = {
  configurationElement: `[data-${widgetApi}__configuration]`,
  booking: `[data-${widgetApi}__booking]`,
  buttonApi: 'c-btn',
  // Buttons
  selectButtons: `[data-${widgetApi}__booking-select-button]`,
  revertButtons: `[data-${widgetApi}__booking-revert-button]`,
  usps: `[data-${widgetApi}__usps]`,
  messages: `[data-${widgetApi}__messages]`
}

const classNames = {
  hidden: 'is-hidden',
  selected: 'is-selected'
}

// Texts
const textNames = {
  selectButtonText: 'selectButtonText',
  revertButtonText: 'revertButtonText',
  bookingRedeemerMessageSelected: 'bookingRedeemerMessageSelected',
  bookingRedeemerMessageNotSelected: 'bookingRedeemerMessageNotSelected',
  bookingRedeemerMessageDisabled: 'bookingRedeemerMessageDisabled',
  bookingNumberText: 'bookingNumberText',
  bookingsText: 'bookingsText',
  paidAmountText: 'paidAmountText',
  usps: 'usps',
  bookingsAvailableText: 'bookingsAvailableText',
  bookingsNotAvailableText: 'bookingsNotAvailableText',
  modalTriggerText: 'modalTriggerText'
}

const configOptions = {
  noCandidatesModalId: 'modalId'
}

const BookingRedeemerOperations = {
  patch: 'Patch'
}

export default class BookingRedeemer extends BookingBox {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   */
  constructor (element) {
    super(element)
    this.element = element
    const filterConfigurationElement = this.element.querySelector(widgetQueries.configurationElement)
    this.texts = this._readTextsFromConfigurations(filterConfigurationElement)
    this.dataMapper = new BookingRedeemerDataMapper()
    this.configurations = this._readOptionsFromConfigurations(filterConfigurationElement)

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.trackAttr) && { track: this.element.attributes[attr.trackAttr].value }
    })
  }

  isDeferredAndResolvedByItself () {
    return true
  }

  /**
   *
   * @param {Object} data - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  getServiceName () {
    return toCamelCase(widgetApi) || ''
  }

  _updateWidgetData () {
    if (this.data && this.data.user && this.data.user.redeemableBookings) {
      this.redeemableBookings = this.dataMapper.mapWidgetData(this.data.user.redeemableBookings, this.texts)
      const selectedBookings = this.redeemableBookings && this.redeemableBookings.redeemableBookings.filter(booking => booking.selected)
      if (selectedBookings.length > 0) {
        super.setSuccessStateAndUpdateTitle()
      }
    }
    if (this.redeemableBookings) {
      this._showComponent()
      const renderedHtml = BookingRedeemerListTemplate(this.redeemableBookings, this.configurations)
      const newContent = elementFromString(renderedHtml)
      this._render(newContent)
      // IMPORTANT: Put always the 'initDocumentComponentsFromAPI' after the '_render', otherwise the events are not binded correctly.
      Img.createInstancesOnDocument(newContent)
      Component.initDocumentComponentsFromAPI(newContent)
      Component.initComponentActionElements(newContent)
      this._attachEvents()
    } else {
      super.hideComponent()
    }
  }

  _attachEvents () {
    const selectButtons = [...this.element.querySelectorAll(widgetQueries.selectButtons)]
    this.selectButtonApis = selectButtons.map(selectButton => selectButton[widgetQueries.buttonApi])
    this.selectButtonApis.forEach(selectButton => {
      selectButton.events.on('clickButton', (eventArgs) => { this._redeemableBookingSelected(eventArgs, selectButton.element) })
    })

    const revertButtons = [...this.element.querySelectorAll(widgetQueries.revertButtons)]
    this.revertButtonApis = revertButtons.map(revertButton => revertButton[widgetQueries.buttonApi])
    this.revertButtonApis.forEach(revertButton => {
      revertButton.events.on('clickButton', (eventArgs) => { this._redeemableBookingReverted(eventArgs, revertButton.element) })
    })
  }

  _showComponent () {
    this.element.classList.remove(classNames.hidden)
  }

  _hideComponent () {
    this.element.classList.add(classNames.hidden)
  }

  _render (content) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(content)
  }

  _redeemableBookingSelected (eventArgs, element) {
    if (element) {
      this._unselectAllBookingItems()

      this._toggleInformationVisibility(element, true)
      this._mapBookingArgumentsForPatch(element, 'selected')
    }
  }

  _redeemableBookingReverted (eventArgs, element) {
    if (element) {
      this._toggleInformationVisibility(element, false)
      this._mapBookingArgumentsForPatch(element, 'reverted')
    }
  }

  _toggleInformationVisibility (element, enabled = true) {
    const bookingItem = element.closest(widgetQueries.booking)
    if (!bookingItem) return

    // Selected bookingItem
    bookingItem.classList.toggle(classNames.selected, enabled)

    // Message
    const messages = bookingItem.querySelector(widgetQueries.messages)
    messages.classList.toggle(classNames.hidden, !enabled)

    // SelectButtons
    const selectButton = bookingItem.querySelector(widgetQueries.selectButtons)
    selectButton.classList.toggle(classNames.hidden, enabled)
    this._toggleButton(selectButton, enabled)

    // Revert Buttons
    const revertButton = bookingItem.querySelector(widgetQueries.revertButtons)
    revertButton.classList.toggle(classNames.hidden, !enabled)
    this._toggleButton(revertButton, !enabled)

    if (!enabled) {
      this._enableAllBookingItems()
    }

    this._showMessageNoBookingSelected()
  }

  _showMessageNoBookingSelected () {
    const bookingItemsNotSelected = [...this.element.querySelectorAll(widgetQueries.booking + ':not(.' + classNames.selected + ')')]
    const bookingItems = [...this.element.querySelectorAll(widgetQueries.booking)]

    const globalMessage = this.element.querySelector('.w-booking-redeemer__global-messages')
    if (bookingItemsNotSelected.length === bookingItems.length) {
      globalMessage && globalMessage.classList.toggle(classNames.hidden, false)
    } else {
      globalMessage && globalMessage.classList.toggle(classNames.hidden, true)
    }
  }

  _unselectAllBookingItems () {
    const bookingItems = [...this.element.querySelectorAll(widgetQueries.booking)]

    bookingItems.forEach(item => {
      item.classList.remove(classNames.selected)
      const message = item.querySelector(widgetQueries.messages)
      if (message) { message.classList.add(classNames.hidden) }

      // SelectButtons
      const selectButton = item.querySelector(widgetQueries.selectButtons)
      if (selectButton) { selectButton.classList.remove(classNames.hidden) }
      this._toggleButton(selectButton, false)

      // Revert Buttons
      const revertButton = item.querySelector(widgetQueries.revertButtons)
      if (revertButton) { revertButton.classList.add(classNames.hidden) }
      this._toggleButton(revertButton, true)
    })
  }

  _enableAllBookingItems () {
    const bookingItems = [...this.element.querySelectorAll(widgetQueries.booking)]

    bookingItems.forEach(item => {
      const buttons = item.querySelectorAll(widgetQueries.selectButtons)
      buttons.forEach(button => {
        this._toggleButton(button, false)
      })
    })
  }

  _toggleButton (button, disabled = true) {
    if (button[widgetQueries.buttonApi]) {
      button[widgetQueries.buttonApi].setProps({
        disabled
      })
    }
  }

  _mapBookingArgumentsForPatch (element, operationType) {
    const bookingNumber = (operationType === 'selected') ? element.getAttribute(attr.bookingNumber) : null

    const patchEventArgs = {
      method: this._method,
      url: this._url,
      body: {
        operationType: BookingRedeemerOperations.patch,
        bookingNumber
      }
    }

    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, patchEventArgs)
  }

  _readTextsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)
      const texts = {
        selectButtonText: configuration[textNames.selectButtonText],
        revertButtonText: configuration[textNames.revertButtonText],
        bookingRedeemerMessageSelected: configuration[textNames.bookingRedeemerMessageSelected],
        bookingRedeemerMessageNotSelected: configuration[textNames.bookingRedeemerMessageNotSelected],
        bookingRedeemerMessageDisabled: configuration[textNames.bookingRedeemerMessageDisabled],
        bookingNumberText: configuration[textNames.bookingNumberText],
        bookingsText: configuration[textNames.bookingsText],
        paidAmountText: configuration[textNames.paidAmountText],
        usps: configuration[textNames.usps],
        bookingsAvailableText: configuration[textNames.bookingsAvailableText],
        bookingsNotAvailableText: configuration[textNames.bookingsNotAvailableText],
        modalTriggerText: configuration[textNames.modalTriggerText]
      }
      return texts
    }
  }

  _readOptionsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)
      const options = {
        noCandidatesModalId: configuration[configOptions.noCandidatesModalId]
      }
      return options
    }
  }
}

registerWidget(BookingRedeemer, widgetApi)

import { defaultDropdownData, DropdownTemplate } from '../../components/dropdown/c-dropdown.template'
import { MapAvailableHoursToOptions } from './time-helper'

export const BookingFilterableServicesTimeDropdownTemplate = (service, configurations) => {
  return `<div class="w-booking-filterable-services__filter w-booking-filterable-services__filter__header w-booking-filterable-services__filter-time">
    ${DropdownTemplate({
      ...defaultDropdownData,
      label: '',
      icon: 'clock',
      id: `${service.uid}-time`,
      options: MapAvailableHoursToOptions(configurations.timeConfiguration),
      extraClasses: 'w-booking-filterable-services__time-dropdown',
      attributes: {
        'data-w-booking-filterable-services__time-dropdown': '',
        'data-w-booking-filterable-services__service-id': service.uid
      }
    })}
  </div>`
}

/**
 * List of types for the booking-filtrable-services widget
 *
 * perQuantity                    ==> DINNERVOUCHER
 * perParticipant                 ==> SKI ANCILLARIES
 * participantsAsOptions          ==> BRIDGE/EXCURSIONS
 * participantsAsOptionsDynamic   ==> GOLF
 */
export const componentTypeEnum = {
  PerParticipant: 'PerParticipant',
  PerQuantity: 'PerQuantity',
  ParticipantsAsOptions: 'ParticipantsAsOptions',
  ParticipantsAsOptionsDynamic: 'ParticipantsAsOptionsDynamic'
}

/**
 *
 */
export const operationTypeEnum = {
  Add: 'Add',
  Remove: 'Remove',
  Patch: 'Patch',
  Swap: 'Swap'
}

export const renderTypeEnum = {
  PerParticipant: 'PerParticipant',
  PerBooking: 'PerBooking'
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingPriceOverview from '../../../../shared/modules/widgets/booking-price-overview/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const bookingPriceOverviewData = require('../../../../shared/modules/widgets/booking-price-overview/data/w-booking-price-overview__js-template')
const bookingPriceOverviewPerParticipantData = require('../../../../shared/modules/widgets/booking-price-overview/data/w-booking-price-overview__per-participant-js-template')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = async () => {
  const bookingPriceOverviewItem = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-price-overview"]')

  bookingPriceOverviewItem.forEach(element => {
    const bookingPriceOverview = new BookingPriceOverview(element)
    console.log(bookingPriceOverview)
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingPriceOverviewJsTemplate = document.querySelectorAll('[data-booking-price-overview-demo]')
  handleFetched(bookingPriceOverviewJsTemplate, bookingPriceOverviewData)

  const bookingPriceOverviewPerParticipantJsTemplate = document.querySelectorAll('[data-booking-price-overview-per-participant-demo]')
  handleFetched(bookingPriceOverviewPerParticipantJsTemplate, bookingPriceOverviewPerParticipantData)
}

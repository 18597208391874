import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import storage from '../../../../shared/js/document/web-storage'

const cookieNames = {
  userData: 'user-data'
}

loadQueueHelper.add(function () {
  initCeWelcomeMsg()
})

// Based on https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
function utf8ToBase64 (text) {
  const bytes = new window.TextEncoder().encode(text)
  const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join('')
  return btoa(binString)
}

const initCeWelcomeMsg = () => {
  const items = document.querySelectorAll('[data-js-widget="w-ce-welcome-msg"]')
  if (!items.length) return
  const cookie = storage.cookie
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 1)
  const userDataContent = {
    profileData: {
      profileName: 'cezam',
      isSunwebCE: true,
      partnerName: 'Comité CEZAM',
      partnerDiscounts: [{ publicationCode: 'FS', discount: 13.523 }]
    },
    expirationDateTime: currentDate
  }
  userDataContent.profileData = window.encodeURIComponent(utf8ToBase64(JSON.stringify(userDataContent.profileData)))
  cookie.set(cookieNames.userData, userDataContent, {
    path: '/',
    expirationDays: parseInt(1)
  })
}

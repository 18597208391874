import { defaultPriceData, PriceTemplate } from '../../components/price/c-price.template'

export const BookingLuggagePriceTemplate = (value, priceConfig) => {
  return typeof (value) === 'number'
    ? `+ ${PriceTemplate({
      ...defaultPriceData,
      size: 'small',
      value,
      currency: priceConfig.currencySymbol,
      currencyPosition: priceConfig.isCurrencyAtStart ? 'before' : 'after'
    })}`
    : value
}

import { encodeAttributes } from '../../../js/helpers/string'

/**
 * The FormData contains all data needed to hydrate a Form view
 *
 * @typedef {Object}              TextareaData
 *
 * @property {String}             extraClasses
 * @property {FormSectionData[]}  sections        - a list of sections
 */
export const defaultTextareaData = {
  id: 'my-texarea',
  value: '',
  state: '',
  required: false,
  disabled: false,
  highlighted: false,
  hiddenLabel: false,
  placeholder: 'Enter a text',
  messages: []
}

export const TextareaTemplate = (d) => {
  d = { ...defaultTextareaData, ...d }
  return `
    <div class="c-textarea
            ${d.disabled ? 'is-disabled' : ''}
            ${d.required ? 'is-required' : ''}
            ${d.state ? `has-${d.state}` : ''}
            ${d.highlighted ? 'c-textarea--highlighted' : ''}
            ${d.extraClasses ? d.extraClasses : ''}
            "
            ${d.attributes ? encodeAttributes(d.attributes) : ''}
            data-js-component="c-textarea"
            >

        ${d.hiddenLabel
          ? `<label aria-hidden="true" aria-label="${d.label}" for="${d.id}"></label>`
          : d.label
            ? `<label class="textarea__label e-form-label" for="${d.id}">${d.label} ${d.required ? '*' : ''}</label>`
            : ''}

        <div class="textarea__wrapper">
            <textarea 
                    class="c-textarea__element"
                    placeholder="${d.placeholder ? d.placeholder : ''}"
                    name="${d.id}"
                    id="${d.id}"
                    ${d.elementAttributes ? encodeAttributes(d.elementAttributes) : ''}
                    autocomplete="${d.autocomplete ? 'on' : 'off'}"
                    ${d.readonly ? 'readonly' : ''}
                    ${d.disabled ? 'disabled' : ''}
                    ${d.required ? 'required' : ''}>${d.value.trim()}</textarea>
        </div>

        ${d.hiddenMessages
          ? ''
          : `<div class="c-textarea__messages">
            ${d.messages
              ? d.messages.map(message => `
                <div class="c-textarea__message">
                  <i class="m-icon m-icon--warning-circle"></i>
                  <span>${message}</span>
                </div>`)
              : ''}
        </div>`}
    </div>`
}

import { FormTemplate } from '../../components/form/c-form.template'
import { FormSectionTemplate } from '../../components/form/c-form__section.template'
import { FormFieldsetTemplate } from '../../components/form/c-form__fieldset.template'
import { CollapseTemplate } from '../../components/collapse/c-collapse.template'
import { TitleTemplate } from '../../components/title/c-title.template'
import { TextboxTemplate } from '../../components/textbox/c-textbox.template'
import { BoxTemplate } from '../../components/box/c-box.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { DateSelectorTemplate } from '../../components/date-selector/c-date-selector.template'

export const BookingParticipantsBadgeTemplate = (d) => {
  return `
    ${FormTemplate({
      hasSubmitButton: false,
      validate: true,
      html: FormContentTemplate(d)
    })}
  `
}

const FormContentTemplate = (d) => {
  return `
    ${(d.configuration && d.configuration.showEmailSection)
      ? FormSectionTemplate({
        html: FormFieldsetTemplate({
          extraClasses: 'w-booking-participants-badge__email-section',
          html: EmailSectionTemplate(d)
        })
      })
      : ''}
    
    ${(d.configuration && d.configuration.collapseNamesSection)
      ? `<div class="w-booking-participants-badge--participants-header"
          data-c-collapse__id=${d.componentId}w-booking-participants-badge--collapse
          data-c-collapse__action="toggle">
            ${TitleTemplate({
              text: d.setParticipantsSectionLabel,
              size: 'tiny',
              extraClasses: 'w-booking-participants-badge--participants-header--title'
            })}
            ${BtnTemplate({
              ...d.collapseButton,
              ...{ extraClasses: 'w-booking-participants-badge--collapse-btn' }
            })}
        </div>`
      : ''
    }
    
    ${CollapseTemplate({
      id: `${d.componentId}w-booking-participants-badge--collapse`,
      opened: !(d.configuration && d.configuration.collapseNamesSection),
      extraClasses: 'w-booking-participants-badge--collapse-section',
      html: NamesSectionTemplate(d)
    })}
  `
}

const EmailSectionTemplate = (d) => {
  return `
  <div class="o-pack o-pack--auto w-booking-participants-badge__email-field-row ${d.successEmail ? 'w-booking-participants-badge__is-success' : ''}"
        data-w-booking-participants-badge--success-toggle='' >
    <div class="o-pack__item">

        ${TextboxTemplate({
          ...d.mainBookerEmail,
          ...{
            extraClasses: 'c-form__item c-form__item--large w-booking-participants-badge__email-input',
            attributes: {
              'data-w-booking-participants-badge--email-input': ''
            }
          }
        })}
        ${BoxTemplate({
          variant: 'flat',
          extraClasses: 'w-booking-participants-badge__email-success-text cs-state-success cs-state-success--light',
          attributes: {
            'data-w-booking-participants-badge--email-success-text': ''
          },
          html: (d.mainBookerEmail) ? d.mainBookerEmail.successText : ''
        })}

        <div class="w-booking-participants-badge__email-field-row">
          <div class="w-booking-participants-badge__email-information-text">
            ${(d.mainBookerEmail) ? d.mainBookerEmail.infoText : ''}
          </div>
        </div>
        
    </div>
    <div class="o-pack__item w-booking-participants-badge--email-save-button">
        ${BtnTemplate({
          type: 'button',
          text: (d.mainBookerEmail) ? d.mainBookerEmail.saveButtonText : '',
          extraClasses: 'w-booking-participants-badge__email-save',
          variant: 'trust',
          attributes: {
            'data-w-booking-participants-badge--save-button': ''
          }
        })}
    </div>
  </div>
`
}

const NamesSectionTemplate = (d) => {
  return `
    ${d.rooms.map(room => `
        ${FormSectionTemplate({
          title: room.name,
          extraClasses: 'w-booking-participants-badge__participants-room-section',
          html: room.participants.map(participant => `<div class="w-booking-participants-badge__participant-row">
            ${participant.fields
              ? participant.fields.map(field =>
                FieldTemplate(field)
                ).join('')
              : ''
            }</div>`).join('')
        })}
        `).join('')}
        
    <div>
      ${(d.configuration && d.configuration.collapseNamesSection)
          ? `${BtnTemplate({
            type: 'button',
            text: d.cancelButtonText,
            variant: 'flat-neutral',
            attributes: {
              'data-c-collapse__id': `${d.componentId}w-booking-participants-badge--collapse`,
              'data-c-collapse__action': 'close'
            }
            })}`
          : ''
        }
        ${BtnTemplate({
          type: 'button',
          text: d.saveButtonText,
          variant: 'trust',
          attributes: {
            'data-w-booking-participants-badge--save-button': ''
          }
        })}
    </div>
`
}

const defaultFieldData = {
  size: 'small'
}

const FieldTemplate = (d) => {
  d = { ...defaultFieldData, ...d }

  return `
    ${d.type === 'c-textbox'
    ? TextboxTemplate(d.data)
    : ''}
  
    ${d.type === 'c-date-selector'
    ? DateSelectorTemplate(d.data)
    : ''}
  `
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import QuickSearch from '../../../../shared/modules/widgets/quick-search/main'

// class QuickSearchHacked extends QuickSearch {
//   /**
//    * Builds the request URL, appending the query params
//    *
//    * Additionally to original method, it bypass Sunweb sites CORS security by prefixing the
//    * url with a CORS free proxy, so real data can be tested here.
//    *
//    * @param {String} [url]
//    *
//    * @returns {URL}
//    */
//   buildRequestURL (url = this.url) {
//     url = url.startsWith('http')
//       ? 'https://cors-anywhere.herokuapp.com/' + url
//       : url
//     return super.buildRequestURL(url)
//   }
// }

loadQueueHelper.add(function () {
  initQuickSearch(document.getElementById('sweden-quick-search'))
  initQuickSearch(document.getElementById('denmark-quick-search'))
  initQuickSearch(document.getElementById('sweden-summer-quick-search'))
}, 2)

const initQuickSearch = (quickSearchElement) => {
  if (!quickSearchElement) return
  console.log('Quick Search Element found', quickSearchElement)

  const quickSearchInstance = new QuickSearch(quickSearchElement)
  console.log('Quick Search initialized', quickSearchInstance)
}

export const BookedServiceBasketLinesTemplate = d => {
  return `<div class="w-booked-service-basket__service-info">
            ${d.concept ? `<span class="w-booked-service-basket__service-info--text">${d.concept}</span>` : ''}
            ${d.value ? `<span class="w-booked-service-basket__service-info--price">${d.value}</span>` : ''}
          </div>  
            ${
              d.subitems
                ? `<div class="w-booked-service-basket__service-info--detail">
                  ${d.subitems
                    .map((subitem, index) => {
                      return `
                      
                        ${SubItemTemplate(subitem)}
                        ${
                          subitem.subitems && subitem.subitems.length > 0
                            ? `<div class="w-booked-service-basket__table__item-content--children w-booked-service-basket__subitem--indented">
                            ${subitem.subitems.map(child => SubItemTemplate(child)).join('')}  
                          </div>`
                            : ''
                        }
                      </div>`
                    })
                    .join('')}`
                : ''`
                </div>`
            }`
}

const SubItemTemplate = subitem => {
  return `
  <div class="w-booked-service-basket__table__item-content w-booked-service-basket__table__item-content--subitem ${subitem.extraClass ||
    ''}">
    <div class="w-booked-service-basket__table__item-left">
    ${subitem.concept ? subitem.concept : ''}
    </div>
    <div class="w-booked-service-basket__table__item-right">
      ${subitem.value ? subitem.value : ''}
    </div>
  </div>`
}

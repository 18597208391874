import { arrayifyObject } from '../../../js/helpers/arrayify-object'
import { getUrlFromString } from '../../../js/document/url'
import { fetchJsonDataAndStatusInfo } from '../../../js/helpers/json-fetch'

const defaults = {
  thresholdNumCharsToShowCompactMode: 5
}

/**
 * ---------------
 * ApiPriceTable
 * ---------------
 *
 * It's main purpose is to make the request to the API and transform data if needed.
 *
 */
export default class ApiPriceTable {
  /**
   * Creates a new ApiPriceTable
   *
   * @constructor
   *
   * @param {Object} options
   * @param {String} options.url - PriceTable API base URL
   * @param {String} options.thresholdNumCharsToShowCompactMode - Number of characters until which the compact mode will be used (threshold:5, if price has 5 chars or less the compact mode will be used)
   */
  constructor (options = {}) {
    this.options = {
      ...defaults,
      ...options
    }
  }

  /**
   * Fetches data from API and apply data processing
   *
   * @param {Object} params
   *
   * @returns {Promise}
   */
  async fetch (params) {
    try {
      this.data = {}
      const requestUrl = getUrlFromString(this.options.url, arrayifyObject(params))
      const response = await fetchJsonDataAndStatusInfo(requestUrl, { fullReferrerOnCrossOrigin: true })
      if (response.statusCode === 200) {
        if (response.jsonData) {
          this.data = ApiPriceTable.postProcessPriceTableData(response.jsonData)
        }
        return this.data
      } else {
        return {
          errors: [{
            code: response.statusCode,
            message: response.statusText
          }]
        }
      }
    } catch (ex) {
      this.data = {}
      return {
        errors: [{
          code: '404',
          message: 'Could not retrieve data => ' + (ex.message || ex)
        }]
      }
    }
  }

  static postProcessPriceTableData (data) {
    return data.packages
      ? {
          ...data,
          packages: data.packages.map(pkg => ({
            ...pkg,
            id: `${pkg.id}_${pkg.duration}`,
            roundedPrice: pkg.formattedPrice.replace(data.currencySymbol, '').trim()
          }))
        }
      : data
  }

  /**
   * Split single API response, in multiple objects, one per available duration
   * TODO: Consider to delete the roomTypes where no packages available for current duration
   */
  getDataByDurations () {
    const getPeriodName = (duration) => (
      this.data.periodName.startsWith(this.data.duration.toString()) // Handle duration in nights
        ? this.data.periodName.replace(this.data.duration.toString(), duration.toString())
        : this.data.periodName.replace((this.data.duration - 1).toString(), (duration - 1).toString())
    )

    const isCompactMode = false // this._getIsCompactMode(this.data.packages)

    return this.data.durations.map((duration, idx) => ({
      ...this.data,
      ...{
        isCompactMode,
        duration,
        durations: undefined,
        messages: idx > 0 ? [] : this.data.messages || [],
        periodName: getPeriodName(duration),
        packages: this.data.packages.filter(pkg => pkg.duration === duration)
      }
    }))
  }

  /**
   * Return if the fetched data requires the use of alternative filters.
   *
   * @returns {Boolean} True of filter alternatives are requried, false if not or the field is not present in the response.
   */
  hasAlternatives () {
    return this.data.alternatives || false
  }

  /**
   * Get the packages occupancies grouped by room group.
   *
   * @param {String}  departureDate  - The departure date of the customer.
   * @param {Integer} duration       - The duration of the holidays.
   *
   * @returns {Object[]} List of room groups, with the room name, minimum price, stock, occupancies, associated packages.
   */
  getPackagesOccupanciesGroupedByRoomGroup (departureDate, duration) {
    const packages = this.data.packages.filter(pkg => pkg.departureDate === departureDate && pkg.duration === duration)
    const roomGroups = [...new Set(packages.map(pkg => pkg.roomGroup))].sort() // Get sorted unique list of room groups.
    const roomTypes = this.data.roomTypes

    this.packagesOccupanciesByRoomGroup = roomGroups.map(roomGroup => {
      const roomGroupPackages = packages.filter(pkg => pkg.roomGroup === roomGroup)
      const occupancies = this._getRoomGroupPackagesOccupancies(roomGroupPackages)
      const subtitle = roomTypes.filter(rt => rt.id === roomGroup)[0].subtitle || ''

      const roomGroupOccupancies = {
        roomGroup,
        name: roomGroupPackages[0].roomName,
        description: subtitle,
        stock: roomGroupPackages[0].stock,
        occupancies,
        packages: roomGroupPackages.map(roomGroupPackage => ({
          id: roomGroupPackage.id,
          roomId: roomGroupPackage.roomId,
          price: roomGroupPackage.price,
          roundedPrice: roomGroupPackage.roundedPrice,
          occupancies: this._composeOccupancyBounds(roomGroupPackage.occupancyId, occupancies.total.maxOccupancy)
        })),
        currency: {
          symbol: this.data.currencySymbol,
          // TODO API should return currency position
          position: roomGroupPackages[0].formattedPrice.match(/^\d/) ? 'after' : 'before'
        }
      }

      roomGroupOccupancies.minPrice = this._getPackagesMinPrice(roomGroupOccupancies.packages)

      return roomGroupOccupancies
    })

    return this.packagesOccupanciesByRoomGroup
  }

  /**
   * Get the occupancies of the packages associated with a room group.
   *
   * @param {Object[]}     roomGroupPackages  - List of packages associated with a given room group.
   *
   * @returns {RoomOccupancyBoundaries} List of occupancies for the packages associated with a room group.
   */
  _getRoomGroupPackagesOccupancies (roomGroupPackages) {
    const occupancies = {
      total: this._composeMinMaxOccupancy(roomGroupPackages, 'occupancy')
    }
    occupancies.adults = this._composeMinMaxOccupancy(roomGroupPackages, 'adults', occupancies.total.maxOccupancy)
    occupancies.children = this._composeMinMaxOccupancy(roomGroupPackages, 'children', occupancies.total.maxOccupancy)
    occupancies.babies = this._composeMinMaxOccupancy(roomGroupPackages, 'babies', occupancies.total.maxOccupancy)

    return occupancies
  }

  /**
   * Compose the minimum and maximum occupancy for a given type of occupancy (adults, children...) for the packages associated with a room group.
   *
   * @param {Object[]}     roomGroupPackages  - List of packages associated with a given room group.
   * @param {String}       occupancyType      - The type of occupancy to process (adults, children...).
   * @param {Integer|null} maxOccupancy       - Override parameter to force the maximum occupancy for this specific type of occupancy (this is intended to be calculated with the total occupancy and applied to the other types of occupancies).
   *
   * @returns {Object}  Model with the minimum and maximum ocucpancy for a type of occupancy (adults, children...) for all the packages associated with a room group.
   */
  _composeMinMaxOccupancy (roomGroupPackages, occupancyType, maxOccupancy = null) {
    const noMaxLimitSet = 99

    const occupancies = roomGroupPackages.reduce((accummulator, roomGroupPackage) => {
      const pkgOccupancy = this.data.occupancies
        .find(x => x.id === roomGroupPackage.occupancyId)
        .ageProfileThresholds
        .find(x => x.ageProfileId === occupancyType)

      accummulator.minOccupancy = accummulator.minOccupancy !== null
        ? Math.min(accummulator.minOccupancy, pkgOccupancy.min)
        : pkgOccupancy.min

      accummulator.maxOccupancy = accummulator.maxOccupancy !== null && pkgOccupancy.max !== noMaxLimitSet
        ? Math.max(accummulator.maxOccupancy, pkgOccupancy.max)
        : pkgOccupancy.max !== noMaxLimitSet
          ? pkgOccupancy.max
          : accummulator.maxOccupancy

      return accummulator
    }, {
      minOccupancy: null,
      maxOccupancy: null
    })

    if (maxOccupancy !== null && (occupancies.maxOccupancy === null || occupancies.maxOccupancy > maxOccupancy)) {
      occupancies.maxOccupancy = maxOccupancy
    }
    occupancies.maxOccupancy = occupancies.maxOccupancy === null ? noMaxLimitSet : occupancies.maxOccupancy

    return occupancies
  }

  /**
   * Compose the package occupancy bounds for a specific occupancy ID.
   *
   * @param {String}  occupancyId   - The occupancy ID of the packages (to index the list of occupancies definitions).
   * @param {Integer} maxOccupancy  - Override value for the maximum occupancy to be defined for the package.
   *
   * @returns {Object}  Occupancy bounds for the given occupancy ID.
   */
  _composeOccupancyBounds (occupancyId, maxOccupancy = Infinity) {
    const _ageProfileOccupancy = (ageProfileThresholds, ageProfileId) => {
      const ageProfileThreshold = ageProfileThresholds.find(x => x.ageProfileId === ageProfileId)

      return {
        minOccupancy: ageProfileThreshold.min,
        maxOccupancy: Math.min(ageProfileThreshold.max, maxOccupancy)
      }
    }

    const ageProfileThresholds = this.data.occupancies
      .find(x => x.id === occupancyId)
      .ageProfileThresholds

    const occupancyBounds = {
      total: _ageProfileOccupancy(ageProfileThresholds, 'occupancy'),
      adults: _ageProfileOccupancy(ageProfileThresholds, 'adults'),
      children: _ageProfileOccupancy(ageProfileThresholds, 'children'),
      babies: _ageProfileOccupancy(ageProfileThresholds, 'babies')
    }

    return occupancyBounds
  }

  _getPackagesMinPrice (packages) {
    const minPackagePrice = packages.reduce((accummulator, pkg) => {
      if (accummulator.price === null || pkg.price < accummulator.price) {
        accummulator.price = pkg.price
        accummulator.formattedPrice = pkg.roundedPrice
      }

      return accummulator
    }, {
      price: null,
      formattedPrice: null
    })
    return minPackagePrice
  }

  _getIsCompactMode (packages) {
    let isCompactMode = false
    if (packages) {
      const maxLengthPrices = Math.max(...packages.map(pck => pck.formattedPrice ? pck.formattedPrice.length : 0))
      isCompactMode = maxLengthPrices <= this.options.thresholdNumCharsToShowCompactMode
    }
    return isCompactMode
  }
}

import { ImgTemplate, defaultImgData } from '../../components/img/c-img.template'

/**
 * The PhotoGalleryElement contains all data necessary to display a video or an image in a PhotoGallery
 *
 * see: PhotoGalleryElement.cs
 *
 * @typedef {Object}     PhotoGalleryElement
 *
 * @property {String}    id                                                 - The id of the video
 * @property {String}    title                                              - The title of the video
 * @property {string}    description                                        - The description of the video
 * @property {String}    url                                                - The url of the video
 * @property {String}    videoId                                            - The videoId of teh video
 * @property {bool}      isDescriptionAfterVideo                            - If the description should appear after the video
 * @property {String}    thumbnail                                          - The thumbnail for the video
 * @property {String}    apiKey                                             - The api Key of the library responsible of displaying the video
 * @property {Object}    galleryImageThumbnail                              - An Object containing all data for the thumbnail of an image
 * @property {Object}    galleryImageOriginalImage                          - An Object containing all data for the image shown when clicking the thumbnail
 */
export const PhotoGalleryElementTemplate = (d) => {
  let template = ''
  if (d.apiKey && d.thumbnail) {
    template = PhotoGalleryVideoTemplate(d)
  } else if (d.galleryImageThumbnail) {
    template = PhotoGalleryImageTemplate(d)
  }
  return template
}

const PhotoGalleryVideoTemplate = (d) => {
  return `
    <a href="#" title="${d.title}"
    class="c-photo-gallery__item c-photo-gallery__item-video"
    data-item-gallery-type="video"
    data-c-modal__id="${d.modalId}"
    data-c-modal__action="open"
    data-c-photo-gallery-item="${d.position}"
    data-image-placeholderSrc="${d.thumbnail}"
    data-image-resolvedSrc="${d.thumbnail}"
    data-image-ratio="16:9"
    data-image-description="${d.description}"
    ${d.apiKey ? 'data-video-apiKey=' + d.apiKey : ''}
    data-video-url="${d.url}"
    data-video-id="${d.id}"
    data-video-videoId="${d.videoId}">
    ${ImgTemplate({
    ...defaultImgData,
    ratio: '1:1',
    resolve: 'intersect',
    placeholderSrc: d.thumbnail,
    resolvedSrc: d.thumbnail,
    title: d.title
  })}
  <i class="c-photo-gallery-video-thumbnail-info-icon m-icon m-icon--play"></i>
  </a>`
}

export const defaultGalleryImageThumbnailData = {
  sync: false,
  resolve: 'intersect',
  placeholderSrc: '',
  resolvedSrc: '',
  title: '',
  attributes: {},
  ratio: '1:1'
}

export const defaultGalleryImageData = {
  placeholderSrc: '',
  resolvedSrc: '',
  ratio: '16:9',
  galleryImageDescription: ''
}

const PhotoGalleryImageTemplate = (d) => {
  const originalImage = GetOriginalImage(d.galleryImageOriginalImage)
  const thumbnail = GetThumbnail(d.galleryImageThumbnail)
  return `<a href="#" title="${d.title}"
  class="c-photo-gallery__item"
  data-c-modal__id="${d.modalId}"
  data-c-modal__action="open"
  data-c-photo-gallery-item="${d.position}"
  data-image-placeholderSrc="${originalImage.placeholderSrc}"
  data-image-resolvedSrc="${originalImage.resolvedSrc}"
  data-image-ratio="${GetRatioStringFromRatioModel(originalImage.ratio)}"
  data-image-description="${originalImage.galleryImageDescription}"
  data-image-resolve="${originalImage.resolve}">
  ${ImgTemplate(thumbnail)}
  </a>`
}

function GetRatioStringFromRatioModel (ratio) {
  return `${ratio.width}:${ratio.height}`
}

function GetOriginalImage (d) {
  let resolve, ratio
  if (d.resolve) resolve = d.resolve.toLowerCase()
  if (d.ratio) ratio = GetRatioStringFromRatioModel(d.ratio)
  return {
    ...defaultGalleryImageData,
    ...d,
    resolve,
    ratio
  }
}

function GetThumbnail (d) {
  let resolve, ratio
  if (d.resolve) resolve = d.resolve.toLowerCase()
  if (d.ratio) ratio = GetRatioStringFromRatioModel(d.ratio)
  return {
    ...defaultGalleryImageThumbnailData,
    ...d,
    resolve,
    ratio
  }
}

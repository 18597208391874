import { TitleTemplate, defaultTitleData } from '../../components/title/c-title.template'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'
import { SideDrawerTemplate, defaultSideDrawerData } from '../../components/side-drawer/c-side-drawer.template'
import { SideDrawerHeaderTemplate } from '../../components/side-drawer/c-side-drawer__header.template'
import { SideDrawerBodyTemplate } from '../../components/side-drawer/c-side-drawer__body.template'
import { BreadcrumbsTemplate, defaultBreadcrumbsData } from '../../components/breadcrumbs/c-breadcrumbs.template'
import { RatingTemplate, defaultRatingData } from '../../components/rating/c-rating.template'
import { ImgTemplate, defaultImgData } from '../../components/img/c-img.template'
import { PackageBlockTemplate, defaultPackageBlockData } from '../../components/package-block/c-package-block.template'
import { CollapseTemplate, defaultCollapseData } from '../../components/collapse/c-collapse.template'

import { BookingBasketServiceLineTemplate } from './booking-basket__overview-lines.template'
import { BookingMessageTemplate } from '../booking-message/w-booking-message.template'

import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/w-booking-basket__tokens.json')['w-booking-basket']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/widgets/booking-basket/data/w-booking-basket__tokens.json`)['w-booking-basket'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

/**
 * The BookingBasketData contains all data needed to hydrate a BookingBasket view
 *
 * @typedef {Object}                  BookingBasketData
 *
 * @property {AccommodationInfo}      acco info                           - The information of the acco to be shown
 * @property {String}                 AccommodationInfo.Name              - The name of the acco
 * @property {String}                 AccommodationInfo.Ratio             - The rating of the accommodation shown as stars
 * @property {AccoImage}              AccommodationInfo.Image             - Group of settings to show the accommodation image in the Basket
 * @property {AccoLocation}           AccommodationInfo.Location          - List of breadcrumbs items to show Country, Region, City
 * @property {overviewServices[]}     Overview services                   - Contains the list of services booked
 * @property {PackageBlockInfo}       PackageBlockInfo                    - Contains all settings to show all the relevant package information
 *
 */

export const defaultBookingBasketData = {
  title: {
    size: 'tiny',
    tag: 'h2'
  },
  sideDrawer: {
    id: 'sideDrawerId',
    origin: 'bottom',
    until: 'md',
    extraClasses: 'w-booking-basket__side-drawer',
    closable: true,
    attributes: {
      'data-w-booking-basket__c-side-drawer': ''
    },
    header: {
      extraClasses: 'w-booking-basket__side-drawer--header'
    }
  }
}

export const BookingBasketTemplate = (d, configurations) => {
  d = { ...defaultBookingBasketData, ...d }
  return `<div class="w-booking-basket">
      ${BookingBasketMobileFooterTemplate(d, configurations)}
      ${SideDrawerTemplate(
        {
          ...defaultSideDrawerData,
          ...{
            id: 'sideDrawerId',
            extraClasses: 'w-booking-basket__side-drawer',
            until: 'md',
            origin: 'bottom',
            attributes: {
              'data-w-booking-basket__c-side-drawer': ''
            },
            html: `${
            SideDrawerHeaderTemplate({ ...d.sideDrawer.header, ...{ id: d.sideDrawer.id, closable: d.sideDrawer.closable } }) +
            SideDrawerBodyTemplate({
              html: `${BookingBasketContent(d, configurations)}`
            })
          }`
          }
        }
      )}
    </div>
  `
}

export const BookingBasketMobileFooterTemplate = (d, configurations) => {
  return `
    <div class="w-booking-basket__mobile-footer ${getTokenClass('booking-basket__cs', 'footer', tokens)}"
    data-w-booking-basket__mobile-footer=""
    data-c-side-drawer__action="toggle"
    data-c-side-drawer__id='${d.sideDrawer.id}'>
      <div class="w-booking-basket__price--mobile">
        <div class="w-booking-basket__price-total">
          ${TitleTemplate({ ...defaultTitleData, ...{ text: configurations.totalPriceLabel, size: 'medium', extraClasses: 'w-booking-basket__price-total--title' } })}
          ${TitleTemplate({ ...defaultTitleData, ...{ text: d.totalPrice, size: 'medium', extraClasses: 'w-booking-basket__price-total--amount', attributes: { 'data-w-booking-basket__totalPriceText': '' } } })}
          ${BtnTemplate({
            ...defaultButtonData,
            ...{
              variant: 'icon-only',
              icon: 'chevron-up',
              iconPosition: 'left',
              extraClasses: 'w-booking-basket__services--side-drawer-btn'
            }
          })}
        </div>
      </div>
    </div>
  `
}

export const BookingBasketContent = (d, configurations) => {
  return `
    <div class="w-booking-basket__content ${getTokenClass('booking-basket__cs', 'default', tokens)}">
      <div class="w-booking-basket__info">
        <div class="w-booking-basket__header">
          ${d.accommodationInfo.image
            ? ImgTemplate({ ...defaultImgData, ...d.accommodationInfo.image })
            : ''}
          <div class="w-booking-basket__header-acco-info">
            ${RatingTemplate({ ...defaultRatingData, ...{ value: d.accommodationInfo.starsNumber, extraClasses: 'w-booking-basket__rating', size: 's' } })}
            ${TitleTemplate({ ...defaultTitleData, ...{ text: d.accommodationInfo.name, size: 'medium', extraClasses: 'w-booking-basket__acco-name' } })}
            ${
              d.accommodationInfo.location
                ? BreadcrumbsTemplate({
                  ...defaultBreadcrumbsData,
                  ...{ items: d.accommodationInfo.location, extraClasses: 'w-booking-basket__breadcrumbs' }
                })
              : ''
            }
          </div>
        </div>
        ${PackageBlockTemplate({
          ...defaultPackageBlockData,
          ...d.packageBlockInfo
        })}

        ${BookingBasketPrice(d, configurations)}

        ${configurations.extraInfo
          ? `<div class="w-booking-basket__extra-info">
                ${configurations.extraInfo}
            </div>`
          : ''}
      </div>
      
      ${d.overviewServices ? BookingBasketServices(d.overviewServices) : ''}
    </div>    
  `
}

export const BookingBasketPrice = (d, configurations) => {
  return `
    <div class="w-booking-basket__price">
      ${BookingBasketPriceRebooking(d, configurations)}
      ${BookingBasketPriceTotal(d, configurations)}
      ${BookingBasketPriceTotalPerson(d, configurations)}
      ${d.rebookingWarning
        ? `<div class="w-booking-basket__message" data-w-booking-basket__messages>
            ${BookingMessageTemplate(d.rebookingWarning)}
            </div>`
        : ''}     
    </div>
  `
}

export const BookingBasketServices = (overviewServices) => {
  return `
    <div class="w-booking-basket__services">
      ${overviewServices.map(function (service, index) {
        const uniqueId = `w-booking-basket__services-item-${index}`
        return `<div class="w-booking-basket__services-item"
          data-w-booking-basket__service="${service.id}"
          data-c-collapse__id="${uniqueId}"
          data-c-collapse__action="toggle">
            <div class="w-booking-basket__services--header">
              ${service.icon ? `<span class="m-icon m-icon--${service.icon} w-booking-basket__services--header-icon"></span>` : ''}
              ${TitleTemplate({ ...defaultTitleData, ...{ text: service.header, size: 'tiny', extraClasses: 'w-booking-basket__services--header-title' } })}
              ${BtnTemplate({
                ...defaultButtonData,
                ...{
                  variant: 'icon-only',
                  icon: service.IsCollapsedByDefault ? 'chevron-up' : 'chevron-down',
                  iconPosition: 'left',
                  extraClasses: 'w-booking-basket__services--collapse-btn',
                  attributes: service.buttonAttributes
                }
              })}
            </div>
            ${CollapseTemplate({
              ...defaultCollapseData,
              ...{
                id: uniqueId,
                opened: !service.IsCollapsedByDefault,
                html: `
                  <div class="w-booking-basket__services--lines" data-w-booking-basket__services-body="">
                    ${BookingBasketServiceLineTemplate(service)}
                  </div>`
              }
            }
            )}
        </div>
        `
      }).join('')}
    </div>
  `
}

export const BookingBasketPriceRebooking = (d, configurations) => {
  return `
    <div class="w-booking-bakset__price-rebooking ${d.paidAmount ? '' : 'is-hidden'}" data-w-booking-basket__price-rebooking>
      <div class="w-booking-basket__price-title">
        ${TitleTemplate({ ...defaultTitleData, ...{ text: configurations.priceSectionLabel, size: 'medium', extraClasses: 'w-booking-basket__price-title--text m-heading--no-crop' } })}
      </div>
      <div class="w-booking-basket__price-package">
        ${TitleTemplate({ ...defaultTitleData, ...{ text: configurations.pricePackageLabel, size: 'medium', extraClasses: 'w-booking-basket__price-package--title m-heading--no-crop' } })}
        ${TitleTemplate({ ...defaultTitleData, ...{ text: d.totalPackagePrice, size: 'medium', extraClasses: 'w-booking-basket__price-package--amount m-heading--no-crop', attributes: { 'data-w-booking-basket__packagePriceText': '' } } })}
      </div>
      <div class="w-booking-basket__price-package-person">
        ${TitleTemplate({ ...defaultTitleData, ...{ text: d.avgtotalPackagePrice, size: 'medium', extraClasses: 'w-booking-basket__price-package-person--text m-heading--no-crop', attributes: { 'data-w-booking-basket__packagePersonText': '' } } })}
      </div>
      <div class="w-booking-basket__price-paid">
        ${TitleTemplate({ ...defaultTitleData, ...{ text: configurations.pricePaidLabel, size: 'medium', extraClasses: 'w-booking-basket__price-paid--title m-heading--no-crop' } })}
        ${TitleTemplate({ ...defaultTitleData, ...{ text: d.paidAmount, size: 'medium', extraClasses: 'w-booking-basket__price-paid--amount m-heading--no-crop', attributes: { 'data-w-booking-basket__paidAmountText': '' } } })}
      </div>
    </div>
  `
}

export const BookingBasketPriceTotal = (d, configurations) => {
  return `
    <div class="w-booking-basket__price-total ${d.paidAmount ? 'w-booking-basket__price-rebooking' : ''} " data-w-booking-basket__price-total>
      ${TitleTemplate({ ...defaultTitleData, ...{ text: d.paidAmount ? configurations.totalPriceDueLabel : configurations.totalPriceLabel, size: 'medium', extraClasses: 'w-booking-basket__price-total--title m-heading--no-crop', attributes: { 'data-w-booking-basket__price-total--title': configurations.totalPriceLabel, 'data-w-booking-basket__price-total--title-rebooking': configurations.totalPriceDueLabel } } })}
      ${TitleTemplate({ ...defaultTitleData, ...{ text: d.totalPrice, size: 'medium', extraClasses: 'w-booking-basket__price-total--amount m-heading--no-crop', attributes: { 'data-w-booking-basket__totalPriceText': '' } } })}
    </div>
  `
}

export const BookingBasketPriceTotalPerson = (d, configurations) => {
  return `
    <div class="w-booking-basket__price-total-person ${d.paidAmount ? 'is-hidden' : ''}" data-w-booking-basket__price-total-person>
      <p class="w-booking-basket__price-total-person--title">${configurations.averagePriceLabel}</p>
      ${TitleTemplate({ ...defaultTitleData, ...{ text: d.averagePrice, size: 'small', extraClasses: 'w-booking-basket__price-total-person--amount m-heading--no-crop', attributes: { 'data-w-booking-basket__averagePriceText': '' } } })}
    </div>
  `
}

import { propTypes, defaultProps } from './w-dd-grid.props'
import { checkPropTypes } from 'prop-types'
import { encodeAttributes } from '../../../js/helpers/string'
import { dateStringToDate } from '../../../js/helpers/dates'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { language } from '../../../js/user/locale-settings'
import { DDGridTemplateDataFromPrices } from './data-mapper'
import { DDGridTemplateDiscountBullet } from './w-dd-grid.discount.bullet.template'

export const DDGridTemplate = (props) => {
  const d = { ...defaultProps, ...props }
  checkPropTypes(propTypes, d, 'prop', 'DDGridTemplate')

  const { currentYear, departures, durations } = d.prices.length
    ? DDGridTemplateDataFromPrices(d.prices)
    : {
        currentYear: '',
        departures: {},
        durations: []
      }

  return `
    <div class="w-dd-grid ${d.extraClasses ? d.extraClasses : ''}"
      data-js-widget="w-dd-grid"
      ${d.bestValues ? d.bestValues.map(el => `data-threshold-${el.name}=${el.threshold}`).join(' ') : ''}
      ${d.attributes ? encodeAttributes(d.attributes) : ''}>
      <div class="w-dd-grid__header-wrapper">
        <div class="w-dd-grid__header-item">${_getLegend(d.highlightInfo)}</div>
        <div class="w-dd-grid__header-item">${_getDdGridNavigationWrapper(d.texts.navigationNavText)}</div>
      </div>
      <div class="w-dd-grid__wrapper">
        <div class="w-dd-grid__left-area">
          <div class="w-dd-grid__cell w-dd-grid__cell--header">${currentYear}</div>
          ${durations.map(duration => `<div class="w-dd-grid__cell">${_getDurationColumnCellText(duration, d.showDurationInNights, d.texts)}</div>`).join('')}
        </div>
        <div class="w-dd-grid__right-area">
          <div class="w-dd-grid__panorama">
          ${Object.keys(departures).sort().map(departure => {
            const departureDate = dateStringToDate(departure)
            return (
              `<div class="w-dd-grid__column">
                <time datetime="${departure}" class="w-dd-grid__cell w-dd-grid__cell--header">
                  <span class="w-dd-grid__weekday">${departureDate.toLocaleDateString(language, { weekday: 'long' })}</span>
                  <span class="w-dd-grid__date">${departureDate.toLocaleDateString(language, { month: 'short', day: 'numeric' })}</span>
                </time>
                ${durations.map(duration => {
                  const priceData = departures[departure][duration]
                  const priceShown = priceData
                    ? priceData.minPricePerPersonFormatted || priceData.minPricePerPerson
                    : undefined
                  const priceValue = priceData
                    ? priceData.minPricePerPerson
                    : undefined
                  const bestValueScore = (priceData && priceData.bestValueScore) ? priceData.bestValueScore : undefined
                  return `
                    <button class="m-button--clean w-dd-grid__cell ${_getCellThresholdExtraClasses(d.bestValues, bestValueScore)}" data-departure-date="${departure}" data-duration="${duration}" data-price="${priceValue}" data-transport-type="${priceData?.transportType}" data-is-flight-only="${priceData?.isFlightOnly}" data-mealplan="${priceData?.mealplan}" ${bestValueScore ? `data-best-value-score="${bestValueScore}"` : ''} type="button" ${priceShown ? '' : 'disabled'}>
                      ${priceShown || '‒'}
                      ${_getPriceHighlight(priceData, d.highlightInfo)}
                    </button>
                  `
                }).join('')}
              </div>`)
          }).join('')}
          ${_getNextMonthColumn(d)}
          </div>
        </div>
      </div>
      ${d.mandatoryExtraCostsText || d.staticText || (d.bestValues && d.bestValuesCaption)
        ? `<div class="w-dd-grid__footer-wrapper">
            ${d.mandatoryExtraCostsText || d.staticText ? _showMandatoryExtraInfo([d.mandatoryExtraCostsText, d.staticText]) : ''}
            ${d.bestValues && d.bestValuesCaption ? _showTitleWithDescription(d.bestValuesCaption.title, d.bestValuesCaption.description) : ''}
          </div>`
        : ''}
    </div>
    `
}

function _getNextMonthColumn (d) {
  return d.showAddNextMonth
    ? `
    <div class="w-dd-grid__column w-dd-grid__navigation-add-next-month-column">
      ${BtnTemplate({
        type: 'button',
        icon: 'chevron-right',
        iconPosition: 'right',
        variant: 'neutral',
        text: '',
        jsApi: true,
        extraClasses: 'w-dd-grid__navigation-add-next-month-button'
      })}
    </div>
  `
    : ''
}

function _getDdGridNavigationWrapper (navigationNavText) {
  return `
    <div class="w-dd-grid__navigation-wrapper">
      ${BtnTemplate({
        icon: 'chevron-left',
        text: '',
        type: 'button',
        variant: 'icon-only',
        iconPosition: 'left',
        extraClasses: 'w-dd-grid__navigation w-dd-grid__navigation--left'
      })}
      ${navigationNavText ? `<div class="w-dd-grid__navigation-title">${navigationNavText}</div>` : ''}
      ${BtnTemplate({
        icon: 'chevron-right',
        text: '',
        type: 'button',
        variant: 'icon-only',
        iconPosition: 'right',
        extraClasses: 'w-dd-grid__navigation w-dd-grid__navigation--right'
      })}
    </div>
  `
}

function _getDurationColumnCellText (duration, showDurationInNights, texts) {
  if (showDurationInNights) {
    return `${duration - 1} ${texts.durationInNightsText}`
  } else {
    return `${duration} ${texts.durationInDaysText}`
  }
}

function _getCellThresholdExtraClasses (thresholds, bestValueScore) {
  if (thresholds && bestValueScore !== undefined) {
    return thresholds.map(
      el => `${(bestValueScore >= el.threshold) ? ('w-dd-grid__cell-threshold-' + el.name) : ''}`
    ).join(' ')
  } else {
    return ''
  }
}

function _getPriceHighlight (packagePrice, hightlightOptions) {
  if (packagePrice && packagePrice.highlightCode && hightlightOptions) {
    const option = hightlightOptions.find(ho => ho.code === packagePrice.highlightCode)
    if (option) {
      return _isOnRequest(option)
        ? `<div class="w-dd-grid__onrequest">${option.description}</div>`
        : DDGridTemplateDiscountBullet({ ...option, extraClasses: 'w-dd-grid__discount_bullet_placement' })
    }
  }
  return ''
}

function _getLegend (highlightInfo) {
  if (!highlightInfo) return ''
  return `<div class="w-dd-grid__legend">
            ${Object.entries(highlightInfo).map(([legendItemId, legendItem]) => legendItem.hexColor && _getLegendEntry(legendItem)).join('')}
          </div>`
}

function _getLegendEntry (legendItem) {
  if (_isOnRequest(legendItem)) {
    return ''
  }

  return DDGridTemplateDiscountBullet({ ...legendItem, extraClasses: 'w-dd-grid__legend_item_bullet' }) +
          `<div class="w-dd-grid__legend_item_description">${legendItem.description}</div>`
}

function _isOnRequest (item) {
  return item
    ? item.code ? item.code.toUpperCase() === 'ONREQUEST' : false
    : false
}

function _showMandatoryExtraInfo (extraInfo) {
  return `<div class="w-dd-grid__footer-extra-info m-body--tiny">${extraInfo.filter(text => !!text).join(' ')}</div>`
}

function _showTitleWithDescription (title, description) {
  return `
    <div class="w-dd-grid__footer-caption m-body--tiny">
      <div class="w-dd-grid__footer-caption-title">${title || ''}</div>
      <div class="w-dd-grid__footer-caption-description">${description || ''}</div>
    </div>`
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingParticipantsBadge from '../../../../shared/modules/widgets/booking-participants-badge/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const bookingParticipantsBadgeData = require('../../../../shared/modules/widgets/booking-participants-badge/data/w-booking-participants-badge__js-template__new-response')
const bookingParticipantsBadgeWithoutRoomsData = require('../../../../shared/modules/widgets/booking-participants-badge/data/w-booking-participants-badge__js-template__without-rooms')

loadQueueHelper.add(function () {
  initBookingParticipantBadge()
}, 2)

const initBookingParticipantBadge = async () => {
  const bookingParticipantsBadgeItems = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-participants-badge"]')

  bookingParticipantsBadgeItems.forEach(element => {
    const bookingParticipantsBadge = new BookingParticipantsBadge(element)
    return bookingParticipantsBadge
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingParticipantsBadgeJsTemplate = document.querySelectorAll('[data-booking-participants-badge-demo]')
  handleFetched(bookingParticipantsBadgeJsTemplate, bookingParticipantsBadgeData)

  const bookingParticipantsBadgeWithoutRoomsJsTemplate = document.querySelectorAll('[data-booking-participants-badge-demo-without-rooms]')
  handleFetched(bookingParticipantsBadgeWithoutRoomsJsTemplate, bookingParticipantsBadgeWithoutRoomsData)
}

import * as loadQueueHelper from '../../shared/js/document/load-queue'
import { TemplatesDictionary } from './demo-templates'
import Component from '../../shared/js/core/component/component'
import initDocumentWidgetsFromAPI from '../../shared/js/core/widget/widget'
import Swipe from '../../shared/modules/objects/swipe/main'
import Img from '../../shared/modules/components/img/main'
import { elementFromString } from '../../shared/js/document/html-helper'

loadQueueHelper.add(function () {
  document
    .querySelectorAll('[data-docs-jscomponent]')
    .forEach(function (component) {
      if (component) {
        const componentName = component.dataset.docsJscomponent
        const variant = component.dataset.docsVariant
        const elementContainer = component.querySelector('[data-element-container]')
        const scriptContainer = component.querySelector('[data-script-container]')
        const extraHtmlContainer = component.querySelector('[data-extra-html-container]')
        if (componentName) {
          let itemType = ''
          const prefix = componentName.substring(0, 2)
          const friendlyComponentName = componentName.substring(2)
          switch (prefix) {
            case 'c-':
              itemType = 'components'
              break
            case 'w-':
              itemType = 'widgets'
              break
          }

          if (itemType !== '') {
            const templateDefinition = TemplatesDictionary[componentName]
            if (templateDefinition) {
              const data = templateDefinition.data
                ? templateDefinition.data
                : require(`../../shared/modules/${itemType}/${friendlyComponentName}/data/${componentName}__js-template${variant}.json`)
              let hasToRender = true

              if (data.textsDocsScripts) {
                data.textsDocsScripts.forEach(content => {
                  const script = document.createElement('script')
                  script.dataset.uid = content.id
                  script.type = 'application/json'
                  script.innerHTML = JSON.stringify(content)
                  scriptContainer.appendChild(script)
                })
              }

              if (templateDefinition.Type) {
                let myInstance = null
                if (templateDefinition.renderedInConstructor) {
                  myInstance = new templateDefinition.Type(elementContainer, { data })
                  hasToRender = false
                } else if (templateDefinition.renderMethod) {
                  myInstance = new templateDefinition.Type(elementContainer)
                  if (myInstance[templateDefinition.renderMethod]) {
                    myInstance[templateDefinition.renderMethod]({ response: data })
                    hasToRender = false
                  }
                }
              }

              if (hasToRender) {
                const html = templateDefinition.template(data)
                elementContainer.innerHTML = html
              }

              if (data.extraDocsHtml) {
                const extraDocsHtmlElement = elementFromString(data.extraDocsHtml)
                extraHtmlContainer.appendChild(extraDocsHtmlElement)
              }

              Swipe.CreateInstancesOnDocument(component)
              Component.initDocumentComponentsFromAPI(component)
              Component.initComponentActionElements(component)
              Img.createInstancesOnDocument(component)
              initDocumentWidgetsFromAPI(component)
            }
          }
        }
      }
    })
})


export const handleFetched = (items, data = null) => {
  if (items && items.length && data) {
    setTimeout(() => {
      const handleFetchedPromises = Array.from(items).map(element => {
        const bookingItem = element.firstElementChild
        return bookingItem['w-booking-item'].handleFetched({ response: data })
      })
      handleFetchedPromises.forEach(promise => promise.then(resolvedItem => { resolvedItem.enableUI() }))
    }, 1500)
  }
}

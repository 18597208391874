import BookingItem from '../booking-item/main'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBasketServiceLineTemplate } from './booking-basket__overview-lines.template'
import BookingBasketDataMapper from './data-mapper'
import { BookingMessageTemplate } from '../booking-message/w-booking-message.template'
import { BookingBasketTemplate } from './w-booking-basket.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import Img from '../../components/img/main'

const widgetApi = 'w-booking-basket'

const classNames = {
  opened: 'in',
  isEmpty: 'w-booking-basket__services-item--empty',
  hidden: 'is-hidden',
  totalPricePerPerson: 'w-booking-basket__price-total-person',
  totalPriceRebooking: 'w-booking-basket__price-rebooking',
  messagesRebooking: 'w-booking-basket__message'
}

const attr = {
  texts: {
    totalLabelRebooking: 'wBookingBasket__priceTotal-TitleRebooking',
    totalLabel: 'wBookingBasket__priceTotal-Title'
  }
}

const widgetQueries = {
  sideDrawer: `[data-${widgetApi}__c-side-drawer]`,
  sideDrawerApi: 'c-side-drawer',
  stickyPrice: `[data-${widgetApi}__mobile-footer]`,
  basketService: `[data-${widgetApi}__service]`,
  basketServiceBody: `[data-${widgetApi}__services-body]`,
  totalPrice: `[data-${widgetApi}__totalPriceText]`,
  averagePrice: `[data-${widgetApi}__averagePriceText]`,
  paidAmountPrice: `[data-${widgetApi}__paidAmountText]`,
  packagePrice: `[data-${widgetApi}__packagePriceText]`,
  averagePackagePrice: `[data-${widgetApi}__packagePersonText]`,
  rebookingBlock: `[data-${widgetApi}__price-rebooking]`,
  totalLabel: `[data-${widgetApi}__price-total--title]`,
  bookingMessagesElement: `[data-${widgetApi}__messages]`,
  totalPricePerPerson: `[data-${widgetApi}__price-total-person]`,
  totalPriceDiv: `[data-${widgetApi}__price-total]`,
  configurationElement: `[data-${widgetApi}__configuration]`
}

const configOptions = {
  totalPriceLabel: 'totalPriceLabel',
  averagePriceLabel: 'averagePriceLabel',
  priceSectionLabel: 'priceSectionLabel',
  pricePackageLabel: 'pricePackageLabel',
  pricePaidLabel: 'pricePaidLabel',
  totalPricePaidLabel: 'totalPricePaidLabel',
  contentFooter: 'contentFooter',
  contentFooterExtraClasses: 'contentFooterExtraClasses',
  totalPriceDueLabel: 'totalPriceDueLabel',
  extraInfo: 'extraInfo',
  priceFreeText: 'priceFreeText'
}

/**
 * BookingBasket widget
 */
export default class BookingBasket extends BookingItem {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   */
  constructor (element) {
    super(element)
    this.element = element
    this.dataMapper = new BookingBasketDataMapper()
    this.totalPriceElements = document.querySelectorAll(widgetQueries.totalPrice)
    this.averagePriceElements = document.querySelectorAll(widgetQueries.averagePrice)
    this.paidAmountPriceElements = document.querySelectorAll(widgetQueries.paidAmountPrice)
    this.packagePriceElements = document.querySelectorAll(widgetQueries.packagePrice)
    this.averagepackagePriceElements = document.querySelectorAll(widgetQueries.averagePackagePrice)
    this.overviewServicesElements = document.querySelectorAll(widgetQueries.basketService)
    this.rebookingBlockElements = document.querySelectorAll(widgetQueries.rebookingBlock)
    this.totalLabelElement = document.querySelectorAll(widgetQueries.totalLabel)
    this.TextLabelTotalRebooking = this.totalLabelElement[0] ? this.totalLabelElement[0].dataset[attr.texts.totalLabelRebooking] : ''
    this.TextLabelTotal = this.totalLabelElement[0] ? this.totalLabelElement[0].dataset[attr.texts.totalLabel] : ''
    this.totalPricePerPersonElements = document.querySelectorAll(widgetQueries.totalPricePerPerson)
    this.totalPriceSection = document.querySelectorAll(widgetQueries.totalPriceDiv)

    const filterConfigurationElement = this.element.querySelector(widgetQueries.configurationElement)
    this.configurations = this._readOptionsFromConfigurations(filterConfigurationElement)

    this._attachEvents()
  }

  /**
   *
   * @param {Object} data - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    if (this.configurations) {
      this.data = data.response
      this._updateWidgetData()
    } else {
      // To be removed when the widget is rendered ClientSide
      const basketData = this.dataMapper.mapBasketInfo(data.response)
      this._updateWidget(basketData)
    }
    return super.handleFetched(data.response)
  }

  _attachEvents () {
    this._attachSideDrawer()
  }

  _attachSideDrawer () {
    const sideDrawerElement = document.querySelector(widgetQueries.sideDrawer)
    if (sideDrawerElement != null) {
      const sideDrawerApi = sideDrawerElement[widgetQueries.sideDrawerApi]
      const stickyPriceElement = document.querySelector(widgetQueries.stickyPrice)
      if (sideDrawerApi) {
        sideDrawerApi.events.on('open', () => {
          stickyPriceElement.classList.add(classNames.opened)
        })

        sideDrawerApi.events.on('close', () => {
          stickyPriceElement.classList.remove(classNames.opened)
        })
      }
    }
  }

  // To be removed when the widget is rendered ClientSide
  _updateWidget (basketData) {
    // Update total prices
    let index
    for (index = 0; index < this.totalPriceElements.length; index++) {
      this.totalPriceElements[index].innerHTML = basketData.totalPrice
    }

    // Update average prices
    for (index = 0; index < this.averagePriceElements.length; index++) {
      this.averagePriceElements[index].innerHTML = basketData.averagePrice
    }

    if (basketData.paidAmount) {
      for (index = 0; index < this.rebookingBlockElements.length; index++) {
        this.rebookingBlockElements[index].classList.remove(classNames.hidden)
      }

      for (index = 0; index < this.packagePriceElements.length; index++) {
        this.packagePriceElements[index].innerHTML = basketData.totalPackagePrice
      }

      for (index = 0; index < this.totalLabelElement.length; index++) {
        this.totalLabelElement[index].innerHTML = this.TextLabelTotalRebooking
      }

      for (index = 0; index < this.paidAmountPriceElements.length; index++) {
        this.paidAmountPriceElements[index].innerHTML = basketData.paidAmount
      }

      for (index = 0; index < this.averagepackagePriceElements.length; index++) {
        this.averagepackagePriceElements[index].innerHTML = basketData.avgtotalPackagePrice
      }

      for (index = 0; index < this.totalPricePerPersonElements.length; index++) {
        this.totalPricePerPersonElements[index].classList.add(classNames.hidden)
      }

      for (index = 0; index < this.totalPriceSection.length; index++) {
        this.totalPriceSection[index].classList.add(classNames.totalPriceRebooking)
      }
    } else {
      for (index = 0; index < this.totalLabelElement.length; index++) {
        this.totalLabelElement[index].innerHTML = this.TextLabelTotal
      }

      for (index = 0; index < this.rebookingBlockElements.length; index++) {
        this.rebookingBlockElements[index].classList.add(classNames.hidden)
      }

      for (index = 0; index < this.totalPricePerPersonElements.length; index++) {
        this.totalPricePerPersonElements[index].classList.remove(classNames.hidden)
      }

      for (index = 0; index < this.totalPriceSection.length; index++) {
        this.totalPriceSection[index].classList.remove(classNames.totalPriceRebooking)
      }
    }

    const messagesElement = document.querySelector(widgetQueries.bookingMessagesElement)
    if (messagesElement) {
      if (basketData.rebookingWarning) {
        messagesElement.innerHTML = BookingMessageTemplate(basketData.rebookingWarning)
        messagesElement.classList.add(classNames.messagesRebooking)
      } else {
        messagesElement.innerHTML = ''
        messagesElement.classList.remove(classNames.messagesRebooking)
      }
    }

    // Update overview services
    for (index = 0; index < this.overviewServicesElements.length; index++) {
      const currentServiceElement = this.overviewServicesElements[index]
      const newService = basketData.overviewServices.find(overview => overview.id === currentServiceElement.dataset.wBookingBasket__service)
      if (newService) {
        currentServiceElement.classList.remove(classNames.isEmpty)
        const linesBody = currentServiceElement.querySelector(widgetQueries.basketServiceBody)
        if (linesBody) {
          const newInner = BookingBasketServiceLineTemplate(newService)
          linesBody.innerHTML = newInner
        }
      } else {
        currentServiceElement.classList.add(classNames.isEmpty)
      }
    }
  }

  _updateWidgetData () {
    let widgetData

    if (this.data) {
      widgetData = this.dataMapper.mapBasketInfo(this.data, this.configurations)
    }

    if (widgetData) {
      super.showComponent()
      this._init(widgetData)
    } else {
      super.hideComponent()
    }
  }

  _init (data) {
    const renderedHtml = BookingBasketTemplate(data, this.configurations)
    const newContent = elementFromString(renderedHtml)

    this._render(newContent)
    Img.createInstancesOnDocument(newContent)
    Component.initDocumentComponentsFromAPI(newContent)

    this._attachEvents()
  }

  _render (el) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(el)
  }

  _readOptionsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)
      const priceConfigurations = super.readPriceConfigFromConfigurations(configuration)

      return {
        ...priceConfigurations,
        totalPriceLabel: configuration[configOptions.totalPriceLabel],
        averagePriceLabel: configuration[configOptions.averagePriceLabel],
        priceSectionLabel: configuration[configOptions.priceSectionLabel],
        pricePackageLabel: configuration[configOptions.pricePackageLabel],
        pricePaidLabel: configuration[configOptions.pricePaidLabel],
        totalPriceDueLabel: configuration[configOptions.totalPriceDueLabel],
        totalPricePaidLabel: configuration[configOptions.totalPricePaidLabel],
        contentFooter: configuration[configOptions.contentFooter],
        contentFooterExtraClasses: configuration[configOptions.contentFooterExtraClasses],
        extraInfo: configuration[configOptions.extraInfo],
        priceFreeText: configuration[configOptions.priceFreeText]
      }
    }
  }
}

registerWidget(BookingBasket, widgetApi)

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingPreferencesSelector from '../../../../shared/modules/widgets/booking-preferences-selector/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const bookingPreferencesSelectorData = require('../../../../shared/modules/widgets/booking-preferences-selector/data/w-booking-preferences-selector__js-template.json')
const bookingPreferencesSelectorDataWithoutCategories = require('../../../../shared/modules/widgets/booking-preferences-selector/data/w-booking-preferences-selector__js-template--without-categories.json')

loadQueueHelper.add(function () {
  initPreferencesSelector()
}, 2)

const initPreferencesSelector = async () => {
  const bookingPreferencesSelectorItems = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-preferences-selector"]')

  bookingPreferencesSelectorItems.forEach(element => {
    const bookingPreferencesSelector = new BookingPreferencesSelector(element)
    console.log(bookingPreferencesSelector)
  })
  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingPreferencesSelectorJsTemplate = document.querySelectorAll('[data-booking-preferences-selector-demo]')
  handleFetched(bookingPreferencesSelectorJsTemplate, bookingPreferencesSelectorData)

  const bookingPreferencesSelectorJsTemplateWithoutCategories = document.querySelectorAll('[data-booking-preferences-selector-demo--without-categories]')
  handleFetched(bookingPreferencesSelectorJsTemplateWithoutCategories, bookingPreferencesSelectorDataWithoutCategories)
}

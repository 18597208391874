/**
 * The FlightBusSelectorData contains all data as received from API
 *
 * @typedef {Object}          FlightBusSelectorData
 *
 * @property {Object.<FlightId|BusId, FlightData|BusData>}    outboundData
 * @property {Object}                                         outboundData.inboundRelatedItems
 * @property {Object.<FlightId|BusId, FlightData|BusData>}    inboundData
 */

import { EventEmitter } from 'eventemitter3'
import Component from '../../../js/core/component/component'
import FlightSelectorDataModel from './flight-selector-data-model'
import BusSelectorDataModel from './bus-selector-data-model'
import { FlightBusSelectorTemplate } from './w-flight-bus-selector.template'
import { NoFlightBusDataTemplate } from './w-no-flight-bus-data.template'
import { TransportTicketTemplate } from '../../components/transport-ticket/c-transport-ticket.template'
import { elementFromString, flush, moveChildrenFrom } from '../../../js/document/html-helper'
import { debounce } from '../../../js/utils'
import { register } from '../../../js/document/namespace'
import { language } from '../../../js/user/locale-settings'
import registeredEvents from '../../../js/helpers/registered-events'
import { flightBusSelectorEvents } from '../../../js/document/event-types'

const flightSelectorLocales = register(`window.sundio.i18n.${language}.flightSelector`)

const TYPE = { OUT: 'outbound', IN: 'inbound' }
const widgetAPI = 'w-flight-bus-selector'

const widgetQueries = {
  outbound: `.${widgetAPI}__departure-tab`,
  inbound: `.${widgetAPI}__arrival-tab`,
  relatedItems: `.${widgetAPI}__options`,
  unrelatedItems: `.${widgetAPI}__options--unrelated`,
  unrelatedWrapper: `.${widgetAPI}__heading--unrelated`,
  selectedOutboundItem: `.${widgetAPI}__selected--outbound`,
  selectedInboundItem: `.${widgetAPI}__selected--inbound`,
  submitElement: `.${widgetAPI}__submit`,
  sideDrawer: '[data-js-component="c-side-drawer"]',
  collapse: '[data-js-component="c-collapse"]',
  isHiddenClassName: 'is-hidden',
  itemInput: 'input[type=radio]',
  item: `.${widgetAPI}__item`,
  openJawSwitch: `[data-${widgetAPI}__open-jaw]`,
  cancel: `.${widgetAPI}__cancel`,
  switchTab: '[data-js-component="c-side-drawer"] [data-js-component="c-tabs"]',
  switchTabButtons: `.${widgetAPI}__tabs-nav > [data-c-tabs__action="showTab"]`
}

const dataModelDictionary = {
  flight: FlightSelectorDataModel,
  bus: BusSelectorDataModel
}

const defaults = {
  delay: 150,
  collapseAfter: 2, // Min number of items to init content as collapsable.
  singleRowClass: 'w-flight-bus-selector__collapse-single-row' // Classname to adjust min-height and prevent empty space if there's only single row inside collapse component.
}

/**
 * Flight/Bus Selector content
 *
 */
export default class FlightBusSelector {
  /**
   * Creates a new Flight Bus Selector behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement}                 element         - The HTML element.
   * @param {Object}                      [options={}]    - Options object
   * @param {String}                      [options.type]  - Flight/Bus
   * @param {FlightSelectorApiData}       [options.data]  - Options object
   */
  constructor (element, options = {}) {
    this.element = element
    this.options = {
      ...defaults,
      ...options
    }

    this.options.extraData.flightSelector = {
      ...flightSelectorLocales,
      ...this.options.extraData.flightSelector
    }

    this.events = (this.element[widgetAPI] && this.element[widgetAPI].events) || new EventEmitter()

    // Expose component public API
    this.element[widgetAPI] = {
      element: this.element,
      events: this.events,
      refresh: this.refresh.bind(this)
    }

    this._getItemsData = (type = TYPE.OUT) => (this.selectorData && this.selectorData[`${type}Data`]) || {}
    this._getItems = (type = TYPE.OUT) => this.items[type]
    this._getSelectedWrapper = (type = TYPE.OUT) => this.elements[`${type}SelectedWrapper`]
    this._getWrapper = (type = TYPE.OUT) => this.elements[`${type}Wrapper`]

    this.debouncedGetSelectedInfo = debounce(this._getSelectedInfo, this.options.delay)

    registeredEvents.registerWidgetEvents(widgetAPI, this.events, { track: 'track' })

    this._init()
  }

  getSelectedTransportData () {
    if (!this.selected[TYPE.IN] && !this.selected[TYPE.OUT]) {
      return undefined
    }

    return {
      inbound: {
        ...this._getItemsData(TYPE.IN)[this.selected[TYPE.IN].id],
        isDefaultOption: this._isSelectedTransportTheDefaultOption(TYPE.IN)
      },
      outbound: {
        ...this._getItemsData(TYPE.OUT)[this.selected[TYPE.OUT].id],
        isDefaultOption: this._isSelectedTransportTheDefaultOption(TYPE.OUT)
      }
    }
  }

  _init (data = this.options.data) {
    this._initDataModel()
    this._initElements()
    if (data && Object.keys(data).length > 0) {
      this._addListeners()
      this._appendItems()
    } else if (this.options.type) {
      // If we have the type then it is not a clear operation (refresh() method called with an empty object)
      // but an API request that failed; therefore the error condition must be shown.
      this._initError()
    }
    this._renderCollapse(this._getItems(TYPE.OUT).length, this._getItems(TYPE.IN).length)
  }

  _renderCollapse (outboundRows, inboundRows) {
    if (this.elements.collapse) {
      const itemsLength = Math.max(inboundRows, outboundRows)
      if (itemsLength <= this.options.collapseAfter) {
        this.elements.collapse.setProp('maxItems', itemsLength)
        this.elements.collapse.element.classList.add(this.options.singleRowClass)
      } else {
        this.elements.collapse.setProp('maxItems', 0)
        this.elements.collapse.element.classList.remove(this.options.singleRowClass)
      }
      this.elements.collapse.update()
    }
  }

  _initDataModel (data = this.options.data) {
    if (this.element[widgetAPI] && this.element[widgetAPI].selectorDataModel) {
      delete this.element[widgetAPI].selectorDataModel
    }
    const DataModel = dataModelDictionary[this.options.type]
    if (!DataModel) { console.warn('There is no data model for the type provided') }
    if (data && Object.keys(data).length > 0) {
      this.element[widgetAPI].selectorDataModel = new DataModel({ ...this.options.data, id: this.element.id })
      this.selectorDataModel = this.element[widgetAPI].selectorDataModel
      this.selectorData = this.selectorDataModel.getSelectorData()

      const defaultOutboundTransport = Object.entries(this.selectorData.outboundData).find(([key, transport]) => transport.isSelected)
      const defaultInboundTransport = Object.entries(this.selectorData.inboundData).find(([key, transport]) => transport.isSelected)

      this.defaultTransportOutboundId = defaultOutboundTransport ? defaultOutboundTransport[0] : undefined
      this.defaultTransportInboundId = defaultInboundTransport ? defaultInboundTransport[0] : undefined
    } else {
      this.element[widgetAPI].selectorDataModel = null
      this.selectorDataModel = null
      this.selectorData = null
    }
  }

  _initElements () {
    this._createHtmlWrapper()
    Component.initDocumentComponentsFromAPI(this.element)
    this.preselected = {}
    this.selected = {}
    this.items = this._createHtmlItems()
    this.elements = this._getHtmlElements()
  }

  /**
   * Returns the created inbound/outbound items
   *
   * @returns {Object}
   */
  _createHtmlWrapper () {
    if (this.selectorDataModel) {
      const flightBusSelectorHtml = elementFromString(FlightBusSelectorTemplate(this.selectorDataModel.getSelectorTemplateData({ locales: this.options.extraData })))
      moveChildrenFrom(flightBusSelectorHtml, this.element, { flush: true, attributes: true })
    } else {
      flush(this.element)
    }
  }

  /**
   * Returns the created inbound/outbound items
   *
   * @returns {Object}
   */
  _createHtmlItems () {
    const items = {
      [TYPE.OUT]: Object.values(this._getItemsData(TYPE.OUT)).map((outboundData) => elementFromString(
        TransportTicketTemplate(this.selectorDataModel.getSelectorItemTemplateData(outboundData, { locales: this.options.extraData })))
      ),
      [TYPE.IN]: Object.values(this._getItemsData(TYPE.IN)).map((inboundData) => elementFromString(
        TransportTicketTemplate(this.selectorDataModel.getSelectorItemTemplateData(inboundData, { locales: this.options.extraData, isInbound: true })))
      )
    }
    const allItems = [...items[TYPE.OUT], ...items[TYPE.IN]]
    allItems.forEach((item) => (item.itemInput = item.querySelector(widgetQueries.itemInput)))

    return items
  }

  /**
   * Returns the different lists of items
   *
   * @returns {Object}
   */
  _getHtmlElements () {
    return {
      outboundSelectedWrapper: this.element.querySelector(widgetQueries.selectedOutboundItem),
      inboundSelectedWrapper: this.element.querySelector(widgetQueries.selectedInboundItem),
      outboundWrapper: this.element.querySelector(`${widgetQueries.outbound} ${widgetQueries.relatedItems}`),
      inboundWrapper: this.element.querySelector(`${widgetQueries.inbound} ${widgetQueries.relatedItems}`),
      inboundUnrelatedWrapper: this.element.querySelector(`${widgetQueries.inbound} ${widgetQueries.unrelatedItems}`),
      inboundUnrelatedSuperWrapper: this.element.querySelector(`${widgetQueries.unrelatedWrapper}`),
      submitElement: this.element.querySelector(widgetQueries.submitElement),
      sideDrawer: (this.element.querySelector(widgetQueries.sideDrawer) || {})['c-side-drawer'],
      collapse: (this.element.querySelector(widgetQueries.collapse) || {})['c-collapse'],
      openJawSwitch: this.element.querySelector(widgetQueries.openJawSwitch),
      cancelElement: this.element.querySelector(widgetQueries.cancel),
      switchTab: (this.element.querySelector(widgetQueries.switchTab) || {})['c-tabs'],
      switchTabButtons: this.element.querySelectorAll(widgetQueries.switchTabButtons)
    }
  }

  _addListeners () {
    this.elements.sideDrawer.events.on('closed', () => {
      this.events.emit(flightBusSelectorEvents.FLIGHT_SELECTOR_SIDE_DRAWER_CLOSED, this._getDataObject())
      Object.values(TYPE).forEach((type) => { if (this.preselected[type]) { this._checkPreselection(type) } })
      this.preselected = {}
    })

    this.elements.sideDrawer.events.on('opened', () => {
      this.currentTab = this.elements.switchTab.getProp('currentTab')
      this.events.emit(flightBusSelectorEvents.FLIGHT_SELECTOR_SIDE_DRAWER_OPENED, this._getDataObject())
    })

    this.elements.collapse.events.on('closed', () => {
      this.events.emit(flightBusSelectorEvents.FLIGHT_SELECTOR_COLLAPSE_CLOSED, this._getDataObject())
    })

    this.elements.collapse.events.on('opened', () => {
      this.events.emit(flightBusSelectorEvents.FLIGHT_SELECTOR_COLLAPSE_OPENED, this._getDataObject())
    })

    this.elements.cancelElement.addEventListener('click', () => {
      this.events.emit(flightBusSelectorEvents.FLIGHT_SELECTOR_CANCEL, this._getDataObject())
    })

    this.elements.switchTabButtons.forEach(btn => {
      btn.addEventListener('click', (ev) => {
        const currentTab = this.elements.switchTab.getProp('currentTab')
        const switchedTab = currentTab !== this.currentTab
        if (btn.classList.contains('is-active') && switchedTab) {
          this.currentTab = currentTab
          this.events.emit(flightBusSelectorEvents.FLIGHT_SELECTOR_SWITCH_TAB, this._getDataObject())
        }
      })
    })

    this.elements.submitElement.addEventListener('click', () => {
      this.events.emit(flightBusSelectorEvents.FLIGHT_SELECTOR_SAVE, this._getDataObject())
      this.preselected.state = true
    })

    if (this.elements.openJawSwitch) {
      this.elements.openJawSwitch.addEventListener('click', () => {
        this._emitOpenJawSwitch()
        this._refreshOutbountItems()
      })
    }

    // For all types (inbound, outbound), bind click event to item
    Object.values(TYPE).forEach((type) => {
      this._getItems(type).forEach((item) => item.addEventListener('click', (ev) => {
        ev.preventDefault()
        this.events.emit(flightBusSelectorEvents.FLIGHT_SELECTOR_SELECT_FLIGHT, this._getDataObject(type, ev.isTrusted))
        this._clickItem(item, type)
      }))
    })
  }

  _getDataObject (type, interaction = true) {
    if (!type) {
      type = this.elements.sideDrawer.getProp('open')
        ? this.elements.switchTab.getProp('currentTab').includes('departure-tab')
          ? 'outbound'
          : 'inbound'
        : 'outbound'
    }
    return {
      type,
      interaction,
      transportType: this.options.type
    }
  }

  _emitOpenJawSwitch () {
    this.events.emit('openJawSwitch', {
      enabled: this.elements.openJawSwitch ? this.elements.openJawSwitch.checked : true,
      element: this.elements.openJawSwitch
    })
  }

  _refreshOutbountItems () {
    this.previousSelectedOutbound = this.selected.outbound.id
    this.previousSelectedInbound = this.selected.inbound.id

    this._deselectedFlights()
    this._getWrapper(TYPE.OUT).innerHTML = ''
    this._appendItems()
  }

  /**
   * First boot of the items (adding outbound/inbound items and select the first option by default)
   *
   */
  _appendItems () {
    const isOpenJawEnabled = this.elements.openJawSwitch ? this.elements.openJawSwitch.checked : true
    const itemsToRender = []
    let selectedItem

    this._getItems(TYPE.OUT).forEach((itemElement) => {
      const itemInfo = this._getItemsData(TYPE.OUT)[itemElement.id]
      const inboundRelatedItemsAsArray = Object.entries(itemInfo.inboundRelatedItems)
      const hasAnyRelatedItemCloseJaw = inboundRelatedItemsAsArray.some(([key, value]) => !value.isOpenJaw)

      if (!isOpenJawEnabled) {
        if (hasAnyRelatedItemCloseJaw) {
          itemsToRender.push({ element: itemElement, info: itemInfo })
          this._getWrapper(TYPE.OUT).appendChild(itemElement)
        }
      } else {
        itemsToRender.push({ element: itemElement, info: itemInfo })
        this._getWrapper(TYPE.OUT).appendChild(itemElement)
      }
    })

    if (itemsToRender.length) {
      if (this.previousSelectedOutbound) {
        selectedItem = itemsToRender.find(item => item.element.id === this.previousSelectedOutbound)
        this.previousSelectedOutbound = undefined
      } else if (!selectedItem) {
        selectedItem = itemsToRender.find(item => item.info.isSelected)
      }
      if (!selectedItem) {
        selectedItem = itemsToRender[0]
      }
      if (selectedItem) {
        this._clickItem(selectedItem.element, TYPE.OUT)
      }
    }
    this._renderCollapse(itemsToRender.length, this._getItems(TYPE.IN).length)
  }

  _initError () {
    const flightBusSelectorHtml = elementFromString(NoFlightBusDataTemplate({
      id: this.element.id,
      title: this.options.type === 'flight'
        ? this.options.extraData.flightSelector.headerText
        : this.options.extraData.busSelector.headerText,
      message: this.options.type === 'flight'
        ? this.options.extraData.flightSelector.noDataAvailableText
        : this.options.extraData.busSelector.noDataAvailableText
    }))
    moveChildrenFrom(flightBusSelectorHtml, this.element, { flush: true, attributes: true })
  }

  // HELPER FUNCTIONS
  /**
   * Selects (or preselects) an item. For outbound, it checks the related inbound items
   *
   * @param {HTMLElement} [item] - Selected item
   * @param {String}      [type] - outbound or inbound
   */
  _clickItem (item, type = TYPE.OUT) {
    this._getItems(type).forEach((_item) => _item.classList[_item === item ? 'add' : 'remove']('is-checked'))

    if (this.elements.sideDrawer.getProp('open')) { this.preselected[type] = item } else { this._selectItem(item, type) }

    if (type === TYPE.OUT) { this._checkInboundItems(this._getItemsData(type)[item.id]) }
  }

  /**
   * Selects the real item (in desktop just click; in mobile, when accepted through side drawer)
   * This content is only shown in mobile
   *
   * @param {HTMLElement} [item]            - Selected item
   * @param {HTMLElement} [item.itemInput]  - Radio input linked to the main item
   * @param {String}      [type]            - outbound or inbound
   */
  _selectItem (item, type = TYPE.OUT) {
    const wrapper = this._getSelectedWrapper(type)
    this.selected[type] = item
    this.debouncedGetSelectedInfo()
    if (wrapper.children.length < 1 || wrapper.firstChild.id !== item.id) {
      const itemClone = item.cloneNode(true)
      itemClone.querySelector('.c-radio-button').remove()
      flush(wrapper)
      wrapper.appendChild(itemClone)
      item.itemInput.checked = true
    }
  }

  /**
   * Check whether the inbound items are related to the outbound item or not, separating them visually
   *
   * @param {Object} [itemInfo] - Options object
   */
  _checkInboundItems (itemInfo) {
    const isOpenJawEnabled = this.elements.openJawSwitch ? this.elements.openJawSwitch.checked : true
    const inboundRelatedItemsAsArray = Object.entries(itemInfo.inboundRelatedItems)
    const closeJawFlightsArray = inboundRelatedItemsAsArray.filter(([key, value]) => !value.isOpenJaw)

    let previousInboundItem
    this._getItems(TYPE.IN).forEach((inboundItem) => {
      const isInboundItemCloseJaw = closeJawFlightsArray.some(([key, value]) => inboundItem.id === key)
      if (this.previousSelectedInbound && inboundItem.id === this.previousSelectedInbound) {
        previousInboundItem = inboundItem
      }

      const isRelated = !!itemInfo.inboundRelatedItems[inboundItem.id]
      const isCloseJawRelated = Array.from(this._getWrapper(TYPE.OUT).children).some(item => {
        return this._getItemsData(TYPE.OUT)[item.id].departureAirportId === this._getItemsData(TYPE.IN)[inboundItem.id].arrivalAirportId
      })

      if (isOpenJawEnabled) {
        this._getWrapper(`${(TYPE.IN)}${!isRelated ? 'Unrelated' : ''}`).appendChild(inboundItem)
      } else if (isCloseJawRelated) {
        this._getWrapper(`${(TYPE.IN)}${(!isInboundItemCloseJaw) ? 'Unrelated' : ''}`).appendChild(inboundItem)
      } else {
        if (this._getWrapper(TYPE.IN).contains(inboundItem)) this._getWrapper(TYPE.IN).removeChild(inboundItem)
      }
    })

    this.previousSelectedInbound = undefined

    if (!this.selected[TYPE.IN] || (this.selected[TYPE.IN].parentNode !== this._getWrapper(TYPE.IN))) {
      let itemToSelect
      if (previousInboundItem && previousInboundItem.parentNode === this._getWrapper(TYPE.IN)) {
        itemToSelect = previousInboundItem
      }
      if (!itemToSelect) {
        itemToSelect = this._getWrapper(TYPE.IN).firstChild
      }
      if (itemToSelect) {
        itemToSelect.click()
      }
    }
    const isEmpty = this._getWrapper(`${(TYPE.IN)}Unrelated`).children.length < 1
    this.elements.inboundUnrelatedSuperWrapper.classList[isEmpty ? 'add' : 'remove'](widgetQueries.isHiddenClassName)
  }

  /**
   * Checks if preselection needs to be discarded or selected persistently
   *
   * @param {String} [type] - outbound or inbound
   */
  _checkPreselection (type = TYPE.OUT) {
    if (this.preselected.state) {
      this._selectItem(this.preselected[type], type)
    } else {
      this.preselected[type].classList.remove('is-checked')
      this.selected[type].classList.add('is-checked')
      if (type === TYPE.OUT) {
        this._checkInboundItems(this._getItemsData(type)[this.selected.outbound.id])
      }
    }
  }

  /**
   * Holds all the information related to the outbound-inbound combination selected
   */
  _getSelectedInfo () {
    if (this.selected[TYPE.OUT] && this.selected[TYPE.IN]) {
      const overviewInfo = this._getItemsData(TYPE.OUT)[this.selected[TYPE.OUT].id].inboundRelatedItems[this.selected[TYPE.IN].id]
      overviewInfo.detailedTripSummary = this.selectorData.detailedTripSummary
      overviewInfo.contentUsps = this.selectorData.contentUsps
      overviewInfo.labels = this.options.extraData
      overviewInfo.outboundFlightId = this.selected[TYPE.OUT].id
      overviewInfo.outboundTransportInfo = {
        ...this._getItemsData(TYPE.OUT)[this.selected[TYPE.OUT].id],
        isDefaultOption: this._isSelectedTransportTheDefaultOption(TYPE.OUT)
      }
      overviewInfo.inboundTransportInfo = {
        ...this._getItemsData(TYPE.IN)[this.selected[TYPE.IN].id],
        isDefaultOption: this._isSelectedTransportTheDefaultOption(TYPE.IN)
      }
      overviewInfo.transportType = this.options.type

      this.events.emit('selectedInfo', overviewInfo)
    }
  }

  _isSelectedTransportTheDefaultOption (type) {
    if (type === TYPE.OUT && this.selected[TYPE.OUT]) {
      return this.defaultTransportOutboundId === this.selected[TYPE.OUT].id
    } else if (TYPE.IN && this.selected[TYPE.IN]) {
      return this.defaultTransportInboundId === this.selected[TYPE.IN].id
    }

    return false
  }

  _deselectedFlights () {
    Object.values(TYPE).forEach((type) => {
      const wrapper = this._getSelectedWrapper(type)
      flush(wrapper)
      this.selected[type].classList.remove('is-checked')
      this.selected[type].itemInput.checked = false
      this.selected[type] = ''
    })
  }

  /**
   * Recreates HTML content
   *
   * @param {SelectedStateApiDataModel} data
   */
  refresh (options) {
    this.options = {
      ...defaults,
      ...options
    }
    this._init()
  }
}

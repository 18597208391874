import { TitleTemplate, defaultTitleData } from '../../components/title/c-title.template'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/w-booking-box__tokens.json')['w-booking-box']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/widgets/booking-box/data/w-booking-box__tokens.json`)['w-booking-box'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const BookingBoxHeadingTemplate = (d) => {
  return TitleTemplate({
    ...defaultTitleData,
    ...{
      text: d.text,
      size: getTokenClass('booking-box__title-size', 'default', tokens),
      extraClasses: 'w-booking-box__title'
    }
  })
}

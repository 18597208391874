import Swipe from '../../shared/modules/objects/swipe/main'
import Component from '../../shared/js/core/component/component'
import Img from '../../shared/modules/components/img/main'
import initDocumentWidgetsFromAPI from '../../shared/js/core/widget/widget'
import * as loadQueueHelper from '../../shared/js/document/load-queue'

loadQueueHelper.add(function () {
  const promisesArray = []
  document.querySelectorAll('[data-razor-url]').forEach(function (razorElement) {
    const url = razorElement.getAttribute('data-razor-url')

    const opts = {
      method: 'GET',
      headers: {}
    }

    const promise = window.fetch(url, opts).then(function (response) {
      return response.text()
    })
      .then(function (body) {
        razorElement.innerHTML = body
        Swipe.CreateInstancesOnDocument(razorElement)
        Component.initDocumentComponentsFromAPI(razorElement)
        Component.initComponentActionElements(razorElement)
        Img.createInstancesOnDocument(razorElement)
        initDocumentWidgetsFromAPI(razorElement)
      })
    promisesArray.push(promise)
  })
  Promise.all(promisesArray).then(function () {
    document.dispatchEvent(new window.CustomEvent('razor-load-complete'))
  })
})

import { operationTypeEnum } from './enums'

export default class OperationTypeMapper {
  /** Retrives the proper operation type
   *  If user is unselecting the service:
   *    If service has no option, then its an SWAP
   *    Else its a REMOVE
   *  If user is selecting the service then its an ADD
   *  If user is editing the service:
   *    If is changing at least the 'key' field (code+start date+end date) then is a SWAP
   *    Else is changing other fields but the key then its a PATCH
   * @param {object}        row             -- The current row
   * @param {object}        newOption       -- The new options selected
   * @param {boolean}       isUnselecting   -- True is the user is unselecting the service
   *
   * @returns {operationTypeEnum}   The operation type
   */
  static getOperationType (row, newOption, isUnselecting) {
    const previousOption = row.selectedOption
    let opType

    if (isUnselecting) {
      if (row.noneOption) {
        opType = operationTypeEnum.Swap
      } else {
        opType = operationTypeEnum.Remove
      }
    } else if (!previousOption) {
      opType = operationTypeEnum.Add
    } else if (previousOption.code === newOption.code && previousOption.startDate === newOption.startDate && previousOption.endDate === newOption.endDate) {
      opType = operationTypeEnum.Patch
    } else {
      opType = operationTypeEnum.Swap
    }

    return opType
  }
}

import { TabsNavItemTemplate } from '../tabs/c-tabs__nav-item.template'
import { SwipeTemplate } from '../../objects/swipe/o-swipe.template'

import { encodeAttributes } from '../../../js/helpers/string'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/c-tabs__tokens.json')['c-tabs']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/tabs/data/c-tabs__tokens.json`)['c-tabs'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

/**
 * The TabsNavData contains all data needed to hydrate a Tabs Nav selector view.
 *
 * @typedef {Object}        TabsNavData
 *
 * @property {String}             id                  - The component ID.
 * @property {TabsNavItemData[]}  items               - List of items that represent each one a tab item.
 * @property {Boolean}            [swipeable]         - True if the tabs are swipeable (true by default).
 * @property {String}             [variant]           - Variant of the component.
 * @property {String}             [extraClasses]      - CSS extra classes.
 * @property {Object}             [attributes]        - Extra attributes.
 */
export const defaultTabsNavData = {
  id: '',
  items: [],
  swipeable: true,
  variant: '',
  extraClasses: '',
  attributes: {}
}

const swipeAttributes = {
  'data-list-class': 'c-tabs__nav-list--swipe',
  'data-list-item-class': 'c-tabs__nav-item',
  'data-active-class': 'is-active',
  'data-js-swipe': true
}

export const TabsNavTemplate = (d) => {
  d = { ...defaultTabsNavData, ...d }

  return `<div class="c-tabs__nav ${getTokenClass('variant', d.variant || 'default', tokens)} ${d.extraClasses}" ${encodeAttributes(d.attributes)} >
  <div class="c-tabs__nav-list-wrapper" ${d.swipeable ? encodeAttributes(swipeAttributes) : ''} >
    <ul class="c-tabs__nav-list ${d.swipeable ? 'c-tabs__nav-list--swipe' : ''} o-list-bare o-list-inline">
      ${d.items.map(item => !item.featured ? TabsNavItemTemplate({ ...item, componentId: d.id }) : '').join('')}
    </ul>
    ${d.swipeable ? SwipeTemplate() : ''}
  </div>
  
  ${d.items.find(item => item.featured)
    ? `<ul class="c-tabs__nav-list c-tabs__nav-list--featured o-list-bare o-list-inline">
      ${d.items.map(item => item.featured ? TabsNavItemTemplate({ ...item, componentId: d.id, anchorable: d.anchorable }) : '').join('')}
    </ul>`
  : ''}
</div>`
}

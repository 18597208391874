/**
 * The SelfDriveSelectorApiData contains all data as received from API
 *
 * @typedef {Object}          SelfDriveSelectorApiData
 *
 * @property {String}         totalPrice
 * @property {String}         averagePrice
 * @property {Object}         priceBreakdown
 * @property {Object[]}       detailedTripSummary
 */

/**
 * The SelfDriveSelectorData contains all data organized to be easily displayed in a table
 *
 * @typedef {SelfDriveSelectorApiData}          SelfDriveSelectorData
 *
 * @property {String}         id
 */

import { parseFormattedPrice } from '../../components/price/c-price.template'

const defaults = {
  totalPrice: '',
  averagePrice: '',
  priceBreakdown: {},
  detailedTripSummary: []
}

/**
 * A SelfDriveSelectorData model
 */
export default class SelfDriveSelectorDataModel {
  /**
   * Creates a new SelfDriveSelectorDataModel
   *
   * @constructor
   * @param {SelfDriveSelectorData|{}}        [attributes={}]   - The model attributes
   * @param {SelectedStateTemplateData}       [defaultData]     - Extra data (default texts and others)
   */
  constructor (attributes = {}, defaultData = {}) {
    this.id = attributes.id
    this._setSelectorData({
      ...defaults,
      ...attributes
    })
    this.defaultData = defaultData
  }

  /**
   * Sets a self drive selector model
   * @param {SelfDriveSelectorApiData} selectorApiData selector Api Data
   *
   * @returns {SelfDriveSelectorDataModel} Self instance.
   */
  _setSelectorData (selectorApiData) {
    this.selectorData = {
      ...selectorApiData,
      ...this.defaultData,
      routeTotalPrice: {
        raw: parseFormattedPrice(selectorApiData.totalPrice).value,
        formatted: selectorApiData.totalPrice
      },
      routePricePerPerson: {
        raw: parseFormattedPrice(selectorApiData.averagePrice).value,
        formatted: selectorApiData.averagePrice
      }
    }

    return this
  }

  /**
   * Gets the self-drive selector data
   *
   * @returns {SelectedStateApiDataModel} Self instance.
   */
  getSelectorData () {
    return this.selectorData
  }
}

import { arrayOf, bool, number, objectOf, oneOfType, shape, string } from 'prop-types'

export const propTypes = {

  id: string.isRequired,

  roomWord: string.isRequired,

  roomNumber: number.isRequired,

  roomName: string.isRequired,

  roomDescription: string,

  price: shape({
    value: string,
    currencyPosition: string,
    currency: string,
    legend: string,
    priceSuffix: string
  }).isRequired,

  infoMessage: string,

  errorMessage: string,

  stockWarningMessage: string,

  onRequest: bool,

  onRequestLabel: string,

  removable: bool,

  isFlexibleAllocation: bool,

  characteristicsContentLayout: string,

  occupancySelectors: arrayOf(
    shape({
      id: string,
      current: number,
      min: number,
      max: number,
      name: string,
      description: string
    })
  ),

  /** Extra class names to be appended on root element */
  extraClasses: string,

  /** Extra attributes to be appended on root element */
  attributes: objectOf(
    oneOfType([
      bool,
      string
    ])
  )
}

export const defaultProps = {
  id: '',
  roomWord: '',
  roomNumber: 0,
  roomName: '',
  price: {
    value: '',
    currencyPosition: '',
    currency: '',
    legend: ''
  },
  removable: false,
  isFlexibleAllocation: false,
  characteristicsContentLayout: 'default'
}

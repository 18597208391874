import { DropdownTemplate, defaultDropdownData } from '../../components/dropdown/c-dropdown.template'
import { TitleTemplate, defaultTitleData } from '../../components/title/c-title.template'
import { BookingLuggageMessageTemplate } from './w-booking-luggage__info-message.template'
import { BookingLuggageOneClickTemplate } from './w-booking-luggage__one-click.template'
import { BookingLuggagePriceTemplate } from './w-booking-luggage__price.template'
import { ChoiceListTemplate, defaultChoiceListData } from '../../components/choice-list/c-choice-list.template'
import { classNames } from './config'
import { SwitchTemplate } from '../../components/switch/c-switch.template'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/w-booking-luggage__tokens.json')['w-booking-luggage']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/widgets/booking-luggage/data/w-booking-luggage__tokens.json`)['w-booking-luggage'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }
/**
 * The BookingChoiceListData contains all data needed to hydrate a BookingChoiceList view
 *
 * @typedef {Object}                  BookingLuggageData
 *
 * @property {RoomData[]}             rooms                               - The list of rooms to be shown
 * @property {String}                 RoomData.roomName                   - The name of the room
 * @property {ParticipantsData[]}     RoomData.participants               - Contains the list of participants in the room
 * @property {ChoiceListData}         ParticipantsData.choiceListInfo     - Contains all the options to be shown within the choice list component
 *
 */

export const defaultBookingLuggageData = {
  title: {
    size: 'tiny',
    tag: 'h4'
  },
  specialLuggage: {
    title: {
      size: 'small',
      extraClasses: 'w-booking-luggage__title'
    },
    choiceList: {
      method: 'multiple',
      attributes: { 'data-w-booking-luggage__special-luggage': '' }
    }
  },
  dropdownConfig: {
    placeholder: '',
    showDefaultOption: true,
    state: '',
    disabled: false,
    highlighted: false,
    hiddenLabel: true,
    hasClearButton: false,
    icon: false,
    clearButtonAttributes: {
      'data-c-dropdown__clear': ''
    }
  },
  attributes: {
    'data-w-booking-luggage__options': ''
  },
  extraClasses: {
    dropdown: 'w-booking-luggage__option-input w-booking-luggage__message-wide'
  }
}
export const BookingLuggageTemplate = (d) => {
  d = { ...defaultBookingLuggageData, ...d }
  return `<div class="w-booking-luggage__container">
    ${(d.oneClickLuggage && d.oneClickLuggage.isOneClickLuggageReady)
       ? `<div class="${!(d.oneClickLuggage && d.oneClickLuggage.isOneClickLuggageReady)
       ? classNames.hidden
: ''}" data-w-booking-luggage__one-click--view>
        ${BookingLuggageOneClickTemplate(d)}
      </div>
    `
: ''}
    <div class="${(d.oneClickLuggage && d.oneClickLuggage.isOneClickLuggageReady && d.oneClickLuggage.sameOptionSelectedForAllParticipants)
       ? classNames.hidden
: ''}" data-w-booking-luggage__select-per-participant--view>
      ${d.luggageInfoMessage ? BookingLuggageMessageTemplate({ ...d.luggageInfoMessage, ...{ extraClasses: 'cs-state-info cs-state-info--light' } }) : ''}
      ${d.rooms.map(room => `
        ${room.roomName
          ? `<div class="w-booking-luggage__title">
        ${TitleTemplate(Object.assign({}, defaultTitleData, { size: d.title.size, tag: d.title.tag, text: room.roomName }))}
        </div>`
      : ''}
        ${room.participants.map(participant => ParticipantRowTemplate(participant, d.configurations)).join('')}
        <div data-warning-section>
        ${room.warning ? BookingLuggageMessageTemplate({ ...room.warning, ...{ description: room.warning.message, extraClasses: 'cs-state-warning cs-state-warning--light' } }) : ''}
        </div>
      `).join('')}
    </div>
    
    ${d.specialLuggage
      ? `<div>
          ${TitleTemplate(
            {
 ...defaultTitleData,
              ...defaultBookingLuggageData.specialLuggage.title,
              ...{
 text: d.specialLuggage.title,
                   size: getTokenClass('special-luggage__title-size', 'default', tokens)
              }
            }
          )}
          ${d.specialLuggage.showSpecialLuggageSwitch
            ? `${SwitchTemplate(Object.assign({}, d?.specialLuggage?.switchInfo))}
               ${d?.specialLuggage?.extraTextSelected
? `
                <div class="w-booking-luggage__special-luggage-extra-text-switch m-body m-body--medium ${d?.specialLuggage?.switchInfo?.checked ? '' : classNames.hidden}" data-w-booking-luggage__special-luggage-extra-text-switch>
                  ${d?.specialLuggage?.extraTextSelected}
                </div>`
: ''}`
            : `${d.specialLuggage.showSpecialLuggageCheckbox
              ? `${ChoiceListTemplate({ ...defaultChoiceListData, ...defaultBookingLuggageData.specialLuggage.choiceList, ...d.specialLuggage.choiceListInfo })}`
              : `<span>${d.specialLuggage.specialLuggageTextCheckboxIsHidden}</span>`}
            `
          }
        </div>`
      : ''}
    </div>
`
}

export const ParticipantRowTemplate = (participant, configurations) => {
  const shouldPrintRow = participant.luggageInfo.inbound || participant.luggageInfo.outbound
  return `${shouldPrintRow
    ? `<div class="w-booking-luggage__options-header">
        ${TitleTemplate(
          Object.assign({}, defaultTitleData, {
            size: defaultBookingLuggageData.title.size,
            text: participant.luggageInfo.participantName,
            extraClasses: 'w-booking-luggage__participant-name',
            attributes: {
              'data-w-booking-luggage__participant-label': ''
            }
          }))}
      </div>
      <div class="w-booking-luggage__options">
        ${participant.luggageInfo.outbound ? DropdownTemplate({ ...defaultDropdownData, ...participant.luggageInfo.outbound, ...{ options: [], extraClasses: defaultBookingLuggageData.extraClasses.dropdown, attributes: defaultBookingLuggageData.attributes } }) : ''}
        ${participant.luggageInfo.inbound ? DropdownTemplate({ ...defaultDropdownData, ...participant.luggageInfo.inbound, ...{ options: [], extraClasses: defaultBookingLuggageData.extraClasses.dropdown, attributes: defaultBookingLuggageData.attributes } }) : ''}
        <div class="w-booking-luggage__options-price cs-state-info cs-state-info--light" data-w-booking-luggage__options-price>
        ${configurations && configurations.price ? BookingLuggagePriceTemplate(participant.luggageInfo.totalPrice, configurations.price) : participant.luggageInfo.totalPrice}
        </div>
      </div>`
    : ''}`
}

import { arrayifyObject } from '../../../js/helpers/arrayify-object'
import { getUrlFromString } from '../../../js/document/url'
import { fetchJsonData } from '../../../js/helpers/json-fetch'
import { capitalizeString } from '../../../js/helpers/string'
import { CORRECT_FILTER_NAMES } from './config'
import { FILTER_TYPES } from '../../../js/data/filters/config'

/**
 * ---------------
 * ApiFilters
 * ---------------
 *
 * It's main purpose is to make the request to the API and transform data if needed.
 *
 */
export default class ApiFilters {
  /**
   * Creates a new ApiFilters
   *
   * @constructor
   *
   * @param {Object} options
   * @param {String} options.url - Filters API base URL
   */
  constructor (options = {}) {
    this.options = options
  }

  /**
   * Fetches data from API and apply data processing
   *
   * @param {Object} params
   *
   * @returns {Promise}
   */
  async fetch (params) {
    try {
      const requestUrl = getUrlFromString(this.options.url, arrayifyObject(params))
      const serverResponseData = await fetchJsonData(requestUrl, { fullReferrerOnCrossOrigin: true })
      if (!serverResponseData.months && !serverResponseData.Month) {
        return {
          filters: [],
          errors: [{
            code: '500',
            message: 'Missing Month filter, unavailable combination'
          }]
        }
      }
      return ApiFilters.postProcessFiltersData(serverResponseData)
    } catch (e) {
      return {
        filters: [],
        errors: [{
          code: '404',
          message: 'Could not retrieve data for any reason'
        }, {
          code: '404',
          message: e.message || e
        }]
      }
    }
  }

  static postProcessFiltersData (data) {
    const keysWithValues = Object.keys(data).filter(key => Array.isArray(data[key]) && data[key].length > 0)
    const hasErrors = keysWithValues.length === 1 && !!data.participants

    return hasErrors
      ? {
          filters: ApiFilters.getFiltersFromData(data),
          errors: [{
            code: '404',
            message: 'No availability'
          }]
        }
      : {
          filters: ApiFilters.getFiltersFromData(data),
          errors: []
        }
  }

  static getFiltersFromData (data) {
    const dataAsFilters = Object.entries(data).reduce((newData, [key, val]) => {
      key = CORRECT_FILTER_NAMES[key] || capitalizeString(key)
      newData.push({
        type: key,
        isMultiselectable: [FILTER_TYPES.PARTICIPANTS, FILTER_TYPES.DEPARTURE_AIRPORT, FILTER_TYPES.ARRIVAL_AIRPORT, FILTER_TYPES.ROOM_TYPE, FILTER_TYPES.DURATION].includes(key),
        parentFilterType: 'Unknown',
        values: val.map(item => {
          return {
            isSelected: item.selected,
            isAvailable: true,
            caption: item.text || item.value,
            value: item.value,
            count: item.count,
            metadata: (item.extra || item.groupInfo)
              ? Object.entries(item.extra || {}).reduce((acc, [key, val]) => {
                const fixedKey = key === 'room' ? 'Room' : key
                acc.push(`${fixedKey}:${val}`)
                return acc
              }, item.groupInfo ? [`Group:${item.groupInfo}`] : []).join(' || ')
              : undefined,
            groupInfo: item.groupInfo || undefined
          }
        })
      })
      return newData
    }, [])

    try {
      // ⚠️ We observed sometimes no month is selected, with no explanation yet
      // Here we force to select the first one if available.
      const monthFilter = dataAsFilters.find(filter => filter.type === FILTER_TYPES.MONTH)
      const selectedMonth = monthFilter.values.find(value => value.isSelected)
      if (!selectedMonth) {
        monthFilter.values[0].isSelected = true
      }
    } catch (e) {}

    return dataAsFilters
  }
}

/**
 * A mapper to convert from "Sitecore Api response" to "internal widget model".
 */
import { formatDate } from '../../../js/helpers/dates'

export default class BookingParticipantsBadgeDataMapper {
  map (api, componentId, texts, settings) {
    const emailValues = api.participants ? api.participants.map(participant => participant.email).filter(e => e != null) : []
    const roomsAvailable = api.rooms && api.rooms.length > 0 ? api.rooms : [{ name: '', participantIds: [], id: null }]
    const widgetService = {
      collapseId: componentId,
      componentId,
      configuration: {
        showEmailSection: settings.showEmailSection,
        collapseNamesSection: settings.collapseNamesSection || false,
        showDateSelectorFields: settings.showDateSelectorFields || false
      },
      successEmail: emailValues[0],
      mainBookerEmail: {
        id: `${componentId}-email_1`,
        type: 'email',
        required: settings.isEmailRequired,
        label: texts.emailLabel,
        placeholder: texts.emailPlaceholder,
        value: emailValues[0] ? emailValues[0] : '',
        elementAttributes: {
          pattern: texts.emailPattern,
          'data-message-required': texts.emailWarningRequired,
          'data-message-pattern': texts.emailWarningPattern
        },
        infoText: texts.emailMessageInfo,
        successText: texts.emailMessageSuccess,
        saveButtonText: texts.emailSaveButton
      },
      setParticipantsSectionLabel: texts.participantsLabel,
      cookie: {
        enabled: true,
        encryptEnabled: true,
        encryptKey: '381570c3-af0e-4de7-be73-ff6a0ef3d242',
        encryptIV: 'ParticipantsBadgeComponent'
      },
      saveButtonText: texts.participantsSaveButton,
      cancelButtonText: texts.participantsCancelButton,
      collapseButton: {
        type: 'button',
        block: false,
        disabled: false,
        icon: 'chevron-down',
        iconPosition: 'left',
        jsApi: false,
        loading: false,
        variant: 'icon-only',
        text: '',
        link: {}
      },
      rooms: roomsAvailable.map(room => this._mapRoom(room, api.participants, texts, settings))
    }
    return widgetService
  }

  _mapRoom (apiRoom, participants, texts, settings) {
    const filteredParticipants = (apiRoom.participantIds && apiRoom.participantIds.length > 0) ? participants.filter(participant => apiRoom.participantIds.includes(participant.id)) : participants
    const widgetRoom = {
      name: apiRoom.name,
      participants: filteredParticipants.map(participant => this._mapParticipant(participant, texts, settings))
    }
    return widgetRoom
  }

  _mapParticipant (apiParticipant, texts, settings) {
    const participantFieldTypes = this._mapParticipantFieldTypes(apiParticipant, settings)

    const widgetParticipant = {
      fields: participantFieldTypes.map(type => { return this._buildParticipantField(type, apiParticipant, texts, settings) })
    }
    return widgetParticipant
  }

  _mapParticipantFieldTypes (apiParticipant, settings) {
    const defaultFields = ['name']

    let showDateSelectorFields = apiParticipant && apiParticipant.ageCategory && apiParticipant.ageCategory !== 'Adult' && settings.showDateSelectorFields
    if (apiParticipant && apiParticipant.ageCategory && apiParticipant.ageCategory === 'Adult' && settings.showDateSelectorFieldsForAdults && settings.showDateSelectorFields) {
      showDateSelectorFields = true
    }

    if (showDateSelectorFields) {
      defaultFields.push('birthdate')
    }
    return defaultFields
  }

  _buildParticipantField (type, apiParticipant, texts, settings) {
    let component = null
    const id = `${type}_${apiParticipant.id}`

    switch (type) {
      case 'name': {
        component = this._buildTextField({ id, apiParticipant, texts })
        break
      }
      case 'birthdate': {
        component = this._buildDateSelector({ id, apiParticipant, type, settings })
        break
      }
      default:
        // Nothing to do here
        break
    }

    return component
  }

  _buildTextField (data) {
    const { id, apiParticipant, texts } = data

    const value = apiParticipant.firstName || apiParticipant.name || ''
    const label = apiParticipant.alternativeName
    const elementAttributes = {
      pattern: texts.participantsNamePattern,
      'data-message-pattern': texts.participantsNamePatternWarning
    }
    const participantId = apiParticipant.id
    const attributes = {
      'data-participant-id': participantId,
      'data-w-booking-participants-badge--name-input': ''
    }

    const textField = {
      type: 'c-textbox',
      data: {
        ...{
          value,
          participantId,
          type: 'text',
          placeholder: texts.participantsNamePlaceholder,
          elementAttributes
        },
        ...this._mapCommonData({ id, label, required: false, attributes })
      }
    }

    return textField
  }

  _buildDateSelector (data) {
    const { id, apiParticipant, type, settings } = data

    const value = apiParticipant[type] || ''
    const attributes = {
      'data-participant-id': apiParticipant.id,
      'data-w-booking-participants-badge--birthdate-input': ''
    }
    const fieldSettings = settings.dateSelectorsFieldSettings

    const participantAgeCategory = apiParticipant.ageCategory
    const dateRangeAgeCategory = settings.ageCategoryRestrictions && settings.ageCategoryRestrictions.find(restriction => restriction.type === participantAgeCategory)

    const dateSelectorData = {
      ...{
        date: value,
        mainLabel: fieldSettings?.label || '',
        dayPlaceholder: fieldSettings?.placeholders && fieldSettings?.placeholders.day ? fieldSettings?.placeholders.day : '',
        monthPlaceholder: fieldSettings?.placeholders && fieldSettings?.placeholders.month ? fieldSettings?.placeholders.month : '',
        yearPlaceholder: fieldSettings?.placeholders && fieldSettings?.placeholders.year ? fieldSettings?.placeholders.year : '',
        minDate: dateRangeAgeCategory?.dateRange ? formatDate(dateRangeAgeCategory?.dateRange.startDate) : null,
        maxDate: dateRangeAgeCategory?.dateRange ? formatDate(dateRangeAgeCategory?.dateRange.endDate) : null,
        messageInvalid: fieldSettings?.invalidErrorMessage,
        messageOutOfBounds: fieldSettings?.invalidErrorMessage,
        blockModifier: true
      },
      ...this._mapCommonData({ id, required: fieldSettings?.isMandatory, messageRequired: fieldSettings?.emptyErrorMessage, attributes })
    }
    const dateInput = {
      type: 'c-date-selector',
      size: fieldSettings?.size ? fieldSettings?.size.toLowerCase() : 'medium',
      data: dateSelectorData
    }
    return dateInput
  }

  _mapCommonData (data) {
    return {
      id: data.id,
      disabled: false,
      label: data.label,
      attributes: data.attributes,
      required: data.required,
      messageRequired: data.messageRequired,
      extraClasses: 'c-form__item c-form__item--medium w-booking-participants-badge__participant-field'
    }
  }
}

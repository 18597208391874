import BookingItem from '../booking-item/main'
import { bookedServicesListEvents, bookedServiceEditEvents } from '../../../js/document/event-types'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import Component from '../../../js/core/component/component'
import BookedServicesListDataMapper from './data-mapper'
import { getSearchParamsFromUrl, buildUrlWithParams } from '../../../js/document/url'
import { removeUndefinedKeys } from '../../../js/helpers/object'
import { arrayifyObject } from '../../../js/helpers/arrayify-object'
import { EXCLUDE_WHITELISTED_PARAMS } from '../../../js/helpers/white-listed-params'
import { smooth } from '../../../js/document/scroll'
import * as utilsHelper from '../../../js/utils'
import domEventsHelper from '../../../js/document/dom-events-helper'

const widgetApi = 'w-booked-services-list'

const classNames = {
  opened: 'in',
  hidden: 'is-hidden',
  selected: 'is-selected',
  serviceBooked: `${widgetApi}-service-booked`,
  serviceNotBooked: `${widgetApi}-service-not-booked`,
  itemIconRight: `${widgetApi}__item-icon-right`,
  icons: {
    chevronRight: 'm-icon--chevron-right',
    checkmarkCircle: 'm-icon--checkmark-circle',
    plus: 'm-icon--plus'
  }
}

const widgetQueries = {
  sideDrawer: `[data-${widgetApi}__c-side-drawer]`,
  sideDrawerApi: 'c-side-drawer',
  sideDrawerTrigger: `[data-${widgetApi}__side-drawer-trigger]`,
  sideDrawerTriggerText: `[data-${widgetApi}__side-drawer-trigger-text]`,
  sideDrawerBody: '.c-side-drawer__body',
  sideDrawerBodyContent: '.c-side-drawer__body-content',
  sideDrawerHeader: `.${widgetApi}__side-drawer--header`,
  servicesContainer: `[data-${widgetApi}__services-elements]`,
  servicesList: `[data-${widgetApi}__services]`,
  servicesListItem: '[data-c-actionable-list__item]',
  actionableListApi: 'c-actionable-list',
  noServiceSelected: `[data-${widgetApi}__no-service-selected]`,
  bookingItemHeader: '.w-booking-item.service-is-selected .w-booking-box__heading',
  bookedServiceEdit: '.w-booked-service-edit',
  serviceListItemIcon: '.c-actionable-list__item-extended__icon',
  serviceListItemText: '.c-actionable-list__item-extended__details'
}

const attr = {
  actionableItemServiceType: 'data-c-actionable-list__item_service-type',
  actionableItemId: `data-${widgetQueries.actionableListApi}__item_id`
}

const settings = {
  resizeDelay: 200,
  safeMargin: 34
}
/**
 * BookedServiceBasket widget
 */
export default class BookedServicesList extends BookingItem {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   */
  constructor (element) {
    super(element)
    this.element = element
    this.dataMapper = new BookedServicesListDataMapper()
    this.sideDrawerTrigger = document.querySelector(widgetQueries.sideDrawerTrigger)
    this.servicesContainer = document.querySelector(widgetQueries.servicesContainer)
    this.sideDrawerElement = document.querySelector(widgetQueries.sideDrawer)
    this.sideDrawerApi = this.sideDrawerElement[widgetQueries.sideDrawerApi]
    this.noServiceSelected = this.element.querySelector(widgetQueries.noServiceSelected)
    this.bookedServiceEdit = document.querySelector(widgetQueries.bookedServiceEdit)
    this.availableServicesList = {}
    this.currentParams = { ...this.getParamsFromURL() }
    this._buildEvents()
    this._attachEvents()
  }

  /**
   *
   * @param {Object} data - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.availableServicesList = this.dataMapper.mapServiceBasketInfo(data.response)
    this._updateWidget(this.availableServicesList)
    super.showComponent()
    return super.handleFetched(data.response)
  }

  _attachEvents () {
    this._attachSideDrawer()
  }

  _attachSideDrawer () {
    if (this.sideDrawerElement != null) {
      if (this.sideDrawerApi && this.sideDrawerTrigger) {
        this.sideDrawerApi.events.on('open', () => {
          this._scrollDocumentToSideDrawerTrigger()
          this._setPositionFixed()
          this.sideDrawerTrigger.classList.add(classNames.opened)
        })

        this.sideDrawerApi.events.on('close', () => {
          this._removePositionFixed()
          this.sideDrawerTrigger.classList.remove(classNames.opened)
        })
      }
    }
  }

  _scrollDocumentToSideDrawerTrigger (scrollable = true) {
    if (scrollable) {
      const documentScrollingElement = document.scrollingElement || document.documentElement
      const triggerBoundingRect = this.sideDrawerTrigger.getBoundingClientRect()
      if (documentScrollingElement.scrollTop < triggerBoundingRect.y) {
        smooth(documentScrollingElement, documentScrollingElement.scrollTop, triggerBoundingRect.y)
      }
    }
    return this
  }

  _updateWidget (services) {
    this.servicesList = this.element.querySelector(widgetQueries.servicesList)
    this.serviceListItems = [...this.servicesList.querySelectorAll(widgetQueries.servicesListItem)]
    this._reorderHtmlItems(services)
    this._hideMissingServiceItems(services)
    this._calculateSideDrawerHeight()
    Component.initDocumentComponentsFromAPI(this.servicesContainer)
    this._bindServicesEvents()
  }

  _bindServicesEvents () {
    this.servicesListApi = this.servicesList[widgetQueries.actionableListApi]
    this.servicesListApi.events.on('selectItem', this._selectServiceItem.bind(this))

    if (this.bookedServiceEdit) {
      this.bookedServiceEdit['w-booked-service-edit'].events.on(bookedServiceEditEvents.SAVE_CHANGES_PROCESS_FAILED, (itemId) => this._selectServiceItem(itemId), this)
    }
  }

  _selectServiceItem (ev) {
    this._setSelectedService(ev)
    this._setSelectedServiceToTrigger(ev)
    this.updateBrowserUrl()
    this._closeSideDrawer()
    this.events.emit(bookedServicesListEvents.BOOKED_SERVICES_LIST_SERVICE_SELECTED, ev)
  }

  _setSelectedServiceToTrigger (itemId) {
    const itemToAddToTrigger = this.serviceListItems.find(item => item.getAttribute(attr.actionableItemId) === itemId)

    if (itemToAddToTrigger) {
      const sideDrawerText = this.sideDrawerTrigger ? this.sideDrawerTrigger.querySelector(widgetQueries.sideDrawerTriggerText) : null
      if (sideDrawerText) {
        const icon = itemToAddToTrigger.querySelector(widgetQueries.serviceListItemIcon)
        const text = itemToAddToTrigger.querySelector(widgetQueries.serviceListItemText)
        const iconClone = icon ? icon.cloneNode(true) : ''
        const textClone = text ? text.cloneNode(true) : ''
        sideDrawerText.innerHTML = ''
        sideDrawerText.appendChild(iconClone)
        sideDrawerText.appendChild(textClone)
      }
    }
  }

  _setSelectedService (itemId) {
    this.serviceListItems.forEach(item => {
      let setSelected = false
      if (item.getAttribute(attr.actionableItemId) === itemId) {
        setSelected = true
        this.currentParams.serviceType = item.getAttribute(attr.actionableItemServiceType)
      }
      this._setAccordionItemSelectedClass(item, setSelected)
      this._setAccordionItemSelectedIcon(item, setSelected)
    })
    this._toggleNoServiceSelectedVisibility(true)
  }

  _setAccordionItemSelectedClass (item, setSelectedClass = false) {
    item.classList.toggle(classNames.selected, setSelectedClass)
  }

  _setAccordionItemSelectedIcon (item, setSelectedIcon = false) {
    let className = ''
    const iconRight = item.querySelector(`.${classNames.itemIconRight}`)
    if (iconRight) {
      if (setSelectedIcon) {
        className = classNames.icons.chevronRight
        if (iconRight.classList.contains(classNames.icons.checkmarkCircle)) {
          iconRight.classList.replace(classNames.icons.checkmarkCircle, className)
        } else if (iconRight.classList.contains(classNames.icons.plus)) {
          iconRight.classList.replace(classNames.icons.plus, className)
        }
      } else {
        className = item.classList.contains(classNames.serviceBooked) ? classNames.icons.checkmarkCircle : classNames.icons.plus
        if (iconRight.classList.contains(classNames.icons.chevronRight)) {
          iconRight.classList.replace(classNames.icons.chevronRight, className)
        }
      }
    }
  }

  _openSideDrawer () {
    this.sideDrawerApi.open()
  }

  _closeSideDrawer () {
    if (this.sideDrawerApi.getProp('open')) {
      this.sideDrawerApi.close()
    }
  }

  _setPositionFixed () {
    if (this.sideDrawerTrigger) {
      this.sideDrawerTrigger.style.position = 'fixed'
      this.sideDrawerTrigger.style.zIndex = '501'
    }
  }

  _removePositionFixed () {
    if (this.sideDrawerTrigger) {
      this.sideDrawerTrigger.style.removeProperty('position')
      this.sideDrawerTrigger.style.removeProperty('zIndex')
    }
  }

  _selectServiceFirstTime () {
    if (this.currentParams && this.currentParams.serviceType) {
      const currentServiceType = this.currentParams.serviceType.toLocaleUpperCase()
      let selectedServiceId = ''
      this.serviceListItems.forEach(item => {
        if (item.getAttribute(attr.actionableItemServiceType).toLocaleUpperCase() === currentServiceType) {
          selectedServiceId = item.getAttribute(attr.actionableItemId)
        }
      })
      if (this.availableServicesList?.orderedServices?.length > 0 && this.availableServicesList?.orderedServices.find(service => service.componentId === selectedServiceId)) {
        this._selectServiceItem(selectedServiceId)
      } else {
        this._serviceSelectedNotAvailable(currentServiceType)
      }
    } else {
      this._noServiceSelected()
    }
  }

  _serviceSelectedNotAvailable (currentServiceType) {
    this._noServiceSelected()
    const eventArgs = {
      serviceType: currentServiceType
    }
    this.events.emit(bookedServicesListEvents.BOOKED_SERVICES_LIST_SERVICE_NOT_AVAILABLE, eventArgs)
  }

  _noServiceSelected () {
    if (this.sideDrawerHeader) {
      this.sideDrawerHeader.classList.remove(classNames.hidden)
    }
    this._openSideDrawer()
    this._noServiceSelectedPosition()
    this.events.emit(bookedServicesListEvents.BOOKED_SERVICES_LIST_SERVICE_NOT_SELECTED)
  }

  _noServiceSelectedPosition () {
    if (this.noServiceSelected && this.servicesList) {
      const notServicesSelected = this.servicesList.querySelectorAll(`.${classNames.serviceNotBooked}`)
      if (notServicesSelected.length > 0) {
        const itemRect = notServicesSelected[0].getBoundingClientRect()
        const noServiceSelectedHeight = this.noServiceSelected.getBoundingClientRect().height
        const positionTop = ((notServicesSelected.length * (itemRect.height + 1)) - noServiceSelectedHeight) / 2
        const positionLeft = itemRect.width + settings.safeMargin
        this.noServiceSelected.style.top = `${positionTop}px`
        this.noServiceSelected.style.left = `${positionLeft}px`
        this._toggleNoServiceSelectedVisibility()
      } else {
        this._toggleNoServiceSelectedVisibility(true)
      }
    }
  }

  _toggleNoServiceSelectedVisibility (isHidden = false) {
    if (this.noServiceSelected) {
      this.noServiceSelected.classList.toggle(classNames.hidden, isHidden)
    }
  }

  /**
   * Returns the params from URL params, if there's any
   * - Get the relevant params from the URL
   *
   * @returns {Object}
   */
  getParamsFromURL () {
    if (!document.location.search) return {}
    const relevantUrlParams = EXCLUDE_WHITELISTED_PARAMS(
      removeUndefinedKeys(getSearchParamsFromUrl(document.location, { useUndefinedValue: true }))
    )

    if (!relevantUrlParams || Object.keys(relevantUrlParams).length === 0) return {}

    return relevantUrlParams
  }

  /**
   * Update Browser's url with all the params excepting the hidden ones
   * - Should happen after each update
   * - The main purpose is to enable copy/share the URL
   *
   * @returns {BookedServicesList} self instance
   */
  updateBrowserUrl () {
    this.currentUrlParams = { ...this.getParamsFromURL() }

    window.history.replaceState(
      '',
      '',
      buildUrlWithParams(
        document.URL.split('?')[0],
        arrayifyObject({
          ...this.currentUrlParams,
          ...this.currentParams
        })
      ))
    return this
  }

  _reorderHtmlItems (services) {
    if (services.orderedServices) {
      services.orderedServices.forEach(service => {
        let itemToAdd = this.serviceListItems.find(item => item.getAttribute(attr.actionableItemId) === service.componentId)

        if (itemToAdd) {
          const iconClass = service.serviceSelected ? classNames.icons.checkmarkCircle : classNames.icons.plus
          service.serviceSelected ? itemToAdd.classList.add(classNames.serviceBooked) : itemToAdd.classList.add(classNames.serviceNotBooked)
          itemToAdd = this._addIconRightToItem(itemToAdd, iconClass)
          this.servicesList.appendChild(itemToAdd)
        }
      })
    }
  }

  _addIconRightToItem (item, iconClass = '') {
    if (item) {
      const iconRight = item.querySelector(`.${classNames.itemIconRight}`)
      if (!iconRight) {
        item.querySelector('button').innerHTML += `<span class="${classNames.itemIconRight} m-icon ${iconClass}"></span>`
      }
    }
    return item
  }

  _hideMissingServiceItems (services) {
    if (services.orderedServices) {
      const missingServiceItems = this.serviceListItems.filter(item => !services.orderedServices.map(service => service.componentId).includes(item.getAttribute(attr.actionableItemId)))
      missingServiceItems.forEach(item => {
        item.classList.add(classNames.hidden)
      })
    }
  }

  _calculateSideDrawerHeight () {
    if (this.sideDrawerElement) {
      const sideDrawerBodyElement = this.sideDrawerElement.querySelector(widgetQueries.sideDrawerBody)
      const sideDrawerBodyContentElementRect = this.sideDrawerElement.querySelector(widgetQueries.sideDrawerBodyContent).getBoundingClientRect()
      const sideDrawerHeaderElementRect = this.sideDrawerElement.querySelector(widgetQueries.sideDrawerHeader).getBoundingClientRect()

      sideDrawerBodyElement.style.height = `${sideDrawerBodyContentElementRect.height}px`
      this.sideDrawerElement.style.height = `${sideDrawerBodyContentElementRect.height + sideDrawerHeaderElementRect.height + settings.safeMargin}px`
    }
  }

  _buildEvents () {
    this._events = [[window, {
      resize: utilsHelper.debounce(() => {
        this._calculateSideDrawerHeight()
      }, settings.resizeDelay)
    }]]
    domEventsHelper.attachEvents(this._events, widgetApi)
    return this
  }

  handleAllRendered () {
    this._selectServiceFirstTime()
  }
}

registerWidget(BookedServicesList, widgetApi)

/**
 *  TODO: Upgade configuration with NumberOfDecimals feature?
 */
export default class PriceFormatter {
  /**
     *  Formats a price with the given configuration
     * @param {decimal}          price                     -- price to be formatted
     * @param {object}          config                    -- price configurations (isCurrencyAtStart and currencySymbol)
     * @param {int}             config.isCurrencyAtStart  -- true if currency must be before the number, otherwise false
     * @param {int}             config.currencySymbol     -- the symbol to be used for the currency
     * @param {int}             config.numberOfDecimals   -- amount of decimals
     * @param {string}          config.decimalSeparator   -- char used to separate integer and decimal part (dot by default)
     *
     * @returns {String}                         -- the formatted price
     */
  static toFormattedText (price, config) {
    let priceFormatted = price
    if (config) {
      priceFormatted = config.numberOfDecimals || config.numberOfDecimals === 0 ? roundOff(price, config.numberOfDecimals) : price.toString()
      priceFormatted = config.decimalSeparator ? priceFormatted.replace('.', config.decimalSeparator) : priceFormatted
      priceFormatted = price || price === 0
        ? `${config && config.isCurrencyAtStart && config.currencySymbol ? config.currencySymbol : ''}${priceFormatted}${config && !config.isCurrencyAtStart && config.currencySymbol ? config.currencySymbol : ''}${config && config.suffix ? config.suffix : ''}`
        : ''
    }
    return priceFormatted
  }
}

export const roundOff = (value, round) => {
  const priceFormatted = (parseInt(value * (10 ** (round + 1))) - parseInt(value * (10 ** round)) * 10) > 0 ? (((parseFloat(parseInt((value + parseFloat(1 / (10 ** round))) * (10 ** round))))) / (10 ** round)) : (Number(value).toFixed(round))
  return priceFormatted.toLocaleString(undefined, { minimumFractionDigits: round, maximumFractionDigits: round })
}

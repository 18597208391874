import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import { BookingVouchersList } from './w-booking-vouchers-list.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import Voucher from '../voucher/main'
import { apiCaller } from '../../../js/helpers/api-caller'
import { getParamsFromURLForBookingSteps } from '../../../js/document/url'

const widgetApi = 'w-booking-vouchers'

const attr = {
  url: 'url',
  expirationText: 'expirationText'
}

const widgetQueries = {
  voucher: `[data-${widgetApi}__voucher]`
}

export default class BookingVouchers extends BookingBox {
  constructor (element, options = {}) {
    super(element)
    this.locales = this.getLocales()
    this.currentParams = { ...getParamsFromURLForBookingSteps() }
  }

  isDeferredAndResolvedByItself () {
    return true
  }

  async handleFetched () {
    await this._updateWidgetData()
    return super.handleFetched()
  }

  async _updateWidgetData () {
    const result = await apiCaller(this._enrichUrlWithDefaultQueryString(this.element.dataset[attr.url]))

    if (result.success) {
      this.apiData = result.response
      if (this.apiData && this.apiData.vouchers) {
        this.vouchers = this.apiData.vouchers.filter(voucher => voucher.canBeUsed && voucher.isActive) || []
        const html = BookingVouchersList({
          ...this.locales,
          vouchers: this.vouchers
        })
        const content = elementFromString(html)
        Component.initDocumentComponentsFromAPI(content)
        this._render(content)
      } else {
        super.hideComponent()
      }
    } else {
      super.hideComponent()
    }
  }

  _render (content) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(content)

    const voucherElements = this.element.querySelectorAll(widgetQueries.voucher)
    voucherElements.forEach((e, index) => {
      const voucher = this.vouchers[index]
      const voucherApi = new Voucher(e, {
        data: {
          variant: 'compact',
          price: {
            ...(voucher.amount && voucher.amount.value) && { value: voucher.amount.value },
            currencyPosition: voucher.amount.currencySettings.symbolPosition === 'BeforeAmount' ? 'before' : 'after',
            ...(voucher.amount && voucher.amount.currencySettings && voucher.amount.currencySettings.symbol) && { currency: voucher.amount.currencySettings.symbol }
          },
          bonus: {
            ...(voucher.bonus && voucher.bonus.amountLabel) && { amountLabel: voucher.bonus.amountLabel },
            ...(voucher.bonus && voucher.bonus.bonusLabel) && { bonusLabel: voucher.bonus.bonusLabel }
          },
          expirationText: this.locales.expirationText ? this.locales.expirationText.replace('{EXPIRATION_DATE}', voucher.expirationDate) : ''
        }
      })
      return voucherApi
    })
  }

  getLocales () {
    const customLocaleElement = document.querySelector(`[data-type="i18n"][data-uid="${this.componentId}"]`)
    let customLocaleData = null
    try {
      customLocaleData = JSON.parse(customLocaleElement.textContent)
    } catch (err) {}

    return customLocaleData || {}
  }

  /**
   * It adds the default params to the url, if the given url already contains params,
   * default params are appended at the end
   *
   * @returns {string} url
   */
  _enrichUrlWithDefaultQueryString (url, params) {
    url = url || ''

    const urlData = {
      ...params,
      ...this.currentParams,
      ...{
        contextItemId: this.componentId
      }
    }

    const queryString = Object.keys(urlData).map(key => key + '=' + urlData[key]).join('&')
    const andOrQuestionMark = url.includes('?') ? '&' : '?'
    return `${url}${andOrQuestionMark}${queryString}`
  }
}

registerWidget(BookingVouchers, widgetApi)

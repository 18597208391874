import * as loadQueueHelper from '../../../../shared/js/document/load-queue'

loadQueueHelper.add(function () {
  initFloatingBox()
}, 2)

const initFloatingBox = () => {
  const floatingBoxDemoElement = document.getElementById('floating-box-demo')
  if (!floatingBoxDemoElement) return
  console.log('A floating box demo found')

  const demoTextbox = floatingBoxDemoElement.querySelector('.c-textbox')
  const demoTextboxApi = demoTextbox['c-textbox']
  const demoFloatingBox = floatingBoxDemoElement.querySelector('.c-floating-box')
  const demoFloatingBoxApi = demoFloatingBox['c-floating-box']
  const closeBtn = demoFloatingBox.querySelector('.c-btn')

  demoTextboxApi.events.on('focus', () => demoFloatingBoxApi.setProp('opened', true))
  demoTextboxApi.events.on('blur', () => demoFloatingBoxApi.setProp('opened', false))
  closeBtn.addEventListener('click', () => demoFloatingBoxApi.setProp('opened', false))
}

import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import { TransportSummaryTemplate } from '../transport-summary/w-transport-summary.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import BookingTransportSummaryDataMapper from './data-mapper'

const widgetApi = 'w-booking-transport-summary'

/**
 * BookingTransportSummary widget
 */
export default class BookingTransportSummary extends BookingBox {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   * @param {Object} [options={}]   - Options
   */
  constructor (element, options = {}) {
    super(element)
    this.dataMapper = new BookingTransportSummaryDataMapper()
  }

  /**
   *
   * @param {Object}    data      - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  _updateWidgetData () {
    const apiData = this.data.summaryComponents.find(service => service.componentId === this.componentId)
    let widgetData
    if (apiData) {
      widgetData = this.dataMapper.mapWidgetData(apiData)
    }
    if (widgetData) {
      const renderedHtml = TransportSummaryTemplate(widgetData)
      const newContent = elementFromString(renderedHtml)
      Component.initDocumentComponentsFromAPI(newContent)
      this._render(newContent)
    } else {
      super.hideComponent()
    }
  }

  _render (el) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(el)
  }
}

registerWidget(BookingTransportSummary, widgetApi)

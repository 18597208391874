import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import JSONFetcher from '../../../../shared/js/helpers/json-fetcher'
import SearchMap from '../../../../shared/modules/widgets/search-map/main'

loadQueueHelper.add(function () {
  initSelectedState()
}, 2)

const initSelectedState = () => {
  const searchMapElement = document.getElementById('my-search-map')
  if (searchMapElement) {
    const searchRequest = new JSONFetcher({ src: '../assets/api/search-app-maps.json' })
    searchRequest.fetch().then(data => {
      // eslint-disable-next-line no-unused-vars
      const searchMap = new SearchMap(searchMapElement, { data })
    })
  }
}

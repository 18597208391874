import { bookingStepsEvents, bookingItemEvents } from '../../../js/document/event-types'
import { apiCaller } from '../../../js/helpers/api-caller'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import registeredEvents from '../../../js/helpers/registered-events'
import { getParamsFromURLForBookingSteps } from '../../../js/document/url'

const EventEmitter = require('eventemitter3')

const widgetApi = 'w-booking-steps'
const childWidgetApi = 'w-booking-item'

const widgetQueries = {
  urlAttr: `data-${widgetApi}__url`,
  nextStepUrlAttr: `data-${widgetApi}__nextstepurl`,
  stepTimeoutAttr: `data-${widgetApi}__steptimeout`,
  contextItemIdAttr: `data-${widgetApi}__contextitemid`,
  cookieBookingIdAttr: `data-${widgetApi}__cookie-bookingid`,
  childWidgetsSelector: `[data-js-api--${childWidgetApi}]`,
  elementWithErrorSelector: '.has-error',
  loaderElement: '[data-t-bookingsteps__loader]',
  trackAttr: 'data-track',
  debugParam: 'debug'
}

const defaults = {
  timeout: 30000
}

export default class BookingSteps {
  /**
  * Creates a new BookingSteps
  *
  * @constructor
  *
  * @param {HTMLElement} element - The element where to attach BookingSteps
  * @param {Object} options
  */
  constructor (element, options = {}) {
    this.nextStepClicked = false
    this.element = element
    this.options = { ...defaults, ...options }
    this.events = new EventEmitter()
    this.registredItems = []
    this.loaderElement = document.querySelector(widgetQueries.loaderElement)
    this.currentParams = { ...getParamsFromURLForBookingSteps() }
    this.timeout = this.element.getAttribute(widgetQueries.stepTimeoutAttr) || this.options.timeout
    this.debug = this._isDebugMode()

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.trackAttr) && { track: this.element.attributes[widgetQueries.trackAttr].value }
    })

    const url = this.element.getAttribute(widgetQueries.urlAttr)
    const bookingItems = this.element.querySelectorAll(widgetQueries.childWidgetsSelector)
    bookingItems.forEach(item => {
      item[childWidgetApi].events.on(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, this._onComponentDataChanged.bind(this))
      item[childWidgetApi].events.on(bookingStepsEvents.NEXT_STEP_PROCESS_STARTED, (ev) => this._onNextStepProcessStarted(ev), this)
      this.registredItems.push(item[childWidgetApi])
    })

    // initial load of booking draft only when there is an url defined
    if (url) {
      const getUrl = this._enrichUrlWithDefaultQueryString(url)
      try {
        this.callApi('GET', getUrl, undefined, true, this.timeout)
      } catch (exception) {
        this._hideLoader()
      }
    }
  }

  /**
   * Calls backend to retrieve data, and pings all registred items
   *
   * @param {string} method - GET|POST|PATCH
   * @param {string} uri - Full url to execute the action
   * @param {object} body - (Optional) The payload for the POST or PATCH operations
   */
  async callApi (method, url, body, isFirstCall = false, timeout) {
    const result = await apiCaller(url, { method, body, timeout })
    const isSuccess = this._getIsSuccess(result)
    this._hideLoader(isFirstCall)
    if (isSuccess) {
      if (isFirstCall) {
        this.events.emit(bookingStepsEvents.BOOKING_ITEM_BOOKINGDRAFT_FETCHED, result.response)
      } else {
        this.events.emit(bookingStepsEvents.BOOKINGDRAFT_FETCHED_AFTER_ITEM_CHANGED, result.response)
      }

      const simultaneouslyHandleFetchedPromises = this.registredItems
        .filter(item => !item.isDeferredAndResolvedByItself())
        .map(item => item.handleFetched(result))

      const deferredHandleFetchedPromises = this.registredItems
        .filter(item => item.isDeferredAndResolvedByItself())
        .map(item => item.handleFetched(result))

      Promise.all(simultaneouslyHandleFetchedPromises).then(resolvedItems => {
        resolvedItems.forEach(resolvedItem => {
          resolvedItem.enableUI()
          resolvedItem.emitAvailableServiceEvent(isFirstCall)
        })
        deferredHandleFetchedPromises.forEach(promise => promise.then(resolvedItem => {
          resolvedItem.enableUI()
          resolvedItem.emitAvailableServiceEvent(isFirstCall)
        }))
      })

      if (this.nextStepClicked) {
        await this._validateClient()
      }
    } else {
      const handleErrorPromises = this.registredItems.map(item => item.handleError(result))
      Promise.all(handleErrorPromises).then(() => {
        this.registredItems.forEach(item => item.setHasError())
      })
      if (window.newrelic) {
        const errorValue = (result && result.response && result.response.error) || (result && result.error) || 'empty message'
        window.newrelic.noticeError('[BookingDraftFetchedError] Error with:' + JSON.stringify(errorValue))
      }
    }
  }

  /**
  * Checks if api call succeed and if it was, the checks that the response from the api doesn't contains errors
  * @param {object} apiResult
  */
  _getIsSuccess (apiResult) {
    let isSuccess = apiResult.success
    if (isSuccess) {
      if (!!apiResult.response && !!apiResult.response.error) {
        isSuccess = !apiResult.response.error.hasError
      }
    }
    return isSuccess
  }

  /**
  * Callback to be executed when the event 'booking-item-changed' is triggered
  * @param {object} data
  */
  async _onComponentDataChanged (data) {
    this.events.emit(bookingStepsEvents.DATA_CHANGED, data)
    this.registredItems.forEach(item => { item.handleFetching() })
    const url = this._enrichUrlWithDefaultQueryString(data.url, data.params)
    this.callApi(data.method, url, data.body, false, this.timeout)
  }

  /**
   * Callback to be executed when the event 'next-step-process-started' is triggered
   */
  async _onNextStepProcessStarted (ev) {
    try {
      if (!ev.url) {
        return
      }
      // This will be needed to disable all components till all validations finish
      this.registredItems.forEach(item => { item.disableUI() })
      this.nextStepClicked = true
      // 1 Validate all components
      const resultValidation = await this._validateAllComponents()
      if (!resultValidation) {
        this._focusFirstElementWithError()
        this.registredItems.forEach(item => item.enableUI())
        return
      }

      // 2 Perform any action needed before moving to next step (like pop up)
      const resultBeforeNextStep = await this._beforeNextStep()
      if (!resultBeforeNextStep) {
        this.registredItems.forEach(item => item.enableUI())
        return
      }

      // 3 Move to next step
      this._gotoUrl(ev.url + window.location.search)
    } catch (error) {
      // Todo: stop going to next step
    }
  }

  async _validateAllComponents () {
    this.debug && console.log('Validating at client side.....')
    const clientResult = await this._validateClient()
    this.debug && console.log(`... client side done. With result ${clientResult}`)
    if (!clientResult) {
      return false
    }
    this.debug && console.log('Validating at server side.....')
    const serverResult = await this._validateServer()
    this.debug && console.log(`... server side done. With result ${serverResult}`)
    return serverResult
  }

  async _validateClient () {
    const validationPromises = []
    const conditionsValidationPromises = []
    this.registredItems.forEach(item => {
      validationPromises.push(item.handleValidationClient())
      conditionsValidationPromises.push(item.handleConditionsValidation())
    })
    const results = await Promise.all(validationPromises)
    const conditionsValidationResults = await Promise.all(conditionsValidationPromises)
    if (results.every(result => result) && conditionsValidationResults.every(result => result.isValid)) {
      return true
    } else {
      this.debug && console.log('Validation at client side failed')
      return false
    }
  }

  async _validateServer () {
    let index = 0
    let noErrors = true
    while (index < this.registredItems.length && noErrors) {
      const data = await this.registredItems[index].getValidationData()
      if (data.url) {
        const url = this._enrichUrlWithDefaultQueryString(data.url, data.params)
        const result = await apiCaller(url, { method: data.method, body: data.body, isForm: data.isForm ? data.isForm : false, timeout: this.timeout })
        if (result.success) {
          noErrors = await this.registredItems[index].handleValidationServer(result.response)
        } else {
          noErrors = false
        }
      }
      index++
    }
    return noErrors
  }

  async _beforeNextStep () {
    const beforeNextStepPromises = []
    this.registredItems.forEach(item => { beforeNextStepPromises.push(item.beforeNextStep()) })
    const results = await Promise.all(beforeNextStepPromises)
    if (results.every(result => result)) {
      return true
    } else {
      this.debug && console.log('Error when evaluating promises of BeforeNextStep')
      return false
    }
  }

  _gotoUrl (url) {
    this.debug && console.log('Redirecting to ' + url)
    window.location.href = url
  }

  /**
   * It adds the default params to the url, if the given url already contains params,
   * default params are appended at the end
   *
   * @returns {string} url
   */
  _enrichUrlWithDefaultQueryString (url, params) {
    url = url || ''

    const urlData = {
      ...params,
      ...this.currentParams,
      ...{
        stepTimeout: this.timeout,
        contextItemId: this.element.getAttribute(widgetQueries.contextItemIdAttr)
      }
    }

    const queryString = Object.keys(urlData).map(key => key + '=' + urlData[key]).join('&')
    const andOrQuestionMark = url.includes('?') ? '&' : '?'
    return `${url}${andOrQuestionMark}${queryString}`
  }

  _focusFirstElementWithError () {
    const firstElementWithError = document.querySelector(widgetQueries.elementWithErrorSelector)
    if (firstElementWithError != null) {
      firstElementWithError.scrollIntoView({ behavior: 'smooth' })
    }
  }

  _hideLoader (isFirstCall = true) {
    if (isFirstCall && this.loaderElement) {
      this.loaderElement.classList.add('is-hidden')
    }
  }

  _isDebugMode () {
    const urlParams = new URLSearchParams(window.location.search)
    const debugParam = urlParams.get(widgetQueries.debugParam)
    return !!debugParam
  }
}

registerWidget(BookingSteps, widgetApi)

import { defaultNumberStepperData, NumberStepperTemplate } from '../../components/number-stepper/c-number-stepper.template'

export const BookingFilterableServicesQuantityNumberStepperTemplate = (service, configurations) => {
  return service.useQuantity
    ? `<div class="w-booking-filterable-services__filter w-booking-filterable-services__filter__header w-booking-filterable-services__filter-quantity">
        ${NumberStepperTemplate({
          ...defaultNumberStepperData,
          id: 'quantitty-component-id',
          value: service.quantity,
          minValue: 1,
          maxValue: service.maxQuantity,
          label: service.quantityLabel,
          attributes: {
            'data-w-booking-filterable-services__quantity-number-stepper': '',
            'data-w-booking-filterable-services__service-id': service.uid
          }
        })}
      </div>`
    : ''
}

import { BoxTemplate } from '../../components/box/c-box.template'

/**
 * The RoomSelectorDropdownTemplateData contains all data needed to hydrate a RoomSelector view
 *
 * @typedef {Object}                  RoomSelectorDropdownTemplateData
 *
 * @property {Boolean}                [showMessage]   - Indicates if the message must be shown
 * @property {String}                 [state]         - State of the message which sets one color or another
 * @property {String[]}               [messagesText]  - List of texts to show
 * @property {String}                 [token]         - Token name to be used in the title so each brand can use a different style in the title
 * @property {String}                 [extraClasses]  - Extra classes to be injected in the main <div> of the rendered html
 * @property {String}                 [showArrow]     - Indicates if an arrow must be shown in the box of the message
 * @property {String}                 [arrowPosition] - [top|bottom|left|right] Indicates the position of the arrow
 */
export const defaultRoomSelectorMessageData = {
  showMessage: true,
  state: 'info',
  messagesText: [],
  showArrow: false,
  arrowPosition: 'top',
  token: '',
  extraClasses: ''
}

export const RoomSelectorMessageTemplate = (d) => {
  d = { ...defaultRoomSelectorMessageData, ...d }

  const messages = d.messagesText && d.messagesText.filter(msg => msg && msg.length !== '')
  const showMessageBox = d.showMessage && messages.length > 0
  const extraClassArrowPosition = d.showArrow && d.arrowPosition ? `c-box--arrow-${d.arrowPosition}` : ''

  const result = showMessageBox
    ? `<div class="w-room-selector__message ${d.extraClasses}">
              ${BoxTemplate({
                variant: d.showArrow ? 'arrow' : '',
                html: `<div class='w-room-selector__message-container'>
                          ${messages.map(message => `<p>${message}</p>`).join('')}
                      </div>`,
                extraClasses: `${extraClassArrowPosition} w-room-selector__message-box cs-state-${d.state} cs-state-${d.state}--light`
              })}
            </div>`
    : ''
  return result
}

export const RoomSelectorWarningTemplate = (d) => {
  const messages = d.messagesText && d.messagesText.filter(msg => msg && msg.length !== '')
  return `
    <div class="w-room-selector__warning-container ${d.extraClasses}">
      ${messages.map(message => `<p>${message}</p>`).join('')}
    </div>
  `
}

export const BookingBasketServiceLineTemplate = (d) => {
  return `
    ${d.lines.map(line =>
    `<div class="w-booking-basket__lines-item${line.isTitle ? '--title' : ''}">
      <span class="w-booking-basket__lines-item-text">
        ${line.text}
        ${line.price ? `<span class="w-booking-basket__lines-item-price">${line.price}</span>` : ''}
      </span>
      ${line.totalPrice ? `<span class="w-booking-basket__lines-item-total-price">${line.totalPrice}</span>` : ''}
    </div>`
  ).join('')}
  `
}

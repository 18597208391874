import { registerWidget } from '../../../js/core/widget/widget-directory'

const EventEmitter = require('eventemitter3')
const WIDGET_API = 'w-room-occupancy'
const WIDGET_QUERIES = {
  numberSteppers: `.${WIDGET_API}__number-stepper`,
  removeRoomButton: `.${WIDGET_API}__remove-button`

}
const NUMBER_STEPPER_API = 'c-number-stepper'

export default class RoomOccupancy {
  /**
   * Creates a new RoomOccupancy
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element.
   * @param {options} [options={}] - Options object
   *
   */
  constructor (element, options = {}) {
    this.element = element
    this.options = {
      ...options
    }

    this.elements = this.runElementQueries()

    this.events = new EventEmitter()
    this.NumberSteppersApi = []

    this._initNumberSteppersApi()
    this._bindElements()

    this.element[WIDGET_API] = {
      element: this.element,
      options: this.options,
      events: this.events
    }
  }

  runElementQueries () {
    return {
      numberSteppers: [...this.element.querySelectorAll(WIDGET_QUERIES.numberSteppers)],
      removeRoomButton: this.element.querySelector(WIDGET_QUERIES.removeRoomButton)
    }
  }

  /**
   * Make accessible elements Api of componets to this
   *
   * @returns {Object}
   */
  _initNumberSteppersApi () {
    this.numberSteppersApi = this.elements.numberSteppers.map(stepper => stepper[NUMBER_STEPPER_API])
  }

  /**
   * Bind events of the NumberSteppers and RemoveButton
   */
  _bindElements () {
    if (this.numberSteppersApi && this.numberSteppersApi.length > 0) {
      this.numberSteppersApi.forEach(api => {
        api.events.on('change', this._handleRoomOccupancyChanged, this)
      })
    }

    if (this.elements.removeRoomButton) {
      this.elements.removeRoomButton['c-btn'].events.on('clickButton', this._handleRemoveRoomButtonClicked, this)
    }
  }

  /**
   * Gets the room occupancy by ageProfile
   *
   * @returns {PartyComposition}
   */
  getOccupancy () {
    return this.numberSteppersApi.reduce((occupancy, stepper) => {
      const ageProfile = stepper.element.attributes['data-age-profile'].value
      occupancy[ageProfile] = stepper.props.value

      return occupancy
    }, {})
  }

  /**
   * Common code to handle event of number of occupancy changed
   */
  _handleRoomOccupancyChanged () {
    this.events.emit('roomOccupancyChanged', this.getOccupancy())
  }

  /**
   * handle event remove room clicked button
   */
  _handleRemoveRoomButtonClicked () {
    this.events.emit('removeRoom', this.element.id)
  }
}

registerWidget(RoomOccupancy, WIDGET_API)

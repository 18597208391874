import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import { BookingPriceOverviewTemplate } from './w-booking-price-overview.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import BookingPriceOverviewDataMapper from './data-mapper'

const widgetApi = 'w-booking-price-overview'

const definition = {
  name: 'w-booking-price-overview'
}

const widgetQueries = {
  body: `[data-${definition.name}__body]`
}

/**
 * BookingPriceOverview widget
 */
export default class BookingPriceOverview extends BookingBox {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   * @param {Object} [options={}]   - Options
   */
  constructor (element, options = {}) {
    super(element)
    this.bodyElement = this.element.querySelector(widgetQueries.body)
    this.dataMapper = new BookingPriceOverviewDataMapper()
  }

  /**
   *
   * @param {Object}    data      - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  _updateWidgetData () {
    const widgetData = this.data.priceOverviews && this.data.priceOverviews.find(service => service.componentId === this.componentId)
    if (widgetData) {
      const translatedWidgetData = this.dataMapper.map(widgetData)
      const renderedHtml = BookingPriceOverviewTemplate(translatedWidgetData)
      const newContent = elementFromString(renderedHtml)
      Component.initDocumentComponentsFromAPI(newContent)
      this._render(newContent)
    }
  }

  _render (el) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(el)
  }
}

registerWidget(BookingPriceOverview, widgetApi)

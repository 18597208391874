/**
 *
 * @global
 * @typedef {Object}          AgeProfileData
 *
 * @property {String}         id              - Age profile id
 * @property {String}         name            - Age profile name (pluralized)
 * @property {Boolean}        countAsTotal    - Will count as total people when true
 * @property {Number|null}    minAge          - Min age for current profile
 * @property {Number|null}    maxAge          - Max age for current profile, null is infinite
 *
 */

/**
 * Age Profiles collection
 */
export default class AgeProfiles {
  /**
   * Creates a new collection
   *
   * @constructor
   * @param {AgeProfileData[]}      ageProfilesData
   * @param {Object}               [options]
   *
   */
  constructor (ageProfilesData = [], options = {}) {
    this.data = ageProfilesData
  }

  /**
   * Get ageProfile ids as array
   *
   * @returns {String[]}
   */
  getIds () {
    return this.data.map(ageProfile => ageProfile.id)
  }

  /**
   * Get ageProfile ids with occupation as array
   *
   * @param {Object}               ageProfileId
   *
   * @returns {String[]}
   */
  getIdsWithOccupation (occupation) {
    return this.getIds()
      .map(ageProfileId => occupation[ageProfileId] ? ageProfileId : null)
      .filter(ageProfileId => ageProfileId !== null)
  }

  /**
   * Get valid profiles
   *
   * @returns {AgeProfileData}
   */
  getValidProfiles () {
    return this.data
      .filter(ageProfile => ageProfile.minAge !== undefined || ageProfile.maxAge !== undefined)
  }

  /**
   * Find an ageProfile by a given id
   *
   * @param {String}               ageProfileId
   *
   * @returns {AgeProfileData}
   */
  findById (ageProfileId) {
    return this.data.find(ageProfile => ageProfile.id === ageProfileId)
  }

  /**
   * Returns true if given ageProfileId counts as total
   *
   * @param {String}               ageProfileId
   *
   * @returns {Boolean}
   */
  countsAsTotal (ageProfileId) {
    const ageProfile = this.findById(ageProfileId)
    return !!ageProfile && !!ageProfile.countAsTotal
  }

  /**
   * Returns true if given ageProfileId is included on data
   *
   * @param {String}               ageProfileId
   *
   * @returns {Boolean}
   */
  includesId (ageProfileId) {
    return !!this.findById(ageProfileId)
  }
}

export default class BookingRedeemerDataMapper {
  /**
   * Maps API response data to widget data
   *
   * @typedef {Object}         BookingRedeemerData
   *
   * @property {Object}        [apiResponseData]    - Api response data
   * @property {Object}        [texts]              - Translations
   */
  mapWidgetData (apiResponseData, texts) {
    this.texts = texts
    const usps = this._mapUspInfo(texts.usps)
    const mappedTexts = { ...texts, usps }

    const widgetData = {
      texts: mappedTexts,
      redeemableBookings: apiResponseData ? apiResponseData.map(booking => this._mapRedeemableBooking(booking)) : []
    }
    return widgetData
  }

  _mapRedeemableBooking (apiBooking) {
    const isRedeemable = Object.prototype.hasOwnProperty.call(apiBooking, 'isRedeemable') ? apiBooking.isRedeemable : true
    const widgetBooking = {
      image: apiBooking.accoImageUrl ? this._mapBookingImage(apiBooking) : '',
      title: apiBooking.description,
      bookingNumber: apiBooking.number,
      information: this._mapBookingInfo(apiBooking),
      breadcrumbs: apiBooking.location ? this._mapBookingLocation(apiBooking.location) : '',
      selected: apiBooking.selected,
      disabled: !isRedeemable,
      price: {
        value: apiBooking.paidAmount.value,
        size: '',
        legend: this.texts.paidAmountText,
        currency: apiBooking.paidAmount.currencySettings.symbol,
        currencyPosition: apiBooking.paidAmount.currencySettings.symbolPosition === 'BeforeAmount' ? 'before' : 'after'
      }
    }
    return widgetBooking
  }

  _mapBookingImage (apiBooking) {
    const image = {
      sync: false,
      resolve: 'intersect',
      placeholderSrc: `${apiBooking.accoImageUrl}?width=20&height=20&scale=both&mode=crop`,
      resolvedSrc: `${apiBooking.accoImageUrl}?width={W}&height={H}&scale=both&mode=crop`,
      ratio: '16:9',
      dynamicRatios: [
        {
          ratio: '1:1',
          bp: 'sml'
        }
      ]
    }

    return image
  }

  _mapBookingLocation (location) {
    const widgetBookingLocation = {
      unclickable: true,
      structuredData: false,
      items: this._mapItemsForBookingLocation(location)
    }

    return widgetBookingLocation
  }

  _mapItemsForBookingLocation (location) {
    const widgetLoc = Object.keys(location).reduce((acc, curr) => {
      return [...acc, { link: '#', text: location[curr] }]
    }, [])
    return widgetLoc
  }

  _mapBookingInfo (apiBooking) {
    const widgetBookingInfo = {
      variant: 'inline',
      items: this._mapItemsForBookingInfo(apiBooking.date, apiBooking.composition, apiBooking.number)
    }
    return widgetBookingInfo
  }

  _mapItemsForBookingInfo (date, composition, number) {
    const items = []
    if (date) {
      items.push(this._mapBookingInfoBulletListItem('date', date))
    }
    if (composition) {
      items.push(this._mapBookingInfoBulletListItem('composition', composition))
    }
    if (number) {
      items.push(this._mapBookingInfoBulletListItem('number', `${this.texts.bookingNumberText}: ${number}`))
    }

    return items
  }

  _mapBookingInfoBulletListItem (type, text) {
    let icon

    switch (type) {
      case 'date':
        icon = 'calendar'
        break
      case 'composition':
        icon = 'group'
        break
      case 'number':
        icon = 'checkmark-circle'
        break
      default:
        icon = ''
        break
    }

    const item = {
      icon,
      text
    }

    return item
  }

  _mapUspInfo (usps) {
    const widgetUspInfo = {
      variant: '',
      icon: 'checkmark',
      items: usps ? usps.map(usp => this._mapUsp(usp)) : []
    }
    return widgetUspInfo
  }

  _mapUsp (apiUsp) {
    const widgetUsp = {
      text: apiUsp.text
    }

    return widgetUsp
  }
}

export const MapAvailableHoursToOptions = (timeConfig) => {
  const items = []
  for (let hour = timeConfig.minTime; hour < timeConfig.maxTime; hour++) {
    items.push([hour, 0])
    items.push([hour, timeConfig.interval])
  }

  const date = new Date()
  const formatter = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', hour12: false })

  const options = items.map(time => {
    const [hour, minute] = time
    date.setHours(hour)
    date.setMinutes(minute)

    const formattedTime = formatter.format(date)
    return {
      default: false,
      text: formattedTime,
      value: formattedTime,
      selected: false
    }
  })

  return options
}

import { TitleTemplate, defaultTitleData } from '../../components/title/c-title.template'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'
import { BulletListTemplate, defaultBulletListData } from '../../components/bullet-list/c-bullet-list.template'
import { CardPriceTemplate } from '../../components/card-price/c-card-price.template'
import { BreadcrumbsTemplate } from '../../components/breadcrumbs/c-breadcrumbs.template'
import { BookingMessageTemplate } from '../booking-message/w-booking-message.template'

/**
 * The BookingChoiceListData contains all data needed to hydrate a BookingChoiceList view
 *
 * @typedef {Object}                  BookingRedeemerData
 *
 */

export const defaultBookingRedeemerData = {
  title: {
    size: 'tiny',
    tag: 'h3'
  },
  selectButton: '',
  revertButton: '',
  bookingRedeemerMessageSelected: '',
  bookingRedeemerMessageNotSelected: '',
  bookingRedeemerMessageDisabled: '',
  bookingNumberText: '',
  bookingsAvailableText: '',
  bookingsNotAvailableText: '',
  modalTriggerText: ''
}

export const BookingRedeemerListTemplate = (d, options) => {
  d = { ...defaultBookingRedeemerData, ...d }
  return `${d.redeemableBookings.length > 0
        ? `<div class="w-booking-redeemer__container o-layout">
            <p class="m-body m-body--small o-layout__item u-1/1">${d.texts.bookingsAvailableText}</p>
            <div class="w-booking-redeemer__usps o-layout__item u-1/1" data-w-booking-redeemer__usps>
              ${BulletListTemplate({ ...defaultBulletListData, ...d.texts.usps, variant: 'inline' })}
            </div>
            ${TitleTemplate({ ...defaultTitleData, ...{ size: d.title.size, tag: d.title.tag, text: d.texts.bookingsText, extraClasses: 'o-layout__item  u-1/1' } })}
            ${d.redeemableBookings.map(booking => `${RedeemableBookingTemplate(booking, d.texts)}`).join('')}
            ${d.texts && d.texts.bookingRedeemerMessageNotSelected
              ? `<div class="w-booking-redeemer__global-messages ${d.redeemableBookings.filter(booking => booking.selected === true).length === 0 ? '' : 'is-hidden'}">
              ${BookingMessageTemplate({
                extraClasses: '',
                messages: [
                  {
                    type: 'warning',
                    texts: [d.texts.bookingRedeemerMessageNotSelected]
                  }
                ]
              })}
              </div>`
            : ''}
          </div>`
        : `<p class="m-body m-body--small">${d.texts.bookingsNotAvailableText}
            ${d.texts.modalTriggerText && options.noCandidatesModalId
              ? `<a class="w-booking-redeemer__no-candidates-modal-trigger" data-js-component="c-btn" data-c-modal-v2__action="open" data-c-modal-v2__id="${options.noCandidatesModalId}">
                    ${d.texts.modalTriggerText}</a>`
              : ''}
          </p>`
        }`
}

const RedeemableBookingTemplate = (booking, texts) => {
  return `<div class="w-booking-redeemer__booking ${booking.selected ? 'is-selected' : ''}" data-w-booking-redeemer__booking>
            ${CardPriceTemplate({
              image: booking.image,
              title: booking.title,
              titleTag: 'h3',
              subtitle: booking.breadcrumbs && booking.breadcrumbs.items.length > 0 ? BreadcrumbsTemplate(booking.breadcrumbs) : '',
              subtitleTag: 'div',
              price: booking.price,
              description: BookingRedeemerDescriptionTemplate(booking, texts),
              extraClasses: `w-booking-redeemer__booking__card ${booking.image ? 'w-booking-redeemer__booking__card--with-image' : 'w-booking-redeemer__booking__card--without-image'}`
            })}
          ${texts.bookingRedeemerMessageSelected ? BookingRedeemerMessage('success', texts.bookingRedeemerMessageSelected, booking) : ''}
          </div>
          `
}

const BookingRedeemerMessage = (type, text, booking) => {
  let html = ''
  if (text) {
    const messageData = {
      extraClasses: '',
      messages: [
        {
          type,
          texts: [text]
        }
      ]
    }
    html = `<div class="w-booking-redeemer__messages ${!booking.selected ? 'is-hidden' : ''}" data-w-booking-redeemer__messages>${BookingMessageTemplate(messageData)}</div>`
  }
  return html
}

const BookingRedeemerDescriptionTemplate = (booking, texts) => {
  return `<div class="w-booking-redeemer__booking__body">
    <div class="w-booking-redeemer__booking__body-heading">
      ${BulletListTemplate({ ...defaultBulletListData, ...booking.information })}
      ${texts && texts.bookingRedeemerMessageDisabled && booking.disabled
        ? BookingMessageTemplate({
          extraClasses: '',
          messages: [
            {
              type: 'info',
              texts: [texts.bookingRedeemerMessageDisabled]
            }
          ]
        })
        : ''
      }
    </div>
    
    <div class="w-booking-redeemer__booking__body-buttons">
      ${BtnTemplate({
        ...defaultButtonData,
        ...{
          jsApi: true,
          text: texts.selectButtonText,
          variant: 'trust',
          block: false,
          disabled: booking.disabled,
          extraClasses: `w-booking-redeemer__button-select ${booking.selected ? 'is-hidden' : ''}`,
          attributes:
          {
            'data-w-booking-redeemer__booking-select-button': '',
            'data-w-booking-redeemer__booking-number': booking.bookingNumber
          }
        }
      })}
      ${BtnTemplate({
        ...defaultButtonData,
        ...{
          jsApi: true,
          text: texts.revertButtonText,
          variant: 'flat',
          icon: 'cross',
          block: false,
          disabled: booking.disabled,
          extraClasses: `w-booking-redeemer__button-revert ${!booking.selected ? 'is-hidden' : ''}`,
          attributes:
          {
            'data-w-booking-redeemer__booking-revert-button': '',
            'data-w-booking-redeemer__booking-number': booking.bookingNumber
          }
        }
      })}
    </div>
  </div>`
}

import { defaultTitleData, TitleTemplate } from '../../components/title/c-title.template'
import { defaultTransportTicketData, TransportTicketTemplate } from '../../components/transport-ticket/c-transport-ticket.template'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/w-transport-summary__tokens.json')['w-transport-summary']

const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/widgets/transport-summary/data/w-transport-summary__tokens.json`)['w-transport-summary'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

/**
 * The TransportSummaryTemplate contains all the needed data to hydrate the booking Flight summary component
 *
 * @typedef {Object}      TransportSummaryTemplate
 *
 * @property {String}     id                               - The id used to show the component
 * @property {String}     type                             - Type of transport
 * @property {String}     extraClasses                     - Extra classes if needed
 * @property {String}     outboundHeading                  - The outbound heading
 * @property {String}     outboundDate                     - The outbound Date
 * @property {String}     oldOutboundDate                  - The date the outbound flight had before being changed
 * @property {String}     inboundHeading                   - The inbound heading
 * @property {String}     inboundDate                      - The inbound Date
 * @property {String}     oldInboundDate                   - The date the inbound flight had before being changed
 * @property {String}     outbound                         - The outbound object
 * @property {String}     inbound                          - The inbound object
 * @property {String}     flightDetails                    - flight details text
 * @property {bool}       showNoTransportMessage           - True if we want to show cards with a message if there is no transport
 * @property {String}     inboundNoTransportMessage        - Message shown if there is no inbound transport
 * @property {String}     outboundNoTransportMessage       - Message shown if there is no outbound transport
 */

export const defaultBookingTransportSummaryData = {
  id: 'my-transport-summary',
  type: 'flight',
  extraClasses: '',
  outboundHeading: '',
  outboundDate: '',
  oldOutboundDate: '',
  inboundHeading: '',
  inboundDate: '',
  oldInboundDate: '',
  outbound: {},
  inbound: {},
  distribution: '',
  flightDetails: '',
  showNoTransportMessage: false,
  inboundNoTransportMessage: '',
  outboundNoTransportMessage: ''
}

export const TransportSummaryTemplate = (d) => {
  return `
  <div class="w-transport-summary w-transport-summary__container ${d.extraClasses}">
    <div class="w-transport-summary__items-container ${getTokenClass('distribution', d.distribution, tokens)}">
      ${(d.outbound || d.outboundDate || (d.showNoTransportMessage && !d.outbound))
        ? renderItem(d, d.outboundHeading, d.outboundDate, d.oldOutboundDate, d.outbound, false)
        : ''}
        ${(d.inbound || d.inboundDate || (d.showNoTransportMessage && !d.outbound))
        ? renderItem(d, d.inboundHeading, d.inboundDate, d.oldInboundDate, d.inbound, true)
        : ''}
    </div>
    ${d.flightDetails ? `<div class="w-transport-summary__flight-details">${d.flightDetails}</div>` : ''}
  </div>
  `
}

function renderItem (d, heading, date, oldDate, ticket, isInbound) {
  const hasOldDate = !!oldDate && (oldDate !== date)
  const showNoTransportMessage = d.showNoTransportMessage && !ticket
  const dateAdditionalClass = hasOldDate ? 'w-transport-summary__heading-date--new' : ''
  const transportTicketParams = {
    type: d.type,
    selectorIsHidden: true,
    isInbound,
    isFlexibleTicket: isInbound ? d.inbound.isFlexible : d.outbound.isFlexible,
    maxCheckedLuggageWeight: isInbound ? d.inbound.maxCheckedLuggageWeight : d.outbound.maxCheckedLuggageWeight,
    maxHandLuggageWeight: isInbound ? d.inbound.maxHandLuggageWeight : d.outbound.maxHandLuggageWeight,
    checkedLuggageDescription: isInbound ? d.inbound.checkedLuggageDescription : d.outbound.checkedLuggageDescription,
    handLuggageDescription: isInbound ? d.inbound.handLuggageDescription : d.outbound.handLuggageDescription
  }

  return `
  <div class="w-transport-summary__item ${showNoTransportMessage ? 'w-transport-summary__item--empty-message' : ''}">
    <div class="w-transport-summary__heading">
      ${TitleTemplate(Object.assign({}, defaultTitleData, { size: getTokenClass('title-size', 'default', tokens), tag: 'span', text: heading, extraClasses: 'w-transport-summary__heading-text' }))}
      <span class="w-transport-summary__heading-date">
        ${hasOldDate ? `<span class="w-transport-summary__heading-date--old">${oldDate}</span>` : ''}
        <span class="${dateAdditionalClass}">${date}</span>
      </span>
    </div>
    <div class="w-transport-summary__options">
      ${d.showNoTransportMessage && !ticket
        ? `
        <div class="c-transport-ticket__contents">
          <div class="w-transport-summary__transport-ticket-title">
            ${isInbound ? d.inboundNoTransportMessage : d.outboundNoTransportMessage}
          </div>
        </div>`
        : TransportTicketTemplate(Object.assign({}, defaultTransportTicketData, ticket, transportTicketParams))
      }

    </div>
  </div>
  `
}

import { bool, objectOf, oneOfType, shape, string } from 'prop-types'

export const propTypes = {

  /** Card image */
  image: shape({
    sync: bool,
    resolve: string,
    placeholderSrc: string,
    resolvedSrc: string,
    title: string,
    extraClasses: string,
    attributes: objectOf(
      oneOfType([
        bool,
        string
      ])
    ),
    ratio: string
  }),

  /** Card title */
  title: string.isRequired,

  /** Card title tag */
  titleTag: string.isRequired,

  /** Card subtitle */
  subtitle: string,

  /** Card subtitle tag */
  subtitleTag: string,

  /** Price */
  price: shape({
    size: string,
    value: string,
    currencyPosition: string,
    currency: string,
    legend: string
  }),

  /** Optional descriptin */
  description: string,

  button: shape({
    block: bool,
    loading: bool,
    variant: string,
    text: string,
    link: shape({
      href: string,
      title: string,
      target: string
    })
  }),

  /** Extra class names to be appended on root element */
  extraClasses: string,

  /** Extra attributes to be appended on root element */
  attributes: objectOf(
    oneOfType([
      bool,
      string
    ])
  )
}

export const defaultProps = {
  title: '',
  variant: 'default',
  titleTag: 'h3',
  subtitle: '',
  subtitleTag: 'h4',
  description: '',
  price: {
    size: '',
    value: '',
    currencyPosition: '',
    currency: '',
    legend: '',
    extraClasses: 'c-card-price__price'
  }
}

import { BulletListTemplate, defaultBulletListData } from '../../components/bullet-list/c-bullet-list.template'

/**
 * The BookingOptionalAncillariesPopUp contains all data needed to hydrate a BookingCarRental view
 *
 * @typedef {Object}                          BookingOptionalAncillariesPopupData
 *
 */

export const BookingOptionalAncillariesPopupBodyTemplate = (d) => {
  return d.optionalServices && d.optionalServices.length > 0
    ? BulletListTemplate({
      ...defaultBulletListData,
      items: d.optionalServices
    })
    : ''
}

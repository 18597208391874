/**
 * A mapper to convert from "Sitecore Api response" to "internal widget model".
 * An example of the internal widget model is:
 * {
 *  "error": {
 *      "hasError": true,
 *      "description": "Error of type...",
 *      "hints": [
 *          "First hint",
 *          "Second hint"
 *       ],
 *      "phoneInfo": "666123456",
 *      "emailInfo": "customer@test.com"
 *  }
 */
export default class BookingErrorDataMapper {
  mapApiErrorToWidget (apiErrorResponse, titleText) {
    let messages = []
    let phoneInfo = null
    let emailInfo = null
    let errorCode = ''
    let errorMessage = ''

    if (apiErrorResponse && apiErrorResponse.error) {
      const hints = apiErrorResponse.error.hints ? apiErrorResponse.error.hints : []
      messages = [apiErrorResponse.error.description, ...hints]
      phoneInfo = apiErrorResponse.error.phoneInfo
      emailInfo = apiErrorResponse.error.emailInfo
      errorMessage = apiErrorResponse.error.errorMessage || ''
      errorCode = apiErrorResponse.error.errorCode || ''
    }
    const widgetData = {
      externalMessage: {
        errorMessage,
        errorCode
      },
      internalMessages: {
        visible: true,
        title: titleText,
        messages
      },
      phoneInfo,
      emailInfo
    }
    return widgetData
  }
}

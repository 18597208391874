import Calendar from '../calendar/main'
import CalendarDataModel from '../calendar/calendar-data-model'
import domEventsHelper from '../../../js/document/dom-events-helper'
import Component from '../../../js/core/component/component'
import { propTypes } from './c-calendar-field.props'

import { registerComponent } from '../../../js/core/component/component-directory'

const COMPONENT_API = 'c-calendar-field'

const COMPONENT_QUERIES = {
  textBox: `[data-${COMPONENT_API}__date-textbox]`,
  floatingBox: `[data-${COMPONENT_API}__floating-box]`,
  calendar: `[data-${COMPONENT_API}__calendar]`,
  cancel: `[data-${COMPONENT_API}__calendar-action="cancel"]`,
  submit: `[data-${COMPONENT_API}__calendar-action="submit"]`,
  // apis
  textBoxApi: 'c-textbox',
  floatingBoxApi: 'c-floating-box'
}

const defaults = {
  selectedDates: [],
  selectedDate: '',
  disabledDates: [],
  startDate: '',
  endDate: ''
}

export const COMPONENT_DEFINITION = {
  name: COMPONENT_API,
  props: propTypes
}

const EventEmitter = require('eventemitter3')

// export default class CalendarField extends Component {
export default class CalendarField extends Component {
  /**
   * Creates a new CalendarField
   *
   * @constructor
   *
   */
  constructor (element, options = {}) {
    super(element, COMPONENT_DEFINITION.name)
    this.calendarDataModelObject = {
      ...defaults,
      ...options.calendarDataModelObject.attributes
    }

    this.events = new EventEmitter()
    // Run the queries finding for elements
    this.textBox = this.element.querySelector(COMPONENT_QUERIES.textBox)
    this.textBoxInput = this.textBox.querySelector('input')
    this.floatingBox = this.element.querySelector(COMPONENT_QUERIES.floatingBox)
    this.calendar = this.element.querySelector(COMPONENT_QUERIES.calendar)
    this.cancel = this.element.querySelector(COMPONENT_QUERIES.cancel)
    this.submit = this.element.querySelector(COMPONENT_QUERIES.submit)

    Component.initDocumentComponentsFromAPI(this.element)
    // Access to available elements API
    this.textBoxApi = this.textBox[COMPONENT_QUERIES.textBoxApi]
    this.floatingBoxApi = this.floatingBox[COMPONENT_QUERIES.floatingBoxApi]

    // Init component state
    this.isOpened = false

    // Bind component events
    this.textBoxApi.events.on('focus', this.onFocus, this)
    this.textBoxInput.addEventListener('mousedown', (ev) => {
      this.onFocus()
      ev.stopPropagation()
    })

    this.textBoxApi.events.on('clear', () => {
      this.selection.setAttribute('selectedDates', [])
    })

    // Bind DOM events
    this._domEvents = [
      [this.cancel, { click: (ev) => this.onCancel(ev) }],
      [this.submit, { click: (ev) => this.onSubmit(ev) }],
      [this.floatingBox, { mousedown: (ev) => this.stopPropagation(ev) }]
    ]
    domEventsHelper.attachEvents(this._domEvents, COMPONENT_API)

    // Init filterModel
    this.setCalendarDataModel()

    // Initialize calendar element
    this.calendarApi = new Calendar(this.calendar, this.tempSelection)

    this.closeFloatingBox()

    // Expose component public API
    this.element[COMPONENT_API] = {
      element: this.element,
      selection: this.selection,
      events: this.events
    }
  }

  setCalendarDataModel () {
    this.selection = new CalendarDataModel(this.calendarDataModelObject)
    this.tempSelection = new CalendarDataModel(this.calendarDataModelObject)

    this.textBoxApi.setProp('value', this.tempSelection.humanizedSelectedDates())

    if (this.calendarApi) this.calendarApi.updateCalendarData(this.tempSelection)

    this.selection.events.on('change', this.onCalendarDataModelChange, this)

    return this
  }

  openFloatingBox () {
    this.textBoxInput.classList.add('is-focused')
    this.floatingBoxApi.setProp('opened', true)
    this._floatingBoxEvents = [
      [document, { mousedown: (ev) => this.onCancel(ev) }],
      [this.textBox, { click: (ev) => this.stopPropagation(ev) }]
    ]
    domEventsHelper.attachEvents(this._floatingBoxEvents, COMPONENT_API)
  }

  closeFloatingBox () {
    domEventsHelper.detachEvents(this._floatingBoxEvents, COMPONENT_API)
    const closingPromise = this.floatingBoxApi.setProp('opened', false)
    this.textBoxInput.classList.remove('is-focused')
    return closingPromise
  }

  onFocus (ev) {
    if (this.isOpened) return
    this.isOpened = true

    const { cid, ...restoreAttributes } = this.selection.attributes
    this.tempSelection.setAttributes(restoreAttributes)
    this.openFloatingBox()
    if (this.calendarApi && this.tempSelection.getAttribute('selectedDates').length === 0) {
      const firstAvailableDate = this.calendarDataModelObject.startDate
      const firstDateArray = [new Date(firstAvailableDate)]
      this.calendarApi.setCalendarSelectedDatesOnModel(firstDateArray)
      this.calendarApi.jumpToDate(firstAvailableDate)
    }
  }

  onCancel (ev) {
    this.isOpened = false
    const { cid, ...restoreAttributes } = this.selection.attributes

    this.closeFloatingBox()
      .then(() => {
        this.tempSelection.setAttributes(restoreAttributes)
      })
  }

  onSubmit (ev) {
    this.isOpened = false
    this.closeFloatingBox()
    const areSameValues = this.tempSelection.attributes.selectedDates.length === this.selection.attributes.selectedDates.length &&
      this.tempSelection.attributes.selectedDates.sort().every((v, i) => v === this.selection.attributes.selectedDates.sort()[i])
    const { cid, ...newAttributes } = this.tempSelection.attributes
    this.selection.setAttributes(newAttributes)
    if (!areSameValues) this.events.emit('selectedDatesChanged', this.selection.attributes.selectedDates)
  }

  onCalendarDataModelChange (ev) {
    this.textBoxApi.setProp('value', this.selection.humanizedSelectedDates())
  }

  stopPropagation (ev) {
    ev.stopPropagation()
  }
}

registerComponent(CalendarField, COMPONENT_API, COMPONENT_DEFINITION)

import BookingItem from '../booking-item/main'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { StepsTemplate, defaultStepsTemplate } from '../../components/steps/c-steps.template'
import { apiCaller } from '../../../js/helpers/api-caller'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import BookingStepsTabsDataMapper from './data-mapper'
import { getParamsFromURLForBookingSteps } from '../../../js/document/url'

const widgetApi = 'w-booking-steps-tabs'

const widgetQueries = {
  tabs: `[data-${widgetApi}__tab]`
}

const attr = {
  stepsVariant: 'wBookingStepsTabs-Variant',
  url: 'url'
}

export default class BookingStepsTabs extends BookingItem {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   */
  constructor (element) {
    super(element)
    this.element = element
    this.url = this.element.dataset[attr.url]
    this.stepsVariant = this.element.dataset[attr.stepsVariant]
    this.dataMapper = new BookingStepsTabsDataMapper()
    this.currentParams = { ...getParamsFromURLForBookingSteps() }
    this._fetchTabs()
  }

  _fillUrlTabs () {
    Array.from(document.querySelectorAll(`${widgetQueries.tabs}`))
      .forEach((tab) => {
        const link = tab.querySelector('a')
        if (link.href !== '' && link.href.indexOf('?') === -1) { link.href = link.href + window.location.search }
      })
  }

  async handleFetched (data) {
    this._fillUrlTabs() // TODO This line can be removed after relase of 2021-03-29
    return super.handleFetched(data ? data.response : null)
  }

  async _fetchTabs () {
    if (this.url) {
      await this._updateWidgetData()
      this._fillUrlTabs()
    }
  }

  async _updateWidgetData () {
    const result = await apiCaller(this._enrichUrlWithDefaultQueryString(this.url))

    if (result.success) {
      this.apiData = result.response
      if (this.apiData && this.apiData.bookingStepsTabs) {
        this.bookingStepsTabs = this.dataMapper.mapWidgetData(this.apiData.bookingStepsTabs, this.stepsVariant)
      }
      if (this.bookingStepsTabs) {
        const html = StepsTemplate(Object.assign({}, defaultStepsTemplate, this.bookingStepsTabs))
        const content = elementFromString(html)
        Component.initDocumentComponentsFromAPI(content)
        this._render(content)
      } else {
        super.hideComponent()
      }
    } else {
      super.hideComponent()
    }
  }

  _render (content) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(content)
  }

  async handleError (errorData) {
    this._fillUrlTabs()
  }

  /**
   * It adds the default params to the url, if the given url already contains params,
   * default params are appended at the end
   *
   * @returns {string} url
   */
  _enrichUrlWithDefaultQueryString (url, params) {
    url = url || ''

    const urlData = {
      ...params,
      ...this.currentParams,
      ...{
        contextItemId: this.componentId
      }
    }

    const queryString = Object.keys(urlData).map(key => key + '=' + urlData[key]).join('&')
    const andOrQuestionMark = url.includes('?') ? '&' : '?'
    return `${url}${andOrQuestionMark}${queryString}`
  }
}

registerWidget(BookingStepsTabs, widgetApi)

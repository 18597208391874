/**
 * The SwipeButtonData contains all data needed to hydrate a Swipe Button selector view.
 *
 * @typedef {Object}     SwipeButtonData
 *
 * @property {String}    direction    - Direction of the chevron, either left or right.
 * @property {boolean}   disabled     - If true, it will appear disabled.
 */

export const defaultSwipeButtonData = {
  direction: 'left',
  disabled: false
}

export const SwipeButtonTemplate = (d) => {
  d = { ...defaultSwipeButtonData, ...d }

  return `<button class="o-swipe-btn o-swipe-btn--${d.direction} ${d.disabled ? 'is-disabled' : ''}">
  <span class="m-icon--chevron-${d.direction}"></span>
</button>
`
}

import { getUrlFromString } from '../../../js/document/url'
import { apiCaller } from '../../../js/helpers/api-caller'
import { registerWidget } from '../../../js/core/widget/widget-directory'

const widgetApi = 'w-rebooking-options'

const attr = {
  url: `data-${widgetApi}__url`,
  method: `data-${widgetApi}__method`,
  bookingNumber: `data-${widgetApi}__booking-number`
}

const widgetQueries = {
  rebookingOptionsModal: `[data-${widgetApi}__modal]`,
  modalApi: 'c-modal'
}

export default class RebookingOptions {
  /**
   * Creates a new RebookingOptions
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element
   * @param options
   */
  constructor (element, options = {}) {
    if (!element) { return }
    this.element = element
    this.options = options

    this._getHtmlElements()
    this._attachEvents()
  }

  _getHtmlElements () {
    this.url = this.options.url || this.element.getAttribute(attr.url)
    this.method = this.options.method || this.element.getAttribute(attr.method)
    this.bookingNumber = this.element.getAttribute(attr.bookingNumber)
    this.modal = this.element.querySelector(widgetQueries.rebookingOptionsModal)
  }

  _attachEvents () {
    this.modal[widgetQueries.modalApi].events.on('opened', () => {
      this._selectBookingToRebook()
    })
  }

  async _selectBookingToRebook () {
    const data = {
      bookingNumber: this.bookingNumber
    }

    if (this.url) {
      this._asyncCall(data)
    }
  }

  async _asyncCall (data) {
    const url = getUrlFromString(this.url)

    const options = {
      ...(this.method) && { method: this.method },
      ...(this.method && this.method.toUpperCase() === 'PATCH' && { body: data })
    }

    const result = await apiCaller(url.href, options)

    return result
  }
}

registerWidget(RebookingOptions, widgetApi)

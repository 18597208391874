import { registerWidget } from '../../../js/core/widget/widget-directory'
import { apiCaller } from '../../../js/helpers/api-caller'

export const widgetApi = 'w-delete-account'

export const attr = {
  method: `data-${widgetApi}__method`,
  url: `data-${widgetApi}__url`
}

export default class DeleteAccount {
  /**
   * Creates a new DeleteAccount
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element
   * @param options
   */
  constructor (element, options = {}) {
    if (!element) { return }
    this.element = element
    this.options = {
      method: this.element.getAttribute(attr.method),
      url: this.element.getAttribute(attr.url),
      ...options
    }
    this.fetchAndUpdate()
  }

  async fetchAndUpdate () {
    const { response, success } = await apiCaller(this.options.url, { method: this.options.method })
    if (success && response.isAccountDeletable) this._displayElement()
  }

  _displayElement () {
    this.element.classList.remove('is-hidden')
  }
}

registerWidget(DeleteAccount, widgetApi)

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import JSONFetcher from '../../../../shared/js/helpers/json-fetcher'
import FilterModel from '../../../../shared/js/data/filters/filter-model'
import PriceFilter from '../../../../shared/modules/widgets/price-filter/main'
import { FILTER_TYPES } from '../../../../shared/js/data/filters/config'

loadQueueHelper.add(function () {
  initRangeFilter('my-price-filter')
}, 2)

document.addEventListener('razor-load-complete', function () {
  initRangeFilter('my-price-filter-razor')
})

const initRangeFilter = (elementId) => {
  const rangeFilterElement = document.getElementById(elementId)
  if (!rangeFilterElement) return
  console.log('Price Filter Element found', rangeFilterElement)

  const quickSearchRequest = new JSONFetcher({ src: '../assets/api/quick-search.json' })
  quickSearchRequest
    .fetch()
    .then(data => {
      const filterData = data.filters.find(filter => filter.type === FILTER_TYPES.PRICE_PER_PERSON)
      const filterModel = new FilterModel(filterData)

      console.log('Price filter Data Model initialized', filterModel)

      const rangeFilter = new PriceFilter(rangeFilterElement, filterModel)
      console.log('Price filter initialized', rangeFilter)
    })
}

import { BookingFilterableServicesFilterTemplateRenderer } from './w-booking-filterable-services.template'
import { TabsNavTemplate } from '../../components/tabs/c-tabs__nav.template'
import { TabsContentWrapperTemplate } from '../../components/tabs/c-tabs__content-wrapper.template'

export const BookingFilterableServicesTabFilterTemplate = (filter, filters, service, configurations) => {
  return `
  <div class="w-booking-filterable-services__filter w-booking-filterable-services__filter-${filter.columnName}">
        ${TabsNavTemplate({
          id: filter.uid,
          extraClasses: 'w-booking-filterable-services__tabs__nav',
          swipeable: true,
          items: filter.values.map(value => ({
            text: MapFilterItemText(value, filter.texts),
            isOpen: value === filter.selectedValue,
            id: value,
            attributes: {
              'data-value': value
            }
          })),
          attributes: {
            'data-w-booking-filterable-services__service-id': service.uid,
            'data-w-booking-filterable-services__filter-id': filter.uid,
            'data-w-booking-filterable-services__filter-type': filter.columnName
          }
        })}
      ${TabsContentWrapperTemplate(
        {
          id: filter.uid,
          componentId: service.uid,
          html: CreateItemsDivs(filter),
          attributes: {
            'data-w-booking-filterable-services__filters-tab-nav': '',
            'data-w-booking-filterable-services__filter-type': filter.columnName
          }
        })}
  ${BookingFilterableServicesFilterTemplateRenderer(filters, service, configurations)}
  </div>`
}

const CreateItemsDivs = (filter) => {
  return filter.values.map(value => {
    return `<div id="${value}" class="c-tabs__content ${filter.selectedValue === value ? 'is-open' : ''}" style="display: none;"></div>`
  }).join('')
}

const MapFilterItemText = (filterValue, texts = null) => {
  if (!texts) { return filterValue }
  const textToBeReplaced = texts.find(text => text.key === filterValue)
  return (textToBeReplaced && textToBeReplaced.value) || filterValue
}

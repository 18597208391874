/**
 * The ActionableListRoomData contains all data needed to hydrate the view
 *
 * @typedef {Object}          ActionableListRoomData
 *
 * @property {Object}        labelConfig          - Label config
 * @property {PriceData}     priceConfig          - Price config
 *
 */

import { defaultPriceData, PriceTemplate } from '../../price/c-price.template'

export const defaultActionableListRoomData = {
  priceConfig: {
    extraClasses: 'c-actionable-list__item-room__price'
  }
}

export const ActionableListRoomTemplate = (d) => {
  d = { ...defaultActionableListRoomData, ...d }
  return `<button class="m-button--clean c-actionable-list__item-room" ${d.item.isDisabled ? 'disabled' : ''}>
     <span class="c-actionable-list__item-room__details">
      ${d.item.stock === 1
        ? ` <span class="c-actionable-list__item-room__stock">${d.config.lastRoomAvailable}</span>`
      : (d.item.stock <= d.config.roomStockThresholdToShowLowAvailability)
        ? `<span class="c-actionable-list__item-room__stock">${d.config.roomsLeftAvailable.replace('{n}', d.item.stock)}</span>`
        : ''}
      ${d.item.onRequest && d.config.onRequestLabel ? `<span class="c-actionable-list__item-room__onrequest">${d.config.onRequestLabel}</span>` : ''}
      ${d.item.title ? `<span data-field="title">${d.item.title}</span>` : ''}
      ${d.item.description ? `<span data-field="description">${d.item.description}</span>` : ''}
     </span>
     ${d.item.price
    ? `${PriceTemplate(
      {
        ...defaultPriceData,
        ...d.priceConfig,
        ...d.config.priceDefaults,
        value: d.item.price
      })}`
    : ''}
</button>`
}

import AdyenCheckout from '@adyen/adyen-web'
import { apiCaller } from '../../../../js/helpers/api-caller'
import { resultCodes } from './adyen-constants'
import { paymentEvents } from '../../../../js/document/event-types'

const DEFAULT_OPTIONS = {
  createParams: {},
  initiatePaymentUrl: '',
  submitAdditionalDetailsUrl: '',
  paymentMethodBrand: '',
  extraParams: {},
  bookingNumber: 0,
  parent: null
}

export default class AdyenImplementation {
  constructor (element, options = {}) {
    this.element = element
    this.options = {
      ...DEFAULT_OPTIONS,
      ...options
    }
    this.events = options.events

    this.onSubmit = this._onSubmitHandler.bind(this)
    this.onAdditionalDetails = this._onAdditionalDetailsHandler.bind(this)
  }

  async initCheckout () {
    this.checkout = await AdyenCheckout({
      ...this.options.createParams,
      onAdditionalDetails: this.onAdditionalDetails,
      onSubmit: this.onSubmit
    })

    this.checkoutCreated = this.checkout.create(this.options.paymentMethodBrand, { onConfigSuccess: this.onConfigSuccess.bind(this) }).mount(this.element)

    if (this.options.paymentMethodBrand !== 'scheme') { this.onConfigSuccess() }
  }

  onConfigSuccess () {
    this.events.emit(paymentEvents.CONFIG_LOADED)
  }

  // Remove the method if paymentType change
  unmountMethod () {
    if (!this.checkoutCreated) { return }
    this.checkoutCreated.remove()
  }

  _onSubmitHandler (state, component) {
    component.setStatus('loading')
    if (state.isValid) {
      const prepareData = {
        bookingNumber: this.options.bookingNumber,
        paymentMethodBrand: this.options.paymentMethodBrand,
        paymentOption: this.options.paymentOption,
        fullPaymentAmount: this.options.parent.fullPaymentAmount,
        downPaymentAmount: this.options.parent.downPaymentAmount,
        partialPaymentAmount: this.options.parent.partialPaymentAmount,
        groupUserId: this.options.parent.groupUserId,
        contextitemid: this.options.parent.componentId,
        referrerUrl: window.location.href,
        adyenPaymentObject: JSON.stringify(state.data),
        ...this.options.extraParams
      }
      const eventData = {
        paymentMethod: this.options.paymentMethodBrand,
        paymentType: this.options.paymentOption
      }
      this.events.emit(paymentEvents.ON_SUBMIT, eventData)
      this._handleSubmission(prepareData, component, this.options.initiatePaymentUrl)
    }
  }

  _onAdditionalDetailsHandler (state, component) {
    const prepareData = { adyenAdditionalDetails: JSON.stringify(state.data) }
    this._handleSubmission(prepareData, component, this.options.submitAdditionalDetailsUrl)
  }

  async _handleSubmission (prepareData, component, url) {
    const res = await apiCaller(url, { method: 'POST', body: prepareData })
    if (res.success) {
      const response = typeof res.response.adyenPaymentResponse === 'string' ? JSON.parse(res.response.adyenPaymentResponse) : res.response.adyenPaymentResponse
      this._handleResponse(response, component)
    }
    if (!res.success) {
      this.events.emit(paymentEvents.PAYMENT_ERROR)
      this._logError(res.error)
    }
  }

  _handleResponse (paymentResponse, component) {
    if (paymentResponse.action) {
      component.handleAction(paymentResponse.action)
    } else {
      switch (paymentResponse.resultCode) {
        case resultCodes.AUTHORISED:
          this.events.emit(paymentEvents.PAYMENT_SUCCESS)
          break
        case resultCodes.PENDING:
        case resultCodes.RECEIVED:
          this.events.emit(paymentEvents.PAYMENT_PENDING)
          break
        case resultCodes.REFUSED:
          this.events.emit(paymentEvents.PAYMENT_FAILED)
          break
        default:
          this.events.emit(paymentEvents.PAYMENT_ERROR, paymentResponse.resultCode)
          break
      }
    }
  }

  _logError (err) {
    if (window.newrelic) {
      window.newrelic.noticeError(err)
    }
  }
}

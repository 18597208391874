import { profileFormTypes } from './profile-form-types'
import { apiCaller } from '../../../js/helpers/api-caller'

const widgetApi = 'w-profile-data'

const attr = {
  postUrl: `data-${widgetApi}__post-url`,
  postMethod: 'POST'
}

const queriesProfileDataForm = {
  firstNameInput: `[data-${widgetApi}__firstname-input]`,
  lastNameInput: `[data-${widgetApi}__lastname-input]`,
  dateOfBirthInput: `[data-${widgetApi}__date-of-birth-input]`
}

export default class FormManager {
  /**
    * Creates a new FormManager
    *
    * @constructor
    *
    * @param {HTMLElement} element - The HTML widget element
    * @param options
    */
  constructor (element, options = {}) {
    this.element = element
    this.options = {
      postUrl: this.element.getAttribute(attr.postUrl),
      ...options
    }

    this._getHtmlElements()
  }

  /**
    * Get the necessary elements from the html
    */
  _getHtmlElements () {
    this.firstNameInput = this.element.querySelector(queriesProfileDataForm.firstNameInput)
    this.lastNameInput = this.element.querySelector(queriesProfileDataForm.lastNameInput)
    this.dateOfBirthInput = this.element.querySelector(queriesProfileDataForm.dateOfBirthInput)
  }

  /**
    * Edit profile data
    */
  async _editProfileData (body) {
    const { success, response } = await apiCaller(this.options.postUrl, { method: attr.postMethod, body })
    return { success, updatedValues: response }
  }

  /**
    * Set current values to name form inputs
    */
  _fillNameFormValues (profileData) {
    this.firstNameInput.value = profileData.firstName || ''
    this.lastNameInput.value = profileData.lastName || ''
  }

  /**
    * Set current value to birth date form input
    */
  _fillBirthDateFormValues (birthDate) {
    if (!birthDate) return
    this.dateOfBirthInput['c-date-selector'].setProp('date', birthDate)
  }

  /**
    * Get the request body when editing a field
    */
  _getRequestBody (formType) {
    switch (formType) {
      case profileFormTypes.NAME:
        return {
          firstName: this.firstNameInput.value,
          lastName: this.lastNameInput.value
        }
      case profileFormTypes.BIRTHDATE: {
        const birthDate = this.dateOfBirthInput['c-date-selector'].getProp('date')
        if (!birthDate) return
        return { birthDate }
      }
    }
  }

  /**
    * Edit field
    */
  async editField (formType) {
    const requestBody = this._getRequestBody(formType)
    return await this._editProfileData(requestBody)
  }

  /**
    * Set current values to name form inputs according to form type
    */
  fillInputValues (formType, profileData) {
    switch (formType) {
      case profileFormTypes.NAME:
        this._fillNameFormValues(profileData)
        break
      case profileFormTypes.BIRTHDATE:
        this._fillBirthDateFormValues(profileData.birthDateUnformatted)
        break
    }
  }
}

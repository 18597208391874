import { PriceTemplate } from '../../../components/price/c-price.template'

export const defaultBookingCancellationFlowPricesData = {
  showPrices: false,
  text: '',
  overviewTitle: '',
  overviewCancellationCostText: '',
  overviewPercentageAmountText: '',
  overviewAmountPaidText: '',
  overviewAmountToPayText: '',
  overviewDueDateText: '',
  overviewFooter: '',
  paymentDueDate: ''
}

export const BookingCancellationFlowPricesTemplate = (d) => {
  d = { ...defaultBookingCancellationFlowPricesData, ...d }

  return `
  <div>

    ${d.text}
    ${d.overviewTitle}

    <div class="w-booking-cancellation-flow__prices-summary">

        <div class="w-booking-cancellation-flow__prices-wrapper">
          <div>
            ${d.overviewCancellationCostText}
          </div>
          <div>
            ${PriceTemplate({
              value: d.cost.formattedAmount,
              currency: d.cost.currencySymbol,
              currencyPosition: d.cost.currencyPosition,
              label: d.overviewPercentageAmountText
            })}
          </div>

          <div>
            ${d.overviewAmountPaidText}
          </div>
          <div>
            <div class="c-label c-label--success cs-state-success c-label--badge">
            ${d.amountAlreadyPaid}
            </div>
          </div>
        </div>

        <div class="w-booking-cancellation-flow__separator"></div>

        <div class="w-booking-cancellation-flow__prices-wrapper w-booking-cancellation-flow__prices-wrapper--bold">
          <div class="w-booking-cancellation-flow__amount-to-pay-text u-mb--none">
            ${d.overviewAmountToPayText}
          </div>

          ${PriceTemplate({
            value: d.amountToPay.formattedAmount.replace('-', ''),
            currency: d.amountToPay.currencySymbol,
            currencyPosition: d.amountToPay.currencyPosition,
            extraClasses: 'w-booking-cancellation-flow__amount-to-pay-value u-mb--none'
          })}
        </div>
        ${(parseInt(d.amountToPay.formattedAmount) >= 0
          ? `<div class="w-booking-cancellation-flow__prices-summary__support-item u-mb--none">
            ${d.overviewDueDateText} ${d.paymentDueDate}
          </div>`
        : '')}
    </div>

    ${d.overviewFooter}

  </div>`
}

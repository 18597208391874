/**
 * The BookingGateMessagesTemplateData contains all data organized to be easily displayed in a booking gate
 *
 * @typedef {Object}          BookingGateMessagesTemplateData
 *
 * @property {Object[]}        messages              - Possible messages
 */

import { AbsoluteDialogTemplate } from '../../components/absolute-dialog/c-absolute-dialog.template'

const MessageAlertDictionary = {
  success: 'success',
  warning: 'warning',
  info: 'info',
  error: 'danger'
}

export const defaultBookingGateMessagesData = {
  messages: [
    {
      texts: 'Info',
      level: 'Information'
    },
    {
      texts: 'Error',
      level: 'Error'
    }
  ]
}

/**
 * Returns an html that will wrap Price Table messages elements
 *
 * @param {BookingGateMessagesTemplateData} d
 *
 * @returns {String} Booking Gate messages HTML string
 */
export const BookingGateMessagesTemplate = (d) => {
  d = { ...defaultBookingGateMessagesData, ...d }

  return d.messages
    ? d.messages.map((message) => AbsoluteDialogTemplate({
      body: message.texts.join(', '),
      alertType: MessageAlertDictionary[message.level],
      closable: false
    })
    ).join('')
    : ''
}

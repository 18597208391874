import { elementFromString, flush } from '../../../../js/document/html-helper'
import Component from '../../../../js/core/component/component'
import { widgetQueries } from '../payment-constants'
import { PaymentVoucherRedeemerTemplate } from './w-payment-voucher-redeemer.template'
import VoucherRedeemerWidget from '../../voucher-redeemer/main'
import { paymentEvents } from '../../../../js/document/event-types'

const DEFAULT_OPTIONS = {
  locales: {},
  url: '',
  componentId: '',
  method: 'POST',
  track: '',
  showCancelButton: false
}

export default class VoucherRedeemer {
  constructor (element, options = {}) {
    this.element = element
    this.options = {
      ...DEFAULT_OPTIONS,
      ...options
    }
    this.locales = options.locales || {}
    this.events = options.events

    this._render()
  }

  _render () {
    if (this.element) {
      const html = PaymentVoucherRedeemerTemplate({
        ...this.locales
      })
      const newContent = elementFromString(html)
      Component.initDocumentComponentsFromAPI(newContent)
      flush(this.element)
      this.element.appendChild(newContent)

      const voucherRedeemerElement = this.element.querySelector(widgetQueries.voucherRedeemer)
      if (voucherRedeemerElement) {
        this.voucherRedeemerApi = new VoucherRedeemerWidget(voucherRedeemerElement, {
          data: {
            jsApi: true,
            track: this.options.track,
            attributes: {
              'data-url': this.options.url,
              'data-method': this.options.method,
              'data-component-id': this.options.componentId,
              'data-w-payment__voucher-redeemer': ''
            },
            showCancelButton: this.options.showCancelButton,
            ...this.locales.voucherCodeLabel && { voucherCodeLabel: this.locales.voucherCodeLabel },
            ...this.locales.voucherCodePlaceholder && { voucherCodePlaceholder: this.locales.voucherCodePlaceholder },
            ...this.locales.voucherCodePattern && { voucherCodePattern: this.locales.voucherCodePattern },
            ...this.locales.voucherCodeWarningRequiredMessage && { voucherCodeWarningRequiredMessage: this.locales.voucherCodeWarningRequiredMessage },
            ...this.locales.voucherCodeWarningInvalidMessage && { voucherCodeWarningInvalidMessage: this.locales.voucherCodeWarningInvalidMessage },
            ...this.locales.emailLabel && { emailLabel: this.locales.emailLabel },
            ...this.locales.emailPlaceholder && { emailPlaceholder: this.locales.emailPlaceholder },
            ...this.locales.emailPattern && { emailPattern: this.locales.emailPattern },
            ...this.locales.emailWarningRequiredMessage && { emailWarningRequiredMessage: this.locales.emailWarningRequiredMessage },
            ...this.locales.emailWarningInvalidMessage && { emailWarningInvalidMessage: this.locales.emailWarningInvalidMessage },
            ...this.locales.birthdateLabel && { birthdateLabel: this.locales.birthdateLabel },
            ...this.locales.birthdateWarningRequiredMessage && { birthdateWarningRequiredMessage: this.locales.birthdateWarningRequiredMessage },
            ...this.locales.birthdateWarningInvalidMessage && { birthdateWarningInvalidMessage: this.locales.birthdateWarningInvalidMessage },
            ...this.locales.submitButtonText && { submitButtonText: this.locales.submitButtonText },
            ...this.locales.submitButtonCheckingText && { submitButtonCheckingText: this.locales.submitButtonCheckingText },
            ...this.locales.cancelButtonText && { cancelButtonText: this.locales.cancelButtonText },
            ...this.locales.errorMessage && { errorMessage: this.locales.errorMessage }
          }
        })
        this.voucherRedeemerApi && this.voucherRedeemerApi.events.on('voucherFetched', this._showVoucherSelectionOptions, this)
        this.voucherRedeemerApi && this.voucherRedeemerApi.events.on('canceled', this._cancel, this)
      }
    }
  }

  _showVoucherSelectionOptions (data) {
    if (!data) return
    this.events.emit(paymentEvents.VOUCHER_FETCHED, data)
  }

  _cancel () {
    this.events.emit(paymentEvents.CANCELED)
  }
}

/**
 * The NavListData contains all data needed to hydrate a NavList view
 *
 * @typedef {Object}          NavListData
 *
 * @property {Number}         level
 * @property {Boolean}        multilevel
 * @property {Boolean}        more
 * @property {String}         backText
 * @property {String}         moreText
 * @property {Array}          items
 * @property {String}         [variant]           - Nav's variant
 * @property {String}         [track]              - Nav's track
 * @property {Object}         [attributes]        - Attributes for the nav
 */

import { NavItemTemplate } from './c-nav__item.template'

export const defaultNavListData = {
  level: 1,
  variant: 'default',
  multilevel: false,
  backText: 'Back',
  items: [],
  moreText: 'More',
  more: false
}

export const NavListTemplate = (d) => {
  d = { ...defaultNavListData, ...d }
  const navListData = { multilevel: d.multilevel, variant: d.variant, level: d.level, backText: d.backText }

  return `
    <ul class="c-nav__list ${d.level > 3 ? 'c-nav__list--sibling-children' : ''}"
      data-c-nav__level="${d.level}">
   
      ${(d.variant === 'block' && d.multilevel && d.level > 1 && d.level <= 3)
        ? `${NavItemTemplate(Object.assign({}, { link: { text: d.backText }, isBack: true, level: d.level }))}`
        : ''
      }

      ${d.items.length > 0 && d.items.map((item, index) => {
          return NavItemTemplate(Object.assign({},
            navListData,
            item,
            {
              items: item.items,
              id: `${d.id}-${index}`,
              parentId: d.id,
              more: d.more,
              backText:
              d.backText,
              multilevel:
              d.multilevel,
              moreText: d.moreText
            }
          ))
        }).join('')
      }

      ${(d.more && d.level === 1)
         ? `${NavItemTemplate(Object.assign({},
              {
 variant: d.variant,
                link: { text: d.moreText },
                isMore: true,
                more: d.more,
                backText: d.backText,
                level: d.level,
                multilevel: d.multilevel,
                ...navMoreData(d.items)
              }))}`
         : ''
      }
    </ul>
  `
}

const navMoreData = (navItems) => {
  if (!navItems.length > 0) { return }
  const breakpoints = ['xxs', 'xs', 'sm', 'sml', 'md', 'lg', 'xl', 'xxl', 'xxxl']
  // Select only items with shownOnMoreUntil property
  const items = navItems.filter(i => !!i.shownOnMoreUntil)
  // Find biggest breakpoint of all the items
  const biggestBreakpointIndex = items.reduce((bpIndex, item) => {
    const currentIndex = breakpoints.indexOf(item.shownOnMoreUntil)
    if (currentIndex > bpIndex) {
      bpIndex = currentIndex
    }
    return bpIndex
  }, 0)

  return {
    id: 'nav-more',
    until: breakpoints[biggestBreakpointIndex],
    items: items.map(item => ({
      ...item,
      shownOnMoreFrom: item.shownOnMoreUntil,
      shownOnMoreUntil: undefined
    }))
  }
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingButtonOpenPage from '../../../../shared/modules/widgets/booking-button-open-page/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const bookingButtonOpenPageData = require('../../../../shared/modules/widgets/booking-button-open-page/data/w-booking-button-open-page__js-template')

loadQueueHelper.add(function () {
  initBookingButtonOpenPage()
}, 2)

const initBookingButtonOpenPage = () => {
  const items = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-button-open-page"]')
  items.forEach(element => {
    const bookingButtonOpenPage = new BookingButtonOpenPage(element)
    console.log(bookingButtonOpenPage)
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingButtonOpenPageJsTemplate = document.querySelectorAll('[data-booking-button-open-page-demo]')
  handleFetched(bookingButtonOpenPageJsTemplate, bookingButtonOpenPageData)
}

/**
 * The FlightBusSelectorTemplateData contains all data needed to hydrate a Flight Bus selector view
 *
 * @typedef {Object}          FlightBusSelectorTemplateData
 *
 * @property {String}         id                                - Flight bus selector ID
 * @property {String}         outboundHeading                   - Outbound heading text
 * @property {String}         outboundDepartureDate             - Outbound's departure date
 * @property {String}         outboundArrivalDate               - Outbound's arrival date
 * @property {String}         inboundHeading                    - Inbound heading text
 * @property {String}         inboundDepartureDate              - Inbound's departure date
 * @property {String}         inboundArrivalDate                - Inbound's arrival date
 * @property {String}         unrelatedHeading                  - Unrelated items (no connection between departure and arrival)
 * @property {String}         buttonMoreText
 * @property {String}         buttonLessText
 * @property {String}         buttonCancelText
 * @property {String}         buttonSubmitText
 * @property {Object}         sideDrawer                        - For outbound and inbound buttons/tabs
 * @property {Object}         priceMismatchMessage              - In case there is a price mismatch
 * @property {Object[]}       buttons                           - For outbound and inbound buttons/tabs
 * @property {String}         buttons.text
 * @property {String}         buttons.variant
 * @property {String}         buttons.icon
 * @property {Boolean}        buttons.isOpen
 * @property {Boolean}        flexibleTicket.active             - true if flexible ticket tab is active
 * @property {String}         flexibleTicket.title              - title of the flexible ticket
 * @property {Array}          flexibleTicket.characteristics    - characteristics of the flexible ticket
 * @property {String}         regularTicket.active              - true if regular ticket tab is active
 * @property {Boolean}        regularTicket.title               - title of the regular ticket
 * @property {Array}          regularTicket.characteristics     - characteristics of the regular ticket
 */

import { SideDrawerTemplate } from '../../components/side-drawer/c-side-drawer.template'
import { SideDrawerHeaderTemplate } from '../../components/side-drawer/c-side-drawer__header.template'
import { SideDrawerBodyTemplate } from '../../components/side-drawer/c-side-drawer__body.template'
import { ButtonGroupTemplate } from '../../components/button-group/c-button-group.template'
import { CollapseTemplate } from '../../components/collapse/c-collapse.template'
import { TabsNavTemplate } from '../../components/tabs/c-tabs__nav.template'
import { TabsContentTemplate } from '../../components/tabs/c-tabs__content.template'
import { TabsContentWrapperTemplate } from '../../components/tabs/c-tabs__content-wrapper.template'
import { SideDrawerFooterTemplate } from '../../components/side-drawer/c-side-drawer__footer.template'
import { AbsoluteDialogTemplate } from '../../components/absolute-dialog/c-absolute-dialog.template'
import { SwitchTemplate } from '../../components/switch/c-switch.template'

export const defaultFlightBusSelectorData = {
  id: '',
  title: '[Select your flight]',
  outboundHeading: '[Departure]',
  outboundDepartureDate: '',
  outboundArrivalDate: '',
  inboundHeading: '[Return]',
  inboundDepartureDate: '',
  inboundArrivalDate: '',
  unrelatedHeading: '[Unrelated]',
  buttonMoreText: '[Show more]',
  buttonLessText: '[Show less]',
  buttonCancelText: '[Cancel]',
  buttonSubmitText: '[OK]',
  tabsVariant: '',
  sideDrawer: {
    title: '[Select your]'
  },
  buttons: [{
    text: '[Departure]',
    variant: 'neutral',
    icon: ''
  }, {
    text: '[Return]',
    variant: 'neutral',
    icon: ''
  }],
  flexibleTicket: {
    active: false,
    title: '',
    characteristics: []
  },
  regularTicket: {
    active: false,
    title: '',
    characteristics: []
  },
  typeOfFlights: {
    isCloseJaw: false,
    isOpenJaw: false
  },
  openJawSwitchTitle: '',
  openJawSwitchDescription: '',
  openJawSwitchStatus: true,
  isOpenJawFilteringEnabled: false
}

const HeadingDateTemplate = (heading, departureDate, arrivalDate) => {
  return `
  <div class="w-flight-bus-selector__heading">
    <span class="c-title m-heading m-heading--tiny w-flight-bus-selector__heading-text">${heading}:</span>
    <span class="c-title m-heading m-heading--tiny w-flight-bus-selector__heading-date">${departureDate}</span>
    ${arrivalDate && departureDate !== arrivalDate
      ? `
      <span class="c-title m-heading m-heading--tiny w-flight-bus-selector__heading-date w-flight-bus-selector__heading-date-separator">-</span>
      <span class="c-title m-heading m-heading--tiny w-flight-bus-selector__heading-date w-flight-bus-selector__heading-date-arrival">${arrivalDate}</span>
      `
      : ''}
  </div>
  `
}

export const FlightBusSelectorTemplate = (d, options) => {
  d = { ...defaultFlightBusSelectorData, ...d, ...options }
  d.title = d.headerText
  d.sideDrawer.id = `${d.id}__side-drawer`
  return `
<div id="${d.id}" class="w-flight-bus-selector">
  <div class="w-flight-bus-selector__header-container">
    <h2 class="c-title m-heading m-heading--small w-flight-bus-selector__header-title">${d.title}</h2>
    ${d.isOpenJawFilteringEnabled && d.typeOfFlights.isOpenJaw && d.typeOfFlights.isCloseJaw
      ? SwitchTemplate({
        id: `${d.id}__open-jaw-switch`,
        text: `${d.openJawSwitchTitle}<span class="w-flight-bus-selector__open-jaw-switch-subtitle">${d.openJawSwitchDescription}</span>`,
        extraClasses: 'w-flight-bus-selector__open-jaw-switch',
        checked: d.openJawSwitchStatus,
        dataset: {
          'w-flight-bus-selector__open-jaw': ''
        }
      })
      : ''
    }
  </div>
  ${d.priceMismatchMessage ? AbsoluteDialogTemplate(d.priceMismatchMessage) : ''}
  <div class="w-flight-bus-selector__selected u-hidden--from@sml">
    ${HeadingDateTemplate(d.outboundHeading, d.outboundDepartureDate, d.outboundArrivalDate)}
    <div class="w-flight-bus-selector__selected--outbound"></div>
    ${HeadingDateTemplate(d.inboundHeading, d.inboundDepartureDate, d.inboundArrivalDate)}
    <div class="w-flight-bus-selector__selected--inbound"></div>
    <div class="w-flight-bus-selector__footer">
      <button class="c-btn c-btn--neutral c-btn--block" data-c-side-drawer__action="open" data-c-side-drawer__id="${d.id}__side-drawer">${d.changeTransportButtonText}</button>
    </div>
  </div>
  ${SideDrawerTemplate({
    ...d.sideDrawer,
    html: `${
      SideDrawerHeaderTemplate(d.sideDrawer) +
      SideDrawerBodyTemplate({
        html: `${

          ButtonGroupTemplate({
            extraClasses: 'w-flight-bus-selector__tabs-nav u-hidden--from@sml',
            html: `${d.buttons.map((button, index) => `
              <button type="button" class="c-btn c-btn--${button.variant}" data-c-tabs__id="${d.id}__tabs" data-c-tabs__tab-id="${d.id}__${index === 0 ? 'departure' : 'arrival'}-tab" data-c-tabs__action="showTab">
                <i class="c-btn__icon c-btn__icon--left ${button.icon ? `m-icon--${button.icon}` : ''}"></i><span class="c-btn__text">${button.text}</span>
              </button>`).join('')
            }`
          }) +
          CollapseTemplate({
            id: `${d.id}__collapse`,
            extraClasses: 'w-flight-bus-selector__collapse',
            html: `${
              TabsContentWrapperTemplate({
                id: `${d.id}__tabs`,
                html: `${
                  TabsContentTemplate({
                    id: `${d.id}__departure-tab`,
                    isOpen: true,
                    extraClasses: 'w-flight-bus-selector__departure-tab',
                    html: `
                              ${HeadingDateTemplate(d.outboundHeading, d.outboundDepartureDate, d.outboundArrivalDate)}
                              <div class="w-flight-bus-selector__options"></div>
                            `
                  }) +
                TabsContentTemplate({
                  id: `${d.id}__arrival-tab`,
                  extraClasses: 'w-flight-bus-selector__arrival-tab',
                  html: `
                            ${HeadingDateTemplate(d.inboundHeading, d.inboundDepartureDate, d.inboundArrivalDate)}
                            <div class="w-flight-bus-selector__options"></div>
                            <div class="w-flight-bus-selector__heading--unrelated">
                              <span class="c-title m-heading m-heading--tiny w-flight-bus-selector__heading-text">${d.unrelatedHeading}</span>
                            </div>
                            <div class="w-flight-bus-selector__options--unrelated is-disabled"></div>
                          `
                })
                }`
              })
            }`
          }) + `
          <button type="button"
                      class="c-btn c-btn--default c-btn--block w-flight-bus-selector__collapse-btn"
                      data-c-collapse__id="${d.id}__collapse"
                      data-c-collapse__action="open">
              <span class="c-btn__text">${d.buttonMoreText}</span><i class="c-btn__icon c-btn__icon--right m-icon--chevron-down"></i>
          </button>
          <button type="button"
                      class="c-btn c-btn--default c-btn--block w-flight-bus-selector__collapse-btn"
                      data-c-collapse__id="${d.id}__collapse"
                      data-c-collapse__action="close">
              <span class="c-btn__text">${d.buttonLessText}</span><i class="c-btn__icon c-btn__icon--right m-icon--chevron-up"></i>
          </button>`
          }`
      }) +
      SideDrawerFooterTemplate({
        html: `
          <button type="button" class="c-btn c-btn--flat-neutral w-flight-bus-selector__cancel" data-c-side-drawer__action="close" data-c-side-drawer__id="${d.id}__side-drawer">
            <span class="c-btn__text">${d.buttonCancelText}</span>
          </button><button type="button" class="c-btn c-btn--default w-flight-bus-selector__submit" data-c-side-drawer__action="close" data-c-side-drawer__id="${d.id}__side-drawer">
          <span class="c-btn__text">${d.buttonSubmitText}</span></button>
        `
      })
    }`
  })}

  ${d.extraInfo
    ? `<div class="w-flight-bus-selector__extra-info">
        ${d.extraInfo}
      </div>`
    : ''}

  ${TabsNavTemplate({
    id: `${d.id}__tabs-flexible-regular-flights`,
    variant: 'secondary',
    extraClasses: 'w-flight-bus-selector__flexible-regular-flights-tabs',
    items: [
      {
        id: `${d.id}__tab-flexible-flights`,
        text: d.flexibleTicket.title,
        isOpen: d.flexibleTicket.active,
        icon: 'airplane-flexible',
        extraClasses: d.flexibleTicket.active ? '' : 'is-hidden'
      },
      {
        id: `${d.id}__tab-regular-flights`,
        text: d.regularTicket.title,
        isOpen: !d.flexibleTicket.active,
        icon: 'airplane',
        extraClasses: d.regularTicket.active ? '' : 'is-hidden'
      }
    ]
  })
  }
  ${TabsContentWrapperTemplate({
    id: `${d.id}__tabs-flexible-regular-flights`,
    extraClasses: 'w-flight-bus-selector__flexible-regular-flights-tabs',
    html: `
    ${
      TabsContentTemplate({
        id: `${d.id}__tab-flexible-flights`,
        isOpen: d.flexibleTicket.active,
        extraClasses: `${d.flexibleTicket.active ? '' : 'is-hidden'} sp-stack-children--large`,
        html: `
          <ul class="sp-stack-children--tiny">
            ${d.flexibleTicket.characteristics.map((characteristic) => `
              <li>${characteristic}</li>
            `).join('')}
          </ul>
        `
      }) +
      TabsContentTemplate({
        id: `${d.id}__tab-regular-flights`,
        isOpen: !d.flexibleTicket.active,
        extraClasses: `${d.regularTicket.active ? '' : 'is-hidden'} sp-stack-children--large`,
        html: `
          <ul class="sp-stack-children--tiny">
          ${d.regularTicket.characteristics.map((characteristic) => `
            <li>${characteristic}</li>
          `).join('')}
          </ul>
        `
      })}`
  })}
</div>
`
}

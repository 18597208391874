import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import JSONFetcher from '../../../../shared/js/helpers/json-fetcher'
import FilterModel from '../../../../shared/js/data/filters/filter-model'
import { FILTER_TYPES } from '../../../../shared/js/data/filters/config'
import ParticipantsFilter from '../../../../shared/modules/widgets/participants-filter/main'

loadQueueHelper.add(function () {
  initParticipantsFilter('my-participants-filter__hbs')
  initParticipantsFilter('my-participants-filter__razor')
  initParticipantsFilter('my-participants-filter__hbs--multi-room')
  initParticipantsFilter('my-participants-filter__razor--multi-room')
  initParticipantsFilter('my-participants-filter__razor--flight-only')
}, 2)

const initParticipantsFilter = (participantFilterId) => {
  const participantsFilterElement = document.getElementById(participantFilterId)
  const isMultiRoom = participantFilterId.indexOf('multi-room') >= 0
  if (!participantsFilterElement) return

  if (participantsFilterElement) {
    console.log('Participants Filter Element found', participantsFilterElement)
  }

  const quickSearchRequest = new JSONFetcher({ src: '../assets/api/quick-search.json' })
  quickSearchRequest
    .fetch()
    .then(data => {
      const filterModels = {
        participants: new FilterModel(data.filters.find(({ type }) => type === FILTER_TYPES.PARTICIPANTS)),
        allocation: isMultiRoom
          ? undefined
          : new FilterModel(data.filters.find(({ type }) => type === FILTER_TYPES.ALLOCATION))
      }
      console.log('Participants Data Model initialized', filterModels)

      const participantsFilter = new ParticipantsFilter(participantsFilterElement, filterModels)
      console.log('Participants initialized', participantsFilter)
    })
}

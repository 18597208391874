import { registerWidget } from '../../../js/core/widget/widget-directory'
import BookingItem from '../booking-item/main'
import registeredEvents from '../../../js/helpers/registered-events'
import { bookingEcommerceAnalyticsEvents } from '../../../js/document/event-types'

const widgetApi = 'w-booking-ecommerce-analytics'

const attr = {
  track: 'data-track'
}

/**
 * BookingEcommerceAnalytics widget
 */
export default class BookingEcommerceAnalytics extends BookingItem {
  constructor (element) {
    super(element)
    this.element = element
    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })
  }

  /**
   *
   * @param {Object}    data      - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  _updateWidgetData () {
    const apiData = this.data.eCommerceAnalytics
    if (apiData && apiData.event) {
      this.events.emit(bookingEcommerceAnalyticsEvents.BOOKINGS_ECOMMERCE_ANALYTICS, apiData)
    }
  }
}

registerWidget(BookingEcommerceAnalytics, widgetApi)

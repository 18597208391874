import { componentTypeEnum, renderTypeEnum } from './enums'
import { CalculateServicePrice } from './service-helper'
import PriceFormatter from '../../../js/helpers/price-formatter'

export default class BookingFilterableServicesDataMapper {
  /**
   * Maps API response data to widget data
   *
   * @typedef {Object}         BookingFilterableServicesData
   *
   * @property {Object}        [apiResponseData]    - Api response data
   * @property {Object}        [servicesData]       - Configured services (in cshtml view)
   * @property {Object}        [texts]              - Translations
   * @property {Object}        [configurations]     - Configurations from cshtml
   */
  mapWidgetData (apiResponseData, servicesData, texts, configurations) {
    const componentType = configurations.componentType
    const apiRooms = this._checkRenderTypePerParticipant(componentType) ? apiResponseData.rooms : apiResponseData.rooms.slice(0, 1)
    const componentId = apiResponseData.componentId
    const widgetData = {
      texts,
      configurations,
      rooms: apiRooms
        ? apiRooms.map(apiRoom => {
          return this._mapRoom(apiRoom, apiResponseData, componentId, servicesData, componentType, configurations)
        })
        : ''
    }

    const rows = []
    const emptyRows = []
    const filterDefaultValuesPerParticipant = []
    if (widgetData.rooms) {
      widgetData.rooms.forEach(room => {
        room.participants.forEach(participant => {
          participant.services.forEach(service => {
            if (service.options.length > 0) {
              const filters = []
              service.filters.forEach(filter => {
                filters[filter.uid] = {
                  columnName: filter.columnName,
                  values: filter.values,
                  texts: filter.texts
                }
              }, this)

              const row = {
                uid: service.uid,
                filters,
                options: service.options,
                selectedOption: service.selectedOption,
                noneOption: service.noneOption,
                participantId: participant.id
              }
              rows[row.uid] = row
            } else {
              emptyRows.push(service.uid)
            }
          }, this)

          const participantFilters = participant.services.reduce(function (accumulator, currentValue) {
            return [...accumulator, ...currentValue.filters]
          }, [])
          const filterSelectedDefaultValue = participantFilters.find(filter => filter.definesDefaultValue)
          if (filterSelectedDefaultValue) {
            filterDefaultValuesPerParticipant.push({
              participantId: participant.id,
              columnName: filterSelectedDefaultValue.columnName,
              defaultValue: filterSelectedDefaultValue.selectedValue,
              startDateDefaultValue: filterSelectedDefaultValue.selectedStartDateValue
            })
          }
        }, this)
      }, this)
      widgetData.rows = rows
      widgetData.emptyRows = emptyRows
      widgetData.filterDefaultValuesPerParticipant = filterDefaultValuesPerParticipant
    }

    return widgetData
  }

  /**
   * Maps API response options to ChoiceListTemplate items model
   */
  mapOptions (apiOptions, selectedOption, noneOption, participantId, configurations, previousSelection) {
    const selectedOptionPrice = selectedOption ? selectedOption.price : noneOption ? noneOption.price : 0
    const shouldFilterByPreviousData = previousSelection && previousSelection.startDate
    const options = apiOptions.map(apiOption => {
      const text = apiOption.displayText
      const checked = !shouldFilterByPreviousData && apiOption.isSelected && apiOption.assignedParticipants && apiOption.assignedParticipants.includes(participantId)
      return this._mapOption(apiOption, selectedOptionPrice, apiOption.code, text, checked, null, configurations, participantId)
    })

    if (shouldFilterByPreviousData && options && options.length > 0) {
      let index = selectedOption
        ? options.findIndex(option => option.value === selectedOption.value)
        : options.findIndex(option => option.startDate === previousSelection.startDate)

      index = index === -1 ? 0 : index
      options[index].checked = true
      options[index].assignedParticipants = this._addParticipantToAssignedParticipants(options[index], participantId)
    }
    return options
  }

  mapParticipantsAsOptions (apiOptions, participants, configurations) {
    const options = []

    participants.forEach(participant => {
      apiOptions.forEach(apiOption => {
        if (apiOption.appliesToParticipants.includes(participant.id)) {
          const text = participant.firstName || participant.alternativeName
          const checked = apiOption.assignedParticipants ? apiOption.assignedParticipants.includes(participant.id) : false
          const value = participant.id
          const code = `${apiOption.code}-${Math.floor(Math.random() * 10000)}`
          const option = this._mapOption(apiOption, null, code, text, checked, value, configurations, participant.id)
          options.push(option)
        }
      }, this)
    }, this)
    return options
  }

  _addParticipantToAssignedParticipants (option, participantId) {
    const assignedParticipants = option.assignedParticipants ? option.assignedParticipants : [participantId]
    if (assignedParticipants.indexOf(participantId) < 0) {
      assignedParticipants.push(participantId)
    }
    return assignedParticipants
  }

  /**
   * Maps to ChoiceListTemplate item model
   */
  _mapOption (apiOption, selectedOptionPrice, code, text, checked, value, configurations, participantId = null) {
    const uniqueId = `${apiOption.code}-${apiOption.startDate}-${apiOption.endDate}-${participantId}`
    const isDisabled = (apiOption.disabledParticipants ? apiOption.disabledParticipants.includes(participantId) : false)

    return {
      id: uniqueId,
      name: uniqueId,
      code,
      text,
      checked,
      disabled: isDisabled,
      price: apiOption.price,
      value: value || uniqueId,
      appliesToParticipants: apiOption.appliesToParticipants || null,
      assignedParticipants: apiOption.assignedParticipants || null,
      disabledParticipants: apiOption.disabledParticipants || null,
      additionalText: this._getOptionAdditionalText(apiOption, selectedOptionPrice, configurations.price),
      startDate: apiOption.startDate,
      endDate: apiOption.endDate,
      time: apiOption.time ? apiOption.time : null,
      quantity: apiOption.quantity,
      occupancy: apiOption.occupancy ? apiOption.occupancy : null
    }
  }

  _getOptionAdditionalText (apiOption, selectedOptionPrice, priceConfiguration) {
    const optionPrice = apiOption.price || 0
    const optionCalculatedPrice = selectedOptionPrice ? optionPrice - selectedOptionPrice : optionPrice
    const optionPriceSymbol = optionCalculatedPrice >= 0 ? '+' : '-'
    const additionalText = this._checkIfOptionServiceTypeIsPerParticipant(apiOption)
      ? `${optionPriceSymbol} ${PriceFormatter.toFormattedText(Math.abs(optionCalculatedPrice), priceConfiguration)}`
      : '-'
    return additionalText
  }

  _mapRoom (apiRoom, apiResponseData, componentId, servicesData, componentType, configurations) {
    const participants = this._checkRenderTypePerParticipant(componentType) ? apiResponseData.participants : apiResponseData.participants.slice(0, 1)
    const widgetRoom = {
      id: apiRoom.id,
      uid: this._generateUniqueId(componentId, apiRoom),
      roomName: this._checkRenderTypePerParticipant(componentType) ? apiRoom.name : '',
      participants: participants.filter(participant => apiRoom.participantIds.includes(participant.id) &&
      apiResponseData.options.some(option => option.appliesToParticipants.includes(participant.id))
      ).map(apiParticipant => this._mapParticipant(apiParticipant, apiRoom, apiResponseData, componentId, servicesData, componentType, configurations))
    }
    return widgetRoom
  }

  _mapParticipant (apiParticipant, apiRoom, apiResponseData, componentId, servicesData, componentType, configurations) {
    const apiOptions = apiResponseData.options.filter(apiOption => apiOption.appliesToParticipants.includes(apiParticipant.id)).map(apiOption => ({ ...apiOption, ...{ value: `${apiOption.code}-${apiOption.startDate}-${apiOption.endDate}-${apiParticipant.id}` } }))
    const widgetParticipant = {
      label: this._checkRenderTypePerParticipant(componentType) ? apiParticipant.firstName || apiParticipant.alternativeName : '',
      id: apiParticipant.id,
      ageCategory: apiParticipant.ageCategory,
      uid: this._generateUniqueId(componentId, apiRoom, apiParticipant),
      services: this._mapServices(componentId, apiOptions, apiParticipant, apiRoom, apiResponseData.participants, servicesData, componentType, configurations)
    }
    return widgetParticipant
  }

  _checkRenderTypePerParticipant (componentType) {
    if (this._mapRenderType(componentType) !== renderTypeEnum.PerParticipant) return false
    return true
  }

  _mapServices (componentId, apiOptions, apiParticipant, apiRoom, participants, servicesData, componentType, configurations) {
    const services = []
    servicesData.forEach(serviceData => {
      const options = this._filterOptions(apiOptions, serviceData.baseFilterColumn, serviceData.baseFilterValue)
      let optionsWithoutNoneOption = options.filter(option => !option.isNoneOption)
      let uid = this._generateUniqueId(componentId, apiRoom, apiParticipant, serviceData)
      const serviceTitle = serviceData.serviceTitle
      let selectedOption = options.filter(option => option.isSelected && option.assignedParticipants.includes(apiParticipant.id))[0]
      let noneOption = options.filter(option => option.isNoneOption)[0]

      // If componentType is ParticipantsAsOptions, we need to generate a new service
      // for each option in order to be able to select diferent options
      if (componentType === componentTypeEnum.ParticipantsAsOptions) {
        optionsWithoutNoneOption.forEach(option => {
          uid = `${this._generateUniqueId(componentId, apiRoom, apiParticipant, serviceData)}-${option.id}`
          selectedOption = option.isSelected ? option : null
          noneOption = option.isNoneOption ? option : null
          const newOptions = [option]
          const filters = this._mapFilters(componentId, apiRoom, apiParticipant, serviceData, newOptions, selectedOption, uid)
          const service = this._generateServiceObject({ uid, selectedOption, noneOption, optionsWithoutNoneOption: newOptions, filters, participants, serviceData, serviceTitle: option.displayText, apiParticipant, configurations, componentType })

          services.push(service)
        }, this)
      } else if (componentType === componentTypeEnum.ParticipantsAsOptionsDynamic) {
        // If componentType is ParticipantsAsOptionsDynamic, we need to generate a new service
        // for each selected option and one blank service in order to be able to select more options
        const selectedOptions = optionsWithoutNoneOption.filter(option => option.isSelected)

        // Service for each selected option
        selectedOptions.forEach(option => {
          const uid = `${this._generateUniqueId(componentId, apiRoom, apiParticipant, serviceData)}-${option.id}`
          const selectedOption = option.isSelected ? option : null
          const noneOption = option.isNoneOption ? option : null
          const filters = this._mapFilters(componentId, apiRoom, apiParticipant, serviceData, optionsWithoutNoneOption, selectedOption, uid)
          const service = this._generateServiceObject({ uid, selectedOption, noneOption, optionsWithoutNoneOption, filters, participants, serviceData, serviceTitle: option.displayText, apiParticipant, configurations, componentType })
          services.push(service)
        }, this)

        // Blank Service with all options
        selectedOption = null
        // Unset assignedParticipants in order to start with blank assignation
        optionsWithoutNoneOption = optionsWithoutNoneOption.map(option => ({ ...option, assignedParticipants: [] }))
        const filters = this._mapFilters(componentId, apiRoom, apiParticipant, serviceData, optionsWithoutNoneOption, selectedOption, uid)
        const service = this._generateServiceObject({ uid, selectedOption, noneOption, optionsWithoutNoneOption, filters, participants, serviceData, serviceTitle, apiParticipant, configurations, componentType })
        services.push(service)
      } else {
        selectedOption = selectedOption || null
        const filters = this._mapFilters(componentId, apiRoom, apiParticipant, serviceData, optionsWithoutNoneOption, selectedOption, uid)
        const service = this._generateServiceObject({ uid, selectedOption, noneOption, optionsWithoutNoneOption, filters, participants, serviceData, serviceTitle, apiParticipant, configurations, componentType })
        services.push(service)
      }
    }, this)

    return services
  }

  _generateServiceObject (data) {
    const { uid, selectedOption, noneOption, optionsWithoutNoneOption, filters, participants, serviceData, serviceTitle, apiParticipant, configurations, componentType } = data
    const totalPrice = this._calculateServiceTotalPrice(selectedOption, serviceData.useQuantity, serviceData.showParticipantsAsOptions, apiParticipant)
    const filteredOptions = this.mapOptions(this._getFilteredOptions(optionsWithoutNoneOption, filters), selectedOption, noneOption, apiParticipant.id, configurations)

    const service = {
      uid,
      options: optionsWithoutNoneOption,
      selectedOption,
      noneOption,
      filters,
      filteredOptions,
      canBeUnselected: this._mapCanUnselect({ noneOption, options: optionsWithoutNoneOption, participantId: apiParticipant.id, componentType }),
      title: serviceTitle,
      optionsLabel: serviceData.optionsLabel,
      quantityLabel: serviceData.quantityLabel,
      useQuantity: serviceData.useQuantity,
      maxQuantity: serviceData.maxQuantity,
      addTime: serviceData.addTime,
      dateAcceptButton: serviceData.dateAcceptButton,
      dateCancelButton: serviceData.dateCancelButton,
      durationFilterSuffixText: serviceData.durationFilterSuffixText,
      quantity: selectedOption && selectedOption.quantity ? selectedOption.quantity : 1,
      totalPrice,
      showParticipantsAsOptions: serviceData.showParticipantsAsOptions ? serviceData.showParticipantsAsOptions : false,
      participants: serviceData.showParticipantsAsOptions ? this.mapParticipantsAsOptions(this._getFilteredOptions(optionsWithoutNoneOption, filters), participants, configurations) : []
    }
    return service
  }

  /**
   * perQuantity                    ==> DINNERVOUCHER
   * perParticipant                 ==> SKI ANCILLARIES
   * participantsAsOptions          ==> BRIDGE/EXCURSIONS
   * participantsAsOptionsDynamic   ==> GOLF
   */
  _mapRenderType (componentType) {
    let renderType

    switch (componentType) {
      case componentTypeEnum.ParticipantsAsOptions:
      case componentTypeEnum.ParticipantsAsOptionsDynamic:
      case componentTypeEnum.PerQuantity:
        renderType = renderTypeEnum.PerBooking
        break
      case componentTypeEnum.PerParticipant:
        renderType = renderTypeEnum.PerParticipant
        break
      default:
        renderType = renderTypeEnum.PerParticipant
        break
    }
    return renderType
  }

  /**
   * We are calculating the total price of the service depending if
   * there are a selectedOption and if we are using quantity or participants as options
   **/
  _calculateServiceTotalPrice (selectedOption, useQuantity, showParticipantsAsOptions, apiParticipant) {
    let totalPrice, quantity
    if (selectedOption && selectedOption.totalPrice) {
      totalPrice = selectedOption.totalPrice
    } else if (selectedOption) {
      if (useQuantity) {
        quantity = selectedOption.quantity
      } else if (showParticipantsAsOptions && this._checkIfOptionServiceTypeIsPerParticipant(selectedOption)) {
        quantity = selectedOption.assignedParticipants ? selectedOption.assignedParticipants.length : 0
      } else {
        quantity = 1
      }
      const pricePerParticipant = (selectedOption.prices && selectedOption.prices.find(pricePerParticipant => pricePerParticipant.participantIds && pricePerParticipant.participantIds.includes(apiParticipant.id))) || null
      const optionPrice = pricePerParticipant?.price !== undefined ? pricePerParticipant.price : selectedOption.price || 0
      totalPrice = CalculateServicePrice(quantity, optionPrice)
    } else {
      totalPrice = 0
    }

    return totalPrice
  }

  /**
   * You can unselect if:
   * - Service renderType is PerParticipant (SkiAncillaries)
   *   and the SUM of Checked and disabledOptions is less than the total length of options
   *   OR
   * - Service has a no option
   */
  _mapCanUnselect (attributes) {
    const { noneOption, options, participantId, componentType } = attributes

    let canBeUnselected = true
    if (this._checkRenderTypePerParticipant(componentType)) {
      const disabledOptionsNotChecked = options.filter(option => {
        return option.disabledParticipants
          ? (option.disabledParticipants.includes(participantId) &&
                !(option.assignedParticipants && option.assignedParticipants.includes(participantId) && option.isSelected))
          : false
      })

      const checkedOptions = options.filter(option => (option.isSelected && option.assignedParticipants && option.assignedParticipants.includes(participantId)))
      canBeUnselected = checkedOptions.length + disabledOptionsNotChecked.length !== options.length
    } else {
      const isOptional = !!(canBeUnselected)
      const hasNoneOption = !!(noneOption)
      canBeUnselected = hasNoneOption || isOptional
    }

    return canBeUnselected
  }

  _mapFilters (componentId, apiRoom, apiParticipant, serviceData, serviceOptions, serviceSelectedOption, serviceUid) {
    const filters = []
    serviceData.filters.forEach((filterData, index) => {
      const filterValues = this._distinctOptions(serviceOptions, filterData.columnName)
      const sortedFilterValues = (['materialCategory', 'sportLevel', 'buggy'].includes(filterData.columnName)) ? filterValues : filterValues.sort()
      const filter = {
        columnName: filterData.columnName,
        filterLabel: filterData.filterLabel,
        filterType: filterData.filterType,
        filterIcon: filterData.filterIcon,
        definesDefaultValue: filterData.definesDefaultValue,
        values: sortedFilterValues,
        texts: filterData.texts || null,
        selectedValue: (serviceSelectedOption) ? serviceSelectedOption[filterData.columnName] : sortedFilterValues[0],
        selectedStartDateValue: (serviceSelectedOption) ? serviceSelectedOption.startDate : null,
        uid: serviceSelectedOption ? `${this._generateUniqueId(componentId, apiRoom, apiParticipant, serviceData, { id: index + 1 })}-${serviceSelectedOption.id}` : this._generateUniqueId(componentId, apiRoom, apiParticipant, serviceData, { id: index + 1 }),
        serviceUid
      }

      // Check all possible values of the filter if there is no service selected option
      if (!serviceSelectedOption) {
        for (let i = 0; i < filters.length; i++) {
          filter.selectedValue = sortedFilterValues[i]
          const availableOptionsWithCurrentFilters = this._getFilteredOptions(serviceOptions, [...filters, filter])

          if (availableOptionsWithCurrentFilters && availableOptionsWithCurrentFilters.length > 0) {
            break
          }
        }
      }

      filters.push(filter)
    }, this)

    return filters
  }

  _getFilteredOptions (options, filters) {
    filters.forEach(filter => {
      options = this._filterOptions(options, filter.columnName, filter.selectedValue)
    }, this)

    return options
  }

  _filterOptions (options, columnName, columnValue) {
    const filteredOptions = options.filter(function (option) {
      if (columnName && columnValue) {
        return option[columnName].toString() === columnValue.toString()
      } else {
        return option[columnName] === columnValue
      }
    })
    return filteredOptions
  }

  _distinctOptions (options, columnName) {
    const uniqueOptions = [...new Set(options.map(opt => opt[columnName] ? opt[columnName].toString() : null))]
    return uniqueOptions
  }

  _generateUniqueId (componentId, room, participant, service, filter) {
    let id = 'sa-'
    id += (componentId && componentId.length > 3) ? componentId.substring(0, 4) : 'sa-'

    if (room) { id = `${id}_r__${room.id}` }
    if (participant) { id = `${id}_p__${participant.id}` }
    if (service) { id = `${id}_s__${service.serviceId}` }
    if (filter) { id = `${id}_f__${filter.id}` }

    return id
  }

  // if there is min/max occupancy for the service, means that the service is configured perUnit in Davinci
  // if there isn't min/max occupancy configured, the service is configured perParticipant
  _checkIfOptionServiceTypeIsPerParticipant (apiOption = null) {
    return apiOption && !apiOption.occupancy
  }
}

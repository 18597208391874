import { widgetApi as bookingAwaitablePopupWidgetApi } from '../booking-awaitable-popup/main'

export const widgetApi = 'w-booking-optional-ancillaries-popup'

export const widgetQueries = {
  configurationElement: `[data-${widgetApi}__configuration]`,
  bookingAwaitablePopupBodyElement: `[data-${bookingAwaitablePopupWidgetApi}__modal-body]`,
  bookingAwaitablePopupCloseBtnElement: `[data-${bookingAwaitablePopupWidgetApi}__close-btn]`,
  trackAttr: 'data-track',
  bookingAwaitablePopupWidgetApiElement: `[data-js-widget='${bookingAwaitablePopupWidgetApi}']`,
  servicesBodyElement: `[data-${widgetApi}__services-body]`
}

export const api = {
  btn: 'c-btn',
  bookingAwaitablePopup: bookingAwaitablePopupWidgetApi,
  bookingItem: 'w-booking-item'
}

export const configOptions = {
  optionalServicesConfigurations: 'optionalServicesConfigurations'
}

export const config = {
  scroll: { block: 'start', behavior: 'smooth' }
}

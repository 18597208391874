/**
 * The BookingGateErrorTemplateData contains all data organized to be easily displayed in a table
 *
 * @typedef {Object}          BookingGateErrorTemplateData
 *
 * @property {String}         text           - Saying to user some actions could help
 */

import { AbsoluteDialogTemplate } from '../../components/absolute-dialog/c-absolute-dialog.template'

export const defaultBookingGateErrorData = {
  text: ''
}

/**
 * Returns an html that will wrap Price Table inner elements (room selector, flight bus selector, overview)
 *
 * @param {BookingGateErrorTemplateData} d
 *
 * @returns {String} Booking Gate Item HTML string
 */
export const BookingGateErrorTemplate = (d) => {
  d = { ...defaultBookingGateErrorData, ...d }
  return `
    <div class="w-booking-gate__error">
      ${AbsoluteDialogTemplate({
          body: d.text,
          alertType: 'warning',
          dialogType: 'toast',
          closable: false
        })}
    </div>
`
}

import JSONFetcher from '../../../../shared/js/helpers/json-fetcher'
import FilterModel from '../../../../shared/js/data/filters/filter-model'
import { FILTER_TYPES } from '../../../../shared/js/data/filters/config'
import DestinationFilter from '../../../../shared/modules/widgets/destination-filter/main'

document.addEventListener('razor-load-complete', function () {
  initDestinationFilter('my-destination-single-filter-razor', false)
  initDestinationFilter('my-destination-multiple-filter-razor', true)
})

const initDestinationFilter = (elementId, isMultiple = false) => {
  const destinationFilterElement = document.getElementById(elementId)
  if (!destinationFilterElement) return
  console.log('Destination Filter Element found', destinationFilterElement)

  const quickSearchRequest = new JSONFetcher({ src: '../assets/api/quick-search.json' })
  quickSearchRequest
    .fetch()
    .then(data => {
      const destinationFilterData = data.filters.find(filter => filter.type === FILTER_TYPES.DESTINATION)
      destinationFilterData.isMultiselectable = isMultiple
      const destinationFilterModel = new FilterModel(destinationFilterData)

      console.log('Destination Filter Data Model initialized', destinationFilterModel)

      const destinationFilter = new DestinationFilter(destinationFilterElement, destinationFilterModel)
      console.log('Destination Filter initialized', destinationFilter)
    })
}

import { BoxTemplate, defaultBoxData } from '../../components/box/c-box.template'
import { TitleTemplate, defaultTitleData } from '../../components/title/c-title.template'

const bookingLuggageMessageTemplateConsts = {
  box: {
    variant: 'flat',
    extraClasses: 'w-booking-luggage__message'
  },
  title: {
    size: 'tiny',
    extraClasses: 'w-booking-luggage__message-title',
    attributes: ''
  }
}

export const BookingLuggageMessageTemplate = (d) => {
  const boxExtraClasses = `${bookingLuggageMessageTemplateConsts.box.extraClasses} ${d.extraClasses}`
  const html = `
    ${TitleTemplate({ ...defaultTitleData, ...bookingLuggageMessageTemplateConsts.title, ...{ text: d.title } })}
    <div class="w-booking-luggage__message-description">
      ${d.description}
    </div>
    <hr class="w-booking-luggage__message-line">
    ${d.outboundFlightDefinition && d.outboundFlightDefinition.shouldBeShown
      ? `<div class="w-booking-luggage__message-airline-info">
          <div class="w-booking-luggage__message-dummy"></div>
          <div class="w-booking-luggage__message-airline-info--outbound w-booking-luggage__message-wide">
          <div class="w-booking-luggage__message-direction-title">${d.outboundFlightDefinition.title} ${d.outboundFlightDefinition.airline ? `<span> - ${d.outboundFlightDefinition.airline}</span>` : ''} </div>
          ${d.outboundFlightDefinition.checkedInLuggage || d.outboundFlightDefinition.handLuggage
            ? `<ul class="w-booking-luggage__list">
              ${d.outboundFlightDefinition.handLuggage ? `<li>${d.outboundFlightDefinition.handLuggage}</li>` : ''}
              ${d.outboundFlightDefinition.checkedInLuggage ? `<li>${d.outboundFlightDefinition.checkedInLuggage}</li>` : ''}
              </ul>`
            : ''}
          </div>`
      : ''}
      ${d.inboundFlightDefinition && d.inboundFlightDefinition.shouldBeShown
        ? `<hr class="w-booking-luggage__message-line">
          <div class="w-booking-luggage__message-airline-info--inbound w-booking-luggage__message-wide">
          <div class="w-booking-luggage__message-direction-title">${d.inboundFlightDefinition.title} ${d.inboundFlightDefinition.airline ? `<span> - ${d.inboundFlightDefinition.airline}</span>` : ''} </div>
          ${d.inboundFlightDefinition.checkedInLuggage || d.inboundFlightDefinition.handLuggage
            ? `<ul class="w-booking-luggage__list">
                ${d.inboundFlightDefinition.handLuggage ? `<li>${d.inboundFlightDefinition.handLuggage}</li>` : ''}
                ${d.inboundFlightDefinition.checkedInLuggage ? `<li>${d.inboundFlightDefinition.checkedInLuggage}</li>` : ''}
              </ul>`
            : ''}
          </div>`
    : ''}
    ${d.outboundFlightDefinition && d.outboundFlightDefinition.shouldBeShown ? '<div class="w-booking-luggage__message-dummy"></div></div>' : ''}
  `
  return `
    ${BoxTemplate({ ...defaultBoxData, ...bookingLuggageMessageTemplateConsts.box, ...{ html, extraClasses: boxExtraClasses } })}
  `
}

import { ChoiceListTemplate } from '../../../components/choice-list/c-choice-list.template'
import { TextboxTemplate } from '../../../components/textbox/c-textbox.template'
import { BtnTemplate } from '../../../components/btn/c-btn.template'
import { TitleTemplate } from '../../../components/title/c-title.template'

/**
 * The defaultPaymentVoucherOptionsData contains all data to render voucher options
 */

export const defaultPaymentVoucherOptionsData = {
  isExpired: false,
  expiredIcon: 'clock',
  notValidVoucherText: '',
  expiredVoucherReasonsWhyTexts: [],
  voucherBookingAmountText: '',
  currency: '',
  optionsSelection: {
    method: 'single',
    variant: 'boxed',
    highlighted: false,
    name: 'payment-voucher-options',
    id: 'payment-voucher-options',
    label: '',
    extraClasses: '',
    disabled: false,
    unresolved: false,
    items: [],
    attributes: {
      'data-w-payment__voucher-options-choice-list': ' '
    }
  },
  fullRedeemText: '',
  fullRedeemButtonText: '',
  fllRedeemButtonText: '',
  fullRedeemFetchingButtonText: '',
  partialRedeemText: '',
  partialRedeemButtonText: '',
  partialRedeemFetchingButtonText: '',
  amountPlaceholder: '',
  amountWarningRequiredMessage: '',
  amountWarningInvalidMessage: '',
  partialRedeemFieldLabel: '',
  cancelRedeemButtonText: '',
  expiredVoucherButtonText: '',
  nonMainBookerEmailText: '',
  voucherSelectedTitle: '',
  bonusIcon: 'voucher',
  bonusTitle: '',
  bonusMessage: '',
  hasBonus: false
}

export const PaymentVoucherOptionsTemplate = (d) => {
  d = { ...defaultPaymentVoucherOptionsData, ...d }
  return `
    <div class="w-payment__voucher-selection-wrapper">
      ${d.isExpired
        ? `<div class="w-payment__voucher-options-expired-wrapper">
            <span class="m-icon m-icon--size-huge m-icon--${d.expiredIcon}"></span>
            <div class="w-payment__voucher-options-expired-text">
              <span>${d.notValidVoucherText}</span>
              ${d.expiredVoucherReasonsWhyTexts && d.expiredVoucherReasonsWhyTexts.length > 0
                ? `<span> ${d.expiredVoucherReasonsWhyTexts[0]} </span>`
                : ' '
              }
            </div>
            ${BtnTemplate({
              ...d.expiredVoucherButtonText && { text: d.expiredVoucherButtonText },
              variant: 'secondary',
              jsApi: true,
              extraClasses: 'w-payment__voucher-options-expired-button',
              attributes: {
                'data-w-payment__voucher-options-expired-button': ''
              }
            })}
           </div>`
        : `
        ${d.showBonusTexts
          ? `<div class="w-payment__bonus-wrapper">
              <div class="w-payment__bonus-header">
                <div class="w-payment__bonus-icon">
                  <span class="m-icon m-icon--${d.bonusIcon}"></span>
                </div>
                <div class="w-payment__bonus-title">
                  ${TitleTemplate({ text: d.bonusTitle })}
                </div>
              </div>
              <p class="w-payment__bonus-description">
                ${d.bonusMessage}
              </p>
            </div>`
          : `${TitleTemplate({ text: d.voucherSelectedTitle, extraClasses: 'w-payment__selected-title' })}`}
       
        <div class="w-payment__voucher-widget-isolated" data-w-payment__voucher data-w-voucher__code="${d.voucherCode}"></div>
        
        <div class="w-payment__voucher-options-booking-amount-wrapper">
          <span>${d.voucherBookingAmountText}: </span>
          <span class="w-payment__voucher-options-booking-amount"> ${d.bookingAmount}</span>
        </div>
        
        <div class="w-payment__voucher-options-balance-select-amount">
          <div class="w-payment__voucher-options-choice-list-wrapper">
            ${ChoiceListTemplate(Object.assign({}, defaultPaymentVoucherOptionsData.optionsSelection, d.optionsSelection, { items: d.optionsSelection.items }))}
          </div>
          <div class="w-payment__voucher-options-partial-redeemer-wrapper" data-w-payment__payment-voucher-custom-redeemer>
            <div class="w-payment__voucher-options-partial-redeemer">
                <span class="w-payment__voucher-options-input-label">${d.partialRedeemFieldLabel}</span>
                  ${TextboxTemplate({
                    id: 'w-payment-voucher__amount-input',
                    ...d.amountPlaceholder && { placeholder: d.amountPlaceholder },
                    elementAttributes: {
                      pattern: '^[0-9]+((\\.|,)[0-9]{1,2})?$',
                      ...d.amountWarningRequiredMessage && { 'data-message-required': d.amountWarningRequiredMessage },
                      ...d.amountWarningInvalidMessage && { 'data-message-pattern': d.amountWarningInvalidMessage },
                      'data-w-payment__voucher-options-amount-input-element': ''
                    },
                    required: true,
                    extraClasses: 'w-payment__voucher-options-input',
                    attributes: {
                      'data-w-payment__voucher-options-amount-input': ''
                    }
                  })}
                <span class="w-payment__voucher-options-currency">${d.currency}</span>
            </div>
            <div class="w-payment__voucher-options-error-message" data-w-payment__voucher-otpions-error-message-container>
            </div>
          </div>
        </div>
        <div class="w-payment__voucher-options-buttons">
           ${BtnTemplate({
             ...d.fullRedeemButtonText && { text: d.cancelRedeemButtonText },
             variant: 'flat-neutral',
             jsApi: true,
             extraClasses: 'w-payment__voucher-options-cancel-button',
             attributes: {
               'data-w-payment__voucher-options-cancel-button': ''
             }
           })}
          ${BtnTemplate({
            ...d.fullRedeemButtonText && { text: d.fullRedeemButtonText },
            variant: 'flow',
            jsApi: true,
            extraClasses: 'w-payment__voucher-options-submit-button',
            attributes: {
              'data-w-payment__voucher-options-submit-button': '',
              'data-w-payment__voucher-options-full-button-text': d.fullRedeemButtonText || '',
              'data-w-payment__voucher-options-full-button-checking-text': d.fullRedeemFetchingButtonText || '',
              'data-w-payment__voucher-options-partial-button-text': d.partialRedeemButtonText || '',
              'data-w-payment__voucher-options-partial-button-checking-text': d.partialRedeemFetchingButtonText || ''
            }
          })}
        </div>
        ${d.showDistinctEmailInfo
          ? `<p class="w-payment__voucher-options-info">${d.nonMainBookerEmailText}</p>`
          : ''}
      `}
     </div>
    `
}

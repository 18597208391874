import RoomList from '../room-list/main'
import Component from '../../../js/core/component/component'
import { language } from '../../../js/user/locale-settings'
import { register } from '../../../js/document/namespace'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { RoomSelectorTemplate } from './w-room-selector.template'
import { RoomSelectorListTemplate } from './w-room-selector__list.template'
import { RoomSelectorMessageTemplate, RoomSelectorWarningTemplate } from './w-room-selector__message.template'
import { RoomSelectorAgeProfilesMessageTemplate } from './w-room-selector__age-profiles-message.template'
import RoomSelectorService from '../../../js/data/room-selector/room-selector-service'
import { RoomOccupancyTemplate } from '../room-occupancy/w-room-occupancy.template'
import RoomOccupancy from '../room-occupancy/main'
import { elementFromString, flush } from '../../../js/document/html-helper'
import PriceTableType from '../booking-gate/price-table-type'
import CurrencySymbolPosition from './currency-symbol-position'
import { smooth } from '../../../js/document/scroll'

const EventEmitter = require('eventemitter3')
const globalLocales = register(`window.sundio.i18n.${language}.global`)
const componentLocales = register(`window.sundio.i18n.${language}.roomSelector`)

const DEFAULT_OPTIONS = {
  locales: {},
  hasFlexibleAllocation: true,
  allInSameRoom: false,
  priceTableType: PriceTableType.DepartureDateAndRoomType,
  roomCode: null,
  roomOccupancyId: null,
  occupation: null
}

const WIDGET_API = 'w-room-selector'
const WIDGET_QUERIES = {
  mainContainerElement: `[data-${WIDGET_API}--main-container]`,
  roomSelectorItemList: `.${WIDGET_API}__items-list`,
  wrongAllocationMessageContentElement: `.${WIDGET_API}__wrong-allocation-message--content`
}

export default class RoomSelector {
  constructor (element, participants, data, options = {}) {
    this.element = element
    this.participants = data.participants || participants
    this.data = data
    this.options = {
      ...DEFAULT_OPTIONS,
      ...options
    }
    this.locales = {
      ...globalLocales,
      ...componentLocales,
      ...this.options.locales
    }

    this.events = new EventEmitter()
    this.roomSelectorService = new RoomSelectorService(this.data)

    this._initState()
    this._buildHtml()
    this._updateUi()
  }

  getAllocatedPackages () {
    return this.allocatedPackages
  }

  getUnallocatedOccupation () {
    return this.state.unallocatedOccupation
  }

  getTotalOccupation () {
    if (this.options.hasFlexibleAllocation) {
      return this.totalOccupation
    } else {
      // totalOccupation for DP it's empty
      return this.roomSelectorService.getOccupationFromBirthDates(this.participants)
    }
  }

  _initState () {
    this.allocatedPackages = null
    this.totalOccupation = this.options.hasFlexibleAllocation
      ? this.roomSelectorService.getOccupationFromBirthDates(this.participants)
      : this.roomSelectorService.getOccupationBaseObject()

    this.indexFirstPackage = -1
    let firstPackage = null

    if (this.options.priceTableType === PriceTableType.DepartureDateAndRoomType && this.options.roomCode) {
      const roomOccupancies = this.options.roomOccupancyId
        ? this.roomSelectorService.getOccupanciesByRoomIdAndRoomOccupancyId(this.options.roomCode, this.options.roomOccupancyId)
        : this.roomSelectorService.getOccupanciesByRoomId(this.options.roomCode)

      if (this.options.hasFlexibleAllocation) {
        const { occupancy: bestOccupancy, occupation: bestOccupation } = this.roomSelectorService.getBestOccupancyForOccupation(roomOccupancies, this.totalOccupation)
        const bestPackage = this.roomSelectorService.packages.getPackagesByRoomId(this.options.roomCode).find(pkg => pkg.occupancyId === bestOccupancy.id)

        firstPackage = {
          roomContractId: this.options.roomContractId,
          roomId: this.options.roomCode,
          packageId: bestPackage.id,
          occupation: bestOccupation
        }
      } else {
        const roomOccupancy = roomOccupancies.find(occupancy => this._findOccupation(this.options.occupation, occupancy.limits))

        if (roomOccupancy) {
          const selectedPackage = this.roomSelectorService.packages.getPackagesByRoomId(this.options.roomCode).find(pkg => pkg.occupancyId === roomOccupancy.id)

          firstPackage = {
            roomId: this.options.roomCode,
            roomContractId: this.options.roomContractId,
            packageId: selectedPackage.id,
            occupation: roomOccupancy
          }
        }
      }
    }

    const state = {
      packages: undefined,
      unallocatedOccupation: this.totalOccupation
    }

    if (this.options.hasFlexibleAllocation) {
      // Pre-packaging.
      state.packages = []

      if (firstPackage) {
        // Price table 1.0.
        this.indexFirstPackage = 0
        state.packages.push(firstPackage)
        state.unallocatedOccupation = this.roomSelectorService.getOccupationDifference(this.totalOccupation, firstPackage.occupation)
      }
    } else {
      // Dynamic packaging.
      if (firstPackage) {
        this.indexFirstPackage = this.participants.findIndex(participant => {
          const occupation = this.roomSelectorService.getOccupationFromBirthDates(participant)
          return this._findOccupation(occupation, firstPackage.occupation.limits)
        })
      }

      if (this.indexFirstPackage < 0 && firstPackage) {
        this.indexFirstPackage = this.participants.findIndex(participant => this.roomSelectorService.getOccupationFromBirthDates(participant).total === firstPackage.occupation.limits.total.max)
      }

      state.packages = this.participants.map((currentRoomParticipants, idx) => ({
        roomId: this.indexFirstPackage === idx ? firstPackage.roomId : undefined,
        roomContractId: this.indexFirstPackage === idx ? firstPackage.roomContractId : undefined,
        packageId: this.indexFirstPackage === idx ? firstPackage.packageId : undefined,
        occupation: this.roomSelectorService.getOccupationFromBirthDates(currentRoomParticipants)
      }))
    }

    this.state = state
  }

  _findOccupation (occupation, occupationLimits) {
    const match = occupation.adults === occupationLimits.adults.max &&
                  occupation.children === occupationLimits.children.max &&
                  occupation.babies === occupationLimits.babies.max

    return match
  }

  _updateState (newState, options = { silent: false }) {
    const unallocatedOccupation = this.options.hasFlexibleAllocation
      ? newState.packages.reduce((occ, pkg) => this.roomSelectorService.getOccupationDifference(occ, pkg.occupation), { ...this.totalOccupation })
      : this.state.unallocatedOccupation

    this.state = {
      ...newState,
      unallocatedOccupation
    }

    if (!options.silent) {
      this._updateUi()
    }
  }

  _buildHtml () {
    this.element.innerHTML = RoomSelectorTemplate(this.locales)
    this.elements = this._runElementQueries()
  }

  _runElementQueries () {
    return {
      mainContainerElement: this.element.querySelector(WIDGET_QUERIES.mainContainerElement)
    }
  }

  _addRoomsAllocated () {
    let statusOk = true

    this.state.packages.forEach((pkg, idx) => {
      if (!this.options.hasFlexibleAllocation && !pkg.roomId) {
        this._addRoomAllocatedNotSelected(pkg, idx)
      } else {
        const roomStatus = this._addRoomAllocatedAndSelected(pkg, idx)
        statusOk = statusOk && roomStatus
      }
    })

    return statusOk
  }

  _addRoomAllocatedNotSelected (pkg, idx) {
    const roomNumber = idx + 1
    const roomListElement = elementFromString(RoomSelectorListTemplate())
    const roomListApi = this._roomSelectorListHtml(roomListElement, pkg.occupation, roomNumber)
    roomListApi.events.on('selected', (ev) => this._handleAddRoomDynamic(idx, ev.roomId))

    this.elements.mainContainerElement.appendChild(roomListElement)
    Component.initComponentActionElements(roomListElement)
  }

  _addRoomListWithoutAllocation () {
    const roomNumber = this.state.packages.length + 1
    const roomListElement = elementFromString(RoomSelectorListTemplate())
    const roomListApi = this._roomSelectorListHtml(roomListElement, this.state.unallocatedOccupation, roomNumber)
    roomListApi.events.on('selected', (ev) => this._handleAddRoom(ev.roomId, ev.packageId, ev.occupancy))

    this.elements.mainContainerElement.appendChild(roomListElement)
    Component.initComponentActionElements(roomListElement)
  }

  _addSpecialAgeProfileMessage () {
    const showMessage = this.data.isSpecialAgeProfile

    if (showMessage) {
      const specialAgeProfileMessageData = {
        headerTitle: this.locales.specialAgeProfileHeaderTitle,
        headerToggleText: this.locales.specialAgeProfileHeaderToggleText,
        collapseId: 'room-selector-age-profiles',
        contentTitle: this.locales.specialAgeProfileContentTitle,
        profiles: this.roomSelectorService.ageProfiles.getValidProfiles()
          .map(ageProfile => ({
            title: this._getAgeProfileName(ageProfile.id),
            text: this._getAgeRangeDescription(ageProfile)
          }))
      }
      const specialAgeProfileMessageElement = elementFromString(RoomSelectorAgeProfilesMessageTemplate(specialAgeProfileMessageData))
      this.elements.mainContainerElement.appendChild(specialAgeProfileMessageElement)
      Component.initDocumentComponentsFromAPI(specialAgeProfileMessageElement)
    }
  }

  _addInfoMessageWithPendingAllocation () {
    const pendingAllocationMessageData = {
      messagesText: [this._getAllAgeProfilesPendingToAllocateMessages()],
      token: 'pending-allocation-message',
      extraClasses: 'pending-allocation-message'
    }
    const pendingAllocationMessageElement = elementFromString(RoomSelectorMessageTemplate(pendingAllocationMessageData))
    this.elements.mainContainerElement.appendChild(pendingAllocationMessageElement)
  }

  _addErrorMessageWrongAllocation () {
    const messagesText = [this.locales.wrongAllocationMessageToContinue]

    const errorWrongAllocationData = {
      messagesText,
      extraClasses: 'wrong-allocation-message'
    }
    const errorWrongAllocationElement = elementFromString(RoomSelectorWarningTemplate(errorWrongAllocationData))
    this.elements.mainContainerElement.appendChild(errorWrongAllocationElement)
  }

  _roomSelectorListHtml (roomSelectorElement, occupationToAllocate, roomNumber) {
    const roomSelectorListData = this.roomSelectorService.getListOfRoomsSelectableByOccupation(occupationToAllocate, this.options.hasFlexibleAllocation, roomNumber)
    const availableRoomsListData = this._getAvailableRoomsListData(roomSelectorListData)

    if (!availableRoomsListData || availableRoomsListData.length === 0) {
      const wrongAllocationMessageContentElements = roomSelectorElement.querySelectorAll(WIDGET_QUERIES.wrongAllocationMessageContentElement)

      // Set the message that there are no compatible rooms with the people pending to allocate (so the message
      // indicating the participants per age category pending to allocate will be overwritten).
      wrongAllocationMessageContentElements.forEach(element => {
        element.innerText = this.locales.noCompatibleRoomsForPeoplePendingToAllocateText
      })
    }

    const roomListApi = new RoomList(roomSelectorElement, {
      locales: this.locales,
      extraClasses: 'w-room-selector__item-wrapper',
      roomSelectorService: this.roomSelectorService,
      data: {
        id: `${this.element.id}__list-item`,
        roomWord: this.locales.roomWord,
        roomNumber,
        occupationToAllocate,
        roomOccupancy: this.options.hasFlexibleAllocation ? '' : this._getPartyCompostionAllocatedDescription(occupationToAllocate),
        occupancySelectors: this.options.hasFlexibleAllocation ? this._getOccupancySelectorsForRoomList(occupationToAllocate) : null,
        rooms: availableRoomsListData,
        priceConfig: this._getCurrencyConfig()
      }
    })

    return roomListApi
  }

  _getCurrencyConfig () {
    let currencyConfig

    if (this.data.currencySettings) {
      switch (this.data.currencySettings.symbolPosition) {
        case CurrencySymbolPosition.BeforeAmount:
          currencyConfig = {
            currencyPosition: 'before',
            currency: this.data.currencySettings.symbol
          }
          break
        case CurrencySymbolPosition.AfterAmount:
          currencyConfig = {
            currencyPosition: 'after',
            currency: this.data.currencySettings.symbol
          }
          break
        case CurrencySymbolPosition.NotShown:
          currencyConfig = {
            currencyPosition: '',
            currency: ''
          }
          break
        default:
          currencyConfig = undefined
          break
      }
    }

    return currencyConfig
  }

  _handleAddRoomDynamic (idx, roomId) {
    const roomOccupancies = this.roomSelectorService.getOccupanciesByRoomId(roomId)
    const { occupancy: bestOccupancy } = this.roomSelectorService.getBestOccupancyForOccupation(roomOccupancies, this.state.packages[idx].occupation)
    const bestPackage = this.roomSelectorService.packages.getPackagesByRoomId(roomId).find(pkg => pkg.occupancyId === bestOccupancy.id)

    const newStatePackages = [
      ...this.state.packages
    ]

    newStatePackages[idx].roomId = newStatePackages[idx].roomId || roomId
    newStatePackages[idx].packageId = newStatePackages[idx].packageId || bestPackage.id
    newStatePackages[idx].roomContractId = newStatePackages[idx].roomContractId || bestPackage.contractId

    const newState = {
      ...this.state,
      packages: newStatePackages
    }

    this._updateState(newState)
  }

  _handleAddRoom (roomId, packageId, occupancy) {
    let newPackage

    if (occupancy && packageId) {
      // filtered by an occupancy, so we know how many adults/children/baby we need to allocate
      const packageObj = this.roomSelectorService.packages.getPackageById(packageId)
      if (packageObj) {
        newPackage = {
          roomId,
          packageId,
          occupation: occupancy
        }
      }
    } else {
      // not filtered by an occupancy, so we need to find the best occupancy for that room
      const roomOccupancies = this.roomSelectorService.getOccupanciesByRoomId(roomId)
      const { occupancy: bestOccupancy, occupation: bestOccupation } = this.roomSelectorService.getBestOccupancyForOccupation(roomOccupancies, this.state.unallocatedOccupation)
      const bestPackage = this.roomSelectorService.packages.getPackagesByRoomId(roomId).find(pkg => pkg.occupancyId === bestOccupancy.id)
      if (bestPackage) {
        newPackage = {
          roomId,
          packageId: bestPackage.id,
          occupation: bestOccupation
        }
      }
    }

    if (newPackage) {
      const newState = {
        packages: [
          ...this.state.packages,
          newPackage
        ]
      }
      this._updateState(newState)
      this._scrollToLastRoomAdded()
    }
  }

  _scrollToLastRoomAdded () {
    const yOffset = -10
    const roomsAdded = this.elements.mainContainerElement.querySelectorAll('.w-room-occupancy')
    if (roomsAdded.length) {
      const documentScrollingElement = document.scrollingElement || document.documentElement
      const lastRoomAdded = roomsAdded[roomsAdded.length - 1]
      const lastRoomAddedY = lastRoomAdded.getBoundingClientRect().top + window.pageYOffset + yOffset
      smooth(documentScrollingElement, 0, lastRoomAddedY)
    }
  }

  _getAvailableRoomsListData (roomSelectorListData) {
    roomSelectorListData.forEach(room => {
      if (room.stock !== undefined) {
        const roomUsedStock = this.state.packages.filter(pkg => pkg.roomId === room.id && pkg.roomContractId === room.contractId).length

        room.stock = room.stock - roomUsedStock
        room.stockWarningMessage = this._getStockWarningMessage(room.stock)
        room.stockWarningMessageIsLastRoom = this._getStockWarningMessageIsLastRoom(room.stock)
      }
    })

    return roomSelectorListData.filter(room => room.stock === undefined || room.stock > 0)
  }

  _updateUi () {
    this.allocatedPackages = null
    flush(this.elements.mainContainerElement)

    let statusOk = true

    this._addSpecialAgeProfileMessage()

    if (this.state.packages.length > 0) {
      statusOk = this._addRoomsAllocated()
    }

    const isEverybodyAllocated = this._isEverybodyAllocated()
    const allPackagesAllocated = this._areAllPackagesAllocated()
    const showFlexibleAllocationSection = statusOk && this.options.hasFlexibleAllocation && !isEverybodyAllocated
    const showErrorWrongAllocation = !isEverybodyAllocated || !allPackagesAllocated

    if (showFlexibleAllocationSection) {
      this._addInfoMessageWithPendingAllocation()
      this._addRoomListWithoutAllocation()
    }

    if (showErrorWrongAllocation) {
      this._addErrorMessageWrongAllocation()
    }

    if (isEverybodyAllocated) {
      if (allPackagesAllocated) {
        let birthdates = this.participants.flat()

        this.allocatedPackages = this.state.packages.map((pkg, index) => {
          let participants
          if (this.options.hasFlexibleAllocation) {
            const result = this.roomSelectorService.pickBirthdatesByOccupation(birthdates, pkg.occupation)
            participants = result.picked
            birthdates = result.remaining
          } else {
            participants = this.participants[index]
          }

          return {
            ...pkg,
            roomOccupancyId: this.roomSelectorService.packages.getPackageById(pkg.packageId).roomOccupancyId,
            roomName: this.roomSelectorService.rooms.getRoomById(pkg.roomId).name,
            participants
          }
        })

        this.events.emit('participantsAllocated', this.allocatedPackages)
      } else {
        this.events.emit('participantsUnallocated')
      }
    } else {
      this.events.emit('participantsUnallocated')
    }
  }

  _isEverybodyAllocated () {
    return Object.values(this.state.unallocatedOccupation).reduce((accOccupation, amountPeople) => accOccupation + amountPeople, 0) === 0
  }

  _areAllPackagesAllocated () {
    return !this.state.packages.find(pkg => pkg.roomId === undefined)
  }

  _getRoomRowData (pkg, index) {
    const { packageId, occupation, roomId, roomContractId } = pkg

    // ----------------
    // TODO: Quick fix to mitigate incorrectly selected package due to duplicated package ID.
    const roomNumber = index + 1
    const packageData = packageId
      ? this.roomSelectorService.packages.data
        .filter(pkg => pkg.id === packageId)
        .filter(pkg => pkg.roomNumber === roomNumber)
        .find(pkg => this.roomSelectorService.occupancies.occupancyMatchesOccupation(pkg.occupancyId, occupation))
      : undefined
    // ----------------

    // Calculate how many rooms before the current one of the same type has been assigned by the custome, to calculate real stock per room
    const stockRoomsAssigned = index === 0 ? 0 : this.state.packages.slice(0, index).filter(pkg => pkg.roomId === roomId && pkg.roomContractId === roomContractId).length

    const roomData = this.roomSelectorService.rooms.getRoomById(roomId)
    const availableStock = roomData.stock === undefined
      ? undefined
      : roomData.stock - stockRoomsAssigned
    const roomOccupancy = this.roomSelectorService.getRoomOccupancyData(roomId)

    const packageOccupation = occupation
    const errorMessages = this._getPeopleAllocationInRoomErrorMessages(roomOccupancy, packageOccupation)

    const roomRowPackageData = packageId
      ? {
          id: packageId,
          price: {
            value: this.roomSelectorService.getRoundedPrice(packageData.price),
            legend: this._getPartyCompostionAllocatedDescription(packageOccupation),
            priceSuffix: this.locales.pricePerPersonAbbreviation,
            ...this._getCurrencyConfig()
          },
          onRequest: packageData.onRequest,
          onRequestLabel: packageData.onRequest ? this.locales.onRequestLabel : ''
        }
      : {}

    return {
      ...roomRowPackageData,
      // Only on price table 1.0 for the first row the customer must not be able to delete the room row. All others are removable.
      removable: this.options.priceTableType === PriceTableType.DepartureDateAndDuration || index !== this.indexFirstPackage,
      title: `${this.locales.roomWord} ${index + 1}`, // TODO Delete Only used by room-occupancy integrated title
      roomNumber,
      roomWord: this.locales.roomWord,
      roomName: roomData.name,
      roomDescription: roomData.subtitle,
      errorMessage: errorMessages.join(', '),
      stockWarningMessage: this._getStockWarningMessage(availableStock),
      isFlexibleAllocation: this.options.hasFlexibleAllocation,
      occupancySelectors: this._getOccupancySelectors(roomOccupancy, packageOccupation, index),
      roomTypeDescriptionTitle: this.locales.roomTypeDescriptionTitle,
      characteristics: roomData.characteristics,
      images: roomData.images,
      index
    }
  }

  _getStockWarningMessage (availableStock) {
    const warningMessage = ''

    if (availableStock !== undefined && availableStock <= this.locales.roomStockThresholdToShowLowAvailability) {
      if (availableStock === 1) {
        return this.locales.lastRoomAvailable || this.locales.roomsLeftAvailable.replace('{n}', 1)
      } else if (availableStock <= this.locales.roomStockThresholdToShowLowAvailability) {
        return this.locales.roomsLeftAvailable.replace('{n}', availableStock)
      }
    }

    return warningMessage
  }

  _getStockWarningMessageIsLastRoom (availableStock) {
    if (availableStock !== undefined && availableStock <= this.locales.roomStockThresholdToShowLowAvailability) {
      return (availableStock === 1)
    }

    return undefined
  }

  _getPartyCompostionAllocatedDescription (occupation) {
    const msgs = []

    if (occupation.adults) {
      const adultsWord = occupation.adults === 1 ? this.locales.adultWord : this.locales.adultsWord
      msgs.push(`${occupation.adults} ${adultsWord}`)
    }

    if (occupation.children) {
      const childrenWord = occupation.children === 1 ? this.locales.childWord : this.locales.childrenWord
      msgs.push(`${occupation.children} ${childrenWord}`)
    }

    if (occupation.babies) {
      const babiesWord = occupation.babies === 1 ? this.locales.babyWord : this.locales.babiesWord
      msgs.push(`${occupation.babies} ${babiesWord}`)
    }

    return msgs.join(', ')
  }

  _getPeopleAllocationInRoomErrorMessages (roomOccupancy, packageOccupation) {
    const msgs = []

    // Validate each age profile occupancy.
    Object.keys(this.totalOccupation).forEach(ageProfileId => {
      if (!this.totalOccupation[ageProfileId]) return

      let msg = null
      const peoplePendingCount = roomOccupancy[ageProfileId].min - packageOccupation[ageProfileId]
      const peopleInExcessCount = packageOccupation[ageProfileId] - roomOccupancy[ageProfileId].max

      if (peoplePendingCount > 0) {
        msg = this._getPeoplePendingToAllocateMessage(peoplePendingCount, this._getAgeProfileName(ageProfileId, peoplePendingCount))
      } else if (peopleInExcessCount > 0) {
        msg = this._getPeopleAllocatedInExcessMessage(peopleInExcessCount, this._getAgeProfileName(ageProfileId, peopleInExcessCount))
      }

      if (msg) msgs.push(msg)
    })

    return msgs
  }

  _getAgeProfileName (ageProfileId, peopleCount = 0) {
    let localeId = null

    switch (ageProfileId) {
      case 'adults':
        localeId = peopleCount === 1 ? 'adultWord' : 'adultsWord'
        break

      case 'children':
        localeId = peopleCount === 1 ? 'childWord' : 'childrenWord'
        break

      case 'babies':
        localeId = peopleCount === 1 ? 'babyWord' : 'babiesWord'
        break

      case 'total':
        localeId = peopleCount === 1 ? 'personWord' : 'peopleWord'
        break
    }

    return this.locales[localeId]
  }

  _getAllAgeProfilesPendingToAllocateMessages () {
    const pendingToAllocateMsgs = []

    this.roomSelectorService.ageProfiles.getIds().forEach(ageProfileId => {
      const pendingToAllocateNum = this.state.unallocatedOccupation[ageProfileId]

      if (pendingToAllocateNum > 0) {
        const ageProfileName = this._getAgeProfileName(ageProfileId, pendingToAllocateNum)
        const pendingToAllocateMsg = `${pendingToAllocateNum} ${ageProfileName}`
        pendingToAllocateMsgs.push(pendingToAllocateMsg)
      }
    })

    if (pendingToAllocateMsgs.length > 1) {
      const pendingToAllocateMsgsLastItem = pendingToAllocateMsgs.pop()
      return this._getPeoplePendingToAllocateMessage(`${pendingToAllocateMsgs.join(', ')} ${this.locales.separator} ${pendingToAllocateMsgsLastItem}`)
    } else {
      return this._getPeoplePendingToAllocateMessage(pendingToAllocateMsgs.join(''))
    }
  }

  _getPeoplePendingToAllocateMessage (pendingToAllocateMsg) {
    const template = this.locales.peoplePendingToAllocateText || 'There are still {pendingPeople} without room assigned. Please, allocate them'
    return template
      .replace(/{pendingPeople}/ig, pendingToAllocateMsg)
  }

  _getPeopleAllocatedInExcessMessage (participantsCount, ageProfileName) {
    if (participantsCount === 0) return null

    const template = this.locales.peopleAllocatedInExcess || 'There are {PARTICIPANTS_NUM} {AGE_PROFILE_NAME} that must be removed.'
    return template
      .replace(/{PARTICIPANTS_NUM}/ig, participantsCount)
      .replace(/{AGE_PROFILE_NAME}/ig, ageProfileName)
  }

  _getOccupancySelectors (roomOccupancy, packageOccupation, index) {
    const occupancySelectors = []
    const isPriceTableGroupedByDuration = this.options.priceTableType === PriceTableType.DepartureDateAndDuration
    const isNotFirstRoom = index > 0
    const usedAgeProfilesCount = this.roomSelectorService.ageProfiles.getIdsWithOccupation(this.totalOccupation).length

    // If people not in same room and on price table 1.0 for the first row the customer must not be able to change distribution.
    if (!this.options.allInSameRoom && (isPriceTableGroupedByDuration || isNotFirstRoom || usedAgeProfilesCount > 1)) {
      this.roomSelectorService.ageProfiles.data.forEach(ageProfile => {
        if (!this.totalOccupation[ageProfile.id]) return

        const currentOccupation = packageOccupation[ageProfile.id] || 0
        const minOccupants = usedAgeProfilesCount === 1
          ? Math.max(roomOccupancy[ageProfile.id].min, roomOccupancy.total.min)
          : 0
        const maxOccupants = this.state.unallocatedOccupation[ageProfile.id] === 0
          ? currentOccupation
          : Math.min(roomOccupancy[ageProfile.id].max, this.totalOccupation[ageProfile.id])
        const ageProfileName = this._getAgeProfileName(ageProfile.id, currentOccupation)

        occupancySelectors.push({
          id: ageProfile.id,
          current: currentOccupation,
          min: minOccupants,
          max: maxOccupants,
          name: ageProfileName,
          description: this._getAgeRangeDescription(ageProfile)
        })
      })
    }

    return occupancySelectors
  }

  _getAgeRangeDescription (ageProfile) {
    return (this.locales[`${ageProfile.id}AgeRangeNew`] || this.locales[`${ageProfile.id}AgeRange`])
      .replace(/{ageword}/ig, '') // TODO: remove ageword replace when not used in SC
      .replace(/{minage}/ig, ageProfile.minAge >= 0 ? ageProfile.minAge : '')
      .replace(/{maxage}/ig, ageProfile.maxAge >= 0 ? ageProfile.maxAge : '')
  }

  _getOccupancySelectorsForRoomList (occupationToAllocate) {
    const occupancySelectors = []

    const locales = this.locales

    this.roomSelectorService.ageProfiles.data.forEach(ageProfile => {
      const ageProfilePendingToAllocate = occupationToAllocate[ageProfile.id]
      if (ageProfilePendingToAllocate > 0) {
        const ageProfileName = this._getAgeProfileName(ageProfile.id, ageProfilePendingToAllocate)

        occupancySelectors.push({
          ageProfileId: ageProfile.id,
          max: ageProfilePendingToAllocate,
          name: ageProfileName,
          description: (locales[`${ageProfile.id}AgeRangeNew`] || locales[`${ageProfile.id}AgeRange`])
            .replace(/{ageword}/ig, ageProfileName)
            .replace(/{minage}/ig, ageProfile.minAge || ageProfile.minAge >= 0 ? ageProfile.minAge : '')
            .replace(/{maxage}/ig, ageProfile.maxAge || ageProfile.maxAge >= 0 ? ageProfile.maxAge : '')
        })
      }
    })
    return occupancySelectors
  }

  _addRoomAllocatedAndSelected (pkg, index) {
    const roomRowData = {
      ...this._getRoomRowData(pkg, index),
      extraClasses: 'w-room-selector__item-wrapper'
    }
    const roomRowElement = elementFromString(RoomOccupancyTemplate(roomRowData))
    this.elements.mainContainerElement.appendChild(roomRowElement)
    Component.initDocumentComponentsFromAPI(roomRowElement)
    const roomOccupancyApi = new RoomOccupancy(roomRowElement, {})
    roomOccupancyApi.events.on('roomOccupancyChanged', (newOccupancy) => this._handleRoomRowOccupancyChanged(index, newOccupancy))
    roomOccupancyApi.events.on('removeRoom', () => this._removeRoomRow(index))

    return roomRowData.errorMessage.length === 0
  }

  _removeRoomRow (idx) {
    const newState = {
      packages: this.options.hasFlexibleAllocation
        ? this.state.packages.filter((pkg, index) => index !== idx)
        : this.state.packages.map((pkg, index) => ({
          ...pkg,
          roomId: index === idx ? undefined : pkg.roomId,
          roomContractId: index === idx ? undefined : pkg.roomContractId,
          packageId: index === idx ? undefined : pkg.packageId
        })
        )
    }

    this._updateState(newState)
  }

  _handleRoomRowOccupancyChanged (idx, newOccupancy) {
    newOccupancy = this.roomSelectorService.getOccupancyExpandedWithTotal(newOccupancy)
    const newStatePackages = [
      ...this.state.packages
    ]

    newStatePackages[idx].occupation = {
      ...newStatePackages[idx].occupation,
      ...newOccupancy
    }

    const cheapestPackage = this.roomSelectorService.getCheapestPackageOfRoom(this.state.packages[idx].roomId, newOccupancy)
    newStatePackages[idx].packageId = cheapestPackage ? cheapestPackage.id : undefined

    const newState = {
      ...this.state,
      packages: newStatePackages
    }

    this._updateState(newState)
  }

  getAllocatedPackagesIfEverybodyIsAllocated () {
    return this.allocatedPackages
  }
}

/**
 * This facade is needed because RoomSelector constructor does not comply with the interface of a widget.
 *
 * Widget constructors must be of the shape constructor(el: HTMLElement, options: Object).
 */
class RoomSelectorRegisterFacade extends RoomSelector {
  constructor (element, options = {}) {
    super(element, undefined, undefined, options)
  }
}

registerWidget(RoomSelectorRegisterFacade, WIDGET_API)

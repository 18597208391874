// TODO This widget is not used anymore from room selector, so it can be deleted.
// We keep the widget live until we can ensure that everything works fine.
import domEventsHelper from '../../../js/document/dom-events-helper'
import { RoomDropdownTemplate } from './w-room-dropdown.template'
import { elementFromString, moveChildrenFrom } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import EventEmitter from 'eventemitter3'
import { ActionableListTemplate } from '../../components/actionable-list/c-actionable-list.template'
import { language } from '../../../js/user/locale-settings'
import { register } from '../../../js/document/namespace'
import { getStyle } from '../../../js/document/css'
import { smooth } from '../../../js/document/scroll'

require('../../components/floating-box/main')

const GLOBAL_LOCALES = register(`window.sundio.i18n.${language}.global`)
const COMPONENT_LOCALES = register(`window.sundio.i18n.${language}.roomDropdown`)

const WIDGET_API = 'w-room-dropdown'

const WIDGET_QUERIES = {
  url: `data-${WIDGET_API}__url`,
  floatingBox: `[data-${WIDGET_API}__floating-box]`,
  textBox: `[data-${WIDGET_API}__textbox]`,
  cancel: `[data-${WIDGET_API}__action="cancel"]`,
  roomsList: `[data-${WIDGET_API}__rooms-list]`,
  actionableListApi: 'c-actionable-list',
  roomsContainer: '[data-w-room-dropdown__rooms-container]'
}

const DEFAULT_OPTIONS = {
  locales: {},
  data: {
    id: '',
    priceConfig: {},
    rooms: []
  }
}

export default class RoomDropdown {
  /**
   * Creates a new RoomDropdown
   *
   * @constructor
   *
   * @param {HTMLElement}     element                     - The main HTML element
   * @param {Object}          [options={}]                - Options object
   * @param {Object}          [options.locales]           - Localized texts
   * @param {Object}          [options.data]              - Data to be used
   * @param {String}          [options.data.id]           - Element ID to be used
   * @param {PriceData}       [options.data.priceConfig]  - Price config object
   * @param {Object[]}        [options.data.rooms]        - Rooms to be rendered
   */
  constructor (element, options = {}) {
    this.element = element
    this.options = {
      ...DEFAULT_OPTIONS,
      ...options
    }
    this.events = new EventEmitter()

    this.locales = {
      ...GLOBAL_LOCALES,
      ...COMPONENT_LOCALES,
      ...options.locales
    }

    this._createHtml()
    this._getHtmlElements()
    this._bindEvents()
  }

  /**
   * Updates the room list with given rooms
   *
   * @param {Object[]}        rooms                       - Rooms to be rendered
   * @param {Object}          [options={}]                - Options object
   * @param {PriceData}       [options.priceConfig]        - Price config object
   */
  updateRooms (rooms, options = {}) {
    this.options.data.rooms = rooms || []
    if (options.priceConfig) this.options.data.priceConfig = options.priceConfig

    this.roomsContainer.innerHTML = ActionableListTemplate({
      attributes: { 'data-w-room-dropdown__rooms-list': '' },
      jsApi: true,
      config: {
        itemType: 'room',
        priceDefaults: this.options.data.priceConfig,
        roomStockThresholdToShowLowAvailability: this.locales.roomStockThresholdToShowLowAvailability,
        roomsLeftAvailable: this.locales.roomsLeftAvailable,
        lastRoomAvailable: this.locales.lastRoomAvailable,
        onRequestLabel: this.locales.onRequestLabel
      },
      items: this.options.data.rooms
    })
    Component.initDocumentComponentsFromAPI(this.roomsContainer)
    this.roomsList = this.element.querySelector(WIDGET_QUERIES.roomsList)
    this._bindRoomsEvents()
  }

  _createHtml (data = this.options.data) {
    const roomDropdownHtml = elementFromString(RoomDropdownTemplate(
      {
        ...this.locales,
        ...this.options.data
      }
    ))
    moveChildrenFrom(roomDropdownHtml, this.element, { flush: true, attributes: true })
    Component.initDocumentComponentsFromAPI(this.element)
  }

  _getHtmlElements () {
    this.floatingBox = this.element.querySelector(WIDGET_QUERIES.floatingBox)
    this.textBox = this.element.querySelector(WIDGET_QUERIES.textBox)
    this.textBoxInput = this.textBox.querySelector('input')
    this.roomsList = this.element.querySelector(WIDGET_QUERIES.roomsList)
    this.cancel = this.element.querySelector(WIDGET_QUERIES.cancel)
    this.textBoxApi = this.textBox['c-textbox']
    this.floatingBoxApi = this.floatingBox['c-floating-box']
    this.roomsContainer = this.element.querySelector(WIDGET_QUERIES.roomsContainer)
  }

  _bindRoomsEvents () {
    this.roomsListApi = this.roomsList[WIDGET_QUERIES.actionableListApi]
    this.roomsListApi.events.on('selectItem', this._selectRoomItem.bind(this))
  }

  _bindEvents () {
    this._bindRoomsEvents()
    this.textBoxApi.events.on('focus', this._onFocus, this)
    this._domEvents = [
      [this.cancel, { click: () => this._onCancel() }]
    ]
    domEventsHelper.attachEvents(this._domEvents, WIDGET_API)
  }

  _selectRoomItem (ev) {
    this.events.emit('selected', ev)
    this._closeFloatingBox()
  }

  _onFocus () {
    this._openFloatingBox()
  }

  _openFloatingBox () {
    this.textBoxInput.classList.add('is-focused')
    this.floatingBoxApi.setProp('opened', true)
    this._floatingBoxEvents = [
      [document, { mousedown: (ev) => this._onCancel(ev) }]
    ]
    this._useScrollEffect()
    domEventsHelper.attachEvents(this._floatingBoxEvents, WIDGET_API)
  }

  _closeFloatingBox () {
    if (this.floatingBoxApi.getProp('opened')) {
      this.tempSelection = null
      domEventsHelper.detachEvents(this._floatingBoxEvents, WIDGET_API)
      this.floatingBoxApi.setProp('opened', false).then(() => {
        this.textBoxInput.classList.remove('is-focused')
      })
    }
  }

  _onCancel (ev) {
    const delegateTarget = ev && ev.target && ev.target.closest(`.${WIDGET_API}`)
    if (!delegateTarget || this.element !== delegateTarget) { this._closeFloatingBox() }
  }

  _getSafeDocumentYCoordinate (rootElement) {
    // Calc the viewport free vertical space
    const floatingBoxHeight = this.floatingBox.offsetHeight
    const viewportHeight = rootElement.clientHeight
    const viewportFree = viewportHeight - floatingBoxHeight
    // Calc the distance from documentTop to dropdown including safe margin
    const dropdownOffsetTop = this.element.getBoundingClientRect().top + rootElement.scrollTop
    const dropdownHeight = this.element.offsetHeight
    const dropdownMargin = 20
    const dropdownTargetYCoordinate = dropdownOffsetTop + dropdownHeight + dropdownMargin
    // Return the perfect coordinate to ensure visibility
    return dropdownTargetYCoordinate - viewportFree
  }

  _useScrollEffect () {
    const isFloatingBoxFixed = getStyle(this.floatingBox, 'position') === 'fixed'
    const isOpened = getStyle(this.floatingBox, 'display') !== 'none'
    if (isOpened && isFloatingBoxFixed) {
      const documentScrollingElement = document.scrollingElement || document.documentElement
      const safeDocumentYCoordinate = this._getSafeDocumentYCoordinate(documentScrollingElement)
      smooth(documentScrollingElement,
        documentScrollingElement.scrollLeft,
        safeDocumentYCoordinate)
    }
  }

  forceQuickClose () {
    this.floatingBoxApi.forceClosedCallback()
  }
}

import { string } from 'prop-types'

export const propTypes = {

  id: string.isRequired,

  title: string.isRequired,

  description: string,

  price: string.isRequired,

  stockWarningMessage: string,

  characteristicsContentLayout: string
}

export const defaultProps = {
  title: '',
  price: '',
  isSelectable: true,
  characteristicsContentLayout: 'default'
}

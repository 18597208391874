export const widgetApi = 'w-booking-luggage'

export const widgetQueries = {
  luggages: `[data-${widgetApi}__options]`,
  specialLuggage: `[data-${widgetApi}__special-luggage]`,
  specialLuggageSwitch: `[data-${widgetApi}__special-luggage--switch]`,
  prices: `[data-${widgetApi}__options-price]`,
  labels: `[data-${widgetApi}__participant-label]`,
  warningsection: 'data-warning-section',
  configurationElement: `[data-${widgetApi}__configuration]`,
  oneClickOption: `[data-${widgetApi}__one-click-option]`,
  oneClickOptionsSlider: `[data-${widgetApi}__one-click-options--slider]`,
  selectOptionButtonElement: `[data-${widgetApi}__one-click-option--select-button]`,
  removeOptionButtonElement: `[data-${widgetApi}__one-click-remove-button]`,
  selectPerParticipantElementSwitch: `[data-${widgetApi}__one-click--switch-views]`,
  selectPerParticipantView: `[data-${widgetApi}__select-per-participant--view]`,
  specialLuggageExtraTextSwitch: `[data-${widgetApi}__special-luggage-extra-text-switch]`
}

export const apis = {
  dropdownApi: 'c-dropdown',
  choiceListApi: 'c-choice-list',
  buttonApi: 'c-btn',
  sliderApi: 'c-slider'
}

export const attr = {
  specialLuggageUrl: 'specialLuggageUrl',
  showSpecialLuggageCheckbox: 'checkShowSpecialLuggageCheckbox',
  texts: {
    inboundDropdown: 'textInbound',
    outboundDropdown: 'textOutbound',
    bothDropdown: 'textBoth',
    specialLuggageTitle: 'textSpecialLuggageTitle',
    specialLuggageText: 'textSpecialLuggageText',
    specialLuggageExtraTextSelected: 'textSpecialLuggageExtraTextSelected',
    specialLuggageCheckboxId: 'checkSpecialLuggageId',
    specialLuggageCheckboxValue: 'checkSpecialLuggageValue'
  },
  trackAttr: 'data-track',
  optionCode: `data-${widgetApi}__one-click--option-code`
}

export const configOptions = {
  showOneClickLuggage: 'showOneClickLuggage',
  showSpecialLuggageSwitch: 'showSpecialLuggageSwitch',
  maxOneClickOptionsToBeShown: 3,
  oneClickLuggagePriceDescription: 'oneClickLuggagePriceDescription',
  oneClickLuggageSelectTextButton: 'oneClickLuggageSelectTextButton',
  oneClickLuggageUnselectTextButton: 'oneClickLuggageUnselectTextButton',
  oneClickLuggageRemoveTextButton: 'oneClickLuggageRemoveTextButton',
  oneClickLuggageTextSwitchViews: 'oneClickLuggageTextSwitchViews',
  bookingLuggageUsps: 'bookingLuggageUsps',
  oneClickLuggageOutboundAndInboundDifferentText: 'oneClickLuggageOutboundAndInboundDifferentText'
}

export const bookingLuggageConsts = {
  addOperation: 'Add',
  removeOperation: 'Remove',
  swapOperation: 'Swap'
}

export const classNames = {
  hidden: 'is-hidden',
  optionSelected: `${widgetApi}__one-click-option--selected`
}

export const bookingLuggageDirections = {
  outboundGroup: 'Outbound',
  inboundGroup: 'Inbound'
}

export const settings = {
  resizeDelay: 200
}

export const bookingLuggageTypes = {
  luggage: 'luggage',
  oneClickLuggage: 'oneClickLuggage'
}

import { TitleTemplate } from '../../../components/title/c-title.template'

/**
 * The defaultPaymentVoucherRedeemerData contains all data to render voucher redeemer
 */

export const defaultPaymentVoucherRedeemerData = {
  voucherRedeemerTitle: '',
  voucherRedeemerDescription: ''
}

export const PaymentVoucherRedeemerTemplate = (d) => {
  d = { ...defaultPaymentVoucherRedeemerData, ...d }
  return `
    <div class="w-payment__voucher-selection-wrapper">
     ${TitleTemplate({
       text: d.voucherRedeemerTitle,
       extraClasses: 'w-payment-voucher-selection__title'
     })}
      <p class="w-payment__voucher-selection-description">${d.voucherRedeemerDescription}</p>
      <div class="w-payment__voucher-redeemer-widget" data-w-payment__voucher-redeemer></div>
     </div>
    `
}

/**
 * The TabsNavItemData contains all data needed to hydrate a Tabs Nav Item selector view.
 *
 * @typedef {Object}      TabsNavItemData
 *
 * @property {String}     text            - The tab text.
 * @property {String}     [id]            - The component ID.
 * @property {String}     [componentId]   - The parent tabs ID (binds functionallity)
 * @property {String}     [icon]          - The tab icon name.
 * @property {Boolean}    [featured]      - If true, the tab item is featured.
 * @property {Boolean}    [isOpen]        - If true, the tab item has been selected (is open).
 * @property {Object}     [attributes]    - Extra button attributes.
 * @property {String}     [ExtraClasses]  - Extra classes.

 */

import { BtnTemplate } from '../btn/c-btn.template'

export const defaultTabsNavItemData = {
  text: '',
  id: '',
  componentId: '',
  icon: '',
  featured: false,
  isOpen: false,
  attributes: {},
  extraClasses: '',
  anchorable: false
}

export const TabsNavItemTemplate = (d) => {
  d = { ...defaultTabsNavItemData, ...d }

  const attributes = d.componentId
    ? {
        ...{
          'data-c-tabs__id': d.componentId,
          'data-c-tabs__tab-id': d.id,
          'data-c-tabs__action': 'showTab'
        },
        ...d.attributes
      }
    : d.attributes

  return `<li class="c-tabs__nav-item ${d.featured ? 'c-tabs__nav-item--featured' : ''} ${d.isOpen ? 'is-open' : ''} ${d.extraClasses}">
    ${BtnTemplate({ icon: d.icon, text: d.text, variant: 'flat', iconPosition: 'left', extraClasses: 'c-tabs__nav-button', track: d.track, attributes })}
</li>
`
}

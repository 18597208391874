/*
 *  sundio-helpers-scroll-bar - v1.1.5
 *  Show or hide body scroll-bar
 *
 *  Made by Sundio Group Web Development
 */

(function (window, document) {
  // Namespace
  window.sundio = window.sundio || {}
  const helpers = window.sundio.helpers = window.sundio.helpers || {}

  helpers.scrollBar = {

    _width: 0,
    _body: null,
    _html: null,
    _bodyStyle: {
      paddingRight: 0,
      overflow: null,
      scrollTop: null,
      width: null,
      position: null
    },
    _htmlStyle: {
      overflow: null
    },
    _hidden: false,
    _scrollChecker: null,

    hide: function () {
      if (this._hidden) return this
      this._hidden = true
      if (this.isPresent()) {
        this._bodyStyle.paddingRight = this._getBody().style.paddingRight
        this._width = this.getWidth() || 0
        const newPadding = (parseInt(this._bodyStyle.paddingRight) || 0) + this._width
        this._getBody().style.paddingRight = newPadding + 'px'
      }
      this._bodyStyle.scrollTop = this._getBody().scrollTop
      this._bodyStyle.overflow = this._getBody().style.overflow
      this._bodyStyle.position = this._getBody().style.position
      this._bodyStyle.width = this._getBody().style.width
      this._htmlStyle.overflow = this._getHtml().style.overflow
      this._getBody().style.overflow = 'hidden'
      this._getBody().style.position = 'fixed'
      this._getBody().style.width = '100vw'
      this._getHtml().style.overflow = 'hidden'
      if (this._getBody().classList) this._getBody().classList.add('has-scrollbar-hidden')
      this._getBody().scrollTop = this._bodyStyle.scrollTop
      return this
    },

    show: function () {
      if (!this._hidden) return this
      this._hidden = false
      this._getBody().style.paddingRight = this._bodyStyle.paddingRight || ''
      this._getBody().style.overflow = this._bodyStyle.overflow || ''
      this._getBody().style.position = this._bodyStyle.position || ''
      this._getBody().style.width = this._bodyStyle.width || ''
      this._getHtml().style.overflow = this._htmlStyle.overflow || ''
      if (this._getBody().classList) this._getBody().classList.remove('has-scrollbar-hidden')
      this._getBody().scrollTop = this._bodyStyle.scrollTop
      return this
    },

    isPresent: function () {
      const fullWindowWidth = window.innerWidth
      return document.body.clientWidth < fullWindowWidth
    },

    getWidth: function () {
      const scrollChecker = this._getScrollChecker()
      document.body.appendChild(scrollChecker)
      const scrollbarWidth = scrollChecker.offsetWidth - scrollChecker.clientWidth
      document.body.removeChild(scrollChecker)
      return scrollbarWidth
    },

    _getBody: function () {
      this._body = this._body || document.getElementsByTagName('body')[0]
      return this._body
    },

    _getHtml: function () {
      this._html = this._html || document.getElementsByTagName('html')[0]
      return this._html
    },

    _getScrollChecker: function () {
      if (this._scrollChecker) return this._scrollChecker
      const scrollDiv = document.createElement('div')
      scrollDiv.style.position = 'absolute'
      scrollDiv.style.top = '-9999px'
      scrollDiv.style.width = '50px'
      scrollDiv.style.height = '50px'
      scrollDiv.style.overflow = 'scroll'
      this._scrollChecker = scrollDiv
      return this._scrollChecker
    }

  }
})(window, document)

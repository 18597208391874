import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingOptionalAncillariesPopup from '../../../../shared/modules/widgets/booking-optional-ancillaries-popup/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const bookingOptionalAncillariesPopupData = require('../../../../shared/modules/widgets/booking-optional-ancillaries-popup/data/w-booking-optional-ancillaries-popup__js-template')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = async () => {
  const bookingOptionalAncillariesPopupItems = document.querySelectorAll('[data-w-booking-optional-ancillaries-popup]')

  bookingOptionalAncillariesPopupItems.forEach(element => {
    const bookingOptionalAncillariesPopup = new BookingOptionalAncillariesPopup(element)
    console.log(bookingOptionalAncillariesPopup)
    return bookingOptionalAncillariesPopup
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingOptionalAncillariesPopupJsTemplate = document.querySelectorAll('[data-booking-optional-ancillaries-popup-demo]')
  handleFetched(bookingOptionalAncillariesPopupJsTemplate, bookingOptionalAncillariesPopupData)
}

export default class BookingTransportSummaryDataMapper {
  /**
     * Maps API response data to widget data
     *
     * @typedef {Object}         BookingTransportSummaryData
     *
     * @property {Object}        [apiResponseData]   - Api response data
     */
  mapWidgetData (apiResponseData) {
    const widgetData = apiResponseData.type === 'bus' ? this._mapBusData(apiResponseData) : this._mapFlightsData(apiResponseData)
    return widgetData
  }

  _mapBusData (data) {
    const widgetBusData = this._mapBasicData(data)

    widgetBusData.outbound.companyName = data.outboundBusStopDescription
    widgetBusData.outbound.departureName = data.outboundDepartureCity
    widgetBusData.outbound.additionalInfo = ''

    widgetBusData.inbound.companyName = data.inboundBusStopDescription
    widgetBusData.inbound.departureName = data.inboundDepartureCity
    widgetBusData.inbound.additionalInfo = ''

    return widgetBusData
  }

  _mapFlightsData (data) {
    const widgetFlightData = this._mapBasicData(data)

    widgetFlightData.outbound.companyId = data.outboundAirlineCode
    widgetFlightData.outbound.companyName = data.outboundAirlineName
    widgetFlightData.outbound.departureName = data.outboundDepartureCity
    widgetFlightData.outbound.arrivalName = data.outboundArrivalCity
    widgetFlightData.outbound.arrivalTime = data.outboundArrivalTime
    widgetFlightData.outbound.additionalInfo = `${data.outboundHandLuggageDescription}, ${data.outboundCheckedInLuggageDescription}`

    widgetFlightData.inbound.companyId = data.inboundAirlineCode
    widgetFlightData.inbound.companyName = data.inboundAirlineName
    widgetFlightData.inbound.departureName = data.inboundDepartureCity
    widgetFlightData.inbound.arrivalName = data.inboundArrivalCity
    widgetFlightData.inbound.arrivalTime = data.inboundArrivalTime
    widgetFlightData.inbound.additionalInfo = `${data.inboundHandLuggageDescription}, ${data.inboundCheckedInLuggageDescription}`

    return widgetFlightData
  }

  _mapBasicData (data) {
    const basicData = {
      id: `transport-summary-${data.type}`,
      type: data.type,
      outboundHeading: data.outboundText,
      outboundDate: data.outboundDate,
      inboundHeading: data.inboundText,
      inboundDate: data.inboundDate,
      outbound: {
        id: 'OutboundTransport',
        departureTime: data.outboundDepartureTime,
        checked: false,
        disabled: false,
        internal: false
      },
      inbound: {
        id: 'InboundTransport',
        departureTime: data.inboundDepartureTime,
        checked: false,
        disabled: false,
        internal: false
      }
    }
    return basicData
  }
}

/**
 * Enumeration of types of price table.
 */
const PriceTableType = {
  /**
   * Price table grouped by departure date and room type.
   */
  DepartureDateAndRoomType: 'DepartureDateAndRoomType',

  /**
   * Price table grouped by departure date and duration.
   */
  DepartureDateAndDuration: 'DepartureDateAndDuration',

  /**
   * Checks if a value is a member of the enumeration.
   */
  isValid: (enumValue) => {
    return Object.values(PriceTableType).includes(enumValue)
  }
}

export default PriceTableType

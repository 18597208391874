/**
 * The SideDrawerBodyData contains all data needed to hydrate a Side Drawer Body selector view
 *
 * @typedef {Object}          SideDrawerBodyData
 *
 * @property {String}         html                - HTML snippet
 */

export const defaultSideDrawerBodyData = {
  html: ''
}

export const SideDrawerBodyTemplate = (d) => {
  d = { ...defaultSideDrawerBodyData, ...d }
  return `
<div class="c-side-drawer__body o-block__body">
  <div class="c-side-drawer__body-content m-body--medium">` +
// FIXME {{get-token-class @root.ds-tokens-components "c-side-drawer.body__size" "default"}}">
`   ${d.html}
  </div>
</div>
`
}

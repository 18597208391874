export default class BookingComparisonTableDataMapper {
  map (apiService, configurations) {
    const widgetService = {
      features: apiService.features,
      options: apiService.options.map(option => this._mapOption(option)),
      texts: configurations ? this._mapTexts(configurations) : null,
      messages: apiService.warnings && apiService.warnings.length ? this._mapMessages(apiService) : null,
      showMessagesOnTop: configurations && configurations.showMessagesOnTop ? configurations.showMessagesOnTop : false
    }
    return widgetService
  }

  _mapOption (option) {
    return {
      value: option.code,
      text: option.text,
      price: option.percentage,
      priceText: option.percentageText,
      features: option.features,
      checked: option.isSelected,
      appliesToParticipants: option.appliesToParticipants,
      startDate: option.startDate,
      endDate: option.endDate,
      assignedParticipants: option.assignedParticipants,
      bestValueText: option.bestValueText,
      extraInformation: option.modalInformation
        ? {
            title: option.modalInformation.title,
            body: option.modalInformation.body
          }
        : null
    }
  }

  _mapTexts (configurations) {
    return {
      selectTextButton: configurations.selectTextButton || '',
      unselectTextButton: configurations.unselectTextButton || '',
      moreInfoTextButton: configurations.moreInfoTextButton || '',
      topInformationText: configurations.topInformationText || ''
    }
  }

  _mapMessages (apiService) {
    let messages = []
    if (apiService.warnings) {
      messages = apiService.warnings.map(warning => this._mapMessage(warning))
    }
    return messages
  }

  _mapMessage (warning) {
    const type = warning.type ? warning.type.toLowerCase() : 'info'
    const message = {
      title: warning.title,
      type,
      texts: [warning.message]
    }
    return message
  }
}

import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import { BookingGenericSummaryTemplate } from './w-booking-generic-summary.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'

const widgetApi = 'w-booking-generic-summary'

/**
 * BookingGenericSummary widget
 */
export default class BookingGenericSummary extends BookingBox {
  /**
   * Creates a new Booking Generic Summary widget.
   *
   * @constructor
   *
   * @param {HTMLElement} element   - The HTML content.
   * @param {Object} [options={}]   - Options.
   */
  constructor (element, options = {}) {
    super(element)
  }

  /**
   * This is the command executed when the mediator if going to do a call to the backend.
   *
   * @param {Object} data           - Contains the data necessary to render the body of the widget.
   */
  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  /**
   * Hydrates the Booking Generic Summary template with the data, and renders it.
   *
   * @private
   */
  _updateWidgetData () {
    const widgetData = this.data.summaryComponents && this.data.summaryComponents.find(i => i.componentId === this.componentId)
    if (widgetData) {
      const renderedHtml = BookingGenericSummaryTemplate(widgetData)
      const content = elementFromString(renderedHtml)
      Component.initDocumentComponentsFromAPI(content)
      this._render(content)
    } else {
      super.hideComponent()
    }
  }

  /**
   * Renders the content into the widget body.
   *
   * @param content                 - The content to be rendered into the widget body.
   * @private
   */
  _render (content) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(content)
  }
}

registerWidget(BookingGenericSummary, widgetApi)

/**
 * The TabsContentData contains all data needed to hydrate a Tab Content selector view
 *
 * @typedef {Object}          TabsContentData
 *
 * @property {String}         id                - Tab content's id
 * @property {String}         html              - HTML snippet
 * @property {String}         extraClasses      - CSS extra classes
 * @property {Object}         attributes        - HTML attributes
 * @property {Boolean}        isOpen            - If true, content will be shown
 */

import { encodeAttributes } from '../../../js/helpers/string'

export const defaultTabsContentData = {
  id: '',
  html: '',
  extraClasses: '',
  attributes: {},
  isOpen: false
}

export const TabsContentTemplate = (d) => {
  d = { ...defaultTabsContentData, ...d }
  return `
<div ${d.id ? `id="${d.id}"` : ''} data-c-tabs__tab-content
  class="c-tabs__content ${d.isOpen ? 'is-open' : ''} ${d.extraClasses}"
  ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  ${d.html}
</div>
`
}

import { encodeAttributes } from '../../../js/helpers/string'

/**
 * The DDGridTemplateData contains all data organized to be easily displayed in a table
 *
 * @typedef {Object}          DDGridTemplateDiscountBulletData
 * @property {String}         [extraClasses]                                  - Extra classes to add to the element
 * @property {Object}         [attributes]                                    - Element attributes (i.e. data-attributes)
 * @property {String}         [hexColor]                                      - The color of the bullet
 * @property {String}         [description]                                   - The description of the discount
 */

export const defaultDDGridTemplateDiscountBulletData = {
  extraClasses: '',
  attributtes: {},
  hexColor: '',
  description: ''
}

/**
 * Returns an html ddGrid template/string
 *
 * @param {DDGridTemplateDiscountBulletData} d
 *
 * @returns {String} DD Grid HTML string
 */

export const DDGridTemplateDiscountBullet = (d) => {
  d = { ...defaultDDGridTemplateDiscountBulletData, ...d }

  return `<div ${d.attributes ? encodeAttributes(d.attributes) : ''}
               title="${d.description}"
               class="w-dd-grid__discount_bullet ${d.extraClasses}"
               style="background-color:${d.hexColor};">
        </div>`
}

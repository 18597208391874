import { attr, paymentTypes, voucherTypes, widgetQueries } from '../payment-constants'
import { apiCaller } from '../../../../js/helpers/api-caller'
import { elementFromString, flush } from '../../../../js/document/html-helper'
import Component from '../../../../js/core/component/component'
import { PaymentVoucherOptionsTemplate } from './w-payment-voucher-options.template'
import { paymentEvents } from '../../../../js/document/event-types'
import Voucher from '../../voucher/main'

const DEFAULT_OPTIONS = {
  locales: {},
  url: '',
  componentId: '',
  voucherData: {},
  voucherPriceInfo: {},
  bookingNumber: '',
  groupUserId: '',
  paymentMethod: '',
  isLoggedIn: false,
  hasOwnVouchers: false,
  hasSomeOwnBonusVouchers: false
}

export default class voucherDataVoucherOptions {
  constructor (element, options = {}) {
    this.element = element
    this.options = {
      ...DEFAULT_OPTIONS,
      ...options
    }
    this.locales = this.options.locales
    this.events = options.events

    this._init()
    this._render()
  }

  _init () {
    this.fullAmountToRedeem = {
      text: this.options.voucherPriceInfo.amounts[0].priceText,
      value: this.options.voucherPriceInfo.amounts[0].price
    }
    if (this.options.voucherPriceInfo.amounts[0].price > this.options.voucherData.amount.value) {
      this.fullAmountToRedeem.text = this.options.voucherData.amountText
      this.fullAmountToRedeem.value = this.options.voucherData.amount.value
    }
    this.isPartialRedeemEnabled = this.element.hasAttribute(attr.enablePartialRedeem)
    this.errorsEnabled = false
  }

  _render () {
    const isNotValid = !this.options.voucherData.canBeUsed
    const html = isNotValid ? this._renderExpired(this._getCannotBeUsedReasonsTexts(this.options.voucherData.cannotBeUsedReasons)) : this._renderOptions()
    const newContent = elementFromString(html)
    Component.initDocumentComponentsFromAPI(newContent)
    flush(this.element)
    this.element.appendChild(newContent)
    this._attachVoucherOptionsEvents()

    const voucherElement = this.element.querySelector(widgetQueries.voucher)
    this.voucherApis = []
    const voucher = this.options.voucherData
    const voucherApi = new Voucher(voucherElement, {
      data: {
        variant: 'compact',
        price: {
          ...(voucher.amount && voucher.amount.value) && { value: voucher.amount.value },
          currencyPosition: voucher.amount.currencySettings.symbolPosition === 'BeforeAmount' ? 'before' : 'after',
          ...(voucher.amount && voucher.amount.currencySettings && voucher.amount.currencySettings.symbol) && { currency: voucher.amount.currencySettings.symbol }
        },
        bonus: {
          ...(voucher.bonus && voucher.bonus.amountLabel) && { amountLabel: voucher.bonus.amountLabel },
          ...(voucher.bonus && voucher.bonus.bonusLabel) && { bonusLabel: voucher.bonus.bonusLabel }
        },
        expirationText: this.locales.expirationText ? this.locales.expirationText.replace('{EXPIRATION_DATE}', voucher.expirationDate) : ''
      }
    })
    this.voucherApis.push(voucherApi)
  }

  _getCannotBeUsedReasonsTexts (listCannotBeUsedReasonsKeys) {
    const reasonsWhyText = []
    if (listCannotBeUsedReasonsKeys) {
      listCannotBeUsedReasonsKeys.forEach(cbu => {
        if (cbu.toString().toLowerCase() === 'isvoid') {
          reasonsWhyText.push(this.locales.whenIsVoidVoucherText)
        }
        if (cbu.toString().toLowerCase() === 'expired') {
          reasonsWhyText.push(this.locales.whenExpiredVoucherText)
        }
        if (cbu.toString().toLowerCase() === 'nobalance') {
          reasonsWhyText.push(this.locales.whenNoBalanceVoucherText)
        }
      })
    }
    return reasonsWhyText
  }

  _renderExpired (listCannotBeUsedReasons) {
    return PaymentVoucherOptionsTemplate({
      isExpired: true,
      expiredVoucherReasonsWhyTexts: listCannotBeUsedReasons,
      ...this.locales
    })
  }

  _renderOptions () {
    const translatedWidgetData = this._translateOptionsBasedOnVoucherMethodModel()
    return PaymentVoucherOptionsTemplate({
      bookingAmount: this.options.voucherPriceInfo.amounts[0].priceText,
      currency: this.options.voucherData.amount.currencySettings.symbol,
      isExpired: false,
      optionsSelection: translatedWidgetData,
      voucherCode: this.options.voucherData.voucherCode,
      showBonusTexts: this.options.voucherData.bonus && ((this.options.isLoggedIn && (!this.options.hasOwnVouchers || !this.options.hasSomeOwnBonusVouchers)) || (!this.options.isLoggedIn)),
      ...this.locales,
      showDistinctEmailInfo: this.options.voucherData.email !== this.options.mainBookerEmail
    })
  }

  _translateOptionsBasedOnVoucherMethodModel () {
    const options = {
      id: `paymentVoucherOptions${this.options.componentId}`,
      name: `paymentVoucherOptions${this.options.componentId}`,
      items: [{
        id: voucherTypes.FULL_REDEEM,
        name: `${this.options.componentId}_${voucherTypes.FULL_REDEEM}`,
        value: voucherTypes.FULL_REDEEM,
        text: this.locales.fullRedeemText,
        additionalText: this.fullAmountToRedeem.text
      }]
    }

    if (this.isPartialRedeemEnabled) {
      options.items.push({
        id: voucherTypes.PARTIAL_REDEEM,
        name: `${this.options.componentId}_${voucherTypes.PARTIAL_REDEEM}`,
        value: voucherTypes.PARTIAL_REDEEM,
        text: this.locales.partialRedeemText
      })
    }
    return options
  }

  _attachVoucherOptionsEvents () {
    this.amountInputElement = this.element.querySelector(widgetQueries.voucherOptionsAmountInputElement)
    const amount = this.element.querySelector(widgetQueries.voucherOptionsAmountInput)
    const submitButton = this.submitButton = this.element.querySelector(widgetQueries.voucherOptionsSubmitButton)
    const cancelButton = this.cancelButton = this.element.querySelector(widgetQueries.voucherOptionsCancelButton)
    const expiredButton = this.expiredButton = this.element.querySelector(widgetQueries.voucherOptionsExpiredButton)
    const choiceList = this.element.querySelector(widgetQueries.voucherOptionsChoiceList)
    this.errorMessageContainer = this.element.querySelector(widgetQueries.voucherOptionsErrorMessageContainer)

    this.apis = {
      ...amount && { amount: amount['c-textbox'] },
      ...submitButton && { submitButton: submitButton[widgetQueries.buttonApi] },
      ...cancelButton && { cancelButton: cancelButton[widgetQueries.buttonApi] },
      ...expiredButton && { expiredButton: expiredButton[widgetQueries.buttonApi] },
      ...choiceList && { choiceList: choiceList[widgetQueries.choiceListApi] }
    }

    this.apis.amount && this.apis.amount.events.on('input', this._validateTypedAmount, this)
    this.apis.amount && this.apis.amount.events.on('blur', this._validateVoucherAmount, this)
    this.apis.choiceList && this.apis.choiceList.events.on('changeOptions', this._clickedPaymentVoucherOption, this)
    this.submitButton && this.submitButton.addEventListener('click', this._clickedApplyVoucherButton.bind(this))
    this.apis.submitButton && this.apis.submitButton.setProps({
      disabled: true,
      loading: false
    })
    this.cancelButton && this.cancelButton.addEventListener('click', this._cancel.bind(this))
    this.expiredButton && this.expiredButton.addEventListener('click', this._cancel.bind(this))
  }

  _cancel () {
    this.events.emit(paymentEvents.CANCELED)
  }

  _validateTypedAmount (ev) {
    const element = this.amountInputElement || ev.target
    let prev = element.getAttribute('data-prev')
    prev = (prev !== '') ? prev : ''
    const regex = /^([0-9]{0,10})((\\.|,)[0-9]{0,2})?$/
    if (!(regex).test(element.value)) {
      element.value = prev
    }
    element.setAttribute('data-prev', element.value)
  }

  _validateVoucherAmount () {
    const amount = this._getPartialVoucherAmount()
    let isValid = true
    let errorMessage = ''

    const validate = this.apis.amount && this.apis.amount.validate(true)
    if (!validate.isValid) {
      isValid = false
      errorMessage = validate.errorMessages.length > 0 && validate.errorMessages[0]
    } else if (amount > this.fullAmountToRedeem.value) {
      isValid = false
      errorMessage = this.locales.amountWarningRangeOverflowMessage &&
        this.locales.amountWarningRangeOverflowMessage.replace('{MAX_AMOUNT}', this.fullAmountToRedeem.text)
    } else if (amount < parseFloat(this.locales.amountMin || 0)) {
      isValid = false
      errorMessage = this.locales.amountWarningRangeUnderflowMessage
    }

    if (this.errorsEnabled) {
      this.errorMessageContainer.style.visibility = !isValid ? 'visible' : 'hidden'
      this.errorMessageContainer.textContent = (isValid) ? '' : errorMessage
      this.apis.amount.setValidityResult(isValid, [])
    }

    return isValid
  }

  _getPartialVoucherAmount () {
    return (this.apis && this.apis.amount) ? parseFloat(this.apis.amount.getProp('value').replace(',', '.')) : 0
  }

  _clickedPaymentVoucherOption (options) {
    const selectedOption = options.find(option => option.checked)
    const voucherCustomRedeemer = this.element.querySelector(widgetQueries.voucherCustomRedeemer)
    if (selectedOption.value === voucherTypes.PARTIAL_REDEEM && voucherCustomRedeemer) {
      voucherCustomRedeemer.style.display = 'block'
    } else {
      voucherCustomRedeemer.style.display = 'none'
    }
    this._setVoucherButtonsState(false)
  }

  async _clickedApplyVoucherButton (ev) {
    this.errorsEnabled = true
    this.apis.choiceList.disableComponent()
    this._setVoucherButtonsState(true)

    const voucherOption = this.apis.choiceList.getSelectedValues()[0]
    if (voucherOption === voucherTypes.PARTIAL_REDEEM && !this._validateVoucherAmount()) {
      this._setVoucherButtonsState(false)
      this.apis.choiceList.enableComponent()
      this.events.emit(paymentEvents.VOUCHER_AMOUNT_ERROR, this.errorMessageContainer.textContent)
      return
    }

    if (this.options.paymentMethod !== '' && this.apis.choiceList.getSelectedValues().length > 0) {
      const voucherOption = this.apis.choiceList.getSelectedValues()[0]
      const eventData = {
        paymentMethod: this.options.paymentMethod.toLowerCase(),
        paymentType: voucherOption === voucherTypes.PARTIAL_REDEEM ? paymentTypes.DEPOSIT : paymentTypes.FULL,
        amountPaid: voucherOption === voucherTypes.PARTIAL_REDEEM ? this._getPartialVoucherAmount() : this.fullAmountToRedeem.value,
        manual: voucherOption === voucherTypes.PARTIAL_REDEEM ? 'yes' : 'no',
        isOwned: !!this.options.voucherData.isOwned
      }
      this.events.emit(paymentEvents.PAYMENT_METHOD_SELECTED, eventData)
      await this._postToApplyVoucher()
    } else {
      this.events.emit('paymentError')
    }
  }

  _setVoucherButtonsState (isLoading) {
    if (this.submitButton) {
      const voucherOption = this.apis.choiceList.getSelectedValues()[0]
      let text = ''
      if (voucherOption === voucherTypes.PARTIAL_REDEEM) {
        text = isLoading ? this.submitButton.getAttribute(attr.voucherOptionsPartialButtonCheckingText) : this.submitButton.getAttribute(attr.voucherOptionsPartialButtonText)
      } else {
        text = isLoading ? this.submitButton.getAttribute(attr.voucherOptionsFullButtonCheckingText) : this.submitButton.getAttribute(attr.voucherOptionsFullButtonText)
      }
      this.apis.submitButton && this.apis.submitButton.setProps({ disabled: isLoading, loading: isLoading })
      if (text) this.apis.submitButton && this.apis.submitButton.setProp('text', text)
    }
    this.apis.cancelButton && this.apis.cancelButton.setProp('disabled', isLoading)
  }

  async _postToApplyVoucher () {
    try {
      const voucherOption = this.apis.choiceList.getSelectedValues()[0]
      const data = {
        bookingNumber: this.options.bookingNumber,
        groupUserId: this.options.groupUserId,
        paymentmethod: this.options.paymentMethod,
        voucherCode: this.options.voucherData.voucherCode,
        birthdate: this.options.voucherData.birthdate,
        email: this.options.voucherData.email,
        voucherOption,
        amountToPay: voucherOption === voucherTypes.PARTIAL_REDEEM ? this._getPartialVoucherAmount() : this.fullAmountToRedeem.value,
        contextitemid: this.options.componentId,
        referrerUrl: window.location.href
      }

      const result = await apiCaller(`${this.options.url}`, { body: data, method: 'POST' })

      if (result.success && result.response && result.response.isWellApplied) {
        if (result.response.urlRedirect) {
          window.location.replace(result.response.urlRedirect)
        } else {
          this.events.emit(paymentEvents.PAYMENT_SUCCESS, result.response)
        }
      } else {
        this._setVoucherButtonsState(false)
        this.apis.choiceList.enableComponent()
        this.events.emit(paymentEvents.PAYMENT_ERROR)
      }
    } catch (error) {
      this._setVoucherButtonsState(false)
      this.apis.choiceList.enableComponent()
      this.events.emit(paymentEvents.PAYMENT_ERROR)
    }
  }
}

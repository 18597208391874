import { fromCamelCase, encodeAttributes } from '../../../js/helpers/string'
import { ModalTemplate } from '../modal/c-modal.template'
import { BtnTemplate } from '../btn/c-btn.template'

/**
 * The SwitchData contains all data needed to hydrate a Switch view
 *
 * @typedef {Object}          SwitchData
 *
 * @property {String}         id                - The element id
 * @property {String}         name              - The element/group name
 * @property {String}         text              - Label text
 * @property {Boolean}       [checked]
 * @property {Boolean}       [disabled]
 * @property {String}        [state]            - Component state, eg. validation
 * @property {String}        [extraClasses]     - Extra classes to append to the view
 * @property {Object}        [dataset]          - Extra attributes for the input element
 * @property {String}        modal              - Modal
 * @property {String}        modal              - Modal
 */

export const defaultSwitchData = {
  id: '',
  name: '',
  text: '',
  value: null,
  checked: true,
  disabled: false,
  modal: {
    id: '',
    bodyContent: '',
    title: ''
  }
}

export const SwitchTemplate = (d) => `
<div class="c-switch
  ${d.disabled ? 'is-disabled' : ''}
  ${d.state ? `has-${d.state}` : ''}
  ${d.variant ? `c-switch--${d.variant}` : ''}
  ${d.extraClasses ? d.extraClasses : ''}
  ${d.modal ? 'c-switch--with-modal' : ''}"
  ${d.attributes ? encodeAttributes(d.attributes) : ''}>
  ${d.modal
    ? `${ModalTemplate({
      size: 'medium',
      id: d.modal.id,
      async: false,
      extraClasses: 'c-switch__modal',
      attributes: {
        'data-c-modal__action': 'close',
        'data-c-modal__id': d.modal.id
      },
      closable: true,
      style: 'neutral',
      bodyContent: d.modal.bodyContent,
      title: d.modal.title
    })}
      <div class="c-switch__container">
        ${BtnTemplate({
          variant: 'icon-only',
          icon: 'information-circle',
          attributes: {
            'data-c-modal__action': 'open',
            'data-c-modal__id': d.modal.id
          },
          extraClasses: 'c-switch__icon'
        })}
      `
    : ''}
    <label  class="c-switch__slider ">
      <input type="checkbox" class="c-switch__checkbox"
      id="${d.id}"
      name="${d.name}"
      ${d.value ? `value="${d.value}"` : ''}
      ${d.disabled ? 'disabled=""' : ''}
      ${d.checked ? 'checked=""' : ''}
      ${d.dataset ? Object.keys(d.dataset).map(k => `data-${fromCamelCase(k)}="${d.dataset[k]}"`).join(' ') : ''}/>
      <span class="c-switch__circle"></span>
    </label>
  ${d.modal
    ? '</div>'
    : ''}
  <span class="c-switch__text ">${d.text}</span>
</div>`

import { defaultNumberStepperData, NumberStepperTemplate } from '../../components/number-stepper/c-number-stepper.template'
import { BookingFilterableServicesFilterTemplateRenderer } from './w-booking-filterable-services.template'

export const BookingFilterableServicesNumberStepperFilterTemplate = (filter, filters, service, configurations) => {
  return `
  <div class="w-booking-filterable-services__filter w-booking-filterable-services__filter__header w-booking-filterable-services__filter-${filter.columnName}">
  ${NumberStepperTemplate({
    ...defaultNumberStepperData,
    id: `${filter.uid}`,
    value: filter.selectedValue,
    minValue: filter.values[0],
    maxValue: filter.values[filter.values.length - 1],
    label: filter.filterLabel,
    attributes: {
      'data-w-booking-filterable-services__filters-number-stepper': '',
      'data-w-booking-filterable-services__service-id': service.uid,
      'data-w-booking-filterable-services__filter-type': filter.columnName
    }
  })}
  </div>
  ${BookingFilterableServicesFilterTemplateRenderer(filters, service, configurations)}`
}

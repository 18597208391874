/**
 * The PriceTableApiData contains all data as received from API
 * TODO: Document better the input/output expected here
 *
 * @typedef {Object}          PriceTableApiData
 *
 * @property {String}         currencySymbol                      - Price Currency
 * @property {String}         periodName                          - Period for the package in days
 * @property {Object[]}       departures                          - Available departures, non available dates are missing
 * @property {Object[]}       roomTypes                           - Available rooms and it´s details and info (USPs & allowed occupancies)
 * @property {Object[]}       ageProfiles                         - Age profile info (adults, children...)
 * @property {Object[]}       occupancies                         - Relates age profiles with room distribution
 * @property {Object[]}       occupancies.ageProfileGroups        - Age Profiles can come in either groups or thresholds
 * @property {Object[]}       occupancies.ageProfileThresholds
 * @property {Object[]}       packages                            - Package booking unit info (price, departure, occupancy, roomType...)
 * @property {String}         packages.roomId                     - Room's id
 * @property {String}         packages.occupancyId                - Occupancy's id
 * @property {String}         packages.roomFormattedPrice         - Package's room formatted price
 */

const defaults = {
  departures: [],
  packages: [],
  occupancies: [],
  roomTypes: [],
  ageProfiles: [],
  locales: {}
}

const iconForProfile = {
  // occupancy: ['adult', 'adult', '2-persons', '3-persons'],
  occupancy: ['adult'],
  adults: 'adult',
  children: 'child',
  babies: 'baby'
}

/**
 * A PriceTableData model
 */
export default class PriceTableDataModel {
  /**
   * Creates a new PriceTableDataModel
   *
   * @constructor
   * @param {PriceTableApiData|{}}              [attributes={}]   - The model attributes
   */
  constructor (attributes = {}) {
    this._setPriceTableData({
      ...defaults,
      ...attributes
    })
  }

  /**
   * Sets a price table model
   * @param {PriceTableApiData} priceTableApiData
   *
   * @returns {PriceTableDataModel} Self instance.
   */
  _setPriceTableData (priceTableApiData) {
    this.priceTableData = {
      ...priceTableApiData,
      periodName: priceTableApiData.periodName,
      currency: priceTableApiData.currencySymbol,
      roomData: JSON.parse(JSON.stringify(priceTableApiData.roomTypes
        .reduce((obj, { id, occupancyIds, characteristics, description, subtitle, title, images }) => ({
          ...obj,
          [id]: {
            id,
            characteristics,
            images,
            description,
            subtitle,
            title,
            occupancyData: occupancyIds.reduce((obj, occupancyId) => ({
              ...obj,
              [occupancyId]: {
                ...[priceTableApiData.occupancies.find(({ id }) => id === occupancyId)]
                  .reduce((obj, occupancy) => ({
                    ...obj,
                    ...occupancy,
                    ageProfileGroups: occupancy.ageProfileThresholds
                      ? [{
                          ageProfileId: occupancy.ageProfileThresholds[0].ageProfileId,
                          quantity: occupancy.ageProfileThresholds[0].min
                        }]
                      : occupancy.ageProfileGroups
                  }), {}),
                id: undefined,
                packageData: priceTableApiData.packages.filter((_package) => _package.roomGroup === id && _package.occupancyId === occupancyId).reduce((obj, _package) => ({
                  ...obj,
                  [_package.departureDate]: {
                    ..._package,
                    formattedPrice: _package.formattedPrice,
                    occupancyId: undefined,
                    roomGroup: undefined,
                    roomName: undefined
                  }
                }), {})
              }
            }), {})
          }
        }), {}))),
      ageProfileDictionary: priceTableApiData.ageProfiles.reduce((obj, ageProfile) => ({
        ...obj,
        [ageProfile.id]: {
          ...ageProfile,
          icon: iconForProfile[ageProfile.id] || ''
        }
      }), {}),
      departureDictionary: priceTableApiData.departures.reduce((obj, departure) => ({
        ...obj,
        [departure.date]: departure
      }), {})
    }
    return this
  }

  /**
   * Gets a price table model
   *
   * @returns {PriceTableTemplateData} Self instance.
   */
  getPriceTableData () {
    return this.priceTableData
  }
}

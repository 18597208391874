import { propTypes, defaultProps } from './c-calendar-field.props'
import { checkPropTypes } from 'prop-types'
import { encodeAttributes } from '../../../js/helpers/string'
import { LabelTemplate } from '../label/c-label.template'
import { CalendarTemplate } from '../calendar/c-calendar.template'
import { FloatingBoxTemplate } from '../floating-box/c-floating-box.template'
import { TextboxTemplate } from '../textbox/c-textbox.template'
import { FloatingBoxHeaderTemplate } from '../floating-box/c-floating-box__header.template'
import { FloatingBoxBodyTemplate } from '../floating-box/c-floating-box__body.template'
import { BtnTemplate } from '../btn/c-btn.template'

export const CalendarFieldTemplate = (props) => {
  const d = { ...defaultProps, ...props }
  checkPropTypes(propTypes, d, 'prop', 'CalendarFieldTemplate')

  return `
    <div class="c-calendar-field ${d.extraClasses ? d.extraClasses : ''}"
    ${d.attributes ? encodeAttributes(d.attributes) : ''}>
      ${d.date ? CreateDateSelectorDiv(d) : ''}
    </div>
  `
}

const CreateDateSelectorDiv = (d) => {
  return `
    <div class="c-calendar-field-block" data-c-calendar-field__date>
      ${LabelTemplate({
        text: d.date.label ? d.date.label : '',
        size: 'micro',
        extraClasses: 'c-calendar-field-label e-form-label'
      })}
      ${TextboxTemplate({
        id: `${d.uid}`,
        type: 'text',
        readonly: true,
        attributes: { 'data-c-calendar-field__date-textbox': '' },
        icon: d.date.icon ? d.date.icon : '',
        iconPosition: 'left',
        placeholder: d.date.placeholder ? d.date.placeholder : ''
      })}
      ${FloatingBoxTemplate({
        gradient: true,
        extraClasses: 'c-calendar-field__floating-box',
        attributes: { 'data-c-calendar-field__floating-box': '' }
      },
      FloatingBoxHeaderTemplate({}, '') +
      FloatingBoxBodyTemplate({},
        CalendarTemplate({
          id: `${d.uid}__date-calendar`,
          attributes: { 'data-c-calendar-field__calendar': '' }
        }) +
        `<div class="c-calendar-field__body-footer">
        ${
          BtnTemplate({
            variant: 'flat-neutral',
            attributes: { 'data-c-calendar-field__calendar-action': 'cancel' },
            text: d.cancelButtonText
          }) +
          BtnTemplate({
            variant: 'trust',
            attributes: { 'data-c-calendar-field__calendar-action': 'submit' },
            text: d.acceptButtonText
          })
        }
        </div>`
        )
      )}
    </div>`
}

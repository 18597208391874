import { BookingMessageTemplate } from '../booking-message/w-booking-message.template'

export const FilterableServicesWarningMessage = (text) => {
  let html = ''
  if (text) {
    const messageData = {
      extraClasses: '',
      messages: [
        {
          type: 'warning',
          texts: [text]
        }
      ]
    }
    html = BookingMessageTemplate(messageData)
  }
  return html
}

import { profileFormTypes } from './profile-form-types'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { apiCaller } from '../../../js/helpers/api-caller'
import { profileDataEvents } from '../../../js/document/event-types'
import FormManager from './form-manager'

const widgetApi = 'w-profile-data'

const attr = {
  getUrl: `data-${widgetApi}__get-url`,
  myAccountNavLink: 'data-show-logged-in'
}

const queriesProfileData = {
  content: `[data-${widgetApi}__content]`,
  fullNameText: `[data-${widgetApi}__name]`,
  birthDateText: `[data-${widgetApi}__birth-date]`,
  loader: `[data-${widgetApi}__loader]`,
  errorMessage: `[data-${widgetApi}__error-message]`,
  profileFields: `[data-${widgetApi}__field]`,
  collapseOpenButton: `[data-${widgetApi}__collapse-open-btn]`,
  collapseCloseButton: `[data-${widgetApi}__collapse-close-btn]`,
  collapseButtonFormType: `data-${widgetApi}__form-type`,
  formNameEditBtn: `[data-${widgetApi}__form-edit-btn=${profileFormTypes.NAME}]`,
  formBirthDateEditBtn: `[data-${widgetApi}__form-edit-btn=${profileFormTypes.BIRTHDATE}]`,
  editSuccessMessage: `[data-${widgetApi}__edit-success-message]`,
  editErrorMessage: `[data-${widgetApi}__edit-error-message]`,
  formNameCollapse: `[data-${widgetApi}__collapse=${profileFormTypes.NAME}]`,
  formBirthDateCollapse: `[data-${widgetApi}__collapse=${profileFormTypes.BIRTHDATE}]`,
  formName: `[data-${widgetApi}__form=${profileFormTypes.NAME}]`,
  formBirthDate: `[data-${widgetApi}__form=${profileFormTypes.BIRTHDATE}]`,
  noDataText: `data-${widgetApi}__no-data-text`
}

const classNames = {
  hidden: 'is-hidden',
  showMessageAnimation: `${widgetApi}__show-msg-animation`,
  showMessage: `${widgetApi}__show-msg`
}

export default class ProfileData {
  /**
   * Creates a new ProfileData
   *
   * @constructor
   *
   * @param {HTMLElement} element - The HTML widget element
   * @param options
   */
  constructor (element, options = {}) {
    if (!element) { return }
    this.element = element
    this.options = {
      getUrl: this.element.getAttribute(attr.getUrl),
      ...options
    }
    this.profileData = {}

    this._getHtmlElements()
    this._attachEvents()
    this._displayProfileData()

    this.formManager = new FormManager(this.element)
  }

  /**
  * Get the necessary elements from the html
  */
  _getHtmlElements () {
    this.content = this.element.querySelector(queriesProfileData.content)
    this.loader = this.element.querySelector(queriesProfileData.loader)
    this.errorMessage = this.element.querySelector(queriesProfileData.errorMessage)
    this.editSuccessMessage = this.element.querySelector(queriesProfileData.editSuccessMessage)
    this.editErrorMessage = this.element.querySelector(queriesProfileData.editErrorMessage)
    this.fullNameText = this.element.querySelector(queriesProfileData.fullNameText)
    this.birthDateText = this.element.querySelector(queriesProfileData.birthDateText)
    this.formNameCollapse = this.element.querySelector(queriesProfileData.formNameCollapse)
    this.formBirthDateCollapse = this.element.querySelector(queriesProfileData.formBirthDateCollapse)
    this.formNameEditBtn = this.element.querySelector(queriesProfileData.formNameEditBtn)
    this.formBirthDateEditBtn = this.element.querySelector(queriesProfileData.formBirthDateEditBtn)
    this.formName = this.element.querySelector(queriesProfileData.formName)
    this.formBirthDate = this.element.querySelector(queriesProfileData.formBirthDate)
    this.profileFields = this.element.querySelectorAll(queriesProfileData.profileFields)
    this.collapseOpenButtons = this.element.querySelectorAll(queriesProfileData.collapseOpenButton)
    this.collapseCloseButtons = this.element.querySelectorAll(queriesProfileData.collapseCloseButton)
    this.noDataText = this.element.getAttribute(queriesProfileData.noDataText)
  }

  /**
  * Attach the necessary events
  */
  _attachEvents () {
    if (this.collapseOpenButtons && this.collapseOpenButtons.length) {
      this.collapseOpenButtons.forEach(btn =>
        btn.addEventListener('click', (event) => this._handleOpenForm(event)))
    }
    if (this.collapseCloseButtons && this.collapseCloseButtons.length) {
      this.collapseCloseButtons.forEach(btn =>
        btn.addEventListener('click', (event) => this._displayFiledValue(event)))
    }
    if (this.formNameEditBtn) this.formNameEditBtn.addEventListener('click', () => this._editName())
    if (this.formBirthDateEditBtn) this.formBirthDateEditBtn.addEventListener('click', () => this._editBirthDate())
  }

  /**
  * Get the data of the logged customer and display it
  */
  async _displayProfileData () {
    const { response, success } = await apiCaller(this.options.getUrl)
    this._hideLoader()
    if (!success || !response) {
      this._displayGetDataErrorMessage()
      return
    }
    this.profileData = response

    this._displayData()
    this._displayElement()
  }

  /**
  * Display the fields of the profile data
  */
  _displayData () {
    this._setProfileData()

    this.profileFields.forEach(profileField => {
      this._displayCollapseButton(profileField)
    })
  }

  /**
  * Set the fields of the profile data
  */
  _setProfileData () {
    this.fullNameText.innerHTML = this._getProfileName()

    if (!this.profileData.birthDate && this.noDataText) {
      this.birthDateText.innerHTML = this.noDataText
      return
    }
    this.birthDateText.innerHTML = this.profileData.birthDate
  }

  /**
  * Get profile full name
  */
  _getProfileName () {
    let profileName = ''
    if (this.profileData.firstName) profileName = this.profileData.firstName
    if (this.profileData.lastName) profileName += ' ' + this.profileData.lastName
    if (!profileName && this.noDataText) return this.noDataText
    return profileName.trim()
  }

  /**
  * Display the profile data
  */
  _displayElement () {
    this.content.classList.remove(classNames.hidden)
  }

  /**
  * Display the error message when retrieving data
  */
  _displayGetDataErrorMessage () {
    this.errorMessage.classList.remove(classNames.hidden)
  }

  /**
  * Hide page loader
  */
  _hideLoader () {
    this.loader.classList.add(classNames.hidden)
  }

  /**
  * Display collapse buttons
  */
  _displayCollapseButton (fieldElement) {
    const collapseOpenButton = fieldElement.querySelector(queriesProfileData.collapseOpenButton)
    if (!collapseOpenButton) return
    collapseOpenButton.firstElementChild.classList.remove(classNames.hidden)
  }

  /**
  * Hide field form value and set the inputs with the current value
  */
  _handleOpenForm (event) {
    const formType = event.currentTarget.getAttribute(queriesProfileData.collapseButtonFormType)
    this.element.querySelectorAll(`[data-c-collapse__id^='data-w-profile-data__collapse'][data-c-collapse__action='close']:not([data-c-collapse__id='data-w-profile-data__collapse-${formType}'])`).forEach(closeBtn => closeBtn.click())

    const profileFieldValue = this.element.querySelector(`[data-w-profile-data__${formType}]`)
    profileFieldValue.classList.add(classNames.hidden)

    this.formManager.fillInputValues(formType, this.profileData)
  }

  /**
  * Display field value
  */
  _displayFiledValue (event) {
    const formType = event.currentTarget.getAttribute(queriesProfileData.collapseButtonFormType)
    const profileFieldValue = this.element.querySelector(`[data-w-profile-data__${formType}]`)
    profileFieldValue.classList.remove(classNames.hidden)
  }

  /**
  * Edit profile name and update current values if success
  */
  async _editName () {
    const formNameEditBtnApi = this.formNameEditBtn['c-btn']
    const formName = this.formName['c-form']
    const formNameValidation = formName.validate()
    const formIsNotValidated = formNameValidation.some(field => field.isValid !== true)

    if (formIsNotValidated) return

    const updatedValues = await this._editProfile(formNameEditBtnApi, profileFormTypes.NAME)

    if (updatedValues && (updatedValues.firstName || updatedValues.lastName)) {
      this.profileData.firstName = updatedValues.firstName
      this.profileData.lastName = updatedValues.lastName

      this.fullNameText.innerHTML = this._getProfileName()

      this._changeNameInNav()
    } else if (this.noDataText) {
      this.fullNameText.innerHTML = this.noDataText
    }
    this.fullNameText.classList.remove(classNames.hidden)
    this.formNameCollapse['c-collapse'].toggle()
  }

  /**
  * Edit profile date of birth and update current values if success
  */
  async _editBirthDate () {
    const formNameEditBtnApi = this.formBirthDateEditBtn['c-btn']
    const form = this.formBirthDate['c-form']
    const formValidation = form.validate()
    const formIsNotValidated = formValidation.some(field => field.isValid !== true)

    if (formIsNotValidated) return

    const updatedValues = await this._editProfile(formNameEditBtnApi, profileFormTypes.BIRTHDATE)

    if (updatedValues && updatedValues.birthDate && updatedValues.birthDateUnformatted) {
      this.profileData.birthDate = updatedValues.birthDate
      this.profileData.birthDateUnformatted = updatedValues.birthDateUnformatted

      this.birthDateText.innerHTML = this.profileData.birthDate
    } else if (this.noDataText) {
      this.birthDateText.innerHTML = this.noDataText
    }
    this.birthDateText.classList.remove(classNames.hidden)
    this.formBirthDateCollapse['c-collapse'].toggle()
  }

  _changeNameInNav () {
    const eventDetails = {
      detail:
      {
        attribute: attr.myAccountNavLink,
        text: this.profileData.firstName || this.profileData.email
      }
    }

    const updateNameInNavEvent = new window.CustomEvent(profileDataEvents.NAME_CHANGED, eventDetails)
    window.dispatchEvent(updateNameInNavEvent)
  }

  /**
  * Edit profile and show success or error message
  */
  async _editProfile (editBtnApi, formType) {
    editBtnApi.setProps({ disabled: true, loading: true })
    const { success, updatedValues } = await this.formManager.editField(formType)
    editBtnApi.setProps({ disabled: false, loading: false })

    if (!success) {
      this._displayMessage(this.editErrorMessage)
      return
    }
    this._displayMessage(this.editSuccessMessage)
    return updatedValues
  }

  /**
  * Display message with showMessageAnimation class
  */
  _displayMessage (messageElement) {
    messageElement.classList.remove(classNames.showMessageAnimation)
    messageElement.getClientRects() /* trigger css animation reflow */
    messageElement.classList.add(classNames.showMessageAnimation)
  }
}

registerWidget(ProfileData, widgetApi)

import { StepTemplate } from './c-step.template'
import { encodeAttributes } from '../../../js/helpers/string'

import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/c-steps__tokens.json')['c-steps']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/components/steps/data/c-steps__tokens.json`)['c-steps'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export const defaultStepsTemplate = {
  variant: 'default',
  vertical: false,
  extraClasses: '',
  steps: []
}

export const StepsTemplate = (d) => {
  d = { ...defaultStepsTemplate, ...d }

  return `<div class="c-steps ${d.variant ? getTokenClass('variant', d.variant, tokens) : ''} ${d.vertical ? 'c-steps--vertical' : ''} ${d.extraClasses}" ${d.attributes ? encodeAttributes(d.attributes) : ''}>
        ${d.steps ? d.steps.map(step => StepTemplate(step, d.variant)).join('') : ''}
      </div>`
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import JSONFetcher from '../../../../shared/js/helpers/json-fetcher'
import FilterModel from '../../../../shared/js/data/filters/filter-model'
import TabsFilter from '../../../../shared/modules/widgets/tabs-filter/main'
import ApiFilters from '../../../../shared/modules/widgets/booking-gate/api-filters'
import { FILTER_TYPES } from '../../../../shared/js/data/filters/config'

loadQueueHelper.add(function () {
  initTabsFilter('my-month-filter')
}, 2)

document.addEventListener('razor-load-complete', function () {
  initTabsFilter('my-razor-month-filter')
})

const initTabsFilter = (elementId) => {
  const tabsFilterElement = document.getElementById(elementId)
  if (!tabsFilterElement) return

  if (tabsFilterElement) {
    console.log('Tabs Filter Element found', tabsFilterElement)
  }

  const quickSearchRequest = new JSONFetcher({ src: '../assets/api/price-table-filters.json' })
  quickSearchRequest
    .fetch()
    .then(data => {
      const filterData = ApiFilters.postProcessFiltersData(data).find(filter => filter.type === FILTER_TYPES.MONTH)
      const filterModel = new FilterModel(filterData)

      console.log('Tabs Data Model initialized', filterModel)

      const tabsFilter = new TabsFilter(tabsFilterElement, filterModel)
      console.log('Tabs filter initialized', tabsFilter)
    })
}

import { apiCaller } from '../../../../js/helpers/api-caller'

import {
  BookingCancellationFlowFormParticipantsTemplate,
  BookingCancellationFlowFormRemarksAndConditionsTemplate
} from './w-booking-cancellation-flow-form.template'
import BaseCancellation from './base-cancellation'
import { widgetQueries } from '../main'
import { BookingCancellationFlowGenericResponseTemplate } from './w-booking-cancellation-flow-generic-response.template'
import { BookingCancellationFlowGenericConfirmationTemplate } from './w-booking-cancellation-flow-generic-confirmation.template'
import { bookingCancellationFlowEvents } from '../../../../js/document/event-types'

const DEFAULT_OPTIONS = {
  locales: {},
  vouchers: [],
  getParticipantsUrl: '',
  partialCancellationRequestUrl: '',
  deleteUrl: '',
  bookingNumber: 0,
  modalApi: '',
  parent: null
}

export default class PartialCancellation extends BaseCancellation {
  constructor (element, options = {}) {
    super(element)
    this.options = {
      ...DEFAULT_OPTIONS,
      ...options
    }

    this.apis = {}
    this.locales = options.locales
    this.getParticipantsFlag = false
    this.sendFormFlag = false
    this.cancelled = false
    this.nextRender = null
    this.previousRender = null
    this.selectedParticipantsIds = []
    this.participants = null
  }

  async getParticipants () {
    this._setParentButtons(true)
    this.getParticipantsFlag = true
    const result = await apiCaller(`${this.options.getParticipantsUrl}?bookingNumber=${this.options.bookingNumber}`)

    if (this.getParticipantsFlag) {
      this.getParticipantsFlag = false
      const isSuccess = result.success && result.response.isValid && result.response.participants && result.response.participants.length > 0
      this.options.events.emit(bookingCancellationFlowEvents.GET_PARTIAL_CANCELLATION_PARTICIPANTS, {
        bookingNumber: this.options.bookingNumber,
        isSuccess
      })
      if (isSuccess) {
        this.options.parent._showStep(null)
        this.participants = result.response.participants
        this._renderParticipantSelection()
      } else {
        this.options.parent._showStep(null)
        this._renderResponse(false)
      }
    }
  }

  stopAsyncCalls () {
    this.getParticipantsFlag = false
    this.sendFormFlag = false
    this._setParentButtons(false)
  }

  async _sendForm () {
    this._setButtonsState(true)

    if (!this.apis.form || this.apis.form.validate().some(v => !v.isValid)) {
      this._setButtonsState(false)
      return
    }

    this.selectedParticipantsIds = this.apis.participants ? this.apis.participants.getSelectedValues() : this.selectedParticipantsIds
    const data = {
      participants: this.selectedParticipantsIds.map(id => parseInt(id)),
      remarks: this.apis.remarks ? this.apis.remarks.getProp('value') : '',
      contextItemId: this.componentId
    }

    if (this.nextRender) {
      this.nextRender(this.participants.filter(p => this.selectedParticipantsIds.map(id => parseInt(id)).includes(parseInt(p.id))))
      return
    }

    this.sendFormFlag = true
    const result = await apiCaller(`${this.options.partialCancellationRequestUrl}?bookingNumber=${this.options.bookingNumber}`, { method: 'POST', body: data })
    if (this.sendFormFlag) {
      this.options.events.emit(bookingCancellationFlowEvents.CONFIRM_PARTIAL_CANCELLATION, {
        bookingNumber: this.options.bookingNumber,
        isSuccess: result.success,
        participantsNum: data.participants.length
      })
      this.sendFormFlag = false
      this._renderResponse(result.success)
    }
  }

  _renderParticipantSelection (selectedParticipants = []) {
    const partialHtml = BookingCancellationFlowFormParticipantsTemplate({
      text: this.locales.partialText || '',
      participantsLabel: this.locales.partialParticipantsLabel || '',
      participantsRequiredMessage: this.locales.partialParticipantsRequiredMessage || '',
      mainBooker: this.participants[0],
      participants: this.participants.slice(1),
      selectedParticipantsIds: selectedParticipants.map(p => parseInt(p.id))
    })
    const html = BookingCancellationFlowGenericConfirmationTemplate({
      title: this.locales.partialTitle || '',
      confirm: this.locales.partialFormContinueButton || '',
      decline: this.locales.partialFormDeclineButton || '',
      html: partialHtml
    })
    this.nextRender = this._renderRemarksAndConditions
    this.previousRender = null
    this._renderHTML(html)
    this._attachEvents()
  }

  _renderRemarksAndConditions (selectedParticipants) {
    const partialHtml = BookingCancellationFlowFormRemarksAndConditionsTemplate({
      participantsLabel: this.locales.partialParticipantsLabel || '',
      selectedParticipantsLabel: this.locales.partialSelectedParticipantsLabel || '',
      selectedParticipants,
      remarksLabel: this.locales.partialRemarksLabel || '',
      remarksPlaceholder: this.locales.partialRemarksPlaceholder || '',
      termsAndConditionsText: this.locales.partialTermsAndConditionsText || '',
      conditionsLabel: this.locales.partialConditionsLabel || '',
      conditionsRequiredMessage: this.locales.partialConditionsRequiredMessage || ''
    })
    const html = BookingCancellationFlowGenericConfirmationTemplate({
      back: this.locales.partialFormBackButton || '',
      confirm: this.locales.partialFormConfirmButton || '',
      decline: this.locales.partialFormDeclineButton || '',
      html: partialHtml
    })
    this.nextRender = null
    this.previousRender = this._renderParticipantSelection.bind(this, selectedParticipants)
    this._renderHTML(html)
    this._attachEvents()
  }

  _renderResponse (isSuccess) {
    const html = BookingCancellationFlowGenericResponseTemplate({
      ...this.locales,
      modalId: this.options.modalId,
      isSuccess,
      title: isSuccess ? (this.locales.partialSuccessfulTitle || '') : (this.locales.partialFailureTitle || ''),
      text: isSuccess ? (this.locales.partialSuccessfulText || '') : (this.locales.partialFailureText || ''),
      confirm: this.locales.partialConfirmButton || ''
    })
    this.previousRender = null
    this._renderHTML(html)
    this._attachEvents()
  }

  _attachEvents () {
    super._attachEvents()
    const form = this.element.querySelector(widgetQueries.form)
    if (form) {
      this.apis = { ...this.apis, ...{ form: form['c-form'] }, ...this._getFormControls(form) }
    }
  }

  _getFormControls (formEl) {
    const elements = {
      participants: formEl.querySelector(widgetQueries.participantsChoiceList),
      remarks: formEl.querySelector(widgetQueries.remarks),
      conditions: formEl.querySelector(widgetQueries.conditions)
    }
    return {
      participants: elements.participants && elements.participants['c-choice-list'],
      remarks: elements.remarks && elements.remarks['c-textarea'],
      conditions: elements.conditions && elements.conditions['c-checkbox']
    }
  }

  _clickedConfirm (ev) {
    ev.preventDefault()
    this._sendForm()
  }

  _clickedDecline (ev) {
    super._clickedDecline(ev)
    this.getParticipantsFlag = false
    this.sendFormFlag = false
    this.selectedParticipantsIds = []
    this.options.parent._showStep('1')
  }

  _clickedBack (ev) {
    ev.preventDefault()
    if (this.previousRender) {
      this.previousRender()
    }
  }

  _clickedClose (ev) {
    super._clickedClose(ev)
    this.getParticipantsFlag = false
    this.sendFormFlag = false
    this.selectedParticipantsIds = []
  }

  _clickedDone (ev) {
    this._clickedClose(ev)
  }

  _setButtonsState (isLoading) {
    super._setButtonsState(isLoading)
    if (!isLoading) {
      this.apis.participants && this.apis.participants.enableComponent()
      this.apis.conditions && this.apis.conditions.enableComponent()
    } else {
      this.apis.participants && this.apis.participants.disableComponent()
      this.apis.conditions && this.apis.conditions.enableComponent()
    }
  }
}

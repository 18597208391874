import { ChoiceListTemplate } from '../../components/choice-list/c-choice-list.template'
import { TitleTemplate, defaultTitleData } from '../../components/title/c-title.template'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'
import { LoaderTemplate } from '../../components/loader/c-loader.template'

/**
 * The PaymentOptionsTemplate contains all the data needed to hydrate a PaymentOptions
 *
 * @typedef {Object}      PaymentOptionsTemplate
 *
 * @property {String}     variant            - The variant that will be used
 * @property {String}     extraClasses       - Extra classes if needed
 * @property {String}     method             - Choice list method
 * @property {String}     highlighted        - Choice list highlighted
 * @property {String}     name               - Choice list name
 * @property {String}     id                 - Choice list id
 * @property {String}     label              - Choice list label
 * @property {String}     disabled           - Choice list disabled
 * @property {String}     innerHTML          - Choice list innerHTML
 * @property {String}     unresolved         - Choice list unresolved
 * @property {String}     items              - Choice list items
 * @property {String}     attributes         - Choice list attributes
 */

export const defaultPaymentOptionsData = {
  method: 'single',
  variant: 'boxed',
  highlighted: false,
  name: 'payment-options',
  id: 'payment-options',
  label: '',
  extraClasses: 'w-payment__payment-options-choice-list',
  disabled: false,
  unresolved: false,
  items: [],
  attributes: {
    'data-w-payment__payment-options-choice-list': ' '
  }
}

export const defaultPaymentContainerOptionsData = {
  optionsTitle: '',
  offlineMessageTitle: '',
  offlineMessageText: '',
  paymentFullText: '',
  paymentDepositText: '',
  isPartialVoucherPaymentEnabled: false,
  payButtonText: '',
  isAdyenPaymentProvider: false,
  showPayButton: true
}

export const PaymentOptionsTemplate = (d) => `
    ${ChoiceListTemplate(Object.assign({}, defaultPaymentOptionsData, d, { items: d.items }))}
`

export const PaymentOptionsContainerTemplate = (d) => `
  <div class="w-payment__options" data-js-component="w-payment__options-container">
    <div class="w-payment__options-content">
      ${d.isTransactionTypeOnline
        ? TitleTemplate({
          ...defaultTitleData,
          ...{
            text: d.optionsTitle,
            attributes: { 'data-w-payment__options-title': '' }
          }
        })
        : ''}
      ${d.isTransactionTypeOffline && (d.offlineMessageTitle || d.offlineMessageText)
        ? `<div class="w-payment-options__offline-message" data-js-component="w-payment__offline-messages">
          ${TitleTemplate({
            ...defaultTitleData,
            ...{
              text: d.offlineMessageTitle
            }
          })}
          <p>${d.offlineMessageText}</p>
        </div>`
      : ''}
      
      <div class='w-payment__options-list' data-js-component="w-payment__options-list" data-payment-full-text="${d.paymentFullText}" data-payment-deposit-text="${d.paymentDepositText}">
        ${d.isTransactionTypeOnline ? PaymentOptionsTemplate(d.options) : ''}
      </div>
      ${d.isTransactionTypeVoucher
        ? `<div class="w-payment__voucher-option" data-js-component="w-payment__voucher_option" ${d.isPartialVoucherPaymentEnabled ? 'data-enable-partial-redeem' : ''}></div>`
        : ''
      }
      ${d.isAdyenPaymentProvider && d.paymentMethodSelected
        ? `
        ${LoaderTemplate({ size: 'small', extraClasses: 'w-payment__payment-method-loader is-hidden' })}
        <div id="${d.paymentMethodSelected}" class="w-payment__payment-method-container" data-js-component="w-payment__adyen"></div>`
        : ''
      }
      <div class="w-payment__pay-button__align-right">
      ${d.isTransactionTypeOnline && d.showPayButton
        ? BtnTemplate({
          ...defaultButtonData,
          ...{
            text: d.payButtonText,
            variant: 'flow',
            disabled: true,
            loading: false,
            block: false,
            jsApi: true,
            attributes: { 'data-w-payment__payment-options-submit-button': '' }
          }
          })
          : ''
        }
      </div>
    </div>
  </div>
`

import { attr as formAttr } from '../../components/form/main'
import { attr as participantAttr } from './main'
import { toCamelCase } from '../../../js/helpers/string'

/**
 * Data Mapper
 *  Transforms API and settings data into field components.
 *  Transforms the plain form data into the model to send to API
 */
export default class DataMapper {
  mapBookingParticipantsForm (apiData, settings = null) {
    const bookingParticipantsFormConfig = apiData.bookingParticipantsForm
    let widgetBookingParticipantsForm = {}
    if (bookingParticipantsFormConfig) {
      const uniqFormFields = this._getUniqFormFields(bookingParticipantsFormConfig)

      widgetBookingParticipantsForm = {
        formSections: apiData.participants.map(participant => this._mapParticipant({ participant, bookingParticipantsFormConfig, settings })),
        changePatternSettings: this._getChangePatternSettings(uniqFormFields)
      }
    }
    return widgetBookingParticipantsForm
  }

  _getUniqFormFields (participantsFormConfig) {
    let filteredFieldSettings = []
    if (participantsFormConfig) {
      const allFieldSettings = participantsFormConfig.map(section => {
        return section.fieldsets.map(fieldset => fieldset.fields).flat()
      }).flat()

      filteredFieldSettings = allFieldSettings.reduce((acc, current) => {
        const x = acc.find(item => item.type === current.type)
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])
    }

    return filteredFieldSettings
  }

  _mapParticipant (data) {
    const { participant, bookingParticipantsFormConfig, settings } = data
    const participantConfiguration = bookingParticipantsFormConfig && bookingParticipantsFormConfig.find(item => item.participantIds.includes(participant.id))
    const collapseId = `w-bookings-participants-form__collapse_${participant.id}`
    const widgetParticipant = {
      title: this._mapFormSectionTitle(participant),
      canBeCollapsed: settings && settings.participantSectionsCanBeCollapsed,
      collapseId,
      icon: '1-person',
      fieldsets: participantConfiguration ? participantConfiguration.fieldsets.map(fieldset => this._mapParticipantFieldset(fieldset, participant)) : null
    }
    return widgetParticipant
  }

  _mapFormSectionTitle (participant) {
    let title = participant.alternativeName
    if (participant.name && participant.surname) {
      title = `${participant.name} ${participant.surname}`
    }
    return title
  }

  _mapParticipantFieldset (fieldset, participant) {
    const fields = fieldset.fields.map(fieldSettings => {
      return this._getComponents(fieldSettings, participant)
    })
      .filter(components => components != null)
      .flat()

    const widgetFieldset = {
      title: fieldset.title,
      description: fieldset.description,
      extraClasses: 'w-booking-participants-form__participant-fieldset',
      components: fields
    }
    return widgetFieldset
  }

  _getComponents (fieldSettings, participant) {
    const components = []
    const id = `Participant_${participant.id}_${fieldSettings.type}`

    const value = participant[toCamelCase(fieldSettings.type)] || ''
    let component = null
    const fieldAttributes = {}
    fieldAttributes[`${participantAttr.participantField}${participant.id}`] = fieldSettings.type
    fieldAttributes[participantAttr.fieldType] = fieldSettings.type

    switch (fieldSettings.type) {
      case 'Name':
      case 'Surname':
      case 'Email':
      case 'StreetName':
      case 'StreetNumber':
      case 'StreetNumberAdditional':
      case 'PostalCode':
      case 'CityName': {
        const attributes = { ...fieldAttributes }
        if (fieldSettings.type === 'PostalCode') {
          attributes[formAttr.changePatternFieldSelector] = 'postal-code'
        }
        component = this._buildTextField(id, value, fieldSettings, attributes)
        components.push(component)
        break
      }
      case 'Birthdate':
        component = this._buildDateSelector(id, value, fieldSettings, fieldAttributes)
        components.push(component)
        break
      case 'MobilePhoneNumber':
      case 'EmergencyPhoneNumber': {
        const phoneInput = this._buildPhoneField(id, value, fieldSettings, fieldAttributes)
        components.push(phoneInput)
        break
      }
      case 'Country': {
        const countryAttributes = { ...fieldAttributes }
        countryAttributes[formAttr.changePatternSettingsId] = 'country'
        component = this._buildDropdownField(id, value, fieldSettings, countryAttributes)
        components.push(component)
        break
      }
      case 'Title':
      case 'Gender':
        component = this._buildRadioButtons(id, value, fieldSettings, fieldAttributes)
        components.push(component)
        break
      default:
        // Nothing to do here
        break
    }

    return components
  }

  _buildPhoneField (id, value, fieldSettings, attributes) {
    const phoneInput = {
      // fieldset field settings
      type: 'c-phone-input',
      size: fieldSettings.size ? fieldSettings.size.toLowerCase() : 'tiny',
      data: {
        ...{
          // component settings
          id,
          value,
          placeholder: fieldSettings.placeholder,
          messageInvalid: fieldSettings.invalidErrorMessage,
          attributes: attributes || {},
          extraClasses: ''
        },
        ...this._mapCommonData({ id, label: fieldSettings.label, required: fieldSettings.isMandatory, messageRequired: fieldSettings.emptyErrorMessage, attributes })
      }
    }

    if (fieldSettings.additionalSettings) {
      const parsedAdditionalSettings = JSON.parse(fieldSettings.additionalSettings)
      phoneInput.data.countries = parsedAdditionalSettings.allCountries.map(country => this._mapCountryPhoneField(country))
    }
    return phoneInput
  }

  _mapCountryPhoneField (country) {
    const countryData = {
      iso2: country.iso2 || '',
      dial: country.dialCode || '',
      pattern: country.pattern || '',
      name: country.name || ''
    }

    return countryData
  }

  _buildTextField (id, value, fieldSettings, attributes) {
    const textField = {
      // fieldset field settings
      type: 'c-textbox',
      size: fieldSettings.size ? fieldSettings.size.toLowerCase() : 'small',
      data: {
        ...{
          // component settings
          value,
          placeholder: fieldSettings.placeholder,
          patternRule: fieldSettings.pattern,
          messageInvalid: fieldSettings.invalidErrorMessage
        },
        ...this._mapCommonData({ id, label: fieldSettings.label, required: fieldSettings.isMandatory, messageRequired: fieldSettings.emptyErrorMessage, attributes })
      }
    }

    return textField
  }

  _buildDropdownField (id, value, fieldSettings, attributes) {
    const dropdownData = {
      ...{
        placeholder: fieldSettings.placeholder,
        options: []
      },
      ...this._mapCommonData({ id, label: fieldSettings.label, required: fieldSettings.isMandatory, messageRequired: fieldSettings.emptyErrorMessage, attributes })
    }

    if (fieldSettings.values) {
      dropdownData.options = fieldSettings.values.map(function (x) {
        const xValue = x.value ? x.value : ''
        const item = {
          value: x.value,
          text: x.name,
          selected: x.selected ? x.selected : xValue.toLowerCase() === value.toLowerCase()
        }
        return item
      })
    }

    const dropdownField = {
      type: 'c-dropdown',
      size: fieldSettings.size ? fieldSettings.size.toLowerCase() : 'small',
      data: dropdownData
    }

    return dropdownField
  }

  _buildRadioButtons (id, value, fieldSettings, attributes) {
    const choiceListData = {
      ...{
        method: 'single',
        variant: 'inline',
        name: id,
        jsApi: true,
        items: []
      },
      ...this._mapCommonData({ id, label: fieldSettings.label, required: fieldSettings.isMandatory, messageRequired: fieldSettings.emptyErrorMessage, attributes })
    }

    if (fieldSettings.values) {
      choiceListData.items = fieldSettings.values.map(function (x, index) {
        const xValue = x.value ? x.value : ''
        const item = {
          id: '-item-00' + index,
          value: x.value,
          text: x.name,
          checked: xValue.toLowerCase() === value.toLowerCase()
        }
        return item
      })
    }

    const choiceListField = {
      type: 'c-choice-list',
      size: fieldSettings.size ? fieldSettings.size.toLowerCase() : 'large',
      data: choiceListData
    }

    return choiceListField
  }

  _buildDateSelector (id, value, fieldSettings, attributes) {
    const dateSelectorData = {
      ...{
        // component settings
        date: value,
        mainLabel: fieldSettings.label,
        dayPlaceholder: fieldSettings.placeholders && fieldSettings.placeholders.day ? fieldSettings.placeholders.day : '',
        monthPlaceholder: fieldSettings.placeholders && fieldSettings.placeholders.month ? fieldSettings.placeholders.month : '',
        yearPlaceholder: fieldSettings.placeholders && fieldSettings.placeholders.year ? fieldSettings.placeholders.year : '',
        minDate: fieldSettings.dateRange ? fieldSettings.dateRange.startDate : null,
        maxDate: fieldSettings.dateRange ? fieldSettings.dateRange.endDate : null,
        messageInvalid: fieldSettings.invalidErrorMessage,
        messageOutOfBounds: fieldSettings.invalidErrorMessage,
        blockModifier: true
      },
      ...this._mapCommonData({ id, required: fieldSettings.isMandatory, messageRequired: fieldSettings.emptyErrorMessage, attributes })
    }
    const dateInput = {
      // fieldset field settings
      type: 'c-date-selector',
      size: fieldSettings.size ? fieldSettings.size.toLowerCase() : 'medium',
      data: dateSelectorData
    }
    return dateInput
  }

  _mapCommonData (data) {
    return {
      id: data.id,
      disabled: false,
      label: data.label,
      attributes: data.attributes,
      required: data.required,
      messageRequired: data.messageRequired,
      extraClasses: ''
    }
  }

  _getChangePatternSettings (allFieldsSettings) {
    let settings = {}

    const postalCodeSettings = allFieldsSettings.find(x => x.type === 'PostalCode')
    if (postalCodeSettings && postalCodeSettings.additionalSettings && postalCodeSettings.additionalSettings.allCountries) {
      settings = {
        country: [
          {
            fieldSelector: 'postal-code',
            patterns: postalCodeSettings.additionalSettings.allCountries.map(function (x) {
              return { key: x.iso2, pattern: x.pattern }
            })
          }
        ]
      }
    }

    return settings
  }
}

import { defaultSideDrawerBodyData } from './c-side-drawer__body.template'

export const SideDrawerFooterTemplate = (d) => {
  d = { ...defaultSideDrawerBodyData, ...d }
  return `
<div class="c-side-drawer__footer o-block__footer">
  ${d.html}
</div>
`
}

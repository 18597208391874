import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import Component from '../../../../shared/js/core/component/component'
import RoomList from '../../../../shared/modules/widgets/room-list/main'
import RoomSelectorService from '../../../../shared/js/data/room-selector/room-selector-service'

const data = require('../../../../shared/modules/widgets/room-list/data/w-room-list__data-options')
const dataFiltered = require('../../../../shared/modules/widgets/room-list/data/w-room-list__data-options-filtered')
const dataFilteredEmpty = require('../../../../shared/modules/widgets/room-list/data/w-room-list__data-options-filtered-empty')
const dataDP = require('../../../../shared/modules/widgets/room-list/data/w-room-list__data-options-dp')
const dataPP = require('../../../../shared/modules/widgets/room-list/data/w-room-list__data-options-pp')

loadQueueHelper.add(function () {
  initRoomList()
}, 2)

const initRoomList = async () => {
  const roomlists = [
    {
      elementId: 'my-room-list',
      data
    },
    {
      elementId: 'my-room-list-filtered-rooms',
      data: dataFiltered
    },
    {
      elementId: 'my-room-list-filtered-rooms-empty',
      data: dataFilteredEmpty
    },
    {
      elementId: 'my-room-list-dp',
      data: dataDP
    },
    {
      elementId: 'my-room-list-pp',
      data: dataPP
    }
  ]

  roomlists.forEach(item => {
    const roomListElement = document.getElementById(item.elementId)
    if (roomListElement) {
      const roomList = new RoomList(roomListElement, {
        roomSelectorService: new RoomSelectorService(item.data),
        data: item.data
      })
      Component.initComponentActionElements(roomListElement)
      roomList.events.on('selected', handleSelectedStateSubmit, this)
    }
  })
}

function handleSelectedStateSubmit (data) {
  console.log(`Room id selected: '${data}'`)
}

export const widgetApi = 'w-booking-participants-badge'

export const widgetQueries = {
  form: '[data-js-component="c-form"]',
  saveButtons: `[data-${widgetApi}--save-button]`,
  emailInput: `[data-${widgetApi}--email-input]`,
  nameInputs: `[data-${widgetApi}--name-input]`,
  birthdateInputs: `[data-${widgetApi}--birthdate-input]`,
  successToggle: `[data-${widgetApi}--success-toggle]`,
  configurationElement: `[data-${widgetApi}__configuration]`
}

export const attr = {
  texts: {
    emailLabel: 'textEmailLabel',
    emailPlaceholder: 'textEmailPlaceholder',
    emailPattern: 'textEmailPattern',
    emailWarningRequired: 'textEmailWarningRequired',
    emailWarningPattern: 'textEmailWarningPattern',
    emailMessageInfo: 'textEmailMessageInfo',
    emailMessageSuccess: 'textEmailMessageSuccess',
    emailSaveButton: 'textEmailSaveButton',
    participantsLabel: 'textParticipantsLabel',
    participantsNamePlaceholder: 'textParticipantsNamePlaceholder',
    participantsNamePattern: 'textParticipantsNamePattern',
    participantsNamePatternWarning: 'textParticipantsNamePatternWarning',
    participantsSaveButton: 'textParticipantsSaveButton',
    participantsCancelButton: 'textParticipantsCancelButton'
  },
  classes: {
    isSuccess: 'w-booking-participants-badge__is-success'
  },
  participantId: 'participantId',
  settings: {
    cookieEnabled: 'settingCookieEnabled',
    encryptEnabled: 'settingEncryptEnabled',
    encryptKey: 'settingEncryptKey',
    encryptIv: 'settingEncryptIv',
    isEmailRequired: 'settingIsEmailRequired'
  },
  trackAttr: 'data-track'
}

export const componentsApi = {
  formApi: 'c-form',
  textBoxApi: 'c-textbox',
  dateSelectorApi: 'c-date-selector'
}

export const cookieDefaultSettings = {
  cookieEnabled: true,
  cookieName: 'badgedata',
  cookieSettings: {
    expirationDays: 10,
    path: '/'
  },
  encryptEnabled: false,
  encryptAlgorithm: 'AES-CBC',
  encryptKey: 'sunwebgroup.com',
  encryptIv: 'sundio',
  contentTypeString: 'application/json',
  acceptHeaderString: 'application/vnd.sundiogroup.v1+json'
}

export const configOptions = {
  showEmailSection: 'showEmailSection',
  collapseNamesSection: 'collapseNamesSection',
  showDateSelectorFields: 'showDateSelectorFields',
  dateSelectorsFieldSettings: 'dateSelectorsFieldSettings',
  showDateSelectorFieldsForAdults: 'showDateSelectorFieldsForAdults'
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingError from '../../../../shared/modules/widgets/booking-error/main'

const bookingErrorData = require('../../../../shared/modules/widgets/booking-error/data/w-booking-error__js-template__custom-message.json')
const bookingErrorBookingNullData = require('../../../../shared/modules/widgets/booking-error/data/w-booking-error__js-template__booking-null.json')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = async () => {
  const bookingErrorItem = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-error"]')

  bookingErrorItem.forEach(element => {
    const bookingError = new BookingError(element)
    console.log(bookingError)
  })

  const bookingErrorJsTemplate = document.querySelector('[data-booking-error-demo]')
  if (bookingErrorJsTemplate) {
    const widget = new BookingError(bookingErrorJsTemplate.firstElementChild)
    await widget.handleError({ response: bookingErrorData })
    await widget.setHasError()
  }

  const bookingErrorBookingNullJsTemplate = document.querySelector('[data-booking-error-booking-null-demo]')
  if (bookingErrorBookingNullJsTemplate) {
    const widget = new BookingError(bookingErrorBookingNullJsTemplate.firstElementChild)
    await widget.handleError({ response: bookingErrorBookingNullData })
    await widget.setHasError()
  }
}

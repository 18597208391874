import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'

const widgetApi = 'w-booking-button-open-page'

/**
 * BookingButtonOpenPage widget
 */
export default class BookingButtonOpenPage extends BookingBox {
  /**
   * Creates a new Booking Button Open Page widget.
   *
   * @constructor
   *
   * @param {HTMLElement} element   - The HTML content.
   * @param {Object} [options={}]   - Options.
   */
  constructor (element, options = {}) {
    super(element)
    this.element = element
    this.url = element.dataset.url || null
  }

  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  _updateWidgetData () {
    if (this.data && this.data.confirmationComponents && this.url && this.element.dataset.buttontext) {
      this._init(this.data)
    } else {
      super.hideComponent()
    }
  }

  _init (data) {
    if (!data) { return }
    const buttonData = this._mapbuttondata(data)
    const html = BtnTemplate(Object.assign({}, defaultButtonData, buttonData))
    const content = elementFromString(html)
    Component.initDocumentComponentsFromAPI(content)
    this._render(content)
  }

  _mapbuttondata (data) {
    const linkBtn = {
      href: this.url.replace('{bookingNumber}', data.confirmationComponents[0].bookingNumber),
      target: '_blank'
    }
    return {
      text: this.element.dataset.buttontext,
      url: this.url,
      link: linkBtn,
      variant: 'flow',
      extraClasses: 'w-booking-button-open-page__btn'
    }
  }

  _render (content) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(content)
  }
}

registerWidget(BookingButtonOpenPage, widgetApi)

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingChoiceList from '../../../../shared/modules/widgets/booking-choice-list/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const bookingChoiceListData = require('../../../../shared/modules/widgets/booking-choice-list/data/w-booking-choice-list__js-template.json')
const bookingChoiceListPerParticipantData = require('../../../../shared/modules/widgets/booking-choice-list/data/w-booking-choice-list__per-participant__js-template.json')
const bookingChoiceListPerParticipantWithoutRoomsData = require('../../../../shared/modules/widgets/booking-choice-list/data/w-booking-choice-list__per-participant_without-rooms__js-template.json')
const bookingChoiceListPerParticipantWithAppliesToAllData = require('../../../../shared/modules/widgets/booking-choice-list/data/w-booking-choice-list__per-participant-with-applies-to-all__js-template.json')
const bookingChoiceListDifferentPricePerParticipantData = require('../../../../shared/modules/widgets/booking-choice-list/data/w-booking-choice-list__different-price__per-participant__js-template.json')
const bookingChoiceListPerRoomData = require('../../../../shared/modules/widgets/booking-choice-list/data/w-booking-choice-list__per-room__js-template.json')
const bookingChoiceListPerBookingData = require('../../../../shared/modules/widgets/booking-choice-list/data/w-booking-choice-list__per-booking__js-template.json')
const bookingChoiceListBabycotData = require('../../../../shared/modules/widgets/booking-choice-list/data/w-booking-choice-list-baby-cot__js-template.json')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = () => {
  const bookingChoiceListItems = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-choice-list"]')
  bookingChoiceListItems.forEach(item => {
    const bookingChoiceList = new BookingChoiceList(item)
    console.log(bookingChoiceList)
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingChoiceListJsTemplate = document.querySelectorAll('[data-booking-choice-list-demo]')
  handleFetched(bookingChoiceListJsTemplate, bookingChoiceListData)

  const bookingChoiceListJsBabycotTemplates = document.querySelectorAll('[data-booking-choice-list-babycot-demo]')
  handleFetched(bookingChoiceListJsBabycotTemplates, bookingChoiceListBabycotData)

  const bookingChoiceListPerParticipantJsTemplates = document.querySelectorAll('[data-booking-choice-list-per-participant-demo]')
  handleFetched(bookingChoiceListPerParticipantJsTemplates, bookingChoiceListPerParticipantData)

  const bookingChoiceListPerParticipantWithoutRoomsJsTemplates = document.querySelectorAll('[data-booking-choice-list-per-participant-demo-without-rooms]')
  handleFetched(bookingChoiceListPerParticipantWithoutRoomsJsTemplates, bookingChoiceListPerParticipantWithoutRoomsData)

  const bookingChoiceListPerParticipantWithConditionsJsTemplates = document.querySelectorAll('[data-booking-choice-list-per-participant-with-conditions-demo]')
  handleFetched(bookingChoiceListPerParticipantWithConditionsJsTemplates, bookingChoiceListPerParticipantData)

  const bookingChoiceListPerParticipantWithAllJsTemplates = document.querySelectorAll('[data-booking-choice-list-per-participant-with-aplies-to-all]')
  handleFetched(bookingChoiceListPerParticipantWithAllJsTemplates, bookingChoiceListPerParticipantWithAppliesToAllData)

  const bookingChoiceListDifferentPricePerParticipantJsTemplates = document.querySelectorAll('[data-booking-choice-list-different-price-per-participant-demo]')
  handleFetched(bookingChoiceListDifferentPricePerParticipantJsTemplates, bookingChoiceListDifferentPricePerParticipantData)

  const bookingChoiceListPerRoomJsTemplates = document.querySelectorAll('[data-booking-choice-list-per-room-demo]')
  handleFetched(bookingChoiceListPerRoomJsTemplates, bookingChoiceListPerRoomData)

  const bookingChoiceListPerBookingJsTemplates = document.querySelectorAll('[data-booking-choice-list-per-booking-demo]')
  handleFetched(bookingChoiceListPerBookingJsTemplates, bookingChoiceListPerBookingData)

  const bookingChoiceListPerBookingJsTemplatesWithConditions = document.querySelectorAll('[data-booking-choice-list-per-booking-with-conditions-demo]')
  handleFetched(bookingChoiceListPerBookingJsTemplatesWithConditions, bookingChoiceListPerBookingData)
}

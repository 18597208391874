import Component from '../../../js/core/component/component'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { NavTemplate } from '../../components/nav/c-nav.template'
import Swipe from '../../objects/swipe/main'

const widgetApi = 'w-acco-nav'
const navApi = 'c-nav'

const widgetQueries = {
  accoNavMenuWrapper: `[data-${widgetApi}__wrapper]`
}

const classQueries = {
  accoNavMenu: `${widgetApi}__menu`,
  navLink: `${navApi}__link`
}

const classNames = {
  active: 'active',
  open: 'is-open'
}

const accoNavOptions = {
  safeOffset: 120
}

export default class AccoNav {
  /**
   * Creates a new my acco nav.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   */
  constructor (element) {
    if (!element) {
      return
    }
    this.element = element
    this.navWrapper = this.element.querySelector(
      widgetQueries.accoNavMenuWrapper
    )

    this.sectionsRaw = Array.from(
      document.querySelectorAll('[id^="anchorable"]')
    ).sort((a, b) => a.dataset.attrOrder - b.dataset.attrOrder)
    if (this.sectionsRaw) this.sections = this.getSectionsData()

    if (this.sections && this.sections.items.length > 0) {
      this.buildNav(this.sections)
      this.lastExecution = Date.now()

      window.addEventListener('scroll', () => {
        if (Date.now() - this.lastExecution > 100) this.updateActiveNav()
      })
    }
  }

  updateActiveNav () {
    const scrollTopPos = window.scrollY
    let activeSection = null

    this.sectionsRaw.forEach(section => {
      if (section.offsetTop - accoNavOptions.safeOffset <= scrollTopPos) {
        activeSection = section
      }
    })

    if (activeSection) this.setActiveItem(activeSection.id)
  }

  setActiveItem (id) {
    const items = this.navWrapper.querySelectorAll(
      `${widgetQueries.accoNavMenuWrapper} .${classQueries.navLink}.${classNames.active}`
    )
    if (items) items.forEach(item => item.classList.remove(classNames.active))

    // Add active class to the new active item
    const activeItem = this.navWrapper.querySelector(
      `.${classQueries.accoNavMenu} .${classQueries.navLink}[href='#${id}'] `
    )
    if (activeItem) activeItem.classList.add(classNames.active)
  }

  getSectionsData () {
    const sectionProcessed = {
      variant: 'inline',
      extraClasses: classQueries.accoNavMenu,
      id: this.element.id + '--menu',
      track: 'accommodationNav.link',
      attributes: {
        'data-w-acco-nav__menu': ''
      },
      items: []
    }

    this.sectionsRaw.forEach(item => {
      sectionProcessed.items.push({
        id: item.id,
        link: {
          href: `#${item.id}`,
          text: item.dataset.attrTitle
        }
      })
    })

    return sectionProcessed
  }

  buildNav (sections) {
    this.navWrapper.innerHTML = NavTemplate(sections)

    Component.initDocumentComponentsFromAPI(this.element)

    const swipeElement = this.element.querySelector('[data-js-swipe]')
    if (swipeElement) {
      Swipe.CreateInstanceOnElement(swipeElement, {
        activeClass: classNames.open,
        smooth: false
      })
    }

    this.setActiveItem(document.querySelectorAll('[id^="anchorable"]')[0].id)
  }
}

registerWidget(AccoNav, widgetApi)

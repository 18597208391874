import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingCarRental from '../../../../shared/modules/widgets/booking-car-rental/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const data = require('../../../../shared/modules/widgets/booking-car-rental/data/w-booking-car-rental__js-template.json')
const dataSelected = require('../../../../shared/modules/widgets/booking-car-rental/data/w-booking-car-rental__js-template-selected.json')
const dataAddExtraCars = require('../../../../shared/modules/widgets/booking-car-rental/data/w-booking-car-rental__js-template-add-extra-cars.json')
const dataSlider = require('../../../../shared/modules/widgets/booking-car-rental/data/w-booking-car-rental__js-template-slider.json')
const dataIncluded = require('../../../../shared/modules/widgets/booking-car-rental/data/w-booking-car-rental__js-template-included.json')
const dataWithoutCars = require('../../../../shared/modules/widgets/booking-car-rental/data/w-booking-car-rental__js-template-without-available-cars.json')
const dataSelectedSelfService = require('../../../../shared/modules/widgets/booking-car-rental/data/w-booking-car-rental__js-template-selected-selfservice.json')
const dataSelectedSelfServiceNotCancellable = require('../../../../shared/modules/widgets/booking-car-rental/data/w-booking-car-rental__js-template-selected-selfservice-not-cancellable.json')
const dataSelectedSelfServiceNotSelfEditable = require('../../../../shared/modules/widgets/booking-car-rental/data/w-booking-car-rental__js-template-selected-selfservice-not-self-editable.json')
const dataIncludedSelfServiceNotSelfEditable = require('../../../../shared/modules/widgets/booking-car-rental/data/w-booking-car-rental__js-template-included-selfservice-not-self-editable.json')
const dataSelfService = require('../../../../shared/modules/widgets/booking-car-rental/data/w-booking-car-rental__js-template-selfservice.json')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = async () => {
  const bookingCarRentalItems = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-car-rental"]')

  bookingCarRentalItems.forEach(item => {
    const bookingCarRentalElement = new BookingCarRental(item)
    console.log(bookingCarRentalElement)
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingCarRentalJsTemplate = document.querySelectorAll('[data-booking-car-rental-demo]')
  handleFetched(bookingCarRentalJsTemplate, data)

  const bookingCarRentalSelectedJsTemplate = document.querySelectorAll('[data-booking-car-rental-demo-selected]')
  handleFetched(bookingCarRentalSelectedJsTemplate, dataSelected)

  const bookingCarRentalAddExtraCarsJsTemplate = document.querySelectorAll('[data-booking-car-rental-demo-add-extra-cars]')
  handleFetched(bookingCarRentalAddExtraCarsJsTemplate, dataAddExtraCars)

  const bookingCarRentalSliderJsTemplate = document.querySelectorAll('[data-booking-car-rental-demo-slider]')
  handleFetched(bookingCarRentalSliderJsTemplate, dataSlider)

  const bookingCarRentalIncludedJsTemplate = document.querySelectorAll('[data-booking-car-rental-demo-included]')
  handleFetched(bookingCarRentalIncludedJsTemplate, dataIncluded)

  const bookingCarRentalJsTemplateWithoutCars = document.querySelectorAll('[data-booking-car-rental-without-available-cars-demo]')
  handleFetched(bookingCarRentalJsTemplateWithoutCars, dataWithoutCars)

  const bookingCarRentalSelectedSelfServiceJsTemplate = document.querySelectorAll('[data-booking-car-rental-demo-selected-selfservice]')
  handleFetched(bookingCarRentalSelectedSelfServiceJsTemplate, dataSelectedSelfService)

  const bookingCarRentalSelectedSelfServiceNotCancellableJsTemplate = document.querySelectorAll('[data-booking-car-rental-demo-selected-selfservice-not-cancellable]')
  handleFetched(bookingCarRentalSelectedSelfServiceNotCancellableJsTemplate, dataSelectedSelfServiceNotCancellable)

  const bookingCarRentalSelectedSelfServiceNotSelfEditableJsTemplate = document.querySelectorAll('[data-booking-car-rental-demo-selected-selfservice-not-self-editable]')
  handleFetched(bookingCarRentalSelectedSelfServiceNotSelfEditableJsTemplate, dataSelectedSelfServiceNotSelfEditable)

  const bookingCarRentalIncludedSelfServiceNotSelfEditableJsTemplate = document.querySelectorAll('[data-booking-car-rental-demo-included-selfservice-not-self-editable]')
  handleFetched(bookingCarRentalIncludedSelfServiceNotSelfEditableJsTemplate, dataIncludedSelfServiceNotSelfEditable)

  const bookingCarRentalSelfServiceJsTemplate = document.querySelectorAll('[data-booking-car-rental-demo-selfservice]')
  handleFetched(bookingCarRentalSelfServiceJsTemplate, dataSelfService)
}

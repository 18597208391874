import { TitleTemplate, defaultTitleData } from '../../components/title/c-title.template'
import { BulletListTemplate } from '../../components/bullet-list/c-bullet-list.template'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'
import { LabelTemplate } from '../../components/label/c-label.template'
import { SliderTemplate } from '../../components/slider/c-slider.template'
import { SwitchTemplate } from '../../components/switch/c-switch.template'
import { classNames } from './config'
import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/w-booking-luggage__tokens.json')['w-booking-luggage']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/widgets/booking-luggage/data/w-booking-luggage__tokens.json`)['w-booking-luggage'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

/**
 * The BookingLuggageOneClickData contains all data needed to hydrate a BookingChoiceList view
 *
 * @typedef {Object}                  BookingLuggageOneClickData
 *
 */

export const defaultBookingLuggageOneClickData = {

}

export const BookingLuggageOneClickTemplate = (d) => {
  d = { ...defaultBookingLuggageOneClickData, ...d }

  return `
    <div class='w-booking-luggage__one-click sp-stack-children--medium'>
      ${d.oneClickLuggage && d.oneClickLuggage.description
        ? `<p class="m-body m-body--medium">${d.oneClickLuggage.description}</p>`
        : ''
      }
      ${TitleTemplate(
        Object.assign({},
          defaultTitleData,
          {
            size: getTokenClass('one-click-luggage__title-size', 'default', tokens),
            tag: 'h4',
            text: d?.oneClickLuggage?.flightsInformationText
          }
        ))}
        

        ${SliderTemplate({
          id: 'one-clickLuggage-slider',
          extraClasses: 'w-booking-luggage__one-click-options--slider',
          outerNavigation: true,
          center: true,
          stretch: true,
          withGradient: true,
          attributes: {
            'data-loop': false,
            'data-responsive': 'xs:1,sm:2,sml:2,md:3',
            'data-gutter': '12',
            'data-w-booking-luggage__one-click-options--slider': ''
          },
          items: d?.oneClickLuggage?.options?.slice(0, d.configurations.maxOneClickOptionsToBeShown).map(option => OneClickLuggageOptionTemplate(option, d.configurations))
        })
      }
     
      <div class="w-booking-luggage__one-click-actions sp-stack-children--medium">
      ${
        (d.oneClickLuggage && d.oneClickLuggage.options.some(option => option.selected))
        ? BtnTemplate({
          text: d?.configurations?.oneClickLuggageTexts?.removeTextButton || '',
          variant: 'flat-neutral',
          icon: 'bin',
          jsApi: true,
          extraClasses: 'w-booking-luggage__one-click-remove-button',
          attributes: { 'data-w-booking-luggage__one-click-remove-button': '' }
        })
        : ''
      }
      ${SwitchTemplate(Object.assign({}, d?.oneClickLuggage?.switchViewsInfo))}
      </div>
      
    </div>
  `
}

export const OneClickLuggageOptionTemplate = (option, configurations) => {
  return ` <div class="w-booking-luggage__one-click-option ${option.selected ? classNames.optionSelected : ''}" 
    data-w-booking-luggage__one-click-option
    data-w-booking-luggage__one-click--option-code="${option.value}">
    ${option.highlightText
      ? LabelTemplate({
        text: option.highlightText,
        variant: 'badge',
        size: 'micro',
        state: 'success',
        extraClasses: 'w-booking-luggage__one-click-option-label'
      })
      : ''}
  <div class="w-booking-luggage__one-click-title ${getTokenClass('one-click-luggage-option__title-size', 'default', tokens)}">${option.text}</div>
  ${BulletListTemplate(option.usps)}
  <div class="w-booking-luggage__one-click-price-block">
    <span class="w-booking-luggage__one-click-price-description  m-body m-body--tiny">${option.priceDescription}</span>
    <span class="w-booking-luggage__one-click-price  m-body m-body--large">${option.priceFormatted}</span>
  </div>
  <div class="w-booking-luggage__one-click-cta">
    ${
      option.selected
      ? `${BtnTemplate({
          ...defaultButtonData,
          ...{
            text: configurations?.oneClickLuggageTexts?.unselectTextButton || '',
            variant: 'flat-neutral',
            block: true,
            extraClasses: 'w-booking-luggage__one-click-option--remove-button'
          }
        })}
        ${option.extraContent ? `<span class="m-body m-body--small">${option.extraContent}</span>` : ''}
      `
      : BtnTemplate({
        ...defaultButtonData,
        ...{
          text: configurations?.oneClickLuggageTexts?.selectTextButton || '',
          variant: 'neutral',
          block: true,
          extraClasses: 'w-booking-luggage__one-click-option--select-button',
          attributes: {
            'data-w-booking-luggage__one-click-option--select-button': '',
            'data-w-booking-luggage__one-click--option-code': option.value
          }
        }
      })
    }
  </div>
</div>`
}

import BookingItem from '../booking-item/main'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import BookingBundleDataMapper from './data-mapper'
import { BookingBundleTemplate } from './booking-bundle.template'
import Img from '../../components/img/main'
import { bookingItemEvents } from '../../../js/document/event-types'
import { cleanUpSpecialCharacters, toCamelCase } from '../../../js/helpers/string'
import registeredEvents from '../../../js/helpers/registered-events'

const widgetApi = 'w-booking-bundle'

const widgetQueries = {
  configurationElement: `[data-${widgetApi}__configuration]`,
  buttonItem: '[data-w-booking-bundle__btn]'
}

const attr = {
  trackAttr: 'data-track',
  bundleButton: 'data-w-booking-bundle__btn',
  optionCode: 'data-w-booking-bundle__code'
}

const apis = {
  buttonApi: 'c-btn'
}

const mapPatchOptions = {
  remove: 'Remove',
  add: 'Add'
}

export const configOptions = {
  bundleTexts: 'bundleTexts',
  bundleImage: 'bundleImage',
  bundleUsps: 'bundleUsps'
}

export default class BookingBundle extends BookingItem {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   */
  constructor (element) {
    super(element)
    this.element = element
    this.dataMapper = new BookingBundleDataMapper()
    const filterConfigurationElement = this.element.querySelector(widgetQueries.configurationElement)
    this.configurations = this._readOptionsFromConfigurations(filterConfigurationElement)
    this.serviceName = null

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.trackAttr) && { track: this.element.attributes[attr.trackAttr].value }
    })
  }

  /**
   *
   * @param {Object}    data      - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  _updateWidgetData () {
    let widgetData

    this.apiData = this.data.services && this.data.services.find(service => service.componentId === this.componentId)

    if (this.apiData) {
      this.serviceName = cleanUpSpecialCharacters(this.apiData.aName) || ''
      this.apiData = { ...this.apiData, ...{ participants: this.data.participants, rooms: this.data.rooms } }
      widgetData = this.dataMapper.mapWidgetData(this.apiData, this.configurations)
    }

    if (widgetData) {
      super.showComponent()
      this._init(widgetData)
    } else {
      super.hideComponent()
    }
  }

  getServiceName () {
    return this.serviceName || toCamelCase(widgetApi) || ''
  }

  _init (data) {
    const renderedHtml = BookingBundleTemplate(data)
    const newContent = elementFromString(renderedHtml)
    Img.createInstancesOnDocument(newContent)
    Component.initDocumentComponentsFromAPI(newContent)

    this._render(newContent)
    this._attachEvents()
  }

  _render (el) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(el)
  }

  _attachEvents () {
    const buttonElements = [...this.element.querySelectorAll(widgetQueries.buttonItem)]
    this.buttonApis = buttonElements.map(buttonElement => buttonElement[apis.buttonApi])
      .filter(api => api)

    this.buttonApis.forEach(buttonApi => {
      buttonApi.events.on('clickButton', (element) => this._clickedBundle(element))
    })
  }

  _clickedBundle (btn) {
    const bundleCode = btn.getAttribute(attr.optionCode)
    const eventArgs = {
      method: this._method,
      url: this._url,
      body: this._mapBody(bundleCode),
      componentId: this.componentId
    }
    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  _mapBody (bundleCode) {
    const bundleClicked = this.apiData && this.apiData.options.find(option => option.code === bundleCode)

    return {
      operationType: bundleClicked.isSelected ? mapPatchOptions.remove : mapPatchOptions.add,
      newService: {
        code: bundleClicked.code,
        participants: bundleClicked.appliesToParticipants,
        startDate: bundleClicked.startDate || '',
        endDate: bundleClicked.endDate || ''
      },
      oldService: null
    }
  }

  _readOptionsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)
      const priceConfigurations = super.readPriceConfigFromConfigurations(configuration)

      return {
        ...priceConfigurations,
        bundleTexts: configuration[configOptions.bundleTexts],
        bundleImage: configuration[configOptions.bundleImage],
        bundleUsps: configuration[configOptions.bundleUsps]
      }
    }
  }
}

registerWidget(BookingBundle, widgetApi)

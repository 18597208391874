import { TitleTemplate } from '../../../components/title/c-title.template'
import { BtnTemplate } from '../../../components/btn/c-btn.template'

/**
 * The defaultBookingCancellationFlowGenericResponseTemplate contains all data to render confirmation screen
 */

export const defaultBookingCancellationFlowGenericResponseTemplate = {
  isSuccess: false,
  title: '',
  text: '',
  confirm: ''
}

export const BookingCancellationFlowGenericResponseTemplate = (d) => {
  d = { ...defaultBookingCancellationFlowGenericResponseTemplate, ...d }
  return `
      <div class="w-booking-cancellation-flow__body sp-stack-children--medium w-booking-cancellation-flow__cancellation-response">
        <div class="m-icon  m-icon--size-huge ${(d.isSuccess ? ' w-booking-cancellation-flow__confirmed__icon m-icon--checkmark-circle' : ' w-booking-cancellation-flow__error__icon  m-icon--information-circle')}"></div>
        ${TitleTemplate({
          text: d.title,
          size: 'default',
          extraClasses: (d.isSuccess ? 'w-booking-cancellation-flow__confirmed__title' : 'w-booking-cancellation-flow__error__title')
        })}
        <div class="m-body">
           ${d.text}
        </div>
        <div class="w-booking-cancellation-flow__buttons">
          ${d.confirm
            ? `${BtnTemplate({
              text: d.confirm,
              variant: 'flow',
              jsApi: true,
              attributes: d.isSuccess ? { 'data-w-booking-cancellation-flow__options-done': '' } : { 'data-w-booking-cancellation-flow__close_modal': '' }
            })}`
            : ''}
        </div>
    </div>
    `
}

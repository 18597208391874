import { BtnTemplate } from '../../components/btn/c-btn.template'
import { ModalV2Template } from '../../components/modal-v2/c-modal-v2.template'

export const defaultBookingErrorModalTemplate = {
  id: '',
  modalSize: 'small',
  track: null,
  bodyContent: '',
  isAccoRedirect: true
}

export const BookingErrorModalTemplate = (d) => {
  d = { ...defaultBookingErrorModalTemplate, ...d }
  return `
      ${ModalV2Template({
        size: d.modalSize,
        id: d.id,
        async: false,
        extraClasses: 'w-booking-error__modal',
        attributes: { 'data-w-booking-error__modal': '' },
        closable: false,
        title: d.title,
        bodyContent: d.bodyContent,
        footerContent: BookingErrorModalFooterTemplate(d)
      })}
    `
}

const BookingErrorModalFooterTemplate = (d) => {
  return BtnTemplate({
    text: d.isAccoRedirect ? d.redirectAccoButtonText : d.redirectSearchButtonText,
    variant: 'flow',
    extraClasses: 'w-booking-error__modal-btn',
    attributes: { 'data-w-booking-error__modal-btn': '' },
    track: d.track
  })
}

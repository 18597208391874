import { registerWidget } from '../../../js/core/widget/widget-directory'
import BookingItem from '../booking-item/main'

const classNames = {
  hidden: 'is-hidden'
}

const widgetApi = 'w-booked-service-error'

export default class BookedServiceError extends BookingItem {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   */
  constructor (element) {
    super(element)
    this.element = element
    this._hideErrorComponent()
  }

  enableUI () {
    // when fetch() works, the UI is enabled again
    // by defult, booking-error must be hidded when enabling UI
    // super.enableUI()
    super._hideUnresolvedAndLoading()
    this._hideErrorComponent()
  }

  setHasError () {
    // intentionally not calling base method super.setHasError()
    super._hideUnresolvedAndLoading()
    this._showErrorComponent()
  }

  _hideErrorComponent () {
    this.element.classList.add(classNames.hidden)
  }

  _showErrorComponent () {
    this.element.classList.remove(classNames.hidden)
  }
}

registerWidget(BookedServiceError, widgetApi)

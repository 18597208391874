import { BoxTemplate } from '../../components/box/c-box.template'

/**
 *  The ProfileData contains the information needed for each age profile to be rendered.
 *
 *  @typedef  {Object}         AgeProfileData
 *
 *  @property {String}         title               -  Title of the profile (example: 'adults', 'children' or 'babies')
 *  @property {String}         text                -  Text to describe the profile
 *
 */

/**
 * The RoomSelectorDropdownTemplateData contains all data needed to hydrate a RoomSelector view
 *
 * @typedef {Object}            RoomSelectorAgeProfilesMessageData
 *
 * @property {String}           [headerTitle]       - Title text that will be shown always
 * @property {String}           [headerToggleText]  - Text to show in the link to expand the content of the message
 * @property {String}           [contentTitle]      - Title text to show on the header of the content part that can be collapsed.
 * @property {String}           [collapseId]        - Identifier for the collapsible
 * @property {AgeProfileData[]} [profiles]          - Array of profiles that will be rendered as part of the content
 */
export const defaultRoomSelectorAgeProfilesMessageData = {
  headerTitle: '',
  headerToggleText: '',
  contentTitle: '',
  collapseId: '',
  profiles: []
}

export const RoomSelectorAgeProfilesMessageTemplate = (d) => {
  d = { ...defaultRoomSelectorAgeProfilesMessageData, ...d }

  const result = ` <div class="w-room-selector__age-profile-message">
                    ${BoxTemplate({
                      html: `
                        <div class="w-room-selector__age-profile-message-title">
                          <span class="w-room-selector__age-profile-message-title-text">${d.headerTitle}</span>
                          <button type="button" class="m-button--clean c-collapse__trigger w-room-selector__age-profile-message-trigger-btn" data-c-collapse__id="${d.collapseId}"  data-c-collapse__action="toggle">
                            <span class="c-collapse__trigger-text m-body--small">${d.headerToggleText}</span>
                            <span class="m-icon m-icon--chevron-down c-collapse__trigger-icon"></span>
                          </button>
                        </div>
                        <div class="c-collapse" data-js-component="c-collapse" id="${d.collapseId}">
                          <div class="w-room-selector__age-profile-message-content-collapse">
                            <div>${d.contentTitle}</div>
                            <table class="w-room-selector__age-profile-message-profiles">
                              ${d.profiles
                                ? `${d.profiles.map(profile => `
                                    <tr class="w-room-selector__age-profile-message-profile-cell">
                                      <td class="w-room-selector__age-profile-message-profile-title">${profile.title}</td>
                                      <td class="w-room-selector__age-profile-message-profile-text">${profile.text}</td>
                                    </tr>
                                  `).join('')}`
                                : ''}
                            </table>
                          </div>
                        </div>`,
                      extraClasses: 'w-room-selector__age-profile-message-box cs-default cs-default--light'
                    })}
                  </div>`
  return result
}

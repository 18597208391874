import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingBundle from '../../../../shared/modules/widgets/booking-bundle/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const data = require('../../../../shared/modules/widgets/booking-bundle/data/w-booking-bundle__js-template.json')
const dataSelected = require('../../../../shared/modules/widgets/booking-bundle/data/w-booking-bundle__selected-js-template.json')

loadQueueHelper.add(function () {
  initBookingBundle()
}, 2)

const initBookingBundle = async () => {
  const bookingBundleItems = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-bundle"]')

  bookingBundleItems.forEach(item => {
    const bookingBundleElement = new BookingBundle(item)
    console.log(bookingBundleElement)
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingBundleJsTemplate = document.querySelectorAll('[data-booking-bundle-demo]')
  handleFetched(bookingBundleJsTemplate, data)

  const bookingBundleSelectedJsTemplate = document.querySelectorAll('[data-booking-bundle-selected-demo]')
  handleFetched(bookingBundleSelectedJsTemplate, dataSelected)
}

import BookingItem from '../booking-item/main'
import { registerWidget } from '../../../js/core/widget/widget-directory'

export const widgetApi = 'w-booking-awaitable-popup'

const widgetQueries = {
  modal: `[data-${widgetApi}__modal]`,
  modalApi: 'c-modal-v2',
  acceptButton: `[data-${widgetApi}__accept-btn]`,
  closeButton: `[data-${widgetApi}__close-btn]`,
  modalBody: `[data-${widgetApi}__modal-body]`
}

export default class BookingAwaitablePopup extends BookingItem {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   */
  constructor (element) {
    super(element)
    this.element = element
    this.element[widgetApi] = this
    this.modal = this.element.querySelector(widgetQueries.modal)
    this.modalBody = this.element.querySelector(widgetQueries.modalBody)
    this.acceptButton = this.element.querySelector(widgetQueries.acceptButton)
    this.closeButton = this.element.querySelector(widgetQueries.closeButton)
    this._attachEvents()
  }

  _attachEvents () {
    if (this.modal) {
      this.modalApi = this.modal[widgetQueries.modalApi]
      this.modalApi && this.modalApi.events.on('closed', () => {
        this.continue = true
        this.result = false
      })
    }
    if (this.acceptButton) {
      this.acceptButton.addEventListener('click', () => {
        this.continue = true
        this.result = true
      })
    }
  }

  /**
   * Function to be executed before moving to the next step.
   * It will open the pop up if present, and will not contiue to the next step
   * till the customer doesn't accept the popup window
   */
  async beforeNextStep () {
    this.result = false
    this.continue = false
    this.modalApi && this.modalApi.open()
    await this._waitUserInput()
    return this.result
  }

  /**
   * Execute till the customer does any action with the popup
   */
  async _waitUserInput () {
    while (!this.continue) await new Promise(resolve => setTimeout(resolve, 50))
    this.continue = false
  }
}

registerWidget(BookingAwaitablePopup, widgetApi)

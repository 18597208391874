/**
 * A mapper to convert from "Sitecore Api response" to "internal widget model".
 */

import { getTokenClass } from '../../../js/helpers/tokens'

// Import tokens
const tokensShared = require('./data/w-booking-price-overview__tokens.json')['w-booking-price-overview']
const tokensBrand = (() => {
  try {
    return require(`../../../../brands/${WEBPACK_BRAND}/modules/widgets/booking-price-overview/data/w-booking-price-overview__tokens.json`)['w-booking-price-overview'] // eslint-disable-line
  } catch (error) {
    return {}
  }
})()
const tokens = { ...tokensShared, ...tokensBrand }

export default class BookingPriceOverviewDataMapper {
  map (data) {
    return {
      tableContent: {
        items: data.priceOverviewItems ? this._mapPriceOverviewItems(data.priceOverviewItems) : [],
        warningMessage: data.warningMessage,
        mandatoryCostHeader: this._mapMandatoryCostHeader(data.mandatoryCostsHeader) || {},
        mandatoryCosts: data.mandatoryCosts ? data.mandatoryCosts.map(mandatoryCost => mandatoryCost).join('<br>') : []
      }
    }
  }

  _mapPriceOverviewItems (priceOverviewItems) {
    return priceOverviewItems.map(item => {
      const subitems = item.children ? item.children.filter(child => child.concept).map(child => this._mapItem(child)) : []

      const itemTranslated = {
        concept: item.concept || '',
        value: item.price,
        subitems,
        total: item.isTotal,
        isDiscount: item.isDiscount,
        isInfo: item.isInfo,
        icon: item.icon || false,
        topItemsExtraClasses: getTokenClass('booking-price-overview__headings', 'topItems', tokens)
      }
      return itemTranslated
    })
  }

  _mapItem (item, isFirstLevel = true) {
    if (!item.concept) { return }

    const children = item.children ? item.children.map(child => this._mapItem(child, false)) : []

    return {
      ...this._mapItemData(item, isFirstLevel),
      subitems: children
    }
  }

  _mapItemData (data, isFirstLevel) {
    if (!data) { return }

    return {
      concept: data.concept,
      isInfo: data.isInfo,
      icon: data.icon,
      value: data.price || '',
      extraClass: !isFirstLevel ? 'w-booking-price-overview__subitem--child' : ''
    }
  }

  _mapMandatoryCostHeader (text = '') {
    if (!text) { return }

    return {
      text,
      size: getTokenClass('booking-price-overview__headings', 'mandatoryCosts', tokens)
    }
  }
}

import { BookingFilterableServicesFilterTemplateRenderer } from './w-booking-filterable-services.template'
import { CalendarFieldTemplate } from '../../components/calendar-field/c-calendar-field.template'
import { ReturnMissingDates } from './calendar-helper'

export const BookingFilterableServicesDateTimeFilterTemplate = (filter, filters, service, configurations) => {
  return `
  <div class="w-booking-filterable-services__filter w-booking-filterable-services__filter-${filter.columnName}" data-w-booking-filterable-services__filters-calendar-field>
      <script type="application/json" data-c-calendar-field__calendar-data-model>
      ${JSON.stringify({
        selectedDates: [filter.selectedValue || filter.values[0]],
        selectedDate: filter.selectedValue || filter.values[0],
        disabledDates: ReturnMissingDates(filter.values),
        startDate: filter.values[0],
        endDate: filter.values[filter.values.length - 1]
      })}
    </script>
    ${CalendarFieldTemplate({
      uid: filter.uid,
      date: {
        label: filter.filterLabel,
        icon: filter.filterIcon
      },
      acceptButtonText: service.dateAcceptButton,
      cancelButtonText: service.dateCancelButton,
      attributes: {
        'data-c-calendar-field__filters-calendar': ''
      }
    })
    }
  </div>
  ${BookingFilterableServicesFilterTemplateRenderer(filters, service, configurations)}`
}

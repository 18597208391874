export default class CryptoHelper {
  /**
   * @constructor
   *
   * @param {String} algorithmName  - The name of the algorithm
   * @param {String} ivString  - The string to generate iv to use on encryption
   * @param {Number} ivLength  - The iv length
   *
   */
  constructor (algorithmName, ivString, ivLength = 16) {
    this.algorithm = {
      name: algorithmName,
      iv: new window.TextEncoder().encode(
        CryptoHelper.forceStringLength(ivString, ivLength)
      )
    }
  }

  /**
   * Encrypts
   * @param {String} value     - The value to encrypt
   * @param {String} key       - The key to use on encryption
   *
   * @returns {String} encryptedData
   */
  async encrypt (value, key) {
    const secretKey = await this._importSecretKey(CryptoHelper.forceStringLength(key, 32))
    const encryptedData = await window.crypto.subtle.encrypt(
      this.algorithm,
      secretKey,
      new window.TextEncoder().encode(value)
    )
    const result = CryptoHelper.bytesToHexString(encryptedData)
    return result
  }

  /**
   * Decrypts
   * @param {String} encryptedData      - The string to decrypt
   * @param {String} key                - The key to use on decryption
   *
   * @returns {String} value
   */
  async decrypt (encryptedData, key) {
    const secretKey = await this._importSecretKey(CryptoHelper.forceStringLength(key, 32))
    const decryptedData = await window.crypto.subtle.decrypt(
      this.algorithm,
      secretKey,
      CryptoHelper.hexStringToUint8Array(encryptedData)
    )
    const result = CryptoHelper.bytesToASCIIString(decryptedData)
    return result
  }

  static isSupported () {
    return window.crypto && window.crypto.subtle && window.TextEncoder
  }

  _importSecretKey (rawKey) {
    const keyData = new window.TextEncoder().encode(rawKey)
    return window.crypto.subtle.importKey(
      'raw', // format
      keyData,
      this.algorithm.name,
      true, // extractable
      ['encrypt', 'decrypt'] // usages
    )
  }

  static forceStringLength (str, length) {
    str = str || 'abc'
    return Array(length)
      .fill(0)
      .map((e, i) => str[i % str.length])
      .join('')
  }

  static bytesToHexString (bytes) {
    if (!bytes) return null
    bytes = new Uint8Array(bytes)
    return Array(bytes.length)
      .fill(0)
      .map((pos, i) => {
        const hexValue = bytes[i].toString(16)
        return hexValue.length < 2 ? `0${hexValue}` : hexValue
      })
      .join('')
  }

  static hexStringToUint8Array (hexString) {
    return new Uint8Array(hexString.length / 2)
      .fill(0)
      .map((pos, i) => {
        return parseInt(hexString.substr(i * 2, 2), 16)
      })
  }

  static bytesToASCIIString (bytes) {
    return String.fromCharCode.apply(null, new Uint8Array(bytes))
  }
}

import { BreakdownTableTemplate, defaultBreakdownTableData } from '../../components/breakdown-table/c-breakdown-table.template'
import { defaultTitleData, TitleTemplate } from '../../components/title/c-title.template'
import { CollapseTemplate } from '../../components/collapse/c-collapse.template'
import { CollapseTriggerTemplate, defaultCollapseTriggerData } from '../../components/collapse/c-collapse__trigger.template'

/**
 * The BookingParticipantSummaryTemplate contains all the data needed to hydrate a BookingParticipantSummary component
 *
 * @typedef {Object}      BookingParticipantSummaryTemplate
 *
 * @property {String}     variant            - The variant that will be used
 */

export const defaultBookingParticipantSummaryData = {
  id: ''
}

export const BookingParticipantSummaryTemplate = (d) => {
  d = { ...defaultBookingParticipantSummaryData, ...d }
  return `
  <div>
    ${CollapseTemplate({
      id: `${d.id}__collapse`,
      maxItems: 1,
      html: `
        <div>
          <div class="w-booking-participant-summary__heading-extra-content-title">
            ${TitleTemplate(Object.assign({}, defaultTitleData, { size: 'tiny', tag: 'empty', text: d.participants[0].title, extraClasses: 'w-booking-participant-summary__heading-title' }))}
          </div>
          ${BreakdownTableTemplate(Object.assign({}, defaultBreakdownTableData, {
            items: d.participants[0].items,
            variant: 'stripped',
            extraClasses: 'w-booking-participant-summary__table',
            tableItemExtraClasses: 'w-booking-participant-summary__table-item'
          }))}
        </div>
        ${d.participants.map(function (participant, index) {
          return index !== 0
            ? `<div class="w-booking-participant-summary__heading-extra-content-title">
                ${TitleTemplate(Object.assign({}, defaultTitleData, { size: 'tiny', tag: 'empty', text: participant.title, extraClasses: 'w-booking-participant-summary__heading-title' }))}
              </div>
              ${BreakdownTableTemplate(Object.assign({}, defaultBreakdownTableData, {
                items: participant.items,
                variant: 'stripped',
                extraClasses: 'w-booking-participant-summary__table',
                tableItemExtraClasses: 'w-booking-participant-summary__table-item'
              }))}`
            : ''
        }).join('')}`
    })}
    ${CollapseTriggerTemplate(Object.assign({}, defaultCollapseTriggerData, { id: `${d.id}__collapse`, text: d.seeMoreText, action: 'open', extraClasses: 'w-booking-participant-summary__collapse' }))}
    ${CollapseTriggerTemplate(Object.assign({}, defaultCollapseTriggerData, { id: `${d.id}__collapse`, text: d.seeLessText, action: 'close', extraClasses: 'w-booking-participant-summary__collapse' }))}
  </div> 
  `
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'

loadQueueHelper.add(function () {
  if (!document.getElementById('cookie-emergency-dialog-docs')) return

  const show = (elem) => (elem.style.display = 'block')
  const hide = (elem) => (elem.style.display = 'none')
  const toggle = (elem) => elem.style.display === 'none' ? show(elem) : hide(elem)

  const cookieEmergencyDialogElem = document.getElementById('cookie-emergency-dialog-docs')
  const cookieElement = cookieEmergencyDialogElem.querySelector('#master-cookie')
  const emergencyElement = cookieEmergencyDialogElem.querySelector('#alert-dialog')
  cookieEmergencyDialogElem.classList.add('in')
  hide(cookieElement)
  hide(emergencyElement)

  document.querySelector('.cookie-dialog-btn').addEventListener('click', () => {
    if (toggle(cookieElement) === 'block') { hide(emergencyElement) }
  })
  document.querySelector('.emergency-dialog-btn').addEventListener('click', () => {
    if (toggle(emergencyElement) === 'block') { hide(cookieElement) }
  })
}, 2)

import { registerWidget } from '../../../js/core/widget/widget-directory'
import { BookingBox } from '../booking-box/main'
import webStorage from '../../../js/document/web-storage'
import DataMapper from './data-mapper'
import { BookingParticipantsFormTemplate } from './w-booking-participants-form.template'
import { apiCaller } from '../../../js/helpers/api-caller'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import { getParamsFromURLForBookingSteps } from '../../../js/document/url'
import { ModalTemplate } from '../../components/modal/c-modal.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { collapseEvents } from '../../components/collapse/event-types'
import registeredEvents from '../../../js/helpers/registered-events'
import { bookingParticipantsFormEvents } from '../../../js/document/event-types'

require('formdata-polyfill')

const widgetApi = 'w-booking-participants-form'
const antiForgeryTokenName = '__RequestVerificationToken'

const apis = {
  collapse: 'c-collapse',
  formApi: 'c-form',
  dateSelectorApi: 'c-date-selector',
  modalApi: 'c-modal',
  btnApi: 'c-btn'
}

const classList = {
  headerSelected: 'w-booking-participants-form__header-selected'
}

const widgetQueries = {
  form: `[data-${widgetApi}__form]`,
  antiForgeryToken: `[name="${antiForgeryTokenName}"]`,
  mainBookerField: `[data-${widgetApi}__mainbooker]`,
  modalError: `[data-${widgetApi}__modal-error]`,
  modalErrorRefreshButton: `[data-${widgetApi}__modal-error-refresh-button]`,
  collapseSectionHeader: '[data-c-form__collapse-header]',
  collapseSection: '[data-c-form__section-collapse]',
  formSectionTitle: '.c-form-section__title'
}

export const attr = {
  mainBookerField: `data-${widgetApi}__mainbooker`,
  participantField: `data-${widgetApi}__participant_`,
  fieldType: `data-${widgetApi}__field-type`,
  track: 'data-track',
  form: {
    action: 'formActionUrl',
    method: 'formMethod'
  },
  participantSectionsCollapsed: 'wBookingParticipantsForm__participantSectionsCollapsed',
  participantFormSectionCollapse: 'cForm__sectionCollapse'
}

/**
 * BookingParticipantSummary widget
 */
export default class BookingParticipantsForm extends BookingBox {
  /**
   * Creates a new Booking Participants Form widget.
   *
   * @constructor
   *
   * @param {HTMLElement} element   - The HTML content.
   * @param {Object} [options={}]   - Options.
   */
  constructor (element, options = {}) {
    super(element)
    this.element = element
    this.configurationsUrl = this.element.dataset.configurationsUrl
    this.form = this.element.querySelector(widgetQueries.form)
    this.antiForgeryToken = this.element.querySelector(widgetQueries.antiForgeryToken)
    this.contextItemId = this.element.dataset.componentId
    this.formApi = this.form ? this.form[apis.formApi] : null
    this.disableErrorsRealTime = this.element.dataset.disableErrorsRealTime !== undefined
    this.dataMapper = new DataMapper()
    this.currentParams = { ...getParamsFromURLForBookingSteps() }
    this.isParticipantsFormValid = false
    this.settings = {
      participantSectionsCanBeCollapsed: false
    }
    this.collapseSections = null
    this.collapseSectionApis = null
    this.collapseSectionStates = []
    this.track = this.element.hasAttribute(attr.track) && this.element.attributes[attr.track].value

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.track }
    })

    const participantSectionsCollapsed = this.element.dataset[attr.participantSectionsCollapsed] !== undefined

    if (participantSectionsCollapsed) {
      this.settings = {
        ...this.settings,
        participantSectionsCanBeCollapsed: true
      }
    }

    if (this.formApi) {
      this.method = this.formApi.method
      this.action = this.formApi.action
    }

    this.formInputsSelectors = [
      { api: 'c-textbox', propName: 'value', setFunction: this._setPropValue.bind(this) },
      { api: 'c-dropdown', propName: 'value', setFunction: this._setDropdownPropValue.bind(this) },
      { api: 'c-choice-list', propName: 'value', setFunction: this._setChoiceListValue.bind(this) },
      { api: 'c-date-selector', propName: 'date', setFunction: this._setPropValue.bind(this) },
      { api: 'c-phone-input', propName: 'value', getPropsForWebStorage: this._getPhonePropValues.bind(this), setFunction: this._setPhonePropValue.bind(this) }
    ]

    this.element[widgetApi] = {
      setSectionsAsCollapse: (this.setSectionsAsCollapse).bind(this)
    }

    this._enableFormInputsErrors()
    this._attachEvents()
  }

  setSectionsAsCollapse () {
    this.settings = {
      ...this.settings,
      participantSectionsCanBeCollapsed: true
    }
  }

  async handleFetched (data) {
    this.data = data.response
    await this._fetchFormConfiguration()
    this._populateParticipantsForm(this.data)
    if (this.settings.participantSectionsCanBeCollapsed) {
      this._addExtraHTMLToBreakLine('PostalCode')
      this._openParticipantCollapseSection(this.data.participants[0].id)
      this._updateAllParticipantsCollapseHeadings()
    }
    return super.handleFetched(this.data)
  }

  async _fetchFormConfiguration () {
    if (this.configurationsUrl) {
      await this._updateWidgetData()
    }
  }

  async _updateWidgetData () {
    const result = await apiCaller(this._enrichUrlWithDefaultQueryString(this.configurationsUrl))
    if (result.success) {
      let widgetData

      let apiData = result.response

      if (apiData) {
        apiData = { ...apiData, ...{ participants: this.data.participants } }
        widgetData = this.dataMapper.mapBookingParticipantsForm(apiData, this.settings)
      }

      if (widgetData && widgetData.formSections) {
        this.isParticipantsFormValid = true
        this.render(widgetData)
      } else {
        this._setComponentError('Mapping Error: ' + result.response.error, this._enrichUrlWithDefaultQueryString(this.configurationsUrl))
      }
    } else {
      this._setComponentError('API Error: ' + result.error, this._enrichUrlWithDefaultQueryString(this.configurationsUrl))
    }
  }

  render (widgetData) {
    const formAttributes = this._readFormConfigFromAttributes()
    const renderedHtml = BookingParticipantsFormTemplate(widgetData, formAttributes)
    this._renderHTML(renderedHtml, this.bodyElement)
    this._initForm()
  }

  _setComponentError (message, url) {
    this.isParticipantsFormValid = false

    if (window.newrelic) {
      window.newrelic.noticeError('[BookingParticipantsForm] Error in url: ' + url + ' with message : ' + message)
    }

    const renderedHtmlModalError = ModalTemplate({
      attributes: { 'data-w-booking-participants-form__modal-error': '' },
      id: `${this.contextItemId}-modalError`,
      title: 'Oops...',
      bodyContent: 'Looks like something went wrong at our side. Try refreshing the page',
      extraClasses: 'w-booking-participants-form__modal-error',
      footerContent: `${BtnTemplate({
          variant: 'flow',
          text: 'Refresh',
          attributes: { 'data-w-booking-participants-form__modal-error-refresh-button': '' }
        }
      )}`
    })
    this._renderHTML(renderedHtmlModalError, this.bodyElement)
    this.modalError = this.element.querySelector(widgetQueries.modalError)
    if (this.modalError) {
      this.modalError[apis.modalApi].open()
      this.modalError[apis.modalApi].events.on('closed', () => {
        super.hideComponent()
      })

      this.modalErrorRefreshButton = this.modalError.querySelector(widgetQueries.modalErrorRefreshButton)
      this.modalErrorRefreshButton && this.modalErrorRefreshButton[apis.btnApi].events.on('clickButton', () => {
        window.location.reload()
      })
    }
  }

  _renderHTML (html, targetElement) {
    const newContent = elementFromString(html)
    flush(targetElement)
    targetElement.appendChild(newContent)
    Component.initDocumentComponentsFromAPI(newContent)
  }

  _initForm () {
    this.form = this.element.querySelector(widgetQueries.form)
    if (this.form) {
      this.formApi = this.form['c-form']
    }

    if (this.formApi) {
      this.method = this.formApi.method
      this.action = this.formApi.action
    }

    this._enableFormInputsErrors()
    this._initHtmlCollapseElements()
    this._attachEvents()
  }

  _initHtmlCollapseElements () {
    if (this.settings.participantSectionsCanBeCollapsed) {
      this.collapseSections = [...this.form.querySelectorAll(widgetQueries.collapseSection)]
      this.collapseSectionApis = this.collapseSections && this.collapseSections.map(collapseElement => collapseElement[apis.collapse])
        .filter(api => api)
    }
  }

  async handleValidationClient () {
    let validations = []
    if (this.formApi) {
      validations = this.formApi.validate()
    }
    const failedValidations = validations.filter(validation => !validation.isValid)
    failedValidations && failedValidations.forEach(validation => {
      const participantId = validation.fieldName.split('_')[1] || null
      this._openParticipantCollapseSection(participantId)
    })
    return this.isParticipantsFormValid && validations.every(v => v.isValid)
  }

  /**
   * This is the command that the mediator will send to all the registered items once a
   * validation at server side has to be performed. The call is done asynchronously
   */
  async handleValidationServer (response) {
    return response.success && super.handleValidationServer(response)
  }

  /**
   * This method is intended to retrieve all the information needed to perform a
   * server side validation
   */
  async getValidationData () {
    const eventArgs = {
      method: this.method,
      url: this.action,
      isForm: true
    }
    if (this._method !== 'GET') {
      const formData = this._getParticipantsData()
      formData.append('contextitemid', this.contextItemId)
      if (this.antiForgeryToken) {
        formData.append(antiForgeryTokenName, this.antiForgeryToken.value)
      }
      eventArgs.body = formData
    }
    return eventArgs
  }

  _attachEvents () {
    this.formInputsSelectors.forEach(input => {
      const inputItems = this.element.querySelectorAll(`.c-form__item.${input.api}`)
      inputItems.forEach(inputElement => {
        inputElement.addEventListener('focusout', (ev) => {
          this._setSessionStorage()
          const currentParticipantId = this._getParticipantIdFromTargetElement(ev.target)
          this._updateParticipantCollapseHeading(currentParticipantId)
          this._trackInputsValidationsIfError(input, inputElement)
        })
      })
    })

    if (this.settings.participantSectionsCanBeCollapsed) {
      this.collapseSectionApis && this.collapseSectionApis.forEach(collapseApi => {
        collapseApi.events.on(collapseEvents.OPENED, (eventArgs) => {
          const collapseId = eventArgs && eventArgs.attr && eventArgs.attr[attr.participantFormSectionCollapse]
          if (collapseId) {
            this.collapseSectionStates[collapseId] = { ...this.collapseSectionStates[collapseId], ...{ id: collapseId, state: collapseEvents.OPENED } }
          }
        })
        collapseApi.events.on(collapseEvents.CLOSED, (eventArgs) => {
          const collapseId = eventArgs && eventArgs.attr && eventArgs.attr.cForm__sectionCollapse
          if (collapseId) {
            this.collapseSectionStates[collapseId] = { ...this.collapseSectionStates[collapseId], ...{ id: collapseId, state: collapseEvents.CLOSED } }
          }
        })
      })
    }
  }

  _enableFormInputsErrors () {
    if (!this.disableErrorsRealTime) {
      this.formInputsSelectors.forEach(input => {
        const inputItems = this.element.querySelectorAll(`.c-form__item.${input.api}`)
        inputItems.forEach(inputElement => {
          if (input.api !== apis.dateSelectorApi) {
            inputElement[input.api].enableErrors()
          }
        })
      })
    }
  }

  _trackInputsValidationsIfError (input, inputElement) {
    if (this.disableErrorsRealTime) { return }

    const inputApi = inputElement[input.api]
    if (inputApi !== apis.dateSelectorApi) {
      setTimeout(() => {
        const inputValidation = inputApi.validate(true)
        if (!inputValidation.isValid) {
          const eventArgs = {
            type: inputValidation.fieldName,
            isValid: inputValidation.isValid,
            errorTypes: inputValidation.errorTypes
          }
          this.events.emit(bookingParticipantsFormEvents.ITEM_FORM_VALIDATON_ERROR, eventArgs)
        }
      }, 150)
    }
  }

  _getParticipantsData () {
    return this.formApi.getFormData()
  }

  _getParticipantsDataForWebStorage () {
    const overrideInputsConfig = [
      { api: 'c-phone-input', functionOverride: this._getPhonePropValues.bind(this) }
    ]
    const options = { format: 'json', inputsConfig: overrideInputsConfig, defaultValue: '' }
    return this.formApi.getFormData(options)
  }

  _getPhonePropValues (componentApi) {
    const phoneProps = {}
    Object.keys(componentApi.props).forEach(key => {
      phoneProps[key] = componentApi.props[key]
    })
    return JSON.stringify(phoneProps)
  }

  _setPropValue (componentApi, propName, propValue) {
    return componentApi.setProp(propName, propValue)
  }

  _setDropdownPropValue (componentApi, propName, propValue) {
    if (componentApi.props.options.find(option => option.value === propValue) !== undefined) {
      return componentApi.setProp(propName, propValue)
    }
  }

  _setChoiceListValue (componentApi, propName, propValue) {
    const defaultOptions = componentApi.getProp('options')
    const newOptions = defaultOptions.map((option) => ({ ...option, checked: (option.value === propValue) }))

    return componentApi.setProp('options', newOptions)
  }

  _setPhonePropValue (componentApi, propName, props) {
    try {
      const propsObj = JSON.parse(props)
      for (const key in propsObj) {
        if (Object.prototype.hasOwnProperty.call(propsObj, key)) {
          componentApi.setProp(key, propsObj[key])
        }
      }
    } catch (err) {
      componentApi.setProp('value', props)
    }
  }

  _setSessionStorage () {
    const identifier = this._getFormIdentifier()
    if (identifier) {
      const participantsData = this._getParticipantsDataForWebStorage()
      webStorage.session.set(identifier, participantsData)
    }
  }

  _getFormIdentifier () {
    const identifier = this.element.dataset.jsWidget
    return identifier !== null ? identifier : undefined
  }

  _retrieveParticipantsDataFromStorage () {
    let participantsData
    const identifier = this._getFormIdentifier()
    if (identifier) {
      participantsData = webStorage.session.get(identifier)
    }
    return participantsData
  }

  _populateParticipantsForm (data) {
    const savedData = this._retrieveParticipantsDataFromStorage()
    if (savedData) {
      this.formInputsSelectors.forEach(input => {
        const inputItems = this.element.querySelectorAll(`.c-form__item.${input.api}`)
        inputItems.forEach(inputElement => {
          const key = inputElement.id ? inputElement.id : inputElement.querySelector('input,select').id
          if (Object.prototype.hasOwnProperty.call(savedData, key) && inputElement[input.api]) {
            input.setFunction(inputElement[input.api], input.propName, savedData[key], { forceUpdate: true })
          }
        })
      })
    } else if (data.participants && data.participants.length > 0) {
      this._setParticipantValueFromApi(data, data.participants[0], true)
      data.participants.slice(1).forEach((p) => {
        this._setParticipantValueFromApi(data, p, false)
      })
    }
  }

  _setParticipantValueFromApi (data, participant, isMainBooker) {
    const participantsFields = this.element.querySelectorAll(`[${attr.participantField}${participant.id}]`)
    participantsFields.forEach(inputElement => {
      const property = inputElement.getAttribute(attr.participantField + participant.id)
      const value = property ? participant[Object.keys(participant).find(key => key.toLowerCase() === property.toLowerCase())] : undefined
      const input = this.formInputsSelectors.find(inputSelector => inputElement.classList.contains(inputSelector.api))
      if (input !== undefined && value !== undefined) {
        input.setFunction(inputElement[input.api], input.propName, value, { forceUpdate: true })
        if (isMainBooker && property === 'email' && data.bookingInfo && data.bookingInfo.originalBookingNumber) {
          inputElement[input.api].element.querySelector('input').disabled = true
        }
      }
    })
  }

  _getFormDataForCollapsedForm () {
    if (!this.formApi) { return }
    return this.formApi.getFormData({ format: 'json' })
  }

  _updateAllParticipantsCollapseHeadings () {
    if (!this.settings.participantSectionsCanBeCollapsed) { return }
    if (this.data.participants && this.data.participants.length > 0) {
      const getCurrentFormData = this._getFormDataForCollapsedForm()
      this.data.participants.forEach((participant) => {
        this._updateParticipantCollapseHeading(participant.id, getCurrentFormData)
      })
    }
  }

  _updateParticipantCollapseHeading (currentParticipantId, currentFormData = null) {
    if (!this.settings.participantSectionsCanBeCollapsed) { return }
    if (!currentParticipantId) { return }

    currentFormData = currentFormData || this._getFormDataForCollapsedForm()
    const formDataForParticipant = this._getFormDataForParticipant(currentParticipantId, currentFormData)
    const collapseHeader = this._findCollapseHeaderSectionPerParticipant(currentParticipantId)

    if (this._checkIfAllParticipantFieldsAreValid(currentParticipantId)) {
      const currentParticipantTitleData = this._getParticipantInformationForTitle(formDataForParticipant)
      const participantTitle = Object.values(currentParticipantTitleData).join(' ')
      this._updateParticipantCollapseHeadingTitle(collapseHeader, participantTitle)
      this._updateParticipantCollapseHeadingState(collapseHeader)

      const allFieldsFromParticipantAreFulfilled = this._checkIfAllParticipantFieldsAreFulfilled(Object.values(formDataForParticipant))
      const currentParticipantIndex = this.data.participants.findIndex(participant => participant.id === Number(currentParticipantId)) || null
      const nextParticipantId = this.data.participants[currentParticipantIndex + 1] ? this.data.participants[currentParticipantIndex + 1].id : null
      this._openParticipantCollapseSection(nextParticipantId, currentParticipantId, allFieldsFromParticipantAreFulfilled)
    } else {
      this._updateParticipantCollapseHeadingState(collapseHeader, true)
    }
  }

  _addExtraHTMLToBreakLine (fieldSelector) {
    if (!fieldSelector) { return }
    const fieldElement = this.form.querySelector(`[${attr.fieldType}="${fieldSelector}"]`)
    const newElement = elementFromString('<break></break>')
    fieldElement && fieldElement.insertAdjacentElement('beforebegin', newElement)
  }

  _checkIfAllParticipantFieldsAreValid (participantId) {
    if (!participantId) { return }
    const participantSectionFieldValidations = this._validateParticipantFields(participantId)
    return participantSectionFieldValidations.every(element => element === true)
  }

  _checkIfAllParticipantFieldsAreFulfilled (participantFieldValues) {
    return participantFieldValues.every(element => element !== '')
  }

  _validateParticipantFields (participantId) {
    if (!participantId) { return }
    const participantsFields = this.form.querySelectorAll(`[${attr.participantField}${participantId}]`)
    const validations = []
    participantsFields.forEach(inputElement => {
      const elementApi = inputElement.dataset.jsComponent || null
      const inputApi = inputElement[elementApi] || null

      const validation = inputApi && inputApi.validate(true)
      validations.push(validation.isValid)
    })
    return validations
  }

  _findCollapseHeaderSectionPerParticipant (participantId) {
    if (!participantId) { return }
    return this.form.querySelector(`${widgetQueries.collapseSectionHeader}[data-c-collapse__id="w-bookings-participants-form__collapse_${participantId}"]`)
  }

  _findCollapsePerParticipant (participantId) {
    if (!participantId) { return }
    return this.form.querySelector(`${widgetQueries.collapseSection}#w-bookings-participants-form__collapse_${participantId}`)
  }

  _updateParticipantCollapseHeadingTitle (collapseHeader, participantTitle) {
    if (!collapseHeader) { return }
    const participantTitleElement = collapseHeader.querySelector(widgetQueries.formSectionTitle)
    if (participantTitleElement) {
      participantTitleElement.innerText = participantTitle
    }
  }

  _updateParticipantCollapseHeadingState (collapseHeader, toBeRemoved = false) {
    if (!collapseHeader) { return }
    toBeRemoved ? collapseHeader.classList.remove(classList.headerSelected) : collapseHeader.classList.add(classList.headerSelected)
  }

  _getParticipantInformationForTitle (formDataForParticipant) {
    const conditions = ['_Name', '_Surname']
    const currentParticipantTitleData = Object.keys(formDataForParticipant)
      .filter((key) => conditions.some(el => key.includes(el)))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: formDataForParticipant[key]
        })
      }, {})

    return currentParticipantTitleData
  }

  _getFormDataForParticipant (participantId, getCurrentFormData) {
    const currentParticipantFormData = Object.keys(getCurrentFormData)
      .filter((key) => key.includes(participantId))
      .reduce((obj, key) => {
        return Object.assign(obj, {
          [key]: getCurrentFormData[key]
        })
      }, {})

    return currentParticipantFormData
  }

  _getParticipantIdFromTargetElement (target) {
    if (!target) { return }
    const currentElementId = target.getAttribute('id')
    const currentElementIdSplitted = currentElementId.split('_')
    return currentElementIdSplitted[1] || null
  }

  _openParticipantCollapseSection (participantId, previousParticipantId = null, allFieldsFromPreviousParticipantFulfilled) {
    if (previousParticipantId && allFieldsFromPreviousParticipantFulfilled) {
      this._closeParticipantCollapseSection(previousParticipantId)
    }

    if (!participantId) { return }

    const collapseParticipant = this._findCollapsePerParticipant(participantId)
    if (collapseParticipant) {
      const collapseState = this._currentCollapseStateSaved(collapseParticipant)
      const canBeOpened = collapseState ? (collapseState !== collapseEvents.CLOSED) : true

      const collapseApi = collapseParticipant[apis.collapse]
      if (collapseApi && !collapseApi.isOpened() && canBeOpened) {
        collapseApi.open({ silent: true })
      }
    }
  }

  _closeParticipantCollapseSection (participantId) {
    if (!participantId) { return }
    const collapseParticipant = this._findCollapsePerParticipant(participantId)
    if (collapseParticipant) {
      const collapseState = this._currentCollapseStateSaved(collapseParticipant)
      const canBeClosed = collapseState ? (collapseState !== collapseEvents.OPENED) : true

      const collapseApi = collapseParticipant[apis.collapse]
      if (collapseApi && collapseApi.isOpened() && canBeClosed) {
        collapseApi.close({ silent: true })
      }
    }
  }

  _currentCollapseStateSaved (collapseParticipant) {
    if (!collapseParticipant) { return }
    let collapseState = null
    const collapseId = collapseParticipant.dataset[attr.participantFormSectionCollapse] || null

    if (this.collapseSectionStates && this.collapseSectionStates[collapseId]) {
      collapseState = this.collapseSectionStates[collapseId].state
    }

    return collapseState
  }

  _readFormConfigFromAttributes () {
    const formAttributes = {
      formAction: this.element.dataset[attr.form.action],
      formMethod: this.element.dataset[attr.form.method],
      track: this.track
    }
    return formAttributes
  }

  _enrichUrlWithDefaultQueryString (url, params) {
    url = url || ''

    const urlData = {
      ...params,
      ...this.currentParams,
      ...{
        contextItemId: this.contextItemId
      }
    }

    const queryString = Object.keys(urlData).map(key => key + '=' + urlData[key]).join('&')
    const andOrQuestionMark = url.includes('?') ? '&' : '?'
    return `${url}${andOrQuestionMark}${queryString}`
  }
}

registerWidget(BookingParticipantsForm, widgetApi)

import { TabsNavTemplate } from '../../components/tabs/c-tabs__nav.template'
import domEventsHelper from '../../../js/document/dom-events-helper'
import Swipe from '../../objects/swipe/main'
import registeredEvents from '../../../js/helpers/registered-events'
const EventEmitter = require('eventemitter3')

const widgetApi = 'w-tabs-filter'
const widgetQueries = {
  track: 'data-track'
}

export default class TabsFilter {
  /**
   * Creates a new TabsFilter
   *
   * @constructor
   *
   * @param {HTMLElement} element - The element where to attach TabsFilter
   * @param {FilterModel} filterModel - The associated FilterModel
   */
  constructor (element, filterModel) {
    this.element = element
    this.events = new EventEmitter()

    this.defaultTabsData = {
      id: `${this.element.id}__tabs`,
      items: [],
      variant: 'secondary',
      extraClasses: ''
    }

    this.setFilterModel(filterModel)

    this.domEvents = [
      [this.element, { click: (ev) => this._onMouseClick(ev) }]
    ]
    domEventsHelper.attachEvents(this.domEvents, widgetApi)

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(widgetQueries.track) && { track: this.element.attributes[widgetQueries.track].value }
    })
  }

  /**
   * Set the current filterModel
   * - Set given filterModel to filterModel self property
   * - Set options on tabs
   * - Bind filter model events
   *
   * @param {FilterModel} filterModel - The associated FilterModel
   *
   * @returns {TabsFilter} self instance
   */
  setFilterModel (filterModel) {
    this.filterModel = filterModel
    // Setting options silently so that value prop changed is not triggered in case it changes when changing options
    this.renderTabs()

    this.filterModel.events.on('change', () => this.renderTabs())

    return this
  }

  /**
   * Set the current Tabs selectedValues to filter
   *
   * @returns {TabsFilter} self instance
   */
  _setTabsOptionsToFilter (value) {
    this.filterModel
      .clearSelection({ silent: true })
      .setSelectedValues([value])
    return this
  }

  /**
   * Get the current Tabs options from filter
   *
   * @returns {TabsSingleOptionData[]} The parsed filter to Tabs options
   */
  _getTabsOptionsFromFilter () {
    return {
      ...this.defaultTabsData,
      items: this.filterModel.values.models.map(model => ({
        text: model.getAttribute('caption'),
        isOpen: model.getAttribute('isSelected'),
        attributes: {
          'data-value': model.getAttribute('value')
        }
      }))
    }
  }

  /**
   * Render the tabs from the current filter data.
   */
  renderTabs () {
    this.element.innerHTML = TabsNavTemplate(this._getTabsOptionsFromFilter())
    const swipeElement = this.element.querySelector('[data-js-swipe]')
    if (swipeElement) Swipe.CreateInstanceOnElement(swipeElement, { activeClass: 'is-open' })
  }

  /**
   * Handle the mouse click.
   *
   * @param {MouseEvent} ev  - The mouse event.
   */
  _onMouseClick (ev) {
    const tabItemCandidate = this._findTabItemByMouseEvent(ev)
    if (!tabItemCandidate) return

    const value = tabItemCandidate.getAttribute('data-value')
    this._setTabsOptionsToFilter(value)
    this.events.emit('tabChanged', value)
  }

  /**
   * Small utility to find a tabItemElement candidate from mouse event, traversing the DOM
   *
   * @param {MouseEvent}  ev       - The mouse event.
   * @returns {(HTMLElement|null)} - The tabItemElement if found
   */
  _findTabItemByMouseEvent (ev) {
    const closestResult = (el, lastParentToCheck) => {
      do {
        if (el === lastParentToCheck) return null
        if (el.matches('button[data-value]')) return el
        el = el.parentElement || el.parentNode
      } while (el !== null && el !== lastParentToCheck && el.nodeType === 1)
      return null
    }
    return closestResult(ev.target, this.element)
  }
}

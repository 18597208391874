/**
 * List of types for the booking-preferences-selector widget
 *
 * standard                    ==> CHECKBOX
 * customerInput               ==> CHECKBOX && TEXTBOX
 */
export const preferenceTypeEnum = {
  Standard: 'Standard',
  CustomerInput: 'CustomerInput'
}

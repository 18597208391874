import { PhotoGalleryElementTemplate } from './c-photo-gallery-element.template'
import { ModalTemplate } from '../../components/modal/c-modal.template'
import { newGUID } from '../../../js/helpers/guid'

export const PhotoGalleryTemplate = (d) => {
  const id = newGUID()
  const modalId = `${id}_modal`
  return `
<div class="c-photo-gallery ${d.extraClasses ? d.extraClasses : ''}" id="${id}" data-js-component="c-photo-gallery">
${d.elements.map((item, index) => (
  PhotoGalleryElementTemplate({
    ...item,
    modalId,
    position: index,
    title: d.popupTitle
  }))
).join('')}
${ModalTemplate({
  id: modalId,
  size: 'large',
  title: d.popupTitle,
  attributes: { 'data-c-photo-gallery__modal': '' }
})}
</div>`
}

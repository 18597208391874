import { BookingChoiceListTemplate } from '../booking-choice-list/w-booking-choice-list.template'

export const BookingFilterableServicesOptionsTemplate = (service, configurations) => {
  const data = {
    rooms: [
      {
        participants: [
          {
            choiceListInfo: {
              id: service.uid,
              name: service.uid,
              method: 'single',
              variant: 'boxed',
              highlighted: false,
              label: service.optionsLabel,
              extraClasses: 'w-booking-filterable-services__service-options-choice-list',
              disabled: false,
              unresolved: false,
              attributes: {
                'data-w-booking-filterable-services__service-options-choice-list': '',
                'data-w-booking-filterable-services__service-id': service.uid
              },
              items: service.filteredOptions
            }
          }
        ]
      }
    ]
  }
  return `<div class="w-booking-filterable-services__filter__content w-booking-filterable-services__options ${configurations.hideOptions ? 'is-hidden' : ''}">
            ${BookingChoiceListTemplate(data)}
          </div>`
}

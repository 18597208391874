import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingAccomodationSummary from '../../../../shared/modules/widgets/booking-accommodation-summary/main'

const data = require('../../../../shared/modules/widgets/booking-accommodation-summary/data/w-booking-accommodation-summary__js-template.json')

loadQueueHelper.add(function () {
  initBookingAccommodationSummary()
}, 2)

const initBookingAccommodationSummary = async () => {
  const bookingAccommodationSummaryItem = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-accommodation-summary"]')

  bookingAccommodationSummaryItem.forEach(element => {
    const bookingAccommodationSummary = new BookingAccomodationSummary(element)
    console.log(bookingAccommodationSummary)
  })

  const bookingAccommodationSummaryJsTemplate = document.querySelector('[data-booking-accommodation-summary-demo]')
  if (bookingAccommodationSummaryJsTemplate) {
    const widget = new BookingAccomodationSummary(bookingAccommodationSummaryJsTemplate.firstElementChild)
    await widget.handleFetched({ response: data })
    await widget.enableUI()
  }
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import JSONFetcher from '../../../../shared/js/helpers/json-fetcher'
import FlightBusSelector from '../../../../shared/modules/widgets/flight-bus-selector/main'

loadQueueHelper.add(function () {
  initFlightBusSelector()
}, 2)

const initFlightBusSelector = () => {
  const flightElement = document.getElementById('my-dynamic-flight-selector')
  if (flightElement) {
    const quickSearchRequest = new JSONFetcher({ src: '../assets/api/flight-selector.json' })
    quickSearchRequest.fetch().then(data => {
      // eslint-disable-next-line no-unused-vars
      const flightBusSelector = new FlightBusSelector(flightElement, { data, type: 'flight' })
    })
  }
  const busElement = document.getElementById('my-dynamic-bus-selector')
  if (busElement) {
    const quickSearchRequest = new JSONFetcher({ src: '../assets/api/bus-selector-2.json' })
    quickSearchRequest.fetch().then(data => {
      // eslint-disable-next-line no-unused-vars
      const flightBusSelector = new FlightBusSelector(busElement, { data, type: 'bus' })
    })
  }
}

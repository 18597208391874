import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import Calendar from '../../../../shared/modules/components/calendar/main'
import { register } from '../../../../shared/js/document/namespace'

loadQueueHelper.add(function () {
  initSecondCalendar()
  initThirdCalendar()
  initFourthCalendar()
}, 2)

const initSecondCalendar = () => {
  const mySecondCalendar = document.getElementById('my-second-calendar')
  if (!mySecondCalendar) return
  console.log('A calendar element found', mySecondCalendar)

  const mySecondCalendarInstance = new Calendar(mySecondCalendar, {
    selectedDates: ['2018-02-12', '2018-02-13', '2018-02-14', '2018-02-15', '2018-02-16', '2018-02-17', '2018-02-18'],
    selectedDate: '2018-02-15',
    disabledDates: ['2018-02-03', '2018-02-13', '2018-02-23'],
    startDate: '2018-01-01',
    endDate: '2018-12-31'
  })
  console.log('Initialized as Calendar component:', mySecondCalendarInstance)
}

const initThirdCalendar = () => {
  const myThirdCalendar = document.getElementById('my-third-calendar')
  if (!myThirdCalendar) return
  console.log('A calendar element found', myThirdCalendar)

  const myThirdCalendarInstance = new Calendar(myThirdCalendar, {
    selectedDates: ['2018-02-12', '2018-02-13', '2018-02-14', '2018-02-15', '2018-02-16', '2018-02-17', '2018-02-18'],
    disabledDates: ['2018-02-03', '2018-02-13', '2018-02-23'],
    startDate: '2018-01-01',
    endDate: '2018-12-31'
  }, {
    mode: 'range'
  })
  console.log('Initialized as Calendar component:', myThirdCalendarInstance)
}

const initFourthCalendar = () => {
  const myFourthCalendar = document.getElementById('my-fourth-calendar')
  if (!myFourthCalendar) return
  console.log('A calendar element found', myFourthCalendar)

  require('../../../../shared/js/locales/_all')
  const flatpickrLocalesNL = register('window.sundio.i18n.nl.flatpickr')
  const dateLocalesNL = register('window.sundio.i18n.nl.dates')

  const myFourthCalendarInstance = new Calendar(myFourthCalendar, {
    selectedDates: ['2018-02-12', '2018-02-13', '2018-02-14', '2018-02-15', '2018-02-16', '2018-02-17', '2018-02-18'],
    disabledDates: ['2018-02-03', '2018-02-13', '2018-02-23'],
    startDate: '2018-01-01',
    endDate: '2018-12-31'
  }, {
    weekNumbers: dateLocalesNL.weekNumbers,
    locale: flatpickrLocalesNL
  })
  console.log('Initialized as Calendar component:', myFourthCalendarInstance)
}

/**
 * The Download button contains all data needed to hydrate the view
 *
 * @typedef {Object}          DownloadButtonData
 *
 * @property {String}        resourceUrl
 * @property {String}        [icon]
 * @property {String}        title
 * @property {String}        [subtitle]
 */

export const defaultDownloadButtonData = {
  resourceUrl: '',
  icon: '',
  title: '',
  subtitle: '',
  isUnresolved: false
}

export const DownloadButtonTemplate = (d) => {
  d = { ...defaultDownloadButtonData, ...d }
  return `
    <a class="c-download-button ${d.isUnresolved ? 'is-unresolved' : ''}"
      ${d.resourceUrl ? `href="${d.resourceUrl}" download` : ''}>
      ${d.icon ? `<span class="m-icon  m-icon--size-medium m-icon--${d.icon}"></span>` : ''}
      <div class="c-download-button__titles">
        <span class="c-download-button__title">${d.title}</span>
        ${d.subtitle ? `<span class="c-download-button__subtitle">${d.subtitle}</span>` : ''}
      </div>
      <span class="c-download-button__download-icon m-icon m-icon--size-medium m-icon--download-circle"></span>
    </a>
  `
}

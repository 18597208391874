import { registerWidget } from '../../../js/core/widget/widget-directory'
import { elementFromString, flush } from '../../../js/document/html-helper'
import { BookingBox } from '../booking-box/main'
import { BookingCarRentalTemplate } from './w-booking-car-rental.template'
import { BookingCarRentalEditDetailsTemplate } from './w-booking-car-rental-edit-details.template'
import { BookingCarRentalCarsListTemplate } from './w-booking-car-rental-cars-list.template'
import BookingCarRentalDataMapper from './data-mapper'
import Component from '../../../js/core/component/component'
import { getAge, isValidDateString } from '../../../js/helpers/dates'
import { widgetApi, widgetQueries, componentsApis, attr, defaults, configOptions, carRentalOperations, config } from './config'
import { bookingItemEvents, bookingCarRentalEvents } from '../../../js/document/event-types'
import { cleanUpSpecialCharacters } from '../../../js/helpers/string'
import CreditCard from './credit-card/credit-card'
import registeredEvents from '../../../js/helpers/registered-events'

export default class BookingCarRental extends BookingBox {
  /**
  * Creates a new my widget.
  *
  * @constructor
  * @param {HTMLElement} element - The HTML element.
  * @param {BookingCarRentalOptions} [options={}] - Options object
  */
  constructor (element, options = {}) {
    super(element)
    if (!element) { return }
    this.element = element
    this.options = { ...defaults, ...options }
    this.dataMapper = new BookingCarRentalDataMapper()
    const filterConfigurationElement = this.element.querySelector(widgetQueries.configurationElement)
    this.configurations = this._readOptionsFromConfigurations(filterConfigurationElement)
    this.collapseEditContainerApis = []
    this.collapseListContainerApis = []
    this.conditionsUrl = this.element.dataset[attr.conditionsUrl]
    this.serviceName = null
    this.previousCarSelected = null
    this.isUpgradingCar = null

    this.carRentalTrack = this.element.hasAttribute(attr.trackAttr) && this.element.attributes[attr.trackAttr].value
    this.creditCard = new CreditCard(this.element)

    if (this.configurations && this.configurations.creditCardConfiguration && this.configurations.creditCardConfiguration.creditCardEnabled) {
      this.creditCard.setCreditCardCheckboxTexts(this.configurations.creditCardConfiguration.creditCardCheckboxLabel, this.configurations.creditCardConfiguration.creditCardCheckboxRequiredMessage)
      this.creditCard.showAndValidateCreditCardCheckbox()
    }

    registeredEvents.registerWidgetEvents(widgetApi, this.events, {
      ...this.element.hasAttribute(attr.trackAttr) && { track: this.carRentalTrack }
    })

    this.element[widgetApi] = {
      setShowCarsInOneRow: (this.setShowCarsInOneRow).bind(this),
      isCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow: (this.setisCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow).bind(this)
    }
  }

  /**
   *
   * @param {Object}    data      - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  _updateWidgetData () {
    this.widgetData = null
    this.apiData = null
    this.apiData = this.data.services && this.data.services.find(service => service.componentId === this.componentId)

    if (this.apiData) {
      this.serviceName = cleanUpSpecialCharacters(this.apiData.aName) || ''
      this.options.maxNumberOfEquipmentsToBeSelected = this.apiData.maxNumberOfEquipments >= 0 ? this.apiData.maxNumberOfEquipments : defaults.maxNumberOfEquipmentsToBeSelected
      this.apiData = { ...this.apiData, ...{ participants: this.data.participants, rooms: this.data.rooms } }
      this.widgetData = this.dataMapper.mapService(this.apiData, this.configurations, this.options)
      this.widgetData = { ...this.widgetData, carRentalTrack: this.carRentalTrack }
      const selectedOptions = this.apiData.options.filter(option => option.isSelected)
      if (selectedOptions.length > 0) {
        super.setSuccessStateAndUpdateTitle()
      } else {
        super.noOptionSelectedAndUpdateTitle()
      }
    }

    if (this.widgetData) {
      this.showComponent()
      this._init(this.widgetData)
    } else {
      this.hideComponent()
    }
  }

  _init (data) {
    const renderedHtml = BookingCarRentalTemplate(data)
    const newContent = elementFromString(renderedHtml)
    this._render(newContent)
    Component.initDocumentComponentsFromAPI(newContent)

    this._initHtmlElements()
    this._initApis()
    this._attachEvents()
    this._setCollapseListContainerApis()
    this._renderEditDetailsTemplateForIncludedCarsSelected()
  }

  _initHtmlElements () {
    this.conditionsElement = this.element.querySelector(widgetQueries.conditionsElement)
    this.conditionsChoiceList = this.conditionsElement ? this.conditionsElement.querySelector(widgetQueries.conditionsElementChoiceList) : null
  }

  _render (el) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(el)
  }

  async handleValidationClient () {
    let validation = true
    const apiWithError = []
    if (this.choiceListApis) {
      this.choiceListApis.forEach(api => {
        if (api.getProp('method') === 'single' && this._checkIfCarSelectedIsRequired() && !this._checkIfAnyCarIsSelected(api.element)) {
          api.enableErrors()
          validation = (api.validate().isValid && this._checkIfAnyCarIsSelected(api.element)) || false
          if (!validation) { apiWithError.push(api) }
        }
        if (this.creditCard.checkboxRequired) {
          const formItems = api.element.querySelectorAll(widgetQueries.formItem)
          formItems.forEach(formItem => {
            const formValidation = this._validateFormCarSelected(formItem)
            validation = formValidation && formValidation.isValid
          })
        }
      })
    }

    if (!validation) {
      super.openCollapseElement()
      apiWithError.forEach(api => { this._showApiErrors(validation, api) })
    }
    return validation
  }

  async handleConditionsValidation () {
    let validation = true
    let extraInformation = {}
    if (this.conditionsChoiceListApi) {
      const performValidation = this.conditionsChoiceListApi.validate()
      validation = performValidation.isValid
      if (!validation) {
        extraInformation = { serviceName: this.serviceName, fieldType: performValidation.fieldType || '' }
        this.conditionsChoiceListApi.enableErrors()
        this.conditionsChoiceListApi.validate()
      }
    }

    return { isValid: validation, ...extraInformation }
  }

  getServiceName () {
    return this.serviceName || ''
  }

  _checkIfCarSelectedIsRequired () {
    return this.widgetData.isCarSelectedRequired
  }

  _checkIfAnyCarIsSelected (element) {
    const listContainerId = this._getContainerIdFromElement(element, `[${attr.containerListId}]`, attr.containerListId)
    const currentList = this.widgetData.carsLists.find(list => list.carListInformation.listId === listContainerId)

    const selectedCars = [...(currentList?.choiceListInformation?.items || []), ...(currentList?.choiceListSelectedCarInformation?.items || [])].filter(option => option.checked)
    return selectedCars && selectedCars.length > 0
  }

  _showApiErrors (isValid, api) {
    const messages = api.getValidationMessages()
    api.styleValidity(isValid, messages)
  }

  _initApis () {
    const choiceListElements = [...this.element.querySelectorAll(widgetQueries.choiceLists)]
    this.choiceListApis = choiceListElements.map(choiceListElement => choiceListElement[componentsApis.choiceListApi])

    const editCarButtons = [...this.element.querySelectorAll(widgetQueries.editCarButton)]
    this.editCarButtonApis = editCarButtons.map(editCarButton => editCarButton[componentsApis.buttonApi])

    const removeCarButtons = [...this.element.querySelectorAll(widgetQueries.removeCarButton)]
    this.removeCarButtonApis = removeCarButtons.map(removeCarButton => removeCarButton[componentsApis.buttonApi])

    const upgradeCarButtons = [...this.element.querySelectorAll(widgetQueries.upgradeCarButton)]
    this.upgradeCarButtonsApis = upgradeCarButtons.map(upgradeCarButton => upgradeCarButton[componentsApis.buttonApi])
  }

  _removeEvents () {
    if (this.choiceListApis) {
      this.choiceListApis.forEach(choiceListApi => {
        choiceListApi && choiceListApi.events.removeListener('changeOptions')
      })
    }

    this.conditionsChoiceListApi && this.conditionsChoiceListApi.events.removeListener('changeOptions')

    if (this.editCarButtonApis) {
      this.editCarButtonApis.forEach(buttonApi => {
        buttonApi && buttonApi.events.removeListener('clickButton')
      })
    }

    if (this.removeCarButtonApis) {
      this.removeCarButtonApis.forEach(buttonApi => {
        buttonApi && buttonApi.events.removeListener('clickButton')
      })
    }

    if (this.upgradeCarButtonsApis) {
      this.upgradeCarButtonsApis.forEach(buttonApi => {
        buttonApi && buttonApi.events.removeListener('clickButton')
      })
    }
  }

  _attachEvents () {
    this.choiceListApis.forEach(choiceListApi => {
      choiceListApi.events.on('changeOptions', (options) => { this._clickedOption(options, choiceListApi) })
    })

    this.editCarButtonApis.forEach(buttonApi => {
      buttonApi.events.on('clickButton', (element) => {
        this._enableOtherActionButtons(element)
        buttonApi.setProp('disabled', true)
        this._editCarButtonClicked(element)
      })
    })

    this.removeCarButtonApis.forEach(buttonApi => {
      buttonApi.events.on('clickButton', (element) => this._removeCarButtonClicked(element))
    })

    this.upgradeCarButtonsApis.forEach(buttonApi => {
      buttonApi.events.on('clickButton', (element) => {
        this._enableOtherActionButtons(element)
        buttonApi.setProp('disabled', true)
        this._upgradeCarButtonClicked(element)
      })
    })

    this._attachConditionsEvents()
    this._attachAddExtraCarEvents()
    this._attachSliderButtonEvents()
  }

  _enableOtherActionButtons (element) {
    const allButtonApis = [...this.editCarButtonApis, ...this.upgradeCarButtonsApis]
    allButtonApis.forEach(api => {
      if (api.element.getAttribute(attr.carCode) === element.getAttribute(attr.carCode)) {
        api.setProp('disabled', false)
      }
    })
  }

  _attachSliderButtonEvents () {
    if (this.configurations && !this.configurations.showCarsInOneRow) return

    const sliderButtonNavigationElements = [...this.element.querySelectorAll(widgetQueries.sliderButtonsDirection)]
    this.sliderButtonNavigationApis = sliderButtonNavigationElements.map(sliderButtonNavigation => sliderButtonNavigation[componentsApis.buttonApi])

    this.sliderButtonNavigationApis.forEach(buttonApi => {
      buttonApi.events.on('clickButton', (element) => {
        const carListElement = element && element.closest(`[${attr.containerCarListId}]`)
        this.events.emit(bookingCarRentalEvents.SLIDER_BUTTON_CLICKED, {
          buttonDirection: element.getAttribute(attr.sliderButtonDirectionAttr),
          carListId: carListElement && carListElement.getAttribute(attr.containerCarListId)
        })
      })
    })
  }

  _attachConditionsEvents () {
    this.conditionsChoiceListApi = this.conditionsChoiceList ? this.conditionsChoiceList[componentsApis.choiceListApi] : null
    if (this.conditionsChoiceListApi) {
      this.conditionsChoiceListApi.events.on('changeOptions', (eventArgs) => { this._conditionsClicked(eventArgs) })
    }
  }

  _attachAddExtraCarEvents () {
    const addExtraCarButtons = [...this.element.querySelectorAll(widgetQueries.addExtraCarButton)]
    this.addExtraCarButtonApis = addExtraCarButtons.map(addExtraCarButton => addExtraCarButton[componentsApis.buttonApi])

    this.addExtraCarButtonApis.forEach(buttonApi => {
      this.isUpgradingCar = false
      buttonApi.events.on('clickButton', () => {
        buttonApi.setProp('disabled', true)
        this._addExtraCarButtonClicked()
      }
      )
    })
  }

  _addExtraCarButtonClicked () {
    const extraCarListContainer = this.element.querySelector(widgetQueries.extraCarListContainer)
    const dataDefaultListTemplate = this.dataMapper.mapDefaultChoiceListCar(this.apiData, this.configurations, this.options)

    this.widgetData.carsLists = [...this.widgetData.carsLists, { ...dataDefaultListTemplate }]

    const extraCarListTemplate = BookingCarRentalCarsListTemplate(dataDefaultListTemplate, this.widgetData.carsLists.length)
    const newContent = elementFromString(extraCarListTemplate)
    flush(extraCarListContainer)
    extraCarListContainer.appendChild(newContent)
    Component.initDocumentComponentsFromAPI(newContent)

    this._initApis()
    this._removeEvents()
    this._attachEvents()
    this._setCollapseListContainerApis()
  }

  _removeCarButtonClicked (btn) {
    const choiceListParent = btn.closest(widgetQueries.choiceLists)
    const selectedCarItem = choiceListParent.querySelector(widgetQueries.optionItem)

    this._removeCar(selectedCarItem)
  }

  _removeCar (selectedCarItem) {
    const eventArgs = {
      method: this._method,
      url: this._url,
      body: this._mapPatchRemoveCar(selectedCarItem),
      componentId: this.componentId
    }
    this.previousCarSelected = null
    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  _editCarButtonClicked (btn) {
    const carCode = btn.getAttribute(attr.carCode)
    const listCollapseContainerId = this._getContainerIdFromElement(btn, widgetQueries.collapseElement)
    const listContainerId = this._getContainerIdFromElement(btn, `[${attr.containerListId}]`, attr.containerListId)
    const selectedOptions = []
    this.widgetData.carsLists.forEach(list => {
      if (list?.choiceListInformation?.items || list?.choiceListSelectedCarInformation?.items) {
        const optionClicked = [...(list?.choiceListSelectedCarInformation?.items || []), ...(list?.choiceListInformation?.items || [])].find(option => (option.value === carCode) && option.checked)
        if (optionClicked) { selectedOptions.push(optionClicked) }
      }
    })
    const selectedOption = selectedOptions.filter(Boolean)[0]

    // Close container/ hide other options before showing the edit template
    this.collapseListContainerApis[listCollapseContainerId].api.close()

    this._renderEditDetailsTemplate(selectedOption, null, listContainerId, true)
    this._showCollapseEditContainers(true)

    const extraItemsToBeShown = this.configurations.showCarsInOneRow ? 0 : 1
    this._updateCollapseCarItemsVisibility(listCollapseContainerId, listContainerId, extraItemsToBeShown)
  }

  _upgradeCarButtonClicked (btn) {
    const carCode = btn.getAttribute(attr.carCode)
    const carDriver = btn.getAttribute(attr.carDriver)
    const carOption = btn.closest('.w-booking-car-rental__option')
    const collapseFormCarItemId = carOption && carOption.parentElement.querySelector(widgetQueries.editCollapseElement) && carOption.parentElement.querySelector(widgetQueries.editCollapseElement).getAttribute('id')
    const listCollapseContainerId = this._getContainerIdFromElement(btn, widgetQueries.collapseElement)
    const listContainerId = this._getContainerIdFromElement(btn, `[${attr.containerListId}]`, attr.containerListId)

    this.previousCarSelected = { carCode, driverId: carDriver }

    this._hideCollapseEditContainer(collapseFormCarItemId)
    this._updateCollapseCarItemsVisibility(listCollapseContainerId, listContainerId, 1)

    this.addExtraCarButtonApis.forEach(buttonApi => {
      buttonApi.setProp('disabled', true)
    })

    this.isUpgradingCar = true
  }

  _getContainerIdFromElement (el, querySelector, attribute = 'id') {
    if (!el) { return }
    const containerElement = el.closest(querySelector)
    return containerElement && containerElement.getAttribute(attribute)
  }

  _clickedOption (options, api) {
    const currentSelectedOption = options.find(option => option.checked)
    const listCollapseContainerId = this._getContainerIdFromElement(api.element, widgetQueries.collapseElement)
    const listContainerId = this._getContainerIdFromElement(api.element, `[${attr.containerListId}]`, attr.containerListId)

    const currentList = this.widgetData.carsLists.find(list => list.carListInformation.listId === listContainerId)
    if (currentList) {
      const selectedOption = [...(currentList?.choiceListInformation?.items || []), ...(currentList?.choiceListSelectedCarInformation?.items || [])].find(option => option.value === currentSelectedOption.value)
      const oldServiceValue = this._getOldServiceValueFromData([...(currentList?.choiceListInformation?.items || []), ...(currentList?.choiceListSelectedCarInformation?.items || [])]) || null

      this._hideSelectedInfoSectionFromAllOptions(listContainerId)
      if (this.isUpgradingCar || (this.configurations.isCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow && oldServiceValue != null)) {
        const carItemSelectedData = {
          car: selectedOption.car
        }
        const validationResult = this._getValueFromSelectedCar(currentList)

        this._upgradeSelectedCar(validationResult, carItemSelectedData, oldServiceValue, null)
        this.isUpgradingCar = false
        this.previousCarSelected = null
      } else {
        this._renderEditDetailsTemplate(selectedOption, oldServiceValue, listContainerId)
        this._showCollapseEditContainers()
        this._updateCollapseCarItemsVisibility(listCollapseContainerId, listContainerId, 1)
      }
    }
  }

  _getValueFromSelectedCar (currentList) {
    const driverValue = (currentList.choiceListSelectedCarInformation != null && currentList.choiceListSelectedCarInformation?.items) ? currentList.choiceListSelectedCarInformation?.items[0].driver : null
    const equipmentsSelected = (currentList.choiceListSelectedCarInformation != null && currentList.choiceListSelectedCarInformation?.items) ? currentList.choiceListSelectedCarInformation?.items[0].selectedEquipments : null
    const equipments = (equipmentsSelected && equipmentsSelected.map(equipment => equipment.id)) || null

    return {
      isValid: driverValue !== null,
      driver: (driverValue && driverValue.participantId) || null,
      birthdate: (driverValue && driverValue.birthdate) || null,
      equipments
    }
  }

  _conditionsClicked (options) {
    const eventArgs = {
      method: carRentalOperations.patch,
      url: this.conditionsUrl,
      body: {
        conditions: this._mapConditionOptions(options)
      }
    }
    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  _mapConditionOptions (options) {
    const body = options.reduce(
      (obj, option) => Object.assign(obj, { [option.value]: option.checked }), {})
    return body
  }

  _getOldServiceValueFromData (oldOptions) {
    let oldServiceValue = ''
    if (oldOptions) {
      const oldOption = oldOptions.find(opt => opt.checked)
      if (oldOption) {
        oldServiceValue = oldOption.value
      }
    }
    return oldServiceValue
  }

  _renderEditDetailsTemplateForIncludedCarsSelected () {
    const includedCarLists = []
    this.widgetData.carsLists.forEach(list => {
      const carItems = [...(list?.choiceListInformation?.items || []), ...(list?.choiceListSelectedCarInformation?.items || [])]
      const includedCars = carItems && carItems.filter(option => option.isSelected && option.isIncluded && option.isCarEditable)
      if (includedCars && includedCars.length > 0) {
        includedCarLists.push(
          {
            listId: list.carListInformation.listId,
            collapseContainerId: list.collapseListInformation.collapseId,
            includedCars
          }
        )
      }
    })

    if (includedCarLists && includedCarLists.length > 0 && this.options.showEditTemplateForIncludedCars) {
      includedCarLists.forEach(list => {
        list.includedCars.forEach(car => {
          this._renderEditDetailsTemplate(car, null, list.listId, true)
        })
      })

      this._showCollapseEditContainers()

      includedCarLists.forEach(list => {
        const extraItemsToBeShown = this.configurations.showCarsInOneRow ? 0 : includedCarLists.length
        this._updateCollapseCarItemsVisibility(list.collapseContainerId, list.listId, extraItemsToBeShown)
      })
    }
  }

  _hideCancelButton (formElement, car) {
    const editCollapseIdElement = this._getEditCollapseId(car.value)
    const cancelButton = formElement.querySelector(widgetQueries.itemEditCancelButton)

    if (car.isIncluded && this.options.showEditTemplateForIncludedCars && this._collapseEditContainerShouldBeShown(editCollapseIdElement)) {
      cancelButton && cancelButton.classList.add('u-hidden')
    } else {
      cancelButton && cancelButton.classList.remove('u-hidden')
    }
  }

  _renderEditDetailsTemplate (selectedOption, oldServiceValue = null, listContainerId, isCarEntireRow = false) {
    if (!selectedOption) { return }
    const editCollapseIdElement = this._getEditCollapseId(selectedOption.value)
    const dataEditTemplate = {
      ...this.widgetData.editDetailsInformation,
      ...{ collapseId: editCollapseIdElement, optionChecked: selectedOption.checked },
      ...{ location: (selectedOption.dataset && selectedOption.dataset[attr.location]) || selectedOption.attributes['data-w-booking-car-rental__location'] || selectedOption.attributes['data-w-booking-car-rental__location-code'] },
      ...{ conditions: selectedOption.car.conditions || null },
      ...{
        creditCard: {
          checkboxEnabled: (this.configurations.creditCardConfiguration && this.configurations.creditCardConfiguration.creditCardEnabled) || false,
          checkboxTexts: this.creditCard.texts,
          checkboxChecked: selectedOption?.driver?.hasCheckedCreditCard || false
        },
        carRentalTrack: this.carRentalTrack,
        carSelectedCode: (selectedOption && selectedOption.value) || ''
      }
    }

    if (this.configurations && this.configurations.showCarsInOneRow && !isCarEntireRow) {
      const editTemplateContainerElement = this.element.querySelector(`[${attr.containerListId}="${listContainerId}"]`).parentElement.querySelector('[data-w-booking-car-rental--edit-template]')
      flush(editTemplateContainerElement)
      this._renderEditDetailsTemplateContent({ targetElement: editTemplateContainerElement, dataEditTemplate, selectedOption, oldServiceValue, listContainerId, showCarsInOneRow: this.configurations.showCarsInOneRow })
    } else {
      const parentRichOptionElement = selectedOption ? this.element.querySelector(`[${attr.containerListId}="${listContainerId}"] input[value="${selectedOption.value}"]`).closest('.w-booking-car-rental__option') : null
      if (!parentRichOptionElement.nextElementSibling || parentRichOptionElement.nextElementSibling.getAttribute('id') !== editCollapseIdElement) {
        this._renderEditDetailsTemplateContent({ targetElement: parentRichOptionElement, dataEditTemplate, selectedOption, oldServiceValue, listContainerId, editCollapseIdElement })
      }
    }

    this.events.emit(bookingCarRentalEvents.RENDER_EDIT_DETAILS_TEMPLATE, editCollapseIdElement)
  }

  _renderEditDetailsTemplateContent (data) {
    const { targetElement, dataEditTemplate, selectedOption, oldServiceValue, listContainerId, editCollapseIdElement, showCarsInOneRow = false } = data

    const editHtmlTemplate = BookingCarRentalEditDetailsTemplate(dataEditTemplate)
    const newContent = elementFromString(editHtmlTemplate)
    targetElement.insertAdjacentElement(showCarsInOneRow ? 'beforeend' : 'afterend', newContent)
    Component.initDocumentComponentsFromAPI(newContent)

    this._placeArrowEditFormProperly(showCarsInOneRow, selectedOption, listContainerId)

    const collapseCheckoutElement = showCarsInOneRow ? targetElement.children[0] : targetElement.nextElementSibling
    const currentCheckoutCarForm = collapseCheckoutElement && collapseCheckoutElement.querySelector(widgetQueries.formItem)

    const currentList = this.widgetData.carsLists.find(list => list.carListInformation.listId === listContainerId)
    const carItemSelectedData = [...(currentList?.choiceListInformation?.items || []), ...(currentList?.choiceListSelectedCarInformation?.items || [])].find(car => car.id === selectedOption.value)

    if (this.creditCard.checkboxRequired) { this.creditCard.showCreditCardCheckBox(collapseCheckoutElement) }

    this._setCollapseEditContainerApis()
    if (selectedOption.isIncluded && this.options.showEditTemplateForIncludedCars && this._collapseEditContainerShouldBeShown(editCollapseIdElement)) {
      this._hideSelectedInfoSectionFromAllOptions(listContainerId)
    }
    this._hideCancelButton(currentCheckoutCarForm, selectedOption)
    this._attachCarFormEvents(currentCheckoutCarForm, carItemSelectedData, oldServiceValue)
    this._fillCheckoutFormControlsWithData(currentCheckoutCarForm, this.widgetData, carItemSelectedData, listContainerId)
    this._setFormControlsCurrentValues(currentCheckoutCarForm, selectedOption)
  }

  _placeArrowEditFormProperly (showCarsInOneRow, selectedOption, listContainerId) {
    if (!showCarsInOneRow) return
    const optionElementSelected = this.element.querySelector(`[${attr.containerListId}="${listContainerId}"] ${widgetQueries.optionItem}[data-w-booking-car-rental__item-code="${selectedOption.value}"]`).closest('.w-booking-car-rental__option')
    const optionElementSelectedRect = optionElementSelected.getBoundingClientRect()
    const bookingItemRect = this.element.getBoundingClientRect()
    const arrowPosition = (optionElementSelectedRect.left - bookingItemRect.left) + this.options.arrowEditFormLeftPosition

    const arrowItem = this.element.querySelector(widgetQueries.arrowEditFormLeftPosition)
    if (arrowItem) { arrowItem.style.left = `${arrowPosition}px` }
  }

  _getEditCollapseId (selectedOptionValue) {
    return `${this.widgetData.editDetailsInformation.collapseId}-${selectedOptionValue}`
  }

  _collapseEditContainerShouldBeShown (editCollapseIdElement) {
    let shouldBeShown = true
    if (this.collapseEditContainerApis && this.collapseEditContainerApis[editCollapseIdElement]) {
      shouldBeShown = !this.collapseEditContainerApis[editCollapseIdElement].isCollapsed
    }
    return shouldBeShown
  }

  _hideSelectedInfoSectionFromAllOptions (listContainerId = null) {
    this.choiceListApis.forEach(choiceList => {
      let selectedInfoSections = choiceList.element.querySelectorAll(widgetQueries.selectedInfoSection)
      if (listContainerId) {
        selectedInfoSections = choiceList.element.closest(`[${attr.containerListId}="${listContainerId}"] ${widgetQueries.selectedInfoSection}`)
      }

      selectedInfoSections && selectedInfoSections.forEach(selectedInfoSection => {
        selectedInfoSection.classList.add('u-hidden')
      })
    })
  }

  _getDriversIdAlreadySelectedFromExistingLists (widgetData) {
    return widgetData.carsLists.reduce((acc, list) => {
      const carsSelected = [...(list?.choiceListInformation?.items || []), ...(list?.choiceListSelectedCarInformation?.items || [])].filter(item => item.isSelected && item.driver.participantId).flat()
      return [...acc, { listId: list.carListInformation.listId, driverIds: carsSelected.map(item => item.driver.participantId) }]
    }, [])
  }

  _fillCheckoutFormControlsWithData (currentCheckoutCarForm, widgetData, carItemSelectedData, listContainerId) {
    const { apiDriver, apiEquipments } = this._getFormControls(currentCheckoutCarForm)

    const driverIdsAlreadySelected = this._getDriversIdAlreadySelectedFromExistingLists(widgetData)
    const disabledDriversForCurrentList = driverIdsAlreadySelected.filter(list => list.listId !== listContainerId).map(list => list.driverIds).flat()

    const driverItems = widgetData.participants
      ? widgetData.participants.filter(participant => participant.ageCategory === 'Adult').map(
        (participant) => {
          const isDisabled = disabledDriversForCurrentList.includes(Number(participant.id))
          return {
            value: participant.id,
            text: isDisabled ? this._mapDriverDropdownText(participant.name) : participant.name,
            birthdate: participant.birthdate,
            disabled: isDisabled
          }
        })
      : null

    if (driverItems) {
      apiDriver.setProp('options', driverItems, { silent: true })
    }

    if (carItemSelectedData && carItemSelectedData.car && carItemSelectedData.car.hasEquipments) {
      const selectedEquipmentsLength = carItemSelectedData.car.equipments ? carItemSelectedData.car.equipments.filter(option => option.selected).length : null
      const equipments = carItemSelectedData.car.equipments.map(
        (equipment) => ({
          value: equipment.id,
          name: equipment.id,
          text: equipment.text,
          checked: equipment.selected,
          disabled: this._mapDisabledEquipmentState(equipment.selected, selectedEquipmentsLength, this.options.maxNumberOfEquipmentsToBeSelected)
        }))
      apiEquipments.setProp('options', equipments, { silent: true })
    } else {
      const formEquipmentsContainerElement = currentCheckoutCarForm.querySelector(widgetQueries.formEquipmentsContainer)
      formEquipmentsContainerElement && formEquipmentsContainerElement.classList.add('u-hidden')
    }
  }

  _mapDriverDropdownText (participantName) {
    let textReplaced = participantName
    if (this.configurations.addExtraCarsConfiguration && this.configurations.addExtraCarsConfiguration.driverAlreadyAssignedToCarText) {
      textReplaced = this.configurations.addExtraCarsConfiguration.driverAlreadyAssignedToCarText.replace('{driver}', participantName)
    }
    return textReplaced
  }

  _setFormControlsCurrentValues (form, carItem) {
    const { apiDriver, apiBirthdate } = this._getFormControls(form)
    if ((!carItem.driver && !this.previousCarSelected)) { return }
    const driverId = (carItem.driver && carItem.driver.participantId) || (this.previousCarSelected && this.previousCarSelected.driverId) || ''

    let driverBirthdate = carItem.driver && carItem.driver.birthdate
    if (!driverBirthdate) {
      const proposedDriverInfo = this.widgetData.participants.find(participant => participant.id === (this.previousCarSelected && this.previousCarSelected.driverId))
      driverBirthdate = (proposedDriverInfo && proposedDriverInfo.birthdate) || ''
    }

    apiDriver.setProp('value', String(driverId))
    apiBirthdate.setProp('date', driverBirthdate)
  }

  _attachCarFormEvents (currentCheckoutCarForm, carItemSelectedData, oldServiceValue = null) {
    const { apiDriver, apiBirthdate, apiEquipments } = this._getFormControls(currentCheckoutCarForm)
    const { apiCreditCard } = this.creditCard.getFormControls(currentCheckoutCarForm)

    const elWarning = currentCheckoutCarForm && currentCheckoutCarForm.querySelector(widgetQueries.ageWarningSelector)

    const btnConfirmSelection = currentCheckoutCarForm && currentCheckoutCarForm.querySelector(widgetQueries.itemEditConfirmButton)
    if (btnConfirmSelection) { btnConfirmSelection[componentsApis.buttonApi].events.on('clickButton', (ev) => this._onConfirmCarSelectionForm(ev, carItemSelectedData, oldServiceValue)) }

    const btnCancelSelection = currentCheckoutCarForm && currentCheckoutCarForm.querySelector(widgetQueries.itemEditCancelButton)
    if (btnCancelSelection) { btnCancelSelection[componentsApis.buttonApi].events.on('clickButton', (ev) => this._onCancelCarSelectionForm(ev)) }

    apiBirthdate.events.on('propChanged', (ev) => {
      if (ev.name === 'date') {
        const departureDate = apiBirthdate.element.dataset[attr.departureDate]
        this._onBirthdateChanged(ev.value, elWarning, departureDate)
        if (btnConfirmSelection) { btnConfirmSelection[componentsApis.buttonApi].setProp('disabled', false) }
      }
    })

    apiDriver.events.on('change', (value) => {
      this._onDriverChanged(value, apiDriver, apiBirthdate, apiCreditCard)
      if (btnConfirmSelection) { btnConfirmSelection[componentsApis.buttonApi].setProp('disabled', !value) }
    })

    if (apiEquipments) {
      this.previousSelectedEquipmentIds = []
      apiEquipments.events.on('changeOptions', (options) => {
        this._onEquipmentsChanged(options, this.previousSelectedEquipmentIds, carItemSelectedData, apiEquipments, this.options.maxNumberOfEquipmentsToBeSelected)
      })
    }

    if (apiCreditCard) {
      if (carItemSelectedData?.driver?.hasCheckedCreditCard) {
        const creditCardSelectedValues = apiCreditCard.getSelectedValues()
        this.creditCard.setCreditCardSessionStorage(creditCardSelectedValues, carItemSelectedData?.driver?.participantId)
      }

      apiCreditCard.events.on('changeOptions', () => {
        const driver = apiDriver.getProp('value')
        const creditCardSelectedValues = apiCreditCard.getSelectedValues()
        const eventArgs = { creditCardSelectedValues, driver, carItemSelectedData }

        this.creditCard.setCreditCardSessionStorage(creditCardSelectedValues, driver)
        this.events.emit(bookingCarRentalEvents.CREDIT_CARD_CHANGED, eventArgs)
      })
      this.creditCard.attachCreditCardEvents(apiCreditCard)
    }
  }

  _getFormControls (elForm) {
    const elements = {
      driver: elForm.querySelector(widgetQueries.formDriverDropdown),
      birthdate: elForm.querySelector(widgetQueries.formBirthdateDateSelector),
      equipments: elForm.querySelector(widgetQueries.formEquipmentsSelector)
    }

    const apis = {
      driver: elements.driver[componentsApis.dropdownApi],
      birthdate: elements.birthdate[componentsApis.dateSelectorApi],
      equipments: elements.equipments ? elements.equipments[componentsApis.choiceListApi] : undefined
    }

    return {
      apiDriver: apis.driver,
      apiBirthdate: apis.birthdate,
      apiEquipments: apis.equipments
    }
  }

  _onBirthdateChanged (birthdate, elWarning, departureDate) {
    let mustShow = false

    if (this.widgetData.editDetailsInformation.ageWarning.enabled && isValidDateString(birthdate) && isValidDateString(departureDate)) {
      const age = getAge(birthdate, departureDate)
      if (age < this.widgetData.editDetailsInformation.ageWarning.age) {
        mustShow = true
      }
    }

    if (elWarning) {
      elWarning.innerText = mustShow ? this.widgetData.editDetailsInformation.ageWarning.text : ''
      elWarning.hidden = !mustShow
    }
  }

  _onDriverChanged (value, apiDriver, apiBirthdate, apiCreditCard) {
    const options = apiDriver.getProp('options')
    if (options) {
      const selectedOption = options.find(option => option.value === value)
      if (selectedOption) {
        const birthdate = selectedOption.birthdate
        apiBirthdate.setProp('date', birthdate)
        apiBirthdate.setProp('disabled', (!this.configurations.editDriverBirthdateEditable && !!birthdate))
        if (apiCreditCard) {
          this.creditCard.toggleCreditCardCheckbox(apiCreditCard, false, value)
        }
      }
    }
  }

  _onEquipmentsChanged (options, previousSelectedEquipmentIds, carItemSelectedData, apiEquipments, maxNumberOfEquipmentsToBeSelected) {
    const selectedOptionsLength = options ? options.filter(option => option.checked).length : null
    let eventArgs = {}

    if (options) {
      this.previousSelectedEquipmentIds = options.filter(option => option.checked).map(option => option.value)

      apiEquipments.setProp('options',
        options.map((option) => (
          {
            ...option,
            disabled: this._mapDisabledEquipmentState(option.checked, selectedOptionsLength, maxNumberOfEquipmentsToBeSelected)
          }
        ))
        , { silent: true }
      )
      eventArgs = {
        options,
        previousSelectedEquipmentIds,
        carItemSelectedData
      }
    }
    this.events.emit(bookingCarRentalEvents.EQUIPMENTS_CHANGED, eventArgs)
  }

  _mapDisabledEquipmentState (equipmentIsChecked, selectedOptionsLength, maxNumberOfEquipmentsToBeSelected) {
    let isDisabled = false
    if (equipmentIsChecked || maxNumberOfEquipmentsToBeSelected === undefined) { return isDisabled }
    if (selectedOptionsLength >= maxNumberOfEquipmentsToBeSelected) {
      isDisabled = true
    }
    return isDisabled
  }

  _onConfirmCarSelectionForm (btn, carItemSelectedData, oldServiceValue) {
    const carItem = btn.closest(widgetQueries.formItem)
    const collapseFormCarItemId = btn.closest(widgetQueries.editCollapseElement) && btn.closest(widgetQueries.editCollapseElement).getAttribute('id')
    const validationResult = this._validateFormCarSelected(carItem)

    if (validationResult.isValid) {
      this.previousCarSelected = null

      this._setIsCollapsedFromCollapseEditContainerApis()
      if (oldServiceValue) {
        this._upgradeSelectedCar(validationResult, carItemSelectedData, oldServiceValue, collapseFormCarItemId)
      } else {
        if (carItemSelectedData.checked) {
          this._saveEditSelectedCar(validationResult, carItemSelectedData, collapseFormCarItemId)
        } else {
          this._addCar(validationResult, carItemSelectedData, collapseFormCarItemId)
        }
      }
      this.options.showEditTemplateForIncludedCars = false
    } else {
      const firstFormItemWithError = carItem.querySelector(widgetQueries.formItemErrorSelector)
      if (firstFormItemWithError != null) {
        firstFormItemWithError.scrollIntoView(config.scroll)
      }
    }
  }

  _onCancelCarSelectionForm (ev) {
    const carsLists = (this.widgetData && this.widgetData.carsLists.filter(list => (list.choiceListSelectedCarInformation && list.choiceListSelectedCarInformation.items && list.choiceListSelectedCarInformation.items.length))) || null
    if (carsLists && carsLists.length > 0) {
      this.widgetData = {
        ...this.widgetData,
        carsLists
      }
    }
    this._init(this.widgetData)
  }

  _validateFormCarSelected (elForm) {
    let isValidForm = true
    const { apiDriver, apiBirthdate, apiEquipments } = this._getFormControls(elForm)
    const { apiCreditCard } = this.creditCard.getFormControls(elForm)

    apiDriver.enableErrors()
    apiBirthdate.enableErrors()

    this.creditCard.checkboxRequired && apiCreditCard && apiCreditCard.enableErrors()
    const isCreditCardRequiredValid = this.creditCard.checkboxRequired ? this.creditCard.creditCardCheckboxValidation(apiCreditCard) : true

    isValidForm = apiDriver.validate().isValid && apiBirthdate.validate().isValid && isCreditCardRequiredValid
    const driverValue = apiDriver.validate().isValid ? apiDriver.getProp('value') : null
    const birthdateValue = apiBirthdate.validate().isValid ? apiBirthdate.getProp('date') : null

    const equipments = apiEquipments.getSelectedValues()

    const result = {
      isValid: isValidForm,
      driver: driverValue,
      birthdate: birthdateValue,
      equipments
    }

    return result
  }

  _addCar (validationResult, carItemSelectedData, collapseFormCarItemId) {
    const eventArgs = {
      method: this._method,
      url: this._url,
      body: this._mapPatchAddCar(validationResult, carItemSelectedData),
      componentId: this.componentId
    }
    this._hideCollapseEditContainer(collapseFormCarItemId, false)
    this._scrollComponentToView()
    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  _saveEditSelectedCar (validationResult, carItemSelectedData, collapseFormCarItemId) {
    const eventArgs = {
      method: this._method,
      url: this._url,
      body: this._mapPatchEditCar(validationResult, carItemSelectedData),
      componentId: this.componentId
    }
    this._hideCollapseEditContainer(collapseFormCarItemId)
    this._scrollComponentToView()
    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  _upgradeSelectedCar (validationResult, carItemSelectedData, oldServiceValue, collapseFormCarItemId) {
    const eventArgs = {
      method: this._method,
      url: this._url,
      body: this._mapPatchUpgradeCar(carItemSelectedData, oldServiceValue, validationResult),
      componentId: this.componentId
    }
    if (collapseFormCarItemId != null) {
      this._hideCollapseEditContainer(collapseFormCarItemId)
    }
    this._scrollComponentToView()
    this.events.emit(bookingItemEvents.BOOKING_ITEM_DATA_CHANGED, eventArgs)
  }

  _mapPatchAddCar (validationResult, carItemSelectedData) {
    return this._mapCarForPatch(carRentalOperations.add, carItemSelectedData, null, validationResult)
  }

  _mapPatchEditCar (validationResult, carItemSelectedData) {
    return this._mapCarForPatch(carRentalOperations.patch, carItemSelectedData, carItemSelectedData, validationResult)
  }

  _mapPatchUpgradeCar (carItemSelectedData, oldCarItemSelectedValue, validationResult) {
    return this._mapCarForPatch(carRentalOperations.swap, carItemSelectedData, oldCarItemSelectedValue, validationResult)
  }

  _mapPatchRemoveCar (selectedCarItem) {
    const selectedCarItemValue = selectedCarItem.getAttribute(attr.itemCode)
    const selectedCarItemParticipants = selectedCarItem.getAttribute(attr.itemParticipants) ? selectedCarItem.getAttribute(attr.itemParticipants).split(',') : []

    const selectedCarItemInfo = { car: { code: selectedCarItemValue, participants: selectedCarItemParticipants } }

    return this._mapCarForPatch(carRentalOperations.remove, selectedCarItemInfo)
  }

  _mapCarForPatch (operationType, carItemSelectedData, oldCarItemSelectedValue = null, validationResult = null) {
    if (this._method === 'GET') {
      return undefined
    }

    const data = validationResult
      ? {
          pickUpType: carItemSelectedData.car.locationCode,
          driverParticipantId: validationResult.driver,
          driverBirthdate: validationResult.birthdate,
          equipments: validationResult.equipments
        }
      : {}

    // Map services
    const newService = {
      code: carItemSelectedData.car.code,
      participants: carItemSelectedData.car.participants
    }

    const oldService = oldCarItemSelectedValue
      ? {
          code: oldCarItemSelectedValue,
          participants: carItemSelectedData.car.participants
        }
      : null

    const body = {
      operationType,
      newService,
      oldService,
      datatype: 'carrental',
      data: JSON.stringify(data)
    }

    return body
  }

  _setIsCollapsedFromCollapseEditContainerApis (collapse = false) {
    if (this.collapseEditContainerApis) {
      Object.values(this.collapseEditContainerApis).forEach(item => { item.isCollapsed = collapse })
    }
  }

  _setCollapseEditContainerApis () {
    const editCollapseContainerElements = [...this.element.querySelectorAll(widgetQueries.editCollapseElement)]
    editCollapseContainerElements.forEach(collapseElement => {
      const editCollapseId = collapseElement.getAttribute('id')
      this.collapseEditContainerApis[editCollapseId] = { ...this.collapseEditContainerApis[editCollapseId], ...{ api: collapseElement[componentsApis.collapseApi] } }
    }, this)
  }

  _showCollapseEditContainers (editButtonClicked = false) {
    if (this.collapseEditContainerApis) {
      Object.values(this.collapseEditContainerApis).forEach(item => {
        if (item.isCollapsed && editButtonClicked) { item.api.open() }
        if (!item.isCollapsed) { item.api.open() }
      })
    }
  }

  _hideCollapseEditContainer (editCollapseId, isCollapsed = true) {
    if (this.collapseEditContainerApis && this.collapseEditContainerApis[editCollapseId]) {
      this.collapseEditContainerApis[editCollapseId].isCollapsed = isCollapsed
      this.collapseEditContainerApis[editCollapseId].api.close()
    }
  }

  _scrollComponentToView () {
    this.element && this.element.scrollIntoView(config.scroll)
  }

  _setCollapseListContainerApis () {
    const collapseElements = [...this.element.querySelectorAll(widgetQueries.collapseElement)]
    collapseElements.forEach(collapseElement => {
      const collapseListId = collapseElement.getAttribute('id')
      this.collapseListContainerApis[collapseListId] = { ...this.collapseListContainerApis[collapseListId], ...{ api: collapseElement[componentsApis.collapseApi] } }
    }, this)
  }

  _updateCollapseCarItemsVisibility (listCollapseContainerId, listContainerId, extraItemsToBeShown = 0) {
    extraItemsToBeShown = this.configurations.isCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow ? 1 : extraItemsToBeShown
    if (this.collapseListContainerApis && this.collapseListContainerApis[listCollapseContainerId]) {
      this.collapseListContainerApis[listCollapseContainerId].api.setProp('maxItems', this._collapseMaxItemsToBeShown(listContainerId, extraItemsToBeShown))
      this.collapseListContainerApis[listCollapseContainerId].api.update()
    }
  }

  _collapseMaxItemsToBeShown (listContainerId, extraItemsToBeShown = 0) {
    let collapseItemsToBeShown = this.options.maxNumberOfCarsToBeShown
    const currentList = this.widgetData.carsLists.find(list => list.carListInformation.listId === listContainerId)

    const checkedCarItems = [...(currentList?.choiceListSelectedCarInformation?.items || [])].filter(option => option.checked)

    if (checkedCarItems.length > 0) {
      collapseItemsToBeShown = checkedCarItems.length
    }
    return Number(collapseItemsToBeShown + extraItemsToBeShown)
  }

  _mapNumberOfOptionsToBeShown (serviceOptions, maxNumberOfCarsToBeShown) {
    const serviceOptionsSelectedLength = serviceOptions ? serviceOptions.filter(option => option.isSelected).length : 0
    return serviceOptionsSelectedLength > 0 ? serviceOptionsSelectedLength : maxNumberOfCarsToBeShown
  }

  _readOptionsFromConfigurations (el) {
    if (el) {
      const innerHtml = el.innerHTML
      const configuration = JSON.parse(innerHtml)
      const priceConfigurations = super.readPriceConfigFromConfigurations(configuration)

      return {
        ...priceConfigurations,
        buttonTexts: configuration[configOptions.buttonTexts],
        carTexts: configuration[configOptions.carTexts],
        checkoutTexts: configuration[configOptions.checkoutTexts],
        ageWarningConfiguration: configuration[configOptions.ageWarningConfiguration],
        conditionGroup: configuration[configOptions.conditionGroup],
        editDriverBirthdateEditable: configuration[configOptions.editDriverBirthdateEditable],
        addExtraCarsConfiguration: configuration[configOptions.addExtraCarsConfiguration],
        creditCardConfiguration: configuration[configOptions.creditCardConfiguration],
        upgradeCarFeatureEnabled: configuration[configOptions.upgradeCarFeatureEnabled],
        showCarsInOneRow: configuration[configOptions.showCarsInOneRow],
        isCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow: configuration[configOptions.isCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow]
      }
    }
  }

  setShowCarsInOneRow (showCarsInOneRow = false) {
    if (this.configurations) {
      this.configurations = { ...this.configurations, showCarsInOneRow }
    }
  }

  setisCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow (isCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow = false) {
    if (this.configurations) {
      this.configurations = { ...this.configurations, isCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow }
    }
  }
}

registerWidget(BookingCarRental, widgetApi)

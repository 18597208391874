import { arrayifyObject } from '../../../js/helpers/arrayify-object'
import { getUrlFromString } from '../../../js/document/url'
import { fetchJsonDataAndStatusInfo } from '../../../js/helpers/json-fetch'

/**
 * ---------------
 * ApiPriceTableGroupedByDuration
 * ---------------
 *
 * It's main purpose is to make the request to the API and transform data if needed.
 */
export default class ApiPriceTableGroupedByDuration {
  /**
   * Creates a new ApiPriceTable.
   *
   * @constructor
   *
   * @param {Object} options     - Price table API options container.
   * @param {String} options.url - Price table API base URL.
   */
  constructor (options = {}) {
    this.options = options
  }

  /**
   * Fetches data from API.
   *
   * @param {Object} params - Price table API parameters.
   *
   * @returns {Promise}
   */
  async fetch (params) {
    try {
      this.data = {}

      const queryParams = this._getQueryStringParamsArray(params)
      const requestUrl = getUrlFromString(this.options.url, queryParams)
      const response = await fetchJsonDataAndStatusInfo(requestUrl, { fullReferrerOnCrossOrigin: true })
      if (response.statusCode === 200) {
        if (response.jsonData) {
          this.data = response.jsonData
        }
        return this.data
      } else {
        return {
          errors: [{
            code: response.statusCode,
            message: response.statusText
          }]
        }
      }
    } catch (ex) {
      this.data = {}

      return {
        errors: [{
          code: '404',
          message: 'Could not retrieve data => ' + (ex.message || ex)
        }]
      }
    }
  }

  /**
   * Composes the query string parameters array for making the HTTP request.
   *
   * @param {Object} params - Object to be processed to adapt the parameters for the price table grouped by duration.
   */
  _getQueryStringParamsArray (params) {
    const queryParams = {}

    for (let [key, value] of Object.entries(params)) {
      key = key.toLowerCase()

      switch (key) {
        case 'ids': // Ignore, it's not used for price tale grouped by duration.
          value = undefined
          break

        case 'id': // This parameter has been renamed to be more clear about the content.
          key = 'pricetableid'
          break

        case 'month': // Ensure the format is correct (just a month and not a specific date).
          if (value && value.length > 'yyyy-mm'.length) {
            value = value.substr(0, 'yyyy-mm'.length)
          }
          break
      }

      if (value) {
        queryParams[key] = value
      }
    }

    return arrayifyObject(queryParams)
  }
}

export default class BookingTermsAndConditionsDataMapper {
  mapWidgetData (apiData, configurations) {
    const widgetTermsAndConditions =
      apiData.serviceGroupsModified && apiData.serviceGroupsModified.length >= 0
        ? {
            termsandconditions: this._filterAndMapTermsAndConditions(
              apiData,
              configurations.conditions || null
            )
          }
        : null
    return widgetTermsAndConditions
  }

  _filterAndMapTermsAndConditions (apiData, candidatesConditions) {
    const conditionsFiltered = []
    const changedServices = apiData.serviceGroupsModified

    if (candidatesConditions && candidatesConditions.length > 0) {
      candidatesConditions.map(candidate =>
        this._mapMatchedConditions(candidate, changedServices, conditionsFiltered)
      )
    }

    const widgetPreference =
      this._extractWidgetPreferences(conditionsFiltered)
    return widgetPreference
  }

  _mapMatchedConditions (candidate, changedServices, conditionsFiltered) {
    if (candidate.serviceType === null || candidate.serviceType === undefined ||
      changedServices.findIndex(changedService => changedService.startsWith(candidate.serviceType)) > -1) {
      candidate.conditionItems.map(condition =>
        conditionsFiltered.push({
          text: condition,
          errorMessage: candidate.errorMessage
        })
      )
    }
  }

  _extractWidgetPreferences (conditionsFiltered) {
    return conditionsFiltered && conditionsFiltered.length > 0
      ? {
          choiceListInfo: {
            method: 'multiple',
            id: 'w-booking-terms-conditions',
            name: 'w-booking-terms-conditions',
            attributes: {
              'data-w-booking-terms-conditions__choice-list': '',
              'data-message-required': conditionsFiltered[0].errorMessage
            },
            extraClasses: 'w-booking-terms-conditions__conditions-choice-list',
            required: true,
            items: conditionsFiltered
              ? conditionsFiltered.map(conditionFiltered => this._mapTermAndCondition(conditionFiltered)
              )
              : null
          }
        }
      : null
  }

  _mapTermAndCondition (apiTermAndCondition) {
    const widgetItem = {
      name: apiTermAndCondition.text,
      value: apiTermAndCondition.text,
      text: apiTermAndCondition.text,
      checked: false,
      required: true
    }
    return widgetItem
  }
}

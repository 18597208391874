import AgeProfiles from './age-profiles'
import Occupancies from './occupancies'
import Packages from './packages'
import Rooms from './rooms'
import PriceFormatter from '../../../js/helpers/price-formatter'
import SortRoomsBy from './sort-rooms-by'

/**
 *
 * @global
 * @typedef {Object}             RoomSelectorData
 *
 * @property {PackageData[]}     packages                     - Available packages
 * @property {RoomData[]}        rooms                        - Available rooms
 * @property {OccupancyData[]}   occupancies                  - Eligible occupancies
 * @property {AgeProfileData[]}   ageProfiles                   - Age profiles
 * @property {Object}            participantsProfiles          - Participant age profiles by BirthDate
 *
 *
 * @global
 * @typedef {Object}             RoomSelectorServiceOptions
 *
 * @property {Boolean}           flexibleAllocation            - Available packages
 *
 */

export default class RoomSelectorService {
  /**
   * Creates a new model
   *
   * @constructor
   * @param {RoomSelectorData}              data
   * @param {RoomSelectorServiceOptions}    options
   */
  constructor (data, options = {}) {
    this.options = {
      flexibleAllocation: true,
      ...options
    }

    const { packages, rooms, occupancies, ageProfiles, participantsProfiles } = data
    this.packages = new Packages(packages)
    this.rooms = new Rooms(rooms)
    this.occupancies = new Occupancies(occupancies)
    this.ageProfiles = new AgeProfiles(ageProfiles)
    this.participantsProfiles = participantsProfiles
    this.currencySettings = data.currencySettings
  }

  /**
   * Returns an occupation base object, based on current options and age profiles
   *
   * @global
   * @typedef {Object}                  Occupation
   * @property {Number}                 total                   - Total
   * @property {Number}                 [adults]                - or any other age profile id from data
   * @property {Number}                 [children]              - ...
   * @property {Number}                 [babies]                - ...
   *
   * @returns {Occupation}
   */
  getOccupationBaseObject () {
    return this.ageProfiles.getIds()
      .reduce((baseOcc, ageProfile) => {
        baseOcc[ageProfile] = 0
        return baseOcc
      }, { total: 0 })
  }

  /**
   * Get an Occupation object from given participant birth dates
   *
   * ⚠️ Only previously known birth dates can be handled by this method,
   * those should be returned as keys into participantsProfiles property
   *
   * 🤷 Unknown birth dates will be considered as zero occupation, so
   * consumer should handle those cases
   *
   * @param {DateString[]}              birthDates
   *
   * @returns {Occupation}
   */
  getOccupationFromBirthDates (birthDates) {
    const participantsProfiles = this.participantsProfiles

    return birthDates.flat().reduce((occupation, birthDate) => {
      if (!Object.keys(participantsProfiles).includes(birthDate)) {
        return occupation
      }
      const requestedAgeProfile = participantsProfiles[birthDate]

      if (this.ageProfiles.includesId(requestedAgeProfile)) {
        occupation[requestedAgeProfile]++
      }
      if (this.ageProfiles.countsAsTotal(requestedAgeProfile)) {
        occupation.total++
      }

      return occupation
    }, this.getOccupationBaseObject())
  }

  /**
   * Returns the different between 2 occupations
   *
   * @typedef {Object}                          getOccupationDifference
   * @param {Occupation}                        occupationA
   * @param {Occupation}                        occupationB
   *
   * @returns {getOccupationDifference}
   */
  getOccupationDifference (occupationA, occupationB) {
    return Object.entries(this.getOccupationBaseObject()).reduce((accOccupation, [profileId, amount]) => {
      const amountToAdd = occupationA[profileId] || 0
      const amountToSubstract = occupationB[profileId] || 0

      accOccupation[profileId] += amountToAdd - amountToSubstract

      return accOccupation
    }, this.getOccupationBaseObject())
  }

  /**
   * 💫 Returns the best occupancy for a given occupation
   * A magic method trying to suggest what probably fits better on most users, based on:
   * - Try first to allocate everybody
   * - If it's not possible, explode all possible occupation combinations, and choose one by:
   *   - Considering the maximum people allocated, counting or not for totals
   *   - Considering the minimum absolute differences between age profiles
   *
   * @typedef {Object}                    getBestOccupancyForOccupationResponse
   * @property {OccupancyData|undefined}   occupancy             - Suitable occupancy, undefined if none
   * @property {Occupation}               occupation            - Allocated occupation
   *
   * @param {OccupancyData[]}             occupancies           - Occupancies to evaluate with
   * @param {Occupation}                  occupation            - Occupation to allocate
   *
   * @returns {getBestOccupancyForOccupationResponse}
   */
  getBestOccupancyForOccupation (occupancies = [], occupation = {}) {
    const debug = false
    debug && console.log('🧠 New case for getBestOccupancyForOccupation', occupation)

    // Try to resolve with everyBody allocated
    const easyOccupancy = occupancies
      .find(occupancy => Occupancies.occupancyMatchesOccupation(occupancy, occupation))
    if (easyOccupancy) {
      debug && console.log(`-> ✅ -> ${easyOccupancy.id} -> `, occupation)
      return {
        occupancy: easyOccupancy,
        occupation
      }
    }

    // Explode all possible occupation combinations, and try to find the best
    function * cartesian (head, ...tail) {
      const remainder = tail.length ? cartesian(...tail) : [[]]
      for (const r of remainder) for (const h of head) yield [h, ...r]
    }

    const { total: totalOccupation, ...profilesOccupation } = occupation
    const peopleCombinations = cartesian(...Object.values(profilesOccupation).map(people => (
      [...Array(people + 1).keys()]
    )))

    // Generates one occupation object per combination, and sorts them all by total
    const explodedOccupations = [...peopleCombinations]
      .map(peopleCombination => {
        const combinationOccupation = Object.keys(profilesOccupation)
          .reduce((combinationOccupation, profile, i) => ({
            ...combinationOccupation,
            [profile]: peopleCombination[i]
          }), {})
        return {
          ...combinationOccupation,
          total: Object.entries(combinationOccupation)
            .reduce((total, [profile, amount]) => (
              this.ageProfiles.countsAsTotal(profile)
                ? total + amount
                : total
            ), 0)
        }
      })
      .sort((a, b) => (b.total - a.total))

    // Find all the matching options with the maximum people allocated
    debug && console.log(`-> 💫 -> Going to try with ${explodedOccupations.length} exploded occupations`)
    const bestOptions = explodedOccupations.reduce((result, testOccupation) => {
      if (result.length && result[0].occupation.total > testOccupation.total) return result
      const matchingOccupancy = occupancies
        .find(occupancy => Occupancies.occupancyMatchesOccupation(occupancy, testOccupation))
      if (matchingOccupancy) {
        result.push({
          occupancy: matchingOccupancy,
          occupation: { ...testOccupation }
        })
      }
      debug && console.log(`---> ${matchingOccupancy ? `✅ ${matchingOccupancy.id}` : '❌'}`, testOccupation)
      return result
    }, [])

    // Break if there's no match at all
    if (!bestOptions.length) {
      debug && console.log('-> ❓ -> Cannot find any suitable occupancy combination')
      return {
        occupancy: undefined,
        occupation: this.getOccupationBaseObject()
      }
    }

    // Choose and return the best option from matching ones, by:
    // - Considering the total people allocated, counting or not for totals
    // - Considering the minimum absolute differences on age profiles
    const getOccupationScore = ({ total: totalOccupation, ...profilesOccupation }) => {
      const reference = Object.values(profilesOccupation)[0]
      const accumulatedDifference = Object.values(profilesOccupation)
        .reduce((accumulatedDifference, amount) => (
          accumulatedDifference + Math.abs(reference - amount)
        ), 0)
      const totalPeople = Object.values(profilesOccupation)
        .reduce((totalPeople, amount) => (
          totalPeople + amount
        ), 0)
      return (totalPeople * 2) - accumulatedDifference
    }
    const bestOption = bestOptions.sort((a, b) => (
      getOccupationScore(b.occupation) - getOccupationScore(a.occupation)
    ))[0]
    debug && console.log(`-> 💫 -> Found the best option as ${bestOption.occupancy.id}`, bestOption.occupation)

    return bestOption
  }

  /**
   * Return the occupancy given a room id
   *
   * @typedef {Object}       PackageData
   *
   * @param {String}     [roomId]
   *
   * @returns {PackageData}
   */
  getOccupanciesByRoomId (roomId) {
    return this._getPackageOccupancies(this.packages.getPackagesByRoomId(roomId))
  }

  /**
   * Return the occupancy given a room id and a room occupancy id
   *
   * @typedef {Object}       PackageData
   *
   * @param {String}     [roomId]
   * @param {String}     [roomOccupancyId]
   *
   * @returns {PackageData}
   */
  getOccupanciesByRoomIdAndRoomOccupancyId (roomId, roomOccupancyId) {
    return this._getPackageOccupancies(this.packages.getPackagesByRoomIdAndRoomOccupancyId(roomId, roomOccupancyId))
  }

  /**
   * Get all rooms with all the compatible packages from each one, matching an optional given occupation
   * Rooms without packages or with no compatible packages will be omitted
   *
   * @typedef {Object}       getRoomsWithCompatiblePackagesResponse
   * @property {RoomData}    room                               - Room data object
   * @property {PackageData} pkg                                - Package data object
   *
   * @param {Occupation}     [occupation]                       - Optional occupation to filter by
   * @param {Boolean}        hasFlexibleOccupation
   *
   * @returns {getRoomsWithCompatiblePackagesResponse[]}
   */
  getRoomsWithCompatiblePackages (occupation, hasFlexibleOccupation, roomNumber) {
    const compatibleOccupancies = occupation
      ? this.occupancies.getCompatibleOccupancies(occupation, hasFlexibleOccupation).map(occupancy => occupancy.id)
      : []
    return this.rooms.data
      .map(room => {
        let compatiblePackages = occupation
          ? this.packages
            .getPackagesByRoomId(room.id)
            .filter(pkg => compatibleOccupancies.includes(pkg.occupancyId))
          : this.packages.getPackagesByRoomId(room.id)
        compatiblePackages = compatiblePackages
          .map(compatiblePkg => ({
            isCheapest: false,
            ...compatiblePkg
          }))
          .filter(pkg => pkg.roomNumber === roomNumber)

        const cheapestPackage = Packages.getCheapestPackage(compatiblePackages)
        if (cheapestPackage) {
          cheapestPackage.isCheapest = true
        }

        return cheapestPackage
          ? { room, compatiblePackages }
          : undefined
      })
      .filter(Boolean)
  }

  /**
   * For the occupation return the cheapest package of a room
   * Rooms without packages or with no compatible packages will be omitted
   *
   * @typedef {Object}       getCheapestPackageOfRoom
   *
   * @param {String}     [roomId]                       - Optional occupation to filter by
   * @param {Occupation} [occupation]                   - Optional occupation to filter by
   *
   * @returns {getCheapestPackageOfRoom}
   */
  getCheapestPackageOfRoom (roomId, roomOccupation) {
    const compatibleOccupancies = this.occupancies.getOccupanciesMatchingOccupation(roomOccupation).map(occupancy => occupancy.id)

    const compatiblePackages = compatibleOccupancies
      ? this.packages
        .getPackagesByRoomId(roomId)
        .filter(pkg => compatibleOccupancies.includes(pkg.occupancyId))
      : this.packages.getPackagesByRoomId(roomId)

    const cheapestPackage = Packages.getCheapestPackage(compatiblePackages)

    return cheapestPackage || undefined
  }

  /**
   * Get the RoomSelectorListData build by getRoomsWithCompatiblePackages call, same args
   *
   * @typedef {Object}       RoomSelectorListData
   * @property {String}      id                                 - Room Id, like: "2PKA"
   * @property {String}      title                              - Room name
   * @property {String}      description                        - Room description
   * @property {Number}      stock                              - Room stock
   * @property {Boolean}     onRequest                          - Indicates if the room is on request or not
   * @property {Number}      price                              - Room price
   * @property {String}      priceFormatted                     - Room price formatted
   * @property {String}      packageId                          - Room package Id
   * @property {Array}       characteristics                    - Room characteristics
   * @property {String}      occupancyId                        - Occupancy id of the room for current package
   * @property {Boolean}     isCheapest                         - Indicates if its the cheapest occupancy of the room
   *
   * @param {Occupation}     [occupation]                       - Optional occupation to filter by
   * @param {Boolean}        hasFlexibleOccupation
   *
   * @returns {RoomSelectorListData[]}
   */
  getListOfRoomsSelectableByOccupation (occupation, hasFlexibleOccupation, roomNumber) {
    return this.getRoomsWithCompatiblePackages(occupation, hasFlexibleOccupation, roomNumber)
      .map(item =>
        item.compatiblePackages.map(pkg => ({
          id: item.room.id,
          contractId: pkg.contractId,
          title: item.room.name,
          description: item.room.subtitle,
          stock: item.room.stock,
          onRequest: pkg.onRequest,
          price: this.getRoundedPrice(pkg.price),
          priceFormatted: pkg.priceFormatted,
          packageId: pkg.id,
          characteristics: item.room.characteristics,
          occupancyId: pkg.occupancyId,
          isCheapest: pkg.isCheapest,
          images: item.room.images,
          selected: item.room.selected,
          roomNumber: pkg.roomNumber
        })
        )).flat()
  }

  /**
   * Filters the raw data from 'getListOfRoomsSelectableByOccupation' to get the proper data to show
   *
   * @typedef {Object}       FilterResult
   * @property {Array<Room>}  roomsMatchingOccupancy     - list of rooms that are inside the filter
   * @property {Array<Room>}  roomsNotMatchingOccupancy  - list of rooms that are outside the filter
   *
   * @param {Array<Room>}    rooms                       - Optional occupation to filter by
   * @param {Occupation}     occupation                  - Optional occupation to filter by
   * @param {String}         sortType
   *
   * @returns {FilterResult}
   */
  filterRooms (rooms, occupation, sortType) {
    let roomsMatchingOccupancy = rooms
    let roomsNotMatchingOccupancy = []

    if (this.occupancies.isOccupationEmptyOrNotSet(occupation)) {
      // WITHOUT OCCUPANCY FILTER
      roomsMatchingOccupancy = rooms.filter(item => item.isCheapest)
      roomsNotMatchingOccupancy = []
    } else {
      // WITH OCCUPANCY FILTER
      const data = rooms.map(room => ({
        room,
        matches: this.occupancies.occupancyMatchesOccupation(room.occupancyId, occupation)
      }))

      roomsMatchingOccupancy = data.filter(item => item.matches).map(item => item.room)
      const filteredIds = roomsMatchingOccupancy.map(room => room.id)
      roomsNotMatchingOccupancy = data.filter(item => !item.matches && item.room.isCheapest && !filteredIds.includes(item.room.id)).map(item => item.room)
    }

    if (sortType) {
      roomsMatchingOccupancy = this.sortRooms(roomsMatchingOccupancy, sortType)
      roomsNotMatchingOccupancy = this.sortRooms(roomsNotMatchingOccupancy, sortType)
    }

    return { roomsMatchingOccupancy, roomsNotMatchingOccupancy }
  }

  /**
   * Sort an array of rooms by type
   *
   * @typedef {Object}        sortRooms
   *
   * @param {Array}           rooms
   * @param {String}          sortType
   *
   * @returns {sortRooms}
   */
  sortRooms (rooms, sortType) {
    let sorted = rooms
    if (rooms && rooms.length > 0) {
      if (sortType) {
        switch (sortType) {
          case SortRoomsBy.Price:
            sorted = rooms.sort((room1, room2) => this._compareNumbers(room1.price, room2.price))
            break
          case SortRoomsBy.RoomId:
            sorted = rooms.sort((room1, room2) => this._compareString(room1.id, room2.id))
            break
          case SortRoomsBy.Name:
            sorted = rooms.sort((room1, room2) => this._compareString(room1.title, room2.title))
            break
          case SortRoomsBy.PriceAndName:
            sorted = rooms.sort((room1, room2) => this._compareNumbers(room1.price, room2.price) || this._compareString(room1.title, room2.title))
            break
        }
      }
    }
    return sorted
  }

  /**
   * Compare two numbers
   *
   * @param {Number}           number1
   * @param {Number}           number2
   *
   * @returns {Number}
   */
  _compareNumbers (number1, number2) {
    return number1 - number2
  }

  /**
   * Compare two strings
   *
   * @param {String}           string1
   * @param {String}           string2
   *
   * @returns {String}
   */
  _compareString (string1, string2) {
    if (string1 < string2) {
      return -1
    }
    if (string1 > string2) {
      return 1
    }
    return 0
  }

  /**
   * Round a number to the decimals set in the currency settings
   *
   * @param {Number}           price
   *
   * @returns {String | Number}
   */
  getRoundedPrice (price) {
    let roundedPrice = price
    if (this.currencySettings && this.currencySettings.numberOfDecimals && this.currencySettings.numberOfDecimals >= 0) {
      roundedPrice = PriceFormatter.toFormattedText(price, {
        currencySymbol: '',
        numberOfDecimals: this.currencySettings.numberOfDecimals,
        decimalSeparator: this.currencySettings.decimalSeparator
      })
    }
    return roundedPrice
  }

  /**
   *
   * @typedef {Object}        getRoomOccupancyData
   *
   * @param {String}          [roomId]
   *
   * @returns                 {getRoomOccupancyData}
   */
  getRoomOccupancyData (roomId) {
    return Occupancies.getCombinedOccupancyLimits(this.getOccupanciesByRoomId(roomId))
  }

  /**
   * Expands the given occupaGet the roomSelectorListData build by getRoomsWithCompatiblePackages call, same args
   *
   * @typedef {Object}       Occupation
   * @property {Number}      [adults]                - or any other age profile id from data
   * @property {Number}      [children]              - ...
   * @property {Number}      [babies]                - ...
   *
   * @param {Occupation}     [occupation]            - The occupation to be expanded
   *
   * @returns {Occupation} with the total property calculated
   */
  getOccupancyExpandedWithTotal (occupation) {
    return this.ageProfiles.getIds()
      .reduce((baseOcc, ageProfile) => {
        const currentAgeProfileAmount = occupation[ageProfile] || 0

        baseOcc[ageProfile] = currentAgeProfileAmount
        baseOcc.total += this.ageProfiles.countsAsTotal(ageProfile) ? currentAgeProfileAmount : 0

        return baseOcc
      }, { total: 0 })
  }

  /**
   * Returns the matching birthdates of specific occupations
   *
   * @typedef {Object}        pickBirthdatesByOccupation
   *
   * @param {Array}          birthdates
   * @param {Object}          occupation
   *
   * @returns                 {pickBirthdatesByOccupation}
   */
  pickBirthdatesByOccupation (birthdates, occupation) {
    const pickBirthdates = []
    const remainingBirthdates = [...birthdates]

    Object.entries(occupation).forEach(([ageProfile, amount]) => {
      while (amount > 0) {
        const birthdateCandidate = remainingBirthdates.find(birthdate => this.participantsProfiles[birthdate] === ageProfile)

        if (birthdateCandidate) {
          pickBirthdates.push(birthdateCandidate)
          const index = remainingBirthdates.indexOf(birthdateCandidate)
          remainingBirthdates.splice(index, 1)
          amount--
        } else {
          break
        }
      }
    })

    return {
      picked: pickBirthdates,
      remaining: remainingBirthdates
    }
  }

  /**
   * Return the occupancies of packages removing the duplicates
   *
   * @typedef {Object}        _getPackageOccupancies
   *
   * @param {Array}           packages
   *
   * @returns                 {_getPackageOccupancies}
   */
  _getPackageOccupancies (packages) {
    return packages.reduce((occupancyIds, pkg) => {
      if (!occupancyIds.includes(pkg.occupancyId)) {
        occupancyIds.push(pkg.occupancyId)
      }

      return occupancyIds
    }, [])
      .map(occupancyId => this.occupancies.getById(occupancyId))
  }
}

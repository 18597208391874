import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import CalendarField from '../../../../shared/modules/components/calendar-field/main'

loadQueueHelper.add(function () {
  initDatesFilter()
}, 2)

const initDatesFilter = async () => {
  const calendarFields = document.querySelectorAll('[data-docs-jscomponent="c-calendar-field"] .c-calendar-field')

  if (!calendarFields.length > 0) return
  console.log('Calendar field Elements found', calendarFields)

  calendarFields.forEach(element => {
    const calendarObject = {
      selectedDates: [],
      selectedDate: '',
      disabledDates: [],
      startDate: '2021-06-01',
      endDate: '2021-09-30'
    }
    return new CalendarField(element, { calendarDataModelObject: { attributes: calendarObject } })
  })
}

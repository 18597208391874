import Component from '../../../js/core/component/component'
import SelectedStateDataModel from './selected-state-data-model'
import { elementFromString, moveChildrenFrom } from '../../../js/document/html-helper'
import { SelectedStateTemplate } from './w-selected-state.template'
import Img from '../../components/img/main'

const EventEmitter = require('eventemitter3')

const widgetAPI = 'w-selected-state'

const defaults = {
  data: {},
  locales: {}
}

/**
 * SelectedState content
 *
 */
export default class SelectedState {
  /**
   * Creates a new SelectedState behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {HTMLElement} element - The HTML element.
   * @param {Object}                    [options]             - Options object
   * @param {SelectedStateApiDataModel} [options.data]        - Selected state info
   * @param {Object}                    [options.locales]     - Selected locales data
   */
  constructor (element, options = {}) {
    this.element = element
    this.options = {
      ...defaults,
      ...options
    }
    this.events = new EventEmitter()

    // Expose component public API
    this.element[widgetAPI] = {
      refresh: this.refresh.bind(this),
      setLoadingState: this.setLoadingState.bind(this),
      events: this.events
    }

    this.initSelectedStateDataModel()
    this.createHtml()
    this.bindEvents()

    this.element[widgetAPI].selectedStateDataModel = this.selectedStateDataModel
  }

  initSelectedStateDataModel () {
    if (this.selectedStateDataModel) delete this.selectedStateDataModel
    if (this.options.data && Object.keys(this.options.data).length > 0) {
      this.selectedStateDataModel = new SelectedStateDataModel(
        this.options.data,
        {
          id: this.element.id,
          locales: this.options.locales
        }
      )
    }
  }

  createHtml () {
    const selectedStateElement = elementFromString(
      SelectedStateTemplate(
        this.selectedStateDataModel
          ? this.selectedStateDataModel._getSelectedStateTemplateData()
          : { id: this.element.id, isEmpty: true },
        {
          mandatoryCostsCollapsed: this.options.mandatoryCostsCollapsed
        }
      )
    )
    moveChildrenFrom(selectedStateElement, this.element, {
      flush: true,
      attributes: true
    })

    this.submitButton = this.element.querySelector('.w-selected-state__footer-button')
    Img.createInstancesOnDocument(this.element)
    Component.initDocumentComponentsFromAPI(this.element)
  }

  bindEvents () {
    this.handleSubmitButtonClick = this.handleSubmitButtonClick || this._handleSubmitButtonClick.bind(this)
    if (this.submitButton) this.submitButton.addEventListener('click', this.handleSubmitButtonClick)
  }

  unBindEvents () {
    if (this.submitButton) this.submitButton.removeEventListener('click', this.handleSubmitButtonClick)
  }

  setLoadingState (currentLoadingState = false) {
    if (this.submitButton && this.submitButton['c-btn']) this.submitButton['c-btn'].setProp('loading', currentLoadingState)
  }

  /**
   * Handle submit button click
   */
  _handleSubmitButtonClick () {
    this.events.emit('submit', this.options.data)
  }

  /**
   * Refreshes selectedState with new data
   *
   * @param {Object} data
   */
  refresh (data) {
    this.options.data = data
    this.unBindEvents()
    this.initSelectedStateDataModel()
    this.createHtml()
    this.bindEvents()
    this.events.emit('refresh')
  }
}

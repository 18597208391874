import { TitleTemplate } from '../../components/title/c-title.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'

/**
 * The FaqTopQuestionsContentData contains all data needed to hydrate inner content for FaqTopQuestions widget
 *
 * @typedef {Object}                      BookingChangedNotificationResponseContentData

 * @property {string}   templateData.modalId                - The id of the modal.
 * @property {Boolean}  templateData.isSuccess              - Indicates if the response is success (true) or not (false)
 * @property {string}   templateData.title                  - The title of the modal.
 * @property {string}   templateData.text            - The body of the response.
 * @property {string}   templateData.buttonText             - The text of the button that closes the modal.
 */

export const defaultBookingChangedNotificationResponseContentData = {
  modalId: 'booking-1234-flight-changed',
  isSuccess: false,
  title: '',
  text: '',
  buttonText: ''
}

export const BookingChangedNotificationResponseContentTemplate = (d) => {
  d = { ...defaultBookingChangedNotificationResponseContentData, ...d }
  const statusClass = d.isSuccess ? 'w-booking-changed-notification--succeeded' : 'w-booking-changed-notification--failed'
  const icon = d.isSuccess ? 'checkmark-circle' : 'warning-circle'
  const btnVariant = d.isSuccess ? 'flow' : 'default'

  return `<div class='w-booking-changed-notification--content-notification sp-stack-children--medium ${statusClass}'>
    ${icon ? `<div class="w-booking-changed-notification__icon m-icon--size-huge m-icon--${icon}"></div>` : ''}
    ${d.title
      ? `${TitleTemplate({
        text: d.title,
        size: 'medium',
        variant: '',
        extraClasses: 'w-booking-changed-notification__title'
      })}`
      : ''}

    ${d.text || d.buttonText
      ? `
          ${d.text
            ? `<p>${d.text}</p>`
            : ''}
          ${d.buttonText
            ? `${BtnTemplate({
              text: d.buttonText,
              variant: btnVariant,
              attributes: {
                'data-c-modal__action': 'close',
                'data-c-modal__id': d.modalId
              }
            })}`
            : ''}
        `
      : ''}
    </div>`
}

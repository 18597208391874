import { arrayOf, bool, number, objectOf, oneOfType, shape, string } from 'prop-types'

export const propTypes = {

  bestValues: arrayOf(
    shape({
      name: string,
      threshold: number
    })
  ).isRequired,

  bestValuesCaption: string,

  mandatoryExtraCostsText: string,
  staticText: string,

  /** Prices collection to be shown on grid */
  prices: arrayOf(
    shape({
      duration: number,
      departureDate: string,
      minPricePerPerson: number,
      minPricePerPersonFormatted: string,
      bestValueScore: number
    })
  ).isRequired,

  texts: shape({
    navigationNavText: string,
    durationInDaysText: string,
    durationInNightsText: string
  }),

  showDurationInNights: bool,

  /** Extra class names to be appended on root element */
  extraClasses: string,

  /** Extra attributes to be appended on root element */
  attributes: objectOf(
    oneOfType([
      bool,
      string
    ])
  )
}

export const defaultProps = {
  bestValues: [],
  prices: [],
  texts: {},
  showDurationInNights: false,
  showAddNextMonth: false
}

import { registerWidget } from '../../../js/core/widget/widget-directory'
import BookingItem from '../booking-item/main'
import { BookingMessageTemplate } from './w-booking-message.template'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import BookingMessageDataMapper from './data-mapper'

const widgetApi = 'w-booking-message'

const classNames = {
  hidden: 'is-hidden'
}

/**
 * BookingMessages widget
 */
export default class BookingMessages extends BookingItem {
  constructor (element) {
    super(element)
    this.element = element
    this.dataMapper = new BookingMessageDataMapper()
  }

  /**
   *
   * @param {Object}    data      - Contains the data needed to render the widget body
   */
  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  _updateWidgetData () {
    if (this.data.conditionalMessages) {
      const apiData = this.data.conditionalMessages.find(condMessage => condMessage.componentId === this.componentId)
      if (apiData) {
        const widgetData = this.dataMapper.mapComponent(apiData)
        if (widgetData && widgetData.messages.length > 0) {
          this._showComponent()
          const renderedHtml = BookingMessageTemplate(widgetData)
          const newContent = elementFromString(renderedHtml)
          Component.initDocumentComponentsFromAPI(newContent)
          this._render(newContent)
        } else {
          this._hideComponent()
        }
      }
    }
  }

  _showComponent () {
    this.element.classList.remove(classNames.hidden)
  }

  _hideComponent () {
    this.element.classList.add(classNames.hidden)
  }

  _render (el) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(el)
  }
}

registerWidget(BookingMessages, widgetApi)

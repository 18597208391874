/**
 * @typedef {Object}       OccupancyLimitsProfileData
 *
 * @property {Number}      min                                - Min occupancy
 * @property {Number}      max                                - Max occupancy
 *
 *
 * @typedef {Object}       OccupancyLimitsData
 *
 * @property {OccupancyLimitsProfileData}    total             - Limits for Total people
 * @property {OccupancyLimitsProfileData}    [adults]          - Limits for each other age profile ids
 * @property {OccupancyLimitsProfileData}    [children]        - ...
 * @property {OccupancyLimitsProfileData}    [babies]          - ...
 *
 *
 * @global
 * @typedef {Object}       OccupancyData
 *
 * @property {String}                 id                      - Occupancy id, like: "occupancy_1"
 * @property {OccupancyLimitsData}    limits                  - Occupancy limits
 * @property {String}                 [description]           - Occupancy description
 *
 */

/**
 * Occupancies dictionary
 */
export default class Occupancies {
  /**
   *
   * @constructor
   * @param {OccupancyData[]}     occupanciesData
   *
   */
  constructor (occupanciesData = []) {
    this.data = occupanciesData
  }

  /**
   * Get occupancy matching a given ID
   *
   ** @param {String}            occupancyId
   *
   * @returns {OccupancyData[]}
   */
  getById (occupancyId) {
    return this.data.find(occupancyData => occupancyData.id === occupancyId)
  }

  /**
   * Get Occupancies matching a given occupation
   *
   * @param {Occupation}            occupation
   *
   * @returns {OccupancyData[]}
   */
  getOccupanciesMatchingOccupation (occupation) {
    return this.data.filter(occupancyData => Occupancies.occupancyMatchesOccupation(occupancyData, occupation))
  }

  /**
   * Get compatible occupancies of an occupation.
   *
   * For example if occupations it's 3 adults will return as valid room occupancies occupation of 1 person,
   * occupation of 2 people and occupation of 3 people, because customer may be not want the 3 adults in same room
   *
   * @param {Occupation}            occupation
   * @param {Boolean}               hasFlexibleOccupation
   *
   * @returns {OccupancyData[]}
   */
  getCompatibleOccupancies (occupation, hasFlexibleOccupation) {
    return this.data.filter(occupancyData => Occupancies.occupancyMatchesOccupation(occupancyData, occupation, hasFlexibleOccupation))
  }

  /**
   * Checks if given occupancy matches given occupation
   *
   * @param {String}                occupancyId
   * @param {Occupation}            occupation
   *
   * @returns {Boolean}
   */
  occupancyMatchesOccupation (occupancyId, occupation) {
    const occupancyData = this.getById(occupancyId)
    return (
      occupancyData &&
      Occupancies.occupancyMatchesOccupation(occupancyData, occupation)
    )
  }

  /**
   * Checks if given occupancy matches given occupation.
   *
   * @param {OccupancyData}         occupancyData            - The occupancy data (the important data is the room limits for each age profile).
   * @param {Occupation}            occupation               - The desired occupation to be check against the room limits.
   * @param {Boolean}               [hasFlexibleOccupation]  - Indicates if it's pre-packaging (if true) or dynamic packaging (if false).
   *
   * @returns {Boolean}  True if the occupancy matches the occupation, false otherwise.
   */
  static occupancyMatchesOccupation (occupancyData, occupation, hasFlexibleOccupation = false) {
    const { limits } = occupancyData
    let assignedPeopleOnRoom = 0

    const ageProfilesMatches = Object.keys(limits).reduce((matches, ageProfileId) => {
      const peopleToConsider = occupation[ageProfileId] || 0

      if (ageProfileId !== 'total') {
        // Aggregate all the possible people in each age profile to the room, as it will be check at the end with the total minimum people of a room.
        // This is required to fix bug MF-1658: the occupancy 2:2-0:1-0:2-0:2 matched the occupation 2-0-0 when it should have not.
        assignedPeopleOnRoom += Math.min(peopleToConsider, limits[ageProfileId].max)
      }

      return matches &&
        (
          // Check age profile by age profile that their specific limits matches the occupancy: the minimum always has to be enforced, while the maximum
          // only for DP (the occupation of each room is fixed) as for PP the customer can decide to put less people in the room than the total unallocated people.
          limits[ageProfileId].min <= peopleToConsider &&
          (limits[ageProfileId].max >= peopleToConsider || hasFlexibleOccupation)
        )
    }, true)

    return ageProfilesMatches &&
           assignedPeopleOnRoom >= limits.total.min // Check that the maximum people to the room, age profile at age profile, still meets the total minimum people
    // requirement of the room (see bug MF-1658): it was because age profiles where considered individually and not
    // also as a whole (putting all the maximum age profiles, given the occupatin, didn't match the total minimum).
  }

  /**
   * Combines some OccupancyData limits to obtain one to fit them all
   *
   * @param {OccupancyData[]}       occupancies
   *
   * @returns {OccupancyLimitsData}
   */
  static getCombinedOccupancyLimits (originalOccupancies) {
    // CHECK IT WITH DANI FORNELLS BEFORE MERGE THIS CODE
    // Needed to clone the occupancies because it's modified min and max limit
    // The object returned by it, it's a reference to this.data insed occupancies.js  any change done in the object returned also change
    // values in occupancies.js.
    // To reproduce it uncomment console.log line getCheapestPackageOfRoom of room-selector-service.js.
    // Set a party composition of 6 people, click on a room of 1 person, and then select room 2 person room type A (Rihana ressort).
    // Change the room occupation and you will see in console that the package of 3 people has min occupancy 2 max occupancy 3. The package of 3
    // people it´s not assignable to 2 people.
    const occupancies = JSON.parse(JSON.stringify(originalOccupancies))

    if (!occupancies.length) return undefined

    return occupancies.reduce((combinedLimits, { limits }) => {
      return Object.entries(combinedLimits).reduce((combinedLimits, [ageProfile, { min, max }]) => {
        combinedLimits[ageProfile].min = Math.min(min, limits[ageProfile].min)
        combinedLimits[ageProfile].max = Math.max(max, limits[ageProfile].max)
        return combinedLimits
      }, { ...combinedLimits })
    }, { ...occupancies[0].limits })
  }

  /**
   * Checks if given occupancy is set or empty
   *
   * @param {Occupation}       occupation
   *
   * @returns {Boolean}
   */
  isOccupationEmptyOrNotSet (occupation) {
    const totalOccupancy = occupation
      ? Object.keys(occupation).reduce((total, profileId) => {
        total += occupation[profileId] || 0
        return total
      }, 0)
      : 0

    return totalOccupancy === 0
  }
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'

loadQueueHelper.add(function () {
  const getAndShowValue = function (element) {
    setTimeout(() => {
      const api = element['c-phone-input']
      const input = element.querySelector('input')
      console.log('Phone input id=' + input.id + ' value=' + api.getProp('value'))
    }, 700)
  }
  const demoPhoneInputs = document.querySelectorAll('.docs-demo-phone-input-get-value')
  demoPhoneInputs.forEach(demoPhoneInput => {
    getAndShowValue(demoPhoneInput)
  })
}, 2)

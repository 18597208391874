import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingTransportSummary from '../../../../shared/modules/widgets/booking-transport-summary/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const bookingTransportSummaryData = require('../../../../shared/modules/widgets/booking-transport-summary/data/w-booking-transport-summary__js-template')
const bookingTransportSummaryBusData = require('../../../../shared/modules/widgets/booking-transport-summary/data/w-booking-transport-summary__js-template--bus')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = async () => {
  const bookingTransportSummaryItems = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-transport-summary"]')

  bookingTransportSummaryItems.forEach(element => {
    const bookingTransportSummaryItem = new BookingTransportSummary(element)
    return bookingTransportSummaryItem
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingTransportSummaryJsTemplate = document.querySelectorAll('[data-booking-transport-summary-demo]')
  handleFetched(bookingTransportSummaryJsTemplate, bookingTransportSummaryData)

  const bookingTransportSummaryBusJsTemplate = document.querySelectorAll('[data-booking-transport-summary-bus-demo]')
  handleFetched(bookingTransportSummaryBusJsTemplate, bookingTransportSummaryBusData)
}

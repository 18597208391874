/**
 * The FormChangePatternConfiguration contains all data needed to hydrate a Form Change Pattern Configuration view
 *
 * @typedef {Object}          FormChangePatternConfiguration
 *
 * @property {Object[]}       settingsId    - Any name as property, which is the identifier of the associated settings for a field.
 * @property {String}         settingsId[].fieldSelector          - The name of the field whose pattern will change.
 * @property {Object[]}       settingsId[].patterns               - The list of patterns
 * @property {String}         settingsId[].patterns[].key         - The value of the parent field to match, to select the new pattern.
 * @property {String}         settingsId[].patterns[].pattern     - The pattern to use.
 */
export const defaultFormFieldsetData = {
}

export const FormChangePatternConfigurationTemplate = (d) => {
  d = d || {}
  return `
    <script type="application/json" data-c-form__change-pattern-configuration>
        ${JSON.stringify(d)}
    </script>
    `
}

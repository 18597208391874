import BookingItem from '../booking-item/main'
import { registerWidget } from '../../../js/core/widget/widget-directory'

const widgetApi = 'w-booking-confirmation-summary'

// Scroll config
const config = {
  scroll: { block: 'start', behavior: 'smooth' }
}

const widgetQueries = {
  bookingNumber: `[data-${widgetApi}__details-booking-number]`,
  departureDate: `[data-${widgetApi}__details-departure-date]`,
  totalPrice: `[data-${widgetApi}__details-total-price]`,
  anchorBtn: `[data-${widgetApi}__anchor-btn]`,
  targetId: `[data-${widgetApi}__anchor-btn_targetid]`
}

export default class BookingConfirmationSummary extends BookingItem {
  /**
  *
  * @param {HTMLElement} element   - The HTML content
  */
  constructor (element) {
    super(element)
    this.element = element
    this.bookingNumberElement = this.element.querySelector(widgetQueries.bookingNumber)
    this.departureDateElement = this.element.querySelector(widgetQueries.departureDate)
    this.totalPriceElement = this.element.querySelector(widgetQueries.totalPrice)
    this.anchorButtons = this.element.querySelectorAll(widgetQueries.anchorBtn)
    this.anchorButtons.forEach(button => button.addEventListener('click', (ev) => this._scrollToAnchor(ev)))
  }

  _scrollToAnchor (ev) {
    const btn = ev.currentTarget
    const targetId = btn.dataset.wBookingConfirmationSummary__anchorBtn_targetid
    document.querySelector('[data-component-id=\'' + targetId + '\']').scrollIntoView(config.scroll)
  }

  /**
  *  Will be executed once the mediator has fetched a response from the backend
  *
  * @param {Object}    data      - Contains the data needed to render the widget body
  */
  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  _updateWidgetData () {
    const apiData = this.data.confirmationComponents && this.data.confirmationComponents.find(service => service.componentId === this.componentId)

    if (apiData) {
      const bookingNumber = apiData.bookingNumber
      const departureDate = apiData.bookingDate
      const totalPrice = apiData.bookingTotal

      if (bookingNumber) {
        this.bookingNumberElement.innerText = bookingNumber
      }
      if (departureDate) {
        this.departureDateElement.innerText = departureDate
      }
      if (totalPrice) {
        this.totalPriceElement.innerText = totalPrice
      }
    } else {
      super.setHasError()
    }
  }
}

registerWidget(BookingConfirmationSummary, widgetApi)

import PriceFormatter, { roundOff } from '../../../js/helpers/price-formatter'
import { isEmptyObject } from '../../../js/helpers/object'

export default class BookingBundleDataMapper {
  /**
   * Maps API response data to widget data
   *
   * @typedef {Object}         BookingBundleData
   *
   * @property {Object}        [apiResponseData]    - Api response data
   */
  mapWidgetData (apiResponseData, configurations) {
    const widgetData = (!isEmptyObject(apiResponseData) && !isEmptyObject(configurations))
      ? {
          title: (configurations.bundleTexts && configurations.bundleTexts?.bundleTitle) || '',
          titleSelected: (configurations.bundleTexts && configurations.bundleTexts?.bundleSelectedTitle) || '',
          selectButtonText: (configurations.bundleTexts && configurations.bundleTexts?.selectBundleButtonText) || '',
          removeButtonText: (configurations.bundleTexts && configurations.bundleTexts?.removeBundleButtonText) || '',
          bundle: this._mapBundle(apiResponseData, configurations),
          image: configurations.bundleImage ? this._mapBundleImage(configurations.bundleImage) : {}
        }
      : null
    return widgetData
  }

  _mapBundle (data, configurations) {
    const option = (data.options && data.options[0]) || null
    const pricePerParticipant = data.isPricePerPerson || false

    if (!option) { return null }

    return {
      selected: option.isSelected,
      code: option.code,
      price: this._mapPriceComponentData(option, pricePerParticipant, configurations.priceConfiguration),
      usps: this._mapUspInfo(configurations.bundleUsps)
    }
  }

  _mapPriceComponentData (option, pricePerParticipant, priceConfiguration) {
    if (!option) { return }

    const { finalPriceFormatted, oldPriceFormatted, discountFormatted } = this._mapBundlePrices(option, option.appliesToParticipants, pricePerParticipant, priceConfiguration)

    return {
      value: finalPriceFormatted,
      priceConfig: {
        size: '',
        legend: '',
        saleText: discountFormatted || undefined,
        oldPrice: oldPriceFormatted || undefined,
        publicPrice: undefined,
        currency: (priceConfiguration && priceConfiguration.currencySymbol) || '',
        currencyPosition: (priceConfiguration && priceConfiguration.isCurrencyAtStart) ? 'before' : 'after'
      }
    }
  }

  _mapBundleImage (bundleImage) {
    return {
      sync: false,
      resolve: 'intersect',
      placeholderSrc: `${bundleImage.placeholderImageUrl}`,
      resolvedSrc: `${bundleImage.imageUrl}`,
      ratio: '16:9',
      dynamicRatios: [
        {
          ratio: '1:1',
          bp: 'sml'
        }
      ]
    }
  }

  _mapUspInfo (usps) {
    const widgetUspInfo = {
      variant: '',
      items: usps ? usps.map(usp => this._mapUsp(usp)) : []
    }
    return widgetUspInfo
  }

  _mapUsp (apiUsp) {
    const widgetUsp = {
      text: apiUsp.text,
      icon: apiUsp.icon
    }

    return widgetUsp
  }

  _mapBundlePrices (option, participantIds, isPricePerPerson, priceConfiguration = null) {
    if (!option) { return {} }
    let priceToBeShown = 0
    let oldPrice = 0
    let discount = 0

    if (isPricePerPerson && option.priceDetails && option.priceDetails.length > 0) {
      option.priceDetails.forEach(optionPrice => {
        const includedIds = participantIds.filter(id => optionPrice.participantIds.includes(id))
        if (includedIds.length > 0) {
          priceToBeShown += includedIds.length * optionPrice.price
          oldPrice += optionPrice.oldPrice ? includedIds.length * optionPrice.oldPrice : 0
          discount += optionPrice.discount ? includedIds.length * optionPrice.discount : 0
        }
      })
    } else if (isPricePerPerson && (!option.priceDetails || option.priceDetails.length === 0)) {
      priceToBeShown = participantIds.length >= 1 ? (participantIds.length * option.price) : option.price
      oldPrice = participantIds.length >= 1 ? (participantIds.length * option.oldPrice) : option.oldPrice
      discount = participantIds.length >= 1 ? (participantIds.length * option.discount) : option.discount
    } else {
      priceToBeShown = option.price || 0
      oldPrice = option.oldPrice || 0
      discount = option.discount || 0
    }

    const finalPriceFormatted = priceConfiguration ? this._formatPrice(priceToBeShown, priceConfiguration) : priceToBeShown
    const oldPriceFormatted = oldPrice > 0 ? (priceConfiguration ? this._formatPrice(oldPrice, priceConfiguration) : oldPrice) : undefined
    const discountFormatted = discount < 0 ? (priceConfiguration ? PriceFormatter.toFormattedText(discount, priceConfiguration) : discount) : undefined

    return { finalPriceFormatted, oldPriceFormatted, discountFormatted }
  }

  _formatPrice (price, priceConfig) {
    let priceFormatted = price

    if (priceConfig) {
      priceFormatted = priceConfig.numberOfDecimals || priceConfig.numberOfDecimals === 0 ? roundOff(priceFormatted, priceConfig.numberOfDecimals) : price.toString()
      priceFormatted = priceConfig.decimalSeparator ? priceFormatted.replace('.', priceConfig.decimalSeparator) : priceFormatted
      priceFormatted = price || price === 0
        ? priceFormatted
        : ''
    }

    return priceFormatted
  }
}

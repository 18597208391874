/**
 * The content usps contains all data needed to hydrate the view
 *
 * @typedef {Object}          ContentUspsData
 *
 * @property {Boolean}        [unresolved]
 * @property {Boolean}        [showHorizontally]
 * @property {Boolean}        [useIconSecondaryColor]
 * @property {Boolean}        [useUspBlock]
 * @property {String}         [extraClasses]
 * @property {Object}         [attributes]
 * @property {Boolean}        [isSlider]
 * @property {String}         [sliderId]
 * @property {Array}          items
 * @property {String}         [items.link]
 * @property {Object}         [items.image]
 * @property {String}         items.text
 * @property {String}         [items.subtitle]
 * @property {String}         [items.extraClasses]

 */

import { encodeAttributes } from '../../../js/helpers/string'
import { SliderTemplate } from '../../components/slider/c-slider.template'
import { BulletListItemTemplate } from '../../components/bullet-list/c-bullet-list__item.template'
import { BulletListTemplate } from '../../components/bullet-list/c-bullet-list.template'

export const defaultContentUspsData = {
  unresolved: false,
  showHorizontally: false,
  useIconSecondaryColor: false,
  useUspBlock: false,
  extraClasses: '',
  attributes: {},
  isSlider: false,
  sliderId: '',
  items: []
}

export const defaultContentUspsItemData = {
  link: '',
  image: null,
  text: '',
  subtitle: '',
  extraClasses: ''
}

export const ContentUspsTemplate = (d) => {
  d = { ...defaultContentUspsData, ...d }
  return `
    <div class="w-content-usps
      ${d.unresolved ? 'is-unresolved' : ''}
      ${d.showHorizontally ? 'w-content-usps--horizontal' : ''}
      ${d.useIconSecondaryColor ? 'w-content-usps--secondary-color-checkmark' : ''}
      ${d.useUspBlock ? 'w-content-usps--usp-block' : ''}
      ${d.extraClasses}"
      ${d.attributes ? encodeAttributes(d.attributes) : ''}>
        ${d.isSlider
        ? `${ContentUspsSliderTemplate(d)}`
        : `${d.useUspBlock
            ? `${d.items.map(item => ContentUspsItemTemplate(item, d.useUspBlock)).join('')}`
            : `${BulletListTemplate({
              variant: d.showHorizontally ? 'inline' : '',
              icon: 'checkmark',
              items: d.items
            })}`}`}
    </div>
  `
}

const ContentUspsSliderTemplate = (d) => {
  return SliderTemplate({
    id: d.sliderId,
    extraClasses: `w-content-usps__usp-${d.useUspBlock ? 'block' : 'list'}-slider`,
    noButtons: true,
    outerNavigation: false,
    barNavigation: false,
    attributes: {
      'data-loop': true,
      'data-responsive': 'xs:1,sml:disable',
      'data-autoplay': true,
      'data-autoplay-timeout': '4000'
    },
    items: d.items.map(item => ContentUspsItemTemplate(item, d.useUspBlock))
  })
}

const ContentUspsItemTemplate = (d, useUspBlock) => {
  d = { ...defaultContentUspsItemData, ...d }
  return `
    ${useUspBlock
      ? `<div class="w-content-usps__usp-block-item ${d.extraClasses}">
        ${d.link ? `<a class="w-content-usps__usp-block-link" ${d.link ? `href="${d.link}"` : ''}">` : ''}
            ${d.image
              ? `<img class="w-content-usps__usp-block-image" src="${d.image.resolvedSrc}" alt="${d.image.title}">`
              : d.icon ? `<span class="m-icon m-icon--${d.icon} m-icon--size-large w-content-usps__icon"></span>` : ''}
            <h3 class="w-content-usps__usp-block-title">${d.text}</h3>
            ${d.subtitle ? `<p class="w-content-usps__usp-block-subtitle">${d.subtitle}</p>` : ''}
        ${d.link
          ? '</a>'
          : ''}
      </div>`
      : `
      ${BulletListItemTemplate({
        icon: 'checkmark',
        text: d.text
      })}
    `}`
}

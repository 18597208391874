import { RoomListItemTemplate } from './w-room-list-item.template'
import { NumberStepperTemplate } from '../../components/number-stepper/c-number-stepper.template'

/**
 *  The RoomData contains the information for each room that will be printed as a item for the list.
 *
 *  @typedef  {Object}      RoomData
 *
 *  @property {String}      id                   - The identifier of the room
 *  @property {Boolean}     onRequest            - A boolean that indicates if the room is on request or not
 *  @property {String}      stockWarningMessage  - The text to show when as a warning on the stock
 *  @property {String}      title                - Name of the room
 *  @property {String}      description          - Occupancy description of the room
 *  @property {Decimal}     price                - The price value
 *  @property {Array}       characteristics      - Array of texts enumerating the characteristics of the room
 *  @property {Object[]}    images               - Array of objects representing the images of the room
 *
 */

/**
 *  The LocalesData contains the locales used for this template.
 *
 *  @typedef  {Object}      LocalesData
 *
 *  @property {String}      onRequestLabel         -  The text to show when a room is "on request"
 *  @property {String}      roomTypeDescriptionTitle -  The text to show as collapse header of the room characteristics
 *  @property {String}      selectRoomButtonText   -  The text to show in the button to select a room
 *  @property {String}      emptyFilteredRooms     -  The message to show to the user when the filtered rooms are empty
 *  @property {String}      roomsNotMatchingOccupancyHeader -  The message to show in the header of filtered out rooms (rooms that doesn't match the occupancy filter)
 *  @property {String}      priceFromWord          -  The text 'from' to show next to the price when the room will cost that price or greater
 *
 */

/**
 *  The OccupancySelectorData contains the information needed for each number stepper to be rendered.
 *
 *  @typedef  {Object}      OccupancySelectorData
 *
 *  @property {String}      ageProfileId  -  The age profile identifier (tipically adults, children or babies)
 *  @property {Number}      current       -  The current value for the number stepper
 *  @property {Number}      max           -  The maximum number that can be inserted in number stepper
 *  @property {String}      name          -  The primary label text to name to shown in the number stepper
 *  @property {String}      description   -  The secondary label text (less prominent) to show below the primary text
 *
 */

/**
 *  The RoomListTemplate contains all the data needed to hydrate a RoomList component.
 *
 *  @typedef  {Object}      RoomListTemplate
 *
 *  @property {String}      id                  - The element id
 *  @property {String}      roomWord            - The word to say 'room'
 *  @property {String}      roomNumber          - The room number to numerate the rooms
 *  @property {String}      roomOccupancy       - The room occupancy description to be shown next to the title
 *  @property {Boolean}     showEmtpyFilteredRoomsMessage     - A boolean that indicates if the message about empty rows must be shown
 *  @property {String}      roomsNotMatchingOccupancyHeader  - The message to show when filter results in no rooms available, and all of them are on the grayed zone
 *  @property {RoomData[]}  rooms               - The rooms to be appended as actionableList
 *  @property {PriceData}   priceConfig         - The price config
 *  @property {LocalesData} locales             - The locales texts
 *  @property {OccupancySelectorData[]}  occupancySelectors               - The rooms to be appended as actionableList
 *
 */
export const defaultRoomListTemplateData = {
  id: '',
  roomWord: '',
  roomNumber: 0,
  showEmtpyFilteredRoomsMessage: false,
  roomsNotMatchingOccupancyHeader: '',
  rooms: [],
  roomsNotMatchingOccupancy: [],
  occupancySelectors: []
}

export const RoomListTemplate = (d) => {
  d = { ...defaultRoomListTemplateData, ...d }

  return `
    <div class="w-room-list">
      <div class="w-room-list__container">
        <div class='w-room-list__header'>
          <div class='w-room-list__room-title'>
            <span class="w-room-list__room-title-icon m-icon m-icon--bed"></span>
            <h3 class='w-room-list__room-title-text'>${d.roomWord} ${d.roomNumber ? d.roomNumber : ''}</h3>
            ${d.roomOccupancy ? `<span class="w-room-list__room-title-occupancy">${d.roomOccupancy}</span>` : ''}
          </div>
          ${d.occupancySelectors && d.occupancySelectors.length > 0
            ? `
            <div class="w-room-list__occupancy-selector">
              ${d.occupancySelectors.map(occupancySelector => NumberStepperTemplate(getNumberStepperData(d.id, occupancySelector))).join(' ')}
            </div>
            `
            : ''}
        </div>
        <div id="${d.id}" class="w-room-list-items" data-w-room-list__rooms-list=''>
        </div>
      </div>
    </div>
  `
}

export const RoomListItemsTemplate = (d) => {
  return `
      <div>
      ${d.rooms.map(item => `
        ${RoomListItemTemplate({
          ...item,
          roomNumber: d.roomNumber,
          locales: d.locales,
          isSelectable: true,
          priceConfig: {
            ...d.priceConfig,
            legend: d.showFromTextInRooms
                      ? d.locales.priceFromWord
                      : ''
          }
        })}
      `).join('')}

        ${d.showEmtpyFilteredRoomsMessage
          ? `<div class="w-room-list__empty-filtered-rooms-message">
                ${d.locales.emptyFilteredRooms}
              </div>`
          : ''}

        ${d.roomsNotMatchingOccupancy && d.roomsNotMatchingOccupancy.length > 0
          ? ` ${d.roomsNotMatchingOccupancyHeader
              ? `<div class="w-room-list__filtered-out-header">
                  ${d.roomsNotMatchingOccupancyHeader}
                </div>`
              : ''}

              <div class="w-room-list-item__filtered-out-container">
              ${d.roomsNotMatchingOccupancy.map(item => `

                ${RoomListItemTemplate({
                  ...item,
                  locales: d.locales,
                  priceConfig: {
                    ...d.priceConfig,
                    legend: d.locales.priceFromWord
                  },
                  extraClasses: 'w-room-list-item__filtered-out',
                  isSelectable: false
                })}
                `).join('')}
              </div>
             `
        : ''}

        </div>
      `
}

export const slpittedRoomListItemsTemplate = ({ d, selectedRoomTypeList, notSelectedRoomTypeList }) => {
  return `<div>
    <div class="w-room-list--selected-room-type">
    ${d.locales?.roomListSelectedTitle ? `<div class="w-room-list--selected-room-type-title">${d.locales.roomListSelectedTitle}</div>` : ''}
      ${RoomListItemsTemplate({ ...d, rooms: selectedRoomTypeList })}
    </div>
    ${
      notSelectedRoomTypeList?.length
? `
      <hr class="w-room-list-separator">
      <div class="w-room-list--not-selected-room-type">
      ${d.locales?.roomListOtherTitle ? `<div class="w-room-list--selected-room-type-title">${d.locales.roomListOtherTitle}</div>` : ''}
        ${RoomListItemsTemplate({ ...d, rooms: notSelectedRoomTypeList })}
      </div>`
: ''
    }

  </div>
  `
}
function getNumberStepperData (roomId, occupancySelector) {
  return {
    id: roomId + `_${occupancySelector.ageProfileId}`,
    value: occupancySelector.current || 0,
    minValue: 0,
    maxValue: occupancySelector.max,
    step: 1,
    label: occupancySelector.name,
    labelDescription: occupancySelector.description,
    extraClasses: 'w-room-list__number-stepper',
    attributes: { 'data-age-profile': occupancySelector.ageProfileId }
  }
}

/**
 * The SwipeData contains all data needed to hydrate a Swipe selector view.
 *
 * @typedef {Object}     SwipeData
 */

import { SwipeButtonTemplate } from './o-swipe-btn.template'

export const SwipeTemplate = () => {
  return `${SwipeButtonTemplate({ direction: 'left', disabled: true })}
${SwipeButtonTemplate({ direction: 'right', disabled: true })}
`
}

import { FloatingBoxTemplate } from '../../components/floating-box/c-floating-box.template'
import { TextboxTemplate } from '../../components/textbox/c-textbox.template'
import { FloatingBoxHeaderTemplate } from '../../components/floating-box/c-floating-box__header.template'
import { FloatingBoxBodyTemplate } from '../../components/floating-box/c-floating-box__body.template'
import { FloatingBoxFooterTemplate } from '../../components/floating-box/c-floating-box__footer.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { ActionableListTemplate } from '../../components/actionable-list/c-actionable-list.template'

/**
 *  The RoomDropdownTemplate contains all the data needed to hydrate a RoomDropdownTemplate component.
 *
 *  @typedef {Object}     RoomDropdownTemplate
 *
 *  @property {String}    id                  - The element id
 *  @property {String}    placeholder         - The dropdown placeholder
 *  @property {Object[]}  rooms               - The rooms to be appended as actionableList
 *  @property {Number}    roomStockThresholdToShowLowAvailability  - The stock threshold to show warning labels
 *  @property {String}    roomsLeftAvailable  - The pattern to warn how many rooms are left, if necessary
 *  @property {String}    lastRoomAvailable   - The pattern to warn about last room available
 *  @property {String}    onRequestLabel      - The text to show when a room is "on request"
 *  @property {PriceData} priceConfig          - The price config
 *  @property {String}    cancel               - The cancel button text
 *
 */

export const defaultRoomDropdownTemplateData = {
  id: '',
  placeholder: '',
  rooms: [],
  roomStockThresholdToShowLowAvailability: 5,
  roomsLeftAvailable: '',
  lastRoomAvailable: '',
  onRequestLabel: '',
  priceConfig: {},
  cancel: ''
}

export const RoomDropdownTemplate = (d) => {
  d = { ...defaultRoomDropdownTemplateData, ...d }

  return `<div id="${d.id}" class="w-room-dropdown">
  ${TextboxTemplate({
    id: `${d.id}__textbox`,
    readonly: true,
    attributes: { 'data-w-room-dropdown__textbox': '' },
    icon: 'chevron-down',
    iconPosition: 'right',
    placeholder: d.placeholder
  })}
  ${FloatingBoxTemplate({
    gradient: true,
    extraClasses: 'w-room-dropdown__floating-box',
    attributes: { 'data-w-room-dropdown__floating-box': '' }
  },
  FloatingBoxHeaderTemplate({}, '') +
  FloatingBoxBodyTemplate({},
    `${d.rooms
        ? `<div data-w-room-dropdown__rooms-container>
            ${ActionableListTemplate({
              attributes: { 'data-w-room-dropdown__rooms-list': '' },
              jsApi: true,
              config: {
                itemType: 'room',
                priceDefaults: d.priceConfig,
                roomStockThresholdToShowLowAvailability: d.roomStockThresholdToShowLowAvailability,
                roomsLeftAvailable: d.roomsLeftAvailable,
                lastRoomAvailable: d.lastRoomAvailable,
                onRequestLabel: d.onRequestLabel
              },
              items: d.rooms
            })}</div>`
        : ''}`) +
  FloatingBoxFooterTemplate({},
    BtnTemplate({
      variant: 'flat-neutral',
      attributes: { 'data-w-room-dropdown__action': 'cancel' },
      text: d.cancel
    }))
  )}
  </div>
`
}

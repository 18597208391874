import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import JSONFetcher from '../../../../shared/js/helpers/json-fetcher'
import FilterModel from '../../../../shared/js/data/filters/filter-model'
import { FILTER_TYPES } from '../../../../shared/js/data/filters/config'
import ChoiceListFilter from '../../../../shared/modules/widgets/choice-list-filter/main'

loadQueueHelper.add(function () {
  initChoiceListMultipleFilter()
}, 2)

const initChoiceListMultipleFilter = () => {
  const choiceListFilterElement = document.getElementById('my-choice-list-filter')
  const choiceListMultipleFilterElement = document.getElementById('my-choice-list-multiple-filter')
  if (!choiceListFilterElement && !choiceListMultipleFilterElement) return

  if (choiceListFilterElement) {
    console.log('ChoiceList Filter Single Element found', choiceListFilterElement)
  }
  if (choiceListMultipleFilterElement) {
    console.log('ChoiceList Multiple Filter Element found', choiceListMultipleFilterElement)
  }

  const quickSearchRequest = new JSONFetcher({ src: '../assets/api/quick-search.json' })
  quickSearchRequest
    .fetch()
    .then(data => {
      const filterData = data.filters.find(filter => filter.type === FILTER_TYPES.DEPARTURE_AIRPORT)
      const filterModel = new FilterModel(filterData)

      const choiceListFilter = new ChoiceListFilter(choiceListFilterElement, filterModel)
      console.log('ChoiceList Single initialized', choiceListFilter)
      const choiceListMultipleFilter = new ChoiceListFilter(choiceListMultipleFilterElement, filterModel)
      console.log('ChoiceList Multiple initialized', choiceListMultipleFilter)
    })
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingRedeemer from '../../../../shared/modules/widgets/booking-redeemer/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const bookingRedeemerData = require('../../../../shared/modules/widgets/booking-redeemer/data/w-booking-redeemer__js-template')
const bookingRedeemerSelectedData = require('../../../../shared/modules/widgets/booking-redeemer/data/w-booking-redeemer__js-template-selected')
const bookingRedeemerDisabledData = require('../../../../shared/modules/widgets/booking-redeemer/data/w-booking-redeemer__js-template-disabled')
const bookingRedeemerWithoutBookingsData = require('../../../../shared/modules/widgets/booking-redeemer/data/w-booking-redeemer__js-template-without-bookings')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = async () => {
  const bookingRedeemerItem = document.querySelectorAll('[data-razor-url] [data-js-api--w-booking-item="w-booking-redeemer"]')

  bookingRedeemerItem.forEach(element => {
    const bookingRedeemer = new BookingRedeemer(element)
    console.log(bookingRedeemer)
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingRedeemerListJsTemplate = document.querySelectorAll('[data-booking-redeemer-demo]')
  handleFetched(bookingRedeemerListJsTemplate, bookingRedeemerData)

  const bookingRedeemerSelectedListJsTemplate = document.querySelectorAll('[data-booking-redeemer-selected-demo]')
  handleFetched(bookingRedeemerSelectedListJsTemplate, bookingRedeemerSelectedData)

  const bookingRedeemerDisabledListJsTemplate = document.querySelectorAll('[data-booking-redeemer-disabled-demo]')
  handleFetched(bookingRedeemerDisabledListJsTemplate, bookingRedeemerDisabledData)

  const bookingRedeemerListWithoutBookingsJsTemplate = document.querySelectorAll('[data-booking-redeemer-without-bookings-demo]')
  handleFetched(bookingRedeemerListWithoutBookingsJsTemplate, bookingRedeemerWithoutBookingsData)
}

import { defaultDropdownData, DropdownTemplate } from '../../components/dropdown/c-dropdown.template'
import { BookingFilterableServicesFilterTemplateRenderer } from './w-booking-filterable-services.template'

export const BookingFilterableServicesDropdownFilterTemplate = (filter, filters, service, configurations) => {
  return `
  <div class="w-booking-filterable-services__filter w-booking-filterable-services__filter-${filter.columnName}">
    ${DropdownTemplate({
      ...defaultDropdownData,
      label: filter.filterLabel,
      icon: filter.filterIcon,
      id: `${filter.uid}`,
      options: [...filter.values.map(value => { return { default: false, text: value, selected: filter.selectedValue === value, value } })],
      attributes: {
        'data-w-booking-filterable-services__filters-dropdown': '',
        'data-w-booking-filterable-services__service-id': service.uid,
        'data-w-booking-filterable-services__filter-type': filter.columnName
      }
    })}     
  </div>
  ${BookingFilterableServicesFilterTemplateRenderer(filters, service, configurations)}`
}

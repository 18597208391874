/**
 * The SideDrawerHeaderData contains all data needed to hydrate a Side Drawer Header selector view
 *
 * @typedef {Object}          SideDrawerHeaderData
 *
 * @property {String}         id                  - The item's id
 * @property {String}         headerTag           - HTML snippet
 * @property {String}         title               - HTML snippet
 * @property {String}         extraClasses
 * @property {String}         closable            - If true, it will be hidden at some breakpoint
 */

import { defaultSideDrawerData } from './c-side-drawer.template'

export const defaultSideDrawerHeaderData = {
  ...defaultSideDrawerData,
  headerTag: '',
  title: ''
}

export const SideDrawerHeaderTemplate = (d) => {
  d = { ...defaultSideDrawerHeaderData, ...d }
  return `
<div class="c-side-drawer__header o-block__header ${d.extraClasses}">
    ${d.title
      ? `<${d.headerTag || 'h2'}
          class="m-heading m-heading--small c-side-drawer__header-title">` + // {{get-token-class @root.ds-tokens-components "c-side-drawer.header__size" "default"}}
          `${d.title}
        </${d.headerTag || 'h2'}>`
      : ''}
  ${d.id && d.closable
    ? `<button type="button" 
             class="c-btn c-btn--icon-only c-side-drawer__close"
             data-c-side-drawer__action="close"
             data-c-side-drawer__id="${d.id}">
        <i class="c-btn__icon c-btn__icon--left m-icon--cross"></i>
        <span class="c-btn__text"></span>
      </button>`
  : ''}
</div>
`
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import JSONFetcher from '../../../../shared/js/helpers/json-fetcher'
import FilterModel from '../../../../shared/js/data/filters/filter-model'
import DropdownFilter from '../../../../shared/modules/widgets/dropdown-dynamic-filter/main'

loadQueueHelper.add(function () {
  initDropdownFilter('my-dropdown-dynamic-filter')
}, 2)

document.addEventListener('razor-load-complete', function () {
  initDropdownFilter('dropdown-filter-dynamic')
})

const initDropdownFilter = (elementId) => {
  const filterComponent = document.getElementById(elementId)
  if (!filterComponent) return

  const filtersRequest = new JSONFetcher({ src: '../assets/api/dropdown-dynamic-filter.json' })
  filtersRequest
    .fetch()
    .then(data => {
      const filterName = filterComponent.getAttribute('data-w-filters__name')
      const apiFilterData = data.values || []
      const filterData = {
        type: filterName,
        isMultiselectable: data.isMultiselectable,
        parentFilterType: 'Unknown',
        values: apiFilterData.map(filter => ({
          isSelected: filter.isSelected,
          isAvailable: filter.isAvailable,
          caption: filter.caption,
          value: filter.value,
          count: filter.count,
          groupInfo: ''
        }))
      }
      const filterModel = new FilterModel(filterData)

      console.log('DropdownSimple Data Model initialized', filterModel)

      const dropdownMultipleFilter = new DropdownFilter(filterComponent, filterModel)
      console.log('DropdownSimple initialized', dropdownMultipleFilter)
    })
}

// Import demos
require('../html/pages/components/autocomplete')
require('../html/pages/components/calendar')
require('../html/pages/components/calendar-field')
require('../html/pages/components/price-table')
require('../html/pages/components/floating-box')
require('../html/pages/components/date-selector')
require('../html/pages/components/phone-input')
require('../html/pages/components/modal-v2')
require('../html/pages/widgets/airport-filter')
require('../html/pages/widgets/cookie-consent')
require('../html/pages/widgets/cookie-emergency-dialog')
require('../html/pages/widgets/choice-list-filter')
require('../html/pages/widgets/dates-filter')
require('../html/pages/widgets/destination-filter')
require('../html/pages/widgets/dropdown-filter')
require('../html/pages/widgets/dropdown-multiple-filter')
require('../html/pages/widgets/dropdown-dynamic-filter')
require('../html/pages/widgets/flight-bus-selector')
require('../html/pages/widgets/quick-search')
require('../html/pages/widgets/search-map')
require('../html/pages/widgets/participants-filter')
require('../html/pages/widgets/price-filter')
require('../html/pages/widgets/tabs-filter')
require('../html/pages/widgets/booked-service-basket')
require('../html/pages/widgets/booked-services-list')
require('../html/pages/widgets/booking-accommodation-summary')
require('../html/pages/widgets/booking-basket')
require('../html/pages/widgets/booking-bundle')
require('../html/pages/widgets/booking-car-rental')
require('../html/pages/widgets/booking-comparison-table')
require('../html/pages/widgets/booking-terms-conditions')
require('../html/pages/widgets/booking-choice-list')
require('../html/pages/widgets/booking-error')
require('../html/pages/widgets/booking-filterable-services')
require('../html/pages/widgets/booking-price-overview')
require('../html/pages/widgets/booking-generic-summary')
require('../html/pages/widgets/booking-participant-summary')
require('../html/pages/widgets/booking-participants-badge')
require('../html/pages/widgets/booking-participants-form')
require('../html/pages/widgets/booking-preferences-selector')
require('../html/pages/widgets/booking-redeemer')
require('../html/pages/widgets/booking-steps-navigation')
require('../html/pages/widgets/booking-steps-tabs')
require('../html/pages/widgets/booking-transport-summary')
require('../html/pages/widgets/booking-usps')
require('../html/pages/widgets/booking-optional-ancillaries-popup')
require('../html/pages/widgets/booking-button-open-page')
require('../html/pages/widgets/payment')
require('../html/pages/widgets/booking-luggage')
require('../html/pages/widgets/promoted-price')
require('../html/pages/widgets/room-dropdown')
require('../html/pages/widgets/room-list')
require('../html/pages/widgets/booking-vouchers')
require('../html/pages/widgets/ce-modal')
require('../html/pages/widgets/ce-welcome-msg')

import { NavTemplate } from '../../shared/modules/components/nav/c-nav.template'
const menuData = require('../data/menu')

function currentUrlArrayItems () {
  return window.location.pathname.split('/').filter(e => e)
}

function brandResolver (brands) {
  let brand = null
  const switcher = document.getElementsByClassName('js-docs-brand-switcher')[0]
  const currentBrand = switcher.options[switcher.selectedIndex].value

  if (brands && brands.length > 0 && brands.includes(currentBrand)) {
    brand = currentBrand
  }
  return brand
}
function mapUrl (itemHref, brands) {
  const currentPathNameArray = currentUrlArrayItems()
  const brand = brandResolver(brands)
  let url = brand ? `/${brand}/${itemHref}` : `/${itemHref}`
  if (currentPathNameArray.length > 0) {
    if (currentPathNameArray[0] === '_site') {
      url = `/${currentPathNameArray[0]}/${currentPathNameArray[1]}/${itemHref}`
    } else if (currentPathNameArray[0] === 'DesignSystem') {
      url = `/${currentPathNameArray[0]}/${currentPathNameArray[1]}/${currentPathNameArray[2]}/${itemHref}`
    }
  }

  return url
}

function mapGeneralItem (item, brands) {
  return {
    itemDefinition: item.uri,
    link: {
      href: item.items && item.items[0].href ? mapUrl(item.items[0].href, brands) : null,
      text: item.text,
      target: item.target || '_self'
    }
  }
}

function mapItem (item, brands) {
  const urlArrayItems = currentUrlArrayItems()
  const currentItem = urlArrayItems && urlArrayItems.pop()
  const currentItemModule = urlArrayItems && urlArrayItems.at(-1)
  return {
    itemDefinition: item.uri,
    link: {
      href: item.href ? mapUrl(item.href, brands) : null,
      text: item.text,
      target: item.target || '_self'
    },
    isActive: item.uri === (currentItem && currentItem.split('.')[0]),
    isOpened: item.uri === currentItemModule,
    items: item.items ? item.items.map(subitem => mapItem(subitem, brands)) : null
  }
}

function loadMainMenu () {
  const menuElement = document.querySelectorAll('[data-main-menu-ds]')[0]
  const menuBrands = menuData.brands && menuData.brands.length > 0 ? menuData.brands : null
  const mappedItems = menuData.items.map(item => mapItem(item, menuBrands))

  menuElement.innerHTML = NavTemplate({
    ...{
      id: 'ds-main-menu',
      type: 'menu',
      variant: 'block',
      multilevel: true,
      currentLevel: mappedItems.some(item => item.isOpened) ? 2 : 1,
      backText: 'Back'
    },
    ...{ items: mappedItems }
  })
}

function loadMenu () {
  const menuElements = document.querySelectorAll('[data-menu-ds]')
  const menuBrands = menuData.brands && menuData.brands.length > 0 ? menuData.brands : null
  menuElements.forEach(el => {
    const pageUri = el.getAttribute('data-menu-ds')
    let mappedItems = []
    if (pageUri === 'index') {
      mappedItems = menuData.items && menuData.items.map(item => mapGeneralItem(item, menuBrands))
    } else {
      const filteredItems = menuData.items && menuData.items.find(item => item.uri === pageUri)
      mappedItems = filteredItems.items && filteredItems.items.map(item => mapItem(item, menuBrands))
    }

    el.innerHTML = NavTemplate({
      ...{
        id: `ds-menu-${pageUri}`,
        type: 'menu',
        variant: '',
        multilevel: false,
        currentLevel: 1

      },
      ...{ items: mappedItems }
    })
  })
}

loadMainMenu()
loadMenu()

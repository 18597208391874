import { CollapseTemplate } from '../../components/collapse/c-collapse.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'
import { ChoiceListTemplate, defaultChoiceListData } from '../../components/choice-list/c-choice-list.template'
import { CarItemTemplate } from '../../components/car-item/c-car-item.template'
import { PriceTemplate } from '../../components/price/c-price.template'
import { CarRentalCheckoutAdviceTemplate } from './w-booking-car-rental-checkout-advice.template'
import { TitleTemplate } from '../../components/title/c-title.template'

export const BookingCarRentalCarsListTemplate = (d, indexItem) => {
  const result = `
    <div class="w-booking-car-rental__container-list sp-stack-children--medium ${indexItem > 1 ? 'w-booking-car-rental__separator ' : ''}"
      data-w-booking-car-rental__container-list-id='${d.carListInformation.listId}'
      data-w-booking-car-rental__car-list-id='${indexItem}'>
      <div class="w-booking-car-rental__list sp-stack-children--medium ${d.showCarsInOneRow ? 'w-booking-car-rental__list--one-row' : ''}">
        ${d.collapseListInformation.title
          ? TitleTemplate({
                    tag: 'h4',
                    text: `${d.collapseListInformation.title} ${indexItem || ''}`,
                    size: 'small'
                  })
          : ''}
        ${
          CollapseTemplate({
            id: d.collapseListInformation.collapseId,
            maxItems: d.isCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow ? d.collapseListInformation.maxItems + 1 : d.collapseListInformation.maxItems,
            childrenWrapper: d.showCarsInOneRow ? '.w-booking-car-rental__cars-list' : '.c-choice-list__options',
            extraClasses: 'w-booking-car-rental__items',
            attributes: { 'data-w-booking-car-rental__collapse': '', 'data-w-booking-car-rental__collapse-id': d.collapseListInformation.collapseId },
            track: `${d.carRentalTrack}.ShowCars`,
            html: `${bookingCarRentalTemplate(d)}`
          })
        }
      </div>
      ${(d.choiceListInformation.items.length > d.collapseListInformation.maxItems && d.collapseListInformation.showCollapseTriggersSection && !d.showCarsInOneRow)
        ? `<div class="w-booking-car-rental__triggers">
          ${BtnTemplate({
            text: d.collapseButtonTexts.viewMoreButton,
            variant: 'default',
            icon: 'chevron-down',
            iconPosition: 'left',
            extraClasses: 'w-booking-car-rental__trigger',
            attributes: {
              'data-c-collapse__id': d.collapseListInformation.collapseId,
              'data-c-collapse__action': 'open'
            }
          })}
          ${BtnTemplate({
            text: d.collapseButtonTexts.viewLessButton,
            variant: 'default',
            icon: 'chevron-up',
            iconPosition: 'left',
            extraClasses: 'w-booking-car-rental__trigger',
            attributes: {
              'data-c-collapse__id': d.collapseListInformation.collapseId,
              'data-c-collapse__action': 'close'
            }
          })}
          </div>
      </div>`
    : ''}
  `
  return result
}

const bookingCarRentalTemplate = (d) => {
  return d.showCarsInOneRow
    ? ShowCarsInOneRowTemplate(d)
    : bookingCarRentalCarsTemplate(d)
}

const bookingCarRentalCarsTemplate = (d) => {
  const newChoiceListData = {
    ...d.choiceListInformation,
    items: d.choiceListInformation.items.map(option => ({ ...option, ...{ extraRichContent: extraRichContentForCarTemplate(option, d) } }))
  }
  return `${ChoiceListTemplate({ ...defaultChoiceListData, ...newChoiceListData })}`
}

const extraRichContentForCarTemplate = (option, d) => {
  if (!option) return ''
  return `
    <div class="w-booking-car-rental__car-item ${(d.showCarsInOneRow && !d.showSelectedCarsWithoutSlider) ? 'w-booking-car-rental__car-item--vertical-card' : ''}">
      <div class="w-booking-car-rental__car-information">
        ${CarItemTemplate({
          name: option.car.name,
          image: option.car.image,
          type: option.car.type,
          labelText: option.car.label,
          labelExtraClasses: 'w-booking-car-rental__item--label-on-request',
          features: option.car.features,
          extraClasses: `w-booking-car-rental__car-information-item ${(d.showCarsInOneRow && !d.showSelectedCarsWithoutSlider) ? 'w-booking-car-rental__car-information-item--vertical-card' : ''}`,
          extraDetailsContent: OptionalEquipmentsTemplate(option, d.optionalEquipmentText)
        })}
      </div>
      ${PriceTemplate({
        ...option.car.price,
        ...{
          size: 'medium',
          oldPrice: '',
          salePrice: '',
          extraClasses: 'w-booking-car-rental__item-price'
        }
      })}
    </div>

    ${option.checked
      ? `<div class="w-booking-car-rental__selected-info-section sp-stack-children--tiny" data-w-booking-car-rental__selected-info-section>
          <div class="w-booking-car-rental__selected-info-details">
            ${CarRentalDriverTemplate(option, d.driverLabel)}
            ${SelectedEquipmentsTemplate(option, d.selectedEquipmentText)}
          </div>
          ${option.isCarEditable ? CarRentalCheckoutAdviceTemplate(d.checkoutAdvice) : ''}
          <div class="w-booking-car-rental__selected-button-actions">
            ${
              (!option.isIncluded && option.isCancellable)
              ? BtnTemplate({
                text: d.collapseButtonTexts.removeCarButton || '',
                variant: 'flat-neutral',
                icon: 'bin',
                jsApi: true,
                track: `${d.carRentalTrack}.Remove`,
                extraClasses: 'w-booking-car-rental__selected-button',
                attributes: { 'data-w-booking-car-rental__selected-remove': '', 'data-w-booking-car-rental__car-code': option.value }
              })
              : ''
            }
            ${
              option.isCarEditable
              ? BtnTemplate({
                text: d.collapseButtonTexts.editCarButton || '',
                variant: 'flat-neutral',
                icon: 'pencil',
                jsApi: true,
                track: `${d.carRentalTrack}.Edit`,
                extraClasses: 'w-booking-car-rental__selected-button',
                attributes: { 'data-w-booking-car-rental__selected-edit': '', 'data-w-booking-car-rental__car-code': option.value }
              })
              : ''
           }
           ${
            option.canBeUpgraded && !d.isCarIncludedAndIsAlwaysShowingUpgradingCarsInOneRow
            ? BtnTemplate({
              text: d.collapseButtonTexts.upgradeCarButton || '',
              variant: 'flat-neutral',
              icon: 'upgrade',
              jsApi: true,
              extraClasses: 'w-booking-car-rental__selected-button',
              track: `${d.carRentalTrack}.Upgrade`,
              attributes: {
                'data-w-booking-car-rental__selected-upgrade': '',
                'data-w-booking-car-rental__car-code': option.value,
                'data-w-booking-car-rental__car-driver': (option.driver && option.driver.participantId) || '',
                'data-c-collapse__id': d.collapseListInformation.collapseId,
                'data-c-collapse__action': 'open'
              }
            })
            : ''
         }
          </div>
        </div>`
      : ''
    }

  `
}

const CarRentalDriverTemplate = (item, label) => {
  return `<div class="w-booking-car-rental__driver-info">
    <strong>${label}:</strong>
    ${item.driver.name}
    ${item.driver.formattedBirthdate
      ? `(${item.driver.formattedBirthdate})`
      : ''}
  </div>`
}

const OptionalEquipmentsTemplate = (item, optionalEquipmentText = '') => {
  return item.car.hasEquipments && item.car.equipments.length > 0 && !item.selectedEquipments
    ? `<div class="w-booking-car-rental__optional-equipments m-body m-body--small">
        ${optionalEquipmentText ? `${optionalEquipmentText}:` : ''}
        ${item.car.equipments.map(equipment => equipment.text).join(', ')}
      </div>`
    : ''
}

const SelectedEquipmentsTemplate = (item, selectedEquipmentText = '') => {
  return item.checked && item.selectedEquipments && item.selectedEquipments.length > 0
    ? `<div class="w-booking-car-rental__optional-equipments">
      ${selectedEquipmentText ? `<strong>${selectedEquipmentText}:</strong>` : ''} ${item.selectedEquipments.map(equipment => equipment.text).join(', ')}</div>`
    : ''
}

const ShowCarsInOneRowTemplate = (d) => {
  return `
    <div class="w-booking-car-rental__cars-list sp-stack-children--medium">
      ${d.choiceListSelectedCarInformation.items && d.choiceListSelectedCarInformation.items.length > 0 ? bookingCarRentalCarsTemplate({ ...d, showSelectedCarsWithoutSlider: true, choiceListInformation: d.choiceListSelectedCarInformation }) : ''}
      ${bookingCarRentalCarsTemplate(d)}
    </div>
    <div class="" data-w-booking-car-rental--edit-template></div>
    `
}


import { widgetApi, componentsApis } from '../config'
import webStorage from '../../../../js/document/web-storage'

const widgetQueries = {
  creditCardChoiceList: `[data-${widgetApi}__item-field-credit-card]`,
  identifierSearchName: 'sid'
}

const defaultTexts = {
  checkboxLabel: 'The main driver is in possession of a credit card',
  checkboxRequiredMessage: 'The main driver need to be in possession of a credit card'
}

export default class CreditCard {
  constructor (element) {
    this.element = element
    this.checkboxRequired = false
    this.texts = defaultTexts

    this.showAndValidateCreditCardCheckbox = this._showAndValidateCreditCardCheckbox.bind(this)
    this.setCreditCardCheckboxTexts = this._setCreditCardCheckboxTexts.bind(this)
  }

  _showAndValidateCreditCardCheckbox () {
    this.checkboxRequired = true
    this.showCreditCardCheckBox()
  }

  _setCreditCardCheckboxTexts (label = null, requiredMessage = null) {
    if (!label || !requiredMessage) { return }
    this.texts = { ...this.texts, checkboxLabel: label, checkboxRequiredMessage: requiredMessage }
  }

  attachCreditCardEvents (api) {
    api.events.on('changeOptions', () => {
      api.enableErrors()
      api.validate()
    })
  }

  creditCardCheckboxValidation (api) {
    let validation = true
    if (api) {
      api.enableErrors()
      validation = api.validate().isValid

      if (!validation) {
        api.enableErrors()
        api.validate()
      }
    }

    return validation
  }

  toggleCreditCardCheckbox (api, creditCardChecked, driverId) {
    if (!this.checkboxRequired) { return }
    if (api) {
      const sessionStorageInfo = this._getCreditCardCheckboxInfoFromSessionStorage(driverId)
      const newOptions = api.getProp('options').map((option) => {
        const isCreditCardChecked = (sessionStorageInfo && sessionStorageInfo.includes(option.value)) || creditCardChecked
        return { ...option, checked: isCreditCardChecked }
      })
      api.setProp('options', newOptions)
    }
  }

  showCreditCardCheckBox () {
    const creditCardChoiceListElements = this.element.querySelectorAll(widgetQueries.creditCardChoiceList)
    creditCardChoiceListElements.forEach(element => element.classList.remove('is-hidden'))
  }

  getFormControls (elForm) {
    const creditCardElement = elForm.querySelector(widgetQueries.creditCardChoiceList)
    const creditCardApi = creditCardElement ? creditCardElement[componentsApis.choiceListApi] : undefined

    return {
      apiCreditCard: creditCardApi
    }
  }

  _getIdentifier () {
    let identifier = 'car-rental-credit-card'
    const urlParams = new window.URLSearchParams(window.location.search)
    if (urlParams) {
      const draftId = urlParams.get(widgetQueries.identifierSearchName)
      if (draftId) {
        identifier = `${identifier}-${draftId}`
      }
    }
    return identifier
  }

  setCreditCardSessionStorage (creditCardSelectedValues, driverId) {
    const identifier = this._getIdentifier()
    const currentInformationSaved = webStorage.session.get(identifier)
    const info = { ...currentInformationSaved, ...{ [driverId]: creditCardSelectedValues } }

    webStorage.session.set(identifier, info)
  }

  _getCreditCardCheckboxInfoFromSessionStorage (driverId) {
    let creditCardDriverInfo = []
    const identifier = this._getIdentifier()
    const sessionStorageInfo = webStorage.session.get(identifier)
    creditCardDriverInfo = sessionStorageInfo ? sessionStorageInfo[driverId] : []

    return creditCardDriverInfo
  }
}

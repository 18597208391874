export default class BookingStepsNavigationDataMapper {
  /**
   * Maps API response data to widget data
   *
   * @typedef {Object}         BookingStepsNavigationData
   *
   * @property {Object}        [apiResponseData]    - Api response data
   */
  mapButton (apiResponseData) {
    const widgetData = {
      button: apiResponseData ? this._mapButtonInfo(apiResponseData) : {}
    }
    return widgetData
  }

  _mapButtonInfo (data) {
    if (!data.nextButtonUrl || !data.nextButtonText) { return }

    const button = {
      variant: 'flow',
      icon: 'chevron-right',
      iconPosition: 'right',
      text: data.nextButtonText,
      url: data.nextButtonUrl,
      attributes: { 'data-w-booking-steps-navigation__button': '' }
    }
    return button
  }
}

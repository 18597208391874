import { BoxTemplate } from '../../components/box/c-box.template'
import { TitleTemplate } from '../../components/title/c-title.template'

/**
 * The PaymentAlertTemplate contains all the data needed to hydrate a PaymentAlert component
 *
 * @typedef {Object}      PaymentAlertTemplate
 *
 * @property {String}     box            - contains how the box should be
 * @property {String}     extraClasses   - Extra classes if needed
 * @property {String}     content        - contains the content of the box
 */

const defaultPaymentAlertData = {
  box: {
    variant: 'flat',
    extraClasses: 'w-payment__message'
  },
  content: {
    title: '',
    description: ''
  }
}

export const PaymentAlertTemplate = (d) => {
  const boxExtraClasses = `${defaultPaymentAlertData.box.extraClasses} ${d.extraClasses}`
  const html = `
    ${d.successIconUrl
      ? `<div class="w-payment__message-image-alert">
        ${d.backgroundConfetti ? `<div class="w-payment__message-image-alert-background">${d.backgroundConfetti}</div>` : ''}
        <div class="w-payment__message-image">
          <img src="${d.successIconUrl}" alt="icon">
        </div> 
      </div>`
      : ''
    }
    <div class="w-payment__message-content">
      ${TitleTemplate({
        ...d.content && d.content.title && { text: d.content.title },
        ...{ extraClasses: 'w-payment__message-title' }
      })}
      <div class="w-payment__message-description">
        ${d.content.description}
      </div>
    </div>
  `
  return `
    ${BoxTemplate({
      ...defaultPaymentAlertData.box,
      ...{ html, extraClasses: boxExtraClasses }
    })}
  `
}

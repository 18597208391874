/**
 * rectanglesIntersects
 * Returns true if rectangle A and rectangle B intersects at some point
 * (algorithm extracted from https://www.hackerearth.com/practice/notes/how-to-check-if-two-rectangles-intersect-or-not/ )
 *
 * @param {Object} rectA
 * @param {Object} rectB                                       x
 * @param {int}    [rectA.x]  - Pos x of rectangle           y +-------+   ^
 * @param {int}    [rectA.y]  - Pos y of rectangle             |       |   |
 * @param {int}    [rectA.width]  - width of the rectanble     |       | height
 * @param {int}    [rectA.height]  - height of rectable        +-------+   v
 *                                                             < width >
 * @returns {Boolean}
 */
export function rectanglesIntersects (rectA, rectB) {
  // P1 (x,y)
  //  +-------+
  //  |       |
  //  |       |
  //  +-------+ P2 (x,y)
  const A = {
    p1: {
      x: rectA.x,
      y: rectA.y
    },
    p2: {
      x: rectA.x + rectA.width,
      y: rectA.y + rectA.height
    }
  }
  const B = {
    p1: {
      x: rectB.x,
      y: rectB.y
    },
    p2: {
      x: rectB.x + rectB.width,
      y: rectB.y + rectB.height
    }
  }
  const intersects = (A.p1.x < B.p2.x) &&
                        (A.p2.x > B.p1.x) &&
                        (A.p1.y < B.p2.y) &&
                        (A.p2.y > B.p1.y)
  return intersects
}

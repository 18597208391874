import { ChoiceListTemplate } from '../../components/choice-list/c-choice-list.template'
import { BookingMessageTemplate } from '../booking-message/w-booking-message.template'
import { TitleTemplate, defaultTitleData } from '../../components/title/c-title.template'
import { BookingConditionsTemplate } from './w-booking-conditions.template'
import { BookingAppliesToAllParticipantsTemplate } from './w-booking-applies-to-all-participants.template'

/**
 * The BookingChoiceListData contains all data needed to hydrate a BookingChoiceList view
 *
 * @typedef {Object}                  BookingChoiceListData
 *
 * @property {RoomData[]}             rooms                               - The list of rooms to be shown
 * @property {String}                 RoomData.roomName                   - The name of the room
 * @property {ParticipantsData[]}     RoomData.participants               - Contains the list of participants in the room
 * @property {ChoiceListData}         ParticipantsData.choiceListInfo     - Contains all the options to be shown within the choice list component
 *
 */

export const defaultBookingChoiceListData = {
  title: {
    size: 'tiny',
    tag: 'h2'
  },
  choiceList: {
    variant: 'boxed'
  },
  attributes: {
    'data-w-booking-choice-list__choice-list': ''
  },
  showMessagesOnTop: false,
  showAppliesToAllParticipants: false
}

export const BookingChoiceListTemplate = (d) => {
  d = { ...defaultBookingChoiceListData, ...d }

  return ` <div class="w-booking-choice-list sp-stack-children--medium">
    ${d.messages && d.showMessagesOnTop
      ? `<div class="w-booking-choice-list__messages-top" data-w-booking-choice-list__messages >
          ${d.messages ? BookingMessageTemplate(d) : ''}
        </div>`
      : ''}

      ${d.choosePerPerson
        ? `<div data-w-booking-choice-list__applies-to-all-participants-container >
            ${d.choosePerPerson ? BookingAppliesToAllParticipantsTemplate(d) : ''}
          </div>`
        : ''}
    ${d.rooms.map(room => `
      ${room.participants && room.participants.length > 0
      ? `
        ${room.roomName
          ? `<div class="w-booking-choice-list__heading-room-title ${d.appliesToAllParticipantsSelected && d.appliesToAllParticipants ? 'is-hidden' : ''}" data-w-booking-choice-list__heading-room-title>
              ${TitleTemplate(Object.assign({}, defaultTitleData, { size: d.title.size, tag: d.title.tag, text: room.roomName }))}
            </div>`
          : ''}
        ${room.participants.map(participant => `
          <div class="w-booking-choice-list__item ${d.appliesToAllParticipantsSelected && d.appliesToAllParticipants ? 'is-hidden' : ''}" data-w-booking-choice-list__item>
          ${ChoiceListTemplate(Object.assign({}, defaultBookingChoiceListData, participant.choiceListInfo, { required: participant.choiceListInfo.required }, { requiredMessage: participant.choiceListInfo.requiredMessage }, { variant: d.choiceList.variant }, { track: d.track }, { attributes: { ...defaultBookingChoiceListData.attributes, ...participant.choiceListInfo.attributes } }))}
          </div>
        `).join('')}
      `
      : ''}
    `).join('')}
      ${d.conditions
        ? `<div data-w-booking-choice-list__conditions-container >
            ${d.conditions ? BookingConditionsTemplate(d) : ''}
          </div>`
        : ''}
      ${d.messages && !d.showMessagesOnTop
        ? `<div data-w-booking-choice-list__messages >
            ${d.messages ? BookingMessageTemplate(d) : ''}
          </div>`
        : ''}
    </div>
  `
}

import { ChoiceListTemplate, defaultChoiceListData } from '../../components/choice-list/c-choice-list.template'

export const BookingCarRentalConditionsTemplate = (d) => {
  const result = `${d.conditions
    ? ChoiceListTemplate(Object.assign({},
                        defaultChoiceListData,
                        d.conditions.choiceListInfo))
    : ''}
  `
  return result
}

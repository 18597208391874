
/**
 * This data mapper helper convert a GetBookingDraft response into an
 * object that can be read by widget w-booked-services-list
 */

export default class BookedServicesListDataMapper {
  mapServiceBasketInfo (response) {
    const widgetResponse = {
      orderedServices: this._mapOrderedServices(response.services)
    }
    return widgetResponse
  }

  _mapOrderedServices (services) {
    if (services) {
      const selectedServices = services.filter(service => service.options.some(option => option.isSelected))
      const nonSelectedServices = services.filter(service => !selectedServices.includes(service))
      const orderedServices = [
        ...nonSelectedServices.map(service => ({ componentId: service.componentId, serviceSelected: false })),
        ...selectedServices.map(service => ({ componentId: service.componentId, serviceSelected: true }))
      ]
      return orderedServices
    }
  }
}

import { TitleTemplate } from '../../components/title/c-title.template'
import { BtnTemplate } from '../../components/btn/c-btn.template'

/**
 * The FaqTopQuestionsContentData contains all data needed to hydrate inner content for FaqTopQuestions widget
 *
 * @typedef {Object}                      ActionModalResponseContentData

 * @property {string}   templateData.modalId                - The id of the modal.
 * @property {Boolean}  templateData.isSuccess              - Indicates if the response is success (true) or not (false)
 * @property {string}   templateData.title                  - The title of the modal.
 * @property {string}   templateData.text                   - The body of the response.
 * @property {string}   templateData.buttonText             - The text of the button that closes the modal.
 */

export const defaultActionModalResponseContentData = {
  modalId: 'booking-1234-flight-changed',
  isSuccess: false,
  title: '',
  text: '',
  buttonText: ''
}

export const ActionModalResponseContentTemplate = (d) => {
  d = { ...defaultActionModalResponseContentData, ...d }
  const statusClass = d.isSuccess ? 'w-action-modal--succeeded' : 'w-action-modal--failed'
  const icon = d.isSuccess ? 'checkmark-circle' : 'warning-circle'

  return `<div class='w-action-modal--content-notification sp-stack-children--medium ${statusClass}'>
    ${icon ? `<div class="w-action-modal__icon m-icon--size-huge m-icon--${icon}"></div>` : ''}
    ${d.title
      ? `${TitleTemplate({
        text: d.title,
        size: 'medium',
        variant: '',
        extraClasses: 'w-action-modal__title u-mb--small'
      })}`
      : ''}

    ${d.text || d.buttonText
      ? `
          ${d.text
            ? `<p>${d.text}</p>`
            : ''}
          ${d.buttonText
            ? `${BtnTemplate({
              text: d.buttonText,
              variant: 'flow',
              link: {
                href: d.url
              },
              attributes: {
                'data-c-modal__action': 'close',
                'data-c-modal__id': d.modalId
              },
              extraClasses: 'u-mb--medium'
            })}`
            : ''}
        `
      : ''}
    </div>`
}

import { ImgTemplate } from '../../components/img/c-img.template'

/**
 * The BoxData contains all data needed to hydrate a Box view
 *
 * @typedef {Object}          BoxData
 *
 * @property {String}         [variant]           - Box's variant
 * @property {String}         [extraClasses]      - Extra classes for the button
 * @property {Object}         [attributes]        - Attributes for the button
 *
 */
export const defaultBoxData = {
  image: {},
  titleAttributes: {},
  titleText: '',
  infoCtaText: '',
  titleUrl: '',
  breadcrumbs: {},
  grade: '',
  rating: '',
  price: '',
  priceLegend: '',
  hidePrice: true,
  extraClasses: ''
}

export const ProductCardTemplate = (d) => {
  d = { ...defaultBoxData, ...d }

  return `
    <div class="c-card c-product-card o-block o-block--stretch ${d.extraClasses}">
      <div class="c-card__header">
        ${ImgTemplate(d.image)}
      </div>
      <div class="c-card__body o-block__body sp-stack-children--tiny">
          ${d.grade
        ? `<div class="c-number-score c-number-score--bubbled c-number-score--arrow-left c-number-score--label-left c-product-card__score">
          <div class="c-number-score__body">
            <span class="c-number-score__grade">${d.grade}</span>
          </div>
        </div>`
      : ''}
        <span data-rating="${d.rating}" class="c-rating c-rating--xs c-product-card__rating"></span>
        <div class="c-product-card__body-heading">
          <h3 class="c-card__title m-heading">
            <a class="c-card__title-link" href="${d.titleUrl}" ${d.titleAttributes}>${d.titleText}</a>
          </h3>
        </div>
        <div class="c-product-card__breadcrumbs">
          <ol class="c-breadcrumbs c-breadcrumbs--unclickable">
            ${d.breadcrumbs.map((location, index) => {
        return `
                <li class="c-breadcrumbs__item" data-divider=",">
                  <a href="${location.url}" class="c-breadcrumbs__link">
                    <span class="c-breadcrumbs__text">${location.text}</span>
                  </a>
                  <meta itemprop="position" content="${index}">
                </li>
              `
      }).join('')}
          </ol>
        </div>
        <div class="c-product-card__body-cta">
          ${d.price && !d.hidePrice
      ? `<div class="c-product-card__price">
                ${d.priceLegend} ${d.price}
              </div>`
      : ''}
          <a href="${d.titleUrl}" class="c-btn c-btn--flow c-product-card__btn--desktop">${d.infoCtaText}</a>
          <a href="${d.titleUrl}" class="c-btn c-btn--flat c-product-card__btn--mobile">${d.infoCtaText}</a>
        </div>
      </div>
    </div>
    `
}

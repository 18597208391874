import { isEmptyObject } from '../../../js/helpers/object'

export default class BookingOptionalAncillariesPopupDataMapper {
  mapServices (optionalServicesNotSelected, configurations) {
    const widgetService = (optionalServicesNotSelected.length > 0 && !isEmptyObject(configurations))
      ? {
          optionalServices: this._mapServicesNotSelected(optionalServicesNotSelected, configurations.optionalServicesConfigurations)
        }
      : null

    return widgetService
  }

  _mapServicesNotSelected (optionalServicesNotSelected, optionalServicesConfigurations) {
    const servicesMapped = []

    optionalServicesNotSelected.forEach(componentItemId => {
      const optionalServiceNotSelected = optionalServicesConfigurations.find(serviceConfig => serviceConfig.componentId === componentItemId)
      if (optionalServiceNotSelected) {
        servicesMapped.push(this._mapServiceNotSelected(optionalServiceNotSelected))
      }
    })

    return servicesMapped
  }

  _mapServiceNotSelected (service) {
    if (!service) { return }

    return {
      icon: service.icon,
      text: service.text,
      componentId: service.componentId
    }
  }
}

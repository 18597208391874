/**
 * The NavItemData contains all data needed to hydrate a Nav view
 *
 * @typedef {Object}          NavItemData
 *
 * @property {Boolean}        isBack
 * @property {Boolean}        isMore
 * @property {Boolean}        isActive
 * @property {Boolean}        isCurrent
 * @property {Boolean}        isFavoriteCollector
 * @property {Boolean}        isOpened
 * @property {String}         until
 * @property {String}         shownOnMoreUntil
 * @property {String}         shownOnMoreFrom
 * @property {String}         itemDefinition
 * @property {String}         itemType
 * @property {String}         parentId
 * @property {String}         id
 * @property {Array}          items
 * @property {Object}         [link]              - Link info for button in case it's an anchor link
 * @property {String}         [link.href]         - Link's url
 * @property {String}         [link.target]       - Open in same page, new page...
 * @property {String}         [link.icon]
 * @property {String}         [link.text]
 * @property {Object}         [form]              - Form info for button in case it's a form action
 * @property {String}         [form.method]       - Form's method
 * @property {String}         [form.action]       - Form's action
 * @property {Array}          [form.inputs]       - Form's inputs
 */

import { NavLinkFormActionTemplate } from './c-nav__link--form-action.template'
import { NavLinkTemplate } from './c-nav__link.template'
import { CollapseTemplate } from '../collapse/c-collapse.template'
import { NavListTemplate } from './c-nav__list.template'

export const defaultNavItemData = {
  isBack: false,
  isMore: false,
  isActive: false,
  isCurrent: false,
  isFavoriteCollector: false,
  isOpened: false,
  until: '',
  shownOnMoreUntil: '',
  shownOnMoreFrom: '',
  itemDefinition: '',
  itemType: '',
  parentId: '',
  id: '',
  items: [],
  link: {},
  form: {},
  variant: 'default',
  more: false
}

const retrieveExtraClassesForItem = (d) => {
  const itemExtraClassesBack = d.isBack ? 'c-nav__item--back' : ''
  const itemExtraClassesMore = (d.until && d.isMore) ? 'c-nav__item--more u-hidden--from@' + d.until : ''
  const itemExtraClassesShowOnMoreUntil = (d.shownOnMoreUntil && d.more) ? 'u-hidden--until@' + d.shownOnMoreUntil : ''
  const itemExtraClassesShowOnMoreFrom = (d.shownOnMoreFrom && d.more) ? ' u-hidden--from@' + d.shownOnMoreFrom : ''
  const itemExtraClassesActive = d.isActive ? 'is-active' : ''
  const itemExtraClassesOpened = d.isOpened ? 'is-opened' : ''
  const itemExtraClassesCurrent = d.isCurrent ? 'is-current' : ''
  const itemExtraClassesFavoriteCollector = d.isFavoriteCollector ? 'c-nav__item--favorite-collector' : ''
  const itemExtraClassesChild = (d.items && d.items.length > 0 && d.level < 3) ? 'has-child' : ''

  const itemExtraClasses = [...new Set([
    ...['c-nav__item'],
    ...[itemExtraClassesBack],
    ...[itemExtraClassesMore],
    ...[itemExtraClassesShowOnMoreUntil],
    ...[itemExtraClassesShowOnMoreFrom],
    ...[itemExtraClassesActive],
    ...[itemExtraClassesOpened],
    ...[itemExtraClassesCurrent],
    ...[itemExtraClassesFavoriteCollector],
    ...[itemExtraClassesChild]
  ])].filter(e => e).join(' ')

  return itemExtraClasses
}

export const NavItemTemplate = (d) => {
  d = { ...defaultNavItemData, ...d }

  return ` 
    <li class="${retrieveExtraClassesForItem(d)}"
      data-c-nav-item__definition="${d.itemDefinition}">
      ${d.itemType === 'form-action'
        ? `${NavLinkFormActionTemplate(Object.assign({}, {
            method: d.form.method,
            action: d.form.action,
            inputs: d.form.inputs,
            icon: d.link.icon,
            text: d.link.text
          }))}`
        : `${NavLinkTemplate(Object.assign({}, {
              extraClasses: d.isFavoriteCollector ? 'c-favorite-collector' : '',
              isCollapsible: d.variant === 'collapsible' && d.level < 3,
              collapsibleId: `${d.id}-collapse`,
              link: d.link
              }))
            }`
      }

      ${d.items && d.items.length > 0
        ? (d.variant === 'collapsible' && d.level < 3)
            ? `${CollapseTemplate({
              id: d.id + '-collapse',
              opened: d.isOpened,
              extraClasses: 'c-nav__children-collapse',
              groupId: d.parentId + '-collapse-group',
              html: `
                ${NavListTemplate(Object.assign({}, { ...d, items: d.items, level: d.level + 1, id: d.id }))}
              `
            })}`
            : `${NavListTemplate(Object.assign({}, { ...d, items: d.items, level: d.level + 1, id: d.id }))}`
        : ''}
    </li>
  `
}

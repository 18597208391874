/**
 * Enumeration for sorting to apply when listing the rooms.
 */
const SortRoomsBy = {
  /**
     * No sorting applied.
     */
  Unsorted: '',
  /**
     * Sort by price.
     */
  Price: 'price',
  /**
     * Sort by room identifier.
     */
  RoomId: 'room',
  /**
     * Sort by name.
     */
  Name: 'name',

  /**
     * Sort by price, then by name.
     */
  PriceAndName: 'price-and-name',

  /**
     * Checks if a value is a member of the enumeration.
     */
  isValid: (enumValue) => {
    return Object.values(SortRoomsBy).includes(enumValue)
  }
}

export default SortRoomsBy

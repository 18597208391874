import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingComparisonTable from '../../../../shared/modules/widgets/booking-comparison-table/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const bookingComparisonTableData = require('../../../../shared/modules/widgets/booking-comparison-table/data/w-booking-comparison-table__js-template.json')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = () => {
  const bookingComparisonTableItems = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-comparison-table"]')
  bookingComparisonTableItems.forEach(item => {
    const bookingComparisonTable = new BookingComparisonTable(item)
    console.log(bookingComparisonTable)
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingComparisonTableJsTemplate = document.querySelectorAll('[data-booking-comparison-table-demo]')
  handleFetched(bookingComparisonTableJsTemplate, bookingComparisonTableData)
}

import * as loadQueueHelper from '../../../../shared/js/document/load-queue'
import BookingLuggage from '../../../../shared/modules/widgets/booking-luggage/main'
import { handleFetched } from '../../../js/bookingHandleFetched'

const bookingLuggageData = require('../../../../shared/modules/widgets/booking-luggage/data/w-bookings-luggage__js-template.json')
const bookingLuggageDataOneClick = require('../../../../shared/modules/widgets/booking-luggage/data/w-bookings-luggage__js-template_one-click.json')
const bookingLuggageDataWithPriceConfig = require('../../../../shared/modules/widgets/booking-luggage/data/w-bookings-luggage__js-template-with-price-config.json')
const bookingLuggageDataOnlyNoOption = require('../../../../shared/modules/widgets/booking-luggage/data/w-bookings-luggage__js-template_only-no-option.json')
const bookingLuggageDataWithoutOptions = require('../../../../shared/modules/widgets/booking-luggage/data/w-bookings-luggage__js-template_without-options.json')
const bookingLuggageDataWithoutOutboundOptions = require('../../../../shared/modules/widgets/booking-luggage/data/w-bookings-luggage__js-template_without-outbound-options.json')
const bookingLuggageDataWithoutInboundOptions = require('../../../../shared/modules/widgets/booking-luggage/data/w-bookings-luggage__js-template_without-inbound-options.json')
const bookingLuggageDataWithoutSelfserviceSpecialLuggageCheckboxOptions = require('../../../../shared/modules/widgets/booking-luggage/data/w-bookings-luggage__js-template_without-selfservice-special-luggage-checkbox.json')
const bookingLuggageDataOneWayFlight = require('../../../../shared/modules/widgets/booking-luggage/data/w-bookings-luggage__js-template_one-way-flight.json')
const bookingLuggageDataWithouRooms = require('../../../../shared/modules/widgets/booking-luggage/data/w-bookings-luggage__js-template_without-rooms.json')

loadQueueHelper.add(function () {
  initBookingService()
}, 2)

const initBookingService = async () => {
  const bookingLuggageItems = document.querySelectorAll('[data-js-api--w-booking-item="w-booking-luggage"]')

  bookingLuggageItems.forEach(element => {
    const bookingLuggage = new BookingLuggage(element)
    return bookingLuggage
  })

  initDocumentation()

  document.addEventListener('razor-load-complete', function () {
    initDocumentation()
  })
}

const initDocumentation = () => {
  const bookingLuggageJsTemplate = document.querySelectorAll('[data-booking-luggage-demo]')
  handleFetched(bookingLuggageJsTemplate, bookingLuggageData)

  const bookingLuggageOneClickJsTemplate = document.querySelectorAll('[data-booking-luggage-demo-one-click]')
  handleFetched(bookingLuggageOneClickJsTemplate, bookingLuggageDataOneClick)

  const bookingLuggageWithPriceConfigJsTemplate = document.querySelectorAll('[data-booking-luggage-demo-with-price-config]')
  handleFetched(bookingLuggageWithPriceConfigJsTemplate, bookingLuggageDataWithPriceConfig)

  const bookingLuggageJsTemplateOnlyNoOption = document.querySelectorAll('[data-booking-luggage-demo-only-no-option]')
  handleFetched(bookingLuggageJsTemplateOnlyNoOption, bookingLuggageDataOnlyNoOption)

  const bookingLuggageJsTemplateWithoutOptions = document.querySelectorAll('[data-booking-luggage-demo-without-options]')
  handleFetched(bookingLuggageJsTemplateWithoutOptions, bookingLuggageDataWithoutOptions)

  const bookingLuggageJsTemplateWithoutOutboundOptions = document.querySelectorAll('[data-booking-luggage-demo-without-outbound-options]')
  handleFetched(bookingLuggageJsTemplateWithoutOutboundOptions, bookingLuggageDataWithoutOutboundOptions)

  const bookingLuggageJsTemplateWithoutInboundOptions = document.querySelectorAll('[data-booking-luggage-demo-without-inbound-options]')
  handleFetched(bookingLuggageJsTemplateWithoutInboundOptions, bookingLuggageDataWithoutInboundOptions)

  const bookingLuggageJSDataWithoutSelfserviceSpecialLuggageCheckboxOptions = document.querySelectorAll('[data-booking-luggage-demo-without-selfservice-special-luggage-checkbox]')
  handleFetched(bookingLuggageJSDataWithoutSelfserviceSpecialLuggageCheckboxOptions, bookingLuggageDataWithoutSelfserviceSpecialLuggageCheckboxOptions)

  const bookingLuggageJsTemplateOneWayFlight = document.querySelectorAll('[data-booking-luggage-demo-one-way-flight]')
  handleFetched(bookingLuggageJsTemplateOneWayFlight, bookingLuggageDataOneWayFlight)

  const bookingLuggageJsTemplateWithoutRooms = document.querySelectorAll('[data-booking-luggage-demo-without-rooms]')
  handleFetched(bookingLuggageJsTemplateWithoutRooms, bookingLuggageDataWithouRooms)
}

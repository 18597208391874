import {
  ChoiceListTemplate,
  defaultChoiceListData
} from '../../components/choice-list/c-choice-list.template'

/**
 * The defaultBookingTermsAndConditionsData contains all data needed to hydrate a BookingPreferencesSelector view
 *
 * @typedef {Object}                  BookingPreferencesSelectorData
 *
 * @property {ChoiceListData}         ConditionsData.choiceListInfo      - Contains all the options to be shown within the choice list component
 *
 */

export const BookingTermsAndConditionsTemplate = d => {
  return `
    <div class="w-booking-terms-conditions__conditions-container">
      ${d.termsandconditions && d.termsandconditions.choiceListInfo ? ConditionsListTemplate(d.termsandconditions) : ''}
    </div>`
}

const ConditionsListTemplate = termsandconditions => {
  return `${ChoiceListTemplate(Object.assign({},
    defaultChoiceListData,
    termsandconditions.choiceListInfo))}`
}

/**
 * The NavLinkFormActionData contains all data needed to hydrate a NavLinkForm view
 *
 * @typedef {Object}          NavLinkFormActionData
 *
 * @property {String}         method
 * @property {String}         action
 * @property {String}         icon
 * @property {String}         text
 * @property {Array}          inputs
 */

export const defaultNavLinkFormActionData = {
  method: '',
  action: '',
  inputs: [],
  icon: '',
  text: ''
}

export const NavLinkFormActionTemplate = (d) => {
  d = { ...defaultNavLinkFormActionData, ...d }
  return `
    <div class="c-nav__link c-nav__link--form-wrapper">
    <form method="${d.method}" action="${d.action}">
      ${d.inputs
        ? d.inputs.map(input => {
            return `<input type="hidden" name="${input.name}" value="${input.value}" />`
          }).join()
        : ''
      }
     
      <button type="submit" class="c-btn c-btn--flat c-nav__form-submit">
        ${d.icon ? `<i class="c-nav__link-icon m-icon m-icon--${d.icon}"></i>` : ''}
        <span class="c-nav__link-text">
          ${d.text}
        </span>
      </button>
    </form>
  </div>
`
}

import BookingItem from '../booking-item/main'
import { bookingStepsEvents } from '../../../js/document/event-types'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'
import { registerWidget } from '../../../js/core/widget/widget-directory'
import { elementFromString, flush } from '../../../js/document/html-helper'
import Component from '../../../js/core/component/component'
import BookingStepsNavigationDataMapper from './data-mapper'

const widgetApi = 'w-booking-steps-navigation'

const widgetQueries = {
  button: `[data-${widgetApi}__button]`
}

export default class BookingStepsNavigation extends BookingItem {
  /**
   *
   * @param {HTMLElement} element   - The HTML content
   */
  constructor (element) {
    super(element)
    this.element = element
    this.button = this.element.querySelector(widgetQueries.button)
    this.url = element.dataset.url || null
    this.dataMapper = new BookingStepsNavigationDataMapper()

    this._attachEvents()
  }

  _attachEvents () {
    this.button.addEventListener('click', () => this._nextStepRequested(), this)
  }

  _nextStepRequested () {
    const eventData = {
      url: this.url
    }

    this.events.emit(bookingStepsEvents.NEXT_STEP_PROCESS_STARTED, eventData)
  }

  async handleFetched (data) {
    this.data = data.response
    this._updateWidgetData()
    return super.handleFetched(data.response)
  }

  _updateWidgetData () {
    if (this.data && this.data.navigation) {
      this.widgetData = this.dataMapper.mapButton(this.data.navigation)

      if (this.widgetData && this.widgetData.button) {
        this._init(this.widgetData)
      } else {
        super.hideComponent()
      }
    } else {
      super.hideComponent()
    }
  }

  _init (widgetData) {
    if (!widgetData) { return }
    this.url = widgetData?.button?.url
    const html = BtnTemplate(Object.assign({}, defaultButtonData, widgetData?.button))
    const content = elementFromString(html)
    Component.initDocumentComponentsFromAPI(content)
    this._render(content)
    this._initHtmlElements()
    this._attachEvents()
  }

  _render (content) {
    flush(this.bodyElement)
    this.bodyElement.appendChild(content)
  }

  _initHtmlElements () {
    this.button = this.element.querySelector(widgetQueries.button)
  }
}

registerWidget(BookingStepsNavigation, widgetApi)

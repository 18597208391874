/**
 * The TransferTicketData contains all data needed to hydrate the view
 *
 * @typedef {Object}          TransferTicketData
 *
 * @property {String}         infoMessage
 * @property {String}         infoMessage
 * @property {String}         infoMessage
 * @property {String}         infoMessage
 * @property {String}         infoMessage
 * @property {String}         infoMessage
 */

export const defaultTransferTicketData = {
  infoMessage: '',
  isEmpty: false,
  emptyText: '',
  locationLabel: '',
  locationText: '',
  isCancelled: false,
  cancelledText: '',
  timeLabel: '',
  timeText: '',
  timeDetails: ''
}

export const TransferTicketTemplate = (d) => {
  d = { ...defaultTransferTicketData, ...d }
  return `
  <div class="w-transfer-ticket">
    ${d.infoMessage
      ? `<p class="w-transfer-ticket__text">${d.infoMessage}</p>`
    : d.isEmpty
      ? `<p class="w-transfer-ticket__text-empty m-heading m-heading--tiny">${d.emptyText}</p>`
    : `
      <div class="w-transfer-ticket__left-container">
        <div class="w-transfer-ticket__label-container">
          <span class="w-transfer-ticket__icon-label icon m-icon m-icon--pin"></span>
          <span class="w-transfer-ticket__label m-body--small">${d.locationLabel}</span>
        </div>
        <div class="w-transfer-ticket__text-secondary m-heading m-heading--tiny">${d.locationText}</div>
        ${d.isCancelled ? `<div class="w-transfer-ticket__text-warning">${d.cancelledText}</div>` : ''}
      </div>
      <div class="w-transfer-ticket__right-container">
        <div class="w-transfer-ticket__label m-body--small">${d.timeLabel}</div>
        <div class="w-transfer-ticket__text-secondary m-heading m-heading--tiny">${d.timeText}</div>
        <div class="w-transfer-ticket__small-label m-body--tiny">${d.timeDetails}</div>
      </div>`}
  </div>
  `
}

import { BookingMessageTemplate } from '../booking-message/w-booking-message.template'
import { ModalV2Template } from '../../components/modal-v2/c-modal-v2.template'
import { BtnTemplate, defaultButtonData } from '../../components/btn/c-btn.template'
/**
 * The BookingComparisonTableData contains all data needed to hydrate a BookingChoiceList view
 *
 * @typedef {Object}                  defaultBookingComparisonTableData
 *
 */

export const defaultBookingComparisonTableData = {}

export const BookingComparisonTableTemplate = d => {
  d = { ...defaultBookingComparisonTableData, ...d }

  return `<div class="w-booking-comparison-table">
      ${d.messages && d.showMessagesOnTop
        ? `<div data-w-booking-comparison-table__messages >
            ${d.messages ? BookingMessageTemplate(d) : ''}
          </div>`
        : ''
      }

      ${d.features && d.options ? cancellationInsuranceTableTemplate(d) : ''}

      ${d.messages && !d.showMessagesOnTop
        ? `<div data-w-booking-comparison-table__messages >
            ${d.messages ? BookingMessageTemplate(d) : ''}
          </div>`
        : ''
      }
    </div>
  `
}

const cancellationInsuranceTableTemplate = d => {
  const { features, options, texts } = d
  return `<div class="w-booking-comparison-table-table">
  <div class="w-booking-comparison-table-features">
    <div class="w-booking-comparison-table__top-info">${texts.topInformationText}</div>
      <ul class="w-booking-comparison-table-features-list">
        ${features.map(feature => {
            return `<li>
                  <span class="m-icon m-icon--${feature.icon} m-icon--size-medium"></span>
                  <span>${feature.description}<span>
                    ${feature.extraContent
                      ? `<span class="w-booking-comparison-table__more-info-button" type="button" data-js-component="c-btn" data-modal-button-info data-c-modal-v2__id="modal-${feature.code}" data-c-modal-v2__action="open">
                          <span class="c-btn__text">${feature.extraContent.linkText}</span>
                        </span>`
                      : ''
                    }
                  </li>`
          })
          .join('')}
      </ul>
    </div>
  <div class="w-booking-comparison-table-wrapper">
    <ul class="w-booking-comparison-table-columns">
      ${options.map(option => {
          return productTableTemplate(option, texts)
        }).join('')}
    </ul>
  </div>
  ${features.map(feature => {
      if (feature.extraContent) {
        return `
          ${ModalV2Template({
            size: 'medium',
            id: `modal-${feature.code}`,
            async: false,
            closable: true,
            title: feature.extraContent.title,
            bodyContent: feature.extraContent.body
          })}
        `
      } else {
        return ''
      }
    }).join('')}
</div>`
}

const productTableTemplate = (serviceOption, texts) => {
  return `<li class='w-booking-comparison-table-product ${serviceOption.checked ? 'w-booking-comparison-table-product--selected' : ''}'
              data-service-option-code="${serviceOption.value}">
            ${serviceOption.bestValueText
              ? `<div class="best-value-label">
                  <span class="c-label c-label--success cs-state-success w-booking-comparison-table__label">${serviceOption.bestValueText}</span>
                </div>`
              : ''
            }
            <div class="w-booking-comparison-table__top-info">
              <span class="w-booking-comparison-table-product-title">
                ${serviceOption.text}
              </span>
              <span class="w-booking-comparison-table-product-price">
                <strong>${serviceOption.price}</strong>
                ${serviceOption.priceText}
              </span>
            </div>

            <ul class="w-booking-comparison-table-features-list">
              ${serviceOption.features.map(feature => {
                return `<li>${feature ||
                  '<i class="m-icon m-icon--cross m-icon--size-medium u-color--state-danger"></i>'}</li>`
              }).join('')}
            </ul>
            ${buttonGroupTemplate(serviceOption, texts)}
          </li>
          `
}

function selectButtonTemplate (serviceOption, texts) {
  const buttonHTMLClass = serviceOption.checked ? 'c-btn--flat-neutral u-color--state-success' : 'c-btn--neutral'
  return `<button class="c-btn c-btn--block ${buttonHTMLClass}" ${
    serviceOption.checked ? 'disabled' : ''
  } type="button" data-js-component="c-btn" data-w-booking-comparison-table__select-button data-w-booking-comparison-table__option-code="${serviceOption.value}">
    <span class="c-btn__text">${serviceOption.checked ? texts.unselectTextButton : texts.selectTextButton}</span>
  </button>`
}

function moreInfoButtonTemplate (serviceOption, texts) {
  if (!serviceOption || !serviceOption.extraInformation) {
    return ''
  }
  return `
    ${ModalV2Template({
      size: 'medium',
      id: `modal-${serviceOption.value}`,
      async: false,
      closable: true,
      title: serviceOption.extraInformation.title,
      bodyContent: serviceOption.extraInformation.body
    })}
    ${BtnTemplate({
      ...defaultButtonData,
      ...{
        text: texts.moreInfoTextButton,
        variant: 'flat-neutral',
        extraClasses: 'w-booking-comparison-table__more-info-button',
        jsApi: true,
        attributes: {
          'data-modal-button-info': '',
          'data-c-modal-v2__id': `modal-${serviceOption.value}`,
          'data-c-modal-v2__action': 'open'
        }
      }
    })}`
}

function buttonGroupTemplate (serviceOption, texts) {
  return `
    <div class="w-booking-comparison-table__button-group">
      ${selectButtonTemplate(serviceOption, texts)}
      ${moreInfoButtonTemplate(serviceOption, texts)}
    </div>
  `
}

/**
 * The NoFlightBusSelectorTemplateData contains all data needed to hydrate a Flight Bus selector view
 * when there is no data, therefore no combination of fligths or buses.
 *
 * @typedef {Object}          NoFlightBusDataTemplate
 *
 * @property {String}         id          - Flight bus selector ID.
 * @property {String}         [title]     - Flight selector title.
 * @property {String}         message     - Message to show.
 */

export const defaultNoFlightBusData = {
  id: '',
  title: '',
  message: ''
}

export const NoFlightBusDataTemplate = (d, options) => {
  d = { ...defaultNoFlightBusData, ...d, ...options }

  return `
<div id="${d.id}" class="w-flight-bus-selector">
  ${d.title ? `<h2 class="c-title m-heading m-heading--small w-booking-gate__item-title">${d.title}</h2>` : ''}
  <div class="w-flight-bus-selector-error-message cs-state-error cs-state-error--light">
    <p>${d.message}</p>
  </div>
</div>
`
}

import { bookingItemEvents } from '../../../js/document/event-types'
import {
  attr,
  definition,
  widgetQueries,
  configOptions,
  classNames
} from './booking-item-constants'

const EventEmitter = require('eventemitter3')

export default class BookingItem {
  constructor (element) {
    this.element = element
    this.events = new EventEmitter()
    this.componentId = this.element.dataset[attr.componentId]
    this.bodyElement = this.element.querySelector(widgetQueries.body)
    this._url = this.element.dataset[attr.url]
    this._method = this.element.dataset[attr.method]

    this.element[definition.name] = {
      handleFetched: (this.handleFetched).bind(this),
      handleFetching: (this.handleFetching).bind(this),
      handleError: (this.handleError).bind(this),
      handleValidationClient: (this.handleValidationClient).bind(this),
      handleValidationServer: (this.handleValidationServer).bind(this),
      handleConditionsValidation: (this.handleConditionsValidation).bind(this),
      getValidationData: (this.getValidationData).bind(this),
      beforeNextStep: (this.beforeNextStep).bind(this),
      enableUI: (this.enableUI).bind(this),
      disableUI: (this.disableUI).bind(this),
      setHasError: (this.setHasError).bind(this),
      isDeferredAndResolvedByItself: (this.isDeferredAndResolvedByItself).bind(this),
      events: this.events,
      hideComponent: (this.hideComponent.bind(this)),
      showComponent: (this.showComponent.bind(this)),
      handleAllRendered: (this.handleAllRendered.bind(this)),
      readPriceConfigFromConfigurations: (this.readPriceConfigFromConfigurations.bind(this)),
      getServiceName: (this.getServiceName.bind(this))
    }
  }

  /**
   * This is the command executed when the mediator if going to do a call to the backend
   */
  handleFetching () {
    this.disableUI()
  }

  /**
   * This is the command that the mediator will send to all the registered items once a
   * response from the backend has been received. This call is performed in an
   * asynchronously way
   *
   * @param {Object} data - Contains the response object obtained from the backend
   */
  async handleFetched (data) {
    return this
  }

  /**
   * This is the command that the mediator will send to all the registered items once a
   * response error is returned/thrown when requesting data to the backend. The call is
   * performed in an asynchronously way.
   *
   * @param {Object} errorData  - Contains the error data obtained from the backend
   */
  async handleError (errorData) {
  }

  /**
   * This is the command that the mediator will send to all the registered items once a
   * validation at client side has to be performed. The call is done asynchronously
   */
  async handleValidationClient () {
    return true
  }

  /**
   * This is the command that the mediator will send to all the registered items once a
   * validation at server side has to be performed. The call is done asynchronously
   * Returns true if the booking-item validates the response obtained
   */
  async handleValidationServer (response) {
    return true
  }

  /**
   * This is the command that the mediator will send to all the registered items once a
   * validation at client side has to be performed with conditions choiceList. The call is done asynchronously
   */
  async handleConditionsValidation () {
    return { isValid: true }
  }

  /**
   * This is the command that the meadiator will send to all the registered items to retrieve
   * all the information needed to perform a call to the backend part to save and validate if
   * everything is entered properly
   */
  async getValidationData () {
    return { url: undefined }
  }

  /**
   * This is the command that the mediator will send to all the registered items before
   * the current step can go to the next step. This call is performed in an asynchronous
   * way
   */
  async beforeNextStep () {
    return true
  }

  /**
   *  By default all items execute handleFetched and they will enableUI after all of them
   *  being resolved. If isDeferredAndResolvedByItself is true the item will call enableUI
   *  deferred after  all non isDeferredAndResolvedByItself are resolved (also have executed
   *  enableUI) and after being resolved itself.
   * @returns {boolean}
   */
  isDeferredAndResolvedByItself () {
    return false
  }

  /**
   * This call will enable the UI of the item removing all the possible states that can
   * be set to an item that has been disabled
   */
  enableUI () {
    this.events.emit(bookingItemEvents.BOOKING_ITEM_LOADED)
    this._hideUnresolvedAndLoading()
  }

  /**
   * This call will disable the UI of the item adding the is-loading class to the item
   * in order to avoid new interactions from the user with the items
   */
  disableUI () {
    this.element.classList.add(classNames.loading)
  }

  /**
   * This call will set the has-error class to the item in order to specify that an error
   * has been received from the backend. With this approach each component can decide how
   * to behave (to be hidden/shown) when an error is received.
   */
  setHasError () {
    this._hideUnresolvedAndLoading()
    this.element.classList.add(classNames.error)
  }

  _hideUnresolvedAndLoading () {
    this.element.classList.remove(classNames.loading)
    this.element.classList.remove(classNames.unresolved)
  }

  hideComponent () {
    this.element.classList.add(classNames.hidden)
  }

  showComponent () {
    this.element.classList.remove(classNames.hidden)
  }

  handleAllRendered () {
  }

  readPriceConfigFromConfigurations (configurationsAsJson) {
    if (!configurationsAsJson) { return }

    return {
      ...(configurationsAsJson[configOptions.priceConfiguration]) && { priceConfiguration: configurationsAsJson[configOptions.priceConfiguration] }
    }
  }

  getServiceName () {
    return ''
  }

  emitAvailableServiceEvent (isFirstCall) {
    const isShown = !this.element.classList.contains(classNames.hidden)
    if (isFirstCall && isShown) {
      const serviceName = this.getServiceName()
      const rawData = { serviceName }
      this.events.emit(bookingItemEvents.BOOKING_ITEM_SERVICE_AVAILABLE, rawData)
    }
  }
}
